import React from 'react'
import { Sidebar } from '../../layouts/auth/Sidebar'
import { TopNavigation } from '../../layouts/auth/TopNavigation'
import AddCarePlan from './AddCarePlan'
import CarePlanList from './CarePlanList'

function Careplans() {
  const [store, setStore] = React.useState(null);

  return (
    <div><Sidebar /><TopNavigation title='Care Plans' />
    <div className='ml-72 mr-8 mt-10 bg-white h-screen'>
     <AddCarePlan store={store} setStore={setStore}/>
     <CarePlanList setStore={setStore}/>
    </div>
  </div>
  )
}

export default Careplans