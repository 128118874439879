import {getCarersPending, getCarersSuccess, getCarersFail} from '../CarerSlices/GetCarersSlice'
import {getCarers} from '../../../api/carerApi'

 const getCarerList = (data) => async dispatch => {

    try {
        dispatch(getCarersPending());
        const result = await getCarers(data);
        if(result){
            return dispatch(getCarersSuccess(result))
        }
    } catch (error) {
        dispatch(getCarersFail(error))
    }
} 

export default getCarerList