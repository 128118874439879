import {updateCarerTierPending, updateCarerTierSuccess, updateCarerTierFail} from '../ConfigSlices/UpdateCarerTierSlice'
import {updateCarerTiers} from '../../../api/clientApi'

 const updateCarerTier = (data) => async dispatch => {

    try {
        dispatch(updateCarerTierPending());
        const result = await updateCarerTiers(data);
        if(result){
            return dispatch(updateCarerTierSuccess(result))
        }
    } catch (error) {
        dispatch(updateCarerTierFail(error))
    }
} 

export default updateCarerTier