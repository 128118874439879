import {editContactsPending, editContactsSuccess, editContactsFail} from '../ClientSlices/EditContactSlice'
import {updateContacts} from '../../../api/clientApi'

 const editContacts = (data) => async dispatch => {

    try {
        dispatch(editContactsPending());
        const result = await updateContacts(data);
        if(result){
            return dispatch(editContactsSuccess(result))
        }
    } catch (error) {
        dispatch(editContactsFail(error))
    }
} 

export default editContacts