import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import PageLoader from '../../components/PageLoader';
import Pagination from "react-js-pagination";
import fetchClientBirthdays from './ClientActions/GetClientsBirthdaysAction';

const Birthdays = () => {
    const dispatch = useDispatch();
    const {getClientBirthdays, isLoadingGetClientBirthdays} = useSelector(state => state.getclientbirthdays)
    const itemsCountPerPage = 5;
    const [page, setPage] = React.useState(1);

    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
    }

    React.useEffect(() => {
        dispatch(fetchClientBirthdays())

    }, []);

    const handleBirthday = (date) => {
        const birthday  = new Date(date);
        return birthday.getDate().toString().padStart(2, '0')+'/'+(birthday.getMonth() + 1).toString().padStart(2, '0')+'/'+birthday.getFullYear()
    }

    let content = isLoadingGetClientBirthdays  ? <PageLoader /> : getClientBirthdays && getClientBirthdays.data && <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
                {/* <th scope="col" className="px-6 py-3">
                    Title
                </th> */}
                <th scope="col" className="px-6 py-3">
                    Name
                </th>        
                <th scope="col" className="px-6 py-3">
                    Address
                </th>
                <th scope="col" className="px-6 py-3">
                    Email
                </th>
                <th scope="col" className="px-6 py-3">
                    Mobile
                </th>
                <th scope="col" className="px-6 py-3">
                   Date Of Birth
                </th>              
            </tr>
        </thead>
        <tbody>
            {getClientBirthdays && getClientBirthdays.data && getClientBirthdays.data.length > 0 ? getClientBirthdays.data.map((client) => (client.status === 'ACTIVE' && <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-primary dark:hover:bg-gray-600">
                {/* <th scope="row" className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                    {client.title}
                </th> */}
                <th scope="row" className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                    {client.firstname} {client.lastname}
                </th>        
                <td className="px-6 py-4">
                    {client.addressLine1}
                </td>
                <td className="px-6 py-4">
                    {client.email}
                </td>
                <td className="px-6 py-4">
                    {client.mobile}
                </td>
                <td className="px-6 py-4">
                    {handleBirthday(client.dob)}
                </td>                
            
            </tr>)) : <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                <td colSpan="9" className="text-center px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap border rounded bg-red-100">No Data Found</td>
            </tr>}
        </tbody>
    </table>

    return (
        <div className='ml-72 mr-8 mt-10'>
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                {content}
            </div>
            <div>
                {(getClientBirthdays && getClientBirthdays.data && getClientBirthdays.data.length > 0 && !isLoadingGetClientBirthdays) &&
                    <div className="flex justify-end">
                        <Pagination
                            activePage={page}
                            itemsCountPerPage={itemsCountPerPage}
                            totalItemsCount={getClientBirthdays.totalItemsCount}
                            pageRangeDisplayed={1}
                            onChange={handlePageChange}
                            itemClass="page-item"
                            linkClass="page-link"
                        />
                    </div>}
            </div>
        </div>
    )
}

export default Birthdays