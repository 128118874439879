import {getCarersOnplacementPending, getCarersOnplacementSuccess, getCarersOnplacementFail} from '../DashboardSlices/GetCarersOnplacementSlice'
import {getCarersPlacement} from '../../../api/carerApi'

 const getCarersPlaced = (data) => async dispatch => {

    try {
        dispatch(getCarersOnplacementPending());
        const result = await getCarersPlacement(data);
        if(result){
            return dispatch(getCarersOnplacementSuccess(result))
        }
    } catch (error) {
        dispatch(getCarersOnplacementFail(error))
    }
} 

export default getCarersPlaced