import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  educationTraining: {},
  isLoadingEducationTraining: false,
  errorEducationTraining: "",
};

const educationTrainingSlice = createSlice({
  name: "educationTraining",
  initialState,
  reducers: {
    educationTrainingPending: (state) => {
      state.isLoadingEducationTraining = true;
    },
    educationTrainingSuccess: (state, { payload }) => {
      state.isLoadingEducationTraining = false;
      state.educationTraining = payload;
      state.errorEducationTraining = "";
    },
    educationTrainingFail: (state, { payload }) => {
      state.isLoadingEducationTraining = false;
      state.errorEducationTraining = payload;
    },
  },
});

export const { educationTrainingPending, educationTrainingSuccess, educationTrainingFail } = educationTrainingSlice.actions;

export default educationTrainingSlice.reducer;
