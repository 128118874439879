import { clientCareWorkersFail, clientCareWorkersPending, clientCareWorkersSuccess } from "../ClientSlices/ClientCareWorkersSlice";
import { getClientCareWorker } from "../../../api/clientApi";

 const getCareWorkers = (data) => async dispatch => {

    try {
        dispatch(clientCareWorkersPending());
        const result = await getClientCareWorker(data);
        if(result){
            return dispatch(clientCareWorkersSuccess(result))
        }
    } catch (error) {
        dispatch(clientCareWorkersFail(error))
    }
} 

export default getCareWorkers