import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  EqualMonitoring: {},
  isLoadingEqualMonitoring: false,
  errorEqualMonitoring: "",
};

const EqualMonitoringSlice = createSlice({
  name: "EqualMonitoring",
  initialState,
  reducers: {
    EqualMonitoringPending: (state) => {
      state.isLoadingEqualMonitoring = true;
    },
    EqualMonitoringSuccess: (state, { payload }) => {
      state.isLoadingEqualMonitoring = false;
      state.EqualMonitoring = payload;
      state.errorEqualMonitoring = "";
    },
    EqualMonitoringFail: (state, { payload }) => {
      state.isLoadingEqualMonitoring = false;
      state.errorEqualMonitoring = payload;
    },
  },
});

export const { EqualMonitoringPending, EqualMonitoringSuccess, EqualMonitoringFail } =
  EqualMonitoringSlice.actions;

export default EqualMonitoringSlice.reducer;
