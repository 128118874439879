import React from "react";
import FormikController from "../../../components/Formik/FormikController";
import SubmitButton from "../../../components/Formik/SubmitButton";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Postcoder from "react-address-lookup";
import { useDispatch, useSelector } from "react-redux";
import editClientCarePlan from "../ClientActions/EditCarePlansAction";
import editPrescriptionDoc from "../ClientActions/EditPrescriptionAction";
import PageLoader from "../../../components/PageLoader";
import GetMedicationAuditAction from "../../careplans/carePlanActions/GetMedicationAuditAction";
import UpdateAuditAction from "../../carers/RegisterCarerActions/MedicatioAuditUpdateAction";

function MarchartDetails({ e, setShowModal, client }) {
  const med = e ? e : "";
// console.log('received',med.received);
  const itemsCountPerPage = 20;
  const [page, setPage] = React.useState(1);
  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  const dispatch = useDispatch();
  const [active, setActive] = React.useState("details");
  const { addClientPersonalDetails } = useSelector(
    (state) => state.addClientPersonal
  );
  const { addClientCarePlans, isLoadingAddCarePlan, errorAddCarePlan } =
    useSelector((state) => state.addClientCarePlan);
  const { addedCarePlans } = useSelector((state) => state.addedCarePlan);
  const { editPrescription, isLoadingEditPres } = useSelector(
    (state) => state.editPrescriptionDoc
  );
  const { editCarePlan, isLoadingCarePlan, errorEditPlan } = useSelector(
    (state) => state.updateCarePlans
  );
  const { getMedicationAudit, isLoadinggetMedicationAudit } = useSelector(
    (state) => state.GetMedicationAudit
  );

  const prescriptionDoc = {
    prescriptions: "",
  };

  const cpId = med ? med?.id : "";

  const handleDocuments = (data) => {
    data.id = med.id;
    dispatch(editPrescriptionDoc(data));
  };

  const handleAudit = (data) => {
    data.adminAdjustments = +data.adminAdjustments
    // console.log('data',data);
    dispatch(UpdateAuditAction(data));
  };

  const auditArray = e?.audit ? e.audit : []

  function getObjectWithRecentDate() {
    if (auditArray.length === 0) {
      return null; // Return null if the array is empty
    }
  
    let mostRecentDate = auditArray[0].createdAt; // Initialize with the date from the first object
    let objectWithRecentDate = auditArray[0]; // Initialize with the first object
  
    for (let i = 1; i < auditArray.length; i++) {
      const currentDate = auditArray[i].createdAt;
  
      if (currentDate > mostRecentDate) {
        mostRecentDate = currentDate;
        objectWithRecentDate = auditArray[i];
      }
    }
  
    return objectWithRecentDate;
  }
  
  // const objectWithRecentDate = getObjectWithRecentDate(auditArray);

  


  React.useEffect(() => {
    // new Date(Math.max.apply(null, auditArray.map(function(e) {
    //   console.log('RECENT', new Date(e.createdAt));
    // })));
    // dispatch(
    //   GetMedicationAuditAction({
    //     id: cpId,
    //     page,
    //     perPage: itemsCountPerPage,
    //   })
    // );
    getObjectWithRecentDate();
  }, [
    addClientCarePlans,
    addClientPersonalDetails,
    editPrescription,
    editCarePlan,
    addedCarePlans,
    getMedicationAudit,
  ]);

  const initialValues = {
    careplanId: med && med.careplan ? med.careplan.id : "",
    prescriptions: med ? med.prescriptions : "",
    medication: med ? med.medication : "",
    medicationType: med ? med.medicationType : "",
    administration: med ? med.administration : "",
    dosage: med ? med.dosage : "",
    routine: med ? med.routine : "",
    morning: med ? med.morning : false,
    lunch: med ? med.lunch : false,
    evening: med ? med.evening : false,
    enableMedicationTime: med ? med.enableMedicationTime : false,
    bed: med ? med.bed : false,
    alternateHours: med ? med.alternateHours : false,
    alternateDays: med ? med.alternateDays : false,
    weekly: med ? med.weekly : false,
    prn: med ? med.prn : false,
    enableAdministeredQuantity: med ? med.enableAdministeredQuantity : true,
    // notes: med ? med.notes : "",
    received: med ? med.received : 0,
    dailyBalance: med ? med.dailyBalance : "",
    weeklyBalance: med ? med.med : "",
    adminAdjustments: med ? med.adminAdjustments : "",
    riskAssessment: med ? med.riskAssessment : "",
    allergies: client ? client.knownAllergies : "",
    monday: med ? med.monday : false,
    tuesday: med ? med.tuesday : false,
    wednesday: med ? med.wednesday : false,
    thursday: med ? med.thursday : false,
    friday: med ? med.friday : false,
    saturday: med ? med.saturday : false,
    sunday: med ? med.sunday : false,
    intervals: med ? med.intervals : "",
    start_time: med ? med.start_time.slice(0, 5) : "00:00",
    end_time: med ? med.end_time.slice(0, 5) : "00:00",
    continuous: med ? med.continuous : "",
    startDate: med ? med.startDate : "",
    nextReviewDate : med ? med.nextReviewDate : "",
    gpReview : med ? med.gpReview : false,
    maxperDay: med ? med.maxperDay : "",
    medicineReason : med ? med.medicineReason : ""
  };

  let cname = addClientPersonalDetails
    ? addClientPersonalDetails.firstname + addClientPersonalDetails.lastname
    : "";

  let clientname = client ? client.firstname + client.lastname : "";

  let cdob = addClientPersonalDetails ? addClientPersonalDetails.dob : "";

  let clientdob = client ? client.dob : "";

  const validationSchema = Yup.object({});

  const handleSubmit = (values) => {
    // console.log('Values',values);
    values.id = med.id;
    values.received = values.received.toString();
    dispatch(editClientCarePlan(values));
    setShowModal(false);
  };

 

  const Head = (props) => {
    return (
      <div className="border-b mb-5 text-left">
        <label className="text-lg font-bold text-button ">{props.title}</label>
      </div>
    );
  };

  return (
    <div className="w-full h-auto p-5 bg-gray-50">
      <div className="relative p-3 flex-auto text-gray-700">
        <Formik
          initialValues={prescriptionDoc}
          validationSchema={validationSchema}
          onSubmit={(values, { resetForm }) => {
            handleDocuments(values);
            resetForm();
          }}
        >
          {({ setFieldValue, ...rest }) => (
            <Form className="text-left">
              <div className="grid grid-cols-3 gap-2 mb-3 border-b">
                <div>
                  <FormikController
                    control="input"
                    type="text"
                    name="name"
                    label="Client Name"
                    value={client ? clientname : cname}
                    disabled
                  />
                </div>
                <div>
                  <FormikController
                    control="input"
                    type="text"
                    name="dob"
                    label="Date of Birth"
                    value={client ? clientdob : cdob}
                    disabled
                  />
                </div>
                <div>
                  <FormikController
                    control="input"
                    type="date"
                    name="nextReviewDate"
                    label="Date of Review"
                    value={med ? med.nextReviewDate : "Pending..."}
                    disabled
                  />
                </div>
              </div>
             <div className="border rounded-lg mb-3 border-orange-100">
              
               {(med && med.prescriptions) ? <div>
                <iframe
                  src={med ? med.prescriptions : ""}
                  frameborder="0"
                  height="300"
                  width="100%"
                ></iframe>
               </div> :<div className="bg-red-50 p-3 text-red-700 font-medium">No Prescription</div>}
              
              </div> 
              <div className="border p-5 rounded">
                <Head title="Prescription" />
                <FormikController
                  control="input"
                  type="file"
                  name="prescriptions"
                  label="Prescription"
                  value={null}
                  onChange={(event) =>
                    setFieldValue("prescriptions", event.target.files[0])
                  }
                />
              </div>
              <div className=" flex flex-row-reverse">
                {isLoadingEditPres ? (
                  <PageLoader />
                ) : (
                  <SubmitButton label="Update" />
                )}
              </div>
            </Form>
          )}
        </Formik>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={(values, { resetForm }) => {
            handleSubmit(values);
            resetForm();
          }}
        >
          {({ setFieldValue, values }) => (
            <Form className="">
              <div className="mb-5 font-bold ">
                <Head title="MAR Chart" />
              </div>

              <div className="mt-2">
                <Head title="Medication Details" />
              </div>

              <div className="grid grid-cols-2 gap-2">
                <div>
                  <FormikController
                    control="input"
                    type="text"
                    name="medication"
                    label="Medication Name"
                  />
                </div>
                <div>
                  <FormikController
                    control="input"
                    type="text"
                    name="medicationType"
                    label="Type of Medication"
                  />
                </div>
                <div>
                  <FormikController
                    control="textArea"
                    type="text"
                    name="administration"
                    label="How is it Administered ?"
                  />
                </div>
                <div>
                  <FormikController
                    control="textArea"
                    type="text"
                    name="dosage"
                    label="Dosage"
                  />
                </div>
              </div>

              <div className="mt-2">
                <Head title="Time Schedule" />
              </div>
              <div className="mt-2">
                <FormikController
                  control="textArea"
                  type="text"
                  name="allergies"
                  label="Medication Allergies"
                />
              </div>
              <div className="grid grid-cols-1 gap-2 mt-2">
                <div className="text-left">
                  <span className="font-medium">Time</span>
                  <br />
                  <div className="border rounded p-3">
                    {!values.alternateDays &&
                      !values.weekly &&
                      !values.continuous &&
                      !values.prn &&
                      !values.alternateHours && (
                        <div>
                          <label>
                            <Field
                              type="checkbox"
                              name="morning"
                              value={true}
                              checked={values.morning === true}
                            />
                            <span className="ml-2">Morning</span>
                          </label>
                          <br />
                          <label>
                            <Field
                              type="checkbox"
                              name="lunch"
                              value={true}
                              checked={values.lunch === true}
                            />
                            <span className="ml-2">Lunch Time</span>
                          </label>
                          <br />
                          <label>
                            <Field
                              type="checkbox"
                              name="evening"
                              value={true}
                              checked={values.evening === true}
                            />
                            <span className="ml-2">Evening</span>
                          </label>
                          <br />
                          <label>
                            <Field
                              type="checkbox"
                              name="bed"
                              value={true}
                              checked={values.bed === true}
                            />
                            <span className="ml-2">Bed Time</span>
                          </label>
                        </div>
                      )}
                    {/* <br /> */}
                    {!values.alternateDays &&
                      !values.weekly &&
                      !values.prn &&
                      !values.morning &&
                      !values.lunch &&
                      !values.evening &&
                      !values.bed && (
                        <label>
                          <Field
                            type="checkbox"
                            name="alternateHours"
                            value={true}
                            checked={values.alternateHours === true}
                          />
                          <span className="ml-2">Alternate Hours</span>
                        </label>
                      )}
                    <br />
                    {values.alternateHours && !values.alternateDays && (
                      <div className="border p-5 rounded bg-monday">
                        {!values.continuous && (
                          <div className="grid grid-cols-3 gap-5 mb-5">
                            <div>
                              <FormikController
                                control="input"
                                type="text"
                                name="intervals"
                                label={"Number of Hours"}
                              />
                            </div>
                            <div>
                              <FormikController
                                control="input"
                                type="time"
                                name="start_time"
                                label={"Start Time"}
                              />
                            </div>
                            <div>
                              <FormikController
                                control="input"
                                type="time"
                                name="end_time"
                                label={"End Time"}
                              />
                            </div>
                          </div>
                        )}
                        <label className="mt-5">
                          <Field
                            type="checkbox"
                            name="continuous"
                            value={true}
                            checked={values.continuous === true}
                          />
                          <span className="ml-2 font-bold text-button text-lg bg-white p-2 rounded border border-button">
                            Continuous Hours for Alternate Days
                          </span>
                        </label>
                        {values.continuous && (
                          <div>
                            <div className="grid grid-cols-2 gap-5 mt-5">
                              <div>
                                <FormikController
                                  control="input"
                                  type="date"
                                  name="startDate"
                                  label={"Start Date"}
                                />
                              </div>
                              <div>
                                <FormikController
                                  control="input"
                                  type="text"
                                  name="intervals"
                                  label={"Number of Hours"}
                                />
                              </div>
                            </div>
                            <div className="border p-5 rounded">
                              <div className="font-medium">
                                Please Select Administration Time
                              </div>
                              <label className="">
                                <Field
                                  type="checkbox"
                                  name="morning"
                                  value={true}
                                  checked={values.morning === true}
                                />
                                <span className="ml-2">Morning</span>
                              </label>
                              <br />
                              <label>
                                <Field
                                  type="checkbox"
                                  name="lunch"
                                  value={true}
                                  checked={values.lunch === true}
                                />
                                <span className="ml-2">Lunch Time</span>
                              </label>
                              <br />
                              <label>
                                <Field
                                  type="checkbox"
                                  name="evening"
                                  value={true}
                                  checked={values.evening === true}
                                />
                                <span className="ml-2">Evening</span>
                              </label>
                              <br />
                              <label>
                                <Field
                                  type="checkbox"
                                  name="bed"
                                  value={true}
                                  checked={values.bed === true}
                                />
                                <span className="ml-2">Bed Time</span>
                              </label>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                      
                      {(values.morning ||
                                      values.lunch ||
                                      values.evening ||
                                      values.bed || values.prn) && <div className="flex justify-between bg-orange-50 p-5 border border-orange-300 rounded">
                                            
                         { !values.gpReview &&   <FormikController
                                    control="input"
                                    type="date"
                                    name="nextReviewDate"
                                    label="Review Date"
                                  />}
                                     <label className=" ">
                              <Field
                                type="checkbox"
                                name="gpReview"
                                value={true}
                                checked={values.gpReview === true}
                              />
                              <span className="ml-2">GP Review</span>
                            </label>
                         
                                     </div>}
                                     {values.prn && 
                                      <div className="grid grid-cols-3 gap-2 mt-5">
                                         <FormikController
                                    control="input"
                                    type="text"
                                    name="maxperDay"
                                    label="Maximum dose in 24 Hours"
                                  />
                                      <FormikController
                                    control="input"
                                    type="text"
                                    name="intervals"
                                    label="Minimum intervals between doses"
                                  />
                                   <FormikController
                                    control="input"
                                    type="text"
                                    name="medicineReason"
                                    label="Reason for Medicine"
                                  />
                                        </div>}
                    {!values.weekly &&
                      !values.prn &&
                      !values.alternateHours && (
                        <label>
                          <Field
                            type="checkbox"
                            name="alternateDays"
                            value={true}
                            checked={values.alternateDays === true}
                          />
                          <span className="ml-2">Alternate Days </span>
                        </label>
                      )}
                    {values.alternateDays &&
                      !values.alternateHours &&
                      !values.weekly && (
                        <div className="space-x-5 mt-4 border p-4">
                          <label>
                            <Field
                              type="checkbox"
                              name="monday"
                              value={true}
                              checked={values.monday === true}
                            />
                            <span className="ml-2 border p-1 pb-2 px-5 rounded bg-monday ">
                              Monday
                            </span>
                          </label>
                          <label>
                            <Field
                              type="checkbox"
                              name="tuesday"
                              value={true}
                              checked={values.tuesday === true}
                            />
                            <span className="ml-2 border p-1 pb-2 px-5 rounded bg-days ">
                              Tuesday
                            </span>
                          </label>
                          <label>
                            <Field
                              type="checkbox"
                              name="wednesday"
                              value={true}
                              checked={values.wednesday === true}
                            />
                            <span className="ml-2 border p-1 pb-2 px-5 rounded bg-wednesday ">
                              Wednesday
                            </span>
                          </label>
                          <label>
                            <Field
                              type="checkbox"
                              name="thursday"
                              value={true}
                              checked={values.thursday === true}
                            />
                            <span className="ml-2 border p-1 pb-2 px-5 rounded bg-thursday ">
                              Thursday
                            </span>
                          </label>
                          <label>
                            <Field
                              type="checkbox"
                              name="friday"
                              value={true}
                              checked={values.friday === true}
                            />
                            <span className="ml-2 border p-1 pb-2 px-5 rounded bg-friday ">
                              Friday
                            </span>
                          </label>
                          <label>
                            <Field
                              type="checkbox"
                              name="saturday"
                              value={true}
                              checked={values.saturday === true}
                            />
                            <span className="ml-2 border p-1 pb-2 px-5 rounded bg-saturday ">
                              Saturday
                            </span>
                          </label>
                          <label>
                            <Field
                              type="checkbox"
                              name="sunday"
                              value={true}
                              checked={values.sunday === true}
                            />
                            <span className="ml-2 border p-1 pb-2 px-5 rounded bg-sunday ">
                              Sunday
                            </span>
                          </label>
                        </div>
                      )}
                    {(values.monday ||
                      values.tuesday ||
                      values.wednesday ||
                      values.thursday ||
                      values.friday ||
                      values.saturday ||
                      (values.sunday &&
                        values.alternateDays &&
                        !values.weekly)) && (
                      <div className="space-x-10 border bg-monday p-4 ">
                        {/* <div className="pb-2 font-bold ml-10">MONDAYhh</div> */}
                        <label className="ml-10">
                          <Field
                            type="checkbox"
                            name="morning"
                            value={true}
                            checked={values.morning === true}
                          />
                          <span className="ml-2">Morning</span>
                        </label>
                        <br />
                        <label>
                          <Field
                            type="checkbox"
                            name="lunch"
                            value={true}
                            checked={values.lunch === true}
                          />
                          <span className="ml-2">Lunch Time</span>
                        </label>
                        <br />
                        <label>
                          <Field
                            type="checkbox"
                            name="evening"
                            value={true}
                            checked={values.evening === true}
                          />
                          <span className="ml-2">Evening</span>
                        </label>
                        <br />
                        <label>
                          <Field
                            type="checkbox"
                            name="bed"
                            value={true}
                            checked={values.bed === true}
                          />
                          <span className="ml-2">Bed Time</span>
                        </label>
                        <br />
                      </div>
                    )}
                    <br />

                    {!values.alternateDays &&
                      !values.alternateHours &&
                      !values.prn && (
                        <label>
                          <Field
                            type="checkbox"
                            name="weekly"
                            value={true}
                            checked={values.weekly === true}
                          />
                          <span className="ml-2">Weekly</span>
                        </label>
                      )}
                    {values.weekly &&
                      !values.alternateDays &&
                      !values.alternateHours && (
                        <div className="space-x-5 mt-4 border p-4">
                          <label>
                            <Field
                              type="checkbox"
                              name="monday"
                              value={true}
                              checked={values.monday === true}
                            />
                            <span className="ml-2 border p-1 pb-2 px-5 rounded bg-monday ">
                              Monday
                            </span>
                          </label>
                          <label>
                            <Field
                              type="checkbox"
                              name="tuesday"
                              value={true}
                              checked={values.tuesday === true}
                            />
                            <span className="ml-2 border p-1 pb-2 px-5 rounded bg-days ">
                              Tuesday
                            </span>
                          </label>
                          <label>
                            <Field
                              type="checkbox"
                              name="wednesday"
                              value={true}
                              checked={values.wednesday === true}
                            />
                            <span className="ml-2 border p-1 pb-2 px-5 rounded bg-wednesday ">
                              Wednesday
                            </span>
                          </label>
                          <label>
                            <Field
                              type="checkbox"
                              name="thursday"
                              value={true}
                              checked={values.thursday === true}
                            />
                            <span className="ml-2 border p-1 pb-2 px-5 rounded bg-thursday ">
                              Thursday
                            </span>
                          </label>
                          <label>
                            <Field
                              type="checkbox"
                              name="friday"
                              value={true}
                              checked={values.friday === true}
                            />
                            <span className="ml-2 border p-1 pb-2 px-5 rounded bg-friday ">
                              Friday
                            </span>
                          </label>
                          <label>
                            <Field
                              type="checkbox"
                              name="saturday"
                              value={true}
                              checked={values.saturday === true}
                            />
                            <span className="ml-2 border p-1 pb-2 px-5 rounded bg-saturday ">
                              Saturday
                            </span>
                          </label>
                          <label>
                            <Field
                              type="checkbox"
                              name="sunday"
                              value={true}
                              checked={values.sunday === true}
                            />
                            <span className="ml-2 border p-1 pb-2 px-5 rounded bg-sunday ">
                              Sunday
                            </span>
                          </label>
                        </div>
                      )}
                    {/* {(values.monday || values.tuesday || values.wednesday || values.thursday || values.friday || values.saturday || values.sunday && !values.alternateDays && values.weekly ) &&
                                      (
                                        <div className="space-x-10 border bg-monday p-4 ">
                                          <div className="pb-2 font-bold ml-10">MONDAYhh</div>
                                              <label className="ml-10">
                                      <Field
                                        type="checkbox"
                                        name="morning"
                                        value={true}
                                        checked={values.morning === true}
                                      />
                                      <span className="ml-2">Morningjj</span>
                                    </label>
                                    <br />
                                    <label >
                                      <Field
                                        type="checkbox"
                                        name="lunch"
                                        value={true}
                                        checked={values.lunch === true}
                                      />
                                      <span className="ml-2">Lunch Time</span>
                                    </label>
                                    <br />
                                    <label>
                                      <Field
                                        type="checkbox"
                                        name="evening"
                                        value={true}
                                        checked={values.evening === true}
                                      />
                                      <span className="ml-2">Evening</span>
                                    </label>
                                    <br />
                                    <label>
                                      <Field
                                        type="checkbox"
                                        name="bed"
                                        value={true}
                                        checked={values.bed === true}
                                      />
                                      <span className="ml-2">Bed Time</span>
                                    </label>
                                    <br />
                                        </div>
                                      )} */}
                    <br />
                    {!values.alternateDays &&
                      !values.alternateHours &&
                      !values.weekly &&
                      !values.morning &&
                      !values.lunch &&
                      !values.evening &&
                      !values.bed && (
                        <label>
                          <Field
                            type="checkbox"
                            name="prn"
                            value={true}
                            checked={values.prn === true}
                          />
                          <span className="ml-2">PRN (As needed)</span>
                        </label>
                      )}
                    <br />
                    <label>
                      <Field
                        type="checkbox"
                        name="enableAdministeredQuantity"
                        value={true}
                        checked={values.enableAdministeredQuantity === true}
                      />
                      <span className="ml-2">
                        Enable Care Worker to enter medication quantity they
                        administered
                      </span>
                    </label>
                    <br />
                    {/* <label>
                                      <Field
                                        type="checkbox"
                                        name="enableMedicationTime"
                                        value={true}
                                        checked={values.enableMedicationTime === true}
                                      />
                                      <span className="ml-2">
                                        Disable Administration Time
                                      </span>
                                    </label> */}
                  </div>
                </div>
              </div>
              {/* <div className="mt-3">
                <Head title="Administration Notes" />
              </div>
              <div className="grid grid-cols-1 gap-2 mt-2">
                <div>
                  <FormikController
                    control="textArea"
                    type="text"
                    name="notes"
                    label="Notes"
                  />
                </div>
              </div> */}

              <div className="mb-3 mt-5 flex flex-row-reverse">
                <div></div>
                {/* {isLoadingCarePlan ? <PageLoader/> : <SubmitButton label="Update Medication" />} */}
                <SubmitButton label="Update" />
              </div>
            </Form>
          )}
        </Formik>
        <Formik
          initialValues={{
            clientcareplanId: med ? med.id : '',
            id:getObjectWithRecentDate()?.id ? getObjectWithRecentDate().id : '',
            adminAdjustments: 0,
            systemCount :getObjectWithRecentDate()?.systemCount ? getObjectWithRecentDate().systemCount : '',
            countedMedication : getObjectWithRecentDate()?.countedMedication ? getObjectWithRecentDate().countedMedication : '',
            MedicationDiscrepancies : getObjectWithRecentDate()?.MedicationDiscrepancies ? getObjectWithRecentDate().MedicationDiscrepancies : '',
          }}
          validationSchema={validationSchema}
          onSubmit={(values, { resetForm }) => {
            handleAudit(values);
            resetForm();
          }}
        >
          {({ setFieldValue, ...rest }) => (
            <Form className="text-left">
              <div className="mt-3">
                <Head title="Medication Audit" />
              </div>
              <div className="bg-green p-2 rounded text-white">Medication Quantity : {med ? med.received : ""}</div>
              <div className="grid grid-cols-5 gap-2 mt-2">
                <div>
                  <FormikController
                    control="input"
                    type="text"
                    name="systemCount"
                    label="System Balance"
                    disabled
                  />
                </div>
                <div>
                  <FormikController
                    control="input"
                    type="text"
                    name="countedMedication"
                    label="Audit Physical Count"
                    disabled
                  />
                </div>
                <div>
                  <FormikController
                    control="input"
                    type="text"
                    name="MedicationDiscrepancies"
                    label="Variance"
                    disabled
                  />
                </div>
                <div>
                  <FormikController
                    control="input"
                    type="text"
                    name="adminAdjustments"
                    label="Admin Adjustments"
                  />
                </div>
                <div>
                  <FormikController
                    control="input"
                    type="text"
                    name="systemCount"
                    label="New Balance"
                    disabled
                  />
                </div>
              </div>
              <div className=" flex flex-row-reverse">
              <SubmitButton label="Update" />
                {/* {isLoadingEditPres ? (
                  <PageLoader />
                ) : (
                  <SubmitButton label="Update" />
                )} */}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default MarchartDetails;
