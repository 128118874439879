import {EmploymentHistoryPending, EmploymentHistorySuccess, EmploymentHistoryFail} from '../RegisterCarerSlices/EmploymentHistorySlice'
import {EmploymentHistory} from '../../api/carerApi'

 const EmploymentHistoryAction = (data) => async dispatch => {

    try {
        dispatch(EmploymentHistoryPending());
        const result = await EmploymentHistory(data);
        if(result){
            return dispatch(EmploymentHistorySuccess(result))
        }
    } catch (error) {
        dispatch(EmploymentHistoryFail(error))
    }
} 

export default EmploymentHistoryAction