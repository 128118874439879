import {clockInPending, clockInSuccess, clockInFail} from '../DashboardSlices/ClockInSlice'
import {getClockIn} from '../../../api/carerApi'

 const ClockInAction = (data) => async dispatch => {

    try {
        dispatch(clockInPending());
        const result = await getClockIn(data);
        if(result){
            return dispatch(clockInSuccess(result))
        }
    } catch (error) {
        dispatch(clockInFail(error))
    }
} 

export default ClockInAction