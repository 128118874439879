import {getClientInfoPending, getClientInfoSuccess, getClientInfoFail} from '../ClientSlices/GetClientInfoSlice'
import {getClientInfo} from '../../../api/clientApi'

 const ClientInfo = (data) => async dispatch => {

    try {
        dispatch(getClientInfoPending());
        const result = await getClientInfo(data);
        if(result){
            return dispatch(getClientInfoSuccess(result))
        }
    } catch (error) {
        dispatch(getClientInfoFail(error))
    }
} 

export default ClientInfo