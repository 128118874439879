import { useUpdateTimesheet } from "../../../api/timesheetsApi";
import { editInvoiceTimesheetsFail, editInvoiceTimesheetsPending, editInvoiceTimesheetsSuccess } from "../Slices/EditTimesheetsSlice";

const editTimesheets = (data) => async dispatch => {

    try {
        dispatch(editInvoiceTimesheetsPending());
        const result = await useUpdateTimesheet(data);
        if(result){
            return dispatch(editInvoiceTimesheetsSuccess(result))
        }
    } catch (error) {
        dispatch(editInvoiceTimesheetsFail(error))
    }
} 

export default editTimesheets