import {CriminalRecordPending, CriminalRecordSuccess, CriminalRecordFail} from '../RegisterCarerSlices/CriminalRecordSlice'
import {CriminalRecord} from '../../api/carerApi'

 const CriminalRecordAction = (data) => async dispatch => {

    try {
        dispatch(CriminalRecordPending());
        const result = await CriminalRecord(data);
        if(result){
            return dispatch(CriminalRecordSuccess(result))
        }
    } catch (error) {
        dispatch(CriminalRecordFail(error))
    }
} 

export default CriminalRecordAction