import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    getCarersOnplacement: {},
    isLoadingCarerOnPlacement: false,
    errorCarerOnPlacement: ''
}

const getCarersOnplacementSlice = createSlice({
    name: 'getCarersOnplacement',
    initialState,
    reducers:{
        getCarersOnplacementPending: (state) => {
            state.isLoadingCarerOnPlacement = true;
        },
        getCarersOnplacementSuccess: (state, {payload}) => {
            state.isLoadingCarerOnPlacement = false;
            state.getCarersOnplacement = payload;
            state.errorCarerOnPlacement = '';
        },
        getCarersOnplacementFail: (state, {payload}) => {
            state.isLoadingCarerOnPlacement = false;
            state.errorCarerOnPlacement = payload;
        }
    }
})

export const {getCarersOnplacementPending, getCarersOnplacementSuccess, getCarersOnplacementFail} = getCarersOnplacementSlice.actions

export default getCarersOnplacementSlice.reducer