import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  EditRtw: {},
  isLoadingEditRtw: false,
  errorEditRtw: "",
};

const EditRtwSlice = createSlice({
  name: "EditRtw",
  initialState,
  reducers: {
    EditRtwPending: (state) => {
      state.isLoadingEditRtw = true;
    },
    EditRtwSuccess: (state, { payload }) => {
      state.isLoadingEditRtw = false;
      state.EditRtw = payload;
      state.errorEditRtw = "";
    },
    EditRtwFail: (state, { payload }) => {
      state.isLoadingEditRtw = false;
      state.errorEditRtw = payload;
    },
  },
});

export const { EditRtwPending, EditRtwSuccess, EditRtwFail } =
  EditRtwSlice.actions;

export default EditRtwSlice.reducer;
