import {updateClientTierPending, updateClientTierSuccess, updateClientTierFail} from '../ConfigSlices/UpdateClientTierSlice'
import {updateClientTiers} from '../../../api/clientApi'

 const updateClientTier = (data) => async dispatch => {

    try {
        dispatch(updateClientTierPending());
        const result = await updateClientTiers(data);
        if(result){
            return dispatch(updateClientTierSuccess(result))
        }
    } catch (error) {
        dispatch(updateClientTierFail(error))
    }
} 

export default updateClientTier