import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    medicationAuditUpdate: {},
    isLoadingmedicationAuditUpdate: false,
    errormedicationAuditUpdate: ''
}

const medicationAuditUpdateSlice = createSlice({
    name: 'medicationAuditUpdate',
    initialState,
    reducers:{
        medicationAuditUpdatePending: (state) => {
            state.isLoadingmedicationAuditUpdate = true;
        },
        medicationAuditUpdateSuccess: (state, {payload}) => {
            state.isLoadingmedicationAuditUpdate = false;
            state.medicationAuditUpdate = payload;
            state.errormedicationAuditUpdate = '';
        },
        medicationAuditUpdateFail: (state, {payload}) => {
            state.isLoadingmedicationAuditUpdate = false;
            state.errormedicationAuditUpdate = payload;
        }
    }
})

export const {medicationAuditUpdatePending, medicationAuditUpdateSuccess, medicationAuditUpdateFail} = medicationAuditUpdateSlice.actions

export default  medicationAuditUpdateSlice.reducer