import {editPrescriptionPending, editPrescriptionSuccess, editPrescriptionFail} from '../ClientSlices/EditPrescriptionSlice'
import {updatePrescription} from '../../../api/clientApi'

 const editPrescriptionDoc = (data) => async dispatch => {

    try {
        dispatch(editPrescriptionPending());
        const result = await updatePrescription(data);
        if(result){
            return dispatch(editPrescriptionSuccess(result))
        }
    } catch (error) {
        dispatch(editPrescriptionFail(error))
    }
} 

export default editPrescriptionDoc