import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  GetReligion: {},
  isLoadingGetReligion: false,
  errorGetReligion: "",
};

const GetReligionSlice = createSlice({
  name: "GetReligion",
  initialState,
  reducers: {
    GetReligionPending: (state) => {
      state.isLoadingGetReligion = true;
    },
    GetReligionSuccess: (state, { payload }) => {
      state.isLoadingGetReligion = false;
      state.GetReligion = payload;
      state.errorGetReligion = "";
    },
    GetReligionFail: (state, { payload }) => {
      state.isLoadingGetReligion = false;
      state.errorGetReligion = payload;
    },
  },
});

export const { GetReligionPending, GetReligionSuccess, GetReligionFail } =
  GetReligionSlice.actions;

export default GetReligionSlice.reducer;
