import React from "react";
import { Sidebar } from "../../../layouts/auth/Sidebar";
import { TopNavigation } from "../../../layouts/auth/TopNavigation";
import SimpleSwitch from "../../../components/SimpleSwitch";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ProgressBar from "../../clients/AddClient/ProgressBar";
import SubmitButton from "../../../components/Formik/SubmitButton";
import CarerTypeAction from "../RegisterCarerActions/CarerTypeAction";
import PageLoader from "../../../components/PageLoader";

function CarerAvailability({GetCarerInfo}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { signUp, isLoadingSignUp, errorSignUp } = useSelector(
    (state) => state.signUpCarer
  );
  const { CarerType, isLoadingCarerType } = useSelector(
    (state) => state.CarerTypeAvailability
  );

  const initialValues = {
    liveIn: false,
    day: false,
    night: false,
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
  };


  const validationSchema = Yup.object({});

  const Head = (props) => {
    return (
      <div className="border-b mb-5">
        <label className="text-xl font-medium text-gray-700">
          {props.title}
        </label>
      </div>
    );
  };

  const handleSubmit = (values) => {
    values.id = signUp.user.id
    console.log("VALUEE", values);
    dispatch(CarerTypeAction(values));
    navigate('/nextofkin');
  };

  const handleUpdate = (values) => {
    console.log("VALUEE", values);
    values.id = GetCarerInfo?.id
    dispatch(CarerTypeAction(values));
  };

  return (
    <div className="bg-gray-50">
     {(GetCarerInfo && !GetCarerInfo?.availability) ? "" : <Sidebar />}
      {(GetCarerInfo && !GetCarerInfo?.availability) ? "" : <TopNavigation title="Add Carer" />}
      <div className="bg-gray-50 h-screen">
      <div className={(GetCarerInfo && !GetCarerInfo?.availability) ? "mt-5" : "flex ml-80 mr-16 mt-5"}>
         {(GetCarerInfo && !GetCarerInfo?.availability) ? "" : <div className="w-40 border mt-5 p-3 rounded-lg bg-gray-50 h-screen">
            <ProgressBar number={20} />
          </div>}
          <div className="w-full p-5">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values) => (GetCarerInfo && !GetCarerInfo?.availability) ? handleUpdate(values) : handleSubmit(values)}
            >
              {({ formik, values }) => {
                return (
                  <Form className="text-left">
                    <div className="text-left font-semibold text-gray-600 py-1">
                      AVAILABILITY
                    </div>
                    <div className="shadow  bg-white p-5 rounded ">
                      <div className="grid grid-cols-1 gap-2">
                        <SimpleSwitch
                          name="liveIn"
                          label="Live In Carer"
                          checked={values.liveIn === true}
                        />

                        <SimpleSwitch
                          name="day"
                          label="Day Carer"
                          checked={values.day === true}
                        />

                        <SimpleSwitch
                          name="night"
                          label="Night Carer"
                          checked={values.night === true}
                        />

                        <SimpleSwitch
                          name="monday"
                          label="Monday"
                          checked={values.monday === true}
                        />

                        <SimpleSwitch
                          name="tuesday"
                          label="Tuesday"
                          checked={values.tuesday === true}
                        />
                        <SimpleSwitch
                          name="wednesday"
                          label="Wednesday"
                          checked={values.wednesday === true}
                        />
                        <SimpleSwitch
                          name="thursday"
                          label="Thursday"
                          checked={values.thursday === true}
                        />
                        <SimpleSwitch
                          name="friday"
                          label="Friday"
                          checked={values.friday === true}
                        />

                        <SimpleSwitch
                          name="saturday"
                          label="Saturday"
                          checked={values.saturday === true}
                        />

                        <SimpleSwitch
                          name="sunday"
                          label="Sunday"
                          checked={values.sunday === true}
                        />
                      </div>
                    </div>
                    <div className="mb-3 mt-5 flex flex-row-reverse">
                      <div></div>
                     {isLoadingCarerType ? <PageLoader/> : <SubmitButton label={GetCarerInfo && !GetCarerInfo?.availability ? "Save" : "Save & Next"} />}
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CarerAvailability;
