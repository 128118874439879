import { updateStatusFail, updateStatusPending, updateStatusSuccess } from "../CarerSlices/UpdateStatusSlice";
import { updateStatus } from "../../../api/carerApi";

const updateCarerStatus = (data) => async dispatch => {

    try {
        dispatch(updateStatusPending());
        const result = await updateStatus(data);
        if(result){
            return dispatch(updateStatusSuccess(result))
        }
    } catch (error) {
        dispatch(updateStatusFail(error))
    }
} 

export default updateCarerStatus