import React from "react";
import { BsFillEyeFill } from "react-icons/bs";
import FormikController from '../../components/Formik/FormikController';
import SubmitButton from '../../components/Formik/SubmitButton';
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Field } from "formik";
import { getStaff } from '../staff/staffActions/staffAction'
import { useDispatch, useSelector } from 'react-redux';
import editClientCarePlan from '../clients/ClientActions/EditCarePlansAction'
import addedPlans from '../clients/ClientActions/GetAddedCarePlansAction'
import getMarCharts from '../clients/ClientActions/GetMarChartsAction'
import editPrescriptionDoc from '../clients/ClientActions/EditPrescriptionAction'

export default function ViewMarChart({ e }) {
    const dispatch = useDispatch();
    const specialityArray = new Array('CP06');
    const [showModal, setShowModal] = React.useState(false);
    const [indexes, setIndexes] = React.useState([]);
    const [counter, setCounter] = React.useState(0);

    const { addClientPersonalDetails } = useSelector(state => state.addClientPersonal);
    const { addClientCarePlans, isLoadingAddCarePlan, errorAddCarePlan } = useSelector(state => state.addClientCarePlan)
    const { addedCarePlans } = useSelector(state => state.addedCarePlan);
    const {editPrescription, isLoadingEditPres} = useSelector(state => state.editPrescriptionDoc)
    const { editCarePlan, isLoadingCarePlan, errorEditPlan } = useSelector(state => state.updateCarePlans);



    const prescriptionDoc = {
        prescriptions: "",
      }


    const handleDocuments = (data) => {
        data.id = e.id
        dispatch(editPrescriptionDoc(data));
        setShowModal(false)
      }

    const itemsCountPerPage = 10;

    const [page, setPage] = React.useState(1);

    React.useEffect(() => {
        dispatch(getMarCharts({
            id: e.id,
            clientId: addClientPersonalDetails ? addClientPersonalDetails.id : ''
        }));
     
    }, [addClientPersonalDetails, addClientCarePlans, addedCarePlans, editCarePlan,editPrescription]);



    const handleOpen = (id) => {
        setShowModal(true);
        dispatch(addedPlans({
            id: e.id,
            clientId: addClientPersonalDetails.id
        }));
    };


    const initialValues = {
        careplanId: e && e.careplan ? e.careplan.id : '',
        background: e ? e.background : "",
        risks: e ? e.risks : "",
        riskAssessment: e && e.riskAssessment ? e.riskAssessment : "",
        aim: e ? e.aim : "",
        objective: e ? e.objective : "",
        outcome: e ? e.outcome : "",
        behaviourTime: e ? e.behaviourTime : "",
        nonCompliant: e ? e.nonCompliant : "",
        verballyAggressive: e ? e.verballyAggressive : "",
        reputationOfBehaviour: e ? e.reputationOfBehaviour : "",
        otherBehaviour: e ? e.otherBehaviour : "",
        behaviourComment: e ? e.behaviourComment : "",
        morningBowelTime: e ? e.morningBowelTime : "",
        morningBowelType: e ? e.morningBowelType : "",
        morningBowelAmount: e ? e.morningBowelAmount : "",
        afternoonBowelTime: e ? e.afternoonBowelTime : "",
        afternoonBowelType: e ? e.afternoonBowelType : "",
        afternoonBowelAmount: e ? e.afternoonBowelAmount : "",
        nightBowelTime: e ? e.nightBowelTime : "",
        nightBowelType: e ? e.nightBowelType : "",
        nightBowelAmount: e ? e.nightBowelAmount : "",
        repositionLeftSideTime: e ? e.repositionLeftSideTime : "",
        repositionLeftSideSkinInspection: e ? e.repositionLeftSideSkinInspection : "",
        repositionBackTime: e ? e.repositionBackTime : "",
        repositionBackSkinInspection: e ? e.repositionBackSkinInspection : "",
        repositionRightSideTime: e ? e.repositionRightSideTime : "",
        repositionRightSideSkinInspection: e ? e.repositionRightSideSkinInspection : "",
        fluidIntakeTime: e ? e.fluidIntakeTime : "",
        fluidIntake: e ? e.fluidIntake : "",
        fluidIntakeComments: e ? e.fluidIntakeComments : "",
        foodIntakeTime: e ? e.foodIntakeTime : "",
        food: e ? e.food : "",
        foodIntakeAmount: e ? e.foodIntakeAmount : "",
        foodIntakeComments: e ? e.foodIntakeComments : "",
        prescriptions: e ? e.prescriptions : "",
        medication: e ? e.medication : "",
        medicationType: e ? e.medicationType : "",
        administration: e ? e.administration : "",
        dosage: e ? e.dosage : "",
        routine: e ? e.routine : "",
        morning: e ? e.morning : false,
        lunch: e ? e.lunch : false,
        evening: e ? e.evening : false,
        bed: e ? e.bed : false,
        alternateHours: e ? e.alternateHours : false,
        alternateDays: e ? e.alternateDays : false,
        weekly: e ? e.weekly : false,
        prn: e ? e.prn : false,
        enableAdministeredQuantity: e ? e.enableAdministeredQuantity : false,
        notes: e ? e.notes : "",
        received: e ? e.received : 0,
        dailyBalance: e ? e.dailyBalance : "",
        weeklyBalance: e ? e.weeklyBalance : "",
        adminAdjustments: e ? e.adminAdjustments : "",
        adminId: "",
        status: "APPROVED"
    }

    const validationSchema = Yup.object({

    })

    const handleSubmit = (values) => {
        values.id = e.id
    
        dispatch(editClientCarePlan(values))
        setShowModal(false);

    }



    const Head = (props) => {
        return (
            <div className='text-left border-b'><label className='text-xl font-medium text-gray-700'>{props.title}</label></div>
        )
    }
    return (
        <>
            {<button
                className=" text-gray-600 bg-white shadow mt-2 hover:bg-white inline-flex hover:text-gray-700 hover:border hover:border-gray-300 hover:underline decoration-secondary focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-3 mr-1 py-1 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="button"
                onClick={() => handleOpen(e.id)}>
                View <BsFillEyeFill className='ml-1 mt-0.5 text-secondary' />
            </button>}
            {showModal ? (
                <>
                    <div className="justify-center items-center overflow-x-auto overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <div className="relative w-auto my-6 mx-auto max-w-4xl">
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">

                                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                    <h3 className="text-xl font-semibold text-gray-500">
                                        {e.code} {e.title}
                                    </h3>
                                    <button className="p-1 ml-10 bg-transparent border-0 text-button float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => setShowModal(false)} >
                                        ×
                                    </button>
                                </div>

                                <div className="relative p-6 flex-auto">
                                <div className="mt-4"><Head title='MAR Chart Verification' /></div>
                                    <div className="relative p-3 flex-auto text-gray-700">
                                       <iframe src={(e && e.prescriptions) ? e.prescriptions : '' }
                                            frameborder="0"
                                            height="300"
                                            width="100%" ></iframe>
                                     <Formik
                                            initialValues={prescriptionDoc}
                                            validationSchema={validationSchema}
                                            onSubmit={(values, { resetForm }) => {
                                                handleDocuments(values);
                                                resetForm();
                                            }}
                                        >
                                            {({ setFieldValue, ...rest }) => (
                                                <Form className='text-left'>
                                                    <div className="border p-5 rounded">
                                                        <Head title='Prescription' />
                                                        <FormikController
                                                            control='input'
                                                            type='file'
                                                            name='prescriptions'
                                                            label='Prescription'
                                                            value={null}
                                                            onChange={(event) =>
                                                                setFieldValue("prescriptions", event.target.files[0])
                                                            }

                                                        />
                                                    </div>
                                                    <div className=" flex flex-row-reverse">
                                                        { <SubmitButton label='Update' />}
                                                    </div>
                                                </Form>
                                            )
                                            }
                                        </Formik>
                                        <Formik
                                            initialValues={initialValues}
                                            enableReinitialize
                                            validationSchema={validationSchema}
                                            onSubmit={(values, { resetForm }) => {
                                                handleSubmit(values);
                                                resetForm();
                                            }}
                                        >
                                            {({ setFieldValue, values }) => (
                                                <Form className=''>                                                 
                                                        <div className='mb-5 font-bold'><Head title='MAR Chart' /></div>
            
                                                        <div className="mt-2"><Head title='Medication Details' /></div>
                                                        <div className='grid grid-cols-2 gap-2'>
                                                            <div>
                                                                <FormikController
                                                                    control='input'
                                                                    type='text'
                                                                    name='medication'
                                                                    label='Medication Name'
                                                                />
                                                            </div>
                                                            <div>
                                                                <FormikController
                                                                    control='input'
                                                                    type='text'
                                                                    name='medicationType'
                                                                    label='Type of Medication'
                                                                />
                                                            </div>
                                                            <div>
                                                                <FormikController
                                                                    control='textArea'
                                                                    type='text'
                                                                    name='administration'
                                                                    label='How is it Administered ?'
                                                                />
                                                            </div>
                                                            <div>
                                                                <FormikController
                                                                    control='textArea'
                                                                    type='text'
                                                                    name='dosage'
                                                                    label='Dosage'
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="mt-2"><Head title='Time Schedule' /></div>
                                                        <div className='grid grid-cols-1 gap-2 mt-2'>
                                                            <div className='text-left'>
                                                                <span className='font-medium'>Time</span><br />
                                                                <div className='border rounded p-3'>
                                                                    <label>
                                                                        <Field type="checkbox" name='morning' value={true}
                                                                            checked={values.morning === true}
                                                                        />
                                                                        <span className="ml-2">Morning</span>
                                                                    </label><br />
                                                                    <label>
                                                                        <Field type="checkbox" name='lunch' value={true}
                                                                            checked={values.lunch === true}
                                                                        />
                                                                        <span className="ml-2">Lunch Time</span>
                                                                    </label><br />
                                                                    <label>
                                                                        <Field type="checkbox" name='evening' value={true}
                                                                            checked={values.evening === true}
                                                                        />
                                                                        <span className="ml-2">Evening</span>
                                                                    </label><br />
                                                                    <label>
                                                                        <Field type="checkbox" name='bed' value={true}
                                                                            checked={values.bed === true}
                                                                        />
                                                                        <span className="ml-2">Bed Time</span>
                                                                    </label><br />
                                                                    <label>
                                                                        <Field type="checkbox" name='alternateHours' value={true}
                                                                            checked={values.alternateHours === true}
                                                                        />
                                                                        <span className="ml-2">Alternate Hours</span>
                                                                    </label><br />
                                                                    <label>
                                                                        <Field type="checkbox" name='alternateDays' value={true}
                                                                            checked={values.alternateDays === true}
                                                                        />
                                                                        <span className="ml-2">Alternate Days </span>
                                                                    </label><br />
                                                                    <label>
                                                                        <Field type="checkbox" name='weekly' value={true}
                                                                            checked={values.weekly === true}
                                                                        />
                                                                        <span className="ml-2">Weekly</span>
                                                                    </label><br />
                                                                    <label>
                                                                        <Field type="checkbox" name='prn' value={true}
                                                                            checked={values.prn === true}
                                                                        />
                                                                        <span className="ml-2">PRN (As needed)</span>
                                                                    </label><br />
                                                                    <label>
                                                                        <Field type="checkbox" name='enableAdministeredQuantity' value={true}
                                                                            checked={values.enableAdministeredQuantity === true}
                                                                        />
                                                                        <span className="ml-2">Enable Care Worker to enter medication quantity they administered</span>
                                                                    </label><br />

                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className='mt-3'><Head title='Administration Notes' /></div>
                                                        <div className='grid grid-cols-1 gap-2 mt-2'>
                                                            <div>
                                                                <FormikController
                                                                    control='textArea'
                                                                    type='text'
                                                                    name='notes'
                                                                    label='Notes'
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className='mt-3'><Head title='Medication Audit' /></div>
                                                        <div className='grid grid-cols-4 gap-2 mt-2'>
                                                            <div>
                                                                <FormikController
                                                                    control='input'
                                                                    type='number'
                                                                    name='received'
                                                                    label='Received'
                                                                />
                                                            </div>
                                                            <div>
                                                                <FormikController
                                                                    control='input'
                                                                    type='text'
                                                                    name='dailyBalance'
                                                                    label='Daily Balance'
                                                                />
                                                            </div>
                                                            <div>
                                                                <FormikController
                                                                    control='input'
                                                                    type='text'
                                                                    name='weeklyBalance'
                                                                    label='Weekly Balance'
                                                                />
                                                            </div>
                                                            <div>
                                                                <FormikController
                                                                    control='input'
                                                                    type='text'
                                                                    name='adminAdjustments'
                                                                    label='Admin Adjustments'
                                                                />
                                                            </div>
                                                        </div>
                                                        
                                               
                                                    <div className="mb-3 mt-5 flex flex-row-reverse">
                                                        <div></div>
                                                        <SubmitButton label='Verify Medication' />
                                                    </div>
                                                </Form>
                                            )
                                            }
                                        </Formik>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
        </>
    );
}