import {editCarePlanPending, editCarePlanSuccess, editCarePlanFail} from '../ClientSlices/EditCarePlansSlice'
import {updateCarePlan} from '../../../api/clientApi'

 const editClientCarePlan = (data) => async dispatch => {

    try {
        dispatch(editCarePlanPending());
        const result = await updateCarePlan(data);
        if(result){
            return dispatch(editCarePlanSuccess(result))
        }
    } catch (error) {
        dispatch(editCarePlanFail(error))
    }
} 

export default editClientCarePlan