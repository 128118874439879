import {updateEmploymentHistoryPending, updateEmploymentHistorySuccess, updateEmploymentHistoryFail} from '../RegisterCarerSlices/UpdateEmploymentHistorySlice'
import {UpdateWorkHistory} from '../../api/carerApi'

 const UpdateEmploymentHistoryAction = (data) => async dispatch => {

    try {
        dispatch(updateEmploymentHistoryPending());
        const result = await UpdateWorkHistory(data);
        if(result){
            return dispatch(updateEmploymentHistorySuccess(result))
        }
    } catch (error) {
        dispatch(updateEmploymentHistoryFail(error))
    }
} 

export default UpdateEmploymentHistoryAction