import {CarerTypePending, CarerTypeSuccess, CarerTypeFail} from '../RegisterCarerSlices/CarerTypeSlice'
import {CarerTypeAvailability} from '../../api/carerApi'

 const CarerTypeAction = (data) => async dispatch => {

    try {
        dispatch(CarerTypePending());
        const result = await CarerTypeAvailability(data);
        if(result){
            return dispatch(CarerTypeSuccess(result))
        }
    } catch (error) {
        dispatch(CarerTypeFail(error))
    }
} 

export default CarerTypeAction