import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    clockInOverdueCarer: {},
    isLoadingClockInOverdue: false,
    errorClockInOverdue: ''
}

const clockInOverdueSlice = createSlice({
    name: 'clockInOverdueCarer',
    initialState,
    reducers:{
        clockInOverduePending: (state) => {
            state.isLoadingClockInOverdue = true;
        },
        clockInOverdueSuccess: (state, {payload}) => {
            state.isLoadingClockInOverdue = false;
            state.clockInOverdueCarer = payload;
            state.errorClockInOverdue = '';
        },
        clockInOverdueFail: (state, {payload}) => {
            state.isLoadingClockInOverdue = false;
            state.errorClockInOverdue = payload;
        }
    }
})

export const {clockInOverduePending, clockInOverdueSuccess, clockInOverdueFail} = clockInOverdueSlice.actions

export default clockInOverdueSlice.reducer