import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    getSelectedClients: {},
    isLoadinggetSelectedClients: false,
    errorgetSelectedClients: ''
}

const getSelectedClientsSlice = createSlice({
    name: 'getSelectedClients',
    initialState,
    reducers:{
        getSelectedClientsPending: (state) => {
            state.isLoadinggetSelectedClients = true;
        },
        getSelectedClientsSuccess: (state, {payload}) => {
            state.isLoadinggetSelectedClients = false;
            state.getSelectedClients = payload;
            state.errorgetSelectedClients = '';
        },
        getSelectedClientsFail: (state, {payload}) => {
            state.isLoadinggetSelectedClients = false;
            state.errorgetSelectedClients = payload;
        }
    }
})

export const {getSelectedClientsPending, getSelectedClientsSuccess, getSelectedClientsFail} = getSelectedClientsSlice.actions

export default getSelectedClientsSlice.reducer