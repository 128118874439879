import React from "react";
import ViewApplication from "./ViewApplication";
import getCarerList from "../carers/CarerActions/GetCarersAction";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "react-js-pagination";
import PageLoader from "../../components/PageLoader";

const CareWorkerApplications = () => {
  const dispatch = useDispatch();
  const { getCarers, isLoadingGetCarers } = useSelector(
    (state) => state.getCarersList
  );
  const {updatePersonalDetails, isLoadingupdatePersonalDetails, errorupdatePersonalDetails } = useSelector(
    (state) => state.UpdatePersonalInformation
  );
  const { updateHealthDeclaration} = useSelector((state) => state.UpdateHealthDeclaration);
  const { updateBackground} = useSelector((state) => state.UpdateBackground);
  const { updateComplianceStatus } = useSelector(
    (state) => state.complianceStatus
  );
  const {updateAvailability} = useSelector(
    (state) => state.UpdateAvailability
  );
  const itemsCountPerPage = 10;
  const [page, setPage] = React.useState(1);
  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };
  const handleDate = (date) => {
    let format = new Date(date);
    return ("0"+format.getDate()).slice(-2)+'/'+("0"+(format.getMonth()+1)).slice(-2)+'/'+format.getFullYear()+' '+format.getHours()+':'+format.getMinutes();
}
const handleName = (first, last) => {
  if (first !== null) return first + " " + last;
  return <div className="bg-orange-300 px-2 rounded-3xl">In Progress..</div>;
};

  React.useEffect(() => {
    dispatch(
      getCarerList({
        page,
        perPage: itemsCountPerPage,
        status: "PENDING",
      })
    );
  }, [page, updateComplianceStatus]);

  return (
    <div className="ml-72 mr-8 mt-10">
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
       {isLoadingGetCarers ? <PageLoader/> : <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                Name
              </th>
              <th scope="col" className="px-6 py-3">
                Gender
              </th>
              <th scope="col" className="px-6 py-3">
                Date of Application
              </th>
              <th scope="col" className="px-6 py-3">
                Address
              </th>
              <th scope="col" className="px-6 py-3">
                Mobile
              </th>
              <th scope="col" className="px-6 py-3">
                Email
              </th>
              <th scope="col" className="px-6 py-3">
                <span>Shift</span>
              </th>
              <th scope="col" className="px-6 py-3">
                <span className="sr-only">Edit</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {getCarers && getCarers.data && getCarers.data.length > 0 ? (
              getCarers.data.map(
                (carer) =>
                  carer.complianceStatus === "PENDING" &&
                   (
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-primary dark:hover:bg-gray-600">
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap"
                      >
                        {handleName(carer?.firstname, carer?.lastname)}
                      </th>
                      <td className="px-6 py-4">{carer.gender}</td>
                      <td className="px-6 py-4">{handleDate(carer.createdAt)}</td>
                      <td className="px-6 py-4">{carer.addressLine1 ? carer.addressLine1 :"-"}</td>
                      <td className="px-6 py-4">{carer.mobile ? carer.mobile : "-"}</td>
                      <td className="px-6 py-4">{carer.email}</td>
                      <td className="px-6 py-4">
                        {carer.availability && carer.availability.liveIn
                          ? "Live In"
                          : ""}{"-"}
                        {carer.availability && carer.availability.day
                          ? "Day"
                          : ""}{"-"}
                        {carer.availability && carer.availability.night
                          ? "Night"
                          : ""}
                      </td>
                      <td className="px-6 py-4 text-right">
                        <a
                          href="#"
                          className="font-medium text-secondary dark:text-blue-500 hover:underline"
                        >
                          <ViewApplication carer={carer} />
                        </a>
                      </td>
                    </tr>
                  )
              )
            ) : (
              <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                <td
                  colSpan="9"
                  className="text-center px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap border rounded bg-red-100"
                >
                  No Data Found
                </td>
              </tr>
            )}
          </tbody>
        </table>}
        <div className="">
          <div></div>
        </div>
      </div>
      {getCarers &&
        getCarers.data &&
        getCarers.data.length > 0 &&
        !isLoadingGetCarers && (
          <div className="flex justify-end">
            <Pagination
              activePage={page}
              itemsCountPerPage={itemsCountPerPage}
              totalItemsCount={getCarers.totalItemsCount}
              pageRangeDisplayed={1}
              onChange={handlePageChange}
              itemClass="page-item"
              linkClass="page-link"
            />
          </div>
        )}
    </div>
  );
};

export default CareWorkerApplications;
