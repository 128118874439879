import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PageLoader from "../../components/PageLoader";
import fetchClientTier from "./ConfigActions/GetClientTierAction";
import Pagination from "react-js-pagination";

function ClientTierList({ setStore }) {
  const dispatch = useDispatch();
  const itemsCountPerPage = 5;

  const { getClientTiers, isLoadingGetClientTier } = useSelector(
    (state) => state.getClientTier
  );
  const { updateClientTiers } = useSelector((state) => state.updateClientTier);
  const { addClientTiers } = useSelector((state) => state.addClientTier);

  const [page, setPage] = React.useState(1);

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  React.useEffect(() => {
    dispatch(
      fetchClientTier({
        page,
        perPage: itemsCountPerPage,
      })
    );
  }, [page, updateClientTiers, addClientTiers]);

  const handleEdit = (tier) => {
    setStore(tier);
  };

  let content = isLoadingGetClientTier ? (
    <PageLoader />
  ) : (
    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
      <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
        <tr>
          <th scope="col" className="px-6 py-3">
            Tier
          </th>
          <th scope="col" className="px-6 py-3">
            <span className="sr-only">Edit</span>
          </th>
        </tr>
      </thead>
      <tbody>
        {getClientTiers &&
        getClientTiers.data &&
        getClientTiers.data.length > 0 ? (
          getClientTiers.data.map((tier) => (
            <tr
              key={tier.id}
              className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-primary dark:hover:bg-gray-600"
            >
              <th
                scope="row"
                className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap"
              >
                {tier.type}
              </th>
              <td className="px-6 py-4 text-right">
                <button
                  onClick={() => handleEdit(tier)}
                  className="font-medium text-secondary dark:text-blue-500 hover:underline"
                >
                  Edit
                </button>
              </td>
            </tr>
          ))
        ) : (
          <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
            <td
              colSpan="9"
              className="text-center px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap border rounded bg-red-100"
            >
              No Data Found
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );

  return (
    <div className="p-2">
      <div className="relative overflow-x-auto border sm:rounded-lg ">
        {content}
      </div>
      <div>
        {getClientTiers &&
          getClientTiers.data &&
          getClientTiers.data.length > 0 &&
          !isLoadingGetClientTier && (
            <div className="flex justify-end">
              <Pagination
                activePage={page}
                itemsCountPerPage={itemsCountPerPage}
                totalItemsCount={getClientTiers.totalItemsCount}
                pageRangeDisplayed={1}
                onChange={handlePageChange}
                itemClass="page-item"
                linkClass="page-link"
              />
            </div>
          )}
      </div>
    </div>
  );
}

export default ClientTierList;
