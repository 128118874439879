import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    editClientContacts: {},
    isLoadingContacts: false,
    errorEditContacts: ''
}

const editContactsSlice = createSlice({
    name: 'editClientContacts',
    initialState,
    reducers:{
        editContactsPending: (state) => {
            state.isLoadingContacts = true;
        },
        editContactsSuccess: (state, {payload}) => {
            state.isLoadingContacts = false;
            state.editClientContacts = payload;
            state.errorEditContacts = '';
        },
        editContactsFail: (state, {payload}) => {
            state.isLoadingContacts = false;
            state.errorEditContacts = payload;
        }
    }
})

export const {editContactsPending, editContactsSuccess, editContactsFail} = editContactsSlice.actions

export default editContactsSlice.reducer