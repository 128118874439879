import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  TermsAgreement: {},
  isLoadingTermsAgreement: false,
  errorTermsAgreement: "",
};

const TermsAgreementSlice = createSlice({
  name: "TermsAgreement",
  initialState,
  reducers: {
    TermsAgreementPending: (state) => {
      state.isLoadingTermsAgreement = true;
    },
    TermsAgreementSuccess: (state, { payload }) => {
      state.isLoadingTermsAgreement = false;
      state.TermsAgreement = payload;
      state.errorTermsAgreement = "";
    },
    TermsAgreementFail: (state, { payload }) => {
      state.isLoadingTermsAgreement = false;
      state.errorTermsAgreement = payload;
    },
  },
});

export const { TermsAgreementPending, TermsAgreementSuccess, TermsAgreementFail } =
  TermsAgreementSlice.actions;

export default TermsAgreementSlice.reducer;
