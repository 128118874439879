import axios from "axios";

export const addMessage = async (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = sessionStorage.getItem('access_token')
            const res = await axios.post(`${process.env.REACT_APP_ELMS_API_URL}messages/admin`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            resolve(res.data);

        } catch (error) {
            reject(error)
        }
    })
}

export const addMessageReply = async (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = sessionStorage.getItem('access_token')
            const res = await axios.post(`${process.env.REACT_APP_ELMS_API_URL}messages/reply/${data.id}`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            resolve(res.data);

        } catch (error) {
            reject(error)
        }
    })
}
export const getMessages = async (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = sessionStorage.getItem('access_token')
            const res = await axios.get(`${process.env.REACT_APP_ELMS_API_URL}messages?clientId=${data.clientId}&page=${data.page}&perPage=${data.perPage}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            resolve(res.data);

        } catch (error) {
            if(error.response.data.statusCode === 404){
                return null
             }
            
             reject(error)
        }
    })
}


