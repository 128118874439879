import {assignClientPending, assignClientSuccess, assignClientFail} from '../staffSlices/assignClientsSlice'
import {assignClient} from '../../../api/staffApi'

 const assignClientAction = (data) => async dispatch => {

    try {
        dispatch(assignClientPending());
        const result = await assignClient(data);
        if(result){
            return dispatch(assignClientSuccess(result))
        }
    } catch (error) {
        dispatch(assignClientFail(error))
    }
}
export default assignClientAction