import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    getTimesheets: {},
    isLoadingGetTimesheets: false,
    errorGetTimesheets: ''
}

const getTimesheetsSlice = createSlice({
    name: 'getTimesheets',
    initialState,
    reducers:{
        getTimesheetsPending: (state) => {
            state.isLoadingGetTimesheets = true;
        },
        getTimesheetsSuccess: (state, {payload}) => {
            state.isLoadingGetTimesheets = false;
            state.getTimesheets = payload;
            state.errorGetTimesheets = '';
        },
        getTimesheetsFail: (state, {payload}) => {
            state.isLoadingGetTimesheets = false;
            state.errorGeTimesheets = payload;
        }
    }
})

export const {getTimesheetsPending, getTimesheetsSuccess, getTimesheetsFail} = getTimesheetsSlice.actions

export default getTimesheetsSlice.reducer