import React, { useState } from "react";
import { BsFillEyeFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import getClientMarchart from "../ClientActions/ClientMarchartsAction";
import MarchartAuditTrail from "./MarChartAuditTrail";
import ViewClientMarchart from "./ViewMarchart";
import PageLoader from "../../../components/PageLoader";

export default function EditMarChart({ client }) {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = React.useState(false);

  // Inside your component function
  const [searchQuery, setSearchQuery] = useState("");

  // Function to handle search query change
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
  // addClientCarePlans
  const { getClientMarcharts, isLoadingClientMarcharts } = useSelector(
    (state) => state.getclientmarcharts
  );

  const { addClientCarePlans } = useSelector(
    (state) => state.addClientCarePlan
  );

  const { editPrescription, isLoadingEditPres } = useSelector(
    (state) => state.editPrescriptionDoc
  );
  const { editCarePlan, isLoadingCarePlan, errorEditPlan } = useSelector(
    (state) => state.updateCarePlans
  );
  React.useEffect(() => {
    dispatch(
      getClientMarchart({
        clientId: client.id,
        code: "CP06",
      })
    );
  }, [editPrescription, editCarePlan, addClientCarePlans]);

  const handleOpen = (id) => {
    setShowModal(true);
  };

  const current =
    getClientMarcharts && Array.isArray(getClientMarcharts.data)
      ? getClientMarcharts.data[0]
      : null;

  // const alphabet = getClientMarcharts.data && Array.isArray(getClientMarcharts.data) ? getClientMarcharts.data.sort() :[]
  // console.log('alphabet',alphabet);
  // console.log("getClientMarcharts", getClientMarcharts);

  const [active, setActive] = React.useState(current?.id);

  const getOnlyArray = (active) => {
    // this will return only item which match the provided id
    return getClientMarcharts && Array.isArray(getClientMarcharts.data)
      ? getClientMarcharts.data.filter((s) => s.id === active)
      : null;
  };
  const obj = getOnlyArray(active);
  // console.log('obj',obj);
  const e = obj && Array.isArray(obj) ? obj[0] : null;
  // console.log('e',e);
  let content;
  const id = e ? e.id : null;
  switch (active) {
    case id:
      content = (
        <ViewClientMarchart e={e} setShowModal={setShowModal} client={client} />
      );
      break;
    case "medication-audit":
      content = <MarchartAuditTrail e={client.id}  client={client}/>;
      break;
    default:
      setActive("medication-audit");
      content = <MarchartAuditTrail e={client.id} client={client}/>;
      break;
  }
  return (
    <>
      {
        <button
          className=" text-gray-600 bg-white shadow mt-2 hover:bg-white inline-flex hover:text-gray-700 hover:border hover:border-gray-300 hover:underline decoration-secondary focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-3 mr-1 py-1 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          type="button"
          onClick={() => handleOpen()}
        >
          View <BsFillEyeFill className="ml-1 mt-0.5 text-secondary" />
        </button>
      }
      {showModal ? (
        <>
          <div className="justify-center items-center overflow-x-auto overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-8xl">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-xl font-semibold text-gray-500"></h3>
                  <button
                    className="p-1 ml-10 bg-transparent border-0 text-button float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    ×
                  </button>
                </div>

               

                <div className="relative p-6 flex-auto h-full">
               
                  <div className="relative p-3 flex-auto text-gray-700">
                  <div className="flex justify-between">
                    <div></div>
               <input
                  className="py-2 px-10 rounded-lg border mb-2"
                  type="text"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  placeholder="Search Medications..."
                /> 
               </div>
                    <div className="bg-white ">
                      <div className="flex justify-between border rounded bg-orange-50">
                        <div>
                          <ul className="flex flex-wrap font-medium p-2">
                            <li className="mr-2">
                              <button
                                onClick={() => setActive("medication-audit")}
                                className={`inline-block p-4 focus:outline-none rounded-t-lg border-b-2 font-semibold${
                                  active === "medication-audit"
                                    ? "border-secondary text-white dark:text-button dark:border-button bg-button font-semibold"
                                    : "border-transparent hover:text-button hover:border-button dark:hover:text-gray-300 font-bold bg-red-200" 
                                }`}
                              >
                               MEDICATION REPORTS |
                              </button>
                            </li>
                            {isLoadingClientMarcharts ? (
                              <PageLoader />
                            ) : (getClientMarcharts &&
                              getClientMarcharts?.data?.filter((marchart) =>
                                  marchart.medication
                                    .toLowerCase()
                                    .includes(searchQuery.toLowerCase())
                                )
                                .map((marchart) => (
                                  <li className="mr-2" key={marchart.id}>
                                    <button
                                      onClick={() => setActive(marchart.id)}
                                      className={`inline-block p-4 focus:outline-none rounded-t-lg border-b-2 ${
                                        active === marchart.id
                                          ? "border-secondary text-white dark:text-button dark:border-button bg-orange-500 font-semibold"
                                          : "border mb-2 hover:text-button hover:border-button dark:hover:text-gray-300 font-semibold"
                                      }`}
                                    >
                                      {marchart.medication}
                                    </button>
                                  </li>
                                ))
                            )}
                          </ul>
                        </div>
                      </div>
                      <div className="">{content}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
