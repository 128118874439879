import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    assignRegulator: {},
    isLoadingassignRegulator: false,
    errorassignRegulator: ''
}

const assignRegulatorSlice = createSlice({
    name: 'assignRegulator',
    initialState,
    reducers:{
        assignRegulatorPending: (state) => {
            state.isLoadingassignRegulator = true;
        },
        assignRegulatorSuccess: (state, {payload}) => {
            state.isLoadingassignRegulator = false;
            state.assignRegulator = payload;
            state.errorassignRegulator = '';
        },
        assignRegulatorFail: (state, {payload}) => {
            state.isLoadingassignRegulator = false;
            state.errorassignRegulator = payload;
        }
    }
})

export const {assignRegulatorPending, assignRegulatorSuccess, assignRegulatorFail} = assignRegulatorSlice.actions

export default assignRegulatorSlice.reducer