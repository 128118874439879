import { getMedicationAudit } from "../../../api/medicationApi";
import { getMedicationAuditFail, getMedicationAuditPending, getMedicationAuditSuccess } from "../ClientSlices/ClientMedicationAuditTrailSlice";


const getClientMedicationAudit = (data) => async dispatch => {

    try {
        dispatch(getMedicationAuditPending());
        const result = await getMedicationAudit(data);
        if(result){
            return dispatch(getMedicationAuditSuccess(result))
        }
    } catch (error) {
        dispatch(getMedicationAuditFail(error))
    }
} 

export default getClientMedicationAudit