import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    addCare: {},
    isLoadingCarePlan: false,
    errorCarePlan: ''
}

const addCarePlanSlice = createSlice({
    name: 'addCare',
    initialState,
    reducers:{
        addCarePlanPending: (state) => {
            state.isLoadingCarePlan = true;
        },
        addCarePlanSuccess: (state, {payload}) => {
            state.isLoadingCarePlan = false;
            state.addCare = payload;
            state.errorCarePlan = '';
        },
        addCarePlanFail: (state, {payload}) => {
            state.isLoadingCarePlan = false;
            state.errorCarePlan = payload;
        }
    }
})

export const {addCarePlanPending, addCarePlanSuccess, addCarePlanFail} = addCarePlanSlice.actions

export default addCarePlanSlice.reducer