import {sendInvitePending, sendInviteSuccess, sendInviteFail} from '../RegisterCarerSlices/SendCarerInviteSlice'
import {SendInvitation} from '../../api/carerApi'

 const SendCarerInviteAction = (data) => async dispatch => {

    try {
        dispatch(sendInvitePending());
        const result = await SendInvitation(data);
        if(result){
            return dispatch(sendInviteSuccess(result))
        }
    } catch (error) {
        dispatch(sendInviteFail(error))
    }
} 

export default SendCarerInviteAction