import {clockInOverduePending, clockInOverdueSuccess, clockInOverdueFail} from '../DashboardSlices/ClockInOverdueSlice'
import {getClockInOverdue} from '../../../api/carerApi'

 const ClockInOverdueAction = (data) => async dispatch => {

    try {
        dispatch(clockInOverduePending());
        const result = await getClockInOverdue(data);
        if(result){
            return dispatch(clockInOverdueSuccess(result))
        }
    } catch (error) {
        dispatch(clockInOverdueFail(error))
    }
} 

export default ClockInOverdueAction