import {disabilityChecklistPending, disabilityChecklistSuccess, disabilityChecklistFail} from '../RegisterCarerSlices/DisabilitySlice'
import {DisabilityChecklist} from '../../api/carerApi'

 const DisabilityAction = (data) => async dispatch => {

    try {
        dispatch(disabilityChecklistPending());
        const result = await DisabilityChecklist(data);
        if(result){
            return dispatch(disabilityChecklistSuccess(result))
        }
    } catch (error) {
        dispatch(disabilityChecklistFail(error))
    }
} 

export default DisabilityAction