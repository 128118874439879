import React from "react";
import CarerDetails from "./View/CarerDetails";
import Availability from "./View/Availability";
import HealthDeclaration from "./View/HealthDeclaration";
import EducationTraining from "./View/EducationTraining";
import EmploymentHistory from "./View/EmploymentHistory";
import NextOfKin from "./View/NextOfKin";
import TrainingHistory from "./View/TrainingHistory";
import Documents from "./View/Documents";
import { useDispatch, useSelector } from "react-redux";
import editComplianceStatus from "../carers/CarerActions/UpdateComplianceStatusAction";
import { ImCheckmark, ImBlocked } from "react-icons/im";
import AuthAction from "../carers/RegisterCarerActions/AuthAction";
import CarerBackground from "../carers/AddCarer/CarerBackground";
import GetCarerInfoAction from "../carers/RegisterCarerActions/GetCarerInfoAction";
import CarerOptAgreement from "../carers/AddCarer/CarerOptAgreement"
import CarerDataConsent from "../carers/AddCarer/CarerDataConsent";

const ViewApplication = (carer) => {
 
  const [showModal, setShowModal] = React.useState(false);
  const { updateComplianceStatus } = useSelector(
    (state) => state.complianceStatus
  );
  const {Login, isLoading, error } = useSelector((state) => state.CarerToken);
  const { user } = useSelector((state) => state.user);

  

  const rol = user ? user.role : "";
  const dispatch = useDispatch();
  const handleOpen = (id) => {
    setShowModal(true);

  };


  const [active, setActive] = React.useState("personal");


  React.useEffect(() => {
  
  }, [updateComplianceStatus]);



  let content;

  switch (active) {
    case "personal":
      content = <CarerDetails carer={carer} setShowModal={setShowModal} />;
      break;
    case "availability":
      content = <Availability carer={carer} setShowModal={setShowModal} />;
      break;
      case "background":
        content = <CarerBackground carer={carer} setShowModal={setShowModal} />;
        break;
    case "health":
      content = <HealthDeclaration carer={carer} setShowModal={setShowModal} />;
      break;
    case "education":
      content = <EducationTraining carer={carer} setShowModal={setShowModal} />;
      break;
    case "employment":
      content = <EmploymentHistory carer={carer} setShowModal={setShowModal} />;
      break;
    case "kin":
      content = <NextOfKin carer={carer} setShowModal={setShowModal} />;
      break;
    case "history":
      content = <TrainingHistory carer={carer} setShowModal={setShowModal} />;
      break;
    case "documents":
      content = <Documents carer={carer} setShowModal={setShowModal} />;
      break;
      case "opt":
        content = <CarerOptAgreement carer={carer} setShowModal={setShowModal} />;
        break;
      case "consent":
        content = <CarerDataConsent carer={carer} setShowModal={setShowModal} />;
       break;
    default:
      break;
  }

  const handleCompliant = async (event) => {
    const data = {
      id: carer.carer.id,
      complianceStatus: event,
    };
    dispatch(editComplianceStatus(data));
    setShowModal(false);
  };

  return (
    <>
      <button
        className=" text-white bg-button border hover:bg-white hover:text-gray-700 hover:border hover:border-gray-300 hover:underline decoration-secondary focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-1 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        type="button"
        onClick={() => handleOpen(carer.id)}
      >
        View More
      </button>
      {showModal ? (
        <>
          <div className="justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto  mx-auto max-w-7xl">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  {rol === 'REGULATOR' ? <h3 className="text-xl font-semibold text-gray-500">
                    {carer
                      ? <span className="text-green">{carer.carer.firstname + " " + carer.carer.lastname}</span>
                      : ""}
                    `s Profile
                  </h3> :
                  <h3 className="text-xl font-semibold text-gray-500">
                  {carer
                    ? <span className="text-green">{carer.carer.firstname + " " + carer.carer.lastname}</span>
                    : ""}
                  `s Application
                </h3>}
                
                 {rol !== 'REGULATOR' && <div className="inline-flex space-x-5">
                    <div>
                      <button
                        className="bg-gray-600 p-2 px-16 text-white border rounded font-semibold inline-flex hover:bg-gray-500 hover:text-white"
                        onClick={() => handleCompliant("COMPLIANT")}
                      >
                      MARK AS COMPLIANT 
                      {/* <ImCheckmark size={20} className=' ml-2'/> */}
                      </button>
                    </div>
                    {/* <span className="mt-4 text-gray-700">.... OR ....</span> */}
                    <div>
                     <button
                        className="bg-red-700 p-2 px-10 text-white rounded font-semibold inline-flex hover:bg-gray-500 hover:text-red-400"
                        onClick={() => handleCompliant("NOT-COMPLIANT")}
                      >
                      MARK AS NON-COMPLIANT 
                      {/* <ImBlocked size={20} className=' ml-2'/> */}
                      </button>
                    </div>
                    <button
                    className="p-1 ml-10 bg-transparent border-0 text-button float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    × 
                  </button>
                  </div>}

                 
                </div>
                <div className="relative p-6 flex-auto">
                  <div className="border p-1 rounded bg-orange-50">
                    <div>
                      <ul className="flex flex-wrap">
                        <li className="mr-1">
                          <button
                            onClick={() => setActive("personal")}
                            className={`inline-block p-4 focus:outline-none rounded-t-lg border-b-2 ${
                              active === "personal"
                                ? "border-secondary text-white font-medium dark:text-button dark:border-button bg-orange-500":
                                  "border hover:text-button hover:border-button text-gray-500  dark:hover:text-gray-300 hover:bg-gray-50 hover:border-b-secondary"
                            }`}
                          >
                            Personal Details
                          </button>
                        </li>
                        <li className="mr-1">
                          <button
                            onClick={() => setActive("kin")}
                            className={`inline-block p-4 focus:outline-none rounded-t-lg border-b-2 ${
                              active === "kin"
                                ? "border-secondary text-white font-medium dark:text-button dark:border-button bg-orange-500"
                                : "border hover:text-button hover:border-button text-gray-500  dark:hover:text-gray-300 hover:bg-gray-50 hover:border-b-secondary"
                            }`}
                          >
                            Next Of Kin
                          </button>
                        </li>
                        <li className="mr-1">
                          <button
                            onClick={() => setActive("availability")}
                            className={`inline-block p-4 focus:outline-none rounded-t-lg border-b-2 ${
                              active === "availability"
                                ? "border-secondary text-white font-medium bg-orange-500 dark:text-button dark:border-button"
                                : "border hover:text-button hover:border-button text-gray-500  dark:hover:text-gray-300 hover:bg-gray-50 hover:border-b-secondary"
                            }`}
                          >
                            Availability
                          </button>
                        </li>
                        <li className="mr-1">
                          <button
                            onClick={() => setActive("background")}
                            className={`inline-block p-4 focus:outline-none rounded-t-lg border-b-2 ${
                              active === "background"
                                ? "border-secondary text-white font-medium bg-orange-500 dark:text-button dark:border-button"
                                : "border hover:text-button hover:border-button text-gray-500  dark:hover:text-gray-300 hover:bg-gray-50 hover:border-b-secondary"
                            }`}
                          >
                            Background
                          </button>
                        </li>
                        <li className="mr-1">
                          <button
                            onClick={() => setActive("health")}
                            className={`inline-block p-4 focus:outline-none rounded-t-lg border-b-2 ${
                              active === "health"
                                ? "border-secondary text-white font-medium bg-orange-500 dark:text-button dark:border-button"
                                : "border hover:text-button hover:border-button text-gray-500  dark:hover:text-gray-300 hover:bg-gray-50 hover:border-b-secondary"
                            }`}
                          >
                            Health Declaration
                          </button>
                        </li>
                        <li className="mr-1">
                          <button
                            onClick={() => setActive("education")}
                            className={`inline-block p-4 focus:outline-none rounded-t-lg border-b-2 ${
                              active === "education"
                                ? "border-secondary text-white font-medium bg-orange-500 dark:text-button dark:border-button"
                                : "border hover:text-button hover:border-button text-gray-500  dark:hover:text-gray-300 hover:bg-gray-50 hover:border-b-secondary"
                            }`}
                          >
                            Education&Training
                          </button>
                        </li>
                        <li className="mr-1">
                          <button
                            onClick={() => setActive("employment")}
                            className={`inline-block p-4 focus:outline-none rounded-t-lg border-b-2 ${
                              active === "employment"
                                ? "border-secondary text-white font-medium bg-orange-500 dark:text-button dark:border-button"
                                : "border hover:text-button hover:border-button text-gray-500  dark:hover:text-gray-300 hover:bg-gray-50 hover:border-b-secondary"
                            }`}
                          >
                            Employment History
                          </button>
                        </li>
                        <li className="mr-1">
                          <button
                            onClick={() => setActive("history")}
                            className={`inline-block p-4 focus:outline-none rounded-t-lg border-b-2 ${
                              active === "history"
                                ? "border-secondary text-white font-medium bg-orange-500 dark:text-button dark:border-button"
                                : "border hover:text-button hover:border-button text-gray-500  dark:hover:text-gray-300 hover:bg-gray-50 hover:border-b-secondary"
                            }`}
                          >
                            Training
                          </button>
                        </li>
                        <li className="mr-1">
                          <button
                            onClick={() => setActive("documents")}
                            className={`inline-block p-4 focus:outline-none rounded-t-lg border-b-2 ${
                              active === "documents"
                                ? "border-secondary text-white font-medium bg-orange-500 dark:text-button dark:border-button"
                                : "border hover:text-button hover:border-button text-gray-500  dark:hover:text-gray-300 hover:bg-gray-50 hover:border-b-secondary"
                            }`}
                          >
                            Documents
                          </button>
                        </li>
                        <li className="mr-1">
                          <button
                            onClick={() => setActive("opt")}
                            className={`inline-block p-4 focus:outline-none rounded-t-lg border-b-2 ${
                              active === "opt"
                                ? "border-secondary text-white font-medium bg-orange-500 dark:text-button dark:border-button"
                                : "border hover:text-button hover:border-button text-gray-500  dark:hover:text-gray-300 hover:bg-gray-50 hover:border-b-secondary"
                            }`}
                          >
                            Opt Agreement
                          </button>
                        </li>
                        <li className="mr-1">
                          <button
                            onClick={() => setActive("consent")}
                            className={`inline-block p-4 focus:outline-none rounded-t-lg border-b-2 ${
                              active === "consent"
                                ? "border-secondary text-white font-medium bg-orange-500 dark:text-button dark:border-button"
                                : "border hover:text-button hover:border-button text-gray-500  dark:hover:text-gray-300 hover:bg-gray-50 hover:border-b-secondary"
                            }`}
                          >
                            Personal Data Consent
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="text-gray-500">{content}</div>
              </div>
            </div>
          </div>

          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default ViewApplication;
