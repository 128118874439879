import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    clientContracts: {},
    isLoadingClientContracts: false,
    errorClientContracts: ''
}

const clientContractsSlice = createSlice({
    name: 'clientContracts',
    initialState,
    reducers:{
        clientContractsPending: (state) => {
            state.isLoadingClientContracts = true;
        },
        clientContractsSuccess: (state, {payload}) => {
            state.isLoadingClientContracts = false;
            state.clientContracts = payload;
            state.errorClientContracts = '';
        },
        clientContractsFail: (state, {payload}) => {
            state.isLoadingClientContracts = false;
            state.errorClientContracts = payload;
        }
    }
})

export const {clientContractsPending, clientContractsSuccess, clientContractsFail} = clientContractsSlice.actions

export default clientContractsSlice.reducer