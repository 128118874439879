import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    addReference: {},
    isLoadingAddReference: false,
    errorAddReference: ''
}

const addReferenceSlice = createSlice({
    name: 'addReference',
    initialState,
    reducers:{
        addReferencePending: (state) => {
            state.isLoadingAddReference = true;
        },
        addReferenceSuccess: (state, {payload}) => {
            state.isLoadingAddReference = false;
            state.addReference = payload;
            state.errorAddReference = '';
        },
        addReferenceFail: (state, {payload}) => {
            state.isLoadingAddReference = false;
            state.errorAddReference = payload;
        }
    }
})

export const {addReferencePending, addReferenceSuccess, addReferenceFail} = addReferenceSlice.actions

export default  addReferenceSlice.reducer