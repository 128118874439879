import React from 'react'
import ViewApplication from './ViewApplication'
import getStaffDbsApplicationList  from './DbsActions/GetDbsAction'
import { useDispatch, useSelector } from 'react-redux'
import PageLoader from '../../components/PageLoader'


function StaffDbs() {

    const dispatch = useDispatch();
    const {getStaffDbsApplications, isLoadingGetCarers} = useSelector(state => state.getStaffDbsApplicationList);
   
    const itemsCountPerPage = 5;
    const [page, setPage] = React.useState(1);
    const handlePageChange = (pageNumber) => {
       setPage(pageNumber);
   }
    
   
   React.useEffect(() => {
       dispatch(getStaffDbsApplicationList({
           page,
           perPage: itemsCountPerPage           
       }))
   
   }, [page]);   
   const list = getStaffDbsApplications;
  return (
    <div className='ml-72 mr-8 mt-10'>
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
       {isLoadingGetCarers ? <PageLoader/> : <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                    <th scope="col" className="px-6 py-3">
                        Name
                    </th>
                    <th scope="col" className="px-6 py-3">
                        Date of Application
                    </th>
                    <th scope="col" className="px-6 py-3">
                        Address
                    </th>
                    <th scope="col" className="px-6 py-3">
                        Mobile
                    </th>
                    <th scope="col" className="px-6 py-3">
                        Type
                    </th>
                    <th scope="col" className="px-6 py-3">
                        <span className="sr-only">Edit</span>
                    </th>
                </tr>
            </thead>
            <tbody>
              {list && list.data && list.data.length > 0 ? (
                  <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-primary dark:hover:bg-gray-600">
                  <th scope="row" className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                     {}
                  </th>
                  <td className="px-6 py-4">
                      23 June, 2022
                  </td>
                  <td className="px-6 py-4">
                      1010 Cambourne Business Park
                  </td>
                  <td className="px-6 py-4">
                      +44 737645227
                  </td>
                  <td className="px-6 py-4">
                      DBS Application
                  </td>
                  <td className="px-6 py-4 text-right">
                      <a href="#" className="font-medium text-secondary dark:text-blue-500 hover:underline"><ViewApplication /></a>
                  </td>
              </tr>
              ): <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
              <td colSpan="9" className="text-center px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap border rounded bg-red-100">No Data Found</td>
          </tr>}
              
            </tbody>
        </table>}
    </div>
</div>
  )
}

export default StaffDbs