import React from "react";
import { Sidebar } from "../../layouts/auth/Sidebar";
import { TopNavigation } from "../../layouts/auth/TopNavigation";
import FormikController from "../../components/Formik/FormikController";
import SubmitButton from "../../components/Formik/SubmitButton";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import updateMyAccount from "./accountAction";
import { getUserProfile } from "../../pages/dashboard/userAction";
import PageLoader from "../../components/PageLoader";
import ChangePassword from "./ChangePassword";

const Account = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { isLoadingMyAccount, errorMyAccount } = useSelector(
    (state) => state.updateAccount
  );


  React.useEffect(() => {
    dispatch(getUserProfile());
  }, []);

  const initialValues = {
    firstname: user ? user.firstname : "",
    lastname: user ? user.lastname : "",
    middlename: user ? user.middlename : "",
    email: user ? user.email : "",
    mobile: user ? user.mobile : "",
    role: user.role,
    id: user ? user.id : "",
  };

  const validationSchema = Yup.object({
    firstname: Yup.string().required("This field is required"),
    lastname: Yup.string().required("This field is required"),
    mobile: Yup.string().required("This field is required"),
    email: Yup.string()
      .email("Enter valid email address")
      .required("This field is required"),
  });

  const handleSubmit = (values) => {
    dispatch(updateMyAccount(values));
  };

  const Head = (props) => {
    return (
      <div className="border-b mb-5 text-left">
        <label className="text-xl font-medium text-orange-900">
          {props.title}
        </label>
      </div>
    );
  };
  return (
    <div>
      <Sidebar />
      <TopNavigation title="My Account" />
      <div className=" h-screen">
        <div className="ml-72 mr-8">
          <div className="grid grid-cols-12 gap-4">
            <div className="col-span-7 ">
              <div className="relative flex-auto text-gray-700 border p-5 rounded mt-10">
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={(values) => {
                    handleSubmit(values);
                  }}
                >
                  {() => (
                    <Form className="mt-2">
                      {errorMyAccount && (
                        <div
                          className="bg-red-100 border mb-5 text-center border-red-200 text-red-700 px-4 py-3 rounded relative"
                          role="alert"
                        >
                          <span className="block sm:inline">
                            {errorMyAccount.response.data.message}
                          </span>
                          <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                            {/* <svg className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" /></svg> */}
                          </span>
                        </div>
                      )}
                      <div className="">
                        <Head title="Personal Details" />
                      </div>
                      <div className="grid grid-cols-2 gap-2">
                        <div className="">
                          <FormikController
                            control="input"
                            type="text"
                            name="role"
                            label="Role"
                            disabled
                          />
                        </div>
                        <div className="">
                          <FormikController
                            control="input"
                            type="text"
                            name="firstname"
                            label="First name"
                          />
                        </div>
                        <div>
                          <FormikController
                            control="input"
                            type="text"
                            name="middlename"
                            label="Middle name"
                          />
                        </div>

                        <div>
                          <FormikController
                            control="input"
                            type="text"
                            name="lastname"
                            label="Last name"
                          />
                        </div>
                        <div>
                          <FormikController
                            control="input"
                            type="email"
                            name="email"
                            label="Email address"
                          />
                        </div>
                        <div>
                          <FormikController
                            control="input"
                            type="text"
                            name="mobile"
                            label="Mobile number"
                          />
                        </div>
                        <div></div>
                        <div className="mb-3 pt-1">
                          {isLoadingMyAccount ? (
                            <PageLoader />
                          ) : (
                            <SubmitButton label="Update" />
                          )}
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
            <div className="col-span-5">
              <ChangePassword />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Account;
