import {GetCarerInfoPending, GetCarerInfoSuccess, GetCarerInfoFail} from '../RegisterCarerSlices/GetCarerInfoSlice'
import {getCarerInfo} from '../../../../src/api/carerApi'

 const GetCarerInfoAction = (data) => async dispatch => {

    try {
        dispatch(GetCarerInfoPending());
        const result = await getCarerInfo(data);
        if(result){
            return dispatch(GetCarerInfoSuccess(result))
        }
    } catch (error) {
        dispatch(GetCarerInfoFail(error))
    }
} 

export default GetCarerInfoAction