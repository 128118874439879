import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    updateStatus: {},
    isLoadingStatusUpdate: false,
    errorStatusUpdate: ''
}

const updateStatusSlice = createSlice({
    name: 'updateStatus',
    initialState,
    reducers:{
        updateStatusPending: (state) => {
            state.isLoadingStatusUpdate = true;
        },
        updateStatusSuccess: (state, {payload}) => {
            state.isLoadingStatusUpdate = false;
            state.updateStatus = payload;
            state.errorStatusUpdate = '';
        },
        updateStatusFail: (state, {payload}) => {
            state.isLoadingStatusUpdate = false;
            state.errorStatusUpdate = payload;
        }
    }
})

export const {updateStatusPending, updateStatusSuccess, updateStatusFail} = updateStatusSlice.actions

export default updateStatusSlice.reducer