import {updateTrainingPending, updateTrainingSuccess, updateTrainingFail} from '../RegisterCarerSlices/UpdateTrainingSlice'
import {UpdateTraining} from '../../api/carerApi'

 const UpdateTrainingAction = (data) => async dispatch => {

    try {
        dispatch(updateTrainingPending());
        const result = await UpdateTraining(data);
        if(result){
            return dispatch(updateTrainingSuccess(result))
        }
    } catch (error) {
        dispatch(updateTrainingFail(error))
    }
} 

export default UpdateTrainingAction