import { getMessages } from "../../../api/messagesApi";
import { getMessagesFail, getMessagesPending, getMessagesSuccess } from "../DashboardSlices/GetMessagesSlice";

 const getMessageList = (data) => async dispatch => {

    try {
        dispatch(getMessagesPending());
        const result = await getMessages(data); 
        if(result){
            return dispatch(getMessagesSuccess(result))
        }
    } catch (error) {
        dispatch(getMessagesFail(error))
    }
} 

export default getMessageList