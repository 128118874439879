import { getAudit } from "../../../api/auditApi";
import { getAuditFail, getAuditPending, getAuditSuccess } from "../ClientSlices/AuditTrailSlice";


const getAudits = (data) => async dispatch => {

    try {
        dispatch(getAuditPending());
        const result = await getAudit(data);
        if(result){
            return dispatch(getAuditSuccess(result))
        }
    } catch (error) {
        dispatch(getAuditFail(error))
    }
} 

export default getAudits