import { render } from "@testing-library/react";
import axios from "axios";
import { Navigate } from "react-router-dom";
import FlashMessage from 'react-flash-message'

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const getCarers = async (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = sessionStorage.getItem('access_token')
           
            const res = await axios.get(`${process.env.REACT_APP_ELMS_API_URL}users?page=${data?.page}&perPage=${data?.perPage}&status=${data?.status}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            resolve(res.data);

        } catch (error) {
            if(error.response?.data?.statusCode === 404){
                return null
             }
             if(error.response?.data?.statusCode === 401){
                render(
                    <FlashMessage duration={5000}>
                        <strong>Unauthorized Access! Please login to access the page!!</strong>
                    </FlashMessage>,
                    document.body
                );
                <Navigate replace to="/login"/>
             }
            
            
             reject(error)
        }
    })
}


export const getCarersPlacement = async (data) => {
    return new Promise(async (resolve, reject) => {
        try {

            const token = sessionStorage.getItem('access_token')         
            const res = await axios.get(`${process.env.REACT_APP_ELMS_API_URL}users/carers/onplacement?page=${data?.page}&perPage=${data?.perPage}&status=${data?.status}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            resolve(res.data);

        } catch (error) {
            if(error.response?.data?.statusCode === 404){
                return null
             }

             if(error.response?.data?.statusCode === 401){
                <Navigate replace to="/login"/>
             }
            
            
             reject(error)
        }
    })
}

export const getCarersWaitingPlacement = async (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = sessionStorage.getItem('access_token')
            const res = await axios.get(`${process.env.REACT_APP_ELMS_API_URL}users/carers/waiting-placement?page=${data?.page}&perPage=${data?.perPage}&status=${data?.status}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            resolve(res.data);

        } catch (error) {
            if(error.response?.data?.statusCode === 404){
                return null
             }
             if(error.response?.data?.statusCode === 401){
                <Navigate replace to="/login"/>
             }
            
            
             reject(error)
        }
    })
}

export const getClockInOverdue = async (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = sessionStorage.getItem('access_token')
            const res = await axios.get(`${process.env.REACT_APP_ELMS_API_URL}shifts/carer/clockin/overdue?page=${data?.page}&perPage=${data?.perPage}&status=${data?.status}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            resolve(res.data);

        } catch (error) {
            if(error.response?.data?.statusCode === 404){
                return null
             }
             if(error.response?.data?.statusCode === 401){
                <Navigate replace to="/login"/>
             }
            
             reject(error)
        }
    })
}

export const getClockOutOverdue = async (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = sessionStorage.getItem('access_token')
            const res = await axios.get(`${process.env.REACT_APP_ELMS_API_URL}shifts/carer/clockout/overdue?page=${data?.page}&perPage=${data?.perPage}&status=${data?.status}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            resolve(res.data);

        } catch (error) {
            if(error.response?.data?.statusCode === 404){
                return null
             }
             if(error.response?.data?.statusCode === 401){
                <Navigate replace to="/login"/>
             }
            
            
             reject(error)
        }
    })
}

export const getClockIn = async (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = sessionStorage.getItem('access_token')
            const res = await axios.get(`${process.env.REACT_APP_ELMS_API_URL}shifts/carers/currently/clocked?page=${data?.page}&perPage=${data?.perPage}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            resolve(res.data);

        } catch (error) {
            if(error.response?.data?.statusCode === 404){
                return null
             }
            
             if(error.response?.data?.statusCode === 401){
                <Navigate replace to="/login"/>
             }
            
             reject(error)
        }
    })
}

export const getClockOut = async (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = sessionStorage.getItem('access_token')
            const res = await axios.get(`${process.env.REACT_APP_ELMS_API_URL}shifts/carers/clockedout/today?page=${data?.page}&perPage=${data?.perPage}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            resolve(res.data);

        } catch (error) {
            if(error.response?.data?.statusCode === 404){
                return null
             }
             if(error.response?.data?.statusCode === 401){
                <Navigate replace to="/login"/>
             }
            
            
             reject(error)
        }
    })
}


export const getCarer = async (data) => {  
    return new Promise(async (resolve, reject) => { 
        try {
            const token = sessionStorage.getItem('access_token')
            const res = await axios.get(`${process.env.REACT_APP_ELMS_API_URL}users/${data}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })          
            resolve(res.data);

        } catch (error) {
            if(error.response?.data?.statusCode === 404){
                return null
             }

             if(error.response?.data?.statusCode === 401){
                <Navigate replace to="/login"/>
             }
            
            
             reject(error)
        }
    })

    
}

export const getCarerInfo = async (data) => {  
    return new Promise(async (resolve, reject) => { 
        try {
            const token = sessionStorage.getItem('access_token')
            const res = await axios.get(`${process.env.REACT_APP_ELMS_API_URL}users/${data.id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })          
            resolve(res.data);

        } catch (error) {
            if(error.response?.data?.statusCode === 404){
                return null
             }

             if(error.response?.data?.statusCode === 401){
                <Navigate replace to="/login"/>
             }
            
            
             reject(error)
        }
    })

    
}
export const useUpdateCompliance = async (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = sessionStorage.getItem('access_token')
            const res = await axios.patch(`${process.env.REACT_APP_ELMS_API_URL}users/compliance/status/${data.id}`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            resolve(res.data);

        } catch (error) {
            if(error.response?.data?.statusCode === 401){
                <Navigate replace to="/login"/>
             }
            
            reject(error)
        }
    })
}

export const updateStatus = async (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = sessionStorage.getItem('access_token')
            const res = await axios.patch(`${process.env.REACT_APP_ELMS_API_URL}users/${data.id}/suspend`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            resolve(res.data);

        } catch (error) {
            if(error?.response?.data?.statusCode === 401){
                <Navigate replace to="/login"/>
             }
            
            reject(error)
        }
    })
}

export const activateStatus = async (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = sessionStorage.getItem('access_token')
            const res = await axios.patch(`${process.env.REACT_APP_ELMS_API_URL}users/${data.id}/activate`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            resolve(res.data);

        } catch (error) {
            if(error.response?.data?.statusCode === 401){
                <Navigate replace to="/login"/>
             }
            
            reject(error)
        }
    })
}