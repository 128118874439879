import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    editCarePlan: {},
    isLoadingCarePlan: false,
    errorEditPlan: ''
}

const editCarePlanSlice = createSlice({
    name: 'editCarePlan',
    initialState,
    reducers:{
        editCarePlanPending: (state) => {
            state.isLoadingCarePlan = true;
        },
        editCarePlanSuccess: (state, {payload}) => {
            state.isLoadingCarePlan = false;
            state.editCarePlan = payload;
            state.errorEditPlan = '';
        },
        editCarePlanFail: (state, {payload}) => {
            state.isLoadingCarePlan = false;
            state.errorEditPlan = payload;
        }
    }
})

export const {editCarePlanPending, editCarePlanSuccess, editCarePlanFail} = editCarePlanSlice.actions

export default editCarePlanSlice.reducer