import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    editPrescription: {},
    isLoadingEditPres: false,
    errorEditPres: ''
}

const editPrescriptionSlice = createSlice({
    name: 'editPrescription',
    initialState,
    reducers:{
        editPrescriptionPending: (state) => {
            state.isLoadingEditPres = true;
        },
        editPrescriptionSuccess: (state, {payload}) => {
            state.isLoadingEditPres = false;
            state.editPrescription = payload;
            state.errorEditPres = '';
        },
        editPrescriptionFail: (state, {payload}) => {
            state.isLoadingEditPres = false;
            state.errorEditPres = payload;
        }
    }
})

export const {editPrescriptionPending, editPrescriptionSuccess, editPrescriptionFail} = editPrescriptionSlice.actions

export default editPrescriptionSlice.reducer