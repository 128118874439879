import axios from "axios";
import { Navigate} from "react-router-dom";


const BASE_URL = process.env.REACT_APP_ELMS_API_URL;

export const addClientPersonalDetails = async (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = sessionStorage.getItem('access_token')
            const res = await axios.post(`${process.env.REACT_APP_ELMS_API_URL}clients`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            resolve(res.data);
console.log('PersonalSuccess',res);
        } catch (error) {
            if(error?.response?.data?.statusCode === 401){          
                <Navigate replace to="/login"/>
             }
            reject(error)
            console.log('PersonalError', error);
        }
    })
}

export const addClientProperty = async (data) => {
  
    return new Promise(async (resolve, reject) => {
        try {
            const token = sessionStorage.getItem('access_token')
            var formData = new FormData();
            formData.append('pathwaysAndDriveways', data.pathwaysAndDriveways);
            formData.append('pathwaysAndDrivewaysComment', data.pathwaysAndDrivewaysComment);
            formData.append('doorwaysEntryExits', data.doorwaysEntryExits);
            formData.append('doorwaysEntryExitsComment', data.doorwaysEntryExitsComment);
            formData.append('Entrance', data.Entrance);
            formData.append('EntranceComment', data.EntranceComment);
            formData.append('stairwaysAndStairwells', data.stairwaysAndStairwells);
            formData.append('stairwaysAndStairwellsComment', data.stairwaysAndStairwellsComment);
            formData.append('electricalHazards', data.electricalHazards);
            formData.append('electricalHazardsComment', data.electricalHazardsComment);
            formData.append('trippoingFallingStumbling', data.trippoingFallingStumbling);
            formData.append('trippingFallingStumblingComment', data.trippingFallingStumblingComment);
            formData.append('kitchens', data.kitchens);
            formData.append('kitchensComment', data.kitchensComment);
            formData.append('bathrooms', data.bathrooms);
            formData.append('bathroomsComment', data.bathroomsComment);
            formData.append('otherAreas', data.otherAreas);
            formData.append('otherAreasComment', data.otherAreasComment);
            formData.append('documents', data.documents);
            formData.append('id', data.id);
            formData.append('propertyDescription', data.propertyDescription);
            formData.append('propertyRisk', data.propertyRisk);
            formData.append('propertyKeyCode', data.propertyKeyCode);
         
            const res = await axios.post(`${process.env.REACT_APP_ELMS_API_URL}kyc/${data.id}`, formData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            resolve(res.data);
console.log('PropertySuccess',res);
        } catch (error) {
            if(error?.response?.data?.statusCode === 401){          
                <Navigate replace to="/login"/>
             }
            reject(error)
            console.log('PropertyError',error);
        }
    })
}

export const addRiskAssess = async (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = sessionStorage.getItem('access_token')
            const res = await axios.post(`${process.env.REACT_APP_ELMS_API_URL}risk-assessment`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            resolve(res.data);
           console.log('riskSuccess', res.data);

        } catch (error) {
            if(error?.response?.data?.statusCode === 401){          
                <Navigate replace to="/login"/>
             }
             console.log('riskError',error);
            reject(error)
        }
    })
}


export const getRiskAssess = async (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = sessionStorage.getItem('access_token')
            const res = await axios.get(`${process.env.REACT_APP_ELMS_API_URL}risk-assessment/by-client/${data}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            resolve(res.data);
      
        } catch (error) {
            if(error?.response?.data?.statusCode === 404){
                return null
             }
             if(error?.response?.data?.statusCode === 401){          
                <Navigate replace to="/login"/>
             }
            
             reject(error)
        }
    })
}

export const updateRiskAssess = async (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = sessionStorage.getItem('access_token')
            const res = await axios.patch(`${process.env.REACT_APP_ELMS_API_URL}risk-assessment/${data.id}`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            resolve(res.data);

        } catch (error) {
            if(error?.response?.data?.statusCode === 401){          
                <Navigate replace to="/login"/>
             }
            reject(error)
        }
    })
}

export const addClientContacts = async (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = sessionStorage.getItem('access_token')
            const res = await axios.post(`${process.env.REACT_APP_ELMS_API_URL}contacts/${data.id}`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            resolve(res.data);

        } catch (error) {
            if(error?.response?.data?.statusCode === 401){          
                <Navigate replace to="/login"/>
             }
            reject(error)
        }
    })
}

export const addClientContract = async (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = sessionStorage.getItem('access_token')
            var formData = new FormData();
            formData.append('communicationFiles', data.communicationFiles);
            formData.append('referral', data.referral);
            formData.append('clientTierType', data.clientTierType);
            formData.append('contract', data.contract);
            formData.append('contractNumber', data.contractNumber);
            formData.append('contractType', data.contractType);
            formData.append('contractDailyRate', data.contractDailyRate);
            formData.append('contractHourlyRate', data.contractHourlyRate);
            formData.append('ContractWeeklyDays', data.ContractWeeklyDays);
            formData.append('ContractWeeklyHours', data.ContractWeeklyHours);
            formData.append('site', data.site);
            formData.append('startDate', data.startDate);
            formData.append('endDate', data.endDate);
            console.log('formData',formData);
            const res = await axios.post(`${process.env.REACT_APP_ELMS_API_URL}contracts/${data.id}`, formData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            resolve(res.data);
console.log('contractSucc', res);
        } catch (error) {
            console.log('contractErr', error);
            reject(error)
        }
    })
}

export const addClientTiers = async (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = sessionStorage.getItem('access_token')
            const res = await axios.post(`${process.env.REACT_APP_ELMS_API_URL}client-tiers`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            resolve(res.data);

        } catch (error) {
            if(error?.response?.data?.statusCode === 401){          
                <Navigate replace to="/login"/>
             }
            reject(error)
        }
    })
}

export const getClientTiers = async (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = sessionStorage.getItem('access_token')
            const res = await axios.get(`${process.env.REACT_APP_ELMS_API_URL}client-tiers?page=${data.page}&perPage=${data.perPage}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            resolve(res.data);

        } catch (error) {
            if(error?.response?.data?.statusCode === 404){
                return null
             }
             if(error?.response?.data?.statusCode === 401){          
                <Navigate replace to="/login"/>
             }
            
             reject(error)
        }
    })
}

export const getClientInfo = async (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = sessionStorage.getItem('access_token')
            const res = await axios.get(`${process.env.REACT_APP_ELMS_API_URL}clients/${data.id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            resolve(res.data);
// console.log('getInfoSuccess',res);
        } catch (error) {
            // if(error?.response?.data?.statusCode === 404){
            //     return null
            //  }
            //  if(error?.response?.data?.statusCode === 401){          
            //     <Navigate replace to="/login"/>
            //  }
            
             reject(error)
            //  console.log('getInfoError',error);
        }
    })
}


export const updateClientTiers = async (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = sessionStorage.getItem('access_token')
            const res = await axios.patch(`${process.env.REACT_APP_ELMS_API_URL}client-tiers/${data.id}`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            resolve(res.data);

        } catch (error) {
            if(error?.response?.data?.statusCode === 401){          
                <Navigate replace to="/login"/>
             }
            reject(error)
        }
    })
}

export const addCarerTiers = async (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = sessionStorage.getItem('access_token')
            const res = await axios.post(`${process.env.REACT_APP_ELMS_API_URL}care-worker-tiers`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            resolve(res.data);

        } catch (error) {
            if(error?.response?.data?.statusCode === 401){          
                <Navigate replace to="/login"/>
             }
            reject(error)
        }
    })
}


export const getCarerTiers = async (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = sessionStorage.getItem('access_token')
            const res = await axios.get(`${process.env.REACT_APP_ELMS_API_URL}care-worker-tiers?page=${data.page}&perPage=${data.perPage}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            resolve(res.data);

        } catch (error) {
            if(error?.response?.data?.statusCode === 404){
                return null
             }
             if(error?.response?.data?.statusCode === 401){          
                <Navigate replace to="/login"/>
             }
            
             reject(error)
        }
    })
}

export const updateCarerTiers = async (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = sessionStorage.getItem('access_token')
            const res = await axios.patch(`${process.env.REACT_APP_ELMS_API_URL}care-worker-tiers/${data.id}`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            resolve(res.data);

        } catch (error) {
            if(error?.response?.data?.statusCode === 401){          
                <Navigate replace to="/login"/>
             }
            reject(error)
        }
    })
}


export const addClientCarePlans= async (data) => {
  
    return new Promise(async (resolve, reject) => {
        try {
            const token = sessionStorage.getItem('access_token')
            var formData = new FormData();
            formData.append('careplanId', data.careplanId);
            formData.append('background', data.background);
            formData.append('risks', data.risks);
            formData.append('riskAssessment', data.riskAssessment);
            formData.append('aim', data.aim);
            formData.append('routine', data.routine);
            formData.append('objective', data.objective);
            formData.append('outcome', data.outcome);
            formData.append('behaviourTime', data.behaviourTime);
            formData.append('nonCompliant', data.nonCompliant);
            formData.append('verballyAggressive', data.verballyAggressive);
            formData.append('reputationOfBehaviour', data.reputationOfBehaviour);
            formData.append('otherBehaviour', data.otherBehaviour);
            formData.append('behaviourComment', data.behaviourComment);
            formData.append('morningBowelTime', data.morningBowelTime);
            formData.append('morningBowelType', data.morningBowelType);
            formData.append('morningBowelAmount', data.morningBowelAmount);
            formData.append('afternoonBowelTime', data.afternoonBowelTime);
            formData.append('afternoonBowelType', data.afternoonBowelType);
            formData.append('afternoonBowelAmount', data.afternoonBowelAmount);
            formData.append('nightBowelTime', data.nightBowelTime);
            formData.append('nightBowelType', data.nightBowelType);
            formData.append('nightBowelAmount', data.nightBowelAmount);
            formData.append('repositionLeftSideTime', data.repositionLeftSideTime);
            formData.append('repositionLeftSideSkinInspection', data.repositionLeftSideSkinInspection);
            formData.append('repositionBackTime', data.repositionBackTime);
            formData.append('repositionBackSkinInspection', data.repositionBackSkinInspection);
            formData.append('repositionRightSideTime', data.repositionRightSideTime);
            formData.append('repositionRightSideSkinInspection', data.repositionRightSideSkinInspection);
            formData.append('fluidIntakeTime', data.fluidIntakeTime);
            formData.append('fluidIntake', data.fluidIntake);
            formData.append('fluidIntakeComments', data.fluidIntakeComments);
            formData.append('foodIntakeTime', data.foodIntakeTime);
            formData.append('food', data.food);
            formData.append('foodIntakeAmount', data.foodIntakeAmount);
            formData.append('foodIntakeComments', data.foodIntakeComments);
            formData.append('prescriptions', data.prescriptions);
            formData.append('medication', data.medication);
            formData.append('medicationType', data.medicationType);
            formData.append('administration', data.administration);
            formData.append('dosage', data.dosage);
            formData.append('morning', data.morning);
            formData.append('lunch', data.lunch);
            formData.append('evening', data.evening);
            formData.append('bed', data.bed);
            formData.append('alternateHours', data.alternateHours);
            formData.append('alternateDays', data.alternateDays);
            formData.append('weekly', data.weekly);
            formData.append('prn', data.prn);
            formData.append('enableAdministeredQuantity', data.enableAdministeredQuantity);
            formData.append('notes', data.notes);
            formData.append('monday', data.monday);
            formData.append('tuesday', data.tuesday);
            formData.append('wednesday', data.wednesday);
            formData.append('thursday', data.thursday);
            formData.append('friday', data.friday);
            formData.append('saturday', data.saturday);
            formData.append('sunday', data.sunday);
            formData.append('intervals', data.intervals);
            formData.append('received', data.received);
            formData.append('dailyBalance', data.dailyBalance);
            formData.append('weeklyBalance', data.weeklyBalance);
            formData.append('adminAdjustments', data.adminAdjustments);
            formData.append('adminId', data.adminId);
            formData.append('continuous', data.continuous);
            formData.append('start_time', data.start_time);
            formData.append('end_time', data.end_time);
            formData.append('startDate', data.startDate);
            formData.append('maxperDay', data.maxperDay);
            formData.append('nextReviewDate', data.nextReviewDate);
            formData.append('gpReview', data.gpReview);
            formData.append('medicineReason', data.medicineReason);
        
console.log('formData',formData);

            const res = await axios.post(`${process.env.REACT_APP_ELMS_API_URL}client-care-plans/${data.id}`, formData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            resolve(res.data);
console.log('CarePlanSuccess',res);
        } catch (error) {
            if(error?.response?.data?.statusCode === 401){          
                <Navigate replace to="/login"/>
             }
            reject(error)
            console.log('CarePlanError',error);
        }
    })
}


export const clientTiers = async (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = sessionStorage.getItem('access_token')
            const res = await axios.post(`${process.env.REACT_APP_ELMS_API_URL}client-care-workers/${data.id}`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            resolve(res.data);
console.log('taskSUCC',res);
        } catch (error) {
            if(error?.response?.data?.statusCode === 404){
                return null
             }
             if(error?.response?.data?.statusCode === 401){          
                <Navigate replace to="/login"/>
             }
            console.log('taskERR', error);
             reject(error)
        }
    })
}


export const getClients = async (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = sessionStorage.getItem('access_token')
            const res = await axios.get(`${process.env.REACT_APP_ELMS_API_URL}clients?page=${data.page}&perPage=${data.perPage}&status=${data.status}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            resolve(res.data);

        } catch (error) {
       
            if(error?.response?.data?.statusCode === 404){
                return null
             }
            else if(error?.response?.data?.statusCode === 401){                
                   <Navigate replace to="/login"/>
             }else{
                reject(error)
             }
            
            
        }
    })
}


export const getClientBirthdays = async (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = sessionStorage.getItem('access_token')
            const res = await axios.get(`${process.env.REACT_APP_ELMS_API_URL}clients/birthdays`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            resolve(res.data);

        } catch (error) {
            if(error?.response?.data?.statusCode === 404){
                return null
             }
             if(error?.response?.data?.statusCode === 401){          
                <Navigate replace to="/login"/>
             }
            
             reject(error)
        }
    })
}

export const updateClientStatus = async (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = sessionStorage.getItem('access_token')
            const res = await axios.patch(`${process.env.REACT_APP_ELMS_API_URL}clients/${data.id}/status`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            resolve(res.data);

        } catch (error) {
            if(error?.response?.data?.statusCode === 401){          
                <Navigate replace to="/login"/>
             }
            reject(error)
        }
    })
}


export const getAddedCarePlans = async (data) => {
    console.log('dataW',data);
    return new Promise(async (resolve, reject) => {
        try {
            const token = sessionStorage.getItem('access_token')
            const res = await axios.get(`${process.env.REACT_APP_ELMS_API_URL}client-care-plans/get/client/care/plan?clientId=${data.clientId}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            resolve(res.data);
console.log('Added',res);
        } catch (error) {
            if(error?.response?.data?.statusCode === 404){
                return null
             }
             if(error?.response?.data?.statusCode === 401){          
                <Navigate replace to="/login"/>
             }
            
             reject(error)
             console.log('Errx',error);
        }
    })
}


export const getAddedMarCharts = async (data) => {
    return new Promise(async (resolve, reject) => {
      
        if(data.id === 'undefined'){
         return null
        }
        try {
            const token = sessionStorage.getItem('access_token')
            const res = await axios.get(`${process.env.REACT_APP_ELMS_API_URL}client-care-plans/get/marcharts?id=${data.id}&clientId=${data.clientId}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            resolve(res.data);

        } catch (error) {
            if(error?.response?.data?.statusCode === 404){
               return null
            }

            if(error?.response?.data?.statusCode === 401){          
                <Navigate replace to="/login"/>
             }
            reject(error)
            
           
            
        }
    })
}

export const updatePersonalDetails = async (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = sessionStorage.getItem('access_token')
            const res = await axios.patch(`${process.env.REACT_APP_ELMS_API_URL}clients/${data.id}`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            resolve(res.data);

        } catch (error) {
            if(error?.response?.data?.statusCode === 401){          
                <Navigate replace to="/login"/>
             }
            reject(error)
        }
    })
}

export const updateContracts = async (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = sessionStorage.getItem('access_token')
            const res = await axios.patch(`${process.env.REACT_APP_ELMS_API_URL}contracts/${data.id}`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            resolve(res.data);

        } catch (error) {
            if(error?.response?.data?.statusCode === 401){          
                <Navigate replace to="/login"/>
             }
            reject(error)
        }
    })
}

export const updateContacts = async (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = sessionStorage.getItem('access_token')
            const res = await axios.patch(`${process.env.REACT_APP_ELMS_API_URL}contacts/${data.id}`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            resolve(res.data);

        } catch (error) {
            if(error?.response?.data?.statusCode === 401){          
                <Navigate replace to="/login"/>
             }
            reject(error)
        }
    })
}

export const updateProperty = async (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = sessionStorage.getItem('access_token')
            const res = await axios.patch(`${process.env.REACT_APP_ELMS_API_URL}kyc/${data.id}`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            resolve(res.data);
console.log('scuu',res);
        } catch (error) {
            if(error?.response?.data?.statusCode === 401){          
                <Navigate replace to="/login"/>
             }
            reject(error)
            console.log('erx',error);
        }
    })
}


export const updateCarePlan = async (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = sessionStorage.getItem('access_token')
            const res = await axios.patch(`${process.env.REACT_APP_ELMS_API_URL}client-care-plans/update/${data.id}`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            resolve(res.data);
console.log('SUCCESS',res);
        } catch (error) {
            if(error?.response?.data?.statusCode === 401){          
                <Navigate replace to="/login"/>
             }
            reject(error)
            console.log('ERROR',error);
        }
    })
}

export const updateTiers = async (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = sessionStorage.getItem('access_token')
            const res = await axios.patch(`${process.env.REACT_APP_ELMS_API_URL}clients/${data.id}/client/tier-carers`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            resolve(res.data);

        } catch (error) {
            if(error?.response?.data?.statusCode === 401){          
                <Navigate replace to="/login"/>
             }
            reject(error)
        }
    })
}

export const updateReferral = async (data) => {
  
    return new Promise(async (resolve, reject) => {
        try {
            const token = sessionStorage.getItem('access_token')
            var formData = new FormData();
            formData.append('id', data.id);
            formData.append('referral', data.referral);
            
            const res = await axios.patch(`${process.env.REACT_APP_ELMS_API_URL}contracts/${data.id}/referral`, formData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            resolve(res.data);

        } catch (error) {
            if(error?.response?.data?.statusCode === 401){          
                <Navigate replace to="/login"/>
             }
            reject(error)
        }
    })
}

export const updateContractDoc = async (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = sessionStorage.getItem('access_token')
            var formData = new FormData();
            formData.append('id', data.id);
            formData.append('contract', data.contract);
            const res = await axios.patch(`${process.env.REACT_APP_ELMS_API_URL}contracts/${data.id}/contract`, formData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            resolve(res.data);

        } catch (error) {
            if(error?.response?.data?.statusCode === 401){          
                <Navigate replace to="/login"/>
             }
            reject(error)
        }
    })
}

export const updateCommunication = async (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = sessionStorage.getItem('access_token')
            var formData = new FormData();
            formData.append('id', data.id);
            formData.append('communicationFiles', data.communicationFiles);
            const res = await axios.patch(`${process.env.REACT_APP_ELMS_API_URL}contracts/${data.id}/communication`, formData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            resolve(res.data);

        } catch (error) {
            if(error?.response?.data?.statusCode === 401){          
                <Navigate replace to="/login"/>
             }
            reject(error)
        }
    })
}


export const updateClientCarer = async (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = sessionStorage.getItem('access_token')
            const res = await axios.patch(`${process.env.REACT_APP_ELMS_API_URL}client-care-workers/update/${data.id}`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            resolve(res.data);

        } catch (error) {
            if(error?.response?.data?.statusCode === 401){          
                <Navigate replace to="/login"/>
             }
            reject(error)
        }
    })

}

export const updateKyc = async (data) => {
    var formData = new FormData();
    formData.append('documents', data.documents);
    return new Promise(async (resolve, reject) => {
        try {
            const token = sessionStorage.getItem('access_token')
            const res = await axios.patch(`${process.env.REACT_APP_ELMS_API_URL}kyc/${data.id}/documents`, formData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            resolve(res.data);
console.log('updatesuuc',res);
        } catch (error) {
            if(error?.response?.data?.statusCode === 401){          
                <Navigate replace to="/login"/>
             }
            reject(error)
            console.log('uuerror',error);
        }
    })

}

export const updatePrescription = async (data) => {
    console.log('DATAA',data);
    return new Promise(async (resolve, reject) => {
        try {
            const token = sessionStorage.getItem('access_token')
            var formData = new FormData();
            formData.append('prescriptions', data.prescriptions);
            const res = await axios.patch(`${BASE_URL}client-care-plans/${data.id}/prescriptions`, formData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            resolve(res.data);
console.log('TAKEE',res);
        } catch (error) {
            reject(error)
        }
    })
    

}
export const getClientCareWorker = async (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = sessionStorage.getItem('access_token')
            const res = await axios.get(`${process.env.REACT_APP_ELMS_API_URL}client-care-workers/byclient?clientId=${data.clientId}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })           
            resolve(res.data);

        } catch (error) {
            if(error?.response?.data?.statusCode === 404){
                return null
             }
             if(error?.response?.data?.statusCode === 401){          
                <Navigate replace to="/login"/>
             }
             reject(error)
        }
    })
}

export const getClientShifts = async (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = sessionStorage.getItem('access_token')
            const res = await axios.get(`${BASE_URL}shifts?clientId=${data.clientId}&page=${data.page}&perPage=${data.perPage}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })           
            resolve(res.data);

        } catch (error) {
            if(error?.response?.data?.statusCode === 404){
                return null
             }
             if(error?.response?.data?.statusCode === 401){          
                <Navigate replace to="/login"/>
             }
             reject(error)
        }
    })
}