import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    updateRegulatorCarers: {},
    isLoadingupdateRegulatorCarers: false,
    errorupdateRegulatorCarers: ''
}

const updateRegulatorCarersSlice = createSlice({
    name: 'updateRegulatorCarers',
    initialState,
    reducers:{
        updateRegulatorCarersPending: (state) => {
            state.isLoadingupdateRegulatorCarers = true;
        },
        updateRegulatorCarersSuccess: (state, {payload}) => {
            state.isLoadingupdateRegulatorCarers = false;
            state.updateRegulatorCarers = payload;
            state.errorupdateRegulatorCarers = '';
        },
        updateRegulatorCarersFail: (state, {payload}) => {
            state.isLoadingupdateRegulatorCarers = false;
            state.errorupdateRegulatorCarers = payload;
        }
    }
})

export const {updateRegulatorCarersPending, updateRegulatorCarersSuccess, updateRegulatorCarersFail} = updateRegulatorCarersSlice.actions

export default updateRegulatorCarersSlice.reducer