import {assignRegulatorPending, assignRegulatorSuccess, assignRegulatorFail} from '../staffSlices/assignRegulatorSlice'
import {assignCarer} from '../../../api/staffApi'

 const assignRegulatorAction = (data) => async dispatch => {

    try {
        dispatch(assignRegulatorPending());
        const result = await assignCarer(data);
        if(result){
            return dispatch(assignRegulatorSuccess(result))
        }
    } catch (error) {
        dispatch(assignRegulatorFail(error))
    }
}

export default assignRegulatorAction