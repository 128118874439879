import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    getClients: {},
    isLoadingGetClients: false,
    errorGetClient: ''
}

const getClientsSlice = createSlice({
    name: 'getClients',
    initialState,
    reducers:{
        getClientsPending: (state) => {
            state.isLoadingGetClients = true;
        },
        getClientsSuccess: (state, {payload}) => {
            state.isLoadingGetClients = false;
            state.getClients = payload;
            state.errorGetClient = '';
        },
        getClientsFail: (state, {payload}) => {
            state.isLoadingGetClients = false;
            state.errorGetClient = payload;
        }
    }
})

export const {getClientsPending, getClientsSuccess, getClientsFail} = getClientsSlice.actions

export default getClientsSlice.reducer