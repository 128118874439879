import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    myAccount: {},
    isLoadingMyAccount: false,
    errorMyAccount: ''
}

const myAccountSlice = createSlice({
    name: 'myAccount',
    initialState,
    reducers:{
        myAccountPending: (state) => {
            state.isLoadingMyAccount = true;
        },
        myAccountSuccess: (state, {payload}) => {
            state.isLoadingMyAccount = false;
            state.myAccount = payload;
            state.errorMyAccount = '';
        },
        myAccountFail: (state, {payload}) => {
            state.isLoadingMyAccount = false;
            state.errorMyAccount = payload;
        }
    }
})

export const {myAccountPending, myAccountSuccess, myAccountFail} = myAccountSlice.actions

export default myAccountSlice.reducer