import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    editInvoiceTimesheets: {},
    isLoadingEditInvoiceTimesheets: false,
    errorEditInvoiceTimesheets: ''
}

const editInvoiceTimesheetsSlice = createSlice({
    name: 'editInvoiceTimesheets',
    initialState,
    reducers:{
       editInvoiceTimesheetsPending: (state) => {
            state.isLoadingEditInvoiceTimesheets = true;
        },
        editInvoiceTimesheetsSuccess: (state, {payload}) => {
            state.isLoadingEditInvoiceTimesheets = false;
            state.editInvoiceTimesheets = payload;
            state.errorEditInvoiceTimesheets = '';
        },
        editInvoiceTimesheetsFail: (state, {payload}) => {
            state.isLoadingEditInvoiceTimesheets = false;
            state.errorEditInvoiceTimesheets = payload;
        }
    }
})

export const {editInvoiceTimesheetsPending, editInvoiceTimesheetsSuccess, editInvoiceTimesheetsFail} = editInvoiceTimesheetsSlice.actions

export default editInvoiceTimesheetsSlice.reducer