import {
  documentsPending,
  documentsSuccess,
  documentsFail,
} from "../RegisterCarerSlices/DocumentsSlice";
import { Documents } from "../../api/carerApi";

const DocumentsAction = (data) => async (dispatch) => {
  try {
    dispatch(documentsPending());
    const result = await Documents(data);
    if (result) {
      return dispatch(documentsSuccess(result));
    }
  } catch (error) {
    dispatch(documentsFail(error));
  }
};

export default DocumentsAction;
