import React from "react";
import EditTier from "./EditTier";
import FormikController from "../../../components/Formik/FormikController";
import SubmitButton from "../../../components/Formik/SubmitButton";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import getCarerTier from "../../configurations/ConfigActions/GetCarerTierAction";
import addTiers from "../../clients/ClientActions/ClientTierAction";
import getCarerList from "../../carers/CarerActions/GetCarersAction";
import getCareWorkers from "../ClientActions/ClientCareWorkersAction";
import PageLoader from "../../../components/PageLoader";

function Tiers({ client, setShowModal }) {
  const dispatch = useDispatch();
  const { getCarerTiers } = useSelector((state) => state.getCarerTier);
  const { clientTiers, isLoadingClientTiers, errorClientTiers } = useSelector(
    (state) => state.clientTier
  );
  const { addClientPersonalDetails } = useSelector(
    (state) => state.addClientPersonal
  );

  const { getCarers } = useSelector((state) => state.getCarersList);
  const { editClientCarers, isLoadingEditCarer } = useSelector(
    (state) => state.editClientCareWorkers
  );
  const { clientCareWorkers, isLoadingClientCareWorkers } = useSelector(
    (state) => state.clientcareworkers
  );

  const [indexes, setIndexes] = React.useState([]);
  const [counter, setCounter] = React.useState(0);
  const itemsCountPerPage = 20;
  const [page, setPage] = React.useState(1);

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };
  React.useEffect(() => {
    dispatch(
      getCarerTier({
        page,
        perPage: itemsCountPerPage,
      })
    );

    dispatch(
      getCarerList({
        status: "COMPLIANT",
        page,
        perPage: itemsCountPerPage,
      })
    );
    dispatch(
      getCareWorkers({
        clientId: client.id,
      })
    );
  }, [
    addClientPersonalDetails,
    clientTiers,
    editClientCarers,

  ]);

  let carers =
    getCarers && getCarers.data
      ? getCarers.data.map((cxt) => {
          let obj = {
            key: cxt.firstname + " " + cxt.lastname,
            value: cxt.id,
          };
          return obj;
        })
      : [];

  const initialValues = {};

  const validationSchema = Yup.object({});
  const shifts = [
    {
      key: "Day Shift",
      value: "day",
    },
    {
      key: "Night Shift",
      value: "night",
    },
    {
      key: "Live-In Shift",
      value: "livein",
    },
  ];

  let carerTiers =
    getCarerTiers && getCarerTiers.data
      ? getCarerTiers.data.map((cxt) => {
          let obj = {
            key: cxt.type,
            value: cxt.id,
          };
          return obj;
        })
      : [];

  const addMarChart = () => {
    setIndexes((prevIndexes) => [...prevIndexes, counter]);
    setCounter((prevCounter) => prevCounter + 1);
  };

  const removeMarChart = (index) => () => {
    setIndexes((prevIndexes) => [
      ...prevIndexes.filter((item) => item !== index),
    ]);
    setCounter((prevCounter) => prevCounter - 1);
  };

  const clearMarCharts = () => {
    setIndexes([]);
  };

  const handleSubmit = (values) => {
    values.tiers.id = client.id;
    dispatch(addTiers(values.tiers));
    clearMarCharts();
    // setShowModal(false);
  };

  const Head = (props) => {
    return (
      <div className="border-b mb-5 text-left">
        <label className="text-xl font-medium text-gray-700">
          {props.title}
        </label>
      </div>
    );
  };

  return ( isLoadingClientCareWorkers ? <PageLoader/> :
    <div className=" w-full p-5">
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" className="px-6 py-3">
              Care Worker
            </th>
            <th scope="col" className="px-6 py-3">
              Care Worker Tier
            </th>
            <th scope="col" className="px-6 py-3">
              Shift Type
            </th>
            <th scope="col" className="px-6 py-3">
              Start Date
            </th>
            <th scope="col" className="px-6 py-3">
              Start Time
            </th>
            <th scope="col" className="px-6 py-3">
              End Date
            </th>
            <th scope="col" className="px-6 py-3">
              End Time
            </th>
            <th scope="col" className="px-6 py-3">
              <span className="sr-only">Edit</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {clientCareWorkers && clientCareWorkers.data ? (
            clientCareWorkers.data.map((e) => (
              <tr
                key={e.id}
                className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-primary dark:hover:bg-gray-600"
              >
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap"
                >
                  {e.user?.firstname} {e.user?.lastname}
                </th>
                <td className="px-6 py-4">
                  {e.tier.type} {e.tier.brandwidth}
                </td>
                <td className="px-6 py-4">{e.shiftType}</td>
                <td className="px-6 py-4">{e.shiftStartDate}</td>
                <td className="px-6 py-4">{e.shiftStartTime}</td>
                <td className="px-6 py-4">{e.shiftEndDate}</td>
                <td className="px-6 py-4">{e.shiftEndTime}</td>
                <td className="px-6 py-4 text-right">
                  <p className="font-medium text-secondary dark:text-blue-500 hover:underline">
                    <EditTier e={e} />
                  </p>
                </td>
              </tr>
            ))
          ) : (
            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
              <td
                colSpan="9"
                className="text-center px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap border rounded bg-red-100"
              >
                No Data Found
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          handleSubmit(values);
          resetForm();
        }}
      >
        {({ setFieldValue, values }) => (
          <Form className="">
            {indexes.map((index) => {
              const fieldName = `tiers[${index}]`;
              return (
                <fieldset name={fieldName} key={fieldName}>
                  <div className="mb-5 font-bold ml-6 mt-5">
                    <Head title={`Allocate Shift Types`} />
                  </div>
                  <div className="border rounded p-5 ml-6">
                    {/* <div className="">
                      <Head title="Client Tiers" />
                    </div> */}
                    <div className="">
                      <FormikController
                        control="select"
                        type="select"
                        name={`${fieldName}.shiftType`}
                        label="Select Shift"
                        options={shifts}
                      />
                    </div>
                    <div>
                      <FormikController
                        control="input"
                        type="date"
                        name={`${fieldName}.shiftStartDate`}
                        label="Shift Start Date"
                      />
                    </div>
                    <div>
                      <FormikController
                        control="input"
                        type="date"
                        name={`${fieldName}.shiftEndDate`}
                        label="Shift End Date"
                      />
                    </div>
                    <div>
                      <FormikController
                        control="input"
                        type="time"
                        name={`${fieldName}.shiftStartTime`}
                        label="Shift Start Time"
                      />
                    </div>
                    <div>
                      <FormikController
                        control="input"
                        type="time"
                        name={`${fieldName}.shiftEndTime`}
                        label="Shift End Time"
                      />
                    </div>
                    <div>
                      <FormikController
                        control="select"
                        type="select"
                        name={`${fieldName}.careWorkerTierId`}
                        label="Care Worker Tier Rate*"
                        options={carerTiers}
                      />
                    </div>
                    <div className="text-left">
                      <FormikController
                        control="select"
                        type="select"
                        name={`${fieldName}.careWorkerId`}
                        label="Select Care Worker*"
                        options={carers}
                      />
                    </div>
                    <div className="mb-3 mt-5 flex flex-row-reverse">
                      <div></div>
                    </div>
                  </div>
                  <div className="text-left mt-3 border-b mb-5 ml-6">
                    <button
                      className="border border-button text-button font-medium p-3 px-5 bg-red-100 mb-4 rounded"
                      type="button"
                      onClick={removeMarChart(index)}
                    >
                      Remove
                    </button>
                  </div>
                </fieldset>
              );
            })}
            <div className="text-left py-5 ml-6">
              <button
                className="border rounded p-5 mr-3 border-green text-white bg-green font-medium"
                type="button"
                onClick={addMarChart}
              >
                Add Shift Type
              </button>
              {counter ? (
                <button
                  className="border rounded p-5 border-gray-300 bg-gray-500 text-white font-medium"
                  type="button"
                  onClick={clearMarCharts}
                >
                  Clear Shift Types
                </button>
              ) : (
                <div></div>
              )}
            </div>
            {counter ? <SubmitButton label="Add Task Plan" /> : ""}
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default Tiers;
