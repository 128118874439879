import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  GetEthnicity: {},
  isLoadingGetEthnicity: false,
  errorGetEthnicity: "",
};

const GetEthnicitySlice = createSlice({
  name: "GetEthnicity",
  initialState,
  reducers: {
    GetEthnicityPending: (state) => {
      state.isLoadingGetEthnicity = true;
    },
    GetEthnicitySuccess: (state, { payload }) => {
      state.isLoadingGetEthnicity = false;
      state.GetEthnicity = payload;
      state.errorGetEthnicity = "";
    },
    GetEthnicityFail: (state, { payload }) => {
      state.isLoadingGetEthnicity = false;
      state.errorGetEthnicity = payload;
    },
  },
});

export const { GetEthnicityPending, GetEthnicitySuccess, GetEthnicityFail } =
  GetEthnicitySlice.actions;

export default GetEthnicitySlice.reducer;
