import {addClientCarePlansPending, addClientCarePlansSuccess, addClientCarePlansFail} from '../ClientSlices/ClientCarePlansSlice'
import {addClientCarePlans} from '../../../api/clientApi'

 const addCarePlans = (data) => async dispatch => {

    try {
        dispatch(addClientCarePlansPending());
        const result = await addClientCarePlans(data);
        if(result){
            return dispatch(addClientCarePlansSuccess(result))
        }
    } catch (error) {
        dispatch(addClientCarePlansFail(error))
    }
} 


export default addCarePlans   