import React from "react";
import SwitchCheckBox from "../../../components/SwitchCheckBox";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import GetCarerInfoAction from '../../carers/RegisterCarerActions/GetCarerInfoAction';
import CarerDocuments from "../../carers/AddCarer/CarerDocuments";


function Documents({ carer }) {
  const dispatch = useDispatch();
  
  const { documents, isLoadingDocuments } = useSelector(
    (state) => state.Documents
  );
  React.useEffect(() => {
    dispatch(GetCarerInfoAction({id:carer?.carer?.id}))
  }, [documents]);

  const {GetCarerInfo, isLoadingGetCarerInfo} = useSelector((state)=>state.GetCarerInfoSliceReducer)
  const care = GetCarerInfo;
  console.log('care',care);
  const DbsPermission = care.documents ? care.documents.DbsPermission : false;
  const RequireWorkPermit = care.documents
    ? care.documents.RequireWorkPermit
    : false;
  const UKEmploymentRestrictions = care.documents
    ? care.documents.UKEmploymentRestrictions
    : false;

    const  cv = care.documents && care.documents.curriculumVitae ? care.documents?.curriculumVitae.split(",") : null;
    const  passport = care.documents &&care.documents.passport? care.documents.passport.split(",") : null;
    const  permit = care.documents &&care.documents.permit? care.documents.permit.split(",") : null;
    const  dbs = care.documents &&care.documents.dbs? care.documents.dbs.split(",") : null;
    const  rightToWork = care.documents &&care.documents.rightToWork? care.documents.rightToWork.split(",") : null;
  
   

    
    const base64toBlob = (data) => {
      // Cut the prefix `data:application/pdf;base64` from the raw base 64
      
      const bytes = atob(data);
      let length = bytes.length;
      let out = new Uint8Array(length);
  
      while (length--) {
          out[length] = bytes.charCodeAt(length);
      }
  
     const blob = new Blob([out], { type: 'application/pdf' });

    return URL.createObjectURL(blob);
  };

  const validationSchema = Yup.object({});

  const Head = (props) => {
    return (
      <div className="border-b mb-5">
        <label className="text-xl font-medium text-gray-700">
          {props.title}
        </label>
      </div>
    );
  };

  return (!GetCarerInfo.documents ? <CarerDocuments GetCarerInfo={GetCarerInfo}/> :
    <div className="w-full p-5">
      <Formik
        validationSchema={validationSchema}
        initialValues={{
          picked: "",
        }}
        onSubmit={async (values) => {
          await new Promise((r) => setTimeout(r, 500));
          alert(JSON.stringify(values, null, 2));
        }}
      >
        {(formik) => {
          return (
            <div className="">
              {care.documents ? (
                <Form className="text-left">
                   <div className="text-left font-bold text-gray-600 p-4 text-md bg-gray-100 mb-5 rounded uppercase">
                   Documents
                    </div>
                  <div className="border p-5 rounded ">
                   
                    <div className="grid grid-cols-1 gap-2">
                      <SwitchCheckBox
                        name="DbsPermission"
                        label="Dbs Permission"
                        checked={DbsPermission === true}
                      />
                      <SwitchCheckBox
                        name="RequireWorkPermit "
                        label="Require WorkPermit "
                        checked={RequireWorkPermit === true}
                      />

                      <SwitchCheckBox
                        name="UKEmploymentRestrictions"
                        label="UK Employment Restrictions"
                        checked={UKEmploymentRestrictions === true}
                      />

                      <div className="mb-6">
                        <label>DBS Online Number</label>
                        <input
                          type="text"
                          id="large-input"
                          value={care.documents.dbsOnlineNumber}
                          className="block p-2 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 sm:text-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          readOnly
                        />

                        <label>Dbs Certificate Number</label>
                        <input
                          type="text"
                          id="large-input"
                          value={care.documents.DbsCertificateNumber}
                          className="block p-2 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 sm:text-md focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          readOnly
                        />
                      </div>
                      {care.documents?.curriculumVitae && (
                   <> 
                
                    <Formik>
                      <div className="border p-5 rounded">
                      <Head title="Curriculum Vitae" />
                      {/* {cv[0] === 'data:application/pdf' ? <embed src={`data:application/pdf;base64,${cv[1]}`}  height="300"width="100%" /> : <img src={`data:image/jpeg;base64,${cv[1]}`} alt="CV" />} */}
                                 {cv && <iframe
                                    src={cv}
                                    frameborder="0"
                                    height="550"
                                    width="1000"
                                  ></iframe>}
                        <div className="">  
                          
                        </div>
                      </div>
                    </Formik>
                    </>
                  )}

                  {care.documents?.dbs && (
                    <Formik>
                      <div className="border p-5 rounded">
                      <Head title="DBS" />
                      {dbs && <iframe
                                    src={dbs}
                                    frameborder="0"
                                    height="550"
                                    width="100%"
                                  ></iframe>}
                       
                        <div className="">
                         
                        </div>
                      </div>
                    </Formik>
                  )}

                  {care.documents?.passport && (
                    <Formik>
                      <div className="border p-5 rounded">
                      <Head title="Passport" />
                        {passport && <iframe
                                    src={passport}
                                    frameborder="0"
                                    height="550"
                                    width="1000"
                                  ></iframe>}
                        <div className="">
                         
                        </div>
                      </div>
                    </Formik>
                  )}

                  {care.documents?.permit && (
                    <Formik>
                      <div className="border p-5 rounded">
                      <Head title="Permit" />
                       {permit && <iframe
                                    src={permit}
                                    frameborder="0"
                                    height="550"
                                    width="1000"
                                  ></iframe>}
                        <div className="">
                          
                        </div>
                      </div>
                    </Formik>
                  )}
                  {care.documents?.rightToWork && (
                    <Formik>
                      <div className="border p-5 rounded">
                      <Head title="Right To Work" />
                          {rightToWork && <iframe
                                    src={rightToWork}
                                    frameborder="0"
                                    height="550"
                                    width="1000"
                                  ></iframe>}
                        <div className="">
                          
                        </div>
                      </div>
                    </Formik>
                      )}
                    </div>
                  </div>
                  {/* <div className="mb-3 mt-5 flex flex-row-reverse">
                    <div>mark as compliant</div>
                  </div> */}
                </Form>
              ) : (
                <div className="">
                  <span className="text-center">No Documents Uploaded</span>
                </div>
              )}
            </div>
          );
        }}
      </Formik>
    </div>
  );
}

export default Documents;
