import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    getAudited: {},
    isLoadingGetAudit: false,
    errorGetAudit: ''
}

const getAuditTrailSlice = createSlice({
    name: 'getAudited',
    initialState,
    reducers:{
        getAuditPending: (state) => {
            state.isLoadingGetAudit = true;
        },
        getAuditSuccess: (state, {payload}) => {
            state.isLoadingGetAudit = false;
            state.getAudited = payload;
            state.errorGetAudit = '';
        },
        getAuditFail: (state, {payload}) => {
            state.isLoadingGetAudit = false;
            state.errorGetAudit = payload;
        }
    }
})

export const {getAuditPending, getAuditSuccess, getAuditFail} = getAuditTrailSlice.actions

export default getAuditTrailSlice.reducer