import {editPersonalDetailsPending, editPersonalDetailsSuccess, editPersonalDetailsFail} from '../ClientSlices/EditPersonalDetailsSlice'
import {updatePersonalDetails} from '../../../api/clientApi'

 const editPersonal = (data) => async dispatch => {

    try {
        dispatch(editPersonalDetailsPending());
        const result = await updatePersonalDetails(data);
        if(result){
            return dispatch(editPersonalDetailsSuccess(result))
        }
    } catch (error) {
        dispatch(editPersonalDetailsFail(error))
    }
} 

export default editPersonal