import React from "react";
import SimpleSwitch from "../../../components/SimpleSwitch";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import GetCarerInfoAction from '../../carers/RegisterCarerActions/GetCarerInfoAction';
import CarerTraining from "../../carers/AddCarer/CarerTraining";
import SubmitButton from "../../../components/Formik/SubmitButton";
import UpdateTrainingAction from "../../carers/RegisterCarerActions/UpdateTrainingAction";
import PageLoader from "../../../components/PageLoader";

function TrainingHistory({ carer, setShowModal }) {

  const dispatch = useDispatch();

  const {GetCarerInfo, isLoadingGetCarerInfo} = useSelector((state)=>state.GetCarerInfoSliceReducer);
  const {updateTraining, isLoadingupdateTraining} = useSelector((state)=>state.UpdateTraining);
  const { TrainingHistory, isLoadingTrainingHistory } = useSelector(
    (state) => state.TrainingHistory
  );
  // console.log('TRAINING',GetCarerInfo);

  const CareCertificate = GetCarerInfo.trainingHistory
    ? GetCarerInfo.trainingHistory.CareCertificate
    : false;
  const MedicationAdministration = GetCarerInfo.trainingHistory
    ? GetCarerInfo.trainingHistory.MedicationAdministration
    : false;
  const MovingHandling = GetCarerInfo.trainingHistory
    ? GetCarerInfo.trainingHistory.MovingHandling
    : false;
  const InfectionControl = GetCarerInfo.trainingHistory
    ? GetCarerInfo.trainingHistory.InfectionControl
    : false;
  const CovidCertificate = GetCarerInfo.trainingHistory
    ? GetCarerInfo.trainingHistory.CovidCertificate
    : false;
  const pegFeed = GetCarerInfo.HasAllergies
    ? GetCarerInfo.HasAllergies.pegFeed
    : false;
  const EndOfLifePalliative = GetCarerInfo.trainingHistory
    ? GetCarerInfo.trainingHistory.EndOfLifePalliative
    : false;
  const SkinIntegrity = GetCarerInfo.trainingHistory
    ? GetCarerInfo.trainingHistory.SkinIntegrity
    : false;
  const Pmva = GetCarerInfo.trainingHistory
    ? GetCarerInfo.trainingHistory.Pmva
    : false;
  const Oxygen = GetCarerInfo.trainingHistory
    ? GetCarerInfo.trainingHistory.Oxygen
    : false;
  const Demetia = GetCarerInfo.trainingHistory
    ? GetCarerInfo.trainingHistory.Demetia
    : false;
  const MentalHealth = GetCarerInfo.trainingHistory
    ? GetCarerInfo.trainingHistory.MentalHealth
    : false;
  const CoughAssist = GetCarerInfo.trainingHistory
    ? GetCarerInfo.trainingHistory.CoughAssist
    : false;
  const Tracheostomy = GetCarerInfo.trainingHistory
    ? GetCarerInfo.trainingHistory.Tracheostomy
    : false;
  const SpinalInjuries = GetCarerInfo.trainingHistory
    ? GetCarerInfo.trainingHistory.SpinalInjuries
    : false;
  const BrainInjuries = GetCarerInfo.trainingHistory
    ? GetCarerInfo.trainingHistory.BrainInjuries
    : false;
  const LearningDisability = GetCarerInfo.trainingHistory
    ? GetCarerInfo.trainingHistory.LearningDisability
    : false;
  const Catheter = GetCarerInfo.trainingHistory
    ? GetCarerInfo.trainingHistory.Catheter
    : false;
  const Suctioning = GetCarerInfo.trainingHistory
    ? GetCarerInfo.trainingHistory.Suctioning
    : false;
  const StomaBag = GetCarerInfo.trainingHistory
    ? GetCarerInfo.trainingHistory.StomaBag
    : false;
  const MandatoryTraining = GetCarerInfo.trainingHistory
    ? GetCarerInfo.trainingHistory.MandatoryTraining
    : false;
  const EarNoseThroat = GetCarerInfo.trainingHistory
    ? GetCarerInfo.trainingHistory.EarNoseThroat
    : false;

    const initialValues = {
      CareCertificate: GetCarerInfo.trainingHistory
      ? GetCarerInfo.trainingHistory.CareCertificate
      : false,
      MandatoryTraining: false,
      MedicationAdministration: GetCarerInfo.trainingHistory
      ? GetCarerInfo.trainingHistory.MedicationAdministration
      : false,
      MovingHandling : GetCarerInfo.trainingHistory
      ? GetCarerInfo.trainingHistory.MovingHandling
      : false,
      InfectionControl: GetCarerInfo.trainingHistory
      ? GetCarerInfo.trainingHistory.InfectionControl
      : false,
      CovidCertificate: GetCarerInfo.trainingHistory
      ? GetCarerInfo.trainingHistory.CovidCertificate
      : false,
      pegFeed: GetCarerInfo.HasAllergies
      ? GetCarerInfo.HasAllergies.pegFeed
      : false,
      EndOfLifePalliative: GetCarerInfo.trainingHistory
      ? GetCarerInfo.trainingHistory.EndOfLifePalliative
      : false,
      SkinIntegrity: GetCarerInfo.trainingHistory
      ? GetCarerInfo.trainingHistory.SkinIntegrity
      : false,
      Pmva: GetCarerInfo.trainingHistory
      ? GetCarerInfo.trainingHistory.Pmva
      : false,
      Oxygen: GetCarerInfo.trainingHistory
      ? GetCarerInfo.trainingHistory.Oxygen
      : false,
      Demetia: GetCarerInfo.trainingHistory
      ? GetCarerInfo.trainingHistory.Demetia
      : false,
      MentalHealth: GetCarerInfo.trainingHistory
      ? GetCarerInfo.trainingHistory.MentalHealth
      : false,
      CoughAssist: GetCarerInfo.trainingHistory
      ? GetCarerInfo.trainingHistory.CoughAssist
      : false,
      Tracheostomy: GetCarerInfo.trainingHistory
      ? GetCarerInfo.trainingHistory.Tracheostomy
      : false,
      SpinalInjuries: GetCarerInfo.trainingHistory
      ? GetCarerInfo.trainingHistory.SpinalInjuries
      : false,
      BrainInjuries: GetCarerInfo.trainingHistory
      ? GetCarerInfo.trainingHistory.BrainInjuries
      : false,
      LearningDisability: GetCarerInfo.trainingHistory
      ? GetCarerInfo.trainingHistory.LearningDisability
      : false,
      Catheter: GetCarerInfo.trainingHistory
      ? GetCarerInfo.trainingHistory.Catheter
      : false,
      Suctioning: GetCarerInfo.trainingHistory
      ? GetCarerInfo.trainingHistory.Suctioning
      : false,
      StomaBag: GetCarerInfo.trainingHistory
      ? GetCarerInfo.trainingHistory.StomaBag
      : false,
      MandatoryTraining : GetCarerInfo.trainingHistory
    ? GetCarerInfo.trainingHistory.MandatoryTraining
    : false,
      EarNoseThroat: GetCarerInfo.trainingHistory
      ? GetCarerInfo.trainingHistory.EarNoseThroat
      : false,
  
    };

    const validationSchema = Yup.object({});

    React.useEffect(() => {
      dispatch(GetCarerInfoAction({id:carer?.carer?.id}))
    }, [TrainingHistory,updateTraining]);

    const handleUpdate = (values) => {
    
      values.id = GetCarerInfo.trainingHistory.id
      console.log("VALUEE", values);
      dispatch(UpdateTrainingAction(values))
    };

  const Head = (props) => {
    return (
      <div className="border-b mb-5">
        <label className="text-xl font-medium text-gray-700">
          {props.title}
        </label>
      </div>
    );
  };

  return ( !GetCarerInfo?.trainingHistory ? <CarerTraining GetCarerInfo={GetCarerInfo}/> :
    <div className="w-full p-5">
      <Formik
       initialValues={initialValues}
       validationSchema={validationSchema}
       onSubmit={(values) => handleUpdate(values)}
       >
        {({formik, values}) => {
          return (
            <Form className="text-left">
              <div className="text-left font-bold text-gray-600 p-4 text-md bg-gray-100 mb-5 rounded uppercase">
              Training History
                    </div>
              <div className="border p-5 rounded ">
              
                <div className="grid grid-cols-2 gap-2">
                <SimpleSwitch
                    name="CareCertificate"
                    label="Care Certificate"
                    checked={values.CareCertificate === true}
                  />

                  <SimpleSwitch
                    name="MedicationAdministration"
                    label="Medication Administration"
                    checked={values.MedicationAdministration === true}
                  />

                  <SimpleSwitch
                    name="MovingHandling"
                    label="Moving & Handling"
                    checked={values.MovingHandling === true}
                  />

                  <SimpleSwitch
                    name="InfectionControl"
                    label="Infection Control"
                    checked={values.InfectionControl === true}
                  />

                  <SimpleSwitch
                    name="CovidCertificate"
                    label="Covid Certificate"
                    checked={values.CovidCertificate === true}
                  />
                  <SimpleSwitch
                    name="EndOfLifePalliative"
                    label="End Of Life Palliative"
                    checked={values.EndOfLifePalliative === true}
                  />
                  {/* <SimpleSwitch
                    name="MentalIllness"
                    label="MentalIllness"
                    checked={values.MentalIllness === true}
                  /> */}
                  <SimpleSwitch
                    name="SkinIntegrity"
                    label="Skin Integrity"
                    checked={values.SkinIntegrity === true}
                  />

                  <SimpleSwitch
                    name="Pmva"
                    label="Pmva"
                    checked={values.Pmva === true}
                  />

                  <SimpleSwitch
                    name="Oxygen"
                    label="Oxygen"
                    checked={values.Oxygen === true}
                  />

                  <SimpleSwitch
                    name="Demetia"
                    label="Demetia"
                    checked={values.Demetia === true}
                  />

                  <SimpleSwitch
                    name="MentalHealth"
                    label="Mental Health"
                    checked={values.MentalHealth === true}
                  />

                  <SimpleSwitch
                    name="CoughAssist"
                    label="Cough Assist"
                    checked={values.CoughAssist === true}
                  />
                  <SimpleSwitch
                    name="Tracheostomy"
                    label="Tracheostomy"
                    checked={values.Tracheostomy === true}
                  />
                  <SimpleSwitch
                    name="SpinalInjuries"
                    label="Spinal Injuries"
                    checked={values.SpinalInjuries === true}
                  />
                  <SimpleSwitch
                    name="BrainInjuries"
                    label="Brain Injuries"
                    checked={values.BrainInjuries === true}
                  />
                  <SimpleSwitch
                    name="LearningDisability"
                    label="Learning Disability"
                    checked={values.LearningDisability === true}
                  />
                  <SimpleSwitch
                    name="GastrointestinalDisease"
                    label="Gastro Intestinal Disease"
                    checked={values.Catheter === true}
                  />

                  <SimpleSwitch
                    name="Catheter"
                    label="Catheter"
                    checked={values.Catheter === true}
                  />
                  <SimpleSwitch
                    name="StomaBag"
                    label="Stoma Bag"
                    checked={values.StomaBag === true}
                  />
                  <SimpleSwitch
                    name="MandatoryTraining"
                    label="Mandatory Training"
                    checked={values.MandatoryTraining === true}
                  />
                  <SimpleSwitch
                    name="EarNoseThroat"
                    label="Ear, Nose & Throat"
                    checked={values.EarNoseThroat === true}
                  />
                </div>
              </div>
              <div className="mb-3 mt-5 flex flex-row-reverse">
                <div></div>
                {isLoadingupdateTraining ? <PageLoader/> : <SubmitButton label="Update" />}
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default TrainingHistory;
