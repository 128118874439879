import {updatePersonalDetailsPending, updatePersonalDetailsSuccess, updatePersonalDetailsFail} from '../RegisterCarerSlices/UpdatePersonalDetailsSlice'
import {UpdatePersonal} from '../../api/carerApi'

 const UpdatePersonalInfoAction = (data) => async dispatch => {

    try {
        dispatch(updatePersonalDetailsPending());
        const result = await UpdatePersonal(data);
        if(result){
            return dispatch(updatePersonalDetailsSuccess(result))
        }
    } catch (error) {
        dispatch(updatePersonalDetailsFail(error))
    }
} 

export default UpdatePersonalInfoAction