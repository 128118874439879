import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  EditPassport: {},
  isLoadingEditPassport: false,
  errorEditPassport: "",
};

const EditPassportSlice = createSlice({
  name: "EditPassport",
  initialState,
  reducers: {
    EditPassportPending: (state) => {
      state.isLoadingEditPassport = true;
    },
    EditPassportSuccess: (state, { payload }) => {
      state.isLoadingEditPassport = false;
      state.EditPassport = payload;
      state.errorEditPassport = "";
    },
    EditPassportFail: (state, { payload }) => {
      state.isLoadingEditPassport = false;
      state.errorEditPassport = payload;
    },
  },
});

export const { EditPassportPending, EditPassportSuccess, EditPassportFail } =
  EditPassportSlice.actions;

export default EditPassportSlice.reducer;
