import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    getClientMarcharts: {},
    isLoadingClientMarcharts: false,
    errorClientMarcharts: ''
}

const ClientMarchartsPlansSlice = createSlice({
    name: 'getClientMarcharts',
    initialState,
    reducers:{
        getClientMarchartsPending: (state) => {
            state.isLoadingClientMarcharts = true;
        },
        getClientMarchartsSuccess: (state, {payload}) => {
            state.isLoadingClientMarcharts = false;
            state.getClientMarcharts = payload;
            state.errorClientMarcharts = '';
        },
       getClientMarchartsFail: (state, {payload}) => {
            state.isLoadingClientMarcharts = false;
            state.errorClientMarcharts = payload;
        }
    }
})

export const {getClientMarchartsPending, getClientMarchartsSuccess, getClientMarchartsFail} = ClientMarchartsPlansSlice.actions

export default ClientMarchartsPlansSlice.reducer