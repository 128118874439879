import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  HealthDeclaration: {},
  isLoadingHealthDeclaration: false,
  errorHealthDeclaration: "",
};

const HealthDeclarationSlice = createSlice({
  name: "HealthDeclaration",
  initialState,
  reducers: {
    HealthDeclarationPending: (state) => {
      state.isLoadingHealthDeclaration = true;
    },
    HealthDeclarationSuccess: (state, { payload }) => {
      state.isLoadingHealthDeclaration = false;
      state.HealthDeclaration = payload;
      state.errorHealthDeclaration = "";
    },
    HealthDeclarationFail: (state, { payload }) => {
      state.isLoadingHealthDeclaration = false;
      state.errorHealthDeclaration = payload;
    },
  },
});

export const { HealthDeclarationPending, HealthDeclarationSuccess, HealthDeclarationFail } =
  HealthDeclarationSlice.actions;

export default HealthDeclarationSlice.reducer;
