import {changePasswordPending, changePasswordSuccess, changePasswordFail} from './changePasswordSlice'
import {changePassword} from '../../api/userApi'

 const myAccount = (data) => async dispatch => {

    try {
        dispatch(changePasswordPending());
        const result = await changePassword(data);
        if(result){
            return dispatch(changePasswordSuccess(result))
        }
    } catch (error) {
        dispatch(changePasswordFail(error))
    }
} 

export default myAccount