import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    getInvoices: {},
    isLoadingGetInvoices: false,
    errorGetInvoices: ''
}

const getInvoicesSlice = createSlice({
    name: 'getInvoices',
    initialState,
    reducers:{
        getInvoicesPending: (state) => {
            state.isLoadingGetInvoices = true;
        },
        getInvoicesSuccess: (state, {payload}) => {
            state.isLoadingGetInvoices = false;
            state.getInvoices = payload;
            state.errorGetInvoices = '';
        },
        getInvoicesFail: (state, {payload}) => {
            state.isLoadingGetInvoices = false;
            state.errorGetInvoices = payload;
        }
    }
})

export const {getInvoicesPending, getInvoicesSuccess, getInvoicesFail} = getInvoicesSlice.actions

export default getInvoicesSlice.reducer