import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    updateRegulatorCarersStatus: {},
    isLoadingupdateRegulatorCarersStatus: false,
    errorupdateRegulatorCarersStatus: ''
}

const updateRegulatorCarersStatusSlice = createSlice({
    name: 'updateRegulatorCarersStatus',
    initialState,
    reducers:{
        updateRegulatorCarersStatusPending: (state) => {
            state.isLoadingupdateRegulatorCarersStatus = true;
        },
        updateRegulatorCarersStatusSuccess: (state, {payload}) => {
            state.isLoadingupdateRegulatorCarersStatus = false;
            state.updateRegulatorCarersStatus = payload;
            state.errorupdateRegulatorCarersStatus = '';
        },
        updateRegulatorCarersStatusFail: (state, {payload}) => {
            state.isLoadingupdateRegulatorCarersStatus = false;
            state.errorupdateRegulatorCarersStatus = payload;
        }
    }
})

export const {updateRegulatorCarersStatusPending, updateRegulatorCarersStatusSuccess, updateRegulatorCarersStatusFail} = updateRegulatorCarersStatusSlice.actions

export default updateRegulatorCarersStatusSlice.reducer