import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    clientContacts: {},
    isLoadingClientContacts: false,
    errorClientContacts: ''
}

const clientContactsSlice = createSlice({
    name: 'clientContacts',
    initialState,
    reducers:{
        clientContactsPending: (state) => {
            state.isLoadingClientContacts = true;
        },
        clientContactsSuccess: (state, {payload}) => {
            state.isLoadingClientContacts = false;
            state.clientContacts = payload;
            state.errorClientContacts = '';
        },
        clientContactsFail: (state, {payload}) => {
            state.isLoadingClientContacts = false;
            state.errorClientContacts = payload;
        }
    }
})

export const {clientContactsPending, clientContactsSuccess, clientContactsFail} = clientContactsSlice.actions

export default clientContactsSlice.reducer