import React from "react";
import { Sidebar } from "../../../layouts/auth/Sidebar";
import { TopNavigation } from "../../../layouts/auth/TopNavigation";
import { useNavigate } from "react-router-dom";
import FormikController from "../../../components/Formik/FormikController";
import SubmitButton from "../../../components/Formik/SubmitButton";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { FaJenkins } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import ProgressBar from "./ProgressBar";
import { getStaff } from "../../staff/staffActions/staffAction";
import addProperty from "../ClientActions/ClientPropertyAction";
import { Field } from "formik";
import PageLoader from "../../../components/PageLoader";

function PropertyAccess({getClientInfo}) {
  // console.log('getClientInfo',getClientInfo);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { staff, isLoading } = useSelector((state) => state.getstaff);
  const { clientProperty,isLoadingClientProperty } = useSelector((state) => state.addProperty);
  const itemsCountPerPage = 10;

  const [page, setPage] = React.useState(1);

  let admins =
    staff && staff.data
      ? staff.data.map((cxt) => {
          let obj = {
            key: cxt.firstname + " " + cxt.lastname,
            value: cxt.id,
          };
          return obj;
        })
      : [];

  const { addClientPersonalDetails } = useSelector(
    (state) => state.addClientPersonal
  );

  React.useEffect(() => {
    dispatch(
      getStaff({
        page,
        perPage: itemsCountPerPage,
      })
    );
  }, [addClientPersonalDetails]);

  const initialValues = {
    pathwaysAndDriveways: false,
    pathwaysAndDrivewaysComment: "",
    doorwaysEntryExits: false,
    doorwaysEntryExitsComment: "",
    Entrance: false,
    EntranceComment: "",
    stairwaysAndStairwells: false,
    stairwaysAndStairwellsComment: "",
    electricalHazards: false,
    electricalHazardsComment: "",
    trippoingFallingStumbling: false,
    trippingFallingStumblingComment: "",
    kitchens: false,
    kitchensComment: "",
    bathrooms: false,
    bathroomsComment: "",
    otherAreas: false,
    otherAreasComment: "",
    propertyDescription: "",
    whoAmI: "",
    propertyRisk: "",
    propertyKeyCode: "",
    diagnosisDetails: "",
    historyBackground: "",
    likeDislikes: "",
    documents: "",
    mobility: "",
    knownAllergies: "",
    painExpression: "",
    safetyIssues: "",
    Diabetes: false,
    Epilepsy: false,
    Asthma: false,
    SpinaBifina: false,
    OtherMedical: false,
    otherDetails: "",
    dietDiebetes: false,
    vegetarian: false,
    vegan: false,
    specialDiet: false,
    specialDietDetails: "",
    smoker: false,
    cat: false,
    dog: false,
    eatingDrinkingNeeds: "",
    specialistEquipment: "",
  };
  const FILE_SIZE = 1024 * 1024 * 10;
  const SUPPORTED_FORMATS = [
    "application/pdf",
    "image/jpg",
    "image/jpeg",
    "image/png",
  ];
  const validationSchema = Yup.object({
    // propertyDescription: Yup.string().required("This field is required"),
    // whoAmI: Yup.string().required("This field is required"),
    // diagnosisDetails: Yup.string().required("This field is required"),
    // historyBackground: Yup.string().required("This field is required"),
    // likeDislikes: Yup.string().required("This field is required"),
    // mobility: Yup.string().required("This field is required"),
    // documents: Yup.mixed()
    //   .test(
    //     "format",
    //     "Only the following formats are accepted: .pdf, .jpeg, .jpg, .png",
    //     (value) => !value || (value && SUPPORTED_FORMATS.includes(value.type))
    //   )
    //   .test(
    //     "fileSize",

    //     "File too large",

    //     (value) =>
    //       value === null || ((value) => value && value.size <= FILE_SIZE)
    //   )
    //   .required("Required"),
  });

  const handleSubmit = (data) => {
    console.log('data',data);
    data.id = addClientPersonalDetails.id;
    dispatch(addProperty(data));
    navigate("/risk-assessment");
  };

  const handleUpdate = (values) => {
    console.log("VALUEE", values);
    values.id = getClientInfo?.id
    dispatch(addProperty(values));
  };

  let mx = true;

  const Head = (props) => {
    return (
      <div className="border-b mb-5">
        <label className="text-xl font-medium text-gray-700">
          {props.title}
        </label>
      </div>
    );
  };

  const goBack = () => {
    navigate("/referral-contracts");
  };
  return (
    <div>
       {(getClientInfo && !getClientInfo?.kyc) ? "" : <Sidebar />}
      {(getClientInfo && !getClientInfo?.kyc) ? "" : <TopNavigation title="Add Client" />}

      <div className="bg-white ">
       <div className={(getClientInfo && !getClientInfo?.kyc) ? "mt-5" : "flex ml-80 mr-16 mt-5 h-screen"}>
        {(getClientInfo && !getClientInfo?.kyc) ? "" :  <div className="w-40 border mt-5 p-3 rounded-lg bg-gray-50">
            <ProgressBar number={80} />
          </div>}

          <div className="w-full p-5">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values) => (getClientInfo && !getClientInfo?.kyc) ? handleUpdate(values) : handleSubmit(values)
              }
            >
              {({ setFieldValue, values, ...rest }) => {
                return (
                  <Form className="text-left">
                   {(getClientInfo && !getClientInfo?.kyc) ? "": <nav
                      className="flex py-3 px-5 mb-2 text-gray-700 bg-gray-50 rounded-lg border border-gray-200 dark:bg-gray-800 dark:border-gray-700"
                      aria-label="Breadcrumb"
                    >
                      <ol className="inline-flex items-center space-x-1 md:space-x-3">
                        <li className="inline-flex items-center">
                          <a
                            href="#"
                            className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white"
                          >
                            <FaJenkins className="mr-2 w-4 h-4 font-bold" />{" "}
                            {addClientPersonalDetails.firstname}{" "}
                            {addClientPersonalDetails.lastname}
                          </a>
                        </li>
                        <li>
                          <div className="flex items-center">
                            <svg
                              className="w-6 h-6 text-gray-400"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                clip-rule="evenodd"
                              ></path>
                            </svg>
                            <a
                              href="#"
                              className="ml-1 text-sm font-medium text-gray-700 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white"
                            >
                              Referral Contract
                            </a>
                            <svg
                              className="w-6 h-6 text-gray-400"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                clip-rule="evenodd"
                              ></path>
                            </svg>
                          </div>
                        </li>
                        <li>
                          <div className="flex items-center">
                            <a
                              href="#"
                              className=" text-sm font-medium text-gray-700 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white"
                            >
                              Key Contacts
                            </a>
                            <svg
                              className="w-6 h-6 text-gray-400"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                clip-rule="evenodd"
                              ></path>
                            </svg>
                          </div>
                        </li>
                        <li>
                          <div className="flex items-center">
                            <a
                              href="#"
                              className=" text-sm font-medium text-gray-700 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white"
                            >
                              Property Access
                            </a>
                            <svg
                              className="w-6 h-6 text-gray-400"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                clip-rule="evenodd"
                              ></path>
                            </svg>
                          </div>
                        </li>
                      </ol>
                    </nav>}
                    <div className="border p-5 rounded">
                      <div className="">
                        <Head title="Property Risk Assessment" />
                      </div>
                      <div className="">
                        <span className="font-medium">
                          Please Specify Nature of Risk Identified
                        </span>
                        <br />
                        <div className="border rounded p-3">
                          <label>
                            <Field
                              type="checkbox"
                              name="pathwaysAndDriveways"
                              value={true}
                              checked={values.pathwaysAndDriveways === true}
                            />
                            <span className="ml-2">
                              Pathways & Drives Detail
                            </span>
                          </label>
                          {values.pathwaysAndDriveways === true && (
                            <div>
                              <div className="mt-2">
                                <FormikController
                                  control="textArea"
                                  type="text"
                                  name="pathwaysAndDrivewaysComment"
                                  label="Action required to minimise the risk"
                                />
                              </div>
                            </div>
                          )}
                          <br />
                          <label>
                            <Field
                              type="checkbox"
                              name="doorwaysEntryExits"
                              value={true}
                              checked={values.doorwaysEntryExits === true}
                            />
                            <span className="ml-2">
                              Doorways, Entry & Exits
                            </span>
                          </label>
                          {values.doorwaysEntryExits === true && (
                            <div>
                              <div className="mt-2">
                                <FormikController
                                  control="textArea"
                                  type="text"
                                  name="doorwaysEntryExitsComment"
                                  label="Action required to minimise the risk"
                                />
                              </div>
                            </div>
                          )}
                          <br />
                          <label>
                            <Field
                              type="checkbox"
                              name="Entrance"
                              value={true}
                              checked={values.Entrance === true}
                            />
                            <span className="ml-2">Entrance</span>
                          </label>
                          {values.Entrance === true && (
                            <div>
                              <div className="mt-2">
                                <FormikController
                                  control="textArea"
                                  type="text"
                                  name="EntranceComment"
                                  label="Action required to minimise the risk"
                                />
                              </div>
                            </div>
                          )}
                          <br />
                          <label>
                            <Field
                              type="checkbox"
                              name="stairwaysAndStairwells"
                              value={true}
                              checked={values.stairwaysAndStairwells === true}
                            />
                            <span className="ml-2">Stairways & Stairwells</span>
                          </label>
                          {values.stairwaysAndStairwells === true && (
                            <div>
                              <div className="mt-2">
                                <FormikController
                                  control="textArea"
                                  type="text"
                                  name="stairwaysAndStairwellsComment"
                                  label="Action required to minimise the risk"
                                />
                              </div>
                            </div>
                          )}
                          <br />
                          <label>
                            <Field
                              type="checkbox"
                              name="electricalHazards"
                              value={true}
                              checked={values.electricalHazards === true}
                            />
                            <span className="ml-2">Electrical hazards</span>
                          </label>

                          {values.electricalHazards === true && (
                            <div>
                              <div className="mt-2">
                                <FormikController
                                  control="textArea"
                                  type="text"
                                  name="electricalHazardsComment"
                                  label="Action required to minimise the risk"
                                />
                              </div>
                            </div>
                          )}
                          <br />
                          <label>
                            <Field
                              type="checkbox"
                              name="trippoingFallingStumbling"
                              value={true}
                              checked={
                                values.trippoingFallingStumbling === true
                              }
                            />
                            <span className="ml-2">
                              Tripping, Falling & Stumbling
                            </span>
                          </label>
                          {values.trippoingFallingStumbling === true && (
                            <div>
                              <div className="mt-2">
                                <FormikController
                                  control="textArea"
                                  type="text"
                                  name="trippingFallingStumblingComment"
                                  label="Action required to minimise the risk"
                                />
                              </div>
                            </div>
                          )}
                          <br />
                          <label>
                            <Field
                              type="checkbox"
                              name="kitchens"
                              value={true}
                              checked={values.kitchens === true}
                            />
                            <span className="ml-2">Kitchens</span>
                          </label>

                          {values.kitchens === true && (
                            <div>
                              <div className="mt-2">
                                <FormikController
                                  control="textArea"
                                  type="text"
                                  name="kitchensComment"
                                  label="Action required to minimise the risk"
                                />
                              </div>
                            </div>
                          )}
                          <br />
                          <label>
                            <Field
                              type="checkbox"
                              name="bathrooms"
                              value={true}
                              checked={values.bathrooms === true}
                            />
                            <span className="ml-2">Bathrooms</span>
                          </label>
                          {values.bathrooms === true && (
                            <div>
                              <div className="mt-2">
                                <FormikController
                                  control="textArea"
                                  type="text"
                                  name="bathroomsComment"
                                  label="Action required to minimise the risk"
                                />
                              </div>
                            </div>
                          )}
                          <br />
                          <label>
                            <Field
                              type="checkbox"
                              name="otherAreas"
                              value={true}
                              checked={values.otherAreas === true}
                            />
                            <span className="ml-2">Other areas</span>
                          </label>

                          {values.otherAreas === true && (
                            <div>
                              <div className="mt-2">
                                <FormikController
                                  control="textArea"
                                  type="text"
                                  name="otherAreasComment"
                                  label="Nature of the risk identified"
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="mt-5">
                        <Head title="Property Access Instruction" />
                      </div>
                      <div className="grid grid-cols-1 gap-2 mb-5">
                        <FormikController
                          control="textArea"
                          type="text"
                          name="propertyDescription"
                          label="Description"
                        />
                            <FormikController
                    control="textArea"
                    type="text"
                    name="propertyRisk"
                    label="Property Risk Assessment"
                  />
                        <FormikController
                          control="input"
                          type="text"
                          name="propertyKeyCode"
                          label="Key Code Number"
                        />
                      </div>
                      <FormikController
                          control="input"
                          type="file"
                          label="Upload Documents"
                          name="documents"
                          value={null}
                          onChange={(event) =>
                            setFieldValue("documents", event.target.files[0])
                          }
                        />

        
                    </div>
                    <div className="mb-3 mt-5 flex justify-between">
                    {(getClientInfo && !getClientInfo?.kyc) ? "": <div className="mb-3 pt-3">
                        <button
                          className="bg-button text-white w-full rounded p-1.5 text-lg px-10"
                          onClick={() => goBack()}
                        >
                          Back
                        </button>
                      </div>}
                      {isLoadingClientProperty ? <PageLoader/> : <SubmitButton label="Save" />}
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PropertyAccess;
