import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    editClientTiers: {},
    isLoadingEditTiers: false,
    errorEditTier: ''
}

const editClientTiersSlice = createSlice({
    name: 'editClientTiers',
    initialState,
    reducers:{
        editClientTiersPending: (state) => {
            state.isLoadingEditTiers = true;
        },
        editClientTiersSuccess: (state, {payload}) => {
            state.isLoadingEditTiers = false;
            state.editClientTiers = payload;
            state.errorEditTier = '';
        },
        editClientTiersFail: (state, {payload}) => {
            state.isLoadingEditTiers = false;
            state.errorEditTier = payload;
        }
    }
})

export const {editClientTiersPending, editClientTiersSuccess, editClientTiersFail} = editClientTiersSlice.actions

export default editClientTiersSlice.reducer