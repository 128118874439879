import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    getMedicationAudit: {},
    isLoadingGetMedicationAudit: false,
    errorGetMedicationAudit: ''
}

const getMedicationAuditSlice = createSlice({
    name: 'getMedicationAudit',
    initialState,
    reducers:{
        getMedicationAuditPending: (state) => {
            state.isLoadingGetMedicationAdit = true;
        },
        getMedicationAuditSuccess: (state, {payload}) => {
            state.isLoadingGetMedicationAudit = false;
            state.getMedicationAudit = payload;
            state.errorGetMedicationAudit = '';
        },
        getMedicationAuditFail: (state, {payload}) => {
            state.isLoadingGetMedicationAudit = false;
            state.errorGetMedicationAudit = payload;
        }
    }
})

export const {getMedicationAuditPending, getMedicationAuditSuccess, getMedicationAuditFail} = getMedicationAuditSlice.actions

export default getMedicationAuditSlice.reducer