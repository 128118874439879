import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  DbsApplication: {},
  isLoadingDbsApplication: false,
  errorDbsApplication: "",
};

const DbsApplicationSlice = createSlice({
  name: "DbsApplication",
  initialState,
  reducers: {
    DbsApplicationPending: (state) => {
      state.isLoadingDbsApplication = true;
    },
    DbsApplicationSuccess: (state, { payload }) => {
      state.isLoadingDbsApplication = false;
      state.DbsApplication = payload;
      state.errorDbsApplication = "";
    },
    DbsApplicationFail: (state, { payload }) => {
      state.isLoadingDbsApplication = false;
      state.errorDbsApplication = payload;
    },
  },
});

export const { DbsApplicationPending, DbsApplicationSuccess, DbsApplicationFail } =
  DbsApplicationSlice.actions;

export default DbsApplicationSlice.reducer;
