import React from "react";
import { Sidebar } from "../../../layouts/auth/Sidebar";
import { TopNavigation } from "../../../layouts/auth/TopNavigation";
import SimpleSwitch from "../../../components/SimpleSwitch";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import ProgressBar from "../../clients/AddClient/ProgressBar";
import SubmitButton from "../../../components/Formik/SubmitButton";
import FormikController from "../../../components/Formik/FormikController";
import { useDispatch, useSelector } from "react-redux";
import BackgroundAction from "../RegisterCarerActions/BackgroundAction";
import UpdateBackgroundAction from "../RegisterCarerActions/UpdateBackgroundAction";
import GetCarerInfoAction from '../../carers/RegisterCarerActions/GetCarerInfoAction';
import PageLoader from "../../../components/PageLoader";

function CarerBackground({ carer }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { signUp, isLoadingSignUp, errorSignUp } = useSelector(
    (state) => state.signUpCarer
  );
  const { updateBackground, isLoadingupdateBackground } = useSelector((state) => state.UpdateBackground);
  const { Background, isLoadingBackground } = useSelector((state) => state.Background);
  React.useEffect(() => {
    dispatch(GetCarerInfoAction({id:carer?.carer?.id}))
  }, [updateBackground]);

  const {GetCarerInfo, isLoadingGetCarerInfo} = useSelector((state)=>state.GetCarerInfoSliceReducer)
  // console.log('BACKTOBACK',GetCarerInfo);


  const initialValues = {
    hasDogs: GetCarerInfo?.background?.hasDogs ? GetCarerInfo.background.hasDogs : false,
    hasCats: GetCarerInfo?.background?.hasCats ? GetCarerInfo.background.hasCats : false,
    smokes: GetCarerInfo?.background?.smokes ? GetCarerInfo.background.smokes : false,
    Demetia: GetCarerInfo?.background?.Demetia ? GetCarerInfo.background.Demetia : false,
    MentalHealthProblems: GetCarerInfo?.background?.MentalHealthProblems
      ? GetCarerInfo?.background.MentalHealthProblems
      : false,
    Diabetes: GetCarerInfo?.background?.Diabetes ? GetCarerInfo.background.Diabetes : false,
    LearningDisability: GetCarerInfo?.background?.LearningDisability
      ? GetCarerInfo.background.LearningDisability
      : false,
    Autism: GetCarerInfo?.background?.Autism ? GetCarerInfo.background.Autism : false,
    MultipleSclerosis: GetCarerInfo?.background?.MultipleSclerosis
      ? GetCarerInfo.background.MultipleSclerosis
      : false,
    SpinalBrainInjuries: GetCarerInfo?.background?.SpinalBrainInjuries
      ? GetCarerInfo.background.SpinalBrainInjuries
      : false,
    ParkinsonDisease: GetCarerInfo?.background?.ParkinsonDisease
      ? GetCarerInfo.background.ParkinsonDisease
      : false,
    EpilepsySeizures: GetCarerInfo?.background?.EpilepsySeizures
      ? GetCarerInfo.background.EpilepsySeizures
      : false,
    BuccalMidazolamAdministration: GetCarerInfo?.background?.BuccalMidazolamAdministration
      ? GetCarerInfo.background.BuccalMidazolamAdministration
      : false,
    PalliativeCare: GetCarerInfo?.background?.PalliativeCare
      ? GetCarerInfo.background.PalliativeCare
      : false,
    EndOfLife: GetCarerInfo?.background?.EndOfLife ? GetCarerInfo.background.EndOfLife : false,
    Companionship: GetCarerInfo?.background?.Companionship ? GetCarerInfo.background.Companionship : false,
    HasPegInSitu: GetCarerInfo?.background?.HasPegInSitu ? GetCarerInfo.background.HasPegInSitu : false,
    HasCatheterInSitu: GetCarerInfo?.background?.HasCatheterInSitu
      ? GetCarerInfo.background.HasCatheterInSitu
      : false,
    HasSuprapubicCatheter: GetCarerInfo?.background?.HasSuprapubicCatheter
      ? GetCarerInfo.background.HasSuprapubicCatheter
      : false,
    HasStomaBag: GetCarerInfo?.background?.HasStomaBag ? GetCarerInfo.background.HasStomaBag : false,
    HasTracheostomy: GetCarerInfo?.background?.HasTracheostomy
      ? GetCarerInfo.background.HasTracheostomy
      : false,
    RequiresOralSuctioning: GetCarerInfo?.background?.RequiresOralSuctioning
      ? GetCarerInfo.background.RequiresOralSuctioning
      : false,
    RequiresDeepSuctioning: GetCarerInfo?.background?.RequiresDeepSuctioning
      ? GetCarerInfo.background.RequiresDeepSuctioning
      : false,
    UsesOverheadHoist: GetCarerInfo?.background?.UsesOverheadHoist
      ? GetCarerInfo.background.UsesOverheadHoist
      : false,
    UsesMobileHoist: GetCarerInfo?.background?.UsesMobileHoist
      ? GetCarerInfo.background.UsesMobileHoist
      : false,
    UsesRotaStand: GetCarerInfo?.background?.UsesRotaStand ? GetCarerInfo.background.UsesRotaStand : false,
    UsesSaraSteady: GetCarerInfo?.background?.UsesSaraSteady
      ? GetCarerInfo.background.UsesSaraSteady
      : false,
    RaiserStandAidOrOthers: GetCarerInfo?.background?.RaiserStandAidOrOthers
      ? GetCarerInfo.background.RaiserStandAidOrOthers
      : false,
    UsesBathLift: GetCarerInfo?.background?.UsesBathLift ? GetCarerInfo.background.UsesBathLift : false,
    UsesWheelChair: GetCarerInfo?.background?.UsesWheelChair
      ? GetCarerInfo.background.UsesWheelChair
      : false,
    HasMobileVehicle: GetCarerInfo?.background?.HasMobileVehicle
      ? GetCarerInfo.background.HasMobileVehicle
      : false,
    UsesSlideSheets: GetCarerInfo?.background?.UsesSlideSheets
      ? GetCarerInfo.background.UsesSlideSheets
      : false,
    UsesMobileOxygen: GetCarerInfo?.background?.UsesMobileOxygen
      ? GetCarerInfo.background.UsesMobileOxygen
      : false,
    UsesNippyVentilator: GetCarerInfo?.background?.UsesNippyVentilator
      ? GetCarerInfo.background.UsesNippyVentilator
      : false,
    // accessToken: signUp.access_token,
  };

  const validationSchema = Yup.object({});

  const Head = (props) => {
    return (
      <div className=" mb-2">
        <label className="text-xl font-medium div-gray-700">
          {props.title}
        </label>
      </div>
    );
  };

  const handleSubmit = (values) => {
    console.log("VALUEE", values);
    GetCarerInfo && !GetCarerInfo?.background ? values.id = GetCarerInfo?.id  : values.id = signUp.user.id 
  
    dispatch(BackgroundAction(values))
    !GetCarerInfo && navigate("/carer-agreement")
  };

  const handleUpdate = (values) => {
    // values.accessToken = Login.access_token;
    values.id = GetCarerInfo?.background?.id;
    console.log("VAL", values);
    dispatch(UpdateBackgroundAction(values));
    // navigate("/carer-agreement")
  };

  return (
    <div className=" h-screen">
     {!carer && <Sidebar />}
     {!carer && <TopNavigation title="Add Carer" />}
      <div className={carer ? "" : "bg-gray-50"}>
        <div className={carer ? "" : "flex ml-80 mr-16 mt-5"}>
         {!carer && <div className="w-40 border mt-5 p-3 rounded-lg bg-gray-50">
            <ProgressBar number={95} />
          </div>}
          <div className="w-full p-5">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values) => (GetCarerInfo && GetCarerInfo?.background ) ? handleUpdate(values) : handleSubmit(values)}
            >
              {({ formik, values }) => {
                return (
                  <Form className="text-left">
                    <div className="text-left font-bold text-gray-600 p-4 text-md bg-gray-100 mb-5 rounded uppercase">
                      background
                    </div>
                    <div className="bg-white shadow p-5 rounded ">
                      <div className="text-left font-semibold text-gray-600 mb-5">
                        Please select YES if you are trained to support someone
                        who:
                      </div>
                      <div>
                        <div className="grid grid-cols-1 gap-2">
                          <div>
                            <SimpleSwitch
                              name="hasDogs"
                              label="Has Dogs"
                              checked={values.hasDogs === true}
                            />
                          </div>

                          <div>
                            <SimpleSwitch
                              name="hasCats"
                              label="Has Cats"
                              checked={values.hasCats === true}
                            />
                          </div>

                          <div>
                            <SimpleSwitch
                              name="smokes"
                              label="Smokes"
                              checked={values.smokes === true}
                            />
                          </div>
                          <div className="text-left font-semibold text-gray-600 mt-4 mb-5">
                            Please select YES if you are trained to support
                            someone who suffers from the following:
                          </div>

                          <div>
                            <SimpleSwitch
                              name="Demetia"
                              label="Demetia"
                              checked={values.Demetia === true}
                            />
                          </div>

                          <div>
                            <SimpleSwitch
                              name="Diabetes"
                              label="Diabetes"
                              checked={values.Diabetes === true}
                            />
                          </div>

                          <div>
                            <SimpleSwitch
                              name="MentalHealthProblems"
                              label="Mental Health Problems"
                              checked={values.MentalHealthProblems === true}
                            />
                          </div>

                          <div>
                            <SimpleSwitch
                              name="LearningDisability"
                              label="Learning Disability"
                              checked={values.LearningDisability === true}
                            />
                          </div>

                          <div>
                            <SimpleSwitch
                              name="Autism"
                              label="Autism"
                              checked={values.Autism === true}
                            />
                          </div>

                          <div>
                            <SimpleSwitch
                              name="MultipleSclerosis"
                              label="Multiple Sclerosis"
                              checked={values.MultipleSclerosis === true}
                            />
                          </div>

                          <div>
                            <SimpleSwitch
                              name="SpinalBrainInjuries"
                              label="Spinal Brain Injuries"
                              checked={values.SpinalBrainInjuries === true}
                            />
                          </div>

                          <div>
                            <SimpleSwitch
                              name="ParkinsonDisease"
                              label="Parkinson Disease"
                              checked={values.ParkinsonDisease === true}
                            />
                          </div>

                          <div>
                            <SimpleSwitch
                              name="EpilepsySeizures"
                              label="Epilepsy Seizures"
                              checked={values.EpilepsySeizures === true}
                            />
                          </div>

                          <div>
                            <SimpleSwitch
                              name="BuccalMidazolamAdministration"
                              label="Buccal Midazolam Administration"
                              checked={
                                values.BuccalMidazolamAdministration === true
                              }
                            />
                          </div>
                          <div className="text-left font-semibold text-gray-600 mt-4 mb-5">
                            Please select YES if you are trained to support
                            someone who suffers from the following:
                          </div>

                          <div>
                            <SimpleSwitch
                              name="PalliativeCare"
                              label="Palliative Care"
                              checked={values.PalliativeCare === true}
                            />
                          </div>

                          <div>
                            <SimpleSwitch
                              name="EndOfLife"
                              label="End Of Life"
                              checked={values.EndOfLife === true}
                            />
                          </div>

                          <div>
                            <SimpleSwitch
                              name="Companionship"
                              label="Companionship"
                              checked={values.Companionship === true}
                            />
                          </div>

                          <div>
                            <SimpleSwitch
                              name="HasPegInSitu"
                              label="Has Peg InSitu"
                              checked={values.HasPegInSitu === true}
                            />
                          </div>

                          <div>
                            <SimpleSwitch
                              name="HasCatheterInSitu"
                              label="Has Catheter InSitu"
                              checked={values.HasCatheterInSitu === true}
                            />
                          </div>

                          <div>
                            <SimpleSwitch
                              name="HasSuprapubicCatheter"
                              label="Has Suprapubic Catheter"
                              checked={values.HasSuprapubicCatheter === true}
                            />
                          </div>

                          <div>
                            <SimpleSwitch
                              name="HasStomaBag"
                              label="Has Stoma Bag"
                              checked={values.HasStomaBag === true}
                            />
                          </div>

                          <div>
                            <SimpleSwitch
                              name="HasTracheostomy"
                              label="Has Tracheostomy"
                              checked={values.HasTracheostomy === true}
                            />
                          </div>

                          <div>
                            <SimpleSwitch
                              name="RequiresOralSuctioning"
                              label="Requires Oral Suctioning"
                              checked={values.RequiresOralSuctioning === true}
                            />
                          </div>

                          <div>
                            <SimpleSwitch
                              name="RequiresDeepSuctioning"
                              label="Requires Deep Suctioning"
                              checked={values.RequiresDeepSuctioning === true}
                            />
                          </div>

                          <div>
                            <SimpleSwitch
                              name="UsesOverheadHoist"
                              label="Uses Overhead Hoist"
                              checked={values.UsesOverheadHoist === true}
                            />
                          </div>

                          <div>
                            <SimpleSwitch
                              name="UsesMobileHoist"
                              label="Uses Mobile Hoist"
                              checked={values.UsesMobileHoist === true}
                            />
                          </div>

                          <div>
                            <SimpleSwitch
                              name="UsesRotaStand"
                              label="Uses Rota Stand"
                              checked={values.UsesRotaStand === true}
                            />
                          </div>

                          <div>
                            <SimpleSwitch
                              name="UsesSaraSteady"
                              label="Uses Sara Steady"
                              checked={values.UsesSaraSteady === true}
                            />
                          </div>

                          <div>
                            <SimpleSwitch
                              name="RaiserStandAidOrOthers"
                              label="Raiser Stand Aid Or Others"
                              checked={values.RaiserStandAidOrOthers === true}
                            />
                          </div>

                          <div>
                            <SimpleSwitch
                              name="UsesBathLift"
                              label="Uses Bath Lift"
                              checked={values.UsesBathLift === true}
                            />
                          </div>

                          <div>
                            <SimpleSwitch
                              name="UsesWheelChair"
                              label="Uses Wheel Chair"
                              checked={values.UsesWheelChair === true}
                            />
                          </div>

                          <div>
                            <SimpleSwitch
                              name="HasMobileVehicle"
                              label="Has Mobile Vehicle"
                              checked={values.HasMobileVehicle === true}
                            />
                          </div>

                          <div>
                            <SimpleSwitch
                              name="UsesSlideSheets"
                              label="Uses Slide Sheets"
                              checked={values.UsesSlideSheets === true}
                            />
                          </div>

                          <div>
                            <SimpleSwitch
                              name="UsesMobileOxygen"
                              label="Uses Mobile Oxygen"
                              checked={values.UsesMobileOxygen === true}
                            />
                          </div>

                          <div>
                            <SimpleSwitch
                              name="UsesNippyVentilator"
                              label="Uses Nippy Ventilator"
                              checked={values.UsesNippyVentilator === true}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="mb-3 mt-5 flex flex-row-reverse">
                      <div></div>
                      {(isLoadingupdateBackground || isLoadingBackground) ? <PageLoader/> : <SubmitButton label={carer? 'Update': "Save & Next"} />}
                    </div>
                    </div>
                  
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CarerBackground;
