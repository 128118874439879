import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  nextofkin: {},
  isLoadingNextofkin: false,
  errorNextofkin: "",
};

const nextofkinSlice = createSlice({
  name: "nextofkin",
  initialState,
  reducers: {
    nextofkinPending: (state) => {
      state.isLoadingNextofkin = true;
    },
    nextofkinSuccess: (state, { payload }) => {
      state.isLoadingNextofkin = false;
      state.nextofkin = payload;
      state.errorNextofkin = "";
    },
    nextofkinFail: (state, { payload }) => {
      state.isLoadingNextofkin = false;
      state.errorNextofkin = payload;
    },
  },
});

export const { nextofkinPending, nextofkinSuccess, nextofkinFail } = nextofkinSlice.actions;

export default nextofkinSlice.reducer;
