import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    staff: {},
    isLoading: false,
    error: ''
}

const staffSlice = createSlice({
    name: 'staff',
    initialState,
    reducers:{
        getStaffPending: (state) => {
            state.isLoading = true;
        },
        getStaffSuccess: (state, {payload}) => {
            state.isLoading = false;
            state.staff = payload;
            state.error = '';
        },
        getStaffFail: (state, {payload}) => {
            state.isLoading = false;
            state.error = payload;
        }
    }
})

export const {getStaffPending, getStaffSuccess, getStaffFail} = staffSlice.actions

export default staffSlice.reducer