import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    getAssignedMarcharts: {},
    isLoadingAssignedMarcharts: false,
    errorgetAssignedMarcharts: ''
}

const assignedMarchartSlice = createSlice({
    name: 'getAssignedMarcharts',
    initialState,
    reducers:{
        assignedMarchartsPending: (state) => {
            state.isLoadingAssignedMarcharts = true;
        },
        getAssignedMarchartsSuccess: (state, {payload}) => {
            state.isLoadingAssignedMarcharts = false;
            state.getAssignedMarcharts = payload;
            state.errorgetAssignedMarcharts = '';
        },
        getAssignedMarchartsFail: (state, {payload}) => {
            state.isLoadingAssignedMarcharts = false;
            state.errorgetAssignedMarcharts = payload;
        }
    }
})

export const {assignedMarchartsPending,  getAssignedMarchartsSuccess, getAssignedMarchartsFail} = assignedMarchartSlice.actions

export default assignedMarchartSlice.reducer