import {updateAvailabilityPending, updateAvailabilitySuccess, updateAvailabilityFail} from '../RegisterCarerSlices/UpdateAvailabilitySlice'
import {UpdateAvailability} from '../../api/carerApi'

 const updateAvailabilityAction = (data) => async dispatch => {

    try {
        dispatch(updateAvailabilityPending());
        const result = await UpdateAvailability(data);
        if(result){
            return dispatch(updateAvailabilitySuccess(result))
        }
    } catch (error) {
        dispatch(updateAvailabilityFail(error))
    }
} 

export default updateAvailabilityAction