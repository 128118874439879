import {addCarePlanPending, addCarePlanSuccess, addCarePlanFail} from '../carePlanSlices/addCarePlanSlice'
import {addCarePlan} from '../../../api/carePlanApi'

 const addStaff = (data) => async dispatch => {

    try {
        dispatch(addCarePlanPending());
        const result = await addCarePlan(data);
        if(result){
            return dispatch(addCarePlanSuccess(result))
        }
    } catch (error) {
        dispatch(addCarePlanFail(error))
    }
} 

export default addStaff