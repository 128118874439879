import React from 'react'
import { Sidebar } from '../../layouts/auth/Sidebar'
import { TopNavigation } from '../../layouts/auth/TopNavigation'
import Active from './Active'
import Archived from './Archived'
import AddStaff from './AddStaff'
import Birthdays from './Birthdays'
import Regulators from './Regulators'



const Staff = () => {
  const [active, setActive] = React.useState('active');

  let content;

  switch (active) {
    case 'active':
      content = <Active />
      break;
    case 'archived':
      content = <Archived />
      break;
      case 'birthdays':
        content = <Birthdays />
        break;
        case 'regulators':
          content = <Regulators />
          break;
    default:
      break;
  }
  return (
    <div><Sidebar /><div className="shadow-xl"><TopNavigation title='Office Staff' /></div>
      <div className='bg-white h-screen'>
        <div className='flex justify-between'>
          <div>
            <ul className="flex flex-wrap -mb-px ml-80 font-medium pt-5 mt-5">
              <li className="mr-2">
                <button onClick={() => setActive('active')} className={`inline-block py-2 px-4 focus:outline-none rounded-t-lg border-b-2 ${active === 'active' ? "border-secondary text-white dark:text-button dark:border-button bg-orange-500" : "border-transparent hover:text-button hover:border-button dark:hover:text-gray-300"}`}>Active</button>
              </li>
              <li className="mr-2">
                <button onClick={() => setActive('archived')} className={`inline-block py-2 px-4 focus:outline-none rounded-t-lg border-b-2 ${active === 'archived' ? "border-secondary text-white dark:text-button dark:border-button bg-orange-500" : "border-transparent hover:text-button hover:border-button dark:hover:text-gray-300"}`}>Inactive</button>
              </li>
              <li className="mr-2">
                <button onClick={() => setActive('birthdays')} className={`inline-block py-2 px-4 focus:outline-none rounded-t-lg border-b-2 ${active === 'birthdays' ? "border-secondary text-white dark:text-button dark:border-button bg-orange-500" : "border-transparent hover:text-button hover:border-button dark:hover:text-gray-300"}`}>Current & Upcoming Birthdays</button>
              </li>
              <li className="mr-2">
                <button onClick={() => setActive('regulators')} className={`inline-block py-2 px-4 focus:outline-none rounded-t-lg border-b-2 ${active === 'regulators' ? "border-secondary text-white dark:text-button dark:border-button bg-orange-500" : "border-transparent hover:text-button hover:border-button dark:hover:text-gray-300"}`}>Regulators</button>
              </li>
            </ul>
          </div>
          <div className='mt-10 mr-8'>  
          <AddStaff/>
          </div>
        </div>
        <div className="">
          {content}
        </div>
      </div>
    </div>
  )
}

export default Staff