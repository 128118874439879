import {TermsAgreementPending, TermsAgreementSuccess, TermsAgreementFail} from '../RegisterCarerSlices/TermsAgreementSlice'
import {TermsAgreement} from '../../api/carerApi'

 const TermsAgreementAction = (data) => async dispatch => {

    try {
        dispatch(TermsAgreementPending());
        const result = await TermsAgreement(data);
        if(result){
            return dispatch(TermsAgreementSuccess(result))
        }
    } catch (error) {
        dispatch(TermsAgreementFail(error))
    }
} 

export default TermsAgreementAction