import {getCarersWaitingPlacementPending, getCarersWaitingPlacementSuccess, getCarersWaitingPlacementFail} from '../DashboardSlices/GetCarersWaitingPlacementSlice'
import {getCarersWaitingPlacement} from '../../../api/carerApi'

 const getCarersWaiting = (data) => async dispatch => {

    try {
        dispatch(getCarersWaitingPlacementPending());
        const result = await getCarersWaitingPlacement(data);
        if(result){
            return dispatch(getCarersWaitingPlacementSuccess(result))
        }
    } catch (error) {
        dispatch(getCarersWaitingPlacementFail(error))
    }
} 

export default getCarersWaiting