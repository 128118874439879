import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {BrowserRouter as Router, Routes, Route, Link, useNavigate} from 'react-router-dom';
import Login from '../pages/auth/Login';
import { loginSucess } from '../pages/auth/LoginSlice';


export const PrivateRoute = ({children, ...rest}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {isAuth} = useSelector((state) => state.login);
    const { user } = useSelector((state) => state.user);
    const rol = user ? user.role : ""
    const autis = async()=>{
      let goof = await isAuth
    }
    React.useEffect (()=>{
      // sessionStorage.getItem("access_token") && navigate("/dashboard");
        !isAuth && sessionStorage.getItem('access_token') && dispatch(loginSucess())
    },[isAuth, dispatch]);


  return (

    (isAuth ) ? children : <Login/>
  )
}

