import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    updateRegulatorClientStatus: {},
    isLoadingupdateRegulatorClientStatus: false,
    errorupdateRegulatorClientStatus: ''
}

const updateRegulatorClientStatusSlice = createSlice({
    name: 'updateRegulatorClientStatus',
    initialState,
    reducers:{
        updateRegulatorClientStatusPending: (state) => {
            state.isLoadingupdateRegulatorClientStatus = true;
        },
        updateRegulatorClientStatusSuccess: (state, {payload}) => {
            state.isLoadingupdateRegulatorClientStatus = false;
            state.updateRegulatorClientStatus = payload;
            state.errorupdateRegulatorClientStatus = '';
        },
        updateRegulatorClientStatusFail: (state, {payload}) => {
            state.isLoadingupdateRegulatorClientStatus = false;
            state.errorupdateRegulatorClientStatus = payload;
        }
    }
})

export const {updateRegulatorClientStatusPending, updateRegulatorClientStatusSuccess, updateRegulatorClientStatusFail} = updateRegulatorClientStatusSlice.actions

export default updateRegulatorClientStatusSlice.reducer