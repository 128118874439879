import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  documents: {},
  isLoadingDocuments: false,
  errorDocuments: "",
};

const documentsSlice = createSlice({
  name: "documents",
  initialState,
  reducers: {
    documentsPending: (state) => {
      state.isLoadingDocuments = true;
    },
    documentsSuccess: (state, { payload }) => {
      state.isLoadingDocuments = false;
      state.documents = payload;
      state.errorDocuments = "";
    },
    documentsFail: (state, { payload }) => {
      state.isLoadingDocuments = false;
      state.errorDocuments = payload;
    },
  },
});

export const { documentsPending, documentsSuccess, documentsFail } = documentsSlice.actions;

export default documentsSlice.reducer;
