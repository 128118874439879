import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  updateAvailability: {},
  isLoadingupdateAvailability: false,
  errorupdateAvailability: "",
};

const updateAvailabilitySlice = createSlice({
  name: "updateAvailability",
  initialState,
  reducers: {
    updateAvailabilityPending: (state) => {
      state.isLoadingupdateAvailability = true;
    },
    updateAvailabilitySuccess: (state, { payload }) => {
      state.isLoadingupdateAvailability = false;
      state.updateAvailability = payload;
      state.errorupdateAvailability = "";
    },
    updateAvailabilityFail: (state, { payload }) => {
      state.isLoadingupdateAvailability = false;
      state.errorupdateAvailability = payload;
    },
  },
});

export const { updateAvailabilityPending, updateAvailabilitySuccess, updateAvailabilityFail } =
  updateAvailabilitySlice.actions;

export default updateAvailabilitySlice.reducer;
