import {editContractDocPending, editContractDocSuccess, editContractDocFail} from '../ClientSlices/EditClientContractSlice'
import {updateContractDoc} from '../../../api/clientApi'

 const editContractDoc = (data) => async dispatch => {

    try {
        dispatch(editContractDocPending());
        const result = await updateContractDoc(data);
        if(result){
            return dispatch(editContractDocSuccess(result))
        }
    } catch (error) {
        dispatch(editContractDocFail(error))
    }
} 

export default editContractDoc