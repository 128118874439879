import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    getriskAssessment: {},
    isLoadinggetriskAssessment: false,
    errorgetriskAssessment: ''
}

const getriskAssessmentSlice = createSlice({
    name: 'getriskAssessment',
    initialState,
    reducers:{
        getriskAssessmentPending: (state) => {
            state.isLoadinggetriskAssessment = true;
        },
        getriskAssessmentSuccess: (state, {payload}) => {
            state.isLoadinggetriskAssessment = false;
            state.getriskAssessment = payload;
            state.errorgetriskAssessment = '';
        },
        getriskAssessmentFail: (state, {payload}) => {
            state.isLoadinggetriskAssessment = false;
            state.errorgetriskAssessment = payload;
        }
    }
})

export const {getriskAssessmentPending, getriskAssessmentSuccess, getriskAssessmentFail} = getriskAssessmentSlice.actions

export default getriskAssessmentSlice.reducer