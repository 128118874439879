import {React,useState} from 'react'
import Progressbar from 'react-js-progressbar';

const ProgressBar = (props) => {
    const [percentage, setPercentage] = useState(props.number);

    const change_progressbar_input = () => {
      setPercentage(50);
    };
  
  return (
    <><label className='font-medium text-gray-700 text-lg'>Progress Bar</label>
    <div id='progressbarContainer' className='mt-3 font-bold'>
      <Progressbar
        input={percentage}
        pathWidth={10}
        pathColor={['#56ab2f', '#F7BB59']} // use an array for gradient color.
        trailWidth={20}
        trailColor='#e2e2e2' // use a string for solid color.
        textStyle={{ fill: '#24395D' }} // middle text style
        setPercentage={setPercentage}
      >
      </Progressbar>
    </div>
  </>
  )
}

export default ProgressBar