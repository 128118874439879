import {clientContactsPending, clientContactsSuccess, clientContactsFail} from '../ClientSlices/ClientContactsSlice'
import {addClientContacts} from '../../../api/clientApi'

 const addContact = (data) => async dispatch => {

    try {
        dispatch(clientContactsPending());
        const result = await addClientContacts(data);
        if(result){
            return dispatch(clientContactsSuccess(result))
        }
    } catch (error) {
        dispatch(clientContactsFail(error))
    }
} 

export default addContact