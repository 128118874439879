import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    clientCareWorkers: {},
    isLoadingClientCareWorkers: false,
    errorClientCareWorkers: ''
}

const clientCareWorkersSlice = createSlice({
    name: 'clientCareWorkers',
    initialState,
    reducers:{
        clientCareWorkersPending: (state) => {
            state.isLoadingClientCareWorkers = true;
        },
        clientCareWorkersSuccess: (state, {payload}) => {
            state.isLoadingClientCareWorkers = false;
            state.clientCareWorkers = payload;
            state.errorClientCareWorkers = '';
        },
        clientCareWorkersFail: (state, {payload}) => {
            state.isLoadingClientCareWorkers = false;
            state.errorClientCareWorkers = payload;
        }
    }
})

export const {clientCareWorkersPending, clientCareWorkersSuccess, clientCareWorkersFail} = clientCareWorkersSlice.actions

export default clientCareWorkersSlice.reducer