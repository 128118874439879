import React from "react";
import FormikController from "../../components/Formik/FormikController";
import SubmitButton from "../../components/Formik/SubmitButton";
import PageLoader from "../../components/PageLoader";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import addCarerTier from "./ConfigActions/AddCarerTierAction";
import updateCarerTier from "./ConfigActions/UpdateCarerTierAction";

const AddCareWorkerTier = ({ care, setCare }) => {
  const dispatch = useDispatch();
  const { isLoadingCarerTier, errorCarerTier } = useSelector(
    (state) => state.addCarerTier
  );
  const { isLoadingUpdateCarerTier, errorUpdateCarerTier } = useSelector(
    (state) => state.updateCarerTier
  );

  const initialValues = {
    name: care ? care.name : "",
    type: care ? care.type : "",
    carerRate: care ? care.carerRate : "",
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("This field is required"),
    type: Yup.string().required("This field is required"),
    carerRate: Yup.string().required("This field is required"),
  });

  const handleSubmit = (values) => {
    dispatch(addCarerTier(values));
  };

  const handleUpdate = (values) => {
    values.id = care.id;
    dispatch(updateCarerTier(values));
    setCare(null);
  };

  const Head = (props) => {
    return (
      <div className="text-left">
        <label className="text-xl font-medium text-orange-900">
          {props.title}
        </label>
      </div>
    );
  };
  return (
    <div>
      <div className="">
        <div className="relative p-2 flex-auto text-gray-700">
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm }) => {
              care ? handleUpdate(values) : handleSubmit(values);
              resetForm();
            }}
          >
            {() => (
              <Form className="mt-2">
                {errorCarerTier && (
                  <div
                    className="bg-red-100 border mb-5 text-center border-red-200 text-red-700 px-4 py-3 rounded relative"
                    role="alert"
                  >
                    <span className="block sm:inline">
                      {errorCarerTier.response.data.message}
                    </span>
                    <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                      {/* <svg className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" /></svg> */}
                    </span>
                  </div>
                )}

                {errorUpdateCarerTier && (
                  <div
                    className="bg-red-100 border mb-5 text-center border-red-200 text-red-700 px-4 py-3 rounded relative"
                    role="alert"
                  >
                    <span className="block sm:inline">
                      {errorUpdateCarerTier.response.data.message}
                    </span>
                    <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                      {/* <svg className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" /></svg> */}
                    </span>
                  </div>
                )}
                <div className="mb-3">
                  <Head title={care ? "Update Carer Tier" : "Add Carer Tier"} />
                </div>
                <div className="grid grid-cols-12 gap-2 ">
                  <div className="col-span-3">
                    <FormikController
                      control="input"
                      type="text"
                      name="name"
                      label="Name"
                    />
                  </div>
                  <div className="col-span-3">
                    <FormikController
                      control="input"
                      type="text"
                      name="type"
                      label="Type"
                    />
                  </div>
                  <div className="col-span-3">
                    <FormikController
                      control="input"
                      type="text"
                      name="carerRate"
                      label="Rate"
                    />
                  </div>
                  <div className=" col-span-3 pt-2">
                    {isLoadingCarerTier || isLoadingUpdateCarerTier ? (
                      <PageLoader />
                    ) : (
                      [
                        care ? (
                          <SubmitButton label="Update" />
                        ) : (
                          <SubmitButton label="Add" />
                        ),
                      ]
                    )}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default AddCareWorkerTier;
