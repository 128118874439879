import React from 'react'
import ViewApplication from './ViewApplication'
import StaffDbs from './StaffDbs'
import CareWorkerDbs from './CareWorkerDbs';

function Staff() {
  const [active, setActive] = React.useState('carerdbs');

  let content;

  switch (active) {
    case 'staffdbs':
      content = <StaffDbs />
      break;
    case 'carerdbs' :
      content = <CareWorkerDbs />
     break;
  }
  return (
    <>
    <div>
      <ul className="flex flex-wrap -mb-px ml-80 pt-5">
        <li className="mr-2">
          <button onClick={() => setActive('carerdbs')}  className={`inline-block py-2 px-4 focus:outline-none rounded-t-lg border-b-2 ${active === 'carerdbs' ? "border-secondary text-gray-700 font-medium dark:text-button dark:border-button bg-secondary" : "border-transparent hover:text-button hover:border-secondary  text-gray-500  dark:hover:text-gray-300"}`}>Care Workers</button>
          <button onClick={() => setActive('staffdbs')} className={`inline-block py-2 px-4 focus:outline-none rounded-t-lg border-b-2 ${active === 'staffdbs' ? "border-secondary text-gray-700 font-medium dark:text-button dark:border-button bg-secondary" : "border-transparent hover:text-button hover:border-secondary  text-gray-500  dark:hover:text-gray-300"}`}>Office Staff</button>
        </li>
      </ul>
    </div>

  <div className="">
    {content}
  </div>
  </>
  )
}

export default Staff