import {resetEmailPending, resetEmailSuccess, resetEmailFail} from './ResetPasswordSlice'
import {resetEmail} from '../../api/userApi'

const updateStaffStatus = (data) => async dispatch => {

    try {
        dispatch(resetEmailPending());
        const result = await resetEmail(data);
        if(result){
            return dispatch(resetEmailSuccess(result))
        }
    } catch (error) {
        dispatch(resetEmailFail(error))
    }
}

export default updateStaffStatus