import React from "react";
import SimpleSwitch from "../../../components/SimpleSwitch";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import FormikController from "../../../components/Formik/FormikController";
import SubmitButton from "../../../components/Formik/SubmitButton";
import UpdateHealthDeclarationAction from "../../carers/RegisterCarerActions/UpdateHealthDeclarationAction";
import GetCarerInfoAction from "../../carers/RegisterCarerActions/GetCarerInfoAction";
import CarerHealthDeclaration from "../../carers/AddCarer/CarerHealthDeclaration";
import PageLoader from "../../../components/PageLoader";

function HealthDeclaration({ carer, setShowModal }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { GetCarerInfo, isLoadingGetCarerInfo } = useSelector(
    (state) => state.GetCarerInfoSliceReducer
  );
  // console.log('HEALTH',GetCarerInfo);
  const { Login, isLoading, error } = useSelector((state) => state.CarerToken);

  const { updateHealthDeclaration, isLoadingupdateHealthDeclaration } =
    useSelector((state) => state.UpdateHealthDeclaration);

  React.useEffect(() => {
    dispatch(GetCarerInfoAction({ id: carer?.carer?.id }));
  }, [updateHealthDeclaration]);

  const Head = (props) => {
    return (
      <div className="mb-5 w-full">
        <label className="text-xl font-bold text-gray-600 p-4 text-md bg-gray-100 mb-5 rounded">
          {props.title}
        </label>
      </div>
    );
  };

  const initialValues = {
    HasCardiovascularDisease: GetCarerInfo
      ? GetCarerInfo.HasCardiovascularDisease
      : false,
    CardiovascularDisease: GetCarerInfo
      ? GetCarerInfo.CardiovascularDisease
      : "",
    HasRespiratoryDisease: GetCarerInfo
      ? GetCarerInfo.HasRespiratoryDisease
      : false,
    RespiratoryDisease: GetCarerInfo ? GetCarerInfo.RespiratoryDisease : "",
    HasLiverGallbladderDisease: GetCarerInfo
      ? GetCarerInfo.HasLiverGallbladderDisease
      : false,
    LiverGallbladderDisease: GetCarerInfo
      ? GetCarerInfo.LiverGallbladderDisease
      : "",
    HasGastrointestinalDisease: GetCarerInfo
      ? GetCarerInfo.HasGastrointestinalDisease
      : false,
    GastrointestinalDisease: GetCarerInfo
      ? GetCarerInfo.GastrointestinalDisease
      : "",
    HasContagiousBloodDisease: GetCarerInfo
      ? GetCarerInfo.HasContagiousBloodDisease
      : false,
    ContagiousBloodDisease: GetCarerInfo
      ? GetCarerInfo.ContagiousBloodDisease
      : "",
    HasAllergies: GetCarerInfo ? GetCarerInfo.HasAllergies : false,
    Allergies: GetCarerInfo ? GetCarerInfo.Allergies : "",
    HasMentalIllness: GetCarerInfo ? GetCarerInfo.HasMentalIllness : false,
    MentalIllness: GetCarerInfo ? GetCarerInfo.MentalIllness : "",
    HasPreviousGeneralAnaesthesia: GetCarerInfo
      ? GetCarerInfo.HasPreviousGeneralAnaesthesia
      : false,
    PreviousGeneralAnaesthesia: GetCarerInfo
      ? GetCarerInfo.PreviousGeneralAnaesthesia
      : "",
    smoke: GetCarerInfo ? GetCarerInfo.smoke : false,
    smokingHabit: GetCarerInfo ? GetCarerInfo.smokingHabit : "",
    KidneyDisease: GetCarerInfo ? GetCarerInfo.KidneyDisease : false,
    Diabetes: GetCarerInfo ? GetCarerInfo.Diabetes : false,
    RheumatoidArthritis: GetCarerInfo
      ? GetCarerInfo.RheumatoidArthritis
      : false,
    Alcohol: GetCarerInfo ? GetCarerInfo.Alcohol : false,
    OnMedication: GetCarerInfo ? GetCarerInfo.OnMedication : false,
    NameOfMedications: GetCarerInfo ? GetCarerInfo.NameOfMedications : "",
    MedicationDailyDosage: GetCarerInfo
      ? GetCarerInfo.MedicationDailyDosage
      : "",
    SecondCovidVaccine: GetCarerInfo
      ? GetCarerInfo.SecondCovidVaccine
      : "2021-05-05",
  };

  const validationSchema = Yup.object({});
  const handleUpdate = (values) => {
    // values.accessToken = Login.access_token;
    values.id = GetCarerInfo.id;
    console.log("VALII", values);
    dispatch(UpdateHealthDeclarationAction(values));
  };

  return (
    <div className="w-full p-5">
      <div className="w-full ">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => handleUpdate(values)}
        >
          {({ formik, values }) => {
            return (
              <Form>
                <div className="text-left font-bold text-gray-600 p-4 text-md bg-gray-100 mb-5 rounded uppercase">
                  Health Declaration
                </div>
                <span className="text-left">
                  <div className="border p-5 rounded ">
                    <div className="grid grid-cols-1 gap-2">
                      <SimpleSwitch
                        name="HasCardiovascularDisease"
                        label="Cardiovascular Disease"
                        checked={values.HasCardiovascularDisease === true}
                      />
                      {values.HasCardiovascularDisease === true && (
                        <div className="mb-6">
                          <FormikController
                            control="input"
                            type="text"
                            name="CardiovascularDisease"
                            label="Please Specify"
                          />
                        </div>
                      )}

                      <SimpleSwitch
                        name="HasRespiratoryDisease"
                        label="Respiratory Disease"
                        checked={values.HasRespiratoryDisease === true}
                      />

                      {values.HasRespiratoryDisease === true && (
                        <div className="mb-6">
                          <FormikController
                            control="input"
                            type="text"
                            name="RespiratoryDisease"
                            label="Please Specify"
                          />
                        </div>
                      )}

                      <SimpleSwitch
                        name="HasLiverGallbladderDisease"
                        label="Liver Gall-Bladder Disease"
                        checked={values.HasLiverGallbladderDisease === true}
                      />

                      {values.HasLiverGallbladderDisease === true && (
                        <div className="mb-6">
                          <FormikController
                            control="input"
                            type="text"
                            name="LiverGallbladderDisease"
                            label="Please Specify"
                          />
                        </div>
                      )}

                      <SimpleSwitch
                        name="HasGastrointestinalDisease"
                        label="Gastro-Intestinal Disease"
                        checked={values.HasGastrointestinalDisease === true}
                      />

                      {values.HasGastrointestinalDisease === true && (
                        <div className="mb-6">
                          <FormikController
                            control="input"
                            type="text"
                            name="GastrointestinalDisease"
                            label="Please Specify"
                          />
                        </div>
                      )}

                      <SimpleSwitch
                        name="HasContagiousBloodDisease"
                        label="Contagious Blood Disease"
                        checked={values.HasContagiousBloodDisease === true}
                      />
                      {values.HasContagiousBloodDisease === true && (
                        <div className="mb-6">
                          <FormikController
                            control="input"
                            type="text"
                            name="ContagiousBloodDisease"
                            label="Please Specify"
                          />
                        </div>
                      )}

                      <SimpleSwitch
                        name="HasAllergies"
                        label="Allergies"
                        checked={values.HasAllergies === true}
                      />
                      {values.HasAllergies === true && (
                        <div className="mb-6">
                          <FormikController
                            control="input"
                            type="text"
                            name="Allergies"
                            label="Please Specify"
                          />
                        </div>
                      )}

                      <SimpleSwitch
                        name="HasMentalIllness"
                        label="Mental Illness"
                        checked={values.HasMentalIllness === true}
                      />
                      {values.HasMentalIllness === true && (
                        <div className="mb-6">
                          <FormikController
                            control="input"
                            type="text"
                            name="MentalIllness"
                            label="Please Specify"
                          />
                        </div>
                      )}

                      <SimpleSwitch
                        name="HasPreviousGeneralAnaesthesia"
                        label="Previous General Anaesthesia"
                        checked={values.HasPreviousGeneralAnaesthesia === true}
                      />
                      {values.HasPreviousGeneralAnaesthesia === true && (
                        <div className="mb-6">
                          <FormikController
                            control="input"
                            type="text"
                            name="PreviousGeneralAnaesthesia"
                            label="Please Specify"
                          />
                        </div>
                      )}

                      <SimpleSwitch
                        name="smoke"
                        label="Smoke"
                        checked={values.smoke === true}
                      />

                      {values.smoke === true && (
                        <div className="mb-6">
                          <FormikController
                            control="input"
                            type="text"
                            name="smokingHabit"
                            label="Please Specify"
                          />
                        </div>
                      )}

                      <SimpleSwitch
                        name="Alcohol"
                        label="Drink Alcohol Daily"
                        checked={values.Alcohol === true}
                      />
                      <SimpleSwitch
                        name="KidneyDisease"
                        label="Kidney Disease"
                        checked={values.KidneyDisease === true}
                      />

                      <SimpleSwitch
                        name="Diabetes"
                        label="Diabetes"
                        checked={values.Diabetes === true}
                      />

                      <SimpleSwitch
                        name="RheumatoidArthritis"
                        label="Rheumatoid Arthritis"
                        checked={values.RheumatoidArthritis === true}
                      />

                      {/* {values.Vaccinated === true && (
                        <div className="mb-6">
                          <FormikController
                            control="input"
                            type="date"
                            name="SecondCovidVaccine"
                            label="Date of last Vaccination"
                          />
                        </div>
                      )} */}

                      <SimpleSwitch
                        name="OnMedication"
                        label="On Medication"
                        checked={values.OnMedication === true}
                      />

                      {values.OnMedication === true && (
                        <div className="mb-6">
                          <FormikController
                            control="input"
                            type="text"
                            name="NameOfMedications"
                            label="Name of Medication"
                          />
                        </div>
                      )}
                      {values.OnMedication === true && (
                        <div className="mb-6">
                          <FormikController
                            control="input"
                            type="text"
                            name="MedicationDailyDosage"
                            label="Daily Dosage"
                          />
                        </div>
                      )}
                      <SimpleSwitch
                        name="Vaccinated"
                        label="NONE OF THE ABOVE"
                        checked={values.Vaccinated === true}
                      />
                    </div>
                  </div>
                  <div className="mb-3 mt-5 flex flex-row-reverse">
                    <div></div>
                    {isLoadingupdateHealthDeclaration ? (
                      <PageLoader />
                    ) : (
                      <SubmitButton label="Update" />
                    )}
                  </div>
                </span>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

export default HealthDeclaration;
