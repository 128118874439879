import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  updateEmploymentHistory: {},
  isLoadingupdateEmploymentHistory: false,
  errorupdateEmploymentHistory: "",
};

const updateEmploymentHistorySlice = createSlice({
  name: "updateEmploymentHistory",
  initialState,
  reducers: {
    updateEmploymentHistoryPending: (state) => {
      state.isLoadingupdateEmploymentHistory = true;
    },
    updateEmploymentHistorySuccess: (state, { payload }) => {
      state.isLoadingupdateEmploymentHistory = false;
      state.updateEmploymentHistory = payload;
      state.errorupdateEmploymentHistory = "";
    },
    updateEmploymentHistoryFail: (state, { payload }) => {
      state.isLoadingupdateEmploymentHistory = false;
      state.errorupdateEmploymentHistory = payload;
    },
  },
});

export const { updateEmploymentHistoryPending, updateEmploymentHistorySuccess, updateEmploymentHistoryFail } =
  updateEmploymentHistorySlice.actions;

export default updateEmploymentHistorySlice.reducer;
