import React from 'react'
 const ReplyButton = ({label,children, type='submit', ...rest}) => {
    return (
      <div className="mb-3 pt-3">
      <button 
      type={type}
      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">{label}</button>
   </div>
    )
  }

  export default ReplyButton