import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    editContract: {},
    isLoadingEditDoc: false,
    errorEditDoc: ''
}

const editContractDocSlice = createSlice({
    name: 'editContract',
    initialState,
    reducers:{
        editContractDocPending: (state) => {
            state.isLoadingEditDoc = true;
        },
        editContractDocSuccess: (state, {payload}) => {
            state.isLoadingEditDoc = false;
            state.editContract = payload;
            state.errorEditDoc = '';
        },
        editContractDocFail: (state, {payload}) => {
            state.isLoadingEditDoc = false;
            state.errorEditDoc = payload;
        }
    }
})

export const {editContractDocPending, editContractDocSuccess, editContractDocFail} = editContractDocSlice.actions

export default editContractDocSlice.reducer