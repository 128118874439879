import React from "react";
import { BsFillEyeFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import ClockInAction from "../dashboard/DashboardAction/ClockInAction";
import Pagination from "react-js-pagination";

const ClockIn = () => {
  const [showModal, setShowModal] = React.useState(false);

  const handleOpen = (id) => {
    setShowModal(true);
  };
  const dispatch = useDispatch();
  const { carerClockin, isLoadingClockIn } = useSelector(
    (state) => state.clockInCarer
  );


  const itemsCountPerPage = 5;
  const [page, setPage] = React.useState(1);
  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };
  const handleDate = (date) => {
    let format = new Date(date);
    return ("0"+format.getDate()).slice(-2)+'/'+("0"+(format.getMonth()+1)).slice(-2)+'/'+format.getFullYear()+' '+format.getHours()+':'+(format.getMinutes()<10?'0':'') + format.getMinutes()
}

  React.useEffect(() => {
    dispatch(
      ClockInAction({
        page,
        perPage: itemsCountPerPage,
      })
    );
  }, [page]);

  return (
    <>
      <button
        className=" text-white bg-green border hover:bg-white hover:text-gray-700 hover:border hover:border-gray-300 hover:underline inline-flex decoration-secondary focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-3xl text-sm px-3 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        type="button"
        onClick={() => handleOpen()}
      >
        View more <BsFillEyeFill className="ml-1 mt-1" />
      </button>
      {showModal ? (
        <>
          <div className="justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-xl font-semibold text-gray-500">
                    Clocked In Carers
                  </h3>
                  <button
                    className="p-1 ml-10 bg-transparent border-0 text-button float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    ×
                  </button>
                </div>

                <div className="relative p-6 flex-auto">
                  <div className="relative overflow-x-auto shadow-md sm:rounded-lg mb-10">
                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                      <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                          <th scope="col" className="px-6 py-3">
                           Client Name
                          </th>
                          <th scope="col" className="px-6 py-3">
                           Care Worker
                          </th>
                          <th scope="col" className="px-6 py-3">
                           Clocked In at
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {carerClockin &&
                        carerClockin.data &&
                        carerClockin.data.length > 0 ? (
                          carerClockin.data.map((carer) => (
                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-primary dark:hover:bg-gray-600">
                              <th
                                scope="row"
                                className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap"
                              >
                                {carer.client.firstname} {" "}
                                {carer.client.lastname}
                              </th>
                              <th
                                scope="row"
                                className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap"
                              >
                                {carer.carer.firstname} {" "}
                                {carer.carer.lastname}
                              </th>
                              <th
                                scope="row"
                                className="px-6 py-4 font-medium text-green dark:text-white whitespace-nowrap"
                              >
                                {handleDate(carer.clockIn)}
                              </th>
                            </tr>
                          ))
                        ) : (
                          <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                            <td
                              colSpan="9"
                              className="text-center px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap border rounded bg-red-100"
                            >
                              No Data Found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div>
                {(carerClockin && carerClockin.data && carerClockin.data.length > 0 && !isLoadingClockIn) &&
                    <div className="flex justify-end">
                        <Pagination
                            activePage={page}
                            itemsCountPerPage={itemsCountPerPage}
                            totalItemsCount={carerClockin.totalItemsCount}
                            pageRangeDisplayed={1}
                            onChange={handlePageChange}
                            itemclassName="page-item"
                            linkclassName="page-link"
                        />
                    </div>}
            </div>
                </div>
              </div>
            </div>
          </div>

          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default ClockIn;
