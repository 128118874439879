import React from "react";
import FormikController from "../../components/Formik/FormikController";
import SubmitButton from "../../components/Formik/SubmitButton";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import changePassword from "./changePasswordAction";
import PageLoader from "../../components/PageLoader";

function ChangePassword() {
  const dispatch = useDispatch();
  const { updatePassword, isLoadingChangePassword, errorChangePassword } =
    useSelector((state) => state.changePassword);

  React.useEffect(() => {}, []);

  const initialValues = {
    currentPassword: "",
    newPassword: "",
  };

  const validationSchema = Yup.object({
    currentPassword: Yup.string().required("This field is required"),
    newPassword: Yup.string().required("This field is required"),
  });

  const handleSubmit = (values) => {
    console.log('values',values);
    dispatch(changePassword(values));
  };

  const Head = (props) => {
    return (
      <div className="border-b mb-5 text-left">
        <label className="text-xl font-medium text-orange-900">
          {props.title}
        </label>
      </div>
    );
  };
  return (
    <div className="relative flex-auto text-gray-700 border p-5 rounded mt-10">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          handleSubmit(values);
          resetForm();
        }}
      >
        {() => (
          <Form className="mt-2">
            {errorChangePassword && (
              <div
                className="bg-red-100 border font-medium mb-5 text-center border-red-200 text-red-700 px-4 py-3 rounded relative"
                role="alert"
              >
                <span className="block sm:inline">
                  {errorChangePassword.response.data.message}
                </span>
                <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                  {/* <svg className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" /></svg> */}
                </span>
              </div>
            )}
            {updatePassword.message && (
              <div
                className="text-textsuccess font-medium bg-success border mb-5 text-center border-green-100 px-4 py-3 rounded relative"
                role="alert"
              >
                <span className="block sm:inline">
                  Your password has been changed successfully
                </span>
                <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                  {/* <svg className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" /></svg> */}
                </span>
              </div>
            )}
            <div className="">
              <Head title="Change Password" />
            </div>
            <div className="grid gap-2">
              <div>
                <FormikController
                  control="input"
                  type="password"
                  name="currentPassword"
                  label="Current Password"
                />
              </div>
              <div>
                <FormikController
                  control="input"
                  type="password"
                  name="newPassword"
                  label="New Password"
                />
              </div>
              <div className="mb-3 pt-1">
                {isLoadingChangePassword ? (
                  <PageLoader />
                ) : (
                  <SubmitButton label="Change Password" />
                )}
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default ChangePassword;
