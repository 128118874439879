import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  EditCareCert: {},
  isLoadingEditCareCert: false,
  errorEditCareCert: "",
};

const EditCareCertSlice = createSlice({
  name: "EditCareCert",
  initialState,
  reducers: {
    EditCareCertPending: (state) => {
      state.isLoadingEditCareCert = true;
    },
    EditCareCertSuccess: (state, { payload }) => {
      state.isLoadingEditCareCert = false;
      state.EditCareCert = payload;
      state.errorEditCareCert = "";
    },
    EditCareCertFail: (state, { payload }) => {
      state.isLoadingEditCareCert = false;
      state.errorEditCareCert = payload;
    },
  },
});

export const { EditCareCertPending, EditCareCertSuccess, EditCareCertFail } =
  EditCareCertSlice.actions;

export default EditCareCertSlice.reducer;
