import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    updateCarerTier: {},
    isLoadingUpdateCarerTier: false,
    errorUpdateCarerTier: ''
}

const updateCarerTierSlice = createSlice({
    name: 'updateCarerTier',
    initialState,
    reducers:{
        updateCarerTierPending: (state) => {
            state.isLoadingUpdateCarerTier = true;
        },
        updateCarerTierSuccess: (state, {payload}) => {
            state.isLoadingUpdateCarerTier = false;
            state.updateCarerTier = payload;
            state.errorUpdateCarerTier = '';
        },
        updateCarerTierFail: (state, {payload}) => {
            state.isLoadingUpdateCarerTier = false;
            state.errorUpdateCarerTier = payload;
        }
    }
})

export const {updateCarerTierPending, updateCarerTierSuccess, updateCarerTierFail} = updateCarerTierSlice.actions

export default updateCarerTierSlice.reducer