import React from "react";
import { BsFileEarmarkTextFill } from "react-icons/bs";

function Contract({ carer }) {
  const [showModal, setShowModal] = React.useState(false);

  const handleOpen = (id) => {
    setShowModal(true);
  };
  return (
    <>
      <button
        class=" text-button hover:bg-white hover:text-gray-700 hover:border hover:border-gray-300 hover:underline decoration-secondary focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-lg px-3 py-1 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        type="button"
        onClick={() => handleOpen(carer.id)}
      >
        <BsFileEarmarkTextFill size={20}/>
      </button>
      {showModal ? (
        <>
          <div className="justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto  mx-auto max-w-6xl">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-xl font-semibold text-gray-500">
                    <span className="text-green">
                      {carer.firstname} {carer.lastname}
                    </span>
                    's Contract
                  </h3>

                  <button
                    className="p-1 ml-10 bg-transparent border-0 text-button float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    ×
                  </button>
                </div>
                <div className="text-gray-500 p-5">
                  <div className="text-center p-5 bg-green text-white font-bold uppercase text-xl">
                    Employment Contract
                  </div>
                  <div className="text-green font-semibold py-5 text-lg underline">
                    Definitions and Intepretations
                  </div>
                  <div className="font-semibold pb-2">
                    In these Terms the following definitions apply:{" "}
                  </div>
                  <div>
                    {" "}
                    <span className="font-semibold">
                      {" "}
                      “Actual Rate of Pay”{" "}
                    </span>
                    : means the rate of pay which will be paid for each hour
                    worked during an Assignment (to the nearest quarter hour)
                    fortnightly in arrears, subject to Deductions and any Agreed
                    Deductions, as set out in the relevant Assignment Details
                    Form.
                  </div>
                  <div className="py-2">
                    {" "}
                    <span className="font-semibold "> “Agreed Deductions”</span>
                    : means any deductions the Healthcare Assistant or Live in
                    Carer has agreed can be made from their pay.
                  </div>
                  <div className="py-2">
                    <span className="font-semibold ">
                      “Assignment of Service”
                    </span>
                    : means assignment services to be performed by the
                    Healthcare Assistant for the Service User for a period based
                    on Zero hours contract.
                  </div>
                  <div className="py-2">
                    <span className="font-semibold ">
                    “Assignment Details Form”
                    </span>
                    :  means written confirmation of the assignment details to be
                  given to the Healthcare Assistant upon acceptance of the
                  employment.
                  </div>
                  <div className="py-2">
                    <span className="font-semibold ">
                    “AWR” 
                    </span>
                    :  means the Agency Workers Regulations 2010.
                  </div>
                  <div className="py-2">
                    <span className="font-semibold ">
                    “Healthcare Assistant”
                    </span>
                    :  means [Name and Address] supplied to the Employment Business
                  to provide services to the Service User
                  </div>
                  <div className="py-2">
                    <span className="font-semibold ">
                    “Service User”
                    </span>
                    :   means the individual requiring the services of Healthcare
                  Assistant.
                  </div>
                  <div className="py-2">
                    <span className="font-semibold ">
                    “Conduct Regulations”
                    </span>
                    : means the Conduct of Employment Agencies and Employment
                  Businesses Regulations 2003;.
                  </div>
                  <div className="py-2">
                    <span className="font-semibold ">
                    “Confidential Information”
                    </span>
                    :  means any and all confidential commercial, financial,
                  marketing, technical or other information or data of whatever
                  nature relating to the Service User or Employment Business or
                  their business or affairs (including but not limited to these
                  Terms, data, records, reports, agreements, software, programs,
                  specifications, know-how, trade secrets and other information
                  concerning the Assignment) in any form or medium whether
                  disclosed or granted access to whether in writing, orally or
                  by any other means, provided to the Live In Carer or any third
                  party in relation to the Assignment by the Service User or the
                  Employment Business or by a third party on behalf of the
                  Service User whether before or after the date of these Terms
                  together with any reproductions of such information in any
                  form or medium or any part(s) of such information
                  </div>
                  <div className="py-2">
                    <span className="font-semibold ">
                    “Data Protection Laws”
                    </span>
                    :  means the Data Protection Act 2018, the General Data
                  Protection Regulation (EU 2016/679) and any applicable
                  statutory or regulatory provisions and all European Directives
                  and regulations in force from time to time relating to the
                  protection and transfer of personal data.
                  </div>
                  <div className="py-2">
                    <span className="font-semibold ">
                    “Deductions” (PAYE only)
                    </span>
                    :   means any deductions which the Employment Business may be
                  required by law to make and in particular in respect of PAYE
                  pursuant to Sections 44-47 of the Income Tax (Earnings and
                  Pensions) Act 2003 and Class 1 National Insurance
                  Contributions.
                  </div>
                  <div className="py-2">
                    <span className="font-semibold ">
                    “Employment Business”
                    </span>
                    : ELMS Health Solutions of 1010 Cambourne Business Park, Great
                  Cambourne, Cambridgeshire, CB23 6DP registered company no.
                  10422373.
                  </div>
                  <div className="py-2">
                    <span className="font-semibold ">
                    “Engagement”
                    </span>
                    :  means the engagement, employment or use of the Live In Carer’s
                  services by the Service User or by any third party to whom the
                  Healthcare Assistant has been introduced by the Employment
                  Business, directly or indirectly, on a permanent or temporary
                  basis, whether under a contract of service or for services, an
                  agency, licence, franchise or partnership arrangement, or any
                  other engagement or through any other employment business; and
                  “Engage”, “Engages” and “Engaged” shall be construed
                  accordingly.
                  </div>
                  <div className="py-2">
                    <span className="font-semibold ">
                    “Period of Extended Hire”
                    </span>
                    :   means any additional period that the Service User wishes the
                  Healthcare Assistant to be supplied for beyond the duration of
                  the original Assignment or series of assignments as an
                  alternative to paying a Transfer Fee.
                  </div>
                  <div className="py-2">
                    <span className="font-semibold ">
                    “Relevant Period” 
                    </span>
                    :  means whichever ends the later of (a) the period of 8 weeks
                  commencing on the day after the last day on which the
                  Healthcare Assistant worked for the Service User having been
                  supplied by the Employment Business; or (b) the period of 14
                  weeks commencing on the first day on which the Healthcare
                  Assistant worked for the Service User having been supplied by
                  Employment Business or 14 weeks from the first day of the most
                  recent Assignment where there has been a break of more than 6
                  weeks (42 days) since any previous assignment.
                  </div>
                  <div className="py-2">
                    <span className="font-semibold ">
                    “Terms” 
                    </span>
                    :  means these terms of engagement together with any applicable
                  Assignment Details Form.
                  </div>
                  <div className="py-2">
                    <span className="font-semibold ">
                    “WTR” 
                    </span>
                    :    means the Working Time Regulations 1998.
                  </div>
                  <div className="font-semibold uppercase border-b border-t pt-5 pb-5 pl-3 text-left bg-monday">Signature</div>
                <div className="border-b ">
                    
                <iframe
                          src={carer.contract?.signature}
                          frameborder='10'
                          height="300"
                          width="100%"
                        ></iframe>
                </div>
                </div>
                
              </div>
            </div>
          </div>

          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}

export default Contract;
