import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    editPersonalDetails: {},
    isLoadingEditPersonal: false,
    errorEditPersonal: ''
}

const editPersonalDetailsSlice = createSlice({
    name: 'editPersonalDetails',
    initialState,
    reducers:{
        editPersonalDetailsPending: (state) => {
            state.isLoadingEditPersonal = true;
        },
        editPersonalDetailsSuccess: (state, {payload}) => {
            state.isLoadingEditPersonal = false;
            state.editPersonalDetails = payload;
            state.errorEditPersonal = '';
        },
        editPersonalDetailsFail: (state, {payload}) => {
            state.isLoadingEditPersonal = false;
            state.errorEditPersonal = payload;
        }
    }
})

export const {editPersonalDetailsPending, editPersonalDetailsSuccess, editPersonalDetailsFail} = editPersonalDetailsSlice.actions

export default editPersonalDetailsSlice.reducer