import {getClientsPending, getClientsSuccess, getClientsFail} from '../ClientSlices/GetClientsSlice'
import {getClients} from '../../../api/clientApi'

 const fetchClients = (data) => async dispatch => {

    try {
        dispatch(getClientsPending());
        const result = await getClients(data);
        if(result){
            return dispatch(getClientsSuccess(result))
        }
    } catch (error) {
        dispatch(getClientsFail(error))
    }
} 

export default fetchClients