import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    getRegulatorClient: {},
    isLoadinggetRegulatorClient: false,
    errorgetRegulatorClient: ''
}

const getRegulatorClientSlice = createSlice({
    name: 'getRegulatorClient',
    initialState,
    reducers:{
        getRegulatorClientPending: (state) => {
            state.isLoadinggetRegulatorClient = true;
        },
        getRegulatorClientSuccess: (state, {payload}) => {
            state.isLoadinggetRegulatorClient = false;
            state.getRegulatorClient = payload;
            state.errorgetRegulatorClient = '';
        },
        getRegulatorClientFail: (state, {payload}) => {
            state.isLoadinggetRegulatorClient = false;
            state.errorgetRegulatorClient = payload;
        }
    }
})

export const {getRegulatorClientPending, getRegulatorClientSuccess, getRegulatorClientFail} = getRegulatorClientSlice.actions

export default getRegulatorClientSlice.reducer