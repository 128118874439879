import React, {useRef} from 'react'
import { BsFillEyeFill } from "react-icons/bs";
import { useDispatch, useSelector } from 'react-redux';
import getInvoiceDetail from './Actions/InvoiceDetailsAction';
import LogoImage from '../../assets/images/logo.png'
import {PDFExport , savePDF} from '@progress/kendo-react-pdf'
import editTimesheets from './Actions/UpdateTimesheetsAction';

function EditInvoice(invoice) {
    const dispatch = useDispatch();
    var seconds = new Date().getTime() / 1000;
    const [showModal, setShowModal] = React.useState(false);
    const [loadDetails, setLoadDetails] = React.useState(false);
    const {getInvoiceDetails,isLoadingGetInvoiceDetails} = useSelector(state => state.getInvoiceDetail)
    const {editInvoiceTimesheets} = useSelector((state)=> state.edittimesheet)
    React.useEffect(()=>{
        setLoadDetails(true)
        const data = {
            id: invoice.client.client_id,
            invoiceNumber: invoice.client.invoiceNumber ? invoice.client.invoiceNumber: ''
        }
         dispatch(getInvoiceDetail(data))
    },[editInvoiceTimesheets])
  
    const handleOpen = () => {
        setShowModal(true);       
       
    };
    let total = 0;
    let hours =0;
    let days = 0;

    const handleDate = () => {
        let format = {};
        if(invoice.client.invoiceNumber){
            format = new Date(invoice.client.invoiceDate)
        }else{
            format = new Date()
        }
        return ("0"+format.getDate()).slice(-2)+'/'+("0"+(format.getMonth()+1)).slice(-2)+'/'+format.getFullYear();
    }

    const dueDate = () =>{
       var today = {};
       if(invoice.client.invoiceNumber){
        today = new Date(invoice.client.invoiceDate)
    }else{
        today = new Date()
    }
       const last = new Date(today.getFullYear(), today.getMonth()+1, 0) 
       return ("0"+last.getDate()).slice(-2)+'/'+("0"+(last.getMonth()+1)).slice(-2)+'/'+last.getFullYear();
      
    }
  
   const invoiceclient = getInvoiceDetails.client;
   const pdfExportComponent = useRef(null);
   const contentArea = useRef(null);


  const handleExportWithComponent = () => {  
    pdfExportComponent.current.save()
    if(!invoice.client.invoiceNumber){
        const data = {
            id: invoiceclient.id,
            invoiceNumber: seconds.toFixed(0)       
        }        
        dispatch(editTimesheets(data))
    }   
   
    //  setShowModal(false)
    
  };

  const type = (clockIn, clockout)=>{
    const clockindate = new Date(clockIn);
    const clockOutDate = new Date(clockout);
    if((clockindate.getDay()+'-'+(clockindate.getMonth()+1)+'-'+clockindate.getFullYear()) === (clockOutDate.getDay()+'-'+(clockOutDate.getMonth()+1)+'-'+clockOutDate.getFullYear())){
       var hr = clockOutDate.getHours();
       if ( hr <= 11){
           return "Day Carer"
       }else if(hr > 11){
        return "Night Carer"
       }
    }
    else{
       return "Live In Carer"
    }
}


  return (
    <>
    <button
        className=" text-white bg-button border hover:bg-white hover:text-gray-700 hover:border hover:border-gray-300 hover:underline inline-flex decoration-secondary focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-1 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="button"
        onClick={() => handleOpen()}>
       View <BsFillEyeFill className='ml-1 mt-1'/>
    </button>
    {showModal ? (
        <>
            <div className="justify-center items-center  overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-auto my-6 mx-auto max-w-5xl">
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">

              <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                        <h3 className="text-xl font-semibold text-gray-500">

                        </h3>
                        <button className="p-1 ml-10 bg-transparent border-0 text-button float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                            onClick={() => setShowModal(false)} >
                            ×
                        </button>
                        </div>   
                        
                                            
                        <div className='flex justify-end border-b p-3'>
                            <button onClick={handleExportWithComponent} type='submit' className='bg-green text-white rounded-md text-sm px-4 py-2 mr-3 flex items-center'  >
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-download"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="7 10 12 15 17 10"></polyline><line x1="12" y1="15" x2="12" y2="3"></line></svg>
                            <p className="ml-2" >Download</p>
                            </button>
                            {/* <button className="bg-gray-300 rounded-md text-sm px-3 py-2 mr-3 font-medium flex items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" ClassName="feather feather-printer"><polyline points="6 9 6 2 18 2 18 9"></polyline><path d="M6 18H4a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-2"></path><rect x="6" y="14" width="12" height="8"></rect></svg>
                                <p className="ml-2">Print</p>
                            </button> */}
                        </div>   
                       <PDFExport ref={pdfExportComponent} scale={0.5} fontSize="12" paperSize={"A4"}>
                            <div ref={contentArea}>
                                <div className='grid grid-cols-12 gap-2 font-normal p-5 text-gray-400'>
                                <div className="col-span-8 text-left">
                                <img className='h-24 mb-8' src={LogoImage} alt="ELMS logo"/>
                                <p className='font-medium'>ELMS Health Solutions</p>
                                <p>1010 Cambourne Business Park</p>
                                <p>Great Cambourne</p>
                                <p>Cambridge</p>
                                <p>Cambridgeshire CB23 6DP</p>
                                <p className='text-blue-800'>care@elmshealthsolutions.co.uk</p>
                                <p >https://elmshealthsolutions.co.uk</p>
                                <p>01223 751700</p>
                                </div>
                                <div className='col-span-4 text-right'>
                                <table className='text-left w-full'>
                                    <tbody>
                                        <tr className='px-4 text-right'>
                                            <th>DATE</th>
                                            <td>{handleDate()}</td>
                                        </tr>
                                        <tr className='px-4 text-right'>
                                            <th>INVOICE #</th>
                                            <td>{invoice.client.invoiceNumber ? invoice.client.invoiceNumber :  seconds.toFixed(0)}</td>
                                        </tr>
                                        <tr className='px-4 text-right'>
                                            <th>VAT</th>
                                            <td>8383738338</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className='mt-8'>
                                    <p className='font-medium uppercase'>bill to</p>
                                    <p> {invoice.client ? invoice.client.firstname+' '+invoice.client.middlename+' '+invoice.client.lastname : ''}</p>
                                </div>
                                <div className='mt-8'>
                                    <p className='font-medium uppercase'>due date</p>
                                    <p>{dueDate()}</p>
                                </div>
                                </div>

                                </div>
                                <div className='p-5 text-gray-400'>
                                <table className='table-auto mt-8 border-slate-400 w-full'>
                                <thead className='leading-10 bg-gray-100'>
                                    <tr className='text-left'>
                                        <th className='border border-slate-400 pl-3'>Description</th>
                                        <th className='border border-slate-400 pl-3'>Qty(Days/Hours)</th>
                                        <th className='border border-slate-400 text-right pl-3'>Rate</th>
                                        <th className='border border-slate-400 text-right pr-3'>Sub Tototal</th>
                                    </tr>
                                </thead>
                                <tbody className='leading-10'>
                                {getInvoiceDetails && getInvoiceDetails.length > 0 ? getInvoiceDetails.map((detail)=>(
                                    <tr className='border-b'>
                                    <td className='border border-slate-400 pl-3 text-left'>Supply of Contingent Worker {detail?.shift.carer?.firstname+' '+detail?.shift.carer?.lastname} ({type(detail.shift.clockIn,detail.shift.clockOut)})</td>
                                    <td className='border border-slate-400 pl-3 text-left'>{detail.numberOfDays} Days / {detail.numberOfHours} Hours</td>
                                    <td className='border border-slate-400 pl-3 text-right'>£{detail.clientRate} {type(detail.shift.clockIn,detail.shift.clockOut) === 'Live In Carer' ? '/ week' : '/ hour'}</td>
                                    <td className='border border-slate-400 pr-3'>£{Number(detail.invoiceAmount).toFixed(2)}</td>
                                    <span className='hidden'>{total = total+Number(detail.invoiceAmount)}</span>
                                    <span className='hidden'>{hours = hours+Number(detail.numberOfHours)}</span>
                                    <span className='hidden'>{days = days+Number(detail.numberOfDays)}</span>
                                </tr>
                                )) : ''}
                                    
                                 <tr className='text-right font-medium'>
                                    <td colSpan='3' className='pr-3'>Total Days</td>
                                    <td className='pr-3 text-right'>{Number(days)}</td>
                                </tr>
                                <tr className='text-right font-medium'>
                                    <td colSpan='3' className='pr-3'>Total Hours</td>
                                    <td className='pr-3 text-right'>{Number(hours)}</td>
                                </tr>
                                <tr className='text-right font-medium'>
                                    <td colSpan='3' className='pr-3'>GBP Total</td>
                                    <td className='pr-3 text-right'>£{Number(total).toFixed(2)}</td>
                                </tr>
                                </tbody>
                                </table>


                                </div>
                                <div className="grid grid-cols-2 gap-4 mt-8 p-5 text-gray-400">
                                <div>
                                <h3 className="font-medium">PAYMENT DETAILS</h3>
                                <p>Barclays Bank Plc</p>
                                <p>Bank/Sort Code: 20-00-00</p>
                                <p>Account Number: 23060896</p>
                                <p>Payment Reference: </p>
                                </div>
                                <div className="text-right pr-1">
                                <h3 className="font-medium">OTHER INFORMATION</h3>
                                <p>Company Registration Number: 09442292</p>
                                </div>
                                </div>

                            </div>

                       </PDFExport>
                   
                         
                    </div>
                </div>
            </div>
           
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
           

            
        </>
    ) : null}
</>
  )
}

export default EditInvoice