import {updateHealthDeclarationPending, updateHealthDeclarationSuccess, updateHealthDeclarationFail} from '../RegisterCarerSlices/UpdateHealthDeclarationSlice'
import {UpdateHealthDeclaration} from '../../api/carerApi'

 const UpdateHealthDeclarationAction = (data) => async dispatch => {

    try {
        dispatch(updateHealthDeclarationPending());
        const result = await UpdateHealthDeclaration(data);
        if(result){
            return dispatch(updateHealthDeclarationSuccess(result))
        }
    } catch (error) {
        dispatch(updateHealthDeclarationFail(error))
    }
} 

export default UpdateHealthDeclarationAction