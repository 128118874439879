import React from "react";
import Logo from "../../components/Logo";
import TransparentLogo from "../../components/TransparentLogo";
import { useNavigate  } from "react-router-dom";
import { FaJenkins } from "react-icons/fa";
import { FaPeopleCarry } from "react-icons/fa";
import { FaUsers } from "react-icons/fa";
import { FaUniversity } from "react-icons/fa";
import { FaUserGraduate } from "react-icons/fa";
import { FaChartPie } from "react-icons/fa";
import { FaCogs } from "react-icons/fa";
import { HiLogout } from "react-icons/hi";
import { logout } from "../../api/userApi";
import { BsFillPersonFill } from "react-icons/bs";
import { BsFillBagPlusFill } from "react-icons/bs";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile } from "../../pages/dashboard/userAction";
import {reset} from '../../pages/auth/LogoutSlice'
import { BroadcastChannel } from 'broadcast-channel';
import { ImSwitch } from "react-icons/im";


export const Sidebar = () => {
  const navigate = useNavigate();
  const logoutChannel = new BroadcastChannel('logout');
  const dispatch = useDispatch();
  const { myAccount } = useSelector((state) => state.updateAccount);
  const { user } = useSelector((state) => state.user);

  const rol = user ? user.role : "";

  React.useEffect(() => {
    dispatch(getUserProfile());
  }, [myAccount]);

  let activeStyle = {
    backgroundColor: "#FFF7ED",
    color: "black",
  };

  let activeClassName = "underline";

  const handleLogout = () => {
    logout();
    dispatch(reset());
    navigate('/', { replace: true });
    logoutChannel.postMessage("Logout");
  };

  return (
    <div className="fixed top-0 left-0 h-screen w-64 flex flex-col bg-button shadow-lg">
      <div className="  bg-button">
        <TransparentLogo className=" mx-auto" />
      </div>
      <hr className=" shadow " /> 
      <div className="overflow-y-auto py-4 px-3 bg-button ">
        <nav>
          <ul className="space-y-5 text-white ml-3">
           {rol !== 'REGULATOR' && <><li>
              <NavLink
                to="/dashboard"
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
                className="flex items-center p-2 text-base font-normal rounded-sm dark:text-white ml-2 hover:border-b"
              >
                <FaChartPie className="w-6 h-6 text-white mr-3"/> 
                Dashboard
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/clients"
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
                className="flex items-center p-2 text-base font-normal  rounded-sm dark:text-white ml-2 hover:border-b"
              >
                <FaJenkins className="w-6 h-6 text-white mr-3" />
                Clients
              </NavLink>
            </li>
          <li>
              <NavLink
                to="/carers"
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
                className="flex items-center p-2 text-base font-normal rounded-sm dark:text-white ml-2 hover:border-b"
              >
                <FaPeopleCarry className="w-6 h-6 text-white mr-3" />
                Carers
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/staff"
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
                className="flex items-center p-2 text-base font-normal rounded-sm dark:text-white ml-2 hover:border-b"
              >
                <FaUsers className="w-6 h-6 text-white mr-3" />
                Office Staff
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/finance"
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
                className="flex items-center p-2 text-base font-normal rounded-sm dark:text-white ml-2 hover:border-b"
              >
                <FaUniversity className="w-6 h-6 text-white mr-3" />
                Finance
              </NavLink>
            </li>

            <li>
              <NavLink
                to="/recruitment"
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
                className="flex items-center p-2 text-base font-normal rounded-sm dark:text-white ml-2 hover:border-b"
              >
                <FaUserGraduate className="w-6 h-6 text-white mr-3" />
                Recruitment
              </NavLink>
            </li>

            <li>
              <NavLink
                to="/care-plans"
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
                className="flex items-center p-2 text-base font-normal rounded-sm dark:text-white ml-2 hover:border-b"
              >
                <BsFillBagPlusFill className="w-6 h-6 text-white mr-3" />
                Care Plans
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/configurations"
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
                className="flex items-center p-2 text-base font-normal rounded-sm dark:text-white ml-2 hover:border-b"
              >
                <FaCogs className="w-6 h-6 text-white mr-3" />
                Configurations
              </NavLink>
            </li> <hr /></>
            }
            
            {rol === 'REGULATOR' &&<nav><ul className="space-y-5 text-white ml-3"> <li>
              <NavLink
                to="/assigned-clients"
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
                className="flex items-center p-2 text-base font-normal rounded-sm dark:text-white ml-2 hover:border-b"
              >
                <FaJenkins className="w-6 h-6 text-white mr-3" />
                Clients
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/assigned-carers"
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
                className="flex items-center p-2 text-base font-normal rounded-sm dark:text-white ml-2 hover:border-b"
              >
                <FaPeopleCarry className="w-6 h-6 text-white mr-3" />
                Carers
              </NavLink>
            </li></ul></nav>}
            <li>
              <NavLink
                to="/my-account"
                style={({ isActive }) => (isActive ? activeStyle : undefined)}
                className="flex items-center p-2 text-base font-normal rounded-sm dark:text-white ml-2 hover:border-b"
              >
                <BsFillPersonFill className="w-6 h-6 text-white mr-3" />
                My Account
              </NavLink>
            </li>
            <hr />
            <li>
              <button
                onClick={() => handleLogout()}
                className="flex items-center p-2 px-5 text-md font-normal rounded-lg dark:text-white hover:bg-secondary hover:text-black w-full dark:hover:bg-gray-700"
              >Sign Out  <ImSwitch size={15} className=' ml-1 mt-1 text-red-500'/>
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};
