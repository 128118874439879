import {medicationAuditUpdatePending, medicationAuditUpdateSuccess, medicationAuditUpdateFail} from '../RegisterCarerSlices/MedicationAuditUpdateSlice'
import {UpdateAudit} from '../../../api/auditApi'

 const UpdateAuditAction = (data) => async dispatch => {

    try {
        dispatch(medicationAuditUpdatePending());
        const result = await UpdateAudit(data);
        if(result){
            return dispatch(medicationAuditUpdateSuccess(result))
        }
    } catch (error) {
        dispatch(medicationAuditUpdateFail(error))
    }
} 

export default UpdateAuditAction