import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    getCarePlan: {},
    isLoadingGetCarePlan: false,
    errorGetCarePlan: ''
}

const getCarePlanSlice = createSlice({
    name: 'getCarePlan',
    initialState,
    reducers:{
        getCarePlanPending: (state) => {
            state.isLoadingGetCarePlan = true;
        },
        getCarePlanSuccess: (state, {payload}) => {
            state.isLoadingGetCarePlan = false;
            state.getCarePlan = payload;
            state.errorGetCarePlan = '';
        },
        getCarePlanFail: (state, {payload}) => {
            state.isLoadingGetCarePlan = false;
            state.errorGetCarePlan = payload;
        }
    }
})

export const {getCarePlanPending, getCarePlanSuccess, getCarePlanFail} = getCarePlanSlice.actions

export default getCarePlanSlice.reducer