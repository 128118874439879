import {riskAssessmenttPending, riskAssessmenttSuccess, riskAssessmenttFail} from '../ClientSlices/RiskAssessmentSlice'
import {addRiskAssess} from '../../../api/clientApi'

 const riskAssessment = (data) => async dispatch => {

    try {
        dispatch(riskAssessmenttPending());
        const result = await addRiskAssess(data);
        if(result){
            return dispatch(riskAssessmenttSuccess(result))
        }
    } catch (error) {
        dispatch(riskAssessmenttFail(error))
    }
} 

export default riskAssessment