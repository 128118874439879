import React from "react";
import Auth from "../../layouts/auth/Auth";
import { Link } from "react-router-dom";
import FormikController from "../../components/Formik/FormikController";
import { Formik, Form } from "formik";
import PageLoader from "../../components/PageLoader";
import { useDispatch, useSelector } from "react-redux";
import resetEmail from "../../pages/auth/ResetPasswordAction";
import * as Yup from "yup";

function ResetPassword() {
  
  const dispatch = useDispatch();

  const { resetEmailAddress, isLoadingEmailReset, errorEmailReset } =
    useSelector((state) => state.resetEmail);

  const initialValues = {
    email: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
  });

  const handleSubmit = (data) => {
    dispatch(resetEmail(data));
  };

  return (
    <Auth>
      <div
        className="flex p-4 mt-5  items-center  text-gray-700  rounded dark:bg-gray-700 dark:text-gray-300"
        role="alert"
      >
        <div>
          <span className=" text-sm font-medium">
            Please enter your Email to reset your password
          </span>
        </div>
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {() => (
          <Form className="mt-5">
            {errorEmailReset && (
              <div
                className="bg-red-100 border font-medium mb-5 text-center border-red-200 text-red-700 px-4 py-3 rounded relative"
                role="alert"
              >
                <span className="block sm:inline">
                  {errorEmailReset.response.data.message}
                </span>
                <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                  {/* <svg className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" /></svg> */}
                </span>
              </div>
            )}
            {resetEmailAddress.message && (
              <div
                className="text-textsuccess font-medium bg-success border mb-5 text-center border-green-100 px-4 py-3 rounded relative"
                role="alert"
              >
                <span className="block sm:inline">
                  New password has been sent to your email
                </span>
                <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                  {/* <svg className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" /></svg> */}
                </span>
              </div>
            )}

            <div className="mb-5 pt-2 ">
              <FormikController
                control="input"
                type="email"
                placeholder="Email"
                name="email"
              />
            </div>

            <div className="mb-3 pt-1">
              {isLoadingEmailReset ? (
                <PageLoader />
              ) : (
                <div className="mb-3 pt-3">
                  <button
                    type="submit"
                    className="bg-button text-white font-medium border w-full rounded p-1.5 text-lg px-10"
                  >
                    Reset Password
                  </button>
                </div>
              )}
            </div>

            <div className="flex">
              <span className="text-sm text-button mr-1">Remember Password?</span>
              <Link to="/" className="text-sm text-button hover:underline font-medium">
                Login
              </Link>
            </div>
          </Form>
        )}
      </Formik>
    </Auth>
  );
}

export default ResetPassword;
