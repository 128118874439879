import React from 'react'
import FormikController from '../../../components/Formik/FormikController';
import SubmitButton from '../../../components/Formik/SubmitButton';
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Postcoder from "react-address-lookup";
import { useDispatch, useSelector } from 'react-redux';
import PageLoader from '../../../components/PageLoader';
import editPersonal from '../ClientActions/EditPersonalDetailsAction'
import ClientInfo from '../ClientActions/GetClientInfoAction';

function PersonalDetails({client,setShowModal}) {
    const dispatch = useDispatch();
    const {editPersonalDetails, isLoadingEditPersonal, errorEditPersonal} = useSelector(state => state.updatePersonalDetails)
    const {getClientInfo, isLoadinggetClientInfo} = useSelector(state => state.GetClientInfoSlice);
    const { getClients, isLoadingGetClients } = useSelector(state => state.getClient)
    const [page, setPage] = React.useState(1);
    const itemsCountPerPage = 50;
    const title = [
      {
        key: "Mr.",
        value: "Mr",
      },
      {
        key: "Mrs.",
        value: "Mrs",
      },
      {
        key: "Miss",
        value: "Miss",
      },
      {
        key: "Ms",
        value: "Ms",
      },
    ];

    const religon = [
        {
            key: 'Christianity',
            value: 'Christianity'
        },
        {
            key: 'Islamic',
            value: 'Islam'
        },
        {
            key: 'Prefer not say',
            value: 'Prefer Not To Say'
        }
    ];

    const initialValues = {
            title: getClientInfo ? getClientInfo.title : '',
            firstname: getClientInfo ? getClientInfo.firstname : '',
            middlename: getClientInfo ? getClientInfo.middlename : '',
            lastname: getClientInfo ? getClientInfo.lastname : '',
            preference: getClientInfo ? getClientInfo.preference : '',
            dob: getClientInfo ? getClientInfo.dob : '',
            email: getClientInfo ? getClientInfo.email : '',
            mobile: getClientInfo ? getClientInfo.mobile : '',
            landline: getClientInfo ? getClientInfo.landline : '',
            languages: getClientInfo ? getClientInfo.languages : '',
            religion: getClientInfo ? getClientInfo.religion : '',
            postcode: getClientInfo ? getClientInfo.postcode : '',
            addressLine1: getClientInfo ? getClientInfo.addressLine1 : '',
            addressLine2: getClientInfo ? getClientInfo.addressLine2 : '',
            county: getClientInfo ? getClientInfo.county : '',
            town: getClientInfo ? getClientInfo.town : '',
            clientID: getClientInfo ? getClientInfo.clientID : "",
            nhsNumber: getClientInfo ? getClientInfo.nhsNumber : "",
            diagnosisDetails: getClientInfo ? getClientInfo.diagnosisDetails : "",
            historyBackground: getClientInfo ? getClientInfo.historyBackground : "",
            likeDislikes: getClientInfo ? getClientInfo.likeDislikes : "",
            mobility: getClientInfo ? getClientInfo.mobility : "",
            whoAmI: getClientInfo ? getClientInfo.whoAmI : "",
            knownAllergies: getClientInfo ? getClientInfo.knownAllergies : "",
            painExpression: getClientInfo ? getClientInfo.painExpression : "",
            safetyIssues: getClientInfo ? getClientInfo.safetyIssues : "",
            Diabetes: getClientInfo ? getClientInfo.Diabetes : false,
            Epilepsy: getClientInfo ? getClientInfo.Epilepsy : false,
            Asthma: getClientInfo ? getClientInfo.Asthma : false,
            SpinaBifina: getClientInfo ? getClientInfo.SpinaBifina : false,
            OtherMedical: getClientInfo ? getClientInfo.OtherMedical : false,
            otherDetails: getClientInfo ? getClientInfo.otherDetails : "",
            dietDiebetes: getClientInfo ? getClientInfo.dietDiebetes : false,
            vegetarian: getClientInfo ? getClientInfo.vegetarian : false,
            vegan: getClientInfo ? getClientInfo.vegan : false,
            specialDiet: getClientInfo ? getClientInfo.specialDiet : false,
            specialDietDetails: getClientInfo ? getClientInfo.specialDietDetails : "",
            smoker: getClientInfo ? getClientInfo.smoker : false,
            cat: getClientInfo ? getClientInfo.cat : false,
            catName: getClientInfo ? getClientInfo.catName : "",
            catActivities: getClientInfo ? getClientInfo.catActivities : "",
            dog: getClientInfo ? getClientInfo.dog : false,
            dogName: getClientInfo ? getClientInfo.dogName : "",
            dogActivities: getClientInfo ? getClientInfo.dogActivities : "",
            eatingDrinkingNeeds: getClientInfo ? getClientInfo.eatingDrinkingNeeds : "",
            specialistEquipment: getClientInfo ? getClientInfo.specialistEquipment : "",
    }
    const validationSchema = Yup.object({

    })


    const handleSubmit = (values) => {
        values.id = getClientInfo ? getClientInfo.id : ''
        dispatch(editPersonal(values));
        // setShowModal(false)
  
    }

    const Head = (props) => {
        return (
            <div className='border-b mb-5'><label className='text-xl font-medium text-gray-700'>{props.title}</label></div>
        )
    } 

    React.useEffect(()=>{
      // setInterval(function() {
        dispatch(ClientInfo({
          id:client?.id
        }))
      // }, 1000);

    },[editPersonalDetails,])

  return ( isLoadinggetClientInfo ? <PageLoader/> :
    <div className='w-full p-5'>
    <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => handleSubmit(values)}
    >
        {({formik, values}) => {
            const logAddress = (addr) => {
                formik.setFieldValue("addressLine1", addr.addressline1);
                formik.setFieldValue("addressLine2", addr.addressline2);
                formik.setFieldValue("county", addr.county);
                formik.setFieldValue("town", addr.posttown);
                formik.setFieldValue("postcode", addr.postcode);
            };
            return (
                <Form className='text-left'>
                    <div className="border p-5 rounded ">
                      {/* {editPersonalDetails&& (
                  <div
                    className="bg-green text-center border-green text-white py-3 mt-1 mb-5 rounded relative"
                    role="alert"
                  >
                    <span className="block sm:inline">Successful</span>
                    <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                      <svg className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" /></svg>
                    </span>
                  </div>
                )} */}

                        <div className=''><Head title='Peronal Details' /></div>
                        <div className='grid grid-cols-3 gap-2'>
                            <FormikController
                                control='select'
                                type='select'
                                name='title'
                                label='Title*'
                                options={title}
                            />

                            <FormikController
                                control='input'
                                type='text'
                                name='firstname'
                                label='First name*'
                            />
                            <FormikController
                                control='input'
                                type='text'
                                name='middlename'
                                label='Middle name'
                                placeholder="Optional"
                            />

                            <FormikController
                                control='input'
                                type='text'
                                name='lastname'
                                label='Last name*'
                            />

                            <FormikController
                                control='input'
                                type='date'
                                name='dob'
                                label='Date of Birth*'
                            />


                            <FormikController
                                control='input'
                                type='text'
                                name='preference'
                                label='Prefer to be called'
                                placeholder="Optional"
                            />
                              <FormikController
                          control="input"
                          type="text"
                          name="clientID"
                          label="Client ID"
                     
                        />
                        <FormikController
                          control="input"
                          type="text"
                          name="nhsNumber"
                          label="NHS Number"
                          placeholder="Optional"
                        />

                            <FormikController
                                control='input'
                                type='text'
                                name='languages'
                                label='Languages Spoken*'
                                placeholder='eg. English, French, Brittish'
                            />

                            <FormikController
                                control='select'
                                type='select'
                                name='religion'
                                label='Religion*'
                                options={religon}
                            />

                        </div>
                        <div className='mt-10'><Head title='Contact Details' /></div>
                        <div className='grid grid-cols-3 gap-2'>
                            <FormikController
                                control='input'
                                type='text'
                                name='mobile'
                                label='Mobile number*'
                            />
                            <FormikController
                                control='input'
                                type='text'
                                name='landline'
                                label='Landline number'
                            />
                            <FormikController
                                control='input'
                                type='email'
                                name='email'
                                label='Email address*'
                            />
                        </div>
                        <div className="mt-2 ml-4">
                            <label className='font-medium'>Search post code</label>
                            <Postcoder
                                apiKey={process.env.REACT_APP_POSTCODER}
                                addressSelectedCallback={logAddress}
                            />
                        </div>
                        <div className='grid grid-cols-3 gap-2 mt-5'>
                            <div>
                                <FormikController
                                    control='input'
                                    type='text'
                                    name="addressLine1"
                                    placeholder=""
                                    label="Address line 1 *"
                                />
                            </div>
                            <div>
                                <FormikController
                                    control='input'
                                    type='text'
                                    name="addressLine2"
                                    placeholder=""
                                    label="Address line 2"
                                />
                            </div>
                            <div>
                                <FormikController
                                    control='input'
                                    type='text'
                                    name="town"
                                    placeholder=""
                                    label="Town *" />
                            </div>
                            <div>
                                <FormikController
                                    control='input'
                                    type='text'
                                    name="county"
                                    placeholder=""
                                    label="County *" />
                            </div>
                            <div>
                                <FormikController
                                    control='input'
                                    type='text'
                                    name="postcode"
                                    placeholder=""
                                    label="Post code *"
                                />
                            </div>
                        </div>
                        <div className="mt-5">
                  <Head title="Diagnosis Details" />
                </div>
                <div className="grid grid-cols-3 gap-2">
                  <FormikController
                    control="textArea"
                    type="text"
                    name="diagnosisDetails"
                    label="Diagnosis Details"
                  />
                  <FormikController
                    control="textArea"
                    type="text"
                    name="historyBackground"
                    label="History & Background"
                  />
                  <FormikController
                    control="textArea"
                    type="text"
                    name="likeDislikes"
                    label="Likes & Dislikes"
                  />

                  <FormikController
                    control="input"
                    type="text"
                    name="mobility"
                    label="Mobility"
                  />
                  <FormikController
                    control="input"
                    type="text"
                    name="whoAmI"
                    label="Who Am I ?"
                  />
                </div>
                {/* <div className="mt-5">
                        <Head title="Allergies" />
                      </div> */}
                      <div className="grid grid-cols-2 gap-2 mb-5 mt-5">
                        <div className="mt-2">
                          <FormikController
                            control="textArea"
                            type="text"
                            name="eatingDrinkingNeeds"
                            label="Eating | Drinking Needs (eg. has Dysphagia)"
                          />
                        </div>
                        <div className="mt-2">
                          <FormikController
                            control="textArea"
                            type="text"
                            name="specialistEquipment"
                            label="Specify any specialist equipment for moving & handling positioning"
                          />
                        </div>
                      </div>
                      <div className="grid grid-cols-2 gap-2">
                        <FormikController
                          control="textArea"
                          type="text"
                          name="knownAllergies"
                          label="Known Allergies"
                        />
                        <FormikController
                          control="textArea"
                          type="text"
                          name="painExpression"
                          label="How does the person express pain"
                        />
                        <FormikController
                          control="textArea"
                          type="text"
                          name="safetyIssues"
                          label="Identify any issues relating to safety(e.g wandering)"
                        />
                   


                      </div>
                      <div className='grid grid-cols-2 gap-2'>
                      <div className="mt-3">
                          <span className="font-medium">
                            Does the person suffer from any medical conditions
                          </span>
                          <br />
                          <div className="border rounded p-3">
                            <label>
                              <Field
                                type="checkbox"
                                name="Diabetes"
                                value={true}
                                checked={values.Diabetes === true}
                              />
                              <span className="ml-2">Diabetes</span>
                            </label>
                            <br />
                            <label>
                              <Field
                                type="checkbox"
                                name="Epilepsy"
                                value={true}
                                checked={values.Epilepsy === true}
                              />
                              <span className="ml-2">Epilepsy</span>
                            </label>
                            <br />
                            <label>
                              <Field
                                type="checkbox"
                                name="Asthma"
                                value={true}
                                checked={values.Asthma === true}
                              />
                              <span className="ml-2">Asthma</span>
                            </label>
                            <br />
                            <label>
                              <Field
                                type="checkbox"
                                name="SpinaBifina"
                                value={true}
                                checked={values.SpinaBifina === true}
                              />
                              <span className="ml-2">Spina Bifida</span>
                            </label>
                            <br />
                            <label>
                              <Field
                                type="checkbox"
                                name="OtherMedical"
                                value={true}
                                checked={values.OtherMedical === true}
                              />
                              <span className="ml-2">Other</span>
                            </label>

                            {values.OtherMedical === true && (
                              <div className="mt-2">
                                <FormikController
                                  control="textArea"
                                  type="text"
                                  name="otherDetails"
                                  label="Please specificy your other needs"
                                />
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="mt-3">
                          <span className="font-medium">
                            Special Dietary Needs
                          </span>
                          <br />
                          <div className="border rounded p-3">
                            <label>
                              <Field
                                type="checkbox"
                                name="dietDiebetes"
                                value={true}
                                checked={values.dietDiebetes === true}
                              />
                              <span className="ml-2">Diabetes</span>
                            </label>
                            <br />
                            <label>
                              <Field
                                type="checkbox"
                                name="vegetarian"
                                value={true}
                                checked={values.vegetarian === true}
                              />
                              <span className="ml-2">Vegetarian</span>
                            </label>
                            <br />
                            <label>
                              <Field
                                type="checkbox"
                                name="vegan"
                                value={true}
                                checked={values.vegan === true}
                              />
                              <span className="ml-2">Vegan</span>
                            </label>
                            <br />
                            <label>
                              <Field
                                type="checkbox"
                                name="specialDiet"
                                value={true}
                                checked={values.specialDiet === true}
                              />
                              <span className="ml-2">Specialized</span>
                            </label>
                            <br />
                            {values.specialDiet === true && (
                              <div className="mt-2">
                                <FormikController
                                  control="textArea"
                                  type="text"
                                  name="specialDietDetails"
                                  label="Please specificy your other diet needs"
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="mt-3">
                          <span className="font-medium">
                            Additional Information
                          </span>
                          <br />
                          <div className="border rounded p-3">
                            <label>
                              <Field
                                type="checkbox"
                                name="smoker"
                                value={true}
                                checked={values.smoker === true}
                              />
                              <span className="ml-2">Smoker</span>
                            </label>
                            <br />
                            <label>
                              <Field
                                type="checkbox"
                                name="cat"
                                value={true}
                                checked={values.cat === true}
                              />
                              <span className="ml-2">Cat</span>
                            </label>
                            <br />
                            {values.cat && (
                              <div>
                                {" "}
                                <FormikController
                                  control="textArea"
                                  type="text"
                                  name="catName"
                                  label="Name of Cat"
                                />
                                <FormikController
                                  control="textArea"
                                  type="text"
                                  name="catActivities"
                                  label="Activities"
                                />
                              </div>
                            )}
                            <label>
                              <Field
                                type="checkbox"
                                name="dog"
                                value={true}
                                checked={values.dog === true}
                              />
                              <span className="ml-2">Dog</span>
                            </label>
                            <br />
                            {values.dog && (
                              <div>
                                {" "}
                                <FormikController
                                  control="textArea"
                                  type="text"
                                  name="dogName"
                                  label="Name of Dog"
                                />
                                <FormikController
                                  control="textArea"
                                  type="text"
                                  name="dogActivities"
                                  label="Activities"
                                />
                              </div>
                            )}
                          </div>
                        </div>
                    </div>
                    <div className="mb-3 mt-5 flex flex-row-reverse">
                        <div></div>
                        {isLoadingEditPersonal ? <PageLoader/> : <SubmitButton label='Update' />}
                    </div>
                </Form>
            )
        }}
    </Formik>
</div>
  )
}

export default PersonalDetails