import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  EditMandatoryCert: {},
  isLoadingEditMandatoryCert: false,
  errorEditMandatoryCert: "",
};

const EditMandatoryCertSlice = createSlice({
  name: "EditMandatoryCert",
  initialState,
  reducers: {
    EditMandatoryCertPending: (state) => {
      state.isLoadingEditMandatoryCert = true;
    },
    EditMandatoryCertSuccess: (state, { payload }) => {
      state.isLoadingEditMandatoryCert = false;
      state.EditMandatoryCert = payload;
      state.errorEditMandatoryCert = "";
    },
    EditMandatoryCertFail: (state, { payload }) => {
      state.isLoadingEditMandatoryCert = false;
      state.errorEditMandatoryCert = payload;
    },
  },
});

export const { EditMandatoryCertPending, EditMandatoryCertSuccess, EditMandatoryCertFail } =
  EditMandatoryCertSlice.actions;

export default EditMandatoryCertSlice.reducer;
