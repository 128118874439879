import React from "react";
import { Sidebar } from "../../../layouts/auth/Sidebar";
import { TopNavigation } from "../../../layouts/auth/TopNavigation";
import SimpleSwitch from "../../../components/SimpleSwitch";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import ProgressBar from "../../clients/AddClient/ProgressBar";
import SubmitButton from "../../../components/Formik/SubmitButton";
import { useDispatch, useSelector } from "react-redux";
import GetCarerInfoAction from "../RegisterCarerActions/GetCarerInfoAction";
import TermsAgreementAction from "../RegisterCarerActions/TermsAgreementAction";
import PageLoader from "../../../components/PageLoader";

function CarerDataConsent({carer}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { signUp, isLoadingSignUp, errorSignUp } = useSelector(
    (state) => state.signUpCarer
  );

  const Head = (props) => {
    return (
      <div className="border-b mb-5 text-left">
        <label className="text-xl font-medium text-gray-700">
          {props.title}
        </label>
      </div>
    );
  };

  const {GetCarerInfo, isLoadingGetCarerInfo} = useSelector((state)=>state.GetCarerInfoSliceReducer)
  const {TermsAgreement, isLoadingTermsAgreement} = useSelector((state)=>state.AgreementTerms)
  
  React.useEffect(() => {
    dispatch(GetCarerInfoAction({id:carer?.carer?.id}))
  }, [TermsAgreement]);
  const initialValues = {
    PersonalDataConsent:GetCarerInfo ? GetCarerInfo.PersonalDataConsent : false,
  };


  const validationSchema = Yup.object({});

  const handleSubmit = (values) => {
    values.id = signUp?.user?.id
    dispatch(TermsAgreementAction(values))
    navigate("/carer-disability");
  };

  const handleUpdate = (values) => {
    values.id = GetCarerInfo?.id
    dispatch(TermsAgreementAction(values))
  };
  return (
    <div>
      {!carer && <Sidebar />}
      {!carer && <TopNavigation title="Add Carer" />}
      <div className="bg-white h-screen">
      <div className={carer ? "" : "flex ml-80 mr-16 mt-5"}>
         {!carer && <div className="w-40 border mt-5 p-3 rounded-lg bg-gray-50">
            <ProgressBar number={70} />
          </div>}
          <div className="w-full p-5">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values) =>  (GetCarerInfo ) ? handleUpdate(values) : handleSubmit(values)}
            >
              {({ setFieldValue, formik, values }) => {
                return (
                  <Form className="text-left">
                    <div className="border p-5 rounded ">
                      <div className="">
                        <Head title="Employees Processing of Personal Data Consent" />
                      </div>
                      <div className="grid grid-cols-1 gap-2">
                        <div showsVerticalScrollIndicator={false}>
                         
                          <div
                            style={{
                              fontWeight: "600",
                              marginBottom: 15,
                              borderBottomWidth: 0.4,
                              paddingBottom: 3,
                            }}
                          >
                            <div style={{ fontWeight: "800" }}>1.0 :</div> I
                            accept that the Service holds personal data about me
                            and I hereby consent to the processing by the
                            Service or any associated company of my personal
                            data for any purpose related to the performance of
                            my contract of employment or my continuing
                            employment or its termination or the conduct of the
                            Service’s business, including, but not limited to,
                            payroll, human resources and business continuity
                            planning purposes.
                          </div>

                          <div
                            style={{
                              fontWeight: "600",
                              marginBottom: 15,
                              borderBottomWidth: 0.4,
                              paddingBottom: 3,
                            }}
                          >
                            <div style={{ fontWeight: "800" }}>2.0 :</div> I
                            also explicitly consent to the Service or any
                            associated organization processing any sensitive
                            personal data relating to me, for example sickness
                            absence records, medical reports, particular health
                            needs, details of criminal convictions and equal
                            opportunities monitoring data, as necessary for the
                            performance of my contract of employment or my
                            continuing employment or its termination or the
                            conduct of the Service’s business.
                          </div>

                          <div
                            style={{
                              fontWeight: "600",
                              marginBottom: 15,
                              borderBottomWidth: 0.4,
                              paddingBottom: 3,
                            }}
                          >
                            <div style={{ fontWeight: "800" }}>3.0 :</div>{" "}
                            Finally, I consent to the Service providing my
                            personal data to a third party where this is
                            necessary for the performance of my contract of
                            employment or my continuing employment or its
                            termination or the conduct of the Service’s
                            business, for example to a pension scheme provider
                            in relation to my membership of a pension scheme or
                            to an insurance company in relation to the provision
                            of insured benefits.
                          </div>

                          <div
                            style={{ flexDirection: "row", paddingVertical: 5 }} className="bg-monday mt-10 mb-5"
                          >
                            <SimpleSwitch
                              name="PersonalDataConsent"
                              label="l declare that l have read and understood the information above"
                              checked={values.PersonalDataConsent === true}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mb-3 mt-5 flex flex-row-reverse">
                      <div></div>
                     { isLoadingTermsAgreement ? <PageLoader/> : <SubmitButton label="Save" />}
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CarerDataConsent;
