import React from "react";
import FormikController from "../../../components/Formik/FormikController";
import SwitchCheckBox from "../../../components/SwitchCheckBox";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import GetCarerInfoAction from '../../carers/RegisterCarerActions/GetCarerInfoAction';
import CarerEducationTraining from "../../carers/AddCarer/CarerEducationTraining"

function EducationTraining({ carer }) {
  const dispatch = useDispatch();
  const {GetCarerInfo, isLoadingGetCarerInfo} = useSelector((state)=>state.GetCarerInfoSliceReducer)
  const { educationTraining, isLoadingEducationTraining } = useSelector(
    (state) => state.EducationTraining
  );
// console.log('EDUCTT',GetCarerInfo);
    
  const GCSEOLevel = GetCarerInfo?.educationTraining
    ? GetCarerInfo?.educationTraining.GCSEOLevel
    : false;
  const GCSEOLevelMaths = GetCarerInfo?.educationTraining
    ? GetCarerInfo?.educationTraining.GCSEOLevelMaths
    : false;
  const GCSEOLevelEnglish = GetCarerInfo?.educationTraining
    ? GetCarerInfo?.educationTraining.GCSEOLevelEnglish
    : false;
  const MandatoryTrainingCertification = GetCarerInfo?.educationTraining
    ? GetCarerInfo?.educationTraining.MandatoryTrainingCertification
    : false;
  const MedicationTrainingCertification = GetCarerInfo?.educationTraining
    ? GetCarerInfo?.educationTraining.MedicationTrainingCertification
    : false;
  const MovingAndHandlingCertification = GetCarerInfo?.educationTraining
    ? GetCarerInfo?.educationTraining.MovingAndHandlingCertification
    : false;
  const PegFeedCertification = GetCarerInfo?.educationTraining
    ? GetCarerInfo?.educationTraining.PegFeedCertification
    : false;
  const diploma = GetCarerInfo?.educationTraining
    ? GetCarerInfo?.educationTraining.diploma
    : false;

  const MandatoryTrainingCertificate = GetCarerInfo?.educationTraining && GetCarerInfo?.educationTraining.MandatoryTrainingCertificate ? GetCarerInfo?.educationTraining?.MandatoryTrainingCertificate.split(",") : null;
  const MedicationTrainingCertificate = GetCarerInfo?.educationTraining && GetCarerInfo?.educationTraining.MedicationTrainingCertificate ? GetCarerInfo?.educationTraining?.MedicationTrainingCertificate.split(",") : null;
  const MovingAndHandlingCertificate = GetCarerInfo?.educationTraining && GetCarerInfo?.educationTraining.MovingAndHandlingCertificate ? GetCarerInfo?.educationTraining?.MovingAndHandlingCertificate.split(",") : null;
  const PegFeedCertificate = GetCarerInfo?.educationTraining && GetCarerInfo?.educationTraining.PegFeedCertificate ? GetCarerInfo?.educationTraining?.PegFeedCertificate.split(",") : null;
  const diplomaCertificate = GetCarerInfo?.educationTraining && GetCarerInfo?.educationTraining.diplomaCertificate ? GetCarerInfo?.educationTraining?.diplomaCertificate.split(",") : null;
  const CareCertificate = GetCarerInfo?.educationTraining && GetCarerInfo?.educationTraining.CareCertificate ? GetCarerInfo?.educationTraining?.CareCertificate.split(",") : null;

  const initialValues = {
    GCSEOLevelNumberOfSubjects: GetCarerInfo?.educationTraining
      ? GetCarerInfo?.educationTraining.GCSEOLevelNumberOfSubjects
      : false,
  };

  React.useEffect(() => {
    dispatch(GetCarerInfoAction({id:carer?.carer?.id}))
  }, [educationTraining,]);

  const validationSchema = Yup.object({});

  const Head = (props) => {
    return (
      <div className="border-b mb-5">
        <label className="text-xl font-medium text-gray-700">
          {props.title}
        </label>
      </div>
    );
  };


  return (!GetCarerInfo?.educationTraining ? <CarerEducationTraining GetCarerInfo={GetCarerInfo} /> :
    <div className="w-full p-5">
      <Formik initialValues={initialValues} validationSchema={validationSchema}>
        {(formik) => {
          return (
            <Form className="text-left">
              <div className="text-left font-bold text-gray-600 p-4 text-md bg-gray-100 mb-5 rounded uppercase">
              Education & Training
                    </div>
              <div className="border p-5 rounded ">
                
                <div className="grid grid-cols-1 gap-2">
                  <SwitchCheckBox
                    name="GCSEOLevel"
                    label="GCSE O Level"
                    checked={GCSEOLevel === true}
                  />

             { GetCarerInfo?.educationTraining?.GCSEOLevelNumberOfSubjects &&  <FormikController
                    control="input"
                    type="text"
                    name="GCSEOLevelNumberOfSubjects"
                    label="GCSEO Level Number Of Subjects"
                    placeholder=""
                  />}

                  <SwitchCheckBox
                    name="GCSEOLevelMaths"
                    label="GCSE O Level Maths"
                    checked={GCSEOLevelMaths === true}
                  />

                  <SwitchCheckBox
                    name="GCSEOLevelEnglish"
                    label="GCSE O Level English"
                    checked={GCSEOLevelEnglish === true}
                  />
                  {/* <SwitchCheckBox
                    name="MandatoryTrainingCertification"
                    label="Mandatory Training Certification"
                    checked={MandatoryTrainingCertification === true}
                  /> */}
                  {/* {MandatoryTrainingCertification === true && ( */}
                  {MandatoryTrainingCertificate && (
                   <div>
                     <Head title="Mandatory Training Certification" />
                      <iframe
                        src={MandatoryTrainingCertificate}
                        frameborder="0"
                        height="550"
                        width="1000"
                      ></iframe>
                   </div>
                  
                  )}
                  {/* // )} */}

                  {/* <SwitchCheckBox
                    name="MedicationTrainingCertification"
                    label="Medication Training Certification"
                    checked={MedicationTrainingCertification === true}
                  /> */}
                  {/* {MedicationTrainingCertification === true && ( */}
                  {MedicationTrainingCertificate && (
                   <div>
                    <Head title="Medication Training Certification" />
                      <iframe
                        src={MedicationTrainingCertificate}
                        frameborder="0"
                        height="550"
                        width="1000"
                      ></iframe>
                   </div>
                  
                  )}
                
{/* 
                  <SwitchCheckBox
                    name="MovingAndHandlingCertification"
                    label="Moving And Handling Certification"
                    checked={MovingAndHandlingCertification === true}
                  /> */}
                  {/* {MovingAndHandlingCertification === true && ( */}

                  {MovingAndHandlingCertificate && (
                   <div>
                    <Head title="Moving And Handling Certificate" />
                      <iframe
                        src={MovingAndHandlingCertificate}
                        frameborder="0"
                        height="550"
                        width="1000"
                      ></iframe>
                   </div>
                  
                  )}
          

                  {/* <SwitchCheckBox
                    name="PegFeedCertification"
                    label="Peg Feed Certification"
                    checked={PegFeedCertification === true}
                  /> */}
                  {/* {PegFeedCertification === true && ( */}

                  {PegFeedCertificate && (
                   <div>
                      <Head title="Peg Feed Certificate" />
                      <iframe
                        src={PegFeedCertificate}
                        frameborder="0"
                        height="550"
                        width="1000"
                      ></iframe>
                   </div>
                  
                  )}
                

                  {/* <SwitchCheckBox
                    name="diploma"
                    label="Diploma "
                    checked={diploma === true}
                  /> */}
                  {/* {diploma === true && ( */}
                  {GetCarerInfo?.educationTraining?.diplomaName &&<div className="uppercase"> <span className="text-gray-700">DIPLOMA NAME : </span>{GetCarerInfo?.educationTraining.diplomaName}</div>}
                  {diplomaCertificate && (
                   <div>
                        <Head title="Diploma Certificate" />
                      <iframe
                        src={diplomaCertificate}
                        frameborder="0"
                        height="550"
                        width="1000"
                      ></iframe>
                   </div>
                  
                  )}
                  {CareCertificate && (
                   <div>
                        <Head title="Care Certificate" />
                      <iframe
                        src={CareCertificate}
                        frameborder="0"
                        height="550"
                        width="1000"
                      ></iframe>
                   </div>
                  
                  )}

          
                </div>
              </div>
              <div className="mb-3 mt-5 flex flex-row-reverse">
                <div></div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default EducationTraining;
