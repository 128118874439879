import React from "react";
import { BsFillEyeFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import FormikController from "../../../components/Formik/FormikController";
import SubmitButton from "../../../components/Formik/SubmitButton";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import UpdateNextofkinAction from "../RegisterCarerActions/UpdateNextofkinAction";
import PageLoader from "../../../components/PageLoader";

const EditCarerNextofKin = ({kin}) => {

  const [showModal, setShowModal] = React.useState(false);
  const { Login, isLoading, error } = useSelector((state) => state.CarerToken);
  const {GetCarerInfo, isLoadingGetCarerInfo} = useSelector((state)=>state.GetCarerInfoSliceReducer)
  const handleOpen = (id) => {
    setShowModal(true);
  };
  const dispatch = useDispatch();
  const { updateNextofkin, isLoadingupdateNextofkin } = useSelector(
    (state) => state.UpdateNextofkin
  );
console.log('updateNextofkin',updateNextofkin);
  const validationSchema = Yup.object({
  });

  const initialValues = {
    firstname: kin ? kin.firstname : "",
    middlename: kin ? kin.middlename : "",
    lastname:kin ? kin.lastname : "",
    relationship: kin ? kin.relationship : "",
    email: kin ? kin.email : "",
    phone:  kin ? kin.phone : "",


  };


  React.useEffect(() => {
 
  }, []);

 
  const handleUpdate = (values) => {
    // values.accessToken = Login.access_token;
    values.id = kin?.id;
    console.log("VAL", values);
    dispatch(UpdateNextofkinAction(values));
    setShowModal(false);
  };

  return (
    <>
      <button
        className=" text-white bg-green border hover:bg-white hover:text-gray-700 hover:border hover:border-gray-300 hover:underline inline-flex decoration-secondary focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-3xl text-sm px-3 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        type="button"
        onClick={() => handleOpen(kin?.id)}
      >
        View more <BsFillEyeFill className="ml-1 mt-1" />
      </button>
      {showModal ? (
        <>
          <div className="justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-xl font-semibold text-gray-500">
                    Next of Kin
                  </h3>
                  <button
                    className="p-1 ml-10 bg-transparent border-0 text-button float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    ×
                  </button>
                </div>

                <div className="relative p-6 flex-auto">
                  <div className="relative overflow-x-auto shadow-md sm:rounded-lg mb-10">
                  <div className="w-full p-5">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values) => handleUpdate(values)}
            >
              {({ formik, values }) => {
                return (
                  <Form className="">
                   <div className="grid grid-cols-2 gap-2">
                              <FormikController
                                control="input"
                                type="text"
                                name='relationship'
                                label="Relationship"
                              />

                              <FormikController
                                control="input"
                                type="text"
                                name="firstname"
                                label="First name"
                              />
                              <FormikController
                                control="input"
                                type="text"
                                name="middlename"
                                label="Middle name"
                                placeholder="Optional"
                              />

                              <FormikController
                                control="input"
                                type="text"
                                name="lastname"
                                label="Last name"
                              />

                              <FormikController
                                control="input"
                                type="text"
                                name="phone"
                                label="Mobile Number"
                              />

                              <FormikController
                                control="input"
                                type="email"
                                name="email"
                                label="Email"
                              />
                            </div>
                          
                 

                    <div className="mb-3 mt-5 flex flex-row-reverse">
                      <div></div>
                      {isLoadingupdateNextofkin ? <PageLoader/> : <SubmitButton label="Update" />}
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
                  </div>
              
                </div>
              </div>
            </div>
          </div>

          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default EditCarerNextofKin;
