import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    addClientCarePlans: {},
    isLoadingAddCarePlan: false,
    errorAddCarePlan: ''
}

const addClientCarePlansSlice = createSlice({
    name: 'addClientCarePlans',
    initialState,
    reducers:{
        addClientCarePlansPending: (state) => {
            state.isLoadingAddCarePlan = true;
        },
        addClientCarePlansSuccess: (state, {payload}) => {
            state.isLoadingAddCarePlan = false;
            state.addClientCarePlans = payload;
            state.errorAddCarePlan = '';
        },
        addClientCarePlansFail: (state, {payload}) => {
            state.isLoadingAddCarePlan = false;
            state.errorAddCarePlan = payload;
        }
    }
})

export const {addClientCarePlansPending, addClientCarePlansSuccess, addClientCarePlansFail} = addClientCarePlansSlice.actions

export default addClientCarePlansSlice.reducer