import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    getSelectedCarers: {},
    isLoadinggetSelectedCarers: false,
    errorgetSelectedCarers: ''
}

const getSelectedCarersSlice = createSlice({
    name: 'getSelectedCarers',
    initialState,
    reducers:{
        getSelectedCarersPending: (state) => {
            state.isLoadinggetSelectedCarers = true;
        },
        getSelectedCarersSuccess: (state, {payload}) => {
            state.isLoadinggetSelectedCarers = false;
            state.getSelectedCarers = payload;
            state.errorgetSelectedCarers = '';
        },
        getSelectedCarersFail: (state, {payload}) => {
            state.isLoadinggetSelectedCarers = false;
            state.errorgetSelectedCarers = payload;
        }
    }
})

export const {getSelectedCarersPending, getSelectedCarersSuccess, getSelectedCarersFail} = getSelectedCarersSlice.actions

export default getSelectedCarersSlice.reducer