import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    getClientInfo: {},
    isLoadinggetClientInfo: false,
    errorgetClientInfo: ''
}

const getClientInfoSlice = createSlice({
    name: 'getClientInfo',
    initialState,
    reducers:{
        getClientInfoPending: (state) => {
            state.isLoadinggetClientInfo = true;
        },
        getClientInfoSuccess: (state, {payload}) => {
            state.isLoadinggetClientInfo = false;
            state.getClientInfo = payload;
            state.errorgetClientInfo = '';
        },
        getClientInfoFail: (state, {payload}) => {
            state.isLoadinggetClientInfo = false;
            state.errorgetClientInfo = payload;
        }
    }
})

export const {getClientInfoPending, getClientInfoSuccess, getClientInfoFail} = getClientInfoSlice.actions

export default getClientInfoSlice.reducer