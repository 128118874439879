import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  TrainingHistory: {},
  isLoadingTrainingHistory: false,
  errorTrainingHistory: "",
};

const TrainingHistorySlice = createSlice({
  name: "TrainingHistory",
  initialState,
  reducers: {
    TrainingHistoryPending: (state) => {
      state.isLoadingTrainingHistory = true;
    },
    TrainingHistorySuccess: (state, { payload }) => {
      state.isLoadingTrainingHistory = false;
      state.TrainingHistory = payload;
      state.errorTrainingHistory = "";
    },
    TrainingHistoryFail: (state, { payload }) => {
      state.isLoadingTrainingHistory = false;
      state.errorTrainingHistory = payload;
    },
  },
});

export const { TrainingHistoryPending, TrainingHistorySuccess, TrainingHistoryFail } =
  TrainingHistorySlice.actions;

export default TrainingHistorySlice.reducer;
