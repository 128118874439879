import {clientContractsPending, clientContractsSuccess, clientContractsFail} from '../ClientSlices/ClientContractsSlice'
import {addClientContract} from '../../../api/clientApi'

 const addContracts = (data) => async dispatch => {

    try {
        dispatch(clientContractsPending());
        const result = await addClientContract(data);
        if(result){
            return dispatch(clientContractsSuccess(result))
        }
    } catch (error) {
        dispatch(clientContractsFail(error))
    }
} 

export default addContracts