import React from 'react'
import CareWorkerApplications from './CareWorkerApplications'
import CareWorkerDbs from './CareWorkerDbs'

const CareWorkers = () => {
  const [active, setActive] = React.useState('applications');

  let content;

  switch (active) {
    case 'applications':
      content = <CareWorkerApplications />
      break;
    default:
      break;
  }
  return (
    <>
    <div>
      <ul className="flex flex-wrap -mb-px ml-80 pt-1">
        <li className="mr-2">
          {/* <button onClick={() => setActive('applications')} className={`inline-block p-2 focus:outline-none rounded-t-lg border ${active === 'applications' ? "border-secondary text-white font-medium dark:text-button dark:border-button bg-secondary" : "border-transparent hover:text-button hover:border-secondary dark:hover:text-gray-300"}`}>Applications</button> */}
        </li>
      
      </ul>
    </div>

  <div className="">
    {content}
  </div>
  </>
  )
}

export default CareWorkers