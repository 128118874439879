import React from 'react'
import { Sidebar } from '../../layouts/auth/Sidebar'
import { DashboardNav } from '../../layouts/auth/DashboardNav'
import carer1 from '../../assets/images/carer1.png'
import carer2 from '../../assets/images/carer2.png'
import clockin from '../../assets/images/clockin.png'
import clock from '../../assets/images/clock.png'
import clockout from '../../assets/images/clockout.png'
import out from '../../assets/images/out.png'
import waiting from '../../assets/images/waiting.png'
import wait from '../../assets/images/wait.png'
import alerts from '../../assets/images/alerts.png'
import message from '../../assets/images/message.png'
import applicants from '../../assets/images/applicants.png'
import appli from '../../assets/images/appli.png'
import overdue from '../../assets/images/overdue.png'
import over from '../../assets/images/over.png'
import ViewApplication from './ViewApplication'
import ViewCarersOnplacement from './ViewCarersOnplacement'
import ViewCarersWaitingPlacement from './ViewCarersWaitingPlacement'
import ClockInOverdue from './ClockInOverdue'
import ClockIn from './ClockIn'
import ClockOut from './ClockOut'
import ViewMessages from './ViewMessages'
import getCarerList from '../carers/CarerActions/GetCarersAction'
import { useDispatch, useSelector } from 'react-redux'
import CountUp from "react-countup";
import getMessageList from './DashboardAction/GetMessagesAction';
import AssignedAction from './DashboardAction/AssignedAction'
import ViewMarChart from './ViewMarChart'
import ClockOutOverdueAction from './DashboardAction/ClockOutOverdueAction'
import DashboardLoader from '../../components/DashboardLoader'

export const Dashboard = () => {
  const dispatch = useDispatch();
  const { getCarers, isLoadingGetCarers } = useSelector(state => state.getCarersList);
  const { getCarersOnplacement } = useSelector(state => state.getCarerPlacement);
  const { getCarersWaitingPlacement } = useSelector(state => state.getCarerWaitingPlacement);
  const { clockInOverdueCarer } = useSelector(state => state.clockInOverdueCare);
  const { clockOutOverdue, isLoadingClockOutOverdue } = useSelector((state) => state.clockOutOverdueCare);
  const { carerClockin } = useSelector(state => state.clockInCarer);
  const { carerClockOut, isLoadingClockOut } = useSelector(state => state.clockOutCarer);
  const { getMessages, isLoadingMessages } = useSelector(state => state.getMessageList);
  const { getAssignedMarcharts, isLoadingAssignedMarcharts } = useSelector(state => state.assignedmarchart)
  const itemsCountPerPage = 10;
  const [page, setPage] = React.useState(1);
  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  }
 

  React.useEffect(() => {
    dispatch(getCarerList({
      page,
      perPage: itemsCountPerPage,
      status: 'PENDING'
    }))

    dispatch(
      ClockOutOverdueAction({
        page,
        perPage: itemsCountPerPage,
      })
    );

  }, [page]);


  function DisplayCurrentTime() {
    var date = new Date();
    var hours = date.getHours() > 12 ? date.getHours() - 12 : date.getHours();
    var am_pm = date.getHours() >= 12 ? "PM" : "AM";
    hours = hours < 10 ? "0" + hours : hours;
    var minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    var seconds = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    let time = hours + ":" + minutes + ":" + seconds + " " + am_pm;
    let val = time.split(' ')[1];
    let hr = Number(time.slice(0, 2));
    
    var result;
    switch(val){
      case 'AM':
        result = 'Morning';
        break;
      case 'PM':
        if(hr > 5){
          result = 'Evening';
        }else{
          result = 'Afternoon';
        }
        break;
    }
    return result;
    
  }


  React.useEffect(() => {
    dispatch(getMessageList({
      page,
      perPage: itemsCountPerPage
    }))
  }, [page])

  React.useEffect(() => {
    dispatch(AssignedAction())
  }, [])

  

  const overdueclockin = clockInOverdueCarer ? clockInOverdueCarer.totalItemsCount : 0;
  const overdueclockout = clockOutOverdue? clockOutOverdue.totalItemsCount : 0;
  // console.log("clockOutOverdue",clockOutOverdue)
  const Head = (props) => {
    return (
      <div className='border-b mb-5'><label className='text-xl font-medium text-gray-700'>{props.title}</label></div>
    )
  }
  return (
    <div><Sidebar /><DashboardNav title='Dashboard' />

      <div className='bg-backk shadow-lg border h-screen flex'>

        <div className='grid grid-cols-3 2xl:grid-cols-4 gap-3  h-1 ml-72  mt-10 w-full mr-7'>
          <div className="p-6 max-w-sm bg-white rounded-lg flex justify-between border text-left border-gray-200  dark:bg-gray-800 dark:border-gray-700">
            <div><p className="mb-3 font-normal text-gray-700 dark:text-gray-400 inline-flex bg-cyan-50 px-3 py-1 shadow-sm ">Applicants {getCarers && getCarers.data && getCarers.data.length > 0 ? <div><span className=" ml-2 flex h-3 w-3">
              <span className="animate-ping absolute inline-flex h-3 w-3 rounded-full bg-yellow-500 opacity-75"></span>
              <span className="relative inline-flex rounded-full h-3 w-3 bg-yellow-500"></span>
            </span> <span className='bg-blue-600 ml-5 font-semibold text-sm text-white px-2'>NEW</span></div> : ""}</p>
             {isLoadingGetCarers ? <DashboardLoader/> : <h5 className="mb-7 text-3xl font-bold tracking-tight text-gray-900 dark:text-white"> <CountUp end={getCarers && getCarers.data ? getCarers.totalItemsCount : ''} /></h5>}
              <ViewApplication />
            </div>

            <div> <img src={appli} alt='' className='h-20' /></div>
          </div>
          <div className="p-6 max-w-sm bg-white rounded-lg flex justify-between border text-left border-gray-200  dark:bg-gray-800 dark:border-gray-700">
            <div><p className="mb-3 font-normal text-gray-700 dark:text-gray-400">Carers On Placement</p>
              <h5 className="mb-7 text-3xl font-bold tracking-tight text-gray-900 dark:text-white"> <CountUp end={getCarersOnplacement && getCarersOnplacement.data ? getCarersOnplacement.totalItemsCount : ''} /></h5>

              <ViewCarersOnplacement /></div>

            <div> <img src={carer2} alt='' className='h-20' /></div>
          </div>

          <div className="p-6 max-w-sm bg-white rounded-lg flex justify-between border text-left border-gray-200  dark:bg-gray-800 dark:border-gray-700">
            <div><p className="mb-3 font-normal text-gray-700 dark:text-gray-400">Clocked In</p>
              <h5 className="mb-7 text-3xl font-bold tracking-tight text-gray-900 dark:text-white"> <CountUp end={carerClockin && carerClockin.data ? carerClockin.totalItemsCount : ''} /></h5>
              <ClockIn />
            </div>

            <div> <img src={clock} alt='' className='h-20' /></div>
          </div>

          <div className="p-6 max-w-sm bg-white rounded-lg flex justify-between border text-left border-gray-200  dark:bg-gray-800 dark:border-gray-700">
            <div><p className="mb-3 font-normal text-gray-700 dark:text-gray-400 py-1">Clocked Out</p>
              <h5 className="mb-7 text-3xl font-bold tracking-tight text-gray-900 dark:text-white"> <CountUp end={carerClockOut && carerClockOut.data ? carerClockOut.totalItemsCount : ''} /></h5>

              <ClockOut /></div>

            <div> <img src={out} alt='' className='h-20' /></div>
          </div>

          <div className="p-6 max-w-sm bg-white rounded-lg flex justify-between border text-left border-gray-200  dark:bg-gray-800 dark:border-gray-700">
            <div><p className="mb-3 font-normal text-gray-700 dark:text-gray-400">Carers Waiting Placement</p>
              <h5 className="mb-7 text-3xl font-bold tracking-tight text-gray-900 dark:text-white"> <CountUp end={getCarersWaitingPlacement && getCarersWaitingPlacement.data ? getCarersWaitingPlacement.totalItemsCount : ''} /></h5>


              <ViewCarersWaitingPlacement /></div>

            <div> <img src={wait} alt='' className='h-20' /></div>
          </div>
          <div className="p-6 max-w-sm bg-white rounded-lg flex justify-between border text-left border-gray-200  dark:bg-gray-800 dark:border-gray-700">
            <div><p className="mb-3 font-normal text-gray-700 dark:text-gray-400">Overdue</p>
              <h5 className="mb-7 text-3xl font-bold tracking-tight text-gray-900 dark:text-white"> <CountUp end={overdueclockin+overdueclockout} /></h5>
              <ClockInOverdue /></div>

            <div> <img src={over} alt='' className='h-20' /></div>
          </div>
          {/* <div className="p-6 max-w-sm bg-white rounded-lg flex justify-between border text-left border-gray-200  dark:bg-gray-800 dark:border-gray-700">
            <div><p className="mb-3 font-normal text-gray-700 dark:text-gray-400">Alerts</p>
              <h5 className="mb-2 text-3xl font-bold tracking-tight text-gray-900 dark:text-white">0</h5>


              <p className="inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white bg-green rounded-lg hover:bg-primary hover:text-gray-500 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                View
                <svg aria-hidden="true" className="ml-2 -mr-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
              </p></div>

            <div> <img src={alerts} alt='' className='h-20' /></div>
          </div> */}

          {/* <div className="p-6 max-w-sm bg-white rounded-lg flex justify-between border text-left border-gray-200  dark:bg-gray-800 dark:border-gray-700">
            <div><p className="mb-3 font-normal text-gray-700 dark:text-gray-400">Messages</p>
              <h5 className="mb-2 text-3xl font-bold tracking-tight text-gray-900 dark:text-white"><CountUp end={getMessages && getMessages.data ? getMessages.totalItemsCount : ''} /></h5>
              <ViewMessages messages={getMessages}/></div>

            <div> <img src={message} alt='' className='h-20' /></div>
          </div> */}

          {getAssignedMarcharts && getAssignedMarcharts.length > 0 ? getAssignedMarcharts.map((marchart) => (<div className="p-6 max-w-sm bg-white rounded-lg flex justify-between border text-left border-gray-200  dark:bg-gray-800 dark:border-gray-700">

            <div><p className="mb-3 font-normal text-gray-700 dark:text-gray-400 inline-flex">Medication Waiting Verification </p>
              <h5 className="mb-2 text-3xl font-bold tracking-tight text-gray-900 dark:text-white">{marchart.medication}</h5>
              <ViewMarChart e={marchart} />
            </div>

            <div> <img src={applicants} alt='' className='h-20' /></div>
          </div>
          )) : ''}


        </div>





      </div>

    </div>

  )
}
