import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    riskAssessmentt: {},
    isLoadingriskAssessmentt: false,
    errorriskAssessmentt: ''
}

const riskAssessmenttSlice = createSlice({
    name: 'riskAssessmentt',
    initialState,
    reducers:{
        riskAssessmenttPending: (state) => {
            state.isLoadingriskAssessmentt = true;
        },
        riskAssessmenttSuccess: (state, {payload}) => {
            state.isLoadingriskAssessmentt = false;
            state.riskAssessmentt = payload;
            state.errorriskAssessmentt = '';
        },
        riskAssessmenttFail: (state, {payload}) => {
            state.isLoadingriskAssessmentt = false;
            state.errorriskAssessmentt = payload;
        }
    }
})

export const {riskAssessmenttPending, riskAssessmenttSuccess, riskAssessmenttFail} = riskAssessmenttSlice.actions

export default riskAssessmenttSlice.reducer