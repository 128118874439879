import React from 'react'
import { Sidebar } from '../../../layouts/auth/Sidebar'
import { TopNavigation } from '../../../layouts/auth/TopNavigation'
import AddCustomPlan from './AddCustomPlan'
import CarePlan from './CarePlan'
import { FaJenkins } from "react-icons/fa";
import ProgressBar from './ProgressBar';
import { useDispatch, useSelector } from 'react-redux';

function Careplans() {
    const dispatch = useDispatch();

    const { addClientPersonalDetails } = useSelector(state => state.addClientPersonal);

    React.useEffect(() => {

    }, [addClientPersonalDetails]);
    const [store, setStore] = React.useState(null);

    return (
        <div><Sidebar /><TopNavigation title='Add Client' />
            <div className='ml-72 mr-8 mt-10 bg-white h-screen inline-flex'>
                <div className='w-40 mr-3'><ProgressBar number={90} /></div>
                <div className=' w-full p-5'>
                    <nav className="flex py-3 px-5 mb-2 text-gray-700 bg-gray-50 rounded-lg border border-gray-200 dark:bg-gray-800 dark:border-gray-700" aria-label="Breadcrumb">
                        <ol className="inline-flex items-center space-x-1 md:space-x-3">
                            <li className="inline-flex items-center">
                                <a href="#" className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
                                    <FaJenkins className="mr-2 w-4 h-4 font-bold"/>  {addClientPersonalDetails.firstname} {addClientPersonalDetails.lastname}
                                </a>
                            </li>
                            <li>
                                <div className="flex items-center">
                                    <svg className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                    <a href="#" className="ml-1 text-sm font-medium text-gray-700 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white">Referral Contract</a>
                                    <svg className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>

                                </div>
                            </li>
                            <li>
                                <div className="flex items-center">
                                    <a href="#" className=" text-sm font-medium text-gray-700 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white">Key Contacts</a>
                                    <svg className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>

                                </div>
                            </li>
                            <li>
                                <div className="flex items-center">
                                    <a href="#" className=" text-sm font-medium text-gray-700 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white">Property Access</a>
                                    <svg className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>

                                </div>
                            </li>
                            <li>
                                <div className="flex items-center">
                                    <a href="#" className=" text-sm font-medium text-gray-700 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white">Risk Assessment</a>
                                    <svg className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>

                                </div>
                            </li>
                            <li>
                                <div className="flex items-center">
                                    <a href="#" className=" text-sm font-medium text-gray-700 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white">Care Plans</a>
                                    <svg className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>

                                </div>
                            </li>
                        </ol>
                    </nav>
                    <div className='border rounded p-5'>
                    <AddCustomPlan store={store} setStore={setStore} />
                    <CarePlan setStore={setStore} /></div>
                </div>
            </div>
        </div>
    )
}

export default Careplans