import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    getCarerTiers: {},
    isLoadingGetCarerTier: false,
    errorGetCarerTier: ''
}

const getCarerTierSlice = createSlice({
    name: 'getCarerTiers',
    initialState,
    reducers:{
        getCarerTierPending: (state) => {
            state.isLoadingGetCarerTier = true;
        },
        getCarerTierSuccess: (state, {payload}) => {
            state.isLoadingGetCarerTier = false;
            state.getCarerTiers = payload;
            state.errorGetCarerTier = '';
        },
        getCarerTierFail: (state, {payload}) => {
            state.isLoadingGetCarerTier = false;
            state.errorGetCarerTier = payload;
        }
    }
})

export const {getCarerTierPending, getCarerTierSuccess, getCarerTierFail} = getCarerTierSlice.actions

export default getCarerTierSlice.reducer