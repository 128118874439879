import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    getAddedMarCharts: {},
    isLoadingGetMarCharts: false,
    errorGetMarCharts: ''
}

const getMarChartsSlice = createSlice({
    name: 'getAddedMarCharts',
    initialState,
    reducers:{
        getMarChartsPending: (state) => {
            state.isLoadingGetMarCharts = true;
        },
        getMarChartsSuccess: (state, {payload}) => {
            state.isLoadingGetMarCharts = false;
            state.getAddedMarCharts = payload;
            state.errorGetMarCharts = '';
        },
        getMarChartsFail: (state, {payload}) => {
            state.isLoadingGetMarCharts = false;
            state.errorGetMarCharts = payload;
        }
    }
})

export const {getMarChartsPending, getMarChartsSuccess, getMarChartsFail} = getMarChartsSlice.actions

export default getMarChartsSlice.reducer