import React from "react";
import CarerDetails from "../recruitment/View/CarerDetails";
import Availability from "../recruitment/View/Availability";
import { useSelector } from "react-redux";
import HealthDeclaration from "../recruitment/View/HealthDeclaration";
import EducationTraining from "../recruitment/View/EducationTraining";
import EmploymentHistory from "../recruitment/View/EmploymentHistory";
import NextOfKin from "../recruitment/View/NextOfKin";
import TrainingHistory from "../recruitment/View/TrainingHistory";
import CarerBackground from "./AddCarer/CarerBackground";
import Documents from "../recruitment/View/Documents";
import References from "./EditCarers/References";
import CarerOptAgreement from "./AddCarer/CarerOptAgreement";
import CarerDataConsent from "./AddCarer/CarerDataConsent";

export default function DocumentView({ carer }) {
  const [showModal, setShowModal] = React.useState(false);

  const handleOpen = (id) => {
    setShowModal(true);
  };

  const { editCarePlan } = useSelector((state) => state.updateCarePlans);

  React.useEffect(() => {}, [editCarePlan]);

  const [active, setActive] = React.useState("personal");

  let content;

  switch (active) {
    case "personal":
      content = (
        <CarerDetails carer={{ carer: carer }} setShowModal={setShowModal} />
      );
      break;
    case "availability":
      content = (
        <Availability carer={{ carer: carer }} setShowModal={setShowModal} />
      );
      break;
    case "health":
      content = (
        <HealthDeclaration
          carer={{ carer: carer }}
          setShowModal={setShowModal}
        />
      );
      break;
      case "background":
        content = <CarerBackground carer={{ carer: carer }} setShowModal={setShowModal} />;
        break;
    case "education":
      content = <EducationTraining carer={{ carer: carer }} setShowModal={setShowModal} />;
      break;
    case "employment":
      content = (
        <EmploymentHistory
          carer={{ carer: carer }}
          setShowModal={setShowModal}
        />
      );
      break;
    case "kin":
      content = (
        <NextOfKin carer={{ carer: carer }} setShowModal={setShowModal} />
      );
      break;
    case "history":
      content = (
        <TrainingHistory carer={{ carer: carer }} setShowModal={setShowModal} />
      );
      break;
    case "documents":
      content = (
        <Documents carer={{ carer: carer }} setShowModal={setShowModal} />
      );
      break;
    case "opt":
      content = (
        <CarerOptAgreement
          carer={{ carer: carer }}
          setShowModal={setShowModal}
        />
      );
      break;
    case "consent":
      content = (
        <CarerDataConsent
          carer={{ carer: carer }}
          setShowModal={setShowModal}
        />
      );
      break;
    // case "references":
    //   content = <References carer={carer} setShowModal={setShowModal} />;
    //   break;

    default:
      break;
  }

  const options = [
    {
      key: "COMPLIANT",
      value: "COMPLIANT",
    },
    {
      key: "NOT-COMPLIANT",
      value: "NOT-COMPLIANT",
    },
    {
      key: "SUSPENDED",
      value: "SUSPEND",
    },
  ];

  return (
    <>
      <button
        class=" text-white bg-button border hover:bg-white hover:text-gray-700 hover:border hover:border-gray-300 hover:underline decoration-secondary focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-1 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        type="button"
        onClick={() => handleOpen(carer.id)}
      >
        View More
      </button>
      {showModal ? (
        <>
          <div className="justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto  mx-auto max-w-6xl">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-xl font-semibold text-gray-500">
                    <span className="text-green">
                      {carer.firstname} {carer.lastname}
                    </span>
                    's Profile
                  </h3>

                  <button
                    className="p-1 ml-10 bg-transparent border-0 text-button float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    ×
                  </button>
                </div>

                <div className="relative p-6 flex-auto">
                  <div className="border p-1 rounded bg-orange-50">
                    <div>
                      <ul className="flex flex-wrap">
                        <li className="mr-2">
                          <button
                            onClick={() => setActive("personal")}
                            className={`inline-block p-4 focus:outline-none rounded-t-lg border-b-2 ${
                              active === "personal"
                                ? "border-secondary text-white font-medium bg-orange-500 dark:text-button dark:border-button"
                                : "border hover:text-button hover:border-button text-gray-500  dark:hover:text-gray-300 hover:bg-gray-50 hover:border-b-secondary"
                            }`}
                          >
                            Personal Details
                          </button>
                        </li>
                        <li className="mr-2">
                          <button
                            onClick={() => setActive("kin")}
                            className={`inline-block p-4 focus:outline-none rounded-t-lg border-b-2 ${
                              active === "kin"
                                ? "border-secondary text-white font-medium bg-orange-500 dark:text-button dark:border-button"
                                : "border hover:text-button hover:border-button text-gray-500  dark:hover:text-gray-300 hover:bg-gray-50 hover:border-b-secondary"
                            }`}
                          >
                            Next Of Kin
                          </button>
                        </li>
                        <li className="mr-2">
                          <button
                            onClick={() => setActive("availability")}
                            className={`inline-block p-4 focus:outline-none rounded-t-lg border-b-2 ${
                              active === "availability"
                                ? "border-secondary text-white font-medium bg-orange-500 dark:text-button dark:border-button"
                                : "border hover:text-button hover:border-button text-gray-500  dark:hover:text-gray-300 hover:bg-gray-50 hover:border-b-secondary"
                            }`}
                          >
                            Availability
                          </button>
                        </li>
                        <li className="mr-2">
                          <button
                            onClick={() => setActive("health")}
                            className={`inline-block p-4 focus:outline-none rounded-t-lg border-b-2 ${
                              active === "health"
                                ? "border-secondary text-white font-medium bg-orange-500 dark:text-button dark:border-button"
                                : "border hover:text-button hover:border-button text-gray-500  dark:hover:text-gray-300 hover:bg-gray-50 hover:border-b-secondary"
                            }`}
                          >
                            Health Declaration
                          </button>
                        </li>
                        <li className="mr-1">
                          <button
                            onClick={() => setActive("background")}
                            className={`inline-block p-4 focus:outline-none rounded-t-lg border-b-2 ${
                              active === "background"
                                ? "border-secondary text-white font-medium bg-orange-500 dark:text-button dark:border-button"
                                : "border hover:text-button hover:border-button text-gray-500  dark:hover:text-gray-300 hover:bg-gray-50 hover:border-b-secondary"
                            }`}
                          >
                            Background
                          </button>
                        </li>
                        <li className="mr-2">
                          <button
                            onClick={() => setActive("education")}
                            className={`inline-block p-4 focus:outline-none rounded-t-lg border-b-2 ${
                              active === "education"
                                ? "border-secondary text-white font-medium bg-orange-500 dark:text-button dark:border-button"
                                : "border hover:text-button hover:border-button text-gray-500  dark:hover:text-gray-300 hover:bg-gray-50 hover:border-b-secondary"
                            }`}
                          >
                            Education & Training
                          </button>
                        </li>
                        <li className="mr-2">
                          <button
                            onClick={() => setActive("employment")}
                            className={`inline-block p-4 focus:outline-none rounded-t-lg border-b-2 ${
                              active === "employment"
                                ? "border-secondary text-white font-medium bg-orange-500 dark:text-button dark:border-button"
                                : "border hover:text-button hover:border-button text-gray-500  dark:hover:text-gray-300 hover:bg-gray-50 hover:border-b-secondary"
                            }`}
                          >
                            Employment History
                          </button>
                        </li>
                        <li className="mr-2">
                          <button
                            onClick={() => setActive("history")}
                            className={`inline-block p-4 focus:outline-none rounded-t-lg border-b-2 ${
                              active === "history"
                                ? "border-secondary text-white font-medium bg-orange-500 dark:text-button dark:border-button"
                                : "border hover:text-button hover:border-button text-gray-500  dark:hover:text-gray-300 hover:bg-gray-50 hover:border-b-secondary"
                            }`}
                          >
                            Training History
                          </button>
                        </li>
                        <li className="mr-2">
                          <button
                            onClick={() => setActive("documents")}
                            className={`inline-block p-4 focus:outline-none rounded-t-lg border-b-2 ${
                              active === "documents"
                                ? "border-secondary text-white font-medium bg-orange-500 dark:text-button dark:border-button"
                                : "border hover:text-button hover:border-button text-gray-500  dark:hover:text-gray-300 hover:bg-gray-50 hover:border-b-secondary"
                            }`}
                          >
                            Documents
                          </button>
                        </li>
                        <li className="mr-1">
                          <button
                            onClick={() => setActive("opt")}
                            className={`inline-block p-4 focus:outline-none rounded-t-lg border-b-2 ${
                              active === "opt"
                                ? "border-secondary text-white font-medium bg-orange-500 dark:text-button dark:border-button"
                                : "border hover:text-button hover:border-button text-gray-500  dark:hover:text-gray-300 hover:bg-gray-50 hover:border-b-secondary"
                            }`}
                          >
                            Opt Agreement
                          </button>
                        </li>
                        <li className="mr-1">
                          <button
                            onClick={() => setActive("consent")}
                            className={`inline-block p-4 focus:outline-none rounded-t-lg border-b-2 ${
                              active === "consent"
                                ? "border-secondary text-white font-medium bg-orange-500 dark:text-button dark:border-button"
                                : "border hover:text-button hover:border-button text-gray-500  dark:hover:text-gray-300 hover:bg-gray-50 hover:border-b-secondary"
                            }`}
                          >
                            Personal Data Consent
                          </button>
                        </li>
                        {/* <li className="mr-2">
                          <button
                            onClick={() => setActive("references")}
                            className={`inline-block p-4 focus:outline-none rounded-t-lg border-b-2 ${
                              active === "references"
                                ? "border-secondary text-white font-medium bg-orange-500 dark:text-button dark:border-button"
                                : "border-transparent hover:text-button hover:border-secondary  text-gray-500  dark:hover:text-gray-300"
                            }`}
                          >
                            References
                          </button>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="text-gray-500">{content}</div>
              </div>
            </div>
          </div>

          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
