import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  updateDisability: {},
  isLoadingupdateDisability: false,
  errorupdateDisability: "",
};

const updateDisabilitySlice = createSlice({
  name: "updateDisability",
  initialState,
  reducers: {
    updateDisabilityPending: (state) => {
      state.isLoadingupdateDisability = true;
    },
    updateDisabilitySuccess: (state, { payload }) => {
      state.isLoadingupdateDisability = false;
      state.updateDisability = payload;
      state.errorupdateDisability = "";
    },
    updateDisabilityFail: (state, { payload }) => {
      state.isLoadingupdateDisability = false;
      state.errorupdateDisability = payload;
    },
  },
});

export const { updateDisabilityPending, updateDisabilitySuccess, updateDisabilityFail } =
  updateDisabilitySlice.actions;

export default updateDisabilitySlice.reducer;
