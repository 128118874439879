import React from 'react'
import AddStaff from './AddStaff'
import { useDispatch, useSelector } from 'react-redux';
import { getStaff } from '../../pages/staff/staffActions/staffAction'
import { updateStaffStatus } from '../../pages/staff/staffActions/updateStatusAction'
import PageLoader from '../../components/PageLoader';
import Pagination from "react-js-pagination";

function Archived() {
    const dispatch = useDispatch();
    const { staff, isLoading } = useSelector(state => state.getstaff)
    const { updateStatus } = useSelector(state => state.updateStaffStatus)
    const { addStaff } = useSelector(state => state.addStaff);

    const itemsCountPerPage = 10;

    const [page, setPage] = React.useState(1);

    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
    }

    console.log('staff',staff);

    React.useEffect(() => {
        dispatch(getStaff({
            page,
            perPage: itemsCountPerPage,
            status: 'SUSPENDED'
        }));
    }, [updateStatus, addStaff,page])

    const handleChangeStatus = (staff) => {
        let values = staff.status === 'ACTIVE' ? { status: 'SUSPENDED', id: staff.id } : { status: 'ACTIVE', id: staff.id };
        dispatch(updateStaffStatus(values))
    }

    let content = isLoading ? <div className=''><PageLoader /></div> : staff && <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
                <th scope="col" className="px-6 py-3">
                    Name
                </th>
                <th scope="col" className="px-6 py-3">
                    Role
                </th>
                <th scope="col" className="px-6 py-3">
                    Phone Number
                </th>
                <th scope="col" className="px-6 py-3">
                    Email
                </th>
                <th scope="col" className="px-6 py-3">
                    Status
                </th>
                <th scope="col" className="px-6 py-3">
                    <span className="sr-only">Edit</span>
                </th>
                <th scope="col" className="px-6 py-3">
                    <span className="sr-only">Edit</span>
                </th>
            </tr>
        </thead>
        <tbody>
            {(staff && staff.data && staff.data.length > 0) ? staff.data.map((staff) => (staff.status === 'SUSPENDED' &&
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-primary dark:hover:bg-gray-600">
                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                        {staff.firstname} {staff.lastname}
                    </th>
                    <td className="px-6 py-4">
                        {staff.role.charAt(0).toUpperCase() + staff.role.slice(1).toLowerCase()}
                    </td>
                    <td className="px-6 py-4">
                        {staff.mobile}
                    </td>
                    <td className="px-6 py-4">
                        {staff.email}
                    </td>
                    <td className="px-6 py-4">
                        {staff.status.charAt(0).toUpperCase() + staff.status.slice(1).toLowerCase()}
                    </td>
                    <td className="px-6 py-4">
                        {staff.status === 'ACTIVE' ?
                            <button onClick={() => handleChangeStatus(staff)} className="font-medium text-button dark:text-blue-500 hover:underline">De-activate</button> :
                            <button onClick={() => handleChangeStatus(staff)} className="font-medium text-button dark:text-blue-500 hover:underline">Activate</button>}
                    </td>
                    <td className="px-6 py-4 text-right">
                        <a href="#" className="font-medium text-secondary dark:text-blue-500 hover:underline"><AddStaff staff={staff} /></a>
                    </td>
                </tr>
            )) : <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                <td colSpan="9" className="text-center px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap border rounded bg-red-100">No Data Found</td>
            </tr>}
        </tbody>
    </table>

    return (
        <div className='ml-72 mr-8 mt-10'>
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                {content}
            </div>
            <div>  
                 {(staff && staff.data && staff.data.length > 0 && !isLoading) &&
                    <div className="p-4 flex justify-end">
                        <Pagination
                            activePage={page}
                            itemsCountPerPage={itemsCountPerPage}
                            totalItemsCount={staff.totalItemsCount}
                            pageRangeDisplayed={1}
                            onChange={handlePageChange}
                        />
                    </div>}
                    </div>
        </div>
    )
}

export default Archived