import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import EditTimesheet from '../EditTimesheet'
import getTimesheetList from '../Actions/GetTimesheetsAction';
import Pagination from 'react-js-pagination'
import updateTimesheets from '../Actions/TimesheetsAction';
import PageLoader from "../../../components/PageLoader";


function PendingTimesheets() {
 const dispatch = useDispatch();
 const {getTimesheets, isLoadingGetTimesheets} = useSelector(state => state.getTimesheetList);
 const {updateTimesheet, isLoadingEditTimesheets} = useSelector(state => state.updatetimesheet);

 const itemsCountPerPage = 10;
 const [page, setPage] = React.useState(1)

 const handlePageChange = (pageNumber)=>{
    setPage(pageNumber);
 }

 React.useEffect(() =>{
    dispatch(getTimesheetList({
        page,
        perPage: itemsCountPerPage,
        status: 'PENDING'
    }))
 }, [page,updateTimesheet
])

 const handleDate = (date) => {
    let format = new Date(date);
    return ("0"+format.getDate()).slice(-2)+'/'+("0"+(format.getMonth()+1)).slice(-2)+'/'+format.getFullYear()+' '+format.getHours()+':'+format.getMinutes();
}

const handleChangeStatus = (values) => {
    let data = values.paymentStatus === 'PENDING' ? { paymentStatus: 'PAID', id: values.id } : { paymentStatus: 'PENDING', id: values.id };

    dispatch(updateTimesheets(data))
}

    return (
        <div className='ml-72 mr-8 mt-10'>
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                { isLoadingGetTimesheets ? <PageLoader/> : <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" className="px-6 py-3">
                                Care Worker Name
                            </th>                         
                            <th scope="col" className="px-6 py-3">
                                Client Name
                            </th>
                            <th scope="col" className="px-10 py-3">
                                Shift Start
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Shift End
                            </th>
                            <th scope="col" className="px-6 py-3">
                               Break
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Holiday Hours
                            </th>
                            <th scope="col" className="px-6 py-3">
                                Total Hours
                            </th>
                            <th scope='col' className='px-6 py-3'>Invoice Status</th>
                            <th scope='col' className='px-6 py-3'>Payment Status</th>
                            <th scope="col" className="px-6 py-3">
                                <span className="sr-only">Edit</span>
                            </th>
                            <th scope="col" className="px-6 py-3">
                                <span className="sr-only">Edit</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {getTimesheets && getTimesheets.data && getTimesheets.data.length > 0 ? getTimesheets.data.map((timesheet)=>(
                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-primary dark:hover:bg-gray-600">
                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                               {timesheet.shift.carer.firstname+' '+timesheet.shift.carer.middlename+' '+timesheet.shift.carer.lastname}
                            </th>
                         
                            <td className="px-6 py-4">
                                {timesheet.shift.client.firstname+' '+timesheet.shift.client.middlename+' '+timesheet.shift.client.lastname}
                            </td>
                            <td className="px-6 py-4">
                               {handleDate(timesheet.shift.clockIn)}
                            </td>
                            <td className="px-6 py-4">
                            {handleDate(timesheet.shift.clockOut)}
                            </td>
                            <td className="px-6 py-4">
                               {timesheet.breakHours} Hour(s)
                            </td>
                            <td className="px-6 py-4">
                               {timesheet.bankHolidayHours} Hours
                            </td>
                            <td className="px-6 py-4">
                               {timesheet.numberOfHours} Hours
                            </td>
                            <td className='px-6 py-4'>{timesheet.status}</td>
                            <td className='px-6 py-4'>{timesheet.paymentStatus}</td>
                            <td className="px-6 py-4 text-right">
                         
                         {timesheet.paymentStatus === 'PENDING' ?
                             <button onClick={() => handleChangeStatus(timesheet)} className="font-medium text-button dark:text-blue-500 hover:underline">Mark Paid</button> :
                             <button onClick={() => handleChangeStatus(timesheet)} className="font-medium text-button dark:text-blue-500 hover:underline ">Mark UnPaid</button>}
                                     </td>
                            <td className="px-6 py-4 text-right">
                                <a href="#" className="font-medium text-secondary dark:text-blue-500 hover:underline"><EditTimesheet timesheet={timesheet}/></a>
                            </td>
                            </tr>

                        ) ):
                        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                        <td colSpan="12" className="text-center px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap border rounded bg-red-100">No Data Found</td>
                    </tr>
                    }
                       

                    </tbody>
                </table>}
                <div className=''>          
            <div>
                {(getTimesheets && getTimesheets.data && getTimesheets.data.length > 0 && !isLoadingGetTimesheets) &&
                    <div className="flex justify-end">
                        <Pagination
                            activePage={page}
                            itemsCountPerPage={itemsCountPerPage}
                            totalItemsCount={getTimesheets.totalItemsCount}
                            pageRangeDisplayed={1}
                            onChange={handlePageChange}
                            itemclassName="page-item"
                            linkclassName="page-link"
                        />
                    </div>}
            </div>
        </div>
            </div>
        </div>
    )
}

export default PendingTimesheets