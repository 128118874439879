import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    addMessageReply: {},
    isLoadingAddMessageReply: false,
    errorAddMessageReply: ''
}

const addMessageReplySlice = createSlice({
    name: 'addMessageReply',
    initialState,
    reducers:{
        addMessageReplyPending: (state) => {
            state.isLoadingAddMessageReply = true;
        },
        addMessageReplySuccess: (state, {payload}) => {
            state.isLoadingAddMessageReply = false;
            state.addMessageReply = payload;
            state.errorAddMessageReply = '';
        },
        addMessageReplyFail: (state, {payload}) => {
            state.isLoadingAddMessageReply = false;
            state.errorAddMessageReply = payload;
        }
    }
})

export const {addMessageReplyPending, addMessageReplySuccess, addMessageReplyFail} = addMessageReplySlice.actions

export default addMessageReplySlice.reducer