import React from "react";
import { BsFillEyeFill } from "react-icons/bs";
import getCarerList from "../carers/CarerActions/GetCarersAction";
import { useDispatch, useSelector } from "react-redux";
// import ViewApplicantsProfile from "./ViewApplicantsProfile";
import Pagination from "react-js-pagination";
import ViewApplication from "../recruitment/ViewApplication";

const ViewCareApplication = () => {
  const [showModal, setShowModal] = React.useState(false);
  const itemsCountPerPage = 10;
  const [page, setPage] = React.useState(1);

  const handleOpen = (id) => {
    setShowModal(true);
  };

  const handleDate = (date) => {
    let format = new Date(date);
    return (
      ("0" + format.getDate()).slice(-2) +
      "/" +
      ("0" + (format.getMonth() + 1)).slice(-2) +
      "/" +
      format.getFullYear() +
      " " +
      format.getHours() +
      ":" +
      format.getMinutes()
    );
  };

  const dispatch = useDispatch();
  const { getCarers, isLoadingGetCarers } = useSelector(
    (state) => state.getCarersList
  );

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  React.useEffect(() => {
    dispatch(
      getCarerList({
        page,
        perPage: itemsCountPerPage,
        status: "PENDING",
      })
    );
  }, [page]);

  const handleName = (first, last) => {
    if (first !== null) return first + " " + last;
    return <div className="bg-orange-300 px-2 rounded-3xl">In Progress..</div>;
  };

  const carers = getCarers?.data;
  // console.log('carers',carers);
  return (
    <>
      <button
        className=" text-white bg-green py-2 border hover:bg-white hover:text-gray-700 hover:border hover:border-gray-300 hover:underline inline-flex decoration-secondary focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-3xl text-sm px-3 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        type="button"
        onClick={() => handleOpen()}
      >
        View more
        <BsFillEyeFill className="ml-1 mt-1" />
      </button>
      {showModal ? (
        <>
          <div className="justify-center items-center  overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-7xl">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-xl font-semibold text-gray-500">
                    New Applications
                  </h3>
                  <button
                    className="p-1 ml-10 bg-transparent border-0 text-button float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    ×
                  </button>
                </div>

                <div className="relative p-6 flex-auto ">
                  <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                      <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                          <th scope="col" className="px-6 py-3">
                            Name
                          </th>
                          <th scope="col" className="px-6 py-3">
                            Gender
                          </th>
                          <th scope="col" className="px-6 py-3">
                            Date of Application
                          </th>
                          <th scope="col" className="px-6 py-3">
                            Address
                          </th>
                          <th scope="col" className="px-6 py-3">
                            Mobile
                          </th>
                          <th scope="col" className="px-6 py-3">
                            Email
                          </th>
                          <th scope="col" className="px-6 py-3">
                            <span> Shift Type</span>
                          </th>
                          <th scope="col" className="px-6 py-3">
                            <span className="sr-only">Edit</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {carers && carers.length > 0 ? (
                          carers.map((carer) => (
                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-primary dark:hover:bg-gray-600">
                              <th
                                scope="row"
                                className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap"
                              >
                                {handleName(carer?.firstname, carer?.lastname)}
                              </th>
                              <td className="px-6 py-4">{carer.gender}</td>
                              <td className="px-6 py-4">
                                {handleDate(carer.createdAt)}
                              </td>
                              <td className="px-6 py-4">
                                {carer.addressLine1 ? carer.addressLine1 :"-"}
                              </td>
                              <td className="px-6 py-4">{carer.mobile ? carer.mobile :"-"}</td>
                              <td className="px-6 py-4">{carer.email}</td>
                              <td className="px-6 py-4">
                                {carer.availability && carer.availability.liveIn
                                  ? "Live In"
                                  : ""}{"-"}
                                {carer.availability && carer.availability.day
                                  ? "Day"
                                  : ""}{"-"}
                                {carer.availability && carer.availability.night
                                  ? "Night"
                                  : ""}
                              </td>
                              <td className="px-6 py-4 text-right">
                                <a
                                  href="#"
                                  className="font-medium text-secondary dark:text-blue-500 hover:underline"
                                >
                                  <ViewApplication carer={carer} />
                                </a>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                            <td
                              colSpan="9"
                              className="text-center px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap border rounded bg-red-100"
                            >
                              No Data Found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className="">
                    <div>
                      {getCarers &&
                        getCarers.data &&
                        getCarers.data.length > 0 &&
                        !isLoadingGetCarers && (
                          <div className="flex justify-end">
                            <Pagination
                              activePage={page}
                              itemsCountPerPage={itemsCountPerPage}
                              totalItemsCount={getCarers.totalItemsCount}
                              pageRangeDisplayed={1}
                              onChange={handlePageChange}
                              itemclassName="page-item"
                              linkclassName="page-link"
                            />
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default ViewCareApplication;
