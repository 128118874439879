import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  getMedicationAudit: {},
  isLoadinggetMedicationAudit: false,
  errorgetMedicationAudit: "",
};

const getMedicationAuditSlice = createSlice({
  name: "getMedicationAudit",
  initialState,
  reducers: {
    getMedicationAuditPending: (state) => {
      state.isLoadinggetMedicationAudit = true;
    },
    getMedicationAuditSuccess: (state, { payload }) => {
      state.isLoadinggetMedicationAudit = false;
      state.getMedicationAudit = payload;
      state.errorgetMedicationAudit = "";
    },
    getMedicationAuditFail: (state, { payload }) => {
      state.isLoadinggetMedicationAudit = false;
      state.errorgetMedicationAudit = payload;
    },
  },
});

export const { getMedicationAuditPending, getMedicationAuditSuccess, getMedicationAuditFail } =
  getMedicationAuditSlice.actions;

export default getMedicationAuditSlice.reducer;
