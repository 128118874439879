import React from 'react'
import { Sidebar } from '../../../layouts/auth/Sidebar'
import { TopNavigation } from '../../../layouts/auth/TopNavigation'

function AllClient() {
  return (

    <div className='bg-white h-screen'>
        
        </div>
  )
}

export default AllClient