import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    assignClient: {},
    isLoadingassignClient: false,
    errorassignClient: ''
}

const assignClientSlice = createSlice({
    name: 'assignClient',
    initialState,
    reducers:{
        assignClientPending: (state) => {
            state.isLoadingassignClient = true;
        },
        assignClientSuccess: (state, {payload}) => {
            state.isLoadingassignClient = false;
            state.assignClient = payload;
            state.errorassignClient = '';
        },
        assignClientFail: (state, {payload}) => {
            state.isLoadingassignClient = false;
            state.errorassignClient = payload;
        }
    }
})

export const {assignClientPending, assignClientSuccess, assignClientFail} = assignClientSlice.actions

export default assignClientSlice.reducer