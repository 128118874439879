import React from "react";
import { Sidebar } from "../../../layouts/auth/Sidebar";
import { TopNavigation } from "../../../layouts/auth/TopNavigation";
import { useNavigate } from "react-router-dom";
import FormikController from "../../../components/Formik/FormikController";
import SubmitButton from "../../../components/Formik/SubmitButton";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import ProgressBar from "../../clients/AddClient/ProgressBar";
import { FaJenkins } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import getCarerTier from "../../configurations/ConfigActions/GetCarerTierAction";
import addTiers from "../../clients/ClientActions/ClientTierAction";
import getCarerList from "../../carers/CarerActions/GetCarersAction";
import SimpleSwitch from "../../../components/SimpleSwitch";
import EmploymentHistoryAction from "../RegisterCarerActions/EmploymentHistoryAction";
import PageLoader from "../../../components/PageLoader";

function CarerEmployment({GetCarerInfo}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { getCarerTiers } = useSelector((state) => state.getCarerTier);
  const [inValid, setInvalid] = React.useState("");
  const [inEndValid, setEndInvalid] = React.useState("");
  const [startTime, setStartTime] = React.useState("");
  const [endTime, setEndTime] = React.useState("");
  const { EmploymentHistory,isLoadingEmploymentHistory } = useSelector(
    (state) => state.EmploymentHistory
  );
  const [start, setState] = React.useState();
  const [current, currentEmployer] = React.useState(false);

  const [date, setDate] = React.useState();
  const [isEnabled, setIsEnabled] = React.useState(false);
  const { signUp, isLoadingSignUp, errorSignUp } = useSelector(
    (state) => state.signUpCarer
  );
  const { getCarers } = useSelector((state) => state.getCarersList);
  const [indexes, setIndexes] = React.useState([]);
  const [counter, setCounter] = React.useState(0);

  const itemsCountPerPage = 20;
  const [page, setPage] = React.useState(1);

  React.useEffect(() => {}, []);

  const initialValues = {
    companyName: "",
                postCode: "",
                addressLine1: "",
                addressLine2: "",
                town: "",
                county: "",
                country: "",
                startDate: start,
                endDate: date,
                currentEmployer: false,
                natureOfBusiness: "",
                position: "",
                salary: "",
                summaryOfDuties: "",
  };

  function checkDate() {
    var selectedText = document.getElementById("datepicker").value;
    var selectedDate = new Date(selectedText);
    var now = new Date();
    if (selectedDate < now) {
      return setInvalid("Date must be in the future");
    } else {
      setInvalid("");
    }
  }

  function checkEndDate() {
    var selectedText = document.getElementById("datendpicker").value;
    var selectedDate = new Date(selectedText);
    var now = new Date();
    if (selectedDate < now) {
      return setEndInvalid("Date must be in the future");
    } else {
      setEndInvalid("");
    }
  }

  const validationSchema = Yup.object({});

  const addMarChart = () => {
    setIndexes((prevIndexes) => [...prevIndexes, counter]);
    setCounter((prevCounter) => prevCounter + 1);
  };

  const removeMarChart = (index) => () => {
    setIndexes((prevIndexes) => [
      ...prevIndexes.filter((item) => item !== index),
    ]);
    setCounter((prevCounter) => prevCounter - 1);
  };

  const clearMarCharts = () => {
    setIndexes([]);
  };

  const handleSubmit = (values) => {
   
    const test = values.employment[0]
    // test.startDate = start;  
    // test.endDate = date
    // test.currentEmployer= current
 
   const friends = [];
   friends.push(test)
 
     friends.id = signUp.user.id;
     console.log('EEE',friends);
     dispatch(EmploymentHistoryAction(friends))
     
    navigate("/carer-criminalrecord");
  };

  const handleUpdate = (values) => {
  
    const test = values.employment[0];
 
   const friends = [];
   friends.push(test)
 
    //  friends.accessToken = signUp.access_token;
   
     friends.id = GetCarerInfo.id;
   
     console.log('EEE',friends);

     dispatch(EmploymentHistoryAction(friends))
     
  };

  const Head = (props) => {
    return (
      <div className=" mb-5 text-left">
        <label className="text-xl font-medium text-gray-700">
          {props.title}
        </label>
      </div>
    );
  };


  return (
    <div className="bg-gray-50 h-screen">
      {GetCarerInfo?.employmentHistory?.length != 0  && <Sidebar />}
      {GetCarerInfo?.employmentHistory?.length != 0 && <TopNavigation title="Add Carer" />}
      <div className="bg-gray-50 ">
      <div className={GetCarerInfo?.employmentHistory?.length === 0 ? "mt-5" : "flex ml-80 mr-16 mt-5"}>
          {GetCarerInfo?.employmentHistory?.length != 0  && <div className="w-40 mr-3">
            <ProgressBar number={60} />
          </div>}
          <div className="w-full">
            <div className=" w-full p-5"></div>
            <Formik
              initialValues={initialValues}
              enableReinitialize
              validationSchema={validationSchema}
              onSubmit={(values, { resetForm }) => { GetCarerInfo?.employmentHistory?.length === 0 ? handleUpdate(values) :
                handleSubmit(values);
                resetForm();
              }}
            >
              {({ setFieldValue, values }) => (
                <Form className="">
                  {indexes.map((index) => {
                    const fieldName = `employment[${index}]`;
                    return (
                      <fieldset name={fieldName} key={fieldName}>
                        <div className="mb-5 font-bold ml-6">
                          <Head title={`Employment History ${index + 1}`} />
                        </div>
                        <div className="grid grid-cols-2 gap-2 shadow bg-white rounded p-5 ml-6">
                          {/* <div className=''><Head title='Client Tiers' /></div> */}

                          <div>
                            <FormikController
                              control="input"
                              type="text"
                              name={`${fieldName}.companyName`}
                              label="Company Name"
                            />
                          </div>

                          <div className="mt-6">
                            
                            <SimpleSwitch
                              name='currentEmployer'
                              label="Currently Employed"
                              checked={values.currentEmployer === true}
                              // onChange={(val) => currentEmployer(val)}
                            />
                          </div>
                          {/* <div className='text-left text-sm text-red-600'> {inEndValid}</div> */}
                          <div className="mt-3">
                            <FormikController
                              control="input"
                              type="date"
                              name={`${fieldName}.startDate`}
                              label="Start Date"
                              // onChange={(val) => setState(val)}
                            />
                          </div>
                          {/* <div className='text-left text-sm text-red-600'> {startTime}</div> */}
                         {!values.currentEmployer && <div className="mt-3">
                            <FormikController
                              control="input"
                              type="date"
                              name={`${fieldName}.endDate`}
                              label="End Date"
                              // onChange={(val) => setDate(val)}
                            />
                          </div>}
                          {/* <div className='text-left text-sm text-red-600'> {endTime}</div> */}

                          <div>
                            <FormikController
                              control="input"
                              type="text"
                              name={`${fieldName}.addressLine1`}
                              placeholder=""
                              label="Address line 1 *"
                            />
                          </div>
                          <div>
                            <FormikController
                              control="input"
                              type="text"
                              name={`${fieldName}.addressLine2`}
                              placeholder=""
                              label="Address line 2"
                            />
                          </div>
                          <div>
                            <FormikController
                              control="input"
                              type="text"
                              name={`${fieldName}.town`}
                              placeholder=""
                              label="Town *"
                            />
                          </div>
                          <div>
                            <FormikController
                              control="input"
                              type="text"
                              name={`${fieldName}.county`}
                              placeholder=""
                              label="County "
                            />
                          </div>

                          <div>
                            <FormikController
                              control="input"
                              type="text"
                              name={`${fieldName}.postCode`}
                              placeholder=""
                              label="Post code *"
                            />
                          </div>
                          <div>
                            <FormikController
                              control="input"
                              type="text"
                              name={`${fieldName}.country`}
                              placeholder=""
                              label="Country *"
                            />
                          </div>
                          <div>
                            <FormikController
                              control="input"
                              type="text"
                              name={`${fieldName}.natureOfBusiness`}
                              placeholder=""
                              label="Nature of Business *"
                            />
                          </div>
                          <div>
                            <FormikController
                              control="input"
                              type="text"
                              name={`${fieldName}.position`}
                              placeholder=""
                              label="Position *"
                            />
                          </div>
                          <div>
                            <FormikController
                              control="input"
                              type="text"
                              name={`${fieldName}.salary`}
                              placeholder=""
                              label="Salary *"
                            />
                          </div>
                          <div>
                            <FormikController
                              control="input"
                              type="text"
                              name={`${fieldName}.summaryOfDuties`}
                              placeholder=""
                              label="Summary of Duties *"
                            />
                          </div>
                        </div>
                        <div className="text-left mt-3 border-b mb-5 ml-6">
                          <button
                            className="border border-button text-button font-medium p-3 px-5 bg-red-100 mb-4 rounded"
                            type="button"
                            onClick={removeMarChart(index)}
                          >
                            Remove
                          </button>
                        </div>
                      </fieldset>
                    );
                  })}
                  <div className="text-left py-5 ml-6">
                    <button
                      className="border rounded p-5 mr-3 border-green text-white bg-green font-medium"
                      type="button"
                      onClick={addMarChart}
                    >
                      Add Employment History
                    </button>
                    {counter ? (
                      <button
                        className="border rounded p-5 border-gray-300 bg-gray-500 text-white font-medium"
                        type="button"
                        onClick={clearMarCharts}
                      >
                        Clear Employment History
                      </button>
                    ) : (
                      <div></div>
                    )}
                  </div>

                  <div className="mb-3 mt-5 flex flex-row-reverse ">
                    <div></div>
                    {isLoadingEmploymentHistory ? <PageLoader/> : <SubmitButton label={GetCarerInfo?.employmentHistory?.length === 0 ? "Update" :"Save & Next"} />}
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CarerEmployment;
