import { activateStatusFail, activateStatusPending, activateStatusSuccess } from "../CarerSlices/ActivateStatusSlice";
import { activateStatus } from "../../../api/carerApi";

const activateCarerStatus = (data) => async dispatch => {

    try {
        dispatch(activateStatusPending());
        const result = await activateStatus(data);
        if(result){
            return dispatch(activateStatusSuccess(result))
        }
    } catch (error) {
        dispatch(activateStatusFail(error))
    }
} 

export default activateCarerStatus