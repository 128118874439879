import {clockOutPending, clockOutSuccess, clockOutFail} from '../DashboardSlices/ClockOutSlice'
import {getClockOut} from '../../../api/carerApi'

 const ClockOutAction = (data) => async dispatch => {

    try {
        dispatch(clockOutPending());
        const result = await getClockOut(data);
        if(result){
            return dispatch(clockOutSuccess(result))
        }
    } catch (error) {
        dispatch(clockOutFail(error))
    }
} 

export default ClockOutAction