import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    getClientTiers: {},
    isLoadingGetClientTier: false,
    errorGetClientTier: ''
}

const getClientTierSlice = createSlice({
    name: 'getClientTiers',
    initialState,
    reducers:{
        getClientTierPending: (state) => {
            state.isLoadingGetClientTier = true;
        },
        getClientTierSuccess: (state, {payload}) => {
            state.isLoadingGetClientTier = false;
            state.getClientTiers = payload;
            state.errorGetClientTier = '';
        },
        getClientTierFail: (state, {payload}) => {
            state.isLoadingGetClientTier = false;
            state.errorGetClientTier = payload;
        }
    }
})

export const {getClientTierPending, getClientTierSuccess, getClientTierFail} = getClientTierSlice.actions

export default getClientTierSlice.reducer