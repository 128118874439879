import React from "react";
import Pagination from "react-js-pagination";
import { useDispatch, useSelector } from "react-redux";
import getClientMedicationRecord from "../ClientActions/ClientMedicationRecordAction";

function MedicationAdministrationRecord(e){

const dispach = useDispatch()

const { getMedicationRecords, isLoadingGetMedicationRecords} =  useSelector(state => state.medicationrecords);
const itemsCountPerPage = 20;
const [page, setPage] = React.useState(1);

React.useEffect(()=>{
    dispach(getClientMedicationRecord({
        id: e.e,
        page,
        perPage: itemsCountPerPage
    }))
},[page])

const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
}
const handleDate = (date) => {
    const day  = new Date(date);
    return day.getDate().toString().padStart(2, '0')+'/'+(day.getMonth() + 1).toString().padStart(2, '0')+'/'+day.getFullYear()
}

const handleTime = (date) => {
    const day  = new Date(date);
    return day.getHours()+':'+(day.getMinutes()).toString().padStart(2, '0')+':'+day.getSeconds().toString().padStart(2, '0')
}

    return(
        <>
<div class="overflow-x-auto h-auto">
        <div class="bg-gray-50 flex items-center justify-center font-sans overflow-hidden">
            <div class="w-full p-3">
                <div class="bg-white shadow-md rounded my-6 p-3">
                    <table class="min-w-max w-full table-auto">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
                <th scope="col" className="px-6 py-3">
                   Date
                </th>
                <th scope="col" className="px-6 py-3">
                  Time
                </th>
                <th scope="col" className="px-6 py-3">
                  Number Taken
                </th>
                <th scope="col" className="px-6 py-3">
                  Notes
                </th>
                <th scope="col" className="px-6 py-3">
                 Administered By
                </th>               
            </tr>
        </thead>
        <tbody>
            {getMedicationRecords && getMedicationRecords.data?.length > 0 ? getMedicationRecords.data.map((med) => (
         
            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-primary dark:hover:bg-gray-600">
                <th scope="row" className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                    {handleDate(med.createdAt)}
                </th>
                <th scope="row" className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                {handleTime(med.createdAt)}
                </th>
                <th scope="row" className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                    {med.numberTaken}
                </th>
                <td className="px-6 py-4">
                    {med.reasonNotTaken ? med.reasonNotTaken :"-"}
                </td>
                <td className="px-6 py-4">
                {med.carer.firstname} {med.carer.middlename} {med.carer.lastname}
                </td>              
          
            </tr>)
            ) : <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                <td colSpan="9" className="text-center px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap border rounded bg-red-100">No Data Found</td>
            </tr>}
        </tbody>
             </table>
            {(getMedicationRecords && getMedicationRecords && getMedicationRecords.data?.length > 0 && !isLoadingGetMedicationRecords) &&
                <div className="flex justify-end p-lg-2">
                    <Pagination
                        activePage={page}
                        itemsCountPerPage={itemsCountPerPage}
                        totalItemsCount={getMedicationRecords.totalItemsCount}
                        pageRangeDisplayed={1}
                        onChange={handlePageChange}
                        itemClass="page-item"
                        linkClass="page-link"
                    />
                </div>}
        

             </div>
            </div>
        </div>
    </div>
        </>
    )
}

export default MedicationAdministrationRecord