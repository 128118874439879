import React from "react";
import { Sidebar } from "../../../layouts/auth/Sidebar";
import { TopNavigation } from "../../../layouts/auth/TopNavigation";
import ProgressBar from '../../clients/AddClient/ProgressBar';
import FormikController from "../../../components/Formik/FormikController";
import SimpleSwitch from "../../../components/SimpleSwitch";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import SubmitButton from '../../../components/Formik/SubmitButton';
import EducationTrainingAction from "../RegisterCarerActions/EducationTrainingAction"; 
import PageLoader from "../../../components/PageLoader";

function CarerEducationTraining({GetCarerInfo}) {
  console.log('GetCarerInfo',GetCarerInfo);
    const navigate = useNavigate()
    const dispatch = useDispatch();

    const { signUp, isLoadingSignUp, errorSignUp } = useSelector(
      (state) => state.signUpCarer
    );

    const { educationTraining, isLoadingEducationTraining } = useSelector(
      (state) => state.EducationTraining
    );
    
    
  const GCSEOLevel = false;
  const GCSEOLevelMaths = false;
  const GCSEOLevelEnglish = false;
  const MandatoryTrainingCertification =  false;
  const MedicationTrainingCertification =  false;
  const MovingAndHandlingCertification = false;
  const PegFeedCertification = false;
  const diploma =  false;

//   const MandatoryTrainingCertificate = carer.carer.educationTraining && carer.carer.educationTraining.MandatoryTrainingCertificate ? carer.carer.educationTraining?.MandatoryTrainingCertificate.split(",") : null;
//   const MedicationTrainingCertificate = carer.carer.educationTraining && carer.carer.educationTraining.MedicationTrainingCertificate ? carer.carer.educationTraining?.MedicationTrainingCertificate.split(",") : null;
//   const MovingAndHandlingCertificate = carer.carer.educationTraining && carer.carer.educationTraining.MovingAndHandlingCertificate ? carer.carer.educationTraining?.MovingAndHandlingCertificate.split(",") : null;
//   const PegFeedCertificate = carer.carer.educationTraining && carer.carer.educationTraining.PegFeedCertificate ? carer.carer.educationTraining?.PegFeedCertificate.split(",") : null;
//   const diplomaCertificate = carer.carer.educationTraining && carer.carer.educationTraining.diplomaCertificate ? carer.carer.educationTraining?.diplomaCertificate.split(",") : null;
//   const CareCertificate = carer.carer.educationTraining && carer.carer.educationTraining.CareCertificate ? carer.carer.educationTraining?.CareCertificate.split(",") : null;

  const initialValues = {
    GCSEOLevel: false,
    GCSEOLevelNumberOfSubjects: "",
    GCSEOLevelMaths: false,
    GCSEOLevelEnglish: false,
    ALevel: false,
    diploma: false,
    diplomaName: "",
    diplomaCertificate: "",
    CareCertification: false,
    CareCertificate: "",
    MandatoryTrainingCertification: false,
    MandatoryTrainingCertificate: "",
    MedicationTrainingCertification: false,
    MedicationTrainingCertificate: "",
    MovingAndHandlingCertification: false,
    MovingAndHandlingCertificate: "",
    PegFeedCertification: false,
    PegFeedCertificate: "",
  };
  const validationSchema = Yup.object({});

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result.split(',')[1]);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }
  const handleIcon = async (e, setFieldValue, field) => {
    const file = e.target.files[0];
    const type = file.type;
      const base64 = await convertToBase64(file);
      const base64String = `data:${type},${base64}`;
      console.log('FullString: ',base64String);
      setFieldValue(field, base64String);
 
  };

  const handleSubmit = (values) => {
    console.log('val',values);
    values.id = signUp.user.id;
    // values.id = GetCarerInfo.id
    dispatch(EducationTrainingAction(values))
    navigate('/carer-employment');
  }

  const handleUpdate = (values) => {
    console.log('val',values);
    values.id = GetCarerInfo.id
    dispatch(EducationTrainingAction(values))

  }
  const Head = (props) => {
    return (
      <div className="border-b mb-5">
        <label className="text-xl font-medium text-gray-700">
          {props.title}
        </label>
      </div>
    );
  };


  return (
    <div  className="bg-gray-50 h-screen">
      {(GetCarerInfo && !GetCarerInfo?.educationTraining) ? "" : <Sidebar />}
      {(GetCarerInfo && !GetCarerInfo?.educationTraining) ? "" : <TopNavigation title="Add Carer" />}
      <div className="bg-gray-50 ">
      <div className={(GetCarerInfo && !GetCarerInfo?.educationTraining) ? "mt-5" : "flex ml-80 mr-16 mt-5"}>
          {(GetCarerInfo && !GetCarerInfo?.educationTraining) ? "" : <div className="w-40 border mt-5 p-3 rounded-lg bg-gray-50 h-screen">
            <ProgressBar number={50} />
          </div>}
    <div className="w-full p-5">
      <Formik initialValues={initialValues} validationSchema={validationSchema}
      onSubmit={(values)=> (GetCarerInfo && !GetCarerInfo?.educationTraining) ? handleUpdate(values) :handleSubmit(values)}>
        {({setFieldValue,formik, values}) => {
          return (
            <Form className="text-left">
               <div className="text-left font-semibold text-gray-600 py-1">EDUCATION & TRAINING</div>
              <div className="shadow p-5 rounded bg-white ">

                <div className="grid grid-cols-1 gap-2">

                  <SimpleSwitch
                    name="GCSEOLevel"
                    label="GCSE O Level"
                    checked={values.GCSEOLevel === true}
                  />

             {values.GCSEOLevel &&  <div className="grid grid-cols-3 gap-3">
                <FormikController
                    control="input"
                    type="text"
                    name="GCSEOLevelNumberOfSubjects"
                    // label="GCSEO Level Number Of Subjects"
                    placeholder="GCSEO Level Number Of Subjects"
                  />
                   <SimpleSwitch
                    name="GCSEOLevelMaths"
                    label="GCSE O Level Maths"
                    checked={values.GCSEOLevelMaths === true}
                  />

                  <SimpleSwitch
                    name="GCSEOLevelEnglish"
                    label="GCSE O Level English"
                    checked={values.GCSEOLevelEnglish === true}
                  />
                 </div>}

                 <SimpleSwitch
                    name="Alevel"
                    label="A Level"
                    checked={values.Alevel === true}
                  />

<SimpleSwitch
                    name="diploma"
                    label="Diploma/ NQV "
                    checked={values.diploma === true}
                  />
                  {values.diploma && <> <FormikController
                      control="input"
                      type="text"
                      name="diplomaName"
                      label="DBS/NQV Name"
                    />
                    <FormikController
                        control="input"
                        type="file"
                        name="diplomaCertificate"
                        label="Diploma Certififcate"
                        value={null}
                        onChange={(event) =>
                          handleIcon(event, setFieldValue,'diplomaCertificate')
                        }
                      />
                      </>}
                 
                  <SimpleSwitch
                    name="MandatoryTrainingCertification"
                    label="Mandatory Training Certification"
                    checked={values.MandatoryTrainingCertification === true}
                  />
                                    {values.MandatoryTrainingCertification && <> <FormikController
                        control="input"
                        type="file"
                        name="MandatoryTrainingCertificate"
                        value={null}
                        onChange={(event) =>
                          handleIcon(event, setFieldValue,'MandatoryTrainingCertificate')
                        }
                      />
                      </>}
                  {/* {MandatoryTrainingCertification === true && ( */}
                  {/* {MandatoryTrainingCertificate && (
                   <div>
                     <Head title="Mandatory Training Certification" />
                      <iframe
                        src={MandatoryTrainingCertificate}
                        frameborder="0"
                        height="550"
                        width="1000"
                      ></iframe>
                   </div>
                  
                  )} */}
                  {/* // )} */}

                  <SimpleSwitch
                    name="MedicationTrainingCertification"
                    label="Medication Training Certification"
                    checked={values.MedicationTrainingCertification === true}
                  />
                   {values.MedicationTrainingCertification && <> <FormikController
                        control="input"
                        type="file"
                        name="MedicationTrainingCertificate"
                        value={null}
                        onChange={(event) =>
                          handleIcon(event, setFieldValue,'MedicationTrainingCertificate')
                        }
                      />
                      </>}
                  {/* {MedicationTrainingCertification === true && ( */}
                  {/* {MedicationTrainingCertificate && (
                   <div>
                    <Head title="Medication Training Certification" />
                      <iframe
                        src={MedicationTrainingCertificate}
                        frameborder="0"
                        height="550"
                        width="1000"
                      ></iframe>
                   </div>
                  
                  )} */}
                

                  <SimpleSwitch
                    name="MovingAndHandlingCertification"
                    label="Moving And Handling Certification"
                    checked={values.MovingAndHandlingCertification === true}
                  />
                    {values.MovingAndHandlingCertification && <> <FormikController
                        control="input"
                        type="file"
                        name="MovingAndHandlingCertificate"
                        value={null}
                        onChange={(event) =>
                          handleIcon(event, setFieldValue,'MovingAndHandlingCertificate')
                        }
                      />
                      </>}
                  {/* {MovingAndHandlingCertification === true && ( */}

                  {/* {MovingAndHandlingCertificate && (
                   <div>
                    <Head title="Moving And Handling Certificate" />
                      <iframe
                        src={MovingAndHandlingCertificate}
                        frameborder="0"
                        height="550"
                        width="1000"
                      ></iframe>
                   </div>
                  
                  )} */}
          

                  <SimpleSwitch
                    name="PegFeedCertification"
                    label="Peg Feed Certification"
                    checked={values.PegFeedCertification === true}
                  />
                    {values.PegFeedCertification && <> <FormikController
                        control="input"
                        type="file"
                        name="PegFeedCertificate"
                        value={null}
                        onChange={(event) =>
                          handleIcon(event, setFieldValue,'PegFeedCertificate')
                        }
                      />
                      </>}
                  {/* {PegFeedCertification === true && ( */}

                  {/* {PegFeedCertificate && (
                   <div>
                      <Head title="Peg Feed Certificate" />
                      <iframe
                        src={PegFeedCertificate}
                        frameborder="0"
                        height="550"
                        width="1000"
                      ></iframe>
                   </div>
                  
                  )} */}
                
                <SimpleSwitch
                    name="EndofLife"
                    label="End of Life Certification"
                    checked={values.EndofLife === true}
                  />
                    {values.EndofLife && <> <FormikController
                        control="input"
                        type="file"
                        name="EndofLifeCertificate"
                        value={null}
                        onChange={(event) =>
                          handleIcon(event, setFieldValue,'EndofLifeCertificate')
                        }
                      />
                      </>}

                      <SimpleSwitch
                    name="CareCertification"
                    label="Care Certificate"
                    checked={values.CareCertification === true}
                  />
                    {values.CareCertification && <> <FormikController
                        control="input"
                        type="file"
                        name="CareCertificate"
                        value={null}
                        onChange={(event) =>
                          handleIcon(event, setFieldValue,'CareCertificate')
                        }
                      />
                      </>}
                
                  {/* {diploma === true && ( */}
                  {/* {carer.carer.educationTraining?.diplomaName &&<div className="uppercase"> <span className="text-gray-700">DIPLOMA NAME : </span>{carer.carer.educationTraining.diplomaName}</div>}
                  {diplomaCertificate && (
                   <div>
                        <Head title="Diploma Certificate" />
                      <iframe
                        src={diplomaCertificate}
                        frameborder="0"
                        height="550"
                        width="1000"
                      ></iframe>
                   </div>
                  
                  )} */}
                  {/* {CareCertificate && (
                   <div>
                        <Head title="Care Certificate" />
                      <iframe
                        src={CareCertificate}
                        frameborder="0"
                        height="550"
                        width="1000"
                      ></iframe>
                   </div>
                  
                  )} */}

          
                </div>
              </div>
              <div className="mb-3 mt-5 flex flex-row-reverse">
                                            <div></div>
                                            { isLoadingEducationTraining ? <PageLoader/> : <SubmitButton label={GetCarerInfo && !GetCarerInfo?.educationTraining ? "Update":'Save & Next'} />}
                                        </div>
            </Form>
          );
        }}
      </Formik>
    </div>
    </div>
    </div>
    </div>
  );
}

export default CarerEducationTraining;
