import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    activateStatus: {},
    isLoadingactivateStatus: false,
    erroractivateStatus: ''
}

const activateStatusSlice = createSlice({
    name: 'activateStatus',
    initialState, 
    reducers:{
        activateStatusPending: (state) => {
            state.isLoadingactivateStatus = true;
        },
        activateStatusSuccess: (state, {payload}) => {
            state.isLoadingactivateStatus = false;
            state.activateStatus = payload;
            state.erroractivateStatus = '';
        },
        activateStatusFail: (state, {payload}) => {
            state.isLoadingactivateStatus = false;
            state.erroractivateStatus = payload;
        }
    }
})

export const {activateStatusPending, activateStatusSuccess, activateStatusFail} = activateStatusSlice.actions

export default activateStatusSlice.reducer