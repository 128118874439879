import React from 'react'
import getSelectedClientsAction from './staffActions/getSelectedClientsAction'
import { useDispatch, useSelector } from 'react-redux';
import PageLoader from '../../components/PageLoader';
import { Sidebar } from '../../layouts/auth/Sidebar'
import { TopNavigation } from '../../layouts/auth/TopNavigation'
import EditClient from '../clients/EditClient/EditClient'
import Pagination from "react-js-pagination";

function AssignedClients() {
    const dispatch = useDispatch();
    const { getSelectedClients, isLoadinggetSelectedClients } = useSelector(state => state.getSelectedClients);

    const itemsCountPerPage = 5;

    const [page, setPage] = React.useState(1);

    const handleDate = (date) => {
        let format = new Date(date);
        return ("0"+format.getDate()).slice(-2)+'/'+("0"+(format.getMonth()+1)).slice(-2)+'/'+format.getFullYear()+' '+format.getHours()+':'+format.getMinutes();
    }

    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
      };

    React.useEffect(() => {
        dispatch(getSelectedClientsAction({
            page,
            perPage: itemsCountPerPage
        }));

    }, [page]);

    let assigned = isLoadinggetSelectedClients ? <div className=''><PageLoader /></div> : <table className="w-full border text-sm  rounded-lg text-left text-gray-500 dark:text-gray-400">
<thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
    <tr>
        <th scope="col" className="px-6 py-3">
           Client Name
        </th>
        <th scope="col" className="px-6 py-3">
            Phone Number
        </th>
        <th scope="col" className="px-6 py-3">
            Email
        </th>
        <th scope="col" className="px-6 py-3">
            Created At
        </th>
        <th scope="col" className="px-6 py-3">
            Status
        </th>
    
        <th scope="col" className="px-6 py-3">
            <span className="sr-only">Edit</span>
        </th>
    </tr>
</thead>
<tbody>
    {(getSelectedClients && getSelectedClients.data && getSelectedClients.data.length > 0) ? getSelectedClients.data.map((reg) => (
        <tr key={reg.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-primary dark:hover:bg-gray-600">
            <th scope="row" className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                {reg.client.firstname} {reg.client.lastname}
            </th>
            <td className="px-6 py-4">
                {reg.client.mobile}
            </td>
            <td className="px-6 py-4">
                {reg.client.email}
            </td>
            <td className="px-6 py-4">
                {handleDate(reg.client.createdAt)}
            </td>
            <td className="px-6 py-4">
                {reg.client.status.charAt(0).toUpperCase() + reg.client.status.slice(1).toLowerCase()}
            </td>

           
            <td className="px-6 py-4">
                <EditClient client={reg.client}/>
                </td>
        </tr>
    )) : <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
        <td colSpan="9" className="text-center px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap border rounded bg-red-100">No Data Found</td>
    </tr>}
</tbody>
</table>
  return (
    <div>
        <Sidebar /><TopNavigation title='Assigned Clients' />
        <div className='ml-72 p-1 mt-10  rounded-lg mr-8'>{assigned}
        
        <div>
                {(getSelectedClients && getSelectedClients.data && getSelectedClients.data.length > 0 && !isLoadinggetSelectedClients) &&
                    <div className="flex justify-end">
                        <Pagination
                            activePage={page}
                            itemsCountPerPage={itemsCountPerPage}
                            totalItemsCount={getSelectedClients.totalItemsCount}
                            pageRangeDisplayed={1}
                            onChange={handlePageChange}
                            itemclassName="page-item"
                            linkclassName="page-link"
                        />
                    </div>}
            </div></div>
        
        </div>
  )
}

export default AssignedClients