import React from 'react'
import Pending from './invoices/Pending';
import Processed from './invoices/Processed';



const Invoices = () => {
    const [active, setActive] = React.useState('pending');

    let content;
  
    switch (active) {
      case 'pending':
        content = <Pending />
        break;
      case 'processed':
        content = <Processed />
        break;
      default:
        break;
    }
    return (
      <div>
        <div className='bg-white h-screen'>
          <div className='flex justify-between'>
            <div>
              <ul className="flex flex-wrap -mb-px ml-80 pt-5">
                <li className="mr-2">
                  <button onClick={() => setActive('pending')} className={`inline-block py-2 px-4 focus:outline-none rounded-t-lg border-b-2 ${active === 'pending' ? "border-secondary text-white font-medium dark:text-button dark:border-button bg-secondary" : "border-transparent hover:text-button hover:border-button dark:hover:text-gray-300"}`}>Pending</button>
                </li>
                <li className="mr-2">
                  <button onClick={() => setActive('processed')} className={`inline-block py-2 px-4 focus:outline-none rounded-t-lg border-b-2 ${active === 'processed' ? "border-secondary text-white font-medium dark:text-button dark:border-button bg-secondary" : "border-transparent hover:text-button hover:border-button dark:hover:text-gray-300"}`}>Processed</button>
                </li>
              </ul>
            </div>
          </div>
          <div className="">
            {content}
          </div>
        </div>
      </div>
    )
}

export default Invoices