import {editContractsPending, editContractsSuccess, editContractsFail} from '../ClientSlices/EditContractsSlice'
import {updateContracts} from '../../../api/clientApi'

 const editContracts = (data) => async dispatch => {

    try {
        dispatch(editContractsPending());
        const result = await updateContracts(data);
        if(result){
            return dispatch(editContractsSuccess(result))
        }
    } catch (error) {
        dispatch(editContractsFail(error))
    }
} 

export default editContracts