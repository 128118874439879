import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  usersDetails: {},
  isLoadingusersDetails: false,
  errorusersDetails: "",
};

const usersDetailsSlice = createSlice({
  name: "usersDetails",
  initialState,
  reducers: {
    usersDetailsPending: (state) => {
      state.isLoadingusersDetails = true;
    },
    usersDetailsSuccess: (state, { payload }) => {
      state.isLoadingusersDetails = false;
      state.usersDetails = payload;
      state.errorusersDetails = "";
    },
    usersDetailsFail: (state, { payload }) => {
      state.isLoadingusersDetails = false;
      state.errorusersDetails = payload;
    },
  },
});

export const { usersDetailsPending, usersDetailsSuccess, usersDetailsFail } =
  usersDetailsSlice.actions;

export default usersDetailsSlice.reducer;
