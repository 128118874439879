import React from 'react'
import AddStaff from './AddStaff'
import { useDispatch, useSelector } from 'react-redux';
import { getStaff } from '../../pages/staff/staffActions/staffAction'
import { updateStaffStatus } from '../../pages/staff/staffActions/updateStatusAction'
import PageLoader from '../../components/PageLoader';
import Pagination from "react-js-pagination";
import AssignRegular from './AssignRegulator';
import getRegulatorAction from './staffActions/getRegulatorAction'
import updateRegulatorClientStatusAction from './staffActions/updateRegulatorClientStatusAction'
import getRegulatorCarersAction from './staffActions/getRegulatorCarersAction'
import updateRegulatorCarersAction from './staffActions/getRegulatorCarersAction'
import updateRegulatorCarersStatusAction from './staffActions/updateRegulatorCarersStatusAction'


function Regulators() {
    const dispatch = useDispatch();
    const { staff, isLoading } = useSelector(state => state.getstaff);
    const { getRegulatorClient, isLoadinggetRegulatorClient } = useSelector(state => state.getRegulatorClient)
    const { updateStatus } = useSelector(state => state.updateStaffStatus)
    const { addStafff } = useSelector(state => state.addStaff);
    const {updateStaff} = useSelector(state => state.updateStaff)
    const { assignRegulator} = useSelector((state) => state.assignCarer);
    const { assignClient, isLoadingassignClient, errorassignClient } = useSelector((state) => state.assignClient);
    const {updateRegulatorClientStatus,isLoadingupdateRegulatorClientStatus, errorupdateRegulatorClientStatus} = useSelector((state)=>state.updateRegulatorClientsStatus);
    const {getRegulatorCarers, isLoadinggetRegulatorCarers, errorgetRegulatorCarers} = useSelector((state)=> state.getRegulatorCarers);
    const { updateRegulatorCarers,isLoadingupdateRegulatorCarers ,errorupdateRegulatorCarers} = useSelector((state)=> state.updateRegulatorCarers);
    const { updateRegulatorCarersStatus, isLoadingupdateRegulatorCarersStatus ,errorupdateRegulatorCarersStatus} = useSelector((state)=>state.updateRegulatorCarersStatus)
    const itemsCountPerPage = 5;

    const [page, setPage] = React.useState(1);
    const [pageCarer, setPageCarer] = React.useState(1);
    const [pageClient, setPageClient] = React.useState(1);

    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
    }
    const handlePageChangeCarer = (pageNumber) => {
        setPageCarer(pageNumber);
    }

    const handlePageChangeClient = (pageNumber) => {
        setPageClient(pageNumber);
    }


    React.useEffect(() => {
        dispatch(getRegulatorAction({
            pageClient,
            perPage: itemsCountPerPage
        }));
        dispatch(getRegulatorCarersAction({
            pageCarer,
            perPage: itemsCountPerPage
        }));

        dispatch(getStaff({
            page,
            perPage: itemsCountPerPage,
            role:'REGULATOR'
        }));
    }, [updateStatus, addStafff, page, updateRegulatorClientStatus,updateStaff,assignRegulator, assignClient,pageCarer, pageClient,updateRegulatorCarersStatus])

    const handleChangeStatus = (reg) => {
        let values = reg.status === 'ACTIVE' ? { status: 'INACTIVE', id: reg.id } : { status: 'ACTIVE', id: reg.id };
        dispatch(updateRegulatorCarersStatusAction(values));
    }

    const handleChangeStatusClient = (reg) => {
        let values = reg.status === 'ACTIVE' ? { status: 'INACTIVE', id: reg.id } : { status: 'ACTIVE', id: reg.id };
        dispatch(updateRegulatorClientStatusAction(values));
    }

    const Head = (props) => {
        return (
            <div className='mt-14 mb-2 text-left border-b'><label className='text-xl font-medium text-gray-700'>{props.title}</label></div>
        )
    }

    let content = isLoadinggetRegulatorCarers ? <div className=''><PageLoader /></div> : getRegulatorCarers && <>
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
                <th scope="col" className="px-6 py-3">
                    Name
                </th>
                <th scope="col" className="px-6 py-3">
                    Role
                </th>
                <th scope="col" className="px-6 py-3">
                    Phone Number
                </th>
                <th scope="col" className="px-6 py-3">
                    Email
                </th>
                <th scope="col" className="px-6 py-3">
                    Status
                </th>
            
                <th scope="col" className="px-6 py-3">
                    Assigned Carer Worker
                </th>
                <th scope="col" className="px-6 py-3">
                    <span className="sr-only">Edit</span>
                </th>
                {/* <th scope="col" className="px-6 py-3">
                    <span className="sr-only">Edit</span>
                </th> */}
            </tr>
        </thead>
        <tbody>
            {(getRegulatorCarers && getRegulatorCarers.data && getRegulatorCarers.data.length > 0) ? getRegulatorCarers.data.map((reg) => (
                <tr key={reg.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-primary dark:hover:bg-gray-600">
                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                        {reg.regulator.firstname} {reg.regulator.lastname}
                    </th>
                    <td className="px-6 py-4">
                        {reg.regulator.role.charAt(0).toUpperCase() + reg.regulator.role.slice(1).toLowerCase()}
                    </td>
                    <td className="px-6 py-4">
                        {reg.regulator.mobile}
                    </td>
                    <td className="px-6 py-4">
                        {reg.regulator.email}
                    </td>
                    <td className="px-6 py-4">
                        {reg.status.charAt(0).toUpperCase() + reg.status.slice(1).toLowerCase()}
                    </td>
                    
                    <td className="px-6 py-4">
                    {reg.carer.firstname} {' '}{reg.carer.lastname}
                    </td>
                   
                        <td className="px-6 py-4">
                        {reg.status === 'ACTIVE' ?
                            <button onClick={() => handleChangeStatus(reg)} className="font-medium text-button dark:text-blue-500 hover:underline">De-activate</button> :
                            <button onClick={() => handleChangeStatus(reg)} className="font-medium text-button dark:text-blue-500 hover:underline">Activate</button>}
                    </td>
                    
                    {/* <td className="px-6 py-4 text-right">
                    {reg.regulator.role === 'REGULATOR' &&  <a href="#" className="font-medium text-secondary dark:text-blue-500 hover:underline"><AssignRegular reg={reg} /></a>}
                    </td> */}
                </tr>
            )) : <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                <td colSpan="9" className="text-center px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap border rounded bg-red-100">No Data Found</td>
            </tr>}
        </tbody>
       
    </table> 
    <div className='px-5 pb-5'>
                {(getRegulatorCarers && getRegulatorCarers.data && getRegulatorCarers.data.length > 0 && !isLoadinggetRegulatorCarers) &&
                    <div className="flex justify-end">
                        <Pagination
                            activePage={pageCarer}
                            itemsCountPerPage={itemsCountPerPage}
                            totalItemsCount={getRegulatorCarers.totalItemsCount}
                            pageRangeDisplayed={1}
                            onChange={handlePageChangeCarer}
                            itemClass="page-item"
                            linkClass="page-link"
                        />
                    </div>}
            </div>
    </>

let assignedClients = isLoadinggetRegulatorClient ? <div className=''><PageLoader /></div> : staff && <>
<table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
<thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
    <tr>
        <th scope="col" className="px-6 py-3">
            Name
        </th>
        <th scope="col" className="px-6 py-3">
            Role
        </th>
        <th scope="col" className="px-6 py-3">
            Phone Number
        </th>
        <th scope="col" className="px-6 py-3">
            Email
        </th>
        <th scope="col" className="px-6 py-3">
            Status
        </th>
    
        <th scope="col" className="px-6 py-3">
            Assigned Client
        </th>
        <th scope="col" className="px-6 py-3">
            <span className="sr-only">Edit</span>
        </th>
        {/* <th scope="col" className="px-6 py-3">
            <span className="sr-only">Edit</span>
        </th> */}
    </tr>
</thead>
<tbody>
    {(getRegulatorClient && getRegulatorClient.data && getRegulatorClient.data.length > 0) ? getRegulatorClient.data.map((reg) => (
        <tr key={reg.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-primary dark:hover:bg-gray-600">
            <th scope="row" className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                {reg.regulator.firstname} {reg.regulator.lastname}
            </th>
            <td className="px-6 py-4">
                {reg.regulator.role.charAt(0).toUpperCase() + reg.regulator.role.slice(1).toLowerCase()}
            </td>
            <td className="px-6 py-4">
                {reg.regulator.mobile}
            </td>
            <td className="px-6 py-4">
                {reg.regulator.email}
            </td>
            <td className="px-6 py-4">
                {reg.status.charAt(0).toUpperCase() + reg.status.slice(1).toLowerCase()}
            </td>
            
            <td className="px-6 py-4">
            {reg.client.firstname} {' '}{reg.client.lastname}
            </td>
           
                <td className="px-6 py-4">
                {reg.status === 'ACTIVE' ?
                    <button onClick={() => handleChangeStatusClient(reg)} className="font-medium text-button dark:text-blue-500 hover:underline">De-activate</button> :
                    <button onClick={() => handleChangeStatusClient(reg)} className="font-medium text-button dark:text-blue-500 hover:underline">Activate</button>}
            </td>
            
            {/* <td className="px-6 py-4 text-right">
            {reg.regulator.role === 'REGULATOR' &&  <a href="#" className="font-medium text-secondary dark:text-blue-500 hover:underline"><AssignRegular reg={reg} /></a>}
            </td> */}
        </tr>
    )) : <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
        <td colSpan="9" className="text-center px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap border rounded bg-red-100">No Data Found</td>
    </tr>}
</tbody>
</table>
 <div className='px-5 pb-5'>
 {(getRegulatorClient && getRegulatorClient.data && getRegulatorClient.data.length > 0 && !isLoadinggetRegulatorClient) &&
     <div className="flex justify-end">
         <Pagination
             activePage={pageClient}
             itemsCountPerPage={itemsCountPerPage}
             totalItemsCount={getRegulatorClient.totalItemsCount}
             pageRangeDisplayed={1}
             onChange={handlePageChangeClient}
             itemClass="page-item"
             linkClass="page-link"
         />
     </div>}
</div></>




     let unassignedRegulators = isLoading ? <div className=''><PageLoader /></div> : staff && <>
     <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
     <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
         <tr>
             <th scope="col" className="px-6 py-3">
                 Name
             </th>
             <th scope="col" className="px-6 py-3">
                 Role
             </th>
             <th scope="col" className="px-6 py-3">
                 Phone Number
             </th>
             <th scope="col" className="px-6 py-3">
                 Email
             </th>
             <th scope="col" className="px-6 py-3">
                 Status
             </th>
             {/* <th scope="col" className="px-6 py-3">
                 <span className="sr-only">Edit</span>
             </th> */}
             <th scope="col" className="px-6 py-3">
                 <span className="sr-only">Edit</span>
             </th>
             <th scope="col" className="px-6 py-3">
                 <span className="sr-only">Edit</span>
             </th>
         </tr>
     </thead>
     <tbody>
         {(staff && staff.data && staff.data.length > 0) ? staff.data.map((staff) => (staff.status === 'ACTIVE' && staff.role ==='REGULATOR' &&
             <tr key={staff.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-primary dark:hover:bg-gray-600">
                 <th scope="row" className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                     {staff.firstname} {staff.lastname}
                 </th>
                 <td className="px-6 py-4">
                     {staff.role.charAt(0).toUpperCase() + staff.role.slice(1).toLowerCase()}
                 </td>
                 <td className="px-6 py-4">
                     {staff.mobile}
                 </td>
                 <td className="px-6 py-4">
                     {staff.email}
                 </td>
                 <td className="px-6 py-4">
                     {staff.status.charAt(0).toUpperCase() + staff.status.slice(1).toLowerCase()}
                 </td>
                 {/* <td className="px-6 py-4">
                     {staff.status === 'ACTIVE' ?
                         <button onClick={() => handleChangeStatus(staff)} className="font-medium text-button dark:text-blue-500 hover:underline">De-activate</button> :
                         <button onClick={() => handleChangeStatus(staff)} className="font-medium text-button dark:text-blue-500 hover:underline">Activate</button>}
                 </td> */}
                 <td className="px-6 py-4 text-right">
                     <a href="#" className="font-medium text-secondary dark:text-blue-500 hover:underline"><AddStaff staff={staff} /></a>
                 </td>
                 <td className="px-6 py-4 text-right">
                 {<a href="#" className="font-medium text-secondary dark:text-blue-500 hover:underline"><AssignRegular staff={staff} /></a>}
                 </td>
             </tr>
         )) : <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
             <td colSpan="9" className="text-center px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap border rounded bg-red-100">No Data Found</td>
         </tr>}
     </tbody>
 </table>
  <div className='px-5 pb-5'>
  {(staff && staff.data && staff.data.length > 0 && !isLoading) &&
      <div className="flex justify-end">
          <Pagination
              activePage={page}
              itemsCountPerPage={itemsCountPerPage}
              totalItemsCount={staff.totalItemsCount}
              pageRangeDisplayed={1}
              onChange={handlePageChange}
              itemClass="page-item"
              linkClass="page-link"
          />
      </div>}
 </div></>

    return (
        <div className='ml-72 mr-8 mt-10'>
            <Head title='All Regulators'/>
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg"> 
                {unassignedRegulators}
            </div>
            <Head title='Assigned Care Workers'/>
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-2">
            
                {content}
            </div>
            <Head title='Assigned Clients'/>
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-2">
               
                {assignedClients}
            </div>
           
        </div>
    )
}

export default Regulators