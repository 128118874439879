import React from "react";
import { Sidebar } from "../../../layouts/auth/Sidebar";
import { TopNavigation } from "../../../layouts/auth/TopNavigation";
import SimpleSwitch from "../../../components/SimpleSwitch";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ProgressBar from "../../clients/AddClient/ProgressBar";
import SubmitButton from "../../../components/Formik/SubmitButton";
import FormikController from "../../../components/Formik/FormikController";
import DisabilityAction from "../RegisterCarerActions/DisabilityAction";

function CarerDisability({ carer }) {
  // const care = carer.carer;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const initialValues = {
    disability: false,
    SpecificLearningDisability: false,
    LongStandingIllness: false,
    SpectrumDisorder: false,
    DeafSeriousHearing: false,
    MentalHealthConditions: false,
    BlindSeriousVisualImpairment: false,
    CognitiveImpairment: false,
    GeneralLearningDisability: false,
    PhysicalMobilityDisability: false,
    AnyOtherDisability: false,
  };

  const { signUp, isLoadingSignUp, errorSignUp } = useSelector(
    (state) => state.signUpCarer
  );

  console.log('signUp',signUp);
  const gender = [
    { key: "YES", value: "YES" },
    { key: "NO", value: "YES" },
    { key: "DO NOT KNOW", value: "DO NOT KNOW" },
    { key: "USED TO HAVE A DISABILITY BUT NOT ANYMORE", value: "USED TO HAVE A DISABILITY BUT NOT ANYMORE" },
    { key: "PREFER NOT TO SAY", value: "PREFER NOT TO SAY" },
  ];

  const validationSchema = Yup.object({});

  const Head = (props) => {
    return (
      <div className="border-b mb-5">
        <label className="text-xl font-medium text-gray-700">
          {props.title}
        </label>
      </div>
    );
  };

  const handleSubmit = (values) => {
    values.id = signUp.user.id
    console.log("VALUEE", values);
    dispatch(DisabilityAction(values))
    navigate("/carer-health-declaration");
  };

  return (
    <div className="bg-gray-50 h-screen">
      <Sidebar />
      <TopNavigation title="Add Carer" />
      <div className="bg-gray-50">
        <div className="flex ml-80 mr-16 mt-5">
          <div className="w-40 border mt-5 p-3 rounded-lg bg-gray-50 h-screen">
            <ProgressBar number={80} />
          </div>
          <div className="w-full p-5">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values) => handleSubmit(values)}
            >
              {({ formik, values }) => {
                return (
                  <Form className="text-left">
                      <div className="text-left font-semibold text-gray-600 py-1">DISABILITY</div>
                    <div className="bg-white shadow p-5 rounded ">
    
                      <div style={{ color: "gray", fontWeight: "400" }} className="border p-2">
                        The Equality Act 2010 defines a disability as a
                        "physical or mental impairment which has a substantial
                        and long- term adverse effect on a person's ability to
                        carry out normal day-to-day activities". An effect is
                        long-term if it has lasted, or is likely to last, more
                        than 12 months.
                      </div>
                      <div style={{color: "gray", fontWeight: "400", marginTop: 20 }} className="border p-2">
                          Without knowledge of your disability, it may be
                          difficult to fully provide support for your needs and
                          make the necessary adjustments. Information disclosed
                          by the applicant regarding your disability and/or
                          additional needs will be handled with the strictest
                          confidentiality.
                        </div>
                      <div className="grid grid-cols-1 gap-2 mt-10">
                        <FormikController
                          control="select"
                          type="select"
                          name="disability"
                          label="Do you consider that you have a disability/health
                          concern which falls under the Disability
                          Discrimination Act ?"
                          options={gender}
                        />

                        <SimpleSwitch
                          name="SpecificLearningDisability"
                          label="Specific Learning Disability (e.g. Dyslexia)"
                          checked={values.SpecificLearningDisability === true}
                        />
                        {values.day && (
                          <FormikController
                            control="input"
                            type="text"
                            name="specify"
                            label="Please Specify"
                          />
                        )}
                        <SimpleSwitch
                          name="LongStandingIllness"
                          label="Long standing illness/health condition (e.g. epilepsy, asthma
                        or diabetes)"
                          checked={values.LongStandingIllness === true}
                        />
                        {values.night && (
                          <FormikController
                            control="input"
                            type="text"
                            name="specify1"
                            label="Please Specify"
                          />
                        )}
                        <SimpleSwitch
                          name="DeafSeriousHearing"
                          label="Deaf/Serious hearing impairment"
                          checked={values.DeafSeriousHearing === true}
                        />
                        {values.monday && (
                          <FormikController
                            control="input"
                            type="text"
                            name="specify2"
                            label="Please Specify"
                          />
                        )}
                        <SimpleSwitch
                          name="SpectrumDisorder"
                          label="Spectrum Disorder or from head injury"
                          checked={values.SpectrumDisorder === true}
                        />
                        {values.tuesday && (
                          <FormikController
                            control="input"
                            type="text"
                            name="specify"
                            label="Please Specify"
                          />
                        )}
                        <SimpleSwitch
                          name="MentalHealthConditions"
                          label=" Mental health conditions (including depressive illness)"
                          checked={values.MentalHealthConditions === true}
                        />
                        <SimpleSwitch
                          name="BlindSeriousVisualImpairment"
                          label="Blind/Serious visual impairment"
                          checked={values.BlindSeriousVisualImpairment === true}
                        />
                        <SimpleSwitch
                          name="CognitiveImpairment"
                          label="Cognitive Impairment (e.g. Autistic)"
                          checked={values.CognitiveImpairment === true}
                        />

                        <SimpleSwitch
                          name="GeneralLearningDisability"
                          label="General Learning Disability (e.g. Down Syndrome)"
                          checked={values.GeneralLearningDisability === true}
                        />

                        <SimpleSwitch
                          name="PhysicalMobilityDisability"
                          label=" Wheelchair user or another physical/mobility impairment"
                          checked={values.PhysicalMobilityDisability === true}
                        />

                        <SimpleSwitch
                          name="AnyOtherDisability"
                          label="Any other not mentioned"
                          checked={values.AnyOtherDisability === true}
                        />
                        {values.sunday && (
                          <FormikController
                            control="input"
                            type="text"
                            name="specify4"
                            label="Please Specify"
                          />
                        )}
                   
                      </div>
                    </div>
                    <div className="mb-3 mt-5 flex flex-row-reverse">
                      <div></div>
                      <SubmitButton label="Save & Next" />
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CarerDisability;
