import React from 'react'
import { Sidebar } from '../../layouts/auth/Sidebar'
import { TopNavigation } from '../../layouts/auth/TopNavigation'
import Archived from './Archived'
import Compliant from './Compliant'
import NonCompliant from './NonCompliant'
import AddCarer from './AddCarer/AddCarer'
import { Link } from 'react-router-dom'
import { ImUserPlus } from "react-icons/im";
import SendInvite  from './AddCarer/SendInvite'

const Carers = () => {
  const [active, setActive] = React.useState('compliant');

  let content;

  switch (active) {
    case 'compliant':
      content = <Compliant />
      break;
    case 'non-compliant':
      content = <NonCompliant />
      break;
    case 'archived':
      content = <Archived />
      break;
    default:
      break;
  }
  return (
    <div><Sidebar /><TopNavigation title='Carers' />
      <div className='bg-white h-screen'>
        <div className='flex justify-between'>
          <div>
            <ul className="flex flex-wrap -mb-px ml-80 pt-5 mt-5">
              <li className="mr-2">
                <button onClick={() => setActive('compliant')} className={`inline-block py-2 px-4 focus:outline-none rounded-t-lg border-b-2 ${active === 'compliant' ? "border-secondary text-white font-medium dark:text-button dark:border-button bg-orange-500" : "border-transparent hover:text-button hover:border-secondary dark:hover:text-gray-300"}`}>Compliant</button>
              </li>
              <li className="mr-2">
                <button onClick={() => setActive('non-compliant')} className={`inline-block py-2 px-4 focus:outline-none rounded-t-lg border-b-2 ${active === 'non-compliant' ? "border-secondary text-white font-medium dark:text-button dark:border-button bg-orange-500" : "border-transparent hover:text-button hover:border-secondary dark:hover:text-gray-300"}`}>Non Compliant</button>
              </li>
              <li className="mr-2">
                <button onClick={() => setActive('archived')} className={`inline-block py-2 px-4 focus:outline-none rounded-t-lg border-b-2 ${active === 'archived' ? "border-secondary text-white font-medium dark:text-button dark:border-button bg-orange-500" : "border-transparent hover:text-button hover:border-secondary dark:hover:text-gray-300"}`}>Archived</button>
              </li>
            </ul>
          </div>
          <div className='flex'>
         <div className='mt-10 mr-7'><Link to='/add-carer' className="bg-button hover:bg-transparent inline-flex text-white font-semibold hover:text-button py-2 px-4 border border-secondary hover:border-button rounded">
            Add Carer <ImUserPlus size={18} className='mt-1 ml-2'/>
          </Link></div>
          {/* <div className='mt-10 mr-7'><SendInvite/></div> */}
         </div>
        </div>
        <div className="">
          {content}
        </div>
      </div>
    </div>
  )
}

export default Carers