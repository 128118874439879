import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import editClientCarePlan from '../ClientActions/EditCarePlansAction'
import editPrescriptionDoc from '../ClientActions/EditPrescriptionAction'
import MarchartDetails from './MarChartDetails';
import MarchartAuditTrail from './MarChartAuditTrail';
import MedicationAdministrationRecord from './MedicationAdministrationRecord';
import AuditTrail from './AuditTrail';

function ViewClientMarchart({e,setShowModal,client}) {

    const med  = e ? e : '';

    const dispatch = useDispatch();  
    const [active, setActive] = React.useState('details');


    let content;
    switch (active) {      
        case 'details':        
          content = <MarchartDetails e={med} setShowModal={setShowModal} client={client} />
          break;
        case 'audit':
          content = <AuditTrail e={med.id} />
        break   
        case 'administration':
         content = <MedicationAdministrationRecord e={med.id} />
        break      
        default:
          break;
      }  
 

  return (
    
    <div className="relative p-6 flex-auto h-full">
    <div className='flex justify-between'>
      <div>
        <ul className="flex flex-wrap font-medium pt-5">
          <li className="mr-2">
            <button onClick={() => setActive('details')} className={`inline-block p-3 focus:outline-none rounded-t-lg border-b-2 ${active === 'details' ? "border-secondary text-white dark:text-button dark:border-button bg-button" : "border-transparent hover:text-button hover:border-button dark:hover:text-gray-300"}`}>Details</button>
          </li>
          <li className="mr-2">
            <button onClick={() => setActive('administration')} className={`inline-block p-3 focus:outline-none rounded-t-lg border-b-2 ${active === 'administration' ? "border-secondary text-white dark:text-button dark:border-button bg-button" : "border-transparent hover:text-button hover:border-button dark:hover:text-gray-300"}`}>Administration Record</button>
          </li>
          <li className="mr-2">
            <button onClick={() => setActive('audit')} className={`inline-block p-3 focus:outline-none rounded-t-lg border-b-2 ${active === 'audit' ? "border-secondary text-white dark:text-button dark:border-button bg-button" : "border-transparent hover:text-button hover:border-button dark:hover:text-gray-300"}`}>Audit Trail</button>
          </li>
         
        </ul>
      </div>
   
    </div>
    <div className="">
      {content}
    </div>
  </div>
  )
}

export default ViewClientMarchart