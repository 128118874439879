import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile } from "../../pages/dashboard/userAction";
import { ImSwitch } from "react-icons/im";
import { Link, useNavigate  } from "react-router-dom";
import { logout } from "../../api/userApi";
import SendInvite from "../../pages/carers/AddCarer/SendInvite";
import { AiOutlineCaretDown } from "react-icons/ai";




export const TopNavigation = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { myAccount } = useSelector((state) => state.updateAccount);
  const { user } = useSelector((state) => state.user);

  const rol = user ? user.role : "";

  const mail = user ? user.email : "";
  

const ProfileDropdown = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="relative inline-block text-left mt-1">
      <button onClick={toggleDropdown} className="text-gray-700 font-medium inline-flex">
      {user.firstname}{' '}{user.lastname} <AiOutlineCaretDown className="mt-1.5 ml-2"/>
      </button>
      {isOpen && (
        <div className="opacity-100  origin-top-right absolute right-0 mt-2 w-70 rounded-md shadow-lg bg-yellow-50 ring-1 ring-black ring-opacity-5 p-5 ">
           <Link to='/my-account' className="font-semibold text-button hover:text-blue-900 underline">My Profile </Link> 
           <span className="text-sm">{mail}</span>
          
          {/* {rol &&
                    rol.charAt(0).toUpperCase() + rol.slice(1).toLowerCase()}<hr className="mt-2 mb-2"/> */}<hr className="mt-2 "/>
          <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
          <button onClick={()=> handleLogout()} className="text-transparent bg-clip-text bg-gray-700  inline-flex font-medium inline-flex hover:bg-red-600 mt-2 text-sm hover:text-button">
              Sign Out  <ImSwitch size={15} className=' ml-1 mt-0.5 text-red-500'/>
          </button>
          </div>
        </div>
      )}
    </div>
  );
};



  React.useEffect(() => {
    dispatch(getUserProfile());
  }, [myAccount]);

  const handleLogout = () => {
    logout()
    navigate('/', { replace: true });
  }
  return (
    <div className="nav-bar bg-gray-50 border-b shadow-md py-7 px-8 ">
      <div className="flex justify-between">
        <h1 class="text-lg ml-64 font-medium text-gray-900 dark:text-white text-left mt-1 ">
          <span class="text-transparent bg-clip-text bg-gray-700">
            {props.title}
          </span>
        </h1>
        
        <div className="inline-flex">
        <div><SendInvite/> </div>
        <div className="ml-10 "><ProfileDropdown/></div>
        </div>
      </div>
    </div>
  );
};
