import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PageLoader from "../../components/PageLoader";
import getCarerTier from "./ConfigActions/GetCarerTierAction";
import Pagination from "react-js-pagination";

function CareWorkerTierList({ setCare }) {
  const dispatch = useDispatch();

  const { addCarerTier } = useSelector((state) => state.addCarerTier);
  const { updateCarerTier } = useSelector((state) => state.updateCarerTier);
  const { getCarerTiers, isLoadingGetCarerTier } = useSelector(
    (state) => state.getCarerTier
  );
  const itemsCountPerPage = 5;

  const [page, setPage] = React.useState(1);

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  React.useEffect(() => {
    dispatch(
      getCarerTier({
        page,
        perPage: itemsCountPerPage,
      })
    );
  }, [page, addCarerTier, updateCarerTier]);

  const handleEdit = (care) => {
    setCare(care);
  };

  let content = isLoadingGetCarerTier ? (
    <PageLoader />
  ) : (
    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
      <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
        <tr>
          <th scope="col" className="px-6 py-3">
            Name
          </th>
          <th scope="col" className="px-6 py-3">
            Type
          </th>
          <th scope="col" className="px-10 py-3">
            Rate
          </th>
          <th scope="col" className="px-6 py-3">
            <span className="sr-only">Edit</span>
          </th>
        </tr>
      </thead>
      <tbody>
        {getCarerTiers &&
        getCarerTiers.data &&
        getCarerTiers.data.length > 0 ? (
          getCarerTiers.data.map((tier) => (
            <tr
              key={tier.id}
              className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-primary dark:hover:bg-gray-600"
            >
              <th
                scope="row"
                className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap"
              >
                {tier.name}
              </th>

              <th
                scope="row"
                className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap"
              >
                {tier.type}
              </th>

              <td className="px-10 py-4">{tier.carerRate}</td>
              <td className="px-6 py-4 text-right">
                <button
                  onClick={() => handleEdit(tier)}
                  className="font-medium text-secondary dark:text-blue-500 hover:underline"
                >
                  Edit
                </button>
              </td>
            </tr>
          ))
        ) : (
          <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
            <td
              colSpan="9"
              className="text-center px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap border rounded bg-red-100"
            >
              No Data Found
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );

  return (
    <div className="p-2">
      <div className="relative overflow-x-auto border sm:rounded-lg">
        {content}
      </div>
      <div>
        {getCarerTiers &&
          getCarerTiers.data &&
          getCarerTiers.data.length > 0 &&
          !isLoadingGetCarerTier && (
            <div className="flex justify-end">
              <Pagination
                activePage={page}
                itemsCountPerPage={itemsCountPerPage}
                totalItemsCount={getCarerTiers.totalItemsCount}
                pageRangeDisplayed={1}
                onChange={handlePageChange}
                itemClass="page-item"
                linkClass="page-link"
              />
            </div>
          )}
      </div>
    </div>
  );
}

export default CareWorkerTierList;
