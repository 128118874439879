import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    clientProperty: {},
    isLoadingClientProperty: false,
    errorClientProperty: ''
}

const clientPropertySlice = createSlice({
    name: 'clientProperty',
    initialState,
    reducers:{
        clientPropertyPending: (state) => {
            state.isLoadingClientProperty = true;
        },
        clientPropertySuccess: (state, {payload}) => {
            state.isLoadingClientProperty = false;
            state.clientProperty = payload;
            state.errorClientProperty = '';
        },
        clientPropertyFail: (state, {payload}) => {
            state.isLoadingClientProperty = false;
            state.errorClientProperty = payload;
        }
    }
})

export const {clientPropertyPending, clientPropertySuccess, clientPropertyFail} = clientPropertySlice.actions

export default clientPropertySlice.reducer