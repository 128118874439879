import {clientPropertyPending, clientPropertySuccess, clientPropertyFail} from '../ClientSlices/ClientPropertySlice'
import {addClientProperty} from '../../../api/clientApi'

 const addProperty = (data) => async dispatch => {

    try {
        dispatch(clientPropertyPending());
        const result = await addClientProperty(data);
        if(result){
            return dispatch(clientPropertySuccess(result))
        }
    } catch (error) {
        dispatch(clientPropertyFail(error))
    }
} 

export default addProperty