import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import SubmitButton from "../../../components/Formik/SubmitButton";
import SimpleSwitch from "../../../components/SimpleSwitch";
import updateAvailabilityAction from "../../carers/RegisterCarerActions/updateAvailabilityAction";
import GetCarerInfoAction from '../../carers/RegisterCarerActions/GetCarerInfoAction';
import CarerAvailability from "../../carers/AddCarer/CarerAvailability"
import PageLoader from "../../../components/PageLoader";


function Availability({ carer }) {
  const dispatch = useDispatch();
  const care = carer.carer;
  const { updateAvailability,isLoadingupdateAvailability } = useSelector((state) => state.UpdateAvailability);
  const {GetCarerInfo, isLoadingGetCarerInfo} = useSelector((state)=>state.GetCarerInfoSliceReducer);
  const { CarerType, isLoadingCarerType } = useSelector(
    (state) => state.CarerTypeAvailability
  );

  const initialValues = {
    liveIn: GetCarerInfo.availability ? GetCarerInfo.availability.liveIn : false,
    day: GetCarerInfo.availability ? GetCarerInfo.availability.day : false,
    night: GetCarerInfo.availability ? GetCarerInfo.availability.night : false,
    monday: GetCarerInfo.availability ? GetCarerInfo.availability.monday : false,
    tuesday: GetCarerInfo.availability ? GetCarerInfo.availability.tuesday : false,
    wednesday: GetCarerInfo.availability ? GetCarerInfo.availability.wednesday : false,
    thursday: GetCarerInfo.availability ? GetCarerInfo.availability.thursday : false,
    friday: GetCarerInfo.availability ? GetCarerInfo.availability.friday : false,
    saturday: GetCarerInfo.availability ? GetCarerInfo.availability.saturday : false,
    sunday: GetCarerInfo.availability ? GetCarerInfo.availability.sunday : false,
  };

  React.useEffect(() => {
    dispatch(GetCarerInfoAction({id:care.id}))
  }, [updateAvailability,CarerType]);

  const handleUpdate = (values) => {
    // values.accessToken = Login.access_token;
    values.id = care?.availability?.id;
    console.log("VAL", values);
    dispatch(updateAvailabilityAction(values));
  };

  const validationSchema = Yup.object({});

  const Head = (props) => {
    return (
      <div className="border-b mb-5">
        <label className="text-xl font-medium text-gray-700">
          {props.title}
        </label>
      </div>
    );
  };

  return ( !GetCarerInfo.availability ? <CarerAvailability GetCarerInfo={GetCarerInfo}/> :
    <div className="w-full p-5">
      <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values) => handleUpdate(values)}
            >
              {({ formik, values }) => {
                return (
                  <Form className="text-left">
                    <div className="text-left font-bold text-gray-600 p-4 text-md bg-gray-100 mb-5 rounded">
                      AVAILABILITY
                    </div>
                    <div className="shadow p-5 rounded ">
                      <div className="grid grid-cols-1 gap-2">
                        <SimpleSwitch
                          name="liveIn"
                          label="Live In Carer"
                          checked={values.liveIn === true}
                        />

                        <SimpleSwitch
                          name="day"
                          label="Day Carer"
                          checked={values.day === true}
                        />

                        <SimpleSwitch
                          name="night"
                          label="Night Carer"
                          checked={values.night === true}
                        />

                        <SimpleSwitch
                          name="monday"
                          label="Monday"
                          checked={values.monday === true}
                        />

                        <SimpleSwitch
                          name="tuesday"
                          label="Tuesday"
                          checked={values.tuesday === true}
                        />
                        <SimpleSwitch
                          name="wednesday"
                          label="Wednesday"
                          checked={values.wednesday === true}
                        />
                        <SimpleSwitch
                          name="thursday"
                          label="Thursday"
                          checked={values.thursday === true}
                        />
                        <SimpleSwitch
                          name="friday"
                          label="Friday"
                          checked={values.friday === true}
                        />

                        <SimpleSwitch
                          name="saturday"
                          label="Saturday"
                          checked={values.saturday === true}
                        />

                        <SimpleSwitch
                          name="sunday"
                          label="Sunday"
                          checked={values.sunday === true}
                        />
                      </div>
                    </div>
                    <div className="mb-3 mt-5 flex flex-row-reverse">
                      <div></div>
                      {isLoadingupdateAvailability ? <PageLoader/> : <SubmitButton label="Update" />}
                    </div>
                  </Form>
                );
              }}
            </Formik>
    </div>
  );
}

export default Availability;
