import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    addedCarePlans: {},
    isLoadingAddedPlans: false,
    errorAddedPlans: ''
}

const addedCarePlansSlice = createSlice({
    name: 'addedCarePlans',
    initialState,    
    reducers:{
      
        addedCarePlansPending: (state) => {
            state.isLoadingAddedPlans = true;
        },
        addedCarePlansSuccess: (state, {payload}) => {
            state.isLoadingAddedPlans = false;
            state.addedCarePlans = payload;
            state.errorAddedPlans = '';
        },
        addedCarePlansFail: (state, {payload}) => {
            state.isLoadingAddedPlans = false;
            state.errorAddedPlans = payload;
        }
    }
})

export const {addedCarePlansPending, addedCarePlansSuccess, addedCarePlansFail} = addedCarePlansSlice.actions

export default addedCarePlansSlice.reducer