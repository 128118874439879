import React from "react";
import { Sidebar } from "../../../layouts/auth/Sidebar";
import { TopNavigation } from "../../../layouts/auth/TopNavigation";
import SimpleSwitch from "../../../components/SimpleSwitch";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import ProgressBar from "../../clients/AddClient/ProgressBar";
import SubmitButton from "../../../components/Formik/SubmitButton";
import { useDispatch, useSelector } from "react-redux";
import CriminalRecordAction from "../RegisterCarerActions/CriminalRecordAction";


function CarerCriminalRecord() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { signUp, isLoadingSignUp, errorSignUp } = useSelector(
    (state) => state.signUpCarer
  );

  const initialValues = {
    CriminalRecordDeclaration:false,
    carerId :signUp.user.id
  };

  const Head = (props) => {
    return (
      <div className="border-b mb-5 text-left">
        <label className="text-xl font-medium text-gray-700">
          {props.title}
        </label>
      </div>
    );
  };
  const validationSchema = Yup.object({});
  const handleSubmit = (values) => {
    
    
    console.log('val',values);
    dispatch(CriminalRecordAction(values))
    navigate("/carer-disability");
  };
  return (
    <div  className="bg-gray-50 h-screen">
      <Sidebar />
      <TopNavigation title="Add Carer" />
      <div className="bg-gray-50">
        <div className="flex ml-80 mr-16 mt-5">
          <div className="w-40 border mt-5 p-3 rounded-lg bg-gray-50 h-screen">
            <ProgressBar number={70} />
          </div>
          <div className="w-full p-5">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values) => handleSubmit(values)}
            >
              {({ setFieldValue, formik, values }) => {
                return (
                  <Form className="text-left">
                           <div className="text-left font-semibold text-gray-600 py-1">CRIMINAL RECORD</div>
                    <div className="shadow bg-white p-5 rounded ">

                      <div className="grid grid-cols-1 gap-2">
                        <div showsVerticalScrollIndicator={false}>
                          <div
                            style={{
                              fontWeight: "400",
                              marginBottom: 15,
                              color: "gray",
                            }}
                          >
                            The position you have applied for is exempt from the
                            Rehabilitation of Offenders Act 1974 (as amended in
                            England and Wales).
                          </div>
                          <div
                            style={{
                              fontWeight: "400",
                              marginBottom: 15,
                              borderBottomWidth: 0.4,
                              paddingBottom: 3,
                            }}
                          >
                            <div style={{ fontWeight: "800" }}>1.0 :</div> I
                            declare that to the best of my knowledge and belief
                            the information given by me in this application is
                            true, and I understand that the above information
                            forms the basis of my contract of employment. I
                            understand that if any of the information supplied
                            by me is found to be falsely declared, my contract
                            may have been fundamentally breached and my
                            employment may be terminated immediately.
                          </div>

                          <div
                            style={{
                              fontWeight: "400",
                              marginBottom: 15,
                              borderBottomWidth: 0.4,
                              paddingBottom: 3,
                            }}
                          >
                            <div style={{ fontWeight: "800" }}>2.0 :</div> I
                            understand that I may not be offered a post until a
                            satisfactory response has been received with respect
                            to my DBS Register status, and that should I
                            subsequently be offered a post, that offer will be
                            subject to receipt of two satisfactory references,
                            one of which must be from my previous employer, and
                            that confirmation of the employment will be subject
                            to a satisfactory criminal record check from the
                            DBS.
                          </div>

                          <div
                            style={{
                              fontWeight: "400",
                              marginBottom: 15,
                              borderBottomWidth: 0.4,
                              paddingBottom: 3,
                            }}
                          >
                            <div style={{ fontWeight: "800" }}>3.0 :</div> I
                            understand that until a satisfactory response is
                            received from the DBS, and my employment is
                            confirmed, I will be supervised at all times at
                            work, and will not seek or have unsupervised access
                            to vulnerable people. If the post I have applied for
                            is as a Registered Nurse, my confirmation of
                            employment will also be subject to a satisfactory
                            search of the Nursing and Midwifery Council records
                            and registers. By my signature, I authorise ELMS
                            Health Solutions Ltd to request a DBS Register check
                            and a criminal record check from the DBS, on initial
                            employment and at any time during my employment
                            thereafter. I undertake to inform my employer
                            immediately if my DBS Register status or criminal
                            status changes at any time during my employment,
                            such as by being charged with an offence (other than
                            motoring offences), the administering of a warning,
                            criminal conviction, referral to any register of
                            barred Care workers, or withdrawal of any
                            registration required by my employment status
                          </div>
                          <div className="text-left font-semibold text-gray-600 mt-16 mb-2">Please tick to say YES</div>
                          <div
                            style={{ flexDirection: "row", paddingVertical: 5 }} 
                          >
                            <SimpleSwitch
                              name="CriminalRecordDeclaration"
                              label="l declare that l have read and understood the information above"
                              checked={values.CriminalRecordDeclaration === true}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mb-3 mt-5 flex flex-row-reverse">
                      <div></div>
                      <SubmitButton label="Save & Next" />
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CarerCriminalRecord;
