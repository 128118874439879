import {HealthDeclarationPending, HealthDeclarationSuccess, HealthDeclarationFail} from '../RegisterCarerSlices/HealthDeclarationSlice'
import {HealthDeclaration} from '../../api/carerApi'

 const HealthDeclarationAction = (data) => async dispatch => {

    try {
        dispatch(HealthDeclarationPending());
        const result = await HealthDeclaration(data);
        if(result){
            return dispatch(HealthDeclarationSuccess(result))
        }
    } catch (error) {
        dispatch(HealthDeclarationFail(error))
    }
} 

export default HealthDeclarationAction