import {getCarerTierPending, getCarerTierSuccess, getCarerTierFail} from '../ConfigSlices/GetCarerTierSlice'
import {getCarerTiers} from '../../../api/clientApi'

 const getCarerTier = (data) => async dispatch => {

    try {
        dispatch(getCarerTierPending());
        const result = await getCarerTiers(data);
        if(result){
            return dispatch(getCarerTierSuccess(result))
        }
    } catch (error) {
        dispatch(getCarerTierFail(error))
    }
} 

export default getCarerTier