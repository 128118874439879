import React from "react";
import { Formik, Form, Field } from "formik";
import { FcSearch } from "react-icons/fc";
import { ChatView } from "../../dashboard/ChatView";
import ComposeMessage from "../../dashboard/ComposeMessage";
import { useDispatch, useSelector } from "react-redux";
import PageLoader from "../../../components/PageLoader";
import getMessageList from "../../dashboard/DashboardAction/GetMessagesAction";
import FormikController from "../../../components/Formik/FormikController";
import * as Yup from "yup";
import addNewMessage from "../../dashboard/DashboardAction/AddMessageAction";
import ReplyFormikController from "../../../components/Formik/ReplyFormikController";
import ReplyButton from "../../../components/Formik/ReplyButton";

const Messages = (client) => {
  const dispatch = useDispatch();
  const [length, setLength] = React.useState();
  const [sortedMessages, setSortedMessages] = React.useState([]);
  const { addMessage } = useSelector((state) => state.addMessage);
  const { getMessages, isLoadingMessages } = useSelector(
    (state) => state.getMessageList
  );
  const itemsCountPerPage = 1000;
  const page = 1;
 



  const validationSchema = Yup.object({
    reply: Yup.string().required("This field is required"),
  });
  const initialValues = {
    reply: "",
    messageType: "",
    carers: false,
    relative: false,
    clientId: "",
  };

  const handleSubmit = (values) => {
    values.clientId = client.client.id;
    values.messageType = "Admin";
    dispatch(addNewMessage(values));
  };

  const formatDate = (date) => {
    const newdate = new Date(date);
    const today = new Date();
    if (today.toDateString() === newdate.toDateString()) {
      return newdate.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
    }

    return newdate.toLocaleDateString("en-Us", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });
  };


  React.useEffect(() => {
    dispatch(
      getMessageList({
        page,
        perPage: itemsCountPerPage,
        clientId: client.client.id,
      })
    );
    const sorted =getMessages && getMessages.data && [...getMessages.data].sort(
      (a, b) => {return new Date(a.createdAt) - new Date(b.createdAt)}
    );
    setSortedMessages(sorted);
    setLength(getMessages.data ? getMessages.data.length : null);
  }, [page, addMessage]);

  return (  
    <>
     {isLoadingMessages ? <PageLoader/> : 
     <div className="w-full px-5 flex flex-col justify-between bg-slate-50 border">
        <div className="flex flex-col mt-5">
          {sortedMessages && sortedMessages?.length > 0
            ? sortedMessages.map((message) => (
                <div className="flex justify-start mb-4">
                  <div className="ml-2 py-3 px-4 bg-monday rounded-br-3xl rounded-tr-3xl rounded-tl-xl text-black">
                    <div className="text-gray-500 uppercase text-sm">
                      {message.admin
                        ? message.admin.firstname +
                          " " +
                          message.admin.lastname +
                          " (Administrator)"
                        : message.carer.firstname +
                          " " +
                          message.carer.lastname}
                    </div>
                    <div className="justify mt-2">{message.message}</div>
                  </div>
                </div>
              ))
            : ""}
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, { resetForm }) => {
            handleSubmit(values);
            resetForm();
          }}
        >
          {({ setFieldValue, values, ...rest }) => {
            return (
              <Form>
                <div className="py-5 ">
                  <ReplyFormikController
                    control="input"
                    type="text"
                    name="reply"
                  />

                  {/* <div className="">
                    <span className="font-large uppercase">Recipients</span>
                    <br />

                    <div className="border rounded p-3">
                      <label className="mb-4 p-2">
                        <Field
                          type="checkbox"
                          name="carers"
                          value={true}
                          checked={values.carers === true}
                        />
                        <span className="ml-2">Carers</span>
                      </label>
                      <label className="mb-4">
                        <Field
                          type="checkbox"
                          name="relative"
                          value={true}
                          checked={values.relative === true}
                        />
                        <span className="ml-2">Relative</span>
                      </label>
                    </div>
                  </div> */}

                  <ReplyButton label="Send Message" />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
       } 
    </>
  );
};

export default Messages;
