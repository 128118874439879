import {editKycPending, editKycSuccess, editKycFail} from '../ClientSlices/EditKycDocSlice'
import {updateKyc} from '../../../api/clientApi'

 const editKycDocument = (data) => async dispatch => {

    try {
        dispatch(editKycPending());
        const result = await updateKyc(data);
        if(result){
            return dispatch(editKycSuccess(result))
        }
    } catch (error) {
        dispatch(editKycFail(error))
    }
} 

export default editKycDocument