import React, { useState } from "react";
import FormikController from "../../components/Formik/FormikController";
import SubmitButton from "../../components/Formik/SubmitButton";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import PageLoader from "../../components/PageLoader";
import addStaff from "../../pages/staff/staffActions/addStaffAction";
import getCarerList from "../carers/CarerActions/GetCarersAction";
import fetchClients from "../clients/ClientActions/GetClientsAction";
import assignRegulatorAction from "./staffActions/assignRegulatorAction";
import assignClientAction from "./staffActions/assignClientAction";
import { MultiSelect } from "react-multi-select-component";
import updateRegulatorClientsAction from './staffActions/updateRegulatorClientsAction'

function AssignRegular({ reg,staff }) {

  const [selected, setSelected] = useState([]);
  const [selectedClients, setSelectedClients] = useState([]);
  const dispatch = useDispatch();
  const [showModal, setShowModal] = React.useState(false);
  const { getCarers } = useSelector((state) => state.getCarersList);
  const { assignRegulator, isLoadingassignRegulator, errorassignRegulator } =
    useSelector((state) => state.assignCarer);
  const { assignClient, isLoadingassignClient, errorassignClient } =
    useSelector((state) => state.assignClient);
  const { getClients, isLoadingGetClients } = useSelector(
    (state) => state.getClient
  );
  const{updateRegulatorClients, isLoadingupdateRegulatorClients,errorupdateRegulatorClients} = useSelector((state)=> state.updateRegulatorClients);

  const handleOpen = (id) => {
    setShowModal(true);
  };

  const itemsCountPerPage = 20;
  const [page, setPage] = React.useState(1);

 

  let carers =
    getCarers && getCarers.data
      ? getCarers.data.map((cxt) => {
          let obj = {
            label: cxt.firstname + " " + cxt.lastname,
            value: cxt.id,
          };
          return obj;
        })
      : [];

  let clients =
    getClients && getClients.data
      ? getClients.data.map((cxt) => {
          let obj = {
            label: cxt.firstname + " " + cxt.lastname,
            value: cxt.id,
          };
          return obj;
        })
      : [];

  const initialValues = {
    carers: false,
    clients: false,
    clientId : '',
    regulatorId : reg ? reg.regulator.id : ''

  };

  const validationSchema = Yup.object({});

  const submitCarer = () => {
    dispatch(assignRegulatorAction({selected:selected,regulatorId : staff.id}));
  };

  const submitClient = () => {
    dispatch(assignClientAction({selected:selectedClients,regulatorId : staff.id}))
    // {reg ? dispatch(updateRegulatorClientsAction(selectedClients)) : dispatch(assignClientAction({selected:selectedClients,regulatorId : staff.id}))}; 
  };


  React.useEffect(() => {
    dispatch(
      getCarerList({
        page,
        perPage: itemsCountPerPage,
        status: "COMPLIANT",
      })
    );

    dispatch(
      fetchClients({
        status: "ACTIVE",
        page,
        perPage: itemsCountPerPage,
      })
    );
  }, []);
  return (
    <>
   { reg ?  <button
        className="bg-transparent hover:bg-secondary text-button font-semibold hover:text-white py-1 px-4 border border-secondary hover:border-secoundary rounded"
        type="button"
        onClick={() => handleOpen()}
      >
       Re - Assign
      </button> :
      <button
      className="bg-transparent hover:bg-secondary text-button font-semibold hover:text-white py-1 px-4 border border-secondary hover:border-secoundary rounded"
      type="button"
      onClick={() => handleOpen()}
    >
      Assign
    </button>}

      {showModal ? (
        <>
          <div className="justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              <div className="border-0 rounded-lg shadow-lg relative w-full bg-white outline-none focus:outline-none">
                <div className="items-start justify-between p-5 flex border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-xl font-semibold text-gray-500 text-left">
                    {reg ? 'Re-Assign' : 'Assign'} <span className="text-green ">{reg ?  reg.regulator.firstname +' '+ reg.regulator.lastname : ''}</span>
                  </h3>
                  <button
                    className="p-1 ml-10  bg-transparent border-0 text-button float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    ×
                  </button>
                </div>

                <div className="relative p-6 text-gray-700">
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                      values.carers
                        ? submitCarer(values)
                        : submitClient(values);
                    }}
                  >
                    {({ values }) => (
                      <Form className="mt-2">

                        {(reg && values.clients) ? <div className="font-bold  border-b mb-5 p-3 uppercase">SELECTED CLIENT -- <span className="font-bold text-green">{reg.client ? reg.client.firstname : ''} {' '} {reg.client ? reg.client.lastname : ''}</span></div> : ''}
                        {(reg && values.carers) ? <div className="font-bold  border-b mb-5 p-3 uppercase">SELECTED CARER -- <span className="font-bold text-green">{reg.carer ? reg.carer.firstname : ''} {' '} {reg.carer ? reg.carer.lastname : ''}</span></div> : ''}
                        <div className="flex space-x-10">
                          {!values.clients && (
                            <label>
                              <Field
                                type="checkbox"
                                name="carers"
                                value={true}
                                checked={values.carers === true}
                              />
                              <span className="ml-2">Assign Care Workers</span>
                            </label>
                          )}
                          {!values.carers  && (
                            <label>
                              <Field
                                type="checkbox"
                                name="clients"
                                value={true}
                                checked={values.clients === true}
                              />
                              <span className="ml-2">Assign Clients</span>
                            </label>
                          )}
                        </div>
                        <div className="">
                          {values.carers && (
                            <div>
                              <div className="mt-5">
                                <MultiSelect
                                  options={carers}
                                  value={selected}
                                  onChange={setSelected}
                                  labelledBy="Select"
                                />
                              </div>
                              <div></div>
                              <div className=" w-1/2 mb-3 pt-1 ">
                                {isLoadingassignRegulator ? (
                                  <PageLoader />
                                ) : (
                                  <SubmitButton label="Assign" />
                                )}
                              </div>
                            </div>
                          )}

                          {values.clients && (
                            <div>
                              <div className="mt-5">
                                <MultiSelect
                                  options={clients}
                                  value={selectedClients}
                                  onChange={setSelectedClients}
                                  labelledBy="Select"
                                />
                              </div>
                              <div></div>
                              <div className="mb-3 pt-1 ">
                                {isLoadingassignClient ? (
                                  <PageLoader />
                                ) : (
                                  <SubmitButton label="Assign" />
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>

          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}

export default AssignRegular;
