import {getCarerDbsPending, getCarerDbsSuccess, getCarerDbsFail} from '../DbsSlices/GetCarerDbsSlice'
import { getCarerDbsApplications } from '../../../api/DbsApplicationApi'; 
 const getCarerDbsApplicationList = (data) => async dispatch => {

    try {
        dispatch(getCarerDbsPending());
        const result = await getCarerDbsApplications(data);
        if(result){
            return dispatch(getCarerDbsSuccess(result))
        }
    } catch (error) {
        dispatch(getCarerDbsFail(error))
    }
} 

export default getCarerDbsApplicationList