import React from 'react'
import FormikController from '../../../components/Formik/FormikController';
import SubmitButton from '../../../components/Formik/SubmitButton';
import PageLoader from '../../../components/PageLoader';
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from 'react-redux';
import addCarePlan from '../../careplans/carePlanActions/addCarePlanAction'
import UpdateCarePlan from '../../careplans/carePlanActions/updateCarePlanAction';

const AddCustomPlan = ({store,setStore}) => {
  const dispatch = useDispatch();
  const { isLoadingCarePlan, errorCarePlan } = useSelector(state => state.addCarePlan);

  const initialValues = {
    code: (store) ? store.code : '',
    title: (store )? store.title : ''
  }

  const validationSchema = Yup.object({
    code: Yup.string().required('This field is required'),
    title: Yup.string().required('This field is required'),
  })

  const handleSubmit = (values) => {
    dispatch(addCarePlan(values))

  }

  const handleUpdate = (values) =>{
    values.id = store.id
    dispatch(UpdateCarePlan(values));
    setStore(null);
  }

  const Head = (props) => {
    return (
      <div className='text-left border p-1 rounded bg-indigo-400'><label className='text-xl font-medium text-white'>{props.title}</label></div>
    )
  }
  return (
        <div className="relative p-1 flex-auto text-gray-700">
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={(values) => {
              store ? handleUpdate(values) : handleSubmit(values);
            }}
          >
            {() => (
              <Form className='mt-2'>
                {errorCarePlan && <div className="bg-red-100 border mb-5 text-center border-red-200 text-red-700 px-4 py-3 rounded relative" role="alert">
                  <span className="block sm:inline">{errorCarePlan.response.data.message}</span>
                  <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                    {/* <svg className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" /></svg> */}
                  </span>
                </div>}

                <div className='grid grid-cols-12 gap-2 mb-3'>
                  <div className='col-span-4'>
                    <FormikController
                      control='input'
                      type='text'
                      name='code'
                      label='Code'
                    />
                  </div>
                  <div className='col-span-4'>
                    <FormikController
                      control='input'
                      type='text'
                      name='title'
                      label='Title'
                    />
                  </div>
                  <div className=" col-span-3 pt-3">
                    {(isLoadingCarePlan) ? <PageLoader /> : [store ? <SubmitButton label='Edit Care Plan' />: <SubmitButton label='Add Care Plan' />]}
                  </div>
                </div>
              </Form>
            )
            }
          </Formik>
        </div>
  )
}

export default AddCustomPlan