import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import PageLoader from '../../components/PageLoader';
import { BsFillPencilFill } from "react-icons/bs";
import getCarePlann from './carePlanActions/getCarePlanAction'
import Pagination from "react-js-pagination";

function CarePlanList({setStore}) {
  const dispatch = useDispatch();
  const { addCare } = useSelector(state => state.addCarePlan);
  const {updateCarePlan} = useSelector(state => state.updateCarePlan)
  const { getCarePlan, isLoadingGetCarePlan } = useSelector(state => state.getCarePlan)

  const itemsCountPerPage = 50;
console.log('getCarePlan',getCarePlan);
  const [page, setPage] = React.useState(1);

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  }

  const handleEdit = (care) => {
    setStore(care);
}
  React.useEffect(() => {

    dispatch(getCarePlann({
      page,
      perPage: itemsCountPerPage
    }))
  }, [addCare,updateCarePlan,page]);


  return (
    <>
    <div className='grid grid-cols-5 gap-1'>
      {getCarePlan &&  getCarePlan.data && getCarePlan.data.length > 0 ? getCarePlan.data.map((care) => {
        return(<>
          <a href="#" key={care.id} className="block p-6 max-w-sm bg-primary rounded-lg border border-gray-200  hover:bg-yellow-200 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-700 dark:text-white">{care.code}</h5>
            <p className="font-medium text-gray-700 dark:text-gray-400">{care.title}</p>
            <button
                className=" text-gray-700 mt-3 bg-white border hover:bg-white hover:text-gray-700 hover:border hover:border-gray-300 hover:underline inline-flex decoration-secondary focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-1 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="button"
                onClick={() => handleEdit(care)}>
                Edit <BsFillPencilFill className='ml-1 mt-1 text-secondary' />
            </button> 
          </a>
        </>)
      }): <div className='flex'><span className='bg-red-100 p-3 rounded font-medium'> No Careplans Found</span></div>}
  
    </div>
    {(getCarePlan && getCarePlan.data && getCarePlan.data.length > 0 && !isLoadingGetCarePlan) &&
      <div className="flex justify-end">
        <Pagination
          activePage={page}
          itemsCountPerPage={itemsCountPerPage}
          totalItemsCount={getCarePlan.totalItemsCount}
          pageRangeDisplayed={1}
          onChange={handlePageChange}
          itemClass="page-item"
          linkClass="page-link"
        />
      </div>}
      </>
  )
}

export default CarePlanList