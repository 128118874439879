import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  EditResume: {},
  isLoadingEditResume: false,
  errorEditResume: "",
};

const EditResumeSlice = createSlice({
  name: "EditResume",
  initialState,
  reducers: {
    EditResumePending: (state) => {
      state.isLoadingEditResume = true;
    },
    EditResumeSuccess: (state, { payload }) => {
      state.isLoadingEditResume = false;
      state.EditResume = payload;
      state.errorEditResume = "";
    },
    EditResumeFail: (state, { payload }) => {
      state.isLoadingEditResume = false;
      state.errorEditResume = payload;
    },
  },
});

export const { EditResumePending, EditResumeSuccess, EditResumeFail } =
  EditResumeSlice.actions;

export default EditResumeSlice.reducer;
