import { getInvoices } from "../../../api/invoicesApi";
import { getInvoicesFail, getInvoicesPending, getInvoicesSuccess } from "../Slices/GetInvoicesSlice";


 const getInvoiceList = (data) => async dispatch => {

    try {
        dispatch(getInvoicesPending());
        const result = await getInvoices(data);
        if(result){
            return dispatch(getInvoicesSuccess(result))
        }
    } catch (error) {
        dispatch(getInvoicesFail(error))
    }
} 

export default getInvoiceList