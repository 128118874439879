import {
  personalDetailsPending,
  personalDetailsSuccess,
  personalDetailsFail,
} from "../RegisterCarerSlices/PersonalDetailsSlice";
import { PersonalDetails } from "../../api/carerApi";

const PersonalDetailsAction = (data) => async (dispatch) => {
    
  try {
    dispatch(personalDetailsPending());
    const result = await PersonalDetails(data);
    if (result) {
      return dispatch(personalDetailsSuccess(result));
    }
  } catch (error) {
    dispatch(personalDetailsFail(error));
  }
};

export default PersonalDetailsAction;
