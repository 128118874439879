import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  updateEducationTraining: {},
  isLoadingupdateEducationTraining: false,
  errorupdateEducationTraining: "",
};

const updateEducationTrainingSlice = createSlice({
  name: "updateEducationTraining",
  initialState,
  reducers: {
    updateEducationTrainingPending: (state) => {
      state.isLoadingupdateEducationTraining = true;
    },
    updateEducationTrainingSuccess: (state, { payload }) => {
      state.isLoadingupdateEducationTraining = false;
      state.updateEducationTraining = payload;
      state.errorupdateEducationTraining = "";
    },
    updateEducationTrainingFail: (state, { payload }) => {
      state.isLoadingupdateEducationTraining = false;
      state.errorupdateEducationTraining = payload;
    },
  },
});

export const { updateEducationTrainingPending, updateEducationTrainingSuccess, updateEducationTrainingFail } =
  updateEducationTrainingSlice.actions;

export default updateEducationTrainingSlice.reducer;
