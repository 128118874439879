import React from 'react'
import ViewApplication from './ViewApplication'
import { useDispatch, useSelector } from 'react-redux'
import Pagination from 'react-js-pagination'
import getCarerDbsApplicationList  from './DbsActions/GetDbsAction'
import PageLoader from '../../components/PageLoader'


function CareWorkerDbs() {

    const dispatch = useDispatch();
    const {getCarerDbsApplications, isLoadingGetCarers} = useSelector(state => state.getCarerDbsApplicationList);
   
    const itemsCountPerPage = 20;
    const [page, setPage] = React.useState(1);
    const handlePageChange = (pageNumber) => {
       setPage(pageNumber);
   }
    
   
   React.useEffect(() => {
       dispatch(getCarerDbsApplicationList({
           page,
           perPage: itemsCountPerPage           
       }))
   
   }, [page]);   
   const list = getCarerDbsApplications;
  
   const handleDate = (date) => {
    let format = new Date(date);
    return ("0"+format.getDate()).slice(-2)+'/'+("0"+(format.getMonth()+1)).slice(-2)+'/'+format.getFullYear()+' '+format.getHours()+':'+format.getMinutes();
}
  return (
    <div className='ml-72 mr-8 mt-10'>
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
     { isLoadingGetCarers ? <PageLoader/> : <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                    <th scope="col" className="px-6 py-3">
                        Name
                    </th>
                    <th scope="col" className="px-6 py-3">
                        Date of Application
                    </th>
                    <th scope="col" className="px-6 py-3">
                        Address
                    </th>
                    <th scope="col" className="px-6 py-3">
                        Mobile
                    </th>
                    <th scope="col" className="px-6 py-3">
                        Type
                    </th>
                    <th scope="col" className="px-6 py-3">
                        <span className="sr-only">Edit</span>
                    </th>
                </tr>
            </thead>
            <tbody>
              {list && list.data && list.data.length > 0 ?  list.data.map((dbs) =>(
                  <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-primary dark:hover:bg-gray-600">
                  <th scope="row" className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                      {dbs.user.firstname} {dbs.usermiddlename} {dbs.user.lastname}
                  </th>
                  <td className="px-6 py-4">
                     {handleDate(dbs.createdAt)}
                  </td>
                  <td className="px-6 py-4">
                    <div className=''>
                     {/* {dbs.addresses ? dbs.addresses.length > 0 ? dbs.addresses.map((address) =>(
                        <span></span>
                     )): 'No Addresses'} */}
                     </div>
                  </td>
                  <td className="px-6 py-4">
                      +44 737645227
                  </td>
                  <td className="px-6 py-4">
                      Live In
                  </td>
                  <td className="px-6 py-4 text-right">
                      <a href="#" className="font-medium text-secondary dark:text-blue-500 hover:underline"><ViewApplication /></a>
                  </td>
              </tr>
            
              )) : <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
              <td colSpan="9" className="text-center px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap border rounded bg-red-100">No Data Found</td>
          </tr>}
               
            </tbody>
        </table>}
        <div className=''>          
            <div>
                {(list && list.data && list.data.length > 0 && !isLoadingGetCarers) &&
                    <div className="flex justify-end">
                        <Pagination
                            activePage={page}
                            itemsCountPerPage={itemsCountPerPage}
                            totalItemsCount={list.totalItemsCount}
                            pageRangeDisplayed={1}
                            onChange={handlePageChange}
                            itemclassName="page-item"
                            linkclassName="page-link"
                        />
                    </div>}
            </div>
        </div>
    </div>
</div>
  )
}

export default CareWorkerDbs