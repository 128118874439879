import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  disabilityChecklist: {},
  isLoadingDisability: false,
  errorDisability: "",
};

const disabilityChecklistSlice = createSlice({
  name: "disabilityChecklist",
  initialState,
  reducers: {
    disabilityChecklistPending: (state) => {
      state.isLoadingDisability = true;
    },
    disabilityChecklistSuccess: (state, { payload }) => {
      state.isLoadingDisability = false;
      state.disabilityChecklist = payload;
      state.errorDisability = "";
    },
    disabilityChecklistFail: (state, { payload }) => {
      state.isLoadingDisability = false;
      state.errorDisability = payload;
    },
  },
});

export const { disabilityChecklistPending, disabilityChecklistSuccess, disabilityChecklistFail } = disabilityChecklistSlice.actions;

export default disabilityChecklistSlice.reducer;
