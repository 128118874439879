import {myAccountPending, myAccountSuccess, myAccountFail} from './accountSlice'
import {updateMyAccount} from '../../api/userApi'

 const myAccount = (data) => async dispatch => {

    try {
        dispatch(myAccountPending());
        const result = await updateMyAccount(data);
        if(result){
            return dispatch(myAccountSuccess(result))
        }
    } catch (error) {
        dispatch(myAccountFail(error))
    }
} 

export default myAccount