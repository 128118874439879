import React from 'react'
import { BsFillEyeFill } from "react-icons/bs";
import FormikController from '../../../components/Formik/FormikController';
import SubmitButton from '../../../components/Formik/SubmitButton';
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from 'react-redux';
import fetchClientTier from '../../configurations/ConfigActions/GetClientTierAction'
import getCarerTier from '../../configurations/ConfigActions/GetCarerTierAction';
import editClientCarer from '../../clients/ClientActions/EditClientCarersAction'
import getCarerList from '../../carers/CarerActions/GetCarersAction'
import PageLoader from '../../../components/PageLoader'

function EditTier({ e}) {
    const [showModal, setShowModal] = React.useState(false);

    const handleOpen = (id) => {
        setShowModal(true);
    };

    const dispatch = useDispatch();
    const { getCarerTiers } = useSelector(state => state.getCarerTier);
    const { getCarers } = useSelector(state => state.getCarersList);
    const {editClientCarers, isLoadingEditCarer} = useSelector(state => state.editClientCareWorkers)
    const itemsCountPerPage = 20;
    const [page, setPage] = React.useState(1);


    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
    }
    React.useEffect(() => {
        dispatch(getCarerList({
            page,
            perPage: itemsCountPerPage,
            status: 'COMPLIANT'
        }))

        dispatch(getCarerTier({
            page,
            perPage: itemsCountPerPage
        }))

    }, []);

    let carerTiers = getCarerTiers && getCarerTiers.data ? getCarerTiers.data.map(cxt => {
        let obj = {
            key: cxt.type ,
            value: cxt.id
        }
        return (obj);
    }) : [];

    
    let carers = getCarers && getCarers.data ? getCarers.data.map(cxt => {
        let obj = {
            key: cxt.firstname+" "+cxt.lastname,
            value: cxt.id
        }
        return (obj);
    }) : [];


    const shifts = [
        {
            key: 'Day Shift',
            value: 'day'
        },
        {
            key: 'Night Shift',
            value: 'night'
        },
        {
            key: 'Live-In Shift',
            value: 'livein'
        },
    ];

    const initialValues = {

        careWorkerId: e ? e.careWorkerId : "",
        careWorkerTierId: e ? e.careWorkerTierId : "",
        shiftType: e ? e.shiftType : "",
        shiftStartDate: e ? e.shiftStartDate : "",
        shiftEndDate: e ? e.shiftEndDate : "",
        shiftStartTime: e ? e.shiftStartTime : "",
        shiftEndTime: e ? e.shiftEndTime : "",

    }

    const validationSchema = Yup.object({

    })


    const handleSubmit = (values) => {
        values.id = e.id
        dispatch(editClientCarer(values));
        setShowModal(false)
    }

    const Head = (props) => {
        return (
            <div className='border-b mb-5 text-left'><label className='text-xl font-medium text-gray-700'>{props.title}</label></div>
        )
    }
  return (
    <>
    <button
        className=" text-white bg-button border hover:bg-white hover:text-gray-700 hover:border hover:border-gray-300 hover:underline inline-flex decoration-secondary focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-1 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="button"
        onClick={() => handleOpen()}>
       View <BsFillEyeFill className='ml-1 mt-1'/>
    </button>
    {showModal ? (
        <>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-auto my-6 mx-auto max-w-3xl">
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">

                    <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                        <h3 className="text-xl font-semibold text-gray-500">
                           {e.user.email}
                        </h3>
                        <button className="p-1 ml-10 bg-transparent border-0 text-button float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                onClick={() => setShowModal(false)} >
                                ×
                        </button>
                        </div>
                       
                        <div className="relative p-6 flex-auto">
                        <div className=' w-full p-5'>

<Formik
    initialValues={initialValues}
    enableReinitialize
    validationSchema={validationSchema}
    onSubmit={(values) => {
        handleSubmit(values);
    }}
>
    {() => (
        <Form className=' w-full'>
<div className=''><Head title='Task Plans' /></div>
            <div className='grid grid-cols-3 gap-2 border rounded p-5 text-gray-500'>
                
                <div className="">
                    <FormikController
                        control='select'
                        type='select'
                        name='shiftType'
                        label='Select Shift'
                        options={shifts}
                    />
                </div>
                <div>
                    <FormikController
                        control='input'
                        type='date'
                        name='shiftStartDate'
                        label='Shift Start Date'
                    />
                </div>
                <div>
                    <FormikController
                        control='input'
                        type='date'
                        name='shiftEndDate'
                        label='Shift End Date'
                    />
                </div>
                <div>
                    <FormikController
                        control='input'
                        type='time'
                        name='shiftStartTime'
                        label='Shift Start Time'
                    />
                </div>

                <div>
                    <FormikController
                        control='input'
                        type='time'
                        name='shiftEndTime'
                        label='Shift End Time'
                    />
                </div>

                <div>
                    <FormikController
                        control='select'
                        type='select'
                        name='careWorkerTierId'
                        label='Care Worker Tier Rate*'
                        options={carerTiers}
                    />
                </div>
                <div className='text-left'>
                    <FormikController
                        control='select'
                        type='select'
                        name='careWorkerId'
                        label='Select Care Worker*'
                        options={carers}
                    />
                </div>
                
            </div>
            { isLoadingEditCarer ? <PageLoader /> : <div className="">
                    <SubmitButton label='Update' />
                </div>}
        </Form>
    )
    }
</Formik></div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
    ) : null}
</>
  )
}

export default EditTier