import {GetReligionPending, GetReligionSuccess, GetReligionFail} from '../RegisterCarerSlices/GetReligionSlice'
import {GetReligion} from '../../api/carerApi'

 const GetReligionAction = (data) => async dispatch => {

    try {
        dispatch(GetReligionPending());
        const result = await GetReligion(data);
        if(result){
            return dispatch(GetReligionSuccess(result))
        }
    } catch (error) {
        dispatch(GetReligionFail(error))
    }
} 

export default GetReligionAction