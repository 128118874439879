import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    updateComplianceStatus: {},
    isLoadingEditComplianceStatus: false,
    errorEditComplianceStatus: ''
}

const UpdateComplianceSlice = createSlice({
    name: 'updateComplianceStatus',
    initialState,
    reducers:{
       editComplianceStatusPending: (state) => {
            state.isLoadingEditComplianceStatus = true;
        },
        editComplianceStatusSuccess: (state, {payload}) => {
            state.isLoadingEditComplianceStatus = false;
            state.updateComplianceStatus = payload;
            state.errorEditComplianceStatus = '';
        },
        editComplianceStatusFail: (state, {payload}) => {
            state.isLoadingEditComplianceStatus = false;
            state.errorEditComplianceStatus = payload;
        }
    }
})

export const {editComplianceStatusPending, editComplianceStatusSuccess, editComplianceStatusFail} = UpdateComplianceSlice.actions

export default UpdateComplianceSlice.reducer