import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  updateBackground: {},
  isLoadingupdateBackground: false,
  errorupdateBackground: "",
};

const updateBackgroundSlice = createSlice({
  name: "updateBackground",
  initialState,
  reducers: {
    updateBackgroundPending: (state) => {
      state.isLoadingupdateBackground = true;
    },
    updateBackgroundSuccess: (state, { payload }) => {
      state.isLoadingupdateBackground = false;
      state.updateBackground = payload;
      state.errorupdateBackground = "";
    },
    updateBackgroundFail: (state, { payload }) => {
      state.isLoadingupdateBackground = false;
      state.errorupdateBackground = payload;
    },
  },
});

export const { updateBackgroundPending, updateBackgroundSuccess, updateBackgroundFail } =
  updateBackgroundSlice.actions;

export default updateBackgroundSlice.reducer;
