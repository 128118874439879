import React, { useState } from "react";
import FormikController from "../../../components/Formik/FormikController";
import SubmitButton from "../../../components/Formik/SubmitButton";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import SendCarerInviteAction from "../RegisterCarerActions/SendCarerInviteAction";
import { FaTelegramPlane } from "react-icons/fa";
import PageLoader from "../../../components/PageLoader";

function SendInvite({ staff }) {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false); // Add state for success message

  const { sendInvite, isLoadingsendInvite, errorsendInvite } = useSelector(
    (state) => state.SendCarerInviteSlice
  );

  const handleOpen = (id) => {
    setShowModal(true);
  };

  const initialValues = {
    name: "",
    email: "",
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("This field is required"),
    email: Yup.string()
      .email("Enter valid email address")
      .required("This field is required"),
  });

  const handleSubmit = (values) => {
    dispatch(SendCarerInviteAction(values));
    setShowSuccess(true); // Show success message after submitting
    setTimeout(() => setShowSuccess(false), 3000); // Hide success message after 3 seconds
    // setShowModal(false);
  };

  return (
    <>
      <button
        className="bg-blue-500 border hover:bg-white text-white font-medium hover:text-button inline-flex py-1 px-4 rounded shadow shadow-blue-200"
        type="button"
        onClick={() => handleOpen()}
      >
        Invite Care Worker <FaTelegramPlane size={20} className="mt-1 ml-2" />
      </button>

      {showModal ? (
        <>
          <div className="justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-md">
              <div className="border-0 rounded-lg shadow-lg relative w-full bg-white outline-none focus:outline-none">
                <div className="items-start justify-between p-5 flex border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-xl font-semibold text-gray-500 text-left">
                    Invite Candidate
                  </h3>
                  <button
                    className="p-1 ml-10  bg-transparent border-0 text-button float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    ×
                  </button>
                </div>
                {showSuccess && ( // Render success message if showSuccess is true
                  <div
                    className="bg-green text-center border-green text-white py-3 ml-5 mr-5 mt-5 rounded relative"
                    role="alert"
                  >
                    <span className="block sm:inline">Successful</span>
                    <span className=" top-0 bottom-0 right-0 px-4 py-3">
                      {/* <svg className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" /></svg> */}
                    </span>
                  </div>
                )}
                <div className="relative p-6 text-gray-700">
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values, { resetForm }) => {
                      handleSubmit(values);
                      resetForm();
                    }}
                  >
                    {({ values }) => (
                      <Form className="">
                        <div className="grid grid-cols-1 gap-2">
                          <div className="">
                            <FormikController
                              control="input"
                              type="text"
                              name="name"
                              label="Name"
                            />
                          </div>

                          <div>
                            <FormikController
                              control="input"
                              type="email"
                              name="email"
                              label="Email address"
                            />
                          </div>

                          <div></div>
                          <div className="mb-3 pt-1 ">
                            {isLoadingsendInvite ? (
                              <PageLoader />
                            ) : (
                              <SubmitButton label="Send Invite" />
                            )}
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>

          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}

export default SendInvite;
