import {getRegulatorCarersPending, getRegulatorCarersSuccess, getRegulatorCarersFail} from '../staffSlices/getRegulatorCarersSlice'
import {getRegulatorCarers} from '../../../api/staffApi'

 const getRegulatorCarersAction = (data) => async dispatch => {

    try {
        dispatch(getRegulatorCarersPending());
        const result = await getRegulatorCarers(data);
        if(result){
            return dispatch(getRegulatorCarersSuccess(result))
        }
    } catch (error) {
        dispatch(getRegulatorCarersFail(error))
    }
}

export default getRegulatorCarersAction