import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  updateHealthDeclaration: {},
  isLoadingupdateHealthDeclaration: false,
  errorupdateHealthDeclaration: "",
};

const updateHealthDeclarationSlice = createSlice({
  name: "updateHealthDeclaration",
  initialState,
  reducers: {
    updateHealthDeclarationPending: (state) => {
      state.isLoadingupdateHealthDeclaration = true;
    },
    updateHealthDeclarationSuccess: (state, { payload }) => {
      state.isLoadingupdateHealthDeclaration = false;
      state.updateHealthDeclaration = payload;
      state.errorupdateHealthDeclaration = "";
    },
    updateHealthDeclarationFail: (state, { payload }) => {
      state.isLoadingupdateHealthDeclaration = false;
      state.errorupdateHealthDeclaration = payload;
    },
  },
});

export const { updateHealthDeclarationPending, updateHealthDeclarationSuccess, updateHealthDeclarationFail } =
  updateHealthDeclarationSlice.actions;

export default updateHealthDeclarationSlice.reducer;
