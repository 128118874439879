import React from "react";
import { Sidebar } from "../../../layouts/auth/Sidebar";
import { TopNavigation } from "../../../layouts/auth/TopNavigation";
import { useNavigate } from "react-router-dom";
import FormikController from "../../../components/Formik/FormikController";
import SubmitButton from "../../../components/Formik/SubmitButton";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import ProgressBar from "./ProgressBar";
import { FaJenkins } from "react-icons/fa";
import Postcoder from "react-address-lookup";
import ReferralContract from "./ReferralContract";
import addClient from "../ClientActions/PersonalDetailsAction";
import { useDispatch, useSelector } from "react-redux";
import PageLoader from "../../../components/PageLoader";
import { useAccordion } from "@material-tailwind/react";

const AddClient = () => {
  const [store, setStore] = React.useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const { addClientPersonalDetails, isLoadingAddClient, errorAddClient } =
    useSelector((state) => state.addClientPersonal);
  React.useEffect(() => {}, [addClientPersonalDetails]);

  const title = [
    {
      key: "Mr.",
      value: "Mr",
    },
    {
      key: "Mrs.",
      value: "Mrs",
    },
    {
      key: "Miss",
      value: "Miss",
    },
    {
      key: "Ms",
      value: "Ms",
    },
    // {
    //   key: "Other",
    //   value: "Other",
    // },
  ];

  const religon = [
    {
      key: "Christianity",
      value: "Christianity",
    },
    {
      key: "Islamic",
      value: "Islam",
    },
    {
      key: "Prefer not say",
      value: "Prefer Not To Say",
    },
  ];

  const initialValues = {
    title: "",
    firstname: "",
    middlename: "",
    lastname: "",
    preference: "",
    dob: "",
    email: "",
    mobile: "",
    landline: "",
    languages: "",
    religion: "",
    postcode: "",
    addressLine1: "",
    addressLine2: "",
    county: "",
    town: "",
    clientID: "",
    nhsNumber: "",
    diagnosisDetails: "",
    historyBackground: "",
    likeDislikes: "",
    mobility: "",
    whoAmI: "",
    knownAllergies: "",
    painExpression: "",
    safetyIssues: "",
    Diabetes: false,
    Epilepsy: false,
    Asthma: false,
    SpinaBifina: false,
    OtherMedical: false,
    otherDetails: "",
    dietDiebetes: false,
    vegetarian: false,
    vegan: false,
    specialDiet: false,
    specialDietDetails: "",
    smoker: false,
    cat: false,
    catName: "",
    catActivities: "",
    dog: false,
    dogName: "",
    dogActivities: "",
    eatingDrinkingNeeds: "",
    specialistEquipment: "",
  };
  const validationSchema = Yup.object({
    // firstname: Yup.string().matches(/^[A-Za-z ]*$/, 'Please enter valid name').max(40).required('This field is required'),
    // lastname: Yup.string()
    // .matches(/^[A-Za-z ]*$/, 'Please enter valid name')
    // .max(40).required('This field is required'),
    // mobile: Yup.string().matches(phoneRegExp, 'Phone number is not valid').required('This field is required'),
   });

  const handleSubmit = (values) => {
    dispatch(addClient(values));
    navigate("/referral-contracts");
  };

  const Head = (props) => {
    return (
      <div className="border-b mb-5">
        <label className="text-xl font-medium text-gray-700">
          {props.title}
        </label>
      </div>
    );
  };

  return (
    <div>
      <Sidebar />
      <TopNavigation title="Add Client" />
      <div className="bg-white h-screen">
        <div className="flex ml-80 mr-16 mt-5">
          <div className="w-40 border mt-5 p-3 rounded-lg bg-gray-50">
            <ProgressBar number={10} />
          </div>
          <div className="w-full p-5">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values) => handleSubmit(values)}
            >
              {({ formik, values }) => {
                const logAddress = (addr) => {
                  formik.setFieldValue("addressLine1", addr.addressline1);
                  formik.setFieldValue("addressLine2", addr.addressline2);
                  formik.setFieldValue("county", addr.county);
                  formik.setFieldValue("town", addr.posttown);
                  formik.setFieldValue("postcode", addr.postcode);
                };
                return (
                  <Form className="text-left">
                    <nav
                      className="flex py-3 px-5 mb-2 text-gray-700 bg-gray-50 rounded-lg border border-gray-200 dark:bg-gray-800 dark:border-gray-700"
                      aria-label="Breadcrumb"
                    >
                      <ol className="inline-flex items-center space-x-1 md:space-x-3">
                        <li className="inline-flex items-center">
                          <a
                            href="#"
                            className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white"
                          >
                            <FaJenkins className="mr-2 w-4 h-4 font-bold" />{" "}
                            Peronal Details
                            <svg
                              className="w-6 h-6 text-gray-400"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                clip-rule="evenodd"
                              ></path>
                            </svg>
                          </a>
                        </li>
                      </ol>
                    </nav>

                    <div className="rounded">
                      <div className="mt-5">
                        <Head title="Peronal Details" />
                      </div>
                      <div className="grid grid-cols-2 gap-2">
                        <FormikController
                          control="select"
                          type="select"
                          name="title"
                          label="Title*"
                          options={title}
                        />

                        <FormikController
                          control="input"
                          type="text"
                          name="firstname"
                          label="First name*"
                        />
                        <FormikController
                          control="input"
                          type="text"
                          name="middlename"
                          label="Middle name"
                          placeholder="Optional"
                        />

                        <FormikController
                          control="input"
                          type="text"
                          name="lastname"
                          label="Last name*"
                        />

                        <FormikController
                          control="input"
                          type="text"
                          name="preference"
                          label="Prefer to be called"
                          placeholder="Optional"
                        />
                        <FormikController
                          control="input"
                          type="date"
                          name="dob"
                          label="Date of Birth*"
                        />
                        <FormikController
                          control="input"
                          type="text"
                          name="clientID"
                          label="Client ID"
                     
                        />
                        <FormikController
                          control="input"
                          type="text"
                          name="nhsNumber"
                          label="NHS Number"
                          placeholder="Optional"
                        />

                        <FormikController
                          control="input"
                          type="text"
                          name="languages"
                          label="Languages Spoken*"
                          placeholder="eg. English, French, Brittish"
                        />

                        <FormikController
                          control="select"
                          type="select"
                          name="religion"
                          label="Religion*"
                          options={religon}
                        />
                      </div>
                      <div className="mt-5">
                        <Head title="Contact Details" />
                      </div>
                      <div className="grid grid-cols-2 gap-2">
                        <FormikController
                          control="input"
                          type="text"
                          name="mobile"
                          label="Mobile number"
                        />
                        <FormikController
                          control="input"
                          type="text"
                          name="landline"
                          label="Landline number"
                        />
                        <FormikController
                          control="input"
                          type="email"
                          name="email"
                          label="Email address*"
                        />
                      </div>
                      <div className="mt-2">
                        <label className="font-medium ml-2">
                          Search post code
                        </label>
                        <Postcoder
                          apiKey={process.env.REACT_APP_POSTCODER}
                          addressSelectedCallback={logAddress}
                        />
                        <p className="text-sm text-gray-500 ml-2">
                          Searches and allow you to select your address from the
                          dropdown
                        </p>
                      </div>
                      <div className="grid grid-cols-3 gap-2 mt-5">
                        <div>
                          <FormikController
                            control="input"
                            type="text"
                            name="addressLine1"
                            placeholder=""
                            label="Address line 1 *"
                          />
                        </div>
                        <div>
                          <FormikController
                            control="input"
                            type="text"
                            name="addressLine2"
                            placeholder=""
                            label="Address line 2"
                          />
                        </div>
                        <div>
                          <FormikController
                            control="input"
                            type="text"
                            name="town"
                            placeholder=""
                            label="Town *"
                          />
                        </div>
                        <div>
                          <FormikController
                            control="input"
                            type="text"
                            name="county"
                            placeholder=""
                            label="County *"
                          />
                        </div>
                        <div>
                          <FormikController
                            control="input"
                            type="text"
                            name="postcode"
                            placeholder=""
                            label="Post code *"
                          />
                        </div>
                      </div>
                      <div className="mt-5">
                        <Head title="Diagnosis Details" />
                      </div>
                      <div className="grid grid-cols-3 gap-2 ">
                  <FormikController
                    control="textArea"
                    type="text"
                    name="diagnosisDetails"
                    label="Diagnosis Details"
                  />
                  <FormikController
                    control="textArea"
                    type="text"
                    name="historyBackground"
                    label="History & Background"
                  />
                  <FormikController
                    control="textArea"
                    type="text"
                    name="likeDislikes"
                    label="Likes & Dislikes"
                  />

                  <FormikController
                    control="input"
                    type="text"
                    name="mobility"
                    label="Mobility"
                  />
                  <FormikController
                    control="input"
                    type="text"
                    name="whoAmI"
                    label="Who Am I ?"
                  />
                </div>
                      <div className="mt-3">
                        {/* <Head title="Allergies" /> */}
                      </div>
                      <div className="grid grid-cols-2 gap-2 mb-5">
                        <div className="mt-2">
                          <FormikController
                            control="textArea"
                            type="text"
                            name="eatingDrinkingNeeds"
                            label="Eating | Drinking Needs (eg. has Dysphagia)"
                          />
                        </div>
                        <div className="mt-2">
                          <FormikController
                            control="textArea"
                            type="text"
                            name="specialistEquipment"
                            label="Any specialist equipment for moving & handling positioning?"
                          />
                        </div>
                      </div>
                      <div className="grid grid-cols-2 gap-2">
                        <FormikController
                          control="textArea"
                          type="text"
                          name="knownAllergies"
                          label="Known Allergies"
                        />
                        <FormikController
                          control="textArea"
                          type="text"
                          name="painExpression"
                          label="How does the person express pain"
                        />
                        <FormikController
                          control="textArea"
                          type="text"
                          name="safetyIssues"
                          label="Issues relating to safety(e.g wandering)?"
                        />
             

        
                      </div>
                      <div className="grid grid-cols-2 gap-2 mt-2 mb-5">
                      <div className="mt-3">
                          <span className="font-medium">
                            Does the person suffer from any medical conditions
                          </span>
                          <br />
                          <div className="border rounded p-3">
                            <label>
                              <Field
                                type="checkbox"
                                name="Diabetes"
                                value={true}
                                checked={values.Diabetes === true}
                              />
                              <span className="ml-2">Diabetes</span>
                            </label>
                            <br />
                            <label>
                              <Field
                                type="checkbox"
                                name="Epilepsy"
                                value={true}
                                checked={values.Epilepsy === true}
                              />
                              <span className="ml-2">Epilepsy</span>
                            </label>
                            <br />
                            <label>
                              <Field
                                type="checkbox"
                                name="Asthma"
                                value={true}
                                checked={values.Asthma === true}
                              />
                              <span className="ml-2">Asthma</span>
                            </label>
                            <br />
                            <label>
                              <Field
                                type="checkbox"
                                name="SpinaBifina"
                                value={true}
                                checked={values.SpinaBifina === true}
                              />
                              <span className="ml-2">Spina Bifida</span>
                            </label>
                            <br />
                            <label>
                              <Field
                                type="checkbox"
                                name="OtherMedical"
                                value={true}
                                checked={values.OtherMedical === true}
                              />
                              <span className="ml-2">Other</span>
                            </label>

                            {values.OtherMedical === true && (
                              <div className="mt-2">
                                <FormikController
                                  control="textArea"
                                  type="text"
                                  name="otherDetails"
                                  label="Please specificy your other needs"
                                />
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="mt-3">
                          <span className="font-medium">
                            Special Dietary Needs
                          </span>
                          <br />
                          <div className="border rounded p-3">
                            <label>
                              <Field
                                type="checkbox"
                                name="dietDiebetes"
                                value={true}
                                checked={values.dietDiebetes === true}
                              />
                              <span className="ml-2">Diabetes</span>
                            </label>
                            <br />
                            <label>
                              <Field
                                type="checkbox"
                                name="vegetarian"
                                value={true}
                                checked={values.vegetarian === true}
                              />
                              <span className="ml-2">Vegetarian</span>
                            </label>
                            <br />
                            <label>
                              <Field
                                type="checkbox"
                                name="vegan"
                                value={true}
                                checked={values.vegan === true}
                              />
                              <span className="ml-2">Vegan</span>
                            </label>
                            <br />
                            <label>
                              <Field
                                type="checkbox"
                                name="specialDiet"
                                value={true}
                                checked={values.specialDiet === true}
                              />
                              <span className="ml-2">Specialized</span>
                            </label>
                            <br />
                            {values.specialDiet === true && (
                              <div className="mt-2">
                                <FormikController
                                  control="textArea"
                                  type="text"
                                  name="specialDietDetails"
                                  label="Please specificy your other diet needs"
                                />
                              </div>
                            )}
                          </div>
                        </div>
              
                        </div>
                        <span className="font-medium mt-10">
                            Additional Information
                          </span>
                          <br />
                          <div className="border rounded p-3">
                            <label>
                              <Field
                                type="checkbox"
                                name="smoker"
                                value={true}
                                checked={values.smoker === true}
                              />
                              <span className="ml-2">Smoker</span>
                            </label>
                            <br />
                            <label>
                              <Field
                                type="checkbox"
                                name="cat"
                                value={true}
                                checked={values.cat === true}
                              />
                              <span className="ml-2">Cats</span>
                            </label>
                            <br />
                            {values.cat && (
                              <div>
                                {" "}
                                <FormikController
                                  control="textArea"
                                  type="text"
                                  name="catName"
                                  label="Name of Cat"
                                />
                                <FormikController
                                  control="textArea"
                                  type="text"
                                  name="catActivities"
                                  label="Activities"
                                />
                              </div>
                            )}
                            <label>
                              <Field
                                type="checkbox"
                                name="dog"
                                value={true}
                                checked={values.dog === true}
                              />
                              <span className="ml-2">Dogs</span>
                            </label>
                            <br />
                            {values.dog && (
                              <div>
                                {" "}
                                <FormikController
                                  control="textArea"
                                  type="text"
                                  name="dogName"
                                  label="Name of Dog"
                                />
                                <FormikController
                                  control="textArea"
                                  type="text"
                                  name="dogActivities"
                                  label="Activities"
                                />
                              </div>
                            )}
                          </div>
                    </div>
                    <div className="mb-3 mt-5 flex flex-row-reverse">
                      <div></div>
                      {isLoadingAddClient ? (
                        <PageLoader />
                      ) : (
                        <SubmitButton label="Save & Next" />
                      )}
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
          <div className="hidden">
            <ReferralContract store={store} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddClient;
