import React from 'react'
import { Sidebar } from '../../../layouts/auth/Sidebar'
import { TopNavigation } from '../../../layouts/auth/TopNavigation'
import { useNavigate } from 'react-router-dom';
import FormikController from '../../../components/Formik/FormikController';
import SubmitButton from '../../../components/Formik/SubmitButton';
import { Formik, Form } from "formik";
import * as Yup from "yup";
import ProgressBar from './ProgressBar';
import { FaJenkins } from "react-icons/fa";
import addContracts from '../ClientActions/ClientContractsAction'
import { useDispatch,useSelector } from 'react-redux';
import PageLoader from '../../../components/PageLoader';
import fetchClientTier from '../../configurations/ConfigActions/GetClientTierAction'

const ReferralContract = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {addClientPersonalDetails} = useSelector(state =>state.addClientPersonal);
    const {isLoadingClientContracts,errorClientContracts} = useSelector(state =>state.addContract)
    const { getClientTiers } = useSelector(state => state.getClientTier)
    
    const itemsCountPerPage = 20;
    const page = 1;

    let clientTiers = getClientTiers && getClientTiers.data ? getClientTiers.data.map(cxt => {
        let obj = {
            key: cxt.type,
            value: cxt.id
        }
        return (obj);
    }) : [];

    React.useEffect(() => {
        dispatch(fetchClientTier({
            page,
            perPage: itemsCountPerPage
        }))

    },[page, addClientPersonalDetails]);



    const initialValues = {
        clientTierType: "",
        referral: "",
        contract: "",
        contractNumber: "",
        contractType: "",
        contractDailyRate: "",
        contractHourlyRate: "",
        ContractWeeklyDays: "",
        ContractWeeklyHours: "",
        site: "",
        startDate: "",
        endDate: "",
        communicationFiles: ""
    }

    const FILE_SIZE = 1024 * 1024 * 10;
    const SUPPORTED_FORMATS = [
        "application/pdf",
        "image/jpg",
        "image/jpeg",
        "image/png",
    ];

    const validationSchema = Yup.object({
        // contractNumber: Yup.string().required('This field is required'),
        // contractType: Yup.string().required('This field is required'),
        // contractDailyRate: Yup.string().required('This field is required'),
        // contractHourlyRate: Yup.string().required('This field is required'),
        // ContractWeeklyDays: Yup.string().required('This field is required'),
        // ContractWeeklyHours: Yup.string().required('This field is required'),
        // site: Yup.string().required('This field is required'),
        // startDate: Yup.string().required('This field is required'),
        // endDate: Yup.string().required('This field is required'),
        // communicationFiles: Yup.mixed().test(

        //     "format",

        //     "Only the following formats are accepted: .pdf, .jpeg, .jpg, .png",

        //     (value) => !value || (value && SUPPORTED_FORMATS.includes(value.type))

        // ).test(

        //     "fileSize",

        //     "File too large",

        //     (value) =>

        //         value === null || ((value) => value && value.size <= FILE_SIZE)

        // ).required("Required"),

        // referral: Yup.mixed().test(

        //     "format",

        //     "Only the following formats are accepted: .pdf, .jpeg, .jpg, .png",

        //     (value) => !value || (value && SUPPORTED_FORMATS.includes(value.type))

        // ).test(

        //     "fileSize",

        //     "File too large",

        //     (value) =>

        //         value === null || ((value) => value && value.size <= FILE_SIZE)

        // ).required("Required"),
        // contract: Yup.mixed().test(

        //     "format",

        //     "Only the following formats are accepted: .pdf, .jpeg, .jpg, .png",

        //     (value) => !value || (value && SUPPORTED_FORMATS.includes(value.type))

        // ).test(

        //     "fileSize",

        //     "File too large",

        //     (value) =>

        //         value === null || ((value) => value && value.size <= FILE_SIZE)

        // ).required("Required"),
    })

    const handleSubmit = (data) => {
        console.log('data',data);
        data.id = addClientPersonalDetails.id
        dispatch(addContracts(data))
        navigate('/key-contacts')
    }

    const Head = (props) => {
        return (
            <div className='border-b mb-5'><label className='text-xl font-medium text-gray-700'>{props.title}</label></div>
        )
    }

    const goBack = () => {
        navigate('/add-client')
    }

    return (
        <div><Sidebar /><TopNavigation title='Add Client' />
            <div className='bg-white h-screen'>
                <div className="flex ml-80 mr-16 mt-5">
                    <div className='w-40 border mt-5 p-3 rounded-lg bg-gray-50'><ProgressBar number={30} /></div>
                    <div className='w-full p-5'>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={(values) => {
                                handleSubmit(values);
                            }}
                        >
                            {({ setFieldValue, ...rest }) => (
                                <Form className='text-left'>
                                    <nav className="flex py-3 px-5 mb-2 text-gray-700 bg-gray-50 rounded-lg border border-gray-200 dark:bg-gray-800 dark:border-gray-700" aria-label="Breadcrumb">
                                        <ol className="inline-flex items-center space-x-1 md:space-x-3">
                                            <li className="inline-flex items-center">
                                                <a href="#" className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
                                                    <FaJenkins className="mr-2 w-4 h-4 font-bold"/> {addClientPersonalDetails.firstname} {addClientPersonalDetails.lastname}
                                                </a>
                                            </li>
                                            <li>
                                                <div className="flex items-center">
                                                    <svg className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                                    <a href="#" className="ml-1 text-sm font-medium text-gray-700 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white">Referrals And Contracts</a>
                                                    <svg className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>

                                                </div>
                                            </li>
                                        </ol>
                                    </nav>
                                    <div className="mt-5 rounded">
                                        <Head title='Referral' />
                                        <FormikController
                                            control='input'
                                            type='file'
                                            name='referral'
                                            label='Upload Referral'
                                            value={null}
                                            onChange={(event) =>
                                                setFieldValue("referral", event.target.files[0])
                                            }

                                        />
                                        <div className='mt-10'><Head title='Contracts' /></div>
                                        <div className='grid grid-cols-2 gap-2'>
                                            <FormikController
                                                control='input'
                                                type='file'
                                                name='contract'
                                                label='Upload Service Contract'
                                                value={null}
                                                onChange={(event) =>
                                                    setFieldValue("contract", event.target.files[0])
                                                }

                                            />

                                            <FormikController
                                                control='input'
                                                type='text'
                                                name='contractNumber'
                                                label='Contract No.'
                                            />

                                            <FormikController
                                                control='input'
                                                type='text'
                                                name='contractType'
                                                label='Contract Type'
                                            />

                                            <FormikController
                                                control='input'
                                                type='text'
                                                name='contractDailyRate'
                                                label='Contract Daily Rate'
                                            />

                                            <FormikController
                                                control='input'
                                                type='text'
                                                name='contractHourlyRate'
                                                label='Contract Hourly Rate'
                                            />

                                            <FormikController
                                                control='input'
                                                type='text'
                                                name='ContractWeeklyDays'
                                                label='Contract Weekly Days'
                                                placeholder='eg. Monday, Tuesday, Wednesday ...'
                                            />

                                            <FormikController
                                                control='input'
                                                type='text'
                                                name='ContractWeeklyHours'
                                                label='Contract Weekly Hours'
                                            />
                                            <FormikController
                                                control='input'
                                                type='text'
                                                name='site'
                                                label='Site of Client'
                                            />
                                            <FormikController
                                                control='input'
                                                type='date'
                                                name='startDate'
                                                label='Start Date of Contract'
                                            />

                                            <FormikController
                                                control='input'
                                                type='date'
                                                name='endDate'
                                                label='End Date of Contract'
                                            />
                                        </div>
                                        <div className='mt-10'><Head title='Communication' /></div>
                                        <FormikController
                                            control='input'
                                            type='file'
                                            label='Upload Communication Files'
                                            name='communicationFiles'
                                            value={null}
                                            onChange={(event) =>
                                                setFieldValue("communicationFiles", event.target.files[0])
                                            }

                                        />
                                         <div className='text-left mt-5'>
                                                        <FormikController
                                                            control='select'
                                                            type='select'
                                                            name='clientTierType'
                                                            label='Select Client Tier*'
                                                            options={clientTiers}
                                                        />
                                                    </div>
                                    </div>
                                   
                                    <div className="mb-3 mt-5 flex justify-between">
                                        <div className="mb-3 pt-3"><button className="bg-button text-white w-full rounded p-1.5 text-lg px-10" onClick={() => goBack()} >Back</button></div>
                                        {isLoadingClientContracts ? <PageLoader/> : <SubmitButton label='Save & Next' />}
                                    </div>
                                </Form>
                            )
                            }
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReferralContract