import {nextofkinPending, nextofkinSuccess, nextofkinFail} from '../RegisterCarerSlices/NextofKinSlice'
import {NextofKin} from '../../api/carerApi'

 const NextofKinAction = (data) => async dispatch => {

    try {
        dispatch(nextofkinPending());
        const result = await NextofKin(data);
        if(result){
            return dispatch(nextofkinSuccess(result))
        }
    } catch (error) {
        dispatch(nextofkinFail(error))
    }
} 

export default NextofKinAction