import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  EditDiploma: {},
  isLoadingEditDiploma: false,
  errorEditDiploma: "",
};

const EditDiplomaSlice = createSlice({
  name: "EditDiploma",
  initialState,
  reducers: {
    EditDiplomaPending: (state) => {
      state.isLoadingEditDiploma = true;
    },
    EditDiplomaSuccess: (state, { payload }) => {
      state.isLoadingEditDiploma = false;
      state.EditDiploma = payload;
      state.errorEditDiploma = "";
    },
    EditDiplomaFail: (state, { payload }) => {
      state.isLoadingEditDiploma = false;
      state.errorEditDiploma = payload;
    },
  },
});

export const { EditDiplomaPending, EditDiplomaSuccess, EditDiplomaFail } =
  EditDiplomaSlice.actions;

export default EditDiplomaSlice.reducer;
