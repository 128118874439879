import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  updatePersonalDetails: {},
  isLoadingupdatePersonalDetails: false,
  errorupdatePersonalDetails: "",
};

const updatePersonalDetailsSlice = createSlice({
  name: "updatePersonalDetails",
  initialState,
  reducers: {
    updatePersonalDetailsPending: (state) => {
      state.isLoadingupdatePersonalDetails = true;
    },
    updatePersonalDetailsSuccess: (state, { payload }) => {
      state.isLoadingupdatePersonalDetails = false;
      state.updatePersonalDetails = payload;
      state.errorupdatePersonalDetails = "";
    },
    updatePersonalDetailsFail: (state, { payload }) => {
      state.isLoadingupdatePersonalDetails = false;
      state.errorupdatePersonalDetails = payload;
    },
  },
});

export const { updatePersonalDetailsPending, updatePersonalDetailsSuccess, updatePersonalDetailsFail } =
  updatePersonalDetailsSlice.actions;

export default updatePersonalDetailsSlice.reducer;
