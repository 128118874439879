import {updateRegulatorClientStatusPending, updateRegulatorClientStatusSuccess, updateRegulatorClientStatusFail} from '../staffSlices/updateRegulatorClientStatusSlice'
import {updateRegulatorclientStatus} from '../../../api/staffApi'

 const updateRegulatorClientStatusAction = (data) => async dispatch => {

    try {
        dispatch(updateRegulatorClientStatusPending());
        const result = await updateRegulatorclientStatus(data);
        if(result){
            return dispatch(updateRegulatorClientStatusSuccess(result))
        }
    } catch (error) {
        dispatch(updateRegulatorClientStatusFail(error))
    }
}

export default updateRegulatorClientStatusAction