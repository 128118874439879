import React from "react";
import { BsFillEyeFill } from "react-icons/bs";

const MARecord = (marchart) => {
  const [showModal, setShowModal] = React.useState(false);
  const values = marchart.e;
  const marChart = values.administered;

  
console.log(values);

  const handleOpen = (id) => {
    setShowModal(true);
  };

  const Head = (props) => {
    return (
      <div className="text-left border-b mb-5 bg-gray-50 rounded p-2">
        <label className="text-md font-bold text-gray-700">{props.title}</label>
      </div>
    );
  };

  const HeaderTime = (props) => {
    return (
      <div className="text-center border-b mt-5 bg-orange-50 rounded p-1">
        <label className="font-bold text-gray">{props.title}</label>
      </div>
    );
  };
 
  const handleDate = (created)=>{ 
   const date = new Date(created);
   return date.getDate()+'-'+(date.getMonth()+1)+'-'+date.getFullYear();
  }

  const handleTime = (created)=>{ 
    const date = new Date(created);
    return date.getHours()+':'+date.getMinutes()+':'+date.getSeconds()
   }
  return (
    <>
      <button
        className=" text-white bg-orange-500 border hover:bg-white hover:bg-black hover:text-gray-700 hover:border hover:border-gray-300 hover:underline decoration-secondary focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        type="button"
        onClick={() => handleOpen()}
      >
       <BsFillEyeFill color="#FEEAA7" title="View Record" size={30} width={60}/>
      </button>
      {showModal ? (
        <>
          <div className="justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-5xl">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-xl font-semibold text-gray-500">
                    <span className="text-green">{values?.clientName}</span>
                    's Medication Record
                  </h3>
                  <button
                    className="p-1 ml-10 bg-transparent border-0 text-button float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    ×
                  </button>
                </div>

                <div className="relative p-6 flex-auto">
                  <div className="">
                    <div class="overflow-x-auto relative">
                   
                    </div>
                    <div className="mt-10">
                      <Head title={`Administration Record For : ${values?.medication}`} />
                       <Head title={`Recommended Dosage : ${values.dosage}`}/>
                    </div>
                    <div>
                     
                      <div className="grid grid-cols-2 gap-2">
                        {marChart && marChart.length > 0 ? marChart.map((med)=> {
                        return(
                            <div>
                            {med.numberTaken > 0 ?
                               <div className=" flex justify-between items-center w-full bg-white border p-5   rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-700 mt-3">
                               <div>
                                 <div className="font-bold text-lg">
                                  Number Administered:  {med.numberTaken}
                                 </div>
                                 <div>Date: {handleDate(med.createdAt)}</div>
                                 <div>Time: {handleTime(med.createdAt)}</div>
                                 <div>Carer: {med.carer?.firstname} {med.carer?.lastname}</div>
                               </div>
                               <div className="border rounded p-3 bg-green text-white">
                                 Taken
                               </div>
                               </div>
                             :
                            med.numberTaken === 0 && 
                            <div className=" flex justify-between items-center w-full bg-white border p-5 rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-700 mt-3">
                              <div>
                                 <div className="font-bold text-lg">
                                  Number Administered:  {med.numberTaken}
                                 </div>
                                 <div>Date: {handleDate(med.createdAt)}</div>
                                 <div>Time: {handleTime(med.createdAt)}</div>
                                 <div>Carer: {med.carer?.firstname} {med.carer?.lastname}</div>
                                 <div>Notes: {med.reasonNotTaken}</div>
                               </div>
                            <div className="border rounded p-3 border-red-500 font-semibold text-red-500">

                            Declined

                            </div>
                          </div>
                            }
                            </div>
                          )
                          
                        }) :    
                        
                        <div className=" flex justify-between items-center w-full bg-white border p-5 rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-700 mt-3">
                      
                        <div className="border rounded p-3 border-red-500 font-semibold text-red-500">
                          Medication not administered during the selected period
                        </div>
                      </div>}
                      
                      </div>
                  
                   
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default MARecord;
