import React from "react";
import { useField } from "formik";


const SimpleSwitch = ({ label, name, placeholder, ...rest }) => {
  const [field, meta] = useField(name);

  return (
    <div className={rest.className}>
      {rest.text && <p>{rest.text}</p>}
      <div
        className={`w-full ${
          rest.noBorder ? "" : " border border-gray-300"
        } p-1 rounded outline-none 0 py-1 flex justify-between items-center`}
      >
        <div className=" flex flex-auto mt-1 px-1 w-2/3">
          {label || "Click YES or NO"}
        </div>

        <label htmlFor={name} className="switch">
          {/* <div className="switch"> */}
          <input
            type="checkbox"
            // checked={field.value}
            name={name}
            id={name}
            {...field}
            {...rest}
           
          />
          <div className="slider round">           
          </div>
          {/* </div> */}
        </label>
      </div>
   
    </div>
  );
};

export default SimpleSwitch;
