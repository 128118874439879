import {configureStore } from '@reduxjs/toolkit'
import loginReducer from '../pages/auth/LoginSlice'
import CarerTokenReducer from '../pages/carers/RegisterCarerSlices/AuthSlice'
import userReducer  from '../pages/dashboard/userSlice'
import getStaffReducer from '../pages/staff/staffSlices/staffSlice'
import updateStaffStatusReducer from '../pages/staff/staffSlices/updateStatusSlice'
import addStaffReducer from '../pages/staff/staffSlices/addStaffSlice'
import updateStaffReducer from '../pages/staff/staffSlices/updateStaffSlice'
import resetEmailReducer from '../pages/auth/ResetPasswordSlice'
import updateMyAccountReducer from '../pages/myaccount/accountSlice'
import changePasswordReducer from '../pages/myaccount/changePasswordSlice'
import addCarePlanReducer from '../pages/careplans/carePlanSlices/addCarePlanSlice'
import getCarePlanReducer from '../pages/careplans/carePlanSlices/getCarePlanSlice'
import updateCarePlanReducer from '../pages/careplans/carePlanSlices/updateCarePlanSlice'
import addClientPersonalReducer from '../pages/clients/ClientSlices/PersonalDetailsSlice'
import addClientContractReducer from '../pages/clients/ClientSlices/ClientContractsSlice'
import addClientContactReducer from '../pages/clients/ClientSlices/ClientContactsSlice'
import addClientPropertyReducer from '../pages/clients/ClientSlices/ClientPropertySlice'
import addClientTierReducer from '../pages/configurations/ConfigSlices/AddClientTierSlice'
import getClientTierReducer from '../pages/configurations/ConfigSlices/GetClientTierSlice'
import updateClientTierReducer from '../pages/configurations/ConfigSlices/UpdateClientTierSlice'
import addCarerTierReducer from '../pages/configurations/ConfigSlices/AddCarerTierSlice'
import getCarerTierReducer from '../pages/configurations/ConfigSlices/GetCarerTierSlice'
import updateCarerTierReducer from '../pages/configurations/ConfigSlices/UpdateCarerTierSlice'
import addClientCarePlansReducer from '../pages/clients/ClientSlices/ClientCarePlansSlice'
import clientTiersReducer from '../pages/clients/ClientSlices/ClientTierSlice'
import getClientListReducer from '../pages/clients/ClientSlices/GetClientsSlice'
import updateClientStatusReducer from '../pages/clients/ClientSlices/UpdateClientStatusSlice'
import getAddedCarePlansReducer from '../pages/clients/ClientSlices/GetAddedCarePlansSlice'
import getAddedMarChartsReducer from '../pages/clients/ClientSlices/GetMarChartsSlice'
import editPersonalDetailsReducer from '../pages/clients/ClientSlices/EditPersonalDetailsSlice'
import editContractsReducer from '../pages/clients/ClientSlices/EditContractsSlice'
import editContactsReducer from '../pages/clients/ClientSlices/EditContactSlice'
import editPropertyReducer from '../pages/clients/ClientSlices/EditPropertySlice'
import editCarePlanReducer from '../pages/clients/ClientSlices/EditCarePlansSlice'
import editClientTierReducer from '../pages/clients/ClientSlices/EditClientTiersSlice'
import editContractDocReducer from '../pages/clients/ClientSlices/EditClientContractSlice'
import editReferralReducer from '../pages/clients/ClientSlices/EditReferralSlice'
import editCommunicationReducer from '../pages/clients/ClientSlices/EditCommunicationSlice'
import getCarersReducer from '../pages/carers/CarerSlices/GetCarersSlice'
import editClientCarersReducer from '../pages/clients/ClientSlices/EditCarersSlice'
import editKycDocReducer from '../pages/clients/ClientSlices/EditKycDocSlice'
import editPrescriptionReducer from '../pages/clients/ClientSlices/EditPrescriptionSlice'
import getCarersOnplacementReducer from '../pages/dashboard/DashboardSlices/GetCarersOnplacementSlice'
import getCarersWaitingplacementReducer from '../pages/dashboard/DashboardSlices/GetCarersWaitingPlacementSlice'
import getClockInOverdueReducer from '../pages/dashboard/DashboardSlices/ClockInOverdueSlice'
import getClockOutOverdueReducer from '../pages/dashboard/DashboardSlices/ClockOutOverdueSlice'
import getClockInReducer from '../pages/dashboard/DashboardSlices/ClockInSlice'
import getClockOutReducer from '../pages/dashboard/DashboardSlices/ClockOutSlice'
import getCarerDbsApplicationReducer from '../pages/recruitment/DbsSlices/GetCarerDbsSlice'
import getStaffDbsApplicationReducer from '../pages/recruitment/DbsSlices/GetStaffDbsSlice'
import GetTimesheetsSlice from '../pages/finance/Slices/GetTimesheetsSlice'
import GetShiftSlice from '../pages/finance/Slices/GetShiftSlice'
import GetInvoicesSlice from '../pages/finance/Slices/GetInvoicesSlice'
import GetInvoiceDetailsSlice from '../pages/finance/Slices/GetInvoiceDetailsSlice'
import GetMessagesReducer from '../pages/dashboard/DashboardSlices/GetMessagesSlice'
import addMessageReplyReducer from '../pages/dashboard/DashboardSlices/ReplyMessageSlice'
import ClientMarchartsReducer from '../pages/clients/ClientSlices/ClientMarchartsSlice'
import addReferenceReducer from '../pages/carers/CarerSlices/CarerReferencesSlice'
import staffBirthdaysReducer from  '../pages/staff/staffSlices/staffBirthdaysSlice'
import clientBirthdaysReducer from '../pages/clients/ClientSlices/GetClientBirthdaysSlice'
import verifyMarchartReducer from '../pages/dashboard/DashboardSlices/AssignedSlice'
import getMedicationRecordSlice from '../pages/clients/ClientSlices/ClientMedicationRecordSlice'
import clientCareWorkerReducer from '../pages/clients/ClientSlices/ClientCareWorkersSlice'
import clientaddedCarePlanReducer from '../pages/clients/ClientSlices/GetAddedCarePlansSlice'
import addMessageReducer from '../pages/dashboard/DashboardSlices/AddMessageSlice'
import complianceStatusUpdateSlice from '../pages/carers/CarerSlices/UpdateComplianceSlice'
import medicationAuditReducer from '../pages/clients/ClientSlices/ClientMedicationAuditTrailSlice'
import auditsReducer from '../pages/clients/ClientSlices/AuditTrailSlice'
import updateTimesheetReducer from '../pages/finance/Slices/EditTimesheetsSlice'
import editTimesheetsReducer from '../pages/finance/Slices/TimesheetsSlice'
import updateCarerStatusRedcuder from '../pages/carers/CarerSlices/UpdateStatusSlice'
import activateCarerStatusRedcuder from '../pages/carers/CarerSlices/ActivateStatusSlice'
import riskAssessRedcuder from '../pages/clients/ClientSlices/RiskAssessmentSlice'
import getRiskAssessRedcuder from '../pages/clients/ClientSlices/getRiskAssessmentSlice'
import updateRiskAssessReducer from '../pages/clients/ClientSlices/EditRiskAssessmentSlice'
import assignCarerReducer from '../pages/staff/staffSlices/assignRegulatorSlice'
import assignClientReducer from '../pages/staff/staffSlices/assignClientsSlice'
import getRegulatorClientReducer from '../pages/staff/staffSlices/getRegulatorClientsSlice'
import getRegulatorCarersReducer from '../pages/staff/staffSlices/getRegulatorCarersSlice'
import getSelectedCarersReducer from '../pages/staff/staffSlices/getSelectedCarersSlice'
import getSelectedClientsReducer from '../pages/staff/staffSlices/getSelectedClientsSlice'
import updateRegulatorClientsReducer from '../pages/staff/staffSlices/updateRegulatorsClientsSlice'
import updateRegulatorClientsStatusReducer from '../pages/staff/staffSlices/updateRegulatorClientStatusSlice'
import updateRegulatorCarersStatusReducer from '../pages/staff/staffSlices/updateRegulatorCarersStatusSlice'
import updateRegulatorCarersReducer from '../pages/staff/staffSlices/updateRegulatorsCarersSlice'
import getClientShiftsReducer from '../pages/clients/ClientSlices/GetClientShiftsSlice'
import logoutReducer  from '../pages/auth/LogoutSlice';
import getAdministeredMedicationReducer from '../pages/clients/ClientSlices/AdministeredMedicationSlice'

import signUpReducer from "../pages/carers/RegisterCarerSlices/SignUpSlice";
import AddPersonalDetailsReducer from "../pages/carers/RegisterCarerSlices/PersonalDetailsSlice";
import CarerTypeAvailabilityReducer from "../pages/carers/RegisterCarerSlices/CarerTypeSlice";
import DisabilityChecklistReducer from "../pages/carers/RegisterCarerSlices/DisabilitySlice";
import NextofKinReducer from "../pages/carers/RegisterCarerSlices/NextofKinSlice";
import DocumentsReducer from "../pages/carers/RegisterCarerSlices/DocumentsSlice";
import EducationTrainingReducer from "../pages/carers/RegisterCarerSlices/EducationTrainingSlice";
import EmploymentHistoryActionReducer from "../pages/carers/RegisterCarerSlices/EmploymentHistorySlice";
import CriminalRecordReducer from "../pages/carers/RegisterCarerSlices/CriminalRecordSlice";
import HealthDeclarationReducer from "../pages/carers/RegisterCarerSlices/HealthDeclarationSlice";
import EqualMonitoringReducer from "../pages/carers/RegisterCarerSlices/EqualMonitoringSlice";
import EmployeesProcessingReducer from "../pages/carers/RegisterCarerSlices/EmployeesProcessingSlice";
import TrainingHistoryReducer from "../pages/carers/RegisterCarerSlices/TrainingHistorySlice";
import BackgroundReducer from "../pages/carers/RegisterCarerSlices/BackgroundSlice";
import DbsApplicationReducer from "../pages/carers/RegisterCarerSlices/DbsApplicationSlice";
import GetReligionReducer from "../pages/carers/RegisterCarerSlices/GetReligionSlice";
import GetEthnicityReducer from "../pages/carers/RegisterCarerSlices/GetEthnicitySlice";
import TermsAgreementReducer from "../pages/carers/RegisterCarerSlices/TermsAgreementSlice";
import UpdateNextofkinReducer from "../pages/carers/RegisterCarerSlices/UpdateNextofkinSlice";
import UpdateAvailabilityReducer from "../pages/carers/RegisterCarerSlices/UpdateAvailabilitySlice";
import UpdateHealthDeclarationReducer from "../pages/carers/RegisterCarerSlices/UpdateHealthDeclarationSlice";
import UpdateEducationTrainingReducer from "../pages/carers/RegisterCarerSlices/UpdateEducationTrainingSlice";
import UpdateEmploymentHistoryReducer from "../pages/carers/RegisterCarerSlices/UpdateEmploymentHistorySlice";
import UpdateTrainingReducer from "../pages/carers/RegisterCarerSlices/UpdateTrainingSlice";
import UserDetailsReducer from "../pages/carers/RegisterCarerSlices/UserDetailsSlice";
import UpdateBackgroundReducer from "../pages/carers/RegisterCarerSlices/UpdateBackgroundSlice";
import updateDisabilityReducer from "../pages/carers/RegisterCarerSlices/updateDisabilitySlice";
import EditResumeReducer from "../pages/carers/RegisterCarerSlices/EditResumeSlice";
import EditDBSReducer from "../pages/carers/RegisterCarerSlices/EditDBSlice";
import EditPassportReducer from "../pages/carers/RegisterCarerSlices/EditPassportSlice";
import EditPermitReducer from "../pages/carers/RegisterCarerSlices/EditPermitSlice";
import EditRtwReducer from "../pages/carers/RegisterCarerSlices/EditRtwSlice";
import EditDiplomaReducer from "../pages/carers/RegisterCarerSlices/EditDiplomaSlice";
import EditCareCertReducer from "../pages/carers/RegisterCarerSlices/EditCareCertSlice";
import EditMandatoryReducer from "../pages/carers/RegisterCarerSlices/EditMandatoryCertificateSlice";
import EditMedTrainReducer from "../pages/carers/RegisterCarerSlices/EditMedicationTrainingSlice";
import EditMovingReducer from "../pages/carers/RegisterCarerSlices/EditMovingCertSlice";
import EditPegCertReducer from "../pages/carers/RegisterCarerSlices/EditPegCertSlice";
import GetDocumentsReducer from "../pages/carers/RegisterCarerSlices/GetCarerDocumentsSlice";
import UpdatePersonalDetailsSliceReducer from "../pages/carers/RegisterCarerSlices/UpdatePersonalDetailsSlice";
import GetMedicationAuditSliceReducer from '../pages/careplans/carePlanSlices/GetMedicationAuditSlice'
import SendCarerInviteSliceReducer from '../pages/carers/RegisterCarerSlices/SendCarerInviteSlice'
import MedicationAuditUpdateSliceReducer from '../pages/carers/RegisterCarerSlices/MedicationAuditUpdateSlice'
import GetClientInfoSliceReducer from '../pages/clients/ClientSlices/GetClientInfoSlice'
import GetCarerInfoSlice from '../pages/carers/RegisterCarerSlices/GetCarerInfoSlice'



export default configureStore({
  reducer: {
    login: loginReducer,
    user: userReducer,
    getstaff: getStaffReducer,
    getbirthday: staffBirthdaysReducer,
    updateStaffStatus: updateStaffStatusReducer,
    addStaff: addStaffReducer,
    updateStaff: updateStaffReducer,
    resetEmail: resetEmailReducer,
    updateAccount: updateMyAccountReducer,
    changePassword: changePasswordReducer,
    addCarePlan: addCarePlanReducer,
    getCarePlan: getCarePlanReducer,
    updateCarePlan: updateCarePlanReducer,
    addClientPersonal: addClientPersonalReducer,
    addContract: addClientContractReducer,
    addContact: addClientContactReducer,
    addProperty: addClientPropertyReducer,
    addClientTier: addClientTierReducer,
    getClientTier : getClientTierReducer,
    updateClientTier: updateClientTierReducer,
    addCarerTier: addCarerTierReducer,
    getCarerTier : getCarerTierReducer,
    updateCarerTier: updateCarerTierReducer,
    addClientCarePlan : addClientCarePlansReducer,
    clientTier : clientTiersReducer,
    getClient : getClientListReducer,
    updateClientStatus : updateClientStatusReducer,
    addedCarePlan : getAddedCarePlansReducer,
    getCharts : getAddedMarChartsReducer,
    updatePersonalDetails : editPersonalDetailsReducer,
    updateContracts : editContractsReducer,
    updateContacts : editContactsReducer,
    updateProperty : editPropertyReducer,
    updateCarePlans : editCarePlanReducer,
    updateTiers : editClientTierReducer,
    updateContractDoc : editContractDocReducer,
    updateReferral : editReferralReducer,
    updateCommunication : editCommunicationReducer,
    getCarersList : getCarersReducer,
    getCarerDbsApplicationList : getCarerDbsApplicationReducer,
    getStaffDbsApplicationList : getStaffDbsApplicationReducer,
    editClientCareWorkers : editClientCarersReducer,
    editKycDoc : editKycDocReducer,
    editPrescriptionDoc : editPrescriptionReducer,
    getCarerPlacement : getCarersOnplacementReducer,
    getCarerWaitingPlacement : getCarersWaitingplacementReducer,
    clockInOverdueCare : getClockInOverdueReducer,
    clockOutOverdueCare : getClockOutOverdueReducer,
    clockInCarer : getClockInReducer,
    clockOutCarer : getClockOutReducer,
    getTimesheetList: GetTimesheetsSlice,
    getShiftDetail: GetShiftSlice,
    getInvoiceList: GetInvoicesSlice,
    getInvoiceDetail: GetInvoiceDetailsSlice,
    getMessageList: GetMessagesReducer,
    addReply : addMessageReplyReducer,
    getclientmarcharts: ClientMarchartsReducer,
    addReferenced: addReferenceReducer,
    getclientbirthdays: clientBirthdaysReducer,
    assignedmarchart: verifyMarchartReducer,
    medicationrecords: getMedicationRecordSlice,
    clientcareworkers: clientCareWorkerReducer,
    addedclientcareplans: clientaddedCarePlanReducer,
    addMessage : addMessageReducer,
    complianceStatus: complianceStatusUpdateSlice,
    medicationAudit: medicationAuditReducer,
    audited: auditsReducer,
    edittimesheet: updateTimesheetReducer,
    updatetimesheet: editTimesheetsReducer,
    updateCarerStatus : updateCarerStatusRedcuder,
    activateCarerStatus : activateCarerStatusRedcuder,
    riskAssess : riskAssessRedcuder,
    getRiskAssess : getRiskAssessRedcuder,
    updateRiskAssess : updateRiskAssessReducer,
    assignCarer : assignCarerReducer,
    assignClient : assignClientReducer,
    getRegulatorClient : getRegulatorClientReducer,
    getSelectedCarers : getSelectedCarersReducer,
    updateRegulatorClients : updateRegulatorClientsReducer,
    getSelectedClients : getSelectedClientsReducer,
    updateRegulatorClientsStatus : updateRegulatorClientsStatusReducer,
    getRegulatorCarers : getRegulatorCarersReducer,
    updateRegulatorCarers : updateRegulatorCarersReducer,
    updateRegulatorCarersStatus : updateRegulatorCarersStatusReducer,
    getClientShifts : getClientShiftsReducer,
    logoutCache : logoutReducer,
    administered: getAdministeredMedicationReducer,
    signUpCarer: signUpReducer,
    PersonalDetails: AddPersonalDetailsReducer,
    DisabilityChecklist: DisabilityChecklistReducer,
    CarerTypeAvailability: CarerTypeAvailabilityReducer,
    NextofKin: NextofKinReducer,
    Documents: DocumentsReducer,
    EducationTraining: EducationTrainingReducer,
    EmploymentHistory: EmploymentHistoryActionReducer,
    CriminalRecord: CriminalRecordReducer,
    HealthDeclaration: HealthDeclarationReducer,
    EqualMonitoring: EqualMonitoringReducer,
    EmployeeProcessing: EmployeesProcessingReducer,
    TrainingHistory: TrainingHistoryReducer,
    Background: BackgroundReducer,
    DbsApplication: DbsApplicationReducer,
    GetReligion: GetReligionReducer,
    GetEthnicity: GetEthnicityReducer,
    AgreementTerms: TermsAgreementReducer,
    UpdateNextofkin : UpdateNextofkinReducer,
    UpdateAvailability : UpdateAvailabilityReducer,
    UpdateHealthDeclaration : UpdateHealthDeclarationReducer,
    UpdateEducationTraining : UpdateEducationTrainingReducer,
    UpdateEmploymentHistory : UpdateEmploymentHistoryReducer,
    UpdateTraining : UpdateTrainingReducer,
    UserDetails : UserDetailsReducer,
    UpdateBackground : UpdateBackgroundReducer,
    updateDisability : updateDisabilityReducer,
    EditResume : EditResumeReducer,
    EditDBS : EditDBSReducer,
    EditPassport : EditPassportReducer,
    EditPermit : EditPermitReducer,
    EditRtw : EditRtwReducer,
    EditDiploma : EditDiplomaReducer,
    EditCareCert : EditCareCertReducer,
    EditMandatory : EditMandatoryReducer,
    EditMedTrain : EditMedTrainReducer,
    EditMoving : EditMovingReducer,
    EditPegCert : EditPegCertReducer,
    GetDocuments : GetDocumentsReducer,
    UpdatePersonalInformation : UpdatePersonalDetailsSliceReducer,
    GetMedicationAudit : GetMedicationAuditSliceReducer,
    CarerToken: CarerTokenReducer,
    SendCarerInviteSlice : SendCarerInviteSliceReducer,
    MedicationAuditUpdateSlice : MedicationAuditUpdateSliceReducer,
    GetClientInfoSlice: GetClientInfoSliceReducer,
    GetCarerInfoSliceReducer : GetCarerInfoSlice
  },
})