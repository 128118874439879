import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    updatePassword: {},
    isLoadingChangePassword: false,
    errorChangePassword: ''
}

const changePasswordSlice = createSlice({
    name: 'updatePassword',
    initialState,
    reducers:{
        changePasswordPending: (state) => {
            state.isLoadingChangePassword = true;
        },
        changePasswordSuccess: (state, {payload}) => {
            state.isLoadingChangePassword = false;
            state.updatePassword = payload;
            state.errorChangePassword = '';
        },
        changePasswordFail: (state, {payload}) => {
            state.isLoadingChangePassword = false;
            state.errorChangePassword = payload;
        }
    }
})

export const {changePasswordPending, changePasswordSuccess, changePasswordFail} = changePasswordSlice.actions

export default changePasswordSlice.reducer