import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    getCarersWaitingPlacement: {},
    isLoadingCarerWaitingPlacement: false,
    errorCarerWaitingPlacement: ''
}

const getCarersWaitingPlacementSlice = createSlice({
    name: 'getCarersWaitingPlacement',
    initialState,
    reducers:{
        getCarersWaitingPlacementPending: (state) => {
            state.isLoadingCarerWaitingPlacement = true;
        },
        getCarersWaitingPlacementSuccess: (state, {payload}) => {
            state.isLoadingCarerWaitingPlacement = false;
            state.getCarersWaitingPlacement = payload;
            state.errorCarerWaitingPlacement = '';
        },
        getCarersWaitingPlacementFail: (state, {payload}) => {
            state.isLoadingCarerWaitingPlacement = false;
            state.errorCarerWaitingPlacement = payload;
        }
    }
})

export const {getCarersWaitingPlacementPending, getCarersWaitingPlacementSuccess, getCarersWaitingPlacementFail} = getCarersWaitingPlacementSlice.actions

export default getCarersWaitingPlacementSlice.reducer