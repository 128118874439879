import React from "react";
import FormikController from "../../../components/Formik/FormikController";
import SubmitButton from "../../../components/Formik/SubmitButton";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { Sidebar } from "../../../layouts/auth/Sidebar";
import { TopNavigation } from "../../../layouts/auth/TopNavigation";
import signUpAction from '../RegisterCarerActions/SignUpAction'


function AddCarer() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showModal, setShowModal] = React.useState(false);

  const { signUp, isLoadingSignUp, errorSignUp } = useSelector(
    (state) => state.signUpCarer
  );

  const initialValues = {
    email: "",
            password: "Welcome@11X!",
            confirm_password: "Welcome@11X!",
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Enter valid email address")
      .required("This field is required"),
  });



  const handleSubmit = (values) => {

    dispatch(signUpAction(values))
    navigate('/carer-info');
  };
  return (
    <>
      <div className="bg-gray-50">
        <Sidebar />
        <TopNavigation title="Add Carer" />
        <div className="bg-gray-50 h-screen">
          <div className="flex ml-80 mr-16 mt-5">
            {/* <div className="w-40 border mt-7 p-3 rounded-lg bg-gray-50 h-screen">
              <ProgressBar number={0} />
            </div> */}
            <div className="w-full p-5">
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                  handleSubmit(values);
                }}
              >
                {({ values }) => (
                  <Form className="mt-2">
                    {/* <nav
                      className="flex py-3 px-5 mb-2 text-gray-700 bg-gray-50 rounded-lg border border-gray-200 dark:bg-gray-800 dark:border-gray-700"
                      aria-label="Breadcrumb"
                    >
                      <ol className="inline-flex items-center space-x-1 md:space-x-3">
                        <li className="inline-flex items-center">
                          <a
                            href="#"
                            className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white"
                          >
                            <FaJenkins className="mr-2 w-4 h-4 font-bold" />{" "}
                            Email
                            <svg
                              className="w-6 h-6 text-gray-400"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                clip-rule="evenodd"
                              ></path>
                            </svg>
                          </a>
                        </li>
                      </ol>
                    </nav> */}
                    {/* <div className="text-left font-semibold text-gray-600">Please enter Email Address to Start</div> */}
                    <div className="grid grid-cols-2 gap-1 mt-5">
                     
                      <div className=" mb-3  p-3 rounded">
                        <FormikController
                          control="input"
                          type="email"
                          name="email"
                          label="Email address"
                          placeholder="example@yahoo.com"
                        />
                         <div className="mb-5 mt-5">
                        <FormikController
                          control="input"
                          type="text"
                          name="password"
                          label="Password"
                            disabled
                        />
                      </div>
                      {/* <div className="mb-3">
                        <FormikController
                          control="input"
                          type="text"
                          name="confirm_password"
                          label="Confirm Password"
                          disabled
                        />
                      </div> */}
                      <div className="mb-3 mt-5 flex flex-row-reverse">
                                            <div></div>
                                            <SubmitButton label='Save & Next' />
                                        </div>
                      </div>
                     
                      <div></div>
                      
                      
                    </div>
                   
                  </Form>
                )}
              </Formik>
            </div>
            {/* <div className="hidden"><ReferralContract store={store}/></div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default AddCarer;
