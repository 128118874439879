import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import PageLoader from '../../components/PageLoader';
import { Sidebar } from '../../layouts/auth/Sidebar'
import { TopNavigation } from '../../layouts/auth/TopNavigation'
import getSelectedCarersAction from './staffActions/getSelectedCarersAction'
import ViewApplication from '../recruitment/ViewApplication'
import Pagination from "react-js-pagination";

function AssignedCarers() {

    const dispatch = useDispatch();
    const { getSelectedCarers, isLoadinggetSelectedCarers } = useSelector(state => state.getSelectedCarers);

    const itemsCountPerPage = 5;

    const [page, setPage] = React.useState(1);

    const handleDate = (date) => {
        let format = new Date(date);
        return ("0"+format.getDate()).slice(-2)+'/'+("0"+(format.getMonth()+1)).slice(-2)+'/'+format.getFullYear()+' '+format.getHours()+':'+format.getMinutes();
    }

    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
      };

    React.useEffect(() => {
        dispatch(getSelectedCarersAction({
            page,
            perPage: itemsCountPerPage
        }));

    }, [page]);

   

    let assigned = isLoadinggetSelectedCarers ? <div className=''><PageLoader /></div> : <table className="w-full text-sm border rounded-lg text-left text-gray-500 dark:text-gray-400">
    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
        <tr>
            <th scope="col" className="px-6 py-3">
                Name
            </th>
            <th scope="col" className="px-6 py-3">
                Phone Number
            </th>
            <th scope="col" className="px-6 py-3">
                Email
            </th>
            <th scope="col" className="px-6 py-3">
            Created At
            </th>  
            <th scope="col" className="px-6 py-3">
            Compliance Status
            </th> 
            <th scope="col" className="px-6 py-3">
                Status
            </th>
        
            <th scope="col" className="px-6 py-3">
                <span className="sr-only">Edit</span>
            </th>
        </tr>
    </thead>
    <tbody>
        {(getSelectedCarers && getSelectedCarers.data && getSelectedCarers.data.length > 0) ? getSelectedCarers.data.map((reg) => (
            <tr key={reg.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-primary dark:hover:bg-gray-600">
                <th scope="row" className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                    {reg.carer.firstname} {reg.carer.lastname}
                </th>
                <td className="px-6 py-4">
                    {reg.carer.mobile}
                </td>
                <td className="px-6 py-4">
                    {reg.carer.email}
                </td>

                <td className="px-6 py-4">
                {handleDate(reg.carer.createdAt)} 
                </td> 
                
                <td className="px-6 py-4">
                    {reg.carer.complianceStatus.charAt(0).toUpperCase() + reg.carer.complianceStatus.slice(1).toLowerCase()}
             
                </td> 

                <td className="px-6 py-4">
                    {reg.carer.status.charAt(0).toUpperCase() + reg.carer.status.slice(1).toLowerCase()}
                </td>
                
                
                <td className="px-6 py-4">
                <ViewApplication carer={reg.carer}/>
                </td> 
            </tr>
        )) : <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
            <td colSpan="9" className="text-center px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap border rounded bg-red-100">No Data Found</td>
        </tr>}
    </tbody>
    </table>



  return (
    <div>
    <Sidebar /><TopNavigation title='Assigned Carers' />
    <div className='ml-72 p-10 mt-10'>{assigned}
    <div>
                {(getSelectedCarers && getSelectedCarers.data && getSelectedCarers.data.length > 0 && !isLoadinggetSelectedCarers) &&
                    <div className="flex justify-end">
                        <Pagination
                            activePage={page}
                            itemsCountPerPage={itemsCountPerPage}
                            totalItemsCount={getSelectedCarers.totalItemsCount}
                            pageRangeDisplayed={1}
                            onChange={handlePageChange}
                            itemclassName="page-item"
                            linkclassName="page-link"
                        />
                    </div>}
            </div></div>
    </div>
  )
}

export default AssignedCarers