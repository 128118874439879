import React from "react";
import VisitDetails from "./VisitDetails";
import GetClientShiftsAction from '../ClientActions/GetClientShiftsAction'
import { useDispatch, useSelector } from 'react-redux';
import Pagination from "react-js-pagination";
import PageLoader from '../../../components/PageLoader';

function RecentVisits({client}) {
const dispatch = useDispatch();
const {  getClientShifts, isLoadinggetClientShifts, errorgetClientShifts} = useSelector((state)=> state.getClientShifts)

const itemsCountPerPage = 5;
const [page, setPage] = React.useState(1);

const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
}


React.useEffect(()=>{
const data = client.id
  dispatch(GetClientShiftsAction({
    page:page,
    perPage:itemsCountPerPage,
    clientId : data
  }))
},[page]);

const handleDate = (date) => {
  let format = new Date(date);
  return ("0"+format.getDate()).slice(-2)+'/'+("0"+(format.getMonth()+1)).slice(-2)+'/'+format.getFullYear()+' '+format.getHours()+':'+format.getMinutes();
}
  


let content = isLoadinggetClientShifts ? <PageLoader /> : getClientShifts && getClientShifts.data && <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
  <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                Client Name
              </th>
              <th scope="col" className="px-6 py-3">
                Care Worker
              </th>
              <th scope="col" className="px-6 py-3">
                Clock In
              </th>
              <th scope="col" className="px-6 py-3">
                Clock Out
              </th>
              <th scope="col" className="px-6 py-3">
              </th>
            </tr>
          </thead>
<tbody>
{getClientShifts && getClientShifts.data && getClientShifts.data.length > 0 ? getClientShifts.data.map((shift) => (<tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-primary dark:hover:bg-gray-600">
                <th scope="row" className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                    {shift.client.title} {shift.client.firstname} {shift.client.lastname}
                </th>
                <td className="px-6 py-4">
                    {shift.carer.firstname } { shift.carer.lastname }
                </td>
                <td className="px-6 py-4">
                    {shift.clockIn ? handleDate(shift.clockIn) : ''}
                </td>
                <td className="px-6 py-4">
                {shift.clockOut ? handleDate(shift.clockOut) : '---'}
                </td>
                <td className="px-6 py-4">
                    <VisitDetails shift={shift}/>
                </td>
                
            </tr>)) : <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
        <td colSpan="9" className="text-center px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap border rounded bg-red-100">No Data Found</td>
    </tr>}
</tbody>
</table>

  return (
    <div className="p-10">
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                {content}
            </div>
            <div>
                {(getClientShifts && getClientShifts.data && getClientShifts.data.length > 0 && !isLoadinggetClientShifts) &&
                    <div className="flex justify-end">
                        <Pagination
                            activePage={page}
                            itemsCountPerPage={itemsCountPerPage}
                            totalItemsCount={getClientShifts.totalItemsCount}
                            pageRangeDisplayed={1}
                            onChange={handlePageChange}
                            itemClass="page-item"
                            linkClass="page-link"
                        />
                    </div>}
            </div>
    </div>
  );
}

export default RecentVisits;
