import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    sendInvite: {},
    isLoadingsendInvite: false,
    errorsendInvite: ''
}

const sendInviteSlice = createSlice({
    name: 'sendInvite',
    initialState,
    reducers:{
        sendInvitePending: (state) => {
            state.isLoadingsendInvite = true;
        },
        sendInviteSuccess: (state, {payload}) => {
            state.isLoadingsendInvite = false;
            state.sendInvite = payload;
            state.errorsendInvite = '';
        },
        sendInviteFail: (state, {payload}) => {
            state.isLoadingsendInvite = false;
            state.errorsendInvite = payload;
        }
    }
})

export const {sendInvitePending, sendInviteSuccess, sendInviteFail} = sendInviteSlice.actions

export default  sendInviteSlice.reducer