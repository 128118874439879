import { getStaffBirthdays } from "../../../api/staffApi";
import { getStaffBirthdaysFail, getStaffBirthdaysPending, getStaffBirthdaysSuccess } from "../staffSlices/staffBirthdaysSlice";

export const getStaffBirthday = (data) => async dispatch => {

    try {
        dispatch(getStaffBirthdaysPending());
        const result = await getStaffBirthdays(data);       
        if(result){
            return dispatch(getStaffBirthdaysSuccess(result))
        }
    } catch (error) {
        dispatch(getStaffBirthdaysFail(error))
    }
}