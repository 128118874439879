import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    addMessage: {},
    isLoadingAddMessage: false,
    errorAddMessage: ''
}

const addMessageSlice = createSlice({
    name: 'addMessage',
    initialState,
    reducers:{
        addMessagePending: (state) => {
            state.isLoadingAddMessage = true;
        },
        addMessageSuccess: (state, {payload}) => {
            state.isLoadingAddMessage = false;
            state.addMessage = payload;
            state.errorAddMessage = '';
        },
        addMessageFail: (state, {payload}) => {
            state.isLoadingAddMessage = false;
            state.errorAddMessage = payload;
        }
    }
})

export const {addMessagePending, addMessageSuccess, addMessageFail} = addMessageSlice.actions

export default addMessageSlice.reducer