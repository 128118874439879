import React from 'react'
import EditClient from './EditClient/EditClient'
import { useDispatch, useSelector } from 'react-redux';
import PageLoader from '../../components/PageLoader';
import fetchClients from './ClientActions/GetClientsAction'
import Pagination from "react-js-pagination";
import updateStatus from './ClientActions/UpdateClientStatusAction'
// import ClientMessages from './ClientMessages';

const Active = () => {

    const dispatch = useDispatch();
    
    const { getClients, isLoadingGetClients } = useSelector(state => state.getClient)
    const { updateClientStatus } = useSelector(state => state.updateClientStatus)
    const { editPersonalDetails } = useSelector(state => state.updatePersonalDetails)
    const { editCarePlan } = useSelector(state => state.updateCarePlans);
    const { editContract } = useSelector(state => state.updateContractDoc);
    const { editContractInfo } = useSelector(state => state.updateContracts);
    const { editReferralDoc } = useSelector(state => state.updateReferral);
    const { editCommunicationDoc } = useSelector(state => state.updateCommunication);
    const { editClientContacts } = useSelector(state => state.updateContacts);
    const { editProperty } = useSelector(state => state.updateProperty);
    const { addClientCarePlans } = useSelector(state => state.addClientCarePlan);
    const { clientTiers } = useSelector(state => state.clientTier);
    const { editKyc } = useSelector(state => state.editKycDoc);
    const { editPrescription } = useSelector(state => state.editPrescriptionDoc);
    const { editRiskAssessment} = useSelector(state => state.updateRiskAssess)
    const itemsCountPerPage = 20;
    const [page, setPage] = React.useState(1);



    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
    }

    const handleArchiveStatus = (values) => {
        let data = values.status === 'ACTIVE' ? { status: 'ARCHIVED', id: values.id } : { status: 'ACTIVE', id: values.id };
        dispatch(updateStatus(data))
    }
    const handleChangeStatus = (values) => {
        let data = values.status === 'ACTIVE' ? { status: 'INACTIVE', id: values.id } : { status: 'ACTIVE', id: values.id };

        dispatch(updateStatus(data))
    }

    React.useEffect(() => {
        dispatch(fetchClients({
            status: 'ACTIVE',
            page,
            perPage: itemsCountPerPage
        }))

    }, [page, updateClientStatus]);

    let content = isLoadingGetClients ? <PageLoader /> : getClients && getClients.data && <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
                <th scope="col" className="px-6 py-3">
                    Title
                </th>
                <th scope="col" className="px-6 py-3">
                    Name
                </th>
                <th scope="col" className="px-6 py-3">
                    Tier
                </th>
                {/* <th scope="col" className="px-6 py-3">
                    Bandwidth
                </th> */}
                <th scope="col" className="px-6 py-3">
                    Address
                </th>
                <th scope="col" className="px-6 py-3">
                    Email
                </th>
                <th scope="col" className="px-6 py-3">
                    Mobile
                </th>
                <th scope="col" className="px-6 py-3">
                    Status
                </th>
                {/* <th scope="col" className="px-6 py-3">
                  Messages
                </th> */}
                <th scope="col" className="px-6 py-3">
                    <span className="sr-only">Status</span>
                </th>
                <th scope="col" className="px-6 py-3">
                    <span className="sr-only">Status</span>
                </th>
                <th scope="col" className="px-6 py-3">
                    <span className="sr-only">Unarchive</span>
                </th>
                <th scope="col" className="px-6 py-3">
                    <span className="sr-only">Edit</span>
                </th>
            </tr>
        </thead>
        <tbody>
            {getClients && getClients.data && getClients.data.length > 0 ? getClients.data.map((client) => (<tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-primary dark:hover:bg-gray-600">
                <th scope="row" className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                    {client.title}.
                </th>
                <th scope="row" className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                    {client.firstname} {client.lastname}
                </th>
                <td className="px-6 py-4">
                    {client.clientTier ? client.clientTier.type : ''}
                </td>
                {/* <td className="px-6 py-4">
                    {client.clientTier ? client.clientTier.brandwidth : ''}
                </td> */}
                <td className="px-6 py-4">
                    {client.addressLine1}
                </td>
                <td className="px-6 py-4">
                    {client.email}
                </td>
                <td className="px-6 py-4">
                    {client.mobile}
                </td>
                <td className="px-6 py-4">
                    {client.status.charAt(0).toUpperCase() + client.status.slice(1).toLowerCase()}
                </td>
                <td className="px-6 py-4">
                    {/* <ClientMessages client={client}/> */}
                </td>
                <td className="px-6 py-4">
                    {client.status === 'ACTIVE' ?
                        <button onClick={() => handleChangeStatus(client)} className="font-medium text-button dark:text-blue-500 hover:underline">De-activate</button> :
                        <button onClick={() => handleChangeStatus(client)} className="font-medium text-button dark:text-blue-500 hover:underline">Activate</button>}
                </td>
                <td className="px-6 py-4">
                    {client.status === 'ACTIVE' &&
                        <button onClick={() => handleArchiveStatus(client)} className="font-medium text-button dark:text-blue-500 hover:underline">Archive</button>
                    }
                </td>
                <td className="px-6 py-4 text-right">
                    <a className="font-medium text-secondary dark:text-blue-500 hover:underline"><EditClient client={client} /></a>
                </td>
            </tr>)) : <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                <td colSpan="11" className="text-center px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap border rounded bg-red-100">No Data Found</td>
            </tr>}
        </tbody>
    </table>

    return (
        <div className='ml-72 mr-8 mt-10 '>
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                {content}
            </div>
            <div>
                {(getClients && getClients.data && getClients.data.length > 0 && !isLoadingGetClients) &&
                    <div className="flex justify-end">
                        <Pagination
                            activePage={page}
                            itemsCountPerPage={itemsCountPerPage}
                            totalItemsCount={getClients.totalItemsCount}
                            pageRangeDisplayed={1}
                            onChange={handlePageChange}
                            itemClass="page-item"
                            linkClass="page-link"
                        />
                    </div>}
            </div>
        </div>
    )
}

export default Active