import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    clientTiers: {},
    isLoadingClientTiers: false,
    errorClientTiers: ''
}

const clientTiersSlice = createSlice({
    name: 'clientTiers',
    initialState,
    reducers:{
        clientTiersPending: (state) => {
            state.isLoadingClientTiers = true;
        },
        clientTiersSuccess: (state, {payload}) => {
            state.isLoadingClientTiers = false;
            state.clientTiers = payload;
            state.errorClientTiers = '';
        },
        clientTiersFail: (state, {payload}) => {
            state.isLoadingClientTiers = false;
            state.errorClientTiers = payload;
        }
    }
})

export const {clientTiersPending, clientTiersSuccess, clientTiersFail} = clientTiersSlice.actions

export default clientTiersSlice.reducer