import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  signUp: {},
  isLoadingSignUp: false,
  errorSignUp: "",
};

const signUpSlice = createSlice({
  name: "signUp",
  initialState,
  reducers: {
    signUpPending: (state) => {
      state.isLoadingSignUp = true;
    },
    signUpSuccess: (state, { payload }) => {
      state.isLoadingSignUp = false;
      state.signUp = payload;
      state.errorSignUp = "";
    },
    signUpFail: (state, { payload }) => {
      state.isLoadingSignUp = false;
      state.errorSignUp = payload;
    },
  },
});

export const { signUpPending, signUpSuccess, signUpFail } = signUpSlice.actions;

export default signUpSlice.reducer;
