import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  CriminalRecord: {},
  isLoadingCriminalRecord: false,
  errorCriminalRecord: "",
};

const CriminalRecordSlice = createSlice({
  name: "CriminalRecord",
  initialState,
  reducers: {
    CriminalRecordPending: (state) => {
      state.isLoadingCriminalRecord = true;
    },
    CriminalRecordSuccess: (state, { payload }) => {
      state.isLoadingCriminalRecord = false;
      state.CriminalRecord = payload;
      state.errorCriminalRecord = "";
    },
    CriminalRecordFail: (state, { payload }) => {
      state.isLoadingCriminalRecord = false;
      state.errorCriminalRecord = payload;
    },
  },
});

export const { CriminalRecordPending, CriminalRecordSuccess, CriminalRecordFail } =
  CriminalRecordSlice.actions;

export default CriminalRecordSlice.reducer;
