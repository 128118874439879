import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    addCarerTier: {},
    isLoadingCarerTier: false,
    errorCarerTier: ''
}

const addCarerTierSlice = createSlice({
    name: 'addCarerTier',
    initialState,
    reducers:{
        addCarerTierPending: (state) => {
            state.isLoadingCarerTier = true;
        },
        addCarerTierSuccess: (state, {payload}) => {
            state.isLoadingCarerTier = false;
            state.addCarerTier = payload;
            state.errorCarerTier = '';
        },
        addCarerTierFail: (state, {payload}) => {
            state.isLoadingCarerTier = false;
            state.errorCarerTier = payload;
        }
    }
})

export const {addCarerTierPending, addCarerTierSuccess, addCarerTierFail} = addCarerTierSlice.actions

export default addCarerTierSlice.reducer