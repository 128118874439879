import React from 'react'
import { Sidebar } from '../../../layouts/auth/Sidebar'
import { TopNavigation } from '../../../layouts/auth/TopNavigation'
import { useNavigate } from 'react-router-dom';
import FormikController from '../../../components/Formik/FormikController';
import SubmitButton from '../../../components/Formik/SubmitButton';
import { Formik, Form } from "formik";
import * as Yup from "yup";
import ProgressBar from './ProgressBar';
import { FaJenkins } from "react-icons/fa";
import Postcoder from "react-address-lookup";
import { useDispatch, useSelector } from 'react-redux';
import addContact from '../ClientActions/ClientContactsAction'

const KeyContacts = ({getClientInfo}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
    const initialValues = {
        GeneralPractionerPhoneNumber:getClientInfo && getClientInfo.contacts ? getClientInfo?.contacts.GeneralPractionerPhoneNumber :"",
        GeneralPractionerEmail:getClientInfo && getClientInfo.contacts ? getClientInfo?.contacts.GeneralPractionerEmail :"",
        GeneralPractionerAddressLine1:getClientInfo && getClientInfo.contacts ? getClientInfo?.contacts.GeneralPractionerAddressLine1 : "",
        GeneralPractionerAddressLine2:getClientInfo && getClientInfo.contacts ? getClientInfo?.contacts.GeneralPractionerAddressLine2 : "",
        GeneralPractionerTown:getClientInfo && getClientInfo.contacts ? getClientInfo?.contacts.GeneralPractionerTown : "",
        GeneralPractionerCounty:getClientInfo && getClientInfo.contacts ? getClientInfo?.contacts.GeneralPractionerCounty : "",
        GeneralPractionerPostcode:getClientInfo && getClientInfo.contacts ? getClientInfo?.contacts.GeneralPractionerPostcode : "",
        HealthProfessionalsPhoneNumber:getClientInfo && getClientInfo.contacts ? getClientInfo?.contacts.HealthProfessionalsPhoneNumber : "",
        HealthProfessionalsEmail:getClientInfo && getClientInfo.contacts ? getClientInfo?.contacts.HealthProfessionalsEmail : "",
        HealthProfessionalsAddressLine1:getClientInfo && getClientInfo.contacts ? getClientInfo?.contacts.HealthProfessionalsAddressLine1 : "",
        HealthProfessionalsAddressLine2:getClientInfo && getClientInfo.contacts ? getClientInfo?.contacts.HealthProfessionalsAddressLine2 : "",
        HealthProfessionalsTown:getClientInfo && getClientInfo.contacts ? getClientInfo?.contacts.HealthProfessionalsTown : "",
        HealthProfessionalsCounty:getClientInfo && getClientInfo.contacts ? getClientInfo?.contacts.HealthProfessionalsCounty : "",
        HealthProfessionalsPostcode:getClientInfo && getClientInfo.contacts ? getClientInfo?.contacts.HealthProfessionalsPostcode : "",
        PharmacyPhoneNumber:getClientInfo && getClientInfo.contacts ? getClientInfo?.contacts.PharmacyPhoneNumber : "",
        PharmacyEmail:getClientInfo && getClientInfo.contacts ? getClientInfo?.contacts.PharmacyEmail : "",
        PharmacyAddressLine1:getClientInfo && getClientInfo.contacts ? getClientInfo?.contacts.PharmacyAddressLine1 : "",
        PharmacyAddressLine2:getClientInfo && getClientInfo.contacts ? getClientInfo?.contacts.PharmacyAddressLine2 : "",
        PharmacyTown:getClientInfo && getClientInfo.contacts ? getClientInfo?.contacts.PharmacyTown : "",
        PharmacyCounty:getClientInfo && getClientInfo.contacts ? getClientInfo?.contacts.PharmacyCounty : "",
        PharmacyPostcode:getClientInfo && getClientInfo.contacts ? getClientInfo?.contacts.PharmacyPostcode : ""
    }

    const {addClientPersonalDetails} = useSelector(state =>state.addClientPersonal);

    React.useEffect(() => {

    }, [addClientPersonalDetails]);

    const validationSchema = Yup.object({
        GeneralPractionerPhoneNumber: Yup.string().matches(phoneRegExp, 'Phone number is not valid').required('This field is required'),
        HealthProfessionalsPhoneNumber: Yup.string().matches(phoneRegExp, 'Phone number is not valid').required('This field is required'),
        PharmacyPhoneNumber: Yup.string().matches(phoneRegExp, 'Phone number is not valid').required('This field is required'),
        PharmacyCounty: Yup.string().required('This field is required'),
        GeneralPractionerAddressLine1: Yup.string().required('This field is required'),
        HealthProfessionalsAddressLine1: Yup.string().required('This field is required'),
        GeneralPractionerTown: Yup.string().required('This field is required'),
        GeneralPractionerCounty: Yup.string().required('This field is required'),
        HealthProfessionalsTown: Yup.string().required('This field is required'),
        HealthProfessionalsCounty: Yup.string().required('This field is required'),
        PharmacyAddressLine1: Yup.string().required('This field is required'),
        PharmacyTown: Yup.string().required('This field is required'),
        GeneralPractionerEmail: Yup.string().email('Enter valid email address').required('This field is required'),
        HealthProfessionalsEmail: Yup.string().email('Enter valid email address').required('This field is required'),
        PharmacyEmail: Yup.string().email('Enter valid email address').required('This field is required'),
    })

    const handleSubmit = (data) => {
        data.id = addClientPersonalDetails.id
        dispatch(addContact(data))
        navigate('/property-access')

    }

    const handleUpdate = (values) => {
        console.log("VALUEE", values);
        values.id = getClientInfo?.id
        dispatch(addContact(values))
      };

    const Head = (props) => {
        return (
            <div className='border-b mb-5'><label className='text-xl font-medium text-gray-700'>{props.title}</label></div>
        )
    }

    const goBack = () => {
        navigate('/referral-contracts')
    }


    return (
        <div>
    {(getClientInfo && !getClientInfo?.contacts) ? "" : <Sidebar />}
      {(getClientInfo && !getClientInfo?.contacts) ? "" : <TopNavigation title="Add Client" />}
            <div className='bg-white '>
                <div className={(getClientInfo && !getClientInfo?.contacts) ? "mt-5" : "flex ml-80 mr-16 mt-5"}>
                   {(getClientInfo && !getClientInfo?.contacts) ? "" : <div className='w-40 border mt-5 p-3 rounded-lg bg-gray-50'><ProgressBar number={50} /></div>}
                    <div className='w-full p-5'>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={(values) => {(getClientInfo && !getClientInfo?.contacts) ? handleUpdate(values) : handleSubmit(values)
                            }}
                        >
                            {(formik) => {
                                const logAddress1 = (addr) => {
                                    formik.setFieldValue("GeneralPractionerAddressLine1", addr.addressline1);
                                    formik.setFieldValue("GeneralPractionerAddressLine2", addr.addressline2);
                                    formik.setFieldValue("GeneralPractionerCounty", addr.county);
                                    formik.setFieldValue("GeneralPractionerTown", addr.posttown);
                                    formik.setFieldValue("GeneralPractionerPostcode", addr.postcode);

                                };
                                const logAddress2 = (addr) => {
                                    formik.setFieldValue("HealthProfessionalsAddressLine1", addr.addressline1);
                                    formik.setFieldValue("HealthProfessionalsAddressLine2", addr.addressline2);
                                    formik.setFieldValue("HealthProfessionalsCounty", addr.county);
                                    formik.setFieldValue("HealthProfessionalsTown", addr.posttown);
                                    formik.setFieldValue("HealthProfessionalsPostcode", addr.postcode);

                                };
                                const logAddress3 = (addr) => {
                                    formik.setFieldValue("PharmacyAddressLine1", addr.addressline1);
                                    formik.setFieldValue("PharmacyAddressLine2", addr.addressline2);
                                    formik.setFieldValue("PharmacyCounty", addr.county);
                                    formik.setFieldValue("PharmacyTown", addr.posttown);
                                    formik.setFieldValue("PharmacyPostcode", addr.postcode);

                                };
                                return (
                                    <Form className='text-left'>
                                   {! getClientInfo &&  <nav className="flex py-3 px-5 mb-2 text-gray-700 bg-gray-50 rounded-lg border border-gray-200 dark:bg-gray-800 dark:border-gray-700" aria-label="Breadcrumb">
                                            <ol className="inline-flex items-center space-x-1 md:space-x-3">
                                                <li className="inline-flex items-center">
                                                    <a href="#" className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
                                                        <FaJenkins className="mr-2 w-4 h-4 font-bold"/>  {addClientPersonalDetails.firstname} {addClientPersonalDetails.lastname}
                                                    </a>
                                                </li>
                                                <li>
                                                    <div className="flex items-center">
                                                        <svg className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                                        <a href="#" className="ml-1 text-sm font-medium text-gray-700 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white">Referral Contract</a>
                                                        <svg className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>

                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="flex items-center">
                                                        <a href="#" className=" text-sm font-medium text-gray-700 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white">Key Contacts</a>
                                                        <svg className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>

                                                    </div>
                                                </li>
                                            </ol>
                                        </nav>}
                                        <div className="border p-5 rounded">

                                            <div className=''><Head title='GP Contact Details' /></div>
                                            <div className='grid grid-cols-2 gap-2'>
                                                <FormikController
                                                    control='input'
                                                    type='text'
                                                    name='GeneralPractionerPhoneNumber'
                                                    label='Phone number'
                                                />
                                                <FormikController
                                                    control='input'
                                                    type='text'
                                                    name='GeneralPractionerEmail'
                                                    label='Email address'
                                                />
                                            </div>
                                            <div className="mt-3">
                                                <label className='font-medium '>Search post code</label>
                                                <Postcoder
                                                    apiKey={process.env.REACT_APP_POSTCODER}
                                                    addressSelectedCallback={logAddress1}
                                                />
                                                <p className="text-sm text-gray-500">
                                                    Searches and allow you to select your address from the
                                                    dropdown
                                                </p>
                                            </div>
                                            <div className='grid grid-cols-2 gap-2 mt-5'>
                                                <div>
                                                    <FormikController
                                                        control='input'
                                                        type='text'
                                                        name="GeneralPractionerAddressLine1"
                                                        placeholder=""
                                                        label="Address line 1 *"
                                                    />
                                                </div>
                                                <div>
                                                    <FormikController
                                                        control='input'
                                                        type='text'
                                                        name="GeneralPractionerAddressLine2"
                                                        placeholder=""
                                                        label="Address line 2"
                                                    />
                                                </div>
                                                <div>
                                                    <FormikController
                                                        control='input'
                                                        type='text'
                                                        name="GeneralPractionerTown"
                                                        placeholder=""
                                                        label="Town *" />
                                                </div>
                                                <div>
                                                    <FormikController
                                                        control='input'
                                                        type='text'
                                                        name="GeneralPractionerCounty"
                                                        placeholder=""
                                                        label="County *" />
                                                </div>
                                                <div>
                                                    <FormikController
                                                        control='input'
                                                        type='text'
                                                        name="GeneralPractionerPostcode"
                                                        placeholder=""
                                                        label="Post code *"
                                                    />
                                                </div>
                                            </div>

                                            <div className='mt-10'><Head title='Community Health Professionals Details' /></div>
                                            <div className='grid grid-cols-2 gap-2'>
                                                <FormikController
                                                    control='input'
                                                    type='text'
                                                    name='HealthProfessionalsPhoneNumber'
                                                    label='Phone number'
                                                />
                                                <FormikController
                                                    control='input'
                                                    type='text'
                                                    name='HealthProfessionalsEmail'
                                                    label='Email address'
                                                />
                                            </div>
                                            <div className="mt-3">
                                                <label className='font-medium'>Search post code</label>
                                                <Postcoder
                                                    apiKey={process.env.REACT_APP_POSTCODER}
                                                    addressSelectedCallback={logAddress2}
                                                />
                                                <p className="text-sm text-gray-500 ">
                                                    Searches and allow you to select your address from the
                                                    dropdown
                                                </p>
                                            </div>
                                            <div className='grid grid-cols-2 gap-2 mt-5'>
                                                <div>
                                                    <FormikController
                                                        control='input'
                                                        type='text'
                                                        name="HealthProfessionalsAddressLine1"
                                                        placeholder=""
                                                        label="Address line 1 *"
                                                    />
                                                </div>
                                                <div>
                                                    <FormikController
                                                        control='input'
                                                        type='text'
                                                        name="HealthProfessionalsAddressLine2"
                                                        placeholder=""
                                                        label="Address line 2"
                                                    />
                                                </div>
                                                <div>
                                                    <FormikController
                                                        control='input'
                                                        type='text'
                                                        name="HealthProfessionalsTown"
                                                        placeholder=""
                                                        label="Town *" />
                                                </div>
                                                <div>
                                                    <FormikController
                                                        control='input'
                                                        type='text'
                                                        name="HealthProfessionalsCounty"
                                                        placeholder=""
                                                        label="County *" />
                                                </div>
                                                <div>
                                                    <FormikController
                                                        control='input'
                                                        type='text'
                                                        name="HealthProfessionalsPostcode"
                                                        placeholder=""
                                                        label="Post code *"
                                                    />
                                                </div>
                                            </div>

                                            <div className='mt-10'><Head title='Pharmacy Contact Details' /></div>
                                            <div className='grid grid-cols-2 gap-2'>
                                                <FormikController
                                                    control='input'
                                                    type='text'
                                                    name='PharmacyPhoneNumber'
                                                    label='Phone number'
                                                />
                                                <FormikController
                                                    control='input'
                                                    type='text'
                                                    name='PharmacyEmail'
                                                    label='Email address'
                                                />
                                            </div>
                                            <div className="mt-3">
                                                <label className='font-medium '>Search post code</label>
                                                <Postcoder
                                                    apiKey={process.env.REACT_APP_POSTCODER}
                                                    addressSelectedCallback={logAddress3}
                                                />
                                                <p className="text-sm text-gray-500">
                                                    Searches and allow you to select your address from the
                                                    dropdown
                                                </p>
                                            </div>
                                            <div className='grid grid-cols-2 gap-2 mt-5'>
                                                <div>
                                                    <FormikController
                                                        control='input'
                                                        type='text'
                                                        name="PharmacyAddressLine1"
                                                        placeholder=""
                                                        label="Address line 1 *"
                                                    />
                                                </div>
                                                <div>
                                                    <FormikController
                                                        control='input'
                                                        type='text'
                                                        name="PharmacyAddressLine2"
                                                        placeholder=""
                                                        label="Address line 2"
                                                    />
                                                </div>
                                                <div>
                                                    <FormikController
                                                        control='input'
                                                        type='text'
                                                        name="PharmacyTown"
                                                        placeholder=""
                                                        label="Town *" />
                                                </div>
                                                <div>
                                                    <FormikController
                                                        control='input'
                                                        type='text'
                                                        name="PharmacyCounty"
                                                        placeholder=""
                                                        label="County *" />
                                                </div>
                                                <div>
                                                    <FormikController
                                                        control='input'
                                                        type='text'
                                                        name="PharmacyPostcode"
                                                        placeholder=""
                                                        label="Post code *"
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                        <div className="mb-3 mt-5 flex justify-between">
                                      {  (getClientInfo && !getClientInfo?.contacts) ? <div></div>  : <div className="mb-3 pt-3"><button className="bg-button text-white w-full rounded p-1.5 text-lg px-10" onClick={() => goBack()} >Back</button></div>}
                                            <SubmitButton label='Save & Next' />
                                        </div>
                                    </Form>
                                )
                            }}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default KeyContacts