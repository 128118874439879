import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    updateStatus: {},
    isLoadingupdateStatus: false,
    errorupdateStatus: ''
}

const updateStatusSlice = createSlice({
    name: 'updateStatus',
    initialState, 
    reducers:{
        updateStatusPending: (state) => {
            state.isLoadingupdateStatus = true;
        },
        updateStatusSuccess: (state, {payload}) => {
            state.isLoadingupdateStatus = false;
            state.updateStatus = payload;
            state.errorupdateStatus = '';
        },
        updateStatusFail: (state, {payload}) => {
            state.isLoadingupdateStatus = false;
            state.errorupdateStatus = payload;
        }
    }
})

export const {updateStatusPending, updateStatusSuccess, updateStatusFail} = updateStatusSlice.actions

export default updateStatusSlice.reducer