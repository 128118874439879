import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  EditPegCert: {},
  isLoadingEditPegCert: false,
  errorEditPegCert: "",
};

const EditPegCertSlice = createSlice({
  name: "EditPegCert",
  initialState,
  reducers: {
    EditPegCertPending: (state) => {
      state.isLoadingEditPegCert = true;
    },
    EditPegCertSuccess: (state, { payload }) => {
      state.isLoadingEditPegCert = false;
      state.EditPegCert = payload;
      state.errorEditPegCert = "";
    },
    EditPegCertFail: (state, { payload }) => {
      state.isLoadingEditPegCert = false;
      state.errorEditPegCert = payload;
    },
  },
});

export const { EditPegCertPending, EditPegCertSuccess, EditPegCertFail } =
  EditPegCertSlice.actions;

export default EditPegCertSlice.reducer;
