import {getClientShiftsPending, getClientShiftsSuccess, getClientShiftsFail} from '../ClientSlices/GetClientShiftsSlice'
import {getClientShifts} from '../../../api/clientApi'

 const GetClientShiftsAction = (data) => async dispatch => {

    try {
        dispatch(getClientShiftsPending());
        const result = await getClientShifts(data);
        if(result){
            return dispatch(getClientShiftsSuccess(result))
        }
    } catch (error) {
        dispatch(getClientShiftsFail(error))
    }
} 

export default GetClientShiftsAction