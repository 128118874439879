import { getMedicationRecord } from "../../../api/medicationApi";
import { getMedicationRecordsFail, getMedicationRecordsPending, getMedicationRecordsSuccess } from "../ClientSlices/ClientMedicationRecordSlice";

const getClientMedicationRecord = (data) => async dispatch => {

    try {
        dispatch(getMedicationRecordsPending());
        const result = await getMedicationRecord(data);
        if(result){
            return dispatch(getMedicationRecordsSuccess(result))
        }
    } catch (error) {
        dispatch(getMedicationRecordsFail(error))
    }
} 

export default getClientMedicationRecord