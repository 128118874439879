import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    updateClientTiers: {},
    isLoadingUpdateClientTier: false,
    errorUpdateClientTier: ''
}

const updateClientTierSlice = createSlice({
    name: 'updateClientTiers',
    initialState,
    reducers:{
        updateClientTierPending: (state) => {
            state.isLoadingUpdateClientTier = true;
        },
        updateClientTierSuccess: (state, {payload}) => {
            state.isLoadingUpdateClientTier = false;
            state.updateClientTiers = payload;
            state.errorUpdateClientTier = '';
        },
        updateClientTierFail: (state, {payload}) => {
            state.isLoadingUpdateClientTier = false;
            state.errorUpdateClientTier = payload;
        }
    }
})

export const {updateClientTierPending, updateClientTierSuccess, updateClientTierFail} = updateClientTierSlice.actions

export default updateClientTierSlice.reducer