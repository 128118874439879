import {updateStaffPending, updateStaffSuccess, updateStaffFail} from '../staffSlices/updateStaffSlice'
import {updateStaff} from '../../../api/staffApi'

 const updateStaffStatus = (data) => async dispatch => {

    try {
        dispatch(updateStaffPending());
        const result = await updateStaff(data);
        if(result){
            return dispatch(updateStaffSuccess(result))
        }
    } catch (error) {
        dispatch(updateStaffFail(error))
    }
}

export default updateStaffStatus