import React from "react";
import GetCarerInfoAction from '../../carers/RegisterCarerActions/GetCarerInfoAction';
import CarerEmployment from "../../carers/AddCarer/CarerEmployment";
import { useDispatch, useSelector } from "react-redux";
import EditEmploymentHistory from "../../carers/AddCarer/EditEmploymentHistory";

function EmploymentHistory({ carer }) {
  const dispatch = useDispatch();
  const {GetCarerInfo, isLoadingGetCarerInfo} = useSelector((state)=>state.GetCarerInfoSliceReducer)
  // console.log('EHH',GetCarerInfo);
  const { EmploymentHistory,isLoadingEmploymentHistory } = useSelector(
    (state) => state.EmploymentHistory
  );
  const { updateEmploymentHistory, isLoadingupdateEmploymentHistory } = useSelector((state) => state.UpdateEmploymentHistory);
  const formatDate = (newdate) => {
    let date = new Date(newdate);
    let shortMonth = date.toLocaleString("en-us", { month: "short" }); /* Jun */
    let year = date.getFullYear();
    return shortMonth + ", " + year;
  };

  const cares = carer.carer;

  const Head = (props) => {
    return (
        <div className='border-b mb-5 text-left'><label className='text-xl font-medium text-gray-700 '>{props.title}</label></div>
    )
}

React.useEffect(() => {
  dispatch(GetCarerInfoAction({id:carer?.carer?.id}))
}, [updateEmploymentHistory,EmploymentHistory]);

  return (GetCarerInfo.employmentHistory.length === 0  ? <CarerEmployment GetCarerInfo={GetCarerInfo}/> :
    <div className="w-full p-5">
        <div className="text-left font-bold text-gray-600 p-4 text-md bg-gray-100 mb-5 rounded uppercase">
        Work Experience
                    </div>
     
      {GetCarerInfo && GetCarerInfo.employmentHistory.length > 0 ? (
        GetCarerInfo.employmentHistory.map((care) => (
          <>
            <div className="text-left">
              <h1 className="text-2xl font-bold mt-0 mb-6">
                {care.companyName}
              </h1>
              <section className="mb-6">
                <div className="lg:inline-block lg:w-8/12 lg:align-top italic mb-2">
                  {care.addressLine1}, {care.postCode}, {care.county},{" "}
                  {care.town}, {care.country}
                </div>
                <div className="lg:inline-block lg:w-8/12 w-full">
                  {care.position}
                </div>
                <div className="lg:inline-block lg:w-8/12 lg:align-top italic mb-2">
                  {formatDate(care.startDate)} -{" "}
                  {care.currentEmployer === true
                    ? "Present"
                    : formatDate(care.endDate)}
                </div>

                <div className="lg:inline-block lg:w-6/12 lg:align-top italic mb-2">
                  Job Summary:
                </div>
                <div className="lg:inline-block lg:w-8/12 w-full justify-center">
                  {care.summaryOfDuties}
                </div>
                <EditEmploymentHistory care={care}/>
              </section>
            </div>
          </>
        ))
      ) : (
        <div className="">
          <span className="">No Data Found</span>
        </div>
      )}
    </div>
  );
}

export default EmploymentHistory;
