import axios from "axios";

const BASE_URL =process.env.REACT_APP_ELMS_API_URL

export const getInvoices = async (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = sessionStorage.getItem('access_token')
            const res = await axios.get(`${BASE_URL}invoices?page=${data.page}&perPage=${data.perPage}&invoicestatus=${data.invoicestatus}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            resolve(res.data);

        } catch (error) {
            if(error.response.data.statusCode === 404){
                return null
             }
            
             reject(error)
        }
    })
}

export const getInvoiceDetails = async (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = sessionStorage.getItem('access_token')
            const res = await axios.get(`${BASE_URL}invoices/${data.id}?invoice=${data.invoiceNumber}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            resolve(res.data);

        } catch (error) { if(error.response.data.statusCode === 404){
            return null
         }
        
         reject(error) 
        }
    }
    )
}