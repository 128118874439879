import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  GetCarerInfo: {},
  isLoadingGetCarerInfo: false,
  errorGetCarerInfo: "",
};

const GetCarerInfoSlice = createSlice({
  name: "GetCarerInfo",
  initialState,
  reducers: {
    GetCarerInfoPending: (state) => {
      state.isLoadingGetCarerInfo = true;
    },
    GetCarerInfoSuccess: (state, { payload }) => {
      state.isLoadingGetCarerInfo = false;
      state.GetCarerInfo = payload;
      state.errorGetCarerInfo = "";
    },
    GetCarerInfoFail: (state, { payload }) => {
      state.isLoadingGetCarerInfo = false;
      state.errorGetCarerInfo = payload;
    },
  },
});

export const { GetCarerInfoPending, GetCarerInfoSuccess, GetCarerInfoFail } =
  GetCarerInfoSlice.actions;

export default GetCarerInfoSlice.reducer;
