import React from 'react'
import Active from './Active'
import Waiting from './Waiting'


function Compliant() {
  const [active, setActive] = React.useState('active');

  let content;

  switch (active) {
    case 'active':
      content = <Active />
      break;
    // case 'waiting':
    //   content = <Waiting />
    //   break;
    default:
      break;
  }
  return (
    <>
      <div className='flex justify-between'>
        <div></div>
        <div className=''>
          <ul className="flex flex-wrap -mb-px ml-80 pt-1 mr-8 border-b border-secondary">
            {/* <li className="mr-2">
              <button onClick={() => setActive('active')} className={`inline-block p-2 focus:outline-none rounded-t-lg  ${active === 'active' ? "border-secondary text-gray-700 font-medium dark:text-button dark:border-button " : "border-transparent hover:text-button hover:border-secondary dark:hover:text-gray-300"}`}>Active Carers</button>
            </li> */}
            {/* <li className="mr-2">
              <button onClick={() => setActive('waiting')} className={`inline-block p-2 focus:outline-none rounded-t-lg border ${active === 'waiting' ? "border-secondary text-white font-medium bg-secondary dark:text-button dark:border-button" : "border-transparent hover:text-button hover:border-secondary dark:hover:text-gray-300"}`}>Unassigned</button>
            </li> */}
          </ul>
        </div>
      </div>

      <div className="">
        {content}
      </div>
    </>
  )
}

export default Compliant