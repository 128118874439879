import  React from 'react'
import Pagination from 'react-js-pagination';
import { useDispatch, useSelector } from 'react-redux';
import getAudits from '../ClientActions/AuditAction';
import ViewMarChart from '../EditClient/ViewMarChart';


function AuditTrail(e){
    const dispach = useDispatch()
       
    const { getAudited, isLoadingGetAudits} =  useSelector(state => state.audited);

    const itemsCountPerPage = 10;
    const [page, setPage] = React.useState(1);

    React.useEffect(()=>{
        dispach(getAudits({
            id: e.e,
            page,
            perPage: itemsCountPerPage
        }))
    },[page])
   
    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
    }
    const handleDate = (date) => {
        const day  = new Date(date);
        return day.getDate().toString().padStart(2, '0')+'/'+(day.getMonth() + 1).toString().padStart(2, '0')+'/'+day.getFullYear()
    }
    
    const handleTime = (date) => {
        const day  = new Date(date);
        return day.getHours()+':'+(day.getMinutes()).toString().padStart(2, '0')+':'+day.getSeconds().toString().padStart(2, '0')
    }
    
        return(
            <>
    <div class="overflow-x-auto h-auto">
            <div class="bg-red-50 flex items-center justify-center bg-gray-100 font-sans overflow-hidden">
                <div class="w-full p-3">
                    <div class="bg-white shadow-md rounded my-6 p-4">
                        <table class="min-w-max w-full table-auto">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                    <th scope="col" className="px-6 py-3">
                    Date
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Time
                    </th>                 
                    <th scope="col" className="px-6 py-3">
                    Modified By
                    </th>
                    <th scope="col" className="px-6 py-3">   
                       
                    </th>               
                </tr>
            </thead>
            <tbody>
                {getAudited && getAudited.data?.length > 0 ? getAudited.data.map((med) => (
           
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-primary dark:hover:bg-gray-600">
                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                        {handleDate(med.createdAt)}
                    </th>
                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                    {handleTime(med.createdAt)}
                    </th>
                    <td className="px-6 py-4">
                    {med.admin?.firstname ? med.admin?.firstname +" "+ med.admin?.lastname : "Carer Action" } 
                    </td>              
                <td className='px-6 py-4'>
                <a className="font-medium text-secondary dark:text-blue-500 hover:underline"><ViewMarChart e={med.data} /></a>
                </td>
                </tr>
                )
                ) : <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                    <td colSpan="9" className="text-center px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap border rounded bg-red-100">No Data Found</td>
                </tr>}
            </tbody>
                 </table>
                {(getAudited && getAudited && getAudited.data?.length > 0 && !isLoadingGetAudits) &&
                    <div className="flex justify-end p-lg-2">
                        <Pagination
                            activePage={page}
                            itemsCountPerPage={itemsCountPerPage}
                            totalItemsCount={getAudited.totalItemsCount}
                            pageRangeDisplayed={1}
                            onChange={handlePageChange}
                            itemClass="page-item"
                            linkClass="page-link"
                        />
                    </div>}
            
    
                 </div>
                </div>
            </div>
        </div>
            </>
        )
}
export default AuditTrail