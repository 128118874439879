import {editReferralPending, editReferralSuccess, editReferralFail} from '../ClientSlices/EditReferralSlice'
import {updateReferral} from '../../../api/clientApi'

 const editReferral = (data) => async dispatch => {

    try {
        dispatch(editReferralPending());
        const result = await updateReferral(data);
        if(result){
            return dispatch(editReferralSuccess(result))
        }
    } catch (error) {
        dispatch(editReferralFail(error))
    }
} 

export default editReferral