


import { getClientMarchartsFail, getClientMarchartsPending, getClientMarchartsSuccess } from '../ClientSlices/ClientMarchartsSlice';
import { getClientMarcharts } from '../../../api/carePlanApi';

 const getClientMarchart = (data) => async dispatch => {

    try {
        dispatch(getClientMarchartsPending());
        const result = await getClientMarcharts(data);
        if(result){
            return dispatch(getClientMarchartsSuccess(result))
        }
    } catch (error) {
        dispatch(getClientMarchartsFail(error))
    }
} 

export default getClientMarchart