import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    editKyc: {},
    isLoadingEditKyc: false,
    errorEditKyc: ''
}

const editKycSlice = createSlice({
    name: 'editKyc',
    initialState,
    reducers:{
        editKycPending: (state) => {
            state.isLoadingEditKyc = true;
        },
        editKycSuccess: (state, {payload}) => {
            state.isLoadingEditKyc = false;
            state.editKyc = payload;
            state.errorEditKyc = '';
        },
        editKycFail: (state, {payload}) => {
            state.isLoadingEditKyc = false;
            state.errorEditKyc = payload;
        }
    }
})

export const {editKycPending, editKycSuccess, editKycFail} = editKycSlice.actions

export default editKycSlice.reducer