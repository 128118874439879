import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  EmploymentHistory: {},
  isLoadingEmploymentHistory: false,
  errorEmploymentHistory: "",
};

const EmploymentHistorySlice = createSlice({
  name: "EmploymentHistory",
  initialState,
  reducers: {
    EmploymentHistoryPending: (state) => {
      state.isLoadingEmploymentHistory = true;
    },
    EmploymentHistorySuccess: (state, { payload }) => {
      state.isLoadingEmploymentHistory = false;
      state.EmploymentHistory = payload;
      state.errorEmploymentHistory = "";
    },
    EmploymentHistoryFail: (state, { payload }) => {
      state.isLoadingEmploymentHistory = false;
      state.errorEmploymentHistory = payload;
    },
  },
});

export const { EmploymentHistoryPending, EmploymentHistorySuccess, EmploymentHistoryFail } = EmploymentHistorySlice.actions;

export default EmploymentHistorySlice.reducer;
