import {addStaffPending, addStaffSuccess, addStaffFail} from '../staffSlices/addStaffSlice'
import {addStaffMembers} from '../../../api/staffApi'

 const addStaff = (data) => async dispatch => {

    try {
        dispatch(addStaffPending());
        const result = await addStaffMembers(data);
        if(result){
            return dispatch(addStaffSuccess(result))
        }
    } catch (error) {
        dispatch(addStaffFail(error))
    }
} 

export default addStaff