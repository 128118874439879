import React from "react";
import { Sidebar } from "../../../layouts/auth/Sidebar";
import { TopNavigation } from "../../../layouts/auth/TopNavigation";
import { useNavigate } from "react-router-dom";
import FormikController from "../../../components/Formik/FormikController";
import SubmitButton from "../../../components/Formik/SubmitButton";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import ProgressBar from "../../clients/AddClient/ProgressBar";
import { FaJenkins } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import SimpleSwitch from "../../../components/SimpleSwitch";
import DocumentsAction from "../RegisterCarerActions/DocumentsAction";
import PageLoader from "../../../components/PageLoader";

const CarerDocuments = ({GetCarerInfo}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { signUp, isLoadingSignUp, errorSignUp } = useSelector(
    (state) => state.signUpCarer
  );
  const { documents, isLoadingDocuments } = useSelector(
    (state) => state.Documents
  );
  
  React.useEffect(() => {}, []);

  const initialValues = {
    curriculumVitae: "",
    dbs: "",
    DbsCertificateNumber: "",
    dbsOnlinedNumber: "",
    passport: "",
    permit: "",
    rightToWork: "",
    DbsPermission: false,
    UKEmploymentRestrictions: false,
    RequireWorkPermit: false,
    IdDocument: "",
    drivingLicence: "",
    birthCertificate: "",
    motherMedianName: "",
    proofOfAddress: "",
    nationalInsurance: "",
    bankStatement: "",
    birthNationality: "",
    provinceCountyOfBirth: "",
    townOfBirth: "",
    countryOfBirth: "",
    previousKnownNames: "",
    previousSurnames: "",
    DocumentaryEvidence: "",
    dateOfChange: "",
    addresses: [""],
  };


  const FILE_SIZE = 1024 * 1024 * 10;
  const SUPPORTED_FORMATS = [
    "application/pdf",
    "image/jpg",
    "image/jpeg",
    "image/png",
  ];

  const validationSchema = Yup.object({
    // contractNumber: Yup.string().required("This field is required"),

    // contract: Yup.mixed()
    //   .test(
    //     "format",
    //     "Only the following formats are accepted: .pdf, .jpeg, .jpg, .png",
    //     (value) => !value || (value && SUPPORTED_FORMATS.includes(value.type))
    //   )
    //   .test(
    //     "fileSize",
    //     "File too large",
    //     (value) =>
    //       value === null || ((value) => value && value.size <= FILE_SIZE)
    //   )
    //   .required("Required"),
  });

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result.split(',')[1]);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }
  const handleIcon = async (e, setFieldValue, field) => {
    const file = e.target.files[0];
    const type = file.type;
      const base64 = await convertToBase64(file);
      const base64String = `data:${type},${base64}`;
      console.log('FullString: ',base64String);
      setFieldValue(field, base64String);
 
  };
  const handleSubmit = (values) => {
    console.log('ddc',values);
    values.id = signUp.user.id;
    dispatch(DocumentsAction(values))
    navigate("/carer-education");
  };

  const handleUpdate = (values) => {
    
    values.id = GetCarerInfo.id
    console.log('ddc',values);
    dispatch(DocumentsAction(values))

  };

  const Head = (props) => {
    return (
      <div className="border-b mb-5">
        <label className="text-xl font-medium text-gray-700">
          {props.title}
        </label>
      </div>
    );
  };

  const goBack = () => {
    navigate("/nextofkin");
  };

  return (
    <div  className="bg-gray-50 h-screen">
      {(GetCarerInfo && !GetCarerInfo?.documents) ? "" : <Sidebar />}
      {(GetCarerInfo && !GetCarerInfo?.documents) ? "" : <TopNavigation title="Add Carer" />}
      <div className="bg-gray-50 ">
      <div className={(GetCarerInfo && !GetCarerInfo?.documents) ? "mt-5" : "flex ml-80 mr-16 mt-5"}>
         {(GetCarerInfo && !GetCarerInfo?.documents )? "": <div className="w-40 border mt-5 p-3 rounded-lg bg-gray-50 h-screen">
            <ProgressBar number={40} />
          </div>}
          <div className="w-full p-5">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values) => {(GetCarerInfo && !GetCarerInfo.documents) ? handleUpdate(values) :
                handleSubmit(values);
              }}
            >
              {({ setFieldValue, values, ...rest }) => (
                <Form className="text-left">
                  <div className="text-left font-semibold text-gray-600 py-1">
                    DOCUMENTS
                  </div>
                  <div className=" rounded shadow p-5 rounded bg-white">
                    <div className="grid grid-cols-2 gap-2">
                      <FormikController
                        control="input"
                        type="file"
                        name="curriculumVitae"
                        label="Curriculum Vitae"
                        value={null}
                        onChange={(event) => handleIcon(event, setFieldValue, 'curriculumVitae')}
                      />
                      <FormikController
                        control="input"
                        type="file"
                        name="passport"
                        label="Passport"
                        value={null}
                        onChange={(event) =>
                          handleIcon(event, setFieldValue, 'passport')
                        }
                      />

                      <FormikController
                        control="input"
                        type="file"
                        name="permit"
                        label="Residential Permit/ Biometric Card"
                        value={null}
                        onChange={(event) =>
                          handleIcon(event, setFieldValue, 'permit')
                        }
                      />

                      <FormikController
                        control="input"
                        type="file"
                        name="rightToWork"
                        label="Right To Work"
                        value={null}
                        onChange={(event) =>
                          handleIcon(event, setFieldValue, 'rightToWork')
                        }
                      />

                      <FormikController
                        control="input"
                        type="file"
                        name="dbs"
                        label="DBS Certificate"
                        value={null}
                        onChange={(event) =>
                          handleIcon(event, setFieldValue, 'dbs')
                        }
                      />

                      {values.dbs && (
                        <>
                          <FormikController
                            control="input"
                            type="text"
                            name="DbsCertificateNumber"
                            label="DBS Certificate Number"
                          />

                          <FormikController
                            control="input"
                            type="text"
                            name="dbsOnlinedNumber"
                            label="DBS Online Number"
                          />
                        </>
                      )}
                    </div>
                    <div className="text-left font-semibold text-gray-600 mt-4">
                      Please tick to say YES
                    </div>
                    <div className="mt-4">
                      <SimpleSwitch
                        name="UKEmploymentRestrictions"
                        label="Are there any restrictions to your residence in the UK which
                    might affect your right to take up employment in the UK ?"
                        checked={values.UKEmploymentRestrictions === true}
                      />
                    </div>

                    <div className="mt-4">
                      <SimpleSwitch
                        name="RequireWorkPermit"
                        label=" If you are successful in the application, would you require a
                    work permit prior to taking up employment ?"
                        checked={values.RequireWorkPermit === true}
                      />
                    </div>

                    {/* <div className="mt-4">
                      <SimpleSwitch
                        name="day"
                        label="Are there any restrictions to your residence in the UK which
                    might affect your right to take up employment in the UK ? "
                        checked={values.day === true}
                      />
                    </div> */}
                  </div>

                  <div className="mb-3 mt-5 flex justify-between">
                   {GetCarerInfo && !GetCarerInfo?.documents ? <div></div> :<div className="mb-3 pt-3">
                      <button
                        className="bg-button text-white w-full rounded p-1.5 text-lg px-10"
                        onClick={() => goBack()}
                      >
                        Back
                      </button>
                    </div>}

                    {isLoadingDocuments ? <PageLoader/> : <SubmitButton label={GetCarerInfo && !GetCarerInfo?.documents ? "Update" : "Save & Next"} />}
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarerDocuments;
