import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    getCarerDbsApplications: {},
    isLoadingGetCarers: false,
    errorGetCarers: ''
}

const getCarerDbsSlice = createSlice({
    name: 'getCarerDbsApplications',
    initialState,
    reducers:{
        getCarerDbsPending: (state) => {
            state.isLoadingGetCarers = true;
        },
        getCarerDbsSuccess: (state, {payload}) => {
            state.isLoadingGetCarers = false;
            state.getCarerDbsApplications = payload;
            state.errorGetCarers = '';
        },
        getCarerDbsFail: (state, {payload}) => {
            state.isLoadingGetCarers = false;
            state.errorGetCarers = payload;
        }
    }
})



export const {getCarerDbsPending, getCarerDbsSuccess, getCarerDbsFail} = getCarerDbsSlice.actions

export default getCarerDbsSlice.reducer