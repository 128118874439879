import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    carerClockin: {},
    isLoadingClockIn: false,
    errorClockIn: ''
}

const clockInSlice = createSlice({
    name: 'carerClockin',
    initialState,
    reducers:{
        clockInPending: (state) => {
            state.isLoadingClockIn = true;
        },
        clockInSuccess: (state, {payload}) => {
            state.isLoadingClockIn = false;
            state.carerClockin = payload;
            state.errorClockIn = '';
        },
        clockInFail: (state, {payload}) => {
            state.isLoadingClockIn = false;
            state.errorClockIn = payload;
        }
    }
})

export const {clockInPending, clockInSuccess, clockInFail} = clockInSlice.actions

export default clockInSlice.reducer