import React from "react";
import FormikController from "../../../components/Formik/FormikController";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Postcoder from "react-address-lookup";
import SubmitButton from "../../../components/Formik/SubmitButton";
import { useDispatch, useSelector } from "react-redux";
import UpdatePersonalInfoAction from "../../carers/RegisterCarerActions/UpdatePersonalDetailsAction";
import GetReligionAction from "../../carers/RegisterCarerActions/GetReligionAction";
import GetEthnicityAction from "../../carers/RegisterCarerActions/GetEthnicityAction";
import PageLoader from "../../../components/PageLoader";
import GetCarerInfoAction from "../../carers/RegisterCarerActions/GetCarerInfoAction";

function CarerDetails({ carer }) {
console.log("carer",carer);
  const dispatch = useDispatch();
  const { Login, isLoading, error } = useSelector((state) => state.CarerToken);
  const {GetCarerInfo, isLoadingGetCarerInfo} = useSelector((state)=>state.GetCarerInfoSliceReducer)
  const gloves = [
    { key: "Small", value: "Small" },
    { key: "Medium", value: "Medium" },
    { key: "Large", value: "Large" },
    { key: "X-Large", value: "X-Large" },
  ];

  const gender = [
    { key: "Male", value: "Male" },
    { key: "Female", value: "Female" },
    { key: "Other", value: "Other" },
  ];
  const {updatePersonalDetails, isLoadingupdatePersonalDetails, errorupdatePersonalDetails } = useSelector((state) => state.UpdatePersonalInformation);
  const { personalDetails,clearPersonal } = useSelector(
    (state) => state.PersonalDetails
  );

  // console.log('personalDetails',personalDetails);

  const [page] = React.useState(0);
  const itemsCountPerPage = 20;

  const { GetReligion } = useSelector((state) => state.GetReligion);
  const { GetEthnicity } = useSelector((state) => state.GetEthnicity);

  const religion = Array.isArray(GetReligion.data)
    ? GetReligion.data.map((cxt) => {
        let obj = {
          key: cxt.religion,
          value: cxt.id,
        };

        return obj;
      })
    : [];

  const ethnicity = Array.isArray(GetEthnicity.data)
    ? GetEthnicity.data.map((cxt) => {
        let obj = {
          key: cxt.ethnicity,
          value: cxt.id,
        };

        return obj;
      })
    : [];

 

  const religionn = GetCarerInfo?.religion ? GetCarerInfo.religion?.religion : "";
  const ethnicityy = GetCarerInfo?.ethnicity ? GetCarerInfo.ethnicity?.ethnicity : ""

  
  const initialValues = {
    firstname: GetCarerInfo ? GetCarerInfo.firstname : "",
    middlename: GetCarerInfo ? GetCarerInfo.middlename : "",
    lastname: GetCarerInfo ? GetCarerInfo.lastname : "",
    // preference: carer ? carer.carer.preference : "",
    dob: GetCarerInfo ? GetCarerInfo.dob : "",
    email: GetCarerInfo ? GetCarerInfo.email : "",
    mobile: GetCarerInfo ? GetCarerInfo.mobile : "",
    // landline: carer ? carer.carer.landline : "",
    postCode: GetCarerInfo ? GetCarerInfo.postCode : "",
    addressLine1: GetCarerInfo ? GetCarerInfo.addressLine1 : "",
    addressLine2: GetCarerInfo ? GetCarerInfo.addressLine2 : "",
    county: GetCarerInfo ? GetCarerInfo.county : "",
    town: GetCarerInfo ? GetCarerInfo.town : "",
    religionId: GetCarerInfo?.religion ? GetCarerInfo.religion.religion : "",
    ethnicityId: GetCarerInfo?.ethnicity ? GetCarerInfo.ethnicity.ethnicity : "",
    gender: GetCarerInfo?.gender ? GetCarerInfo.gender : "",
    country: GetCarerInfo?.country ? GetCarerInfo.country : "",
    glove: GetCarerInfo?.glove ? GetCarerInfo.glove : "",
    selfEmployed: GetCarerInfo?.selfEmployed ? GetCarerInfo.selfEmployed : false,
    nationalInsuranceNumber: GetCarerInfo?.nationalInsuranceNumber
      ? GetCarerInfo.nationalInsuranceNumber
      : "",
    dogTolerant: GetCarerInfo?.dogTolerant ? GetCarerInfo.dogTolerant : false,
    catTolerant: GetCarerInfo?.catTolerant ? GetCarerInfo.catTolerant : false,
    smoker: GetCarerInfo?.smoker ? GetCarerInfo.smoker : false,
    ownTransport: GetCarerInfo?.ownTransport ? GetCarerInfo.ownTransport : false,
  };


  const validationSchema = Yup.object({});

  const Head = (props) => {
    return (
      <div className="border-b mb-5">
        <label className="text-xl font-medium text-gray-700">
          {props.title}
        </label>
      </div>
    );
  };

  const handleUpdate = (values) => {
    // values.accessToken = Login.access_token;
    values.id = carer?.carer?.id;
    console.log("VAL", values);
    dispatch(UpdatePersonalInfoAction(values));
  };

  React.useEffect(() => {
    dispatch(
      GetReligionAction({
        page,
        perPage: itemsCountPerPage,
      })
    );
    dispatch(
      GetEthnicityAction({
        page,
        perPage: itemsCountPerPage,
      })
    );
    dispatch(GetCarerInfoAction({id:carer?.carer?.id}))
  }, [page,updatePersonalDetails]);


  
  return ( isLoadingGetCarerInfo ? <PageLoader/> :
    <div className="w-full px-7">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => handleUpdate(values)}
      >
        {({ formik, values }) => {
          const logAddress = (addr) => {
            formik.setFieldValue("addressLine1", addr.addressline1);
            formik.setFieldValue("addressLine2", addr.addressline2);
            formik.setFieldValue("county", addr.county);
            formik.setFieldValue("country", addr.country);
            formik.setFieldValue("town", addr.posttown);
            formik.setFieldValue("postcode", addr.postcode);
          };
          return (
            <Form className="text-left">
          {/* {personalDetails && (
                  <div
                    className="bg-green text-center border-green text-white py-3 mb-5 rounded relative"
                    role="alert"
                  >
                    <span className="block sm:inline">Successful</span>
                    <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                      <svg className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" /></svg>
                    </span>
                  </div>
                )} */}

              <div className="rounded">
                <div className="text-left font-bold text-gray-600 p-4 text-md bg-gray-100 mb-5 rounded">
                  PERSONAL DETAILS
                </div>
                <div className="grid grid-cols-2 gap-2 shadow p-3 rounded">
                  <FormikController
                    control="select"
                    type="select"
                    name="gender"
                    label="Gender*"
                    options={gender}
                  />

                  <FormikController
                    control="input"
                    type="text"
                    name="firstname"
                    label="First name*"
                  />
                  <FormikController
                    control="input"
                    type="text"
                    name="middlename"
                    label="Middle name"
                    placeholder="Optional"
                  />

                  <FormikController
                    control="input"
                    type="text"
                    name="lastname"
                    label="Last name*"
                  />

                  <FormikController
                    control="input"
                    type="date"
                    name="dob"
                    label="Date of Birth*"
                  />

                  {/* <FormikController
                                                    control='input'
                                                    type='text'
                                                    name='preference'
                                                    label='Prefer to be called'
                                                    placeholder="Optional"
                                                /> */}

                  {/* <FormikController
                                                    control='input'
                                                    type='text'
                                                    name='languages'
                                                    label='Languages Spoken*'
                                                    placeholder='eg. English, French, Brittish'
                                                /> */}

                  <FormikController
                    control="select"
                    type="select"
                    name="glove"
                    label="Glove Size*"
                    options={gloves}
                  />



               
                </div>
                <div className="  shadow p-3 rounded mt-3">
                <label className="text-high font-medium text-base">Religion / Belief - <span className="text-green">{religionn}</span></label>
                <FormikController
                    control="select"
                    type="select"
                    name="religionId"
                    // label="Religion / Belief" 
                    options={religion}
                  />
                  <label className="text-high font-medium text-base">Ethnic Group - <span className="text-green">{ethnicityy}</span></label>
                  <FormikController
                    control="select"
                    type="select"
                    name="ethnicityId"
                    // label="Ethnic Group"
                    options={ethnicity}
                  />
                </div>
                <div className="text-left font-bold text-gray-600 p-4 mt-5 text-md bg-gray-100 mb-5 rounded">
                  CONTACT DETAILS
                </div>
                <div className="shadow p-3 rounded">
                  <div className="grid grid-cols-2 gap-2">
                    <FormikController
                      control="input"
                      type="text"
                      name="mobile"
                      label="Mobile number*"
                    />
                    <FormikController
                      control="input"
                      type="text"
                      name="nationalInsuranceNumber"
                      label="National Insurance Number"
                    />
                  </div>
                  {/* <div className="mt-2">
                    <label className="font-medium ml-2">Search post code</label>
                    <Postcoder
                      apiKey={process.env.REACT_APP_POSTCODER}
                      addressSelectedCallback={logAddress}
                    />
                    <p className="text-sm text-gray-500 ml-2">
                      Searches and allow you to select your address from the
                      dropdown
                    </p>
                  </div> */}
                  <div className="grid grid-cols-3 gap-2 mt-5">
                    <div>
                      <FormikController
                        control="input"
                        type="text"
                        name="addressLine1"
                        placeholder=""
                        label="Address line 1 *"
                      />
                    </div>
                    <div>
                      <FormikController
                        control="input"
                        type="text"
                        name="addressLine2"
                        placeholder=""
                        label="Address line 2"
                      />
                    </div>
                    <div>
                      <FormikController
                        control="input"
                        type="text"
                        name="town"
                        placeholder=""
                        label="Town *"
                      />
                    </div>
                    <div>
                      <FormikController
                        control="input"
                        type="text"
                        name="county"
                        placeholder=""
                        label="County "
                      />
                    </div>

                    <div>
                      <FormikController
                        control="input"
                        type="text"
                        name="postCode"
                        placeholder=""
                        label="Post code *"
                      />
                    </div>
                    <div>
                      <FormikController
                        control="input"
                        type="text"
                        name="country"
                        placeholder=""
                        label="Country *"
                      />
                    </div>
                  </div>
                </div>
                {/* <div className="font-medium mt-5 ">Please click to say YES</div>

                <div className="shadow rounded p-3">
                  <label>
                    <Field
                      type="checkbox"
                      name="selfEmployed"
                      value={true}
                      checked={values.selfEmployed === true}
                    />
                    <span className="ml-2">Self Employed</span>
                  </label>
                  <br />
                  <label>
                    <Field
                      type="checkbox"
                      name="dogTolerant"
                      value={true}
                      checked={values.dogTolerant === true}
                    />
                    <span className="ml-2">Tolerant to Dogs</span>
                  </label>
                  <br />
                  <label>
                    <Field
                      type="checkbox"
                      name="catTolerant"
                      value={true}
                      checked={values.catTolerant === true}
                    />
                    <span className="ml-2">Tolerant to Cats</span>
                  </label>
                  <br />

                  <label>
                    <Field
                      type="checkbox"
                      name="ownTransport"
                      value={true}
                      checked={values.ownTransport === true}
                    />
                    <span className="ml-2">Own Transport</span>
                  </label>
                  <br />
                  <label>
                    <Field
                      type="checkbox"
                      name="smoker"
                      value={true}
                      checked={values.smoker === true}
                    />
                    <span className="ml-2">Smoker</span>
                  </label>
                  <br />
                </div> */}
              </div>
              <div className="mb-3 mt-5 flex flex-row-reverse">
                <div></div>
                {isLoadingupdatePersonalDetails ? <PageLoader/> : <SubmitButton label="Update" />}
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default CarerDetails;
