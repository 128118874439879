import {editClientCarersPending, editClientCarersSuccess, editClientCarersFail} from '../ClientSlices/EditCarersSlice'
import {updateClientCarer} from '../../../api/clientApi'

 const editClientCarer = (data) => async dispatch => {

    try {
        dispatch(editClientCarersPending());
        const result = await updateClientCarer(data);
        if(result){
            return dispatch(editClientCarersSuccess(result))
        }
    } catch (error) {
        dispatch(editClientCarersFail(error))
    }
} 

export default editClientCarer