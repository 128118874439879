import React from 'react'
import { BsFillEyeFill } from "react-icons/bs";
 

function EditTimesheet(timesheet) {
    const [showModal, setShowModal] = React.useState(false);

    const handleOpen = (id) => {
        setShowModal(true);
    };
    const time = timesheet.timesheet;
 

    const type = (clockIn, clockout)=>{
         const clockindate = new Date(clockIn);
         const clockOutDate = new Date(clockout);
         if((clockindate.getDay()+'-'+(clockindate.getMonth()+1)+'-'+clockindate.getFullYear()) === (clockOutDate.getDay()+'-'+(clockOutDate.getMonth()+1)+'-'+clockOutDate.getFullYear())){
            var hr = clockOutDate.getHours();
            if ( hr <= 11){
                return "Morning Carer"
            }else if(hr > 11){
             return "Night Carer"
            }
         }
         else{
            return "Live In Carer"
         }
    }

    const handleDate = (date) => {
        let format = new Date(date);
        return ("0"+format.getDate()).slice(-2)+'/'+("0"+(format.getMonth()+1)).slice(-2)+'/'+format.getFullYear()+' '+format.getHours()+':'+format.getMinutes();
    }

  return (
    <>
    
    <button
        className=" text-white bg-button border hover:bg-white hover:text-gray-700 hover:border hover:border-gray-300 hover:underline inline-flex decoration-secondary focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-1 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="button"
        onClick={() => handleOpen()}>
       View <BsFillEyeFill className='ml-1 mt-1'/>
    </button>
    {showModal ? (
        <>
            <div className="justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                  <div className="relative w-auto my-6 mx-auto max-w-5xl">
                    <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">

                    <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                        <h3 className=" font-semibold text-gray-500">
                        {time.shift.carer.firstname+' '+time.shift.carer.middlename+' '+time.shift.carer.lastname}'s Timesheet
                        </h3>
                        <button className="p-1 ml-10 bg-transparent border-0 text-button float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                onClick={() => setShowModal(false)} >
                                ×
                        </button>
                        </div>
                       
                        <div className="relative p-6 flex-auto">
                        <div className='p-5'>
                            <table className='table-auto mt-8 border-slate-400 w-full'>
                            
                                <tbody className='leading-10 justify-between text-center'>                           
                                <tr className='border-b'>
                                    <th className='border border-slate-400 pl-3 text-left text-black uppercase'>Client Name</th>                                 
                                    <td className='border border-slate-400 pr-3 text-left text-red-700 uppercase px-4'>{time.shift.client.firstname} {time.shift.client.middlename} {time.shift.client.lastname}</td>
                                </tr>
                                <tr className='border-b'>
                                    <th className='border border-slate-400 pl-3 text-left text-black uppercase'>Shift Type</th>                                 
                                    <td className='border border-slate-400 pr-3 text-left text-red-700  px-4'>{type(time.shift.clockIn,time.shift.clockOut)}</td>
                                </tr>
                                <tr className='border-b'>
                                    <th className='border border-slate-400 pl-3 text-left text-black uppercase'>Shift Start</th>                                 
                                    <td className='border border-slate-400 pr-3 text-left text-red-700  px-4'>{handleDate(time.shift.clockIn)}</td>
                                </tr>
                                <tr className='border-b'>
                                    <th className='border border-slate-400 pl-3 text-left text-black uppercase'>Shift Ends</th>                                 
                                    <td className='border border-slate-400 pr-3 text-left text-red-700  px-4'>{handleDate(time.shift.clockOut)}</td>
                                </tr>
                                <tr className='border-b'>
                                    <th className='border border-slate-400 pl-3 text-left text-black uppercase'>Break Start</th>                                 
                                    <td className='border border-slate-400 pr-3 text-left text-red-700  px-4'>{ time.shift.breakEnds ? handleDate(time.shift.breakEnds) : 'N/A'}</td>
                                </tr>
                                <tr className='border-b'>
                                    <th className='border border-slate-400 pl-3 text-left text-black uppercase'>Break Ends</th>                                 
                                    <td className='border border-slate-400 pr-3 text-left text-red-700  px-4'>{time.shift.breakStart? handleDate(time.shift.breakStart) : 'N/A'}</td>
                                </tr>
                                <tr className='border-b'>
                                    <th className='border border-slate-400 pl-3 text-left text-black uppercase'>Hours Worked</th>                                 
                                    <td className='border border-slate-400 pr-3 text-left text-red-700  px-4'>{time.numberOfHours}</td>
                                </tr>
                                <tr className='border-b'>
                                    <th className='border border-slate-400 pl-3 text-left text-black uppercase'>Bank Holiday Hours</th>                                 
                                    <td className='border border-slate-400 pr-3 text-left text-red-700  px-4'>{time.bankHolidayHours}</td>
                                </tr>
                                <tr className='border-b'>
                                    <th className='border border-slate-400 pl-3 text-left text-black uppercase'>Days Worked</th>                                 
                                    <td className='border border-slate-400 pr-3 text-left text-red-700  px-4'>{time.numberOfDays}</td>
                                </tr>
                                <tr className='border-b'>
                                    <th className='border border-slate-400 pl-3 text-left text-black uppercase'>Pay Rate</th>                                 
                                    <td className='border border-slate-400 pr-3 text-left text-red-700  px-4'>£{time.carerRate} {type(time.shift.clockIn,time.shift.clockOut) === 'Live In Carer' ? '/ week' : '/ hour'}</td>
                                </tr>
                                <tr className='border-b'>
                                    <th className='border border-slate-400 pl-3 text-left text-black uppercase'>Bill Rate</th>                                 
                                    <td className='border border-slate-400 pr-3 text-left text-red-700 px-4'>£{time.clientRate} {type(time.shift.clockIn,time.shift.clockOut) === 'Live In Carer' ? '/ week' : '/ hour'}</td>
                                </tr>
                                <tr className='border-b'>
                                    <th className='border border-slate-400 pl-3 text-left text-black uppercase'>Carer Total Amount</th>                                 
                                    <td className='border border-slate-400 pr-3 text-left text-red-700 text-lg px-4'>£{time.carerAmount}</td>
                                </tr>
                                <tr className='border-b'>
                                    <th className='border border-slate-400 pl-3 text-left text-black uppercase'>Total Bill Amount</th>                                 
                                    <td className='border border-slate-400 pr-3 text-left text-red-700 text-lg px-4'>£{time.invoiceAmount}</td>
                                </tr>
                            </tbody>
                            </table>
                    

                        </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
    ) : null}
</>
  )
}

export default EditTimesheet