import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    updateCarePlan: {},
    isLoadingUpdateCarePlan: false,
    errorUpdateCarePlan: ''
}

const updateCarePlanSlice = createSlice({
    name: 'updateCarePlan',
    initialState,
    reducers:{
        updateCarePlanPending: (state) => {
            state.isLoadingUpdateCarePlan = true;
        },
        updateCarePlanSuccess: (state, {payload}) => {
            state.isLoadingUpdateCarePlan = false;
            state.updateCarePlan = payload;
            state.errorUpdateCarePlan = '';
        },
        updateCarePlanFail: (state, {payload}) => {
            state.isLoadingUpdateCarePlan = false;
            state.errorUpdateCarePlan = payload;
        }
    }
})

export const {updateCarePlanPending, updateCarePlanSuccess, updateCarePlanFail} = updateCarePlanSlice.actions

export default updateCarePlanSlice.reducer