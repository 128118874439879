import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    getClientShifts: {},
    isLoadinggetClientShifts: false,
    errorgetClientShifts: ''
}

const getClientShiftsSlice = createSlice({
    name: 'getClientShifts',
    initialState,
    reducers:{
        getClientShiftsPending: (state) => {
            state.isLoadinggetClientShifts = true;
        },
        getClientShiftsSuccess: (state, {payload}) => {
            state.isLoadinggetClientShifts = false;
            state.getClientShifts = payload;
            state.errorgetClientShifts = '';
        },
        getClientShiftsFail: (state, {payload}) => {
            state.isLoadinggetClientShifts = false;
            state.errorgetClientShifts = payload;
        }
    }
})

export const {getClientShiftsPending, getClientShiftsSuccess, getClientShiftsFail} = getClientShiftsSlice.actions

export default getClientShiftsSlice.reducer