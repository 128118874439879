import {BackgroundPending, BackgroundSuccess, BackgroundFail} from '../RegisterCarerSlices/BackgroundSlice'
import {Background} from '../../api/carerApi'

 const BackgroundAction = (data) => async dispatch => {

    try {
        dispatch(BackgroundPending());
        const result = await Background(data);
        if(result){
            return dispatch(BackgroundSuccess(result))
        }
    } catch (error) {
        dispatch(BackgroundFail(error))
    }
} 

export default BackgroundAction