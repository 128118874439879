import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    getMessages: {},
    isLoadingMessages: false,
    errorMessages: ''
}

const getMessagesSlice = createSlice({
    name: 'getMessages',
    initialState,
    reducers:{
        getMessagesPending: (state) => {
            state.isLoadingMessages = true;
        },
        getMessagesSuccess: (state, {payload}) => {
            state.isLoadingMessages = false;
            state.getMessages = payload;
            state.errorMessages = '';
        },
        getMessagesFail: (state, {payload}) => {
            state.isLoadingMessages = false;
            state.errorMessages = payload;
        }
    }
})

export const {getMessagesPending, getMessagesSuccess, getMessagesFail} = getMessagesSlice.actions

export default getMessagesSlice.reducer