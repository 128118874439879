import {TrainingHistoryPending, TrainingHistorySuccess, TrainingHistoryFail} from '../RegisterCarerSlices/TrainingHistorySlice'
import {TrainingHistory} from '../../api/carerApi'

 const TrainingHistoryAction = (data) => async dispatch => {

    try {
        dispatch(TrainingHistoryPending());
        const result = await TrainingHistory(data);
        if(result){
            return dispatch(TrainingHistorySuccess(result))
        }
    } catch (error) {
        dispatch(TrainingHistoryFail(error))
    }
} 

export default TrainingHistoryAction