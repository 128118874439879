import {editCommuncationPending, editCommuncationSuccess, editCommuncationFail} from '../ClientSlices/EditCommunicationSlice'
import {updateCommunication} from '../../../api/clientApi'

 const editCommunication = (data) => async dispatch => {

    try {
        dispatch(editCommuncationPending());
        const result = await updateCommunication(data);
        if(result){
            return dispatch(editCommuncationSuccess(result))
        }
    } catch (error) {
        dispatch(editCommuncationFail(error))
    }
} 

export default editCommunication