import { getClientBirthdays } from "../../../api/clientApi";
import { getClientBirthdaysFail, getClientBirthdaysPending, getClientBirthdaysSuccess } from "../ClientSlices/GetClientBirthdaysSlice";


 const fetchClientBirthdays = (data) => async dispatch => {

    try {
        dispatch(getClientBirthdaysPending());
        const result = await getClientBirthdays(data);
        if(result){
            return dispatch(getClientBirthdaysSuccess(result))
        }
    } catch (error) {
        dispatch(getClientBirthdaysFail(error))
    }
} 

export default fetchClientBirthdays