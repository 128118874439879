import React, { useState } from "react";
import editClientCarePlan from '../ClientActions/EditCarePlansAction'
import editPrescriptionDoc from '../ClientActions/EditPrescriptionAction'
import MarchartDetails from './MarChartDetails';
import MarchartAuditTrail from './MarChartAuditTrail';
import MedicationAdministrationRecord from './MedicationAdministrationRecord';
import AuditTrail from './AuditTrail';
import { useDispatch, useSelector } from "react-redux";
import { FaTelegramPlane } from "react-icons/fa";
import PageLoader from "../../../components/PageLoader";

function MedicalReports({e,client}) {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = React.useState(false);

  const handleOpen = (id) => {
    setShowModal(true);
  };

  const med  = e ? e : '';
 
  const [active, setActive] = React.useState('details');


  let content;
  switch (active) {      
      case 'details':        
        content = <MarchartDetails e={med} setShowModal={setShowModal} client={client} />
        break;
      case 'audit':
        content = <AuditTrail e={med.id} />
      break   
      case 'administration':
       content = <MedicationAdministrationRecord e={med.id} />
      break      
      default:
        break;
    }  

  return (
    <>
      <button
        className="bg-blue-500 border hover:bg-white text-white font-medium hover:text-button inline-flex py-1 px-4 rounded shadow shadow-blue-200"
        type="button"
        onClick={() => handleOpen()}
      >
        View more <FaTelegramPlane size={20} className="mt-1 ml-2" />
      </button>

      {showModal ? (
        <>
          <div className="justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-7xl">
              <div className="border-0 rounded-lg shadow-lg relative w-full bg-white outline-none focus:outline-none">
                <div className="items-start justify-between p-5 flex border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-xl font-semibold text-gray-500 text-left">
                    Medical Reports
                  </h3>
                  <button
                    className="p-1 ml-10  bg-transparent border-0 text-button float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    ×
                  </button>
                </div>
               
                <div className="relative p-6 flex-auto h-full">
  <div className='flex justify-between'>
    <div>
      <ul className="flex flex-wrap font-medium pt-5">
        <li className="mr-2">
          <button onClick={() => setActive('details')} className={`inline-block p-3 focus:outline-none rounded-t-lg border-b-2 ${active === 'details' ? "border-secondary text-white dark:text-button dark:border-button bg-button" : "border-transparent hover:text-button hover:border-button dark:hover:text-gray-300"}`}>Details</button>
        </li>
        <li className="mr-2">
          <button onClick={() => setActive('administration')} className={`inline-block p-3 focus:outline-none rounded-t-lg border-b-2 ${active === 'administration' ? "border-secondary text-white dark:text-button dark:border-button bg-button" : "border-transparent hover:text-button hover:border-button dark:hover:text-gray-300"}`}>Administration Record</button>
        </li>
        <li className="mr-2">
          <button onClick={() => setActive('audit')} className={`inline-block p-3 focus:outline-none rounded-t-lg border-b-2 ${active === 'audit' ? "border-secondary text-white dark:text-button dark:border-button bg-button" : "border-transparent hover:text-button hover:border-button dark:hover:text-gray-300"}`}>Audit Trail</button>
        </li>
       
      </ul>
    </div>
 
  </div>
  <div className="">
    {content}
  </div>
</div>
              </div>
            </div>
          </div>

          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}

export default MedicalReports;


