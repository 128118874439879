import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  EditPermit: {},
  isLoadingEditPermit: false,
  errorEditPermit: "",
};

const EditPermitSlice = createSlice({
  name: "EditPermit",
  initialState,
  reducers: {
    EditPermitPending: (state) => {
      state.isLoadingEditPermit = true;
    },
    EditPermitSuccess: (state, { payload }) => {
      state.isLoadingEditPermit = false;
      state.EditPermit = payload;
      state.errorEditPermit = "";
    },
    EditPermitFail: (state, { payload }) => {
      state.isLoadingEditPermit = false;
      state.errorEditPermit = payload;
    },
  },
});

export const { EditPermitPending, EditPermitSuccess, EditPermitFail } =
  EditPermitSlice.actions;

export default EditPermitSlice.reducer;
