import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    getClientBirthdays: {},
    isLoadingGetClientBirthdays: false,
    errorGetClientBirthdays: ''
}

const getClientBirthdaysSlice = createSlice({
    name: 'getClientBirthdays',
    initialState,
    reducers:{
        getClientBirthdaysPending: (state) => {
            state.isLoadingGetClientBirthdays = true;
        },
        getClientBirthdaysSuccess: (state, {payload}) => {
            state.isLoadingGetClientBirthdays = false;
            state.getClientBirthdays = payload;
            state.errorGetClient = '';
        },
        getClientBirthdaysFail: (state, {payload}) => {
            state.isLoadingGetClientBirthdays = false;
            state.errorGetClientBirthdays = payload;
        }
    }
})

export const {getClientBirthdaysPending, getClientBirthdaysSuccess, getClientBirthdaysFail} = getClientBirthdaysSlice.actions

export default getClientBirthdaysSlice.reducer