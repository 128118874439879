import React from "react";
import FormikController from '../../../components/Formik/FormikController';
import SubmitButton from '../../../components/Formik/SubmitButton';
import { Formik, Form, Field} from "formik";
import * as Yup from "yup";

const VisitDetails = ({shift}) => {
    

  
  const [showModal, setShowModal] = React.useState(false);

  const initialValues = { notes: false
  }

  const validationSchema = Yup.object({
  })

  const handleSubmit = (values) => {

  }

  const handleOpen = (id) => {
    setShowModal(true);
  };

  const Head = (props) => {
    return (
      <div className="text-left border-b mb-1 bg-indigo-50 rounded p-2">
        <label className="text-md font-bold text-gray-700">{props.title}</label>
      </div>
    );
  };

  const handleDate = (date) => {
    let format = new Date(date);
    return ("0"+format.getDate()).slice(-2)+'/'+("0"+(format.getMonth()+1)).slice(-2)+'/'+format.getFullYear()+' '+format.getHours()+':'+format.getMinutes();
}

  const convertTime = (date) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = new Date(date).toLocaleDateString("en-US", options);
    return formattedDate;
  };
  
  let content = shift && <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
  <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                Client Name
              </th>
              <th scope="col" className="px-6 py-3">
                Care Worker
              </th>
              <th scope="col" className="px-6 py-3">
                Clock In
              </th>
              <th scope="col" className="px-6 py-3">
                Start Break
              </th>
              <th scope="col" className="px-6 py-3">
                Finish Break
              </th>
              <th scope="col" className="px-6 py-3">
                Clock Out
              </th>
            </tr>
          </thead>
<tbody>
<tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-primary dark:hover:bg-gray-600">
                <th scope="row" className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                    {shift.client.title} {shift.client.firstname} {shift.client.lastname}
                </th>
                <td className="px-6 py-4">
                    {shift.carer.firstname } { shift.carer.lastname }
                </td>
                <td className="px-6 py-4">
                    {shift.clockIn ? handleDate(shift.clockIn) : ''}
                </td>
                <td className="px-6 py-4">
                    {shift.breakStart ? handleDate(shift.breakStart) : '---'}
                </td>
                <td className="px-6 py-4">
                    {shift.breakEnds ? handleDate(shift.breakEnds) : '---'}
                </td>
                <td className="px-6 py-4">
                {shift.clockOut ? handleDate(shift.clockOut) : '---'}
                </td>
                
           </tr>
</tbody>
</table>


  return (
    <>
      <button
        className="p-1 text-white bg-orange-500 border hover:bg-white hover:bg-white hover:text-gray-700 hover:border hover:border-gray-300 hover:underline decoration-secondary focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        type="button"
        onClick={() => handleOpen()}
      >
      View More  
      </button>
      {showModal ? (
        <>
          <div className="justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-5xl">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-xl font-semibold text-gray-500">
                    <span className="text-green">Visit Details</span>                   
                  </h3>
                  <button
                    className="p-1 ml-10 bg-transparent border-0 text-button float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    ×
                  </button>
                </div>

                <div className="relative p-6 flex-auto">
                <Head title="SHIFT NOTES" />
                   {shift && shift.notes.length > 0 ? shift.notes.map((e)=>{
                    return <div className="mb-5 border rounded p-5 ">
                    <div className="text-left text-green font-bold mb-2">{e.carer.firstname} {e.carer.lastname}</div>
                    <div className="flex justify-between text-gray-600 border rounded p-3 ">
                    <div>{e.comments}</div> <div>{handleDate(e.createdAt)}</div>
                    </div>
                    <div>
               
                </div>
                </div>
                   }):<div className="text-center p-2 text-red-700 rounded bg-red-50 mb-5">No Notes Found !</div>}
                   
               
                  <Head title="SHIFT DETAILS" />
                  <div className="">
                    <div class="overflow-x-auto relative border rounded p-5">
                      {content}
                    </div>
                    {/* <div className="mt-10">
                      <Head title="MEDICATIONS" />
                    </div> */}
                    {/* <div className="border rounded p-5">
                      <div>
                        <HeaderTime title="MORNING" />
                      </div>
                      <div className="grid grid-cols-2 gap-2">
                        <div className=" flex justify-between items-center w-full bg-white border p-5 rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-700 mt-3">
                          <div className="text-left text-gray-500">
                            <div className="font-bold text-lg ">
                              2. CO-BENELDOPA 100mg/50mg
                            </div>
                            <div>Take four tablets three times a day</div>
                            <div>Tablet</div>
                            <div>Oral</div>
                          </div>
                          <div className="border rounded p-3 bg-green text-white">
                            Taken
                          </div>
                        </div>

                        <div className=" flex justify-between items-center w-full bg-white border p-5  rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-700 mt-3">
                          <div className="text-left text-gray-500">
                            <div className="font-bold text-lg">
                              2. CO-BENELDOPA 100mg/50mg
                            </div>
                            <div>Take four tablets three times a day</div>
                            <div>Tablet</div>
                            <div>Oral</div>
                          </div>
                          <div className="border rounded p-3 bg-green text-white">
                            Taken
                          </div>
                        </div>
                      </div>
                      <div>
                        <div>
                          <HeaderTime title="LUNCH TIME" />
                        </div>
                        <div className="grid grid-cols-2 gap-2">
                          <div className=" flex justify-between items-center w-full bg-white border p-5 rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-700 mt-3">
                            <div className="text-left text-gray-500">
                              <div className="font-bold text-lg">
                                2. CO-BENELDOPA 100mg/50mg
                              </div>
                              <div>Take four tablets three times a day</div>
                              <div>Tablet</div>
                              <div>Oral</div>
                            </div>
                            <div className="border rounded p-3 border-red-500 font-semibold text-red-500">
                              Not Entered
                            </div>
                          </div>

                          <div className=" flex justify-between items-center w-full bg-white border p-5 rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-700 mt-3">
                            <div className="text-left text-gray-500">
                              <div className="font-bold text-lg">
                                CO- AMOXICLAV 10mg
                              </div>
                              <div>Take four tablets three times a day</div>
                              <div>Tablet</div>
                              <div>Oral</div>
                              <div className="text-red-500">
                                Reason : Client Refused to take pills{" "}
                              </div>
                            </div>
                            <div className="border rounded p-3 bg-red-600 text-white">
                              Not Taken
                            </div>
                          </div>
                        </div>
                     
                        <div></div>
                      </div>
                    
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
};

export default VisitDetails;


 {/* <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={(values,{resetForm}) => {handleSubmit(values);
              resetForm();
            }}
          >
            {({values}) => (
              <Form className='mt-2'>
                
                <label>
                    <Field
                      type="checkbox"
                      name="notes"
                      value={true}
                      checked={values.notes === true}
                    />
                    <span className="ml-2 text-md font-bold text-gray-500 ">
                      Add Notes
                    </span>
                  </label>
            { values.notes &&   <div className='grid grid-cols-12 gap-2 border-b'>
                  <div className='col-span-9'>
                    <FormikController
                      control='input'
                      type='text'
                      name='name'
                      label=' Notes'
                    />
                  </div>
                  
                  <div className=" col-span-3 pt-2">
                    <SubmitButton label='Send' />
                  </div>
                </div>}
              </Form>

            )
        }
      </Formik> */}