import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    editClientCarers: {},
    isLoadingEditCarer: false,
    errorEditClientCarer: ''
}

const editClientCarersSlice = createSlice({
    name: 'editClientCarers',
    initialState,
    reducers:{
        editClientCarersPending: (state) => {
            state.isLoadingEditCarer = true;
        },
        editClientCarersSuccess: (state, {payload}) => {
            state.isLoadingEditCarer = false;
            state.editClientCarers = payload;
            state.errorEditClientCarer = '';
        },
        editClientCarersFail: (state, {payload}) => {
            state.isLoadingEditCarer = false;
            state.errorEditClientCarer = payload;
        }
    }
})

export const {editClientCarersPending, editClientCarersSuccess, editClientCarersFail} = editClientCarersSlice.actions

export default editClientCarersSlice.reducer