import React from 'react'
import LogoImage from '../assets/images/tlogo.png'
import { Link } from 'react-router-dom'

function TransparentLogo(props) {
  return (<Link to='/'> <div><img src={LogoImage} alt='' className={props.className} width={150} /></div></Link>
    
  )
}

export default TransparentLogo