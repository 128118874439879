import {getClientTierPending, getClientTierSuccess, getClientTierFail} from '../ConfigSlices/GetClientTierSlice'
import {getClientTiers} from '../../../api/clientApi'

 const fetchClientTier = (data) => async dispatch => {

    try {
        dispatch(getClientTierPending());
        const result = await getClientTiers(data);
        if(result){
            return dispatch(getClientTierSuccess(result))
        }
    } catch (error) {
        dispatch(getClientTierFail(error))
    }
} 

export default fetchClientTier