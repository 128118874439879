import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    getRegulatorCarers: {},
    isLoadinggetRegulatorCarers: false,
    errorgetRegulatorCarers: ''
}

const getRegulatorCarersSlice = createSlice({
    name: 'getRegulatorCarers',
    initialState,
    reducers:{
        getRegulatorCarersPending: (state) => {
            state.isLoadinggetRegulatorCarers = true;
        },
        getRegulatorCarersSuccess: (state, {payload}) => {
            state.isLoadinggetRegulatorCarers = false;
            state.getRegulatorCarers = payload;
            state.errorgetRegulatorCarers = '';
        },
        getRegulatorCarersFail: (state, {payload}) => {
            state.isLoadinggetRegulatorCarers = false;
            state.errorgetRegulatorCarers = payload;
        }
    }
})

export const {getRegulatorCarersPending, getRegulatorCarersSuccess, getRegulatorCarersFail} = getRegulatorCarersSlice.actions

export default getRegulatorCarersSlice.reducer