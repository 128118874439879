import { getAssignedMarcharts } from "../../../api/carePlanApi";
import { assignedMarchartsPending, getAssignedMarchartsFail, getAssignedMarchartsSuccess } from "../DashboardSlices/AssignedSlice";


 const AssignedAction = (data) => async dispatch => {

    try {
        dispatch(assignedMarchartsPending());
        const result = await getAssignedMarcharts(data);
        if(result){
            return dispatch(getAssignedMarchartsSuccess(result))
        }
    } catch (error) {
        dispatch(getAssignedMarchartsFail(error))
    }
} 

export default AssignedAction