import {addCarerTierPending, addCarerTierSuccess, addCarerTierFail} from '../ConfigSlices/AddCarerTierSlice'
import {addCarerTiers} from '../../../api/clientApi'

 const addCarerTier = (data) => async dispatch => {

    try {
        dispatch(addCarerTierPending());
        const result = await addCarerTiers(data);
        if(result){
            return dispatch(addCarerTierSuccess(result))
        }
    } catch (error) {
        dispatch(addCarerTierFail(error))
    }
} 

export default addCarerTier