import {updateCarePlanPending, updateCarePlanSuccess, updateCarePlanFail} from '../carePlanSlices/updateCarePlanSlice'
import {updateCarePlan} from '../../../api/carePlanApi'

 const updateCarePlann = (data) => async dispatch => {

    try {
        dispatch(updateCarePlanPending());
        const result = await updateCarePlan(data);
        if(result){
            return dispatch(updateCarePlanSuccess(result))
        }
    } catch (error) {
        dispatch(updateCarePlanFail(error))
    }
} 

export default updateCarePlann