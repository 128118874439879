import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  personalDetails: {},
  isLoadingPersonal: false,
  errorPersonal: "",
};

const personalDetailsSlice = createSlice({
  name: "personalDetails",
  initialState,
  reducers: {
    personalDetailsPending: (state) => {
      state.isLoadingPersonal = true;
    },
    personalDetailsSuccess: (state, { payload }) => {
      state.isLoadingPersonal = false;
      state.personalDetails = payload;
      state.errorPersonal = "";
    },
    personalDetailsFail: (state, { payload }) => {
      state.isLoadingPersonal = false;
      state.errorPersonal = payload;
    },
  },
});

export const { personalDetailsPending, personalDetailsSuccess, personalDetailsFail } = personalDetailsSlice.actions;

export default personalDetailsSlice.reducer;
