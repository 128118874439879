import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  updateTraining: {},
  isLoadingupdateTraining: false,
  errorupdateTraining: "",
};

const updateTrainingSlice = createSlice({
  name: "updateTraining",
  initialState,
  reducers: {
    updateTrainingPending: (state) => {
      state.isLoadingupdateTraining = true;
    },
    updateTrainingSuccess: (state, { payload }) => {
      state.isLoadingupdateTraining = false;
      state.updateTraining = payload;
      state.errorupdateTraining = "";
    },
    updateTrainingFail: (state, { payload }) => {
      state.isLoadingupdateTraining = false;
      state.errorupdateTraining = payload;
    },
  },
});

export const { updateTrainingPending, updateTrainingSuccess, updateTrainingFail } =
  updateTrainingSlice.actions;

export default updateTrainingSlice.reducer;
