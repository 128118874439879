import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    getMedicationRecords: {},
    isLoadingGetMedicationRecords: false,
    errorGetMedicationRecords: ''
}

const getMedicationRecordsSlice = createSlice({
    name: 'getMedicationRecords',
    initialState,
    reducers:{
        getMedicationRecordsPending: (state) => {
            state.isLoadingGetMedicationRecords = true;
        },
        getMedicationRecordsSuccess: (state, {payload}) => {
            state.isLoadingGetMedicationRecords = false;
            state.getMedicationRecords = payload;
            state.errorGetMedicationRecords = '';
        },
        getMedicationRecordsFail: (state, {payload}) => {
            state.isLoadingGetMedicationRecords = false;
            state.errorGetMedicationRecords = payload;
        }
    }
})

export const {getMedicationRecordsPending, getMedicationRecordsSuccess, getMedicationRecordsFail} = getMedicationRecordsSlice.actions

export default getMedicationRecordsSlice.reducer