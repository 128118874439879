import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    getInvoiceDetails: {},
    isLoadingGetInvoice: false,
    errorGetInvoiceDetails: ''
}

const getInvoiceDetailsSlice = createSlice({
    name: 'getInvoiceDetails',
    initialState,
    reducers:{
        getInvoiceDetailsPending: (state) => {
            state.isLoadingGetInvoiceDetails = true;
        },
        getInvoiceDetailsSuccess: (state, {payload}) => {
            state.isLoadingGetInvoiceDetails = false;
            state.getInvoiceDetails = payload;
            state.errorGetInvoiceDetails = '';
        },
        getInvoiceDetailsFail: (state, {payload}) => {
            state.isLoadingGetInvoiceDetails = false;
            state.errorGetInvoiceDetails = payload;
        }
    }
})

export const {getInvoiceDetailsPending, getInvoiceDetailsSuccess, getInvoiceDetailsFail} = getInvoiceDetailsSlice.actions

export default getInvoiceDetailsSlice.reducer