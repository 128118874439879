import {getCarePlanPending, getCarePlanSuccess, getCarePlanFail} from '../carePlanSlices/getCarePlanSlice'
import {getCarePlan} from '../../../api/carePlanApi'

 const getCarePlann = (data) => async dispatch => {

    try {
        dispatch(getCarePlanPending());
        const result = await getCarePlan(data);
        if(result){
            return dispatch(getCarePlanSuccess(result))
        }
    } catch (error) {
        dispatch(getCarePlanFail(error))
    }
} 

export default getCarePlann