import {updateRegulatorCarersStatusPending, updateRegulatorCarersStatusSuccess, updateRegulatorCarersStatusFail} from '../staffSlices/updateRegulatorCarersStatusSlice'
import {updateRegulatorCarerStatus} from '../../../api/staffApi'

 const updateRegulatorCarersStatusAction = (data) => async dispatch => {

    try {
        dispatch(updateRegulatorCarersStatusPending());
        const result = await updateRegulatorCarerStatus(data);
        if(result){
            return dispatch(updateRegulatorCarersStatusSuccess(result))
        }
    } catch (error) {
        dispatch(updateRegulatorCarersStatusFail(error))
    }
}

export default updateRegulatorCarersStatusAction