import axios from "axios";
import { Navigate } from "react-router-dom";

const BASE_URI = process.env.REACT_APP_ELMS_API_URL

export const getTimesheets = async (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = sessionStorage.getItem('access_token')
            const res = await axios.get(`${BASE_URI}timesheets?page=${data.page}&perPage=${data.perPage}&status=${data.status}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            resolve(res.data);

        } catch (error) {
            if(error.response.data.statusCode === 404){
                return null
             }
             if(error.response.data.statusCode === 401){
                <Navigate replace to="/login"/>
             }
            
            
             reject(error)
        }
    })
}


export const getShiftType = async (data) => {

    return new Promise(async (resolve, reject) => {
        try {
            const token = sessionStorage.getItem('access_token')
            const res = await axios.get(`${BASE_URI}timesheets/shift/type?carerId=${data.carerId}&clientId=${data.clientId}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            resolve(res.data);

        } catch (error) {
            if(error.response.data.statusCode === 404){
                return null
             }
             if(error.response.data.statusCode === 401){
                <Navigate replace to="/login"/>
             }
            
            
             reject(error)
        }
    })
}



export const useUpdateTimesheet = async (data) => {

    return new Promise(async (resolve, reject) => {
        try {
            const token = sessionStorage.getItem('access_token')
            const res = await axios.patch(`${BASE_URI}timesheets/${data.id}`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })           
            resolve(res.data);

        } catch (error) {
            if(error.response.data.statusCode === 401){
                <Navigate replace to="/login"/>
             }
            
            reject(error)
        }
    })
}
