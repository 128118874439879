import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    getShiftType: {},
    isLoadingShift: false,
    errorGetTimesheetsShift: ''
}

const getShiftSlice = createSlice({
    name: 'getShiftType',
    initialState,
    reducers:{
        getShiftPending: (state) => {
            state.isLoadingShift = true;
        },
        getShiftSuccess: (state, {payload}) => {
            state.isLoadingGetShift = false;
            state.getShiftType = payload;
            state.errorGetTimesheets = '';
        },
        getShiftFail: (state, {payload}) => {
            state.isLoadingGetShift = false;
            state.errorGetShift = payload;
        }
    }
})

export const {getShiftPending, getShiftSuccess, getShiftFail} = getShiftSlice.actions

export default getShiftSlice.reducer