import React from "react";
import FormikController from "../../../components/Formik/FormikController";
import SubmitButton from "../../../components/Formik/SubmitButton";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import PageLoader from "../../../components/PageLoader";
import editContracts from "../ClientActions/EditContractsAction";
import editReferral from "../ClientActions/EditReferralAction";
import editCommunication from "../ClientActions/EditCommunicationAction";
import editContractDoc from "../ClientActions/EditClientContractAction";
import ClientInfo from "../ClientActions/GetClientInfoAction";
import addContracts from "../ClientActions/ClientContractsAction";

function Contracts({ client }) {
  const dispatch = useDispatch();
  const { getClientInfo, isLoadinggetClientInfo } = useSelector(
    (state) => state.GetClientInfoSlice
  );
  const { editContract, isLoadingEditDoc, errorEditDoc } = useSelector(
    (state) => state.updateContractDoc
  );
  const { editContractInfo, isLoadingContracts, errorEditContracts } =
    useSelector((state) => state.updateContracts);
  const { editReferralDoc, isLoadingReferral, errorEditReferral } = useSelector(
    (state) => state.updateReferral
  );
  const {
    editCommunicationDoc,
    isLoadingCommunication,
    errorEditCommunication,
  } = useSelector((state) => state.updateCommunication);
  const {clientContracts, isLoadingClientContracts, errorClientContracts } = useSelector(
    (state) => state.addContract
  );
  const { getClientTiers } = useSelector((state) => state.getClientTier);

  const referralValues = {
    clientTierType: "",
    referral: "",
    contract: "",
    contractNumber:
      getClientInfo && getClientInfo.contract
        ? getClientInfo.contract.contractNumber
        : "",
    contractType:
      getClientInfo && getClientInfo.contract
        ? getClientInfo.contract.contractType
        : "",
    contractDailyRate:
      getClientInfo && getClientInfo.contract
        ? getClientInfo.contract.contractDailyRate
        : "",
    contractHourlyRate:
      getClientInfo && getClientInfo.contract
        ? getClientInfo.contract.contractHourlyRate
        : "",
    ContractWeeklyDays:
      getClientInfo && getClientInfo.contract
        ? getClientInfo.contract.ContractWeeklyDays
        : "",
    ContractWeeklyHours:
      getClientInfo && getClientInfo.contract
        ? getClientInfo.contract.ContractWeeklyHours
        : "",
    site:
      getClientInfo && getClientInfo.contract
        ? getClientInfo.contract.site
        : "",
    startDate:
      getClientInfo && getClientInfo.contract
        ? getClientInfo.contract.startDate
        : "",
    endDate:
      getClientInfo && getClientInfo.contract
        ? getClientInfo.contract.endDate
        : "",
    communicationFiles: "",
  };

  const contractValues = {
    contract: "",
  };

  const communValues = {
    communicationFiles: "",
  };

  const initialValues = {
    contractNumber:
      getClientInfo && getClientInfo.contract
        ? getClientInfo.contract.contractNumber
        : "",
    contractType:
      getClientInfo && getClientInfo.contract
        ? getClientInfo.contract.contractType
        : "",
    contractDailyRate:
      getClientInfo && getClientInfo.contract
        ? getClientInfo.contract.contractDailyRate
        : "",
    contractHourlyRate:
      getClientInfo && getClientInfo.contract
        ? getClientInfo.contract.contractHourlyRate
        : "",
    ContractWeeklyDays:
      getClientInfo && getClientInfo.contract
        ? getClientInfo.contract.ContractWeeklyDays
        : "",
    ContractWeeklyHours:
      getClientInfo && getClientInfo.contract
        ? getClientInfo.contract.ContractWeeklyHours
        : "",
    site:
      getClientInfo && getClientInfo.contract
        ? getClientInfo.contract.site
        : "",
    startDate:
      getClientInfo && getClientInfo.contract
        ? getClientInfo.contract.startDate
        : "",
    endDate:
      getClientInfo && getClientInfo.contract
        ? getClientInfo.contract.endDate
        : "",
  };

  let clientTiers =
    getClientTiers && getClientTiers.data
      ? getClientTiers.data.map((cxt) => {
          let obj = {
            key: cxt.type,
            value: cxt.id,
          };
          return obj;
        })
      : [];

  const FILE_SIZE = 1024 * 1024 * 10;
  const SUPPORTED_FORMATS = [
    "application/pdf",
    "image/jpg",
    "image/jpeg",
    "image/png",
  ];

  const validationSchema = Yup.object({
    communicationFiles: Yup.mixed()
      .test(
        "format",

        "Only the following formats are accepted: .pdf, .jpeg, .jpg, .png",

        (value) => !value || (value && SUPPORTED_FORMATS.includes(value.type))
      )
      .test(
        "fileSize",

        "File too large",

        (value) =>
          value === null || ((value) => value && value.size <= FILE_SIZE)
      ),

    referral: Yup.mixed()
      .test(
        "format",

        "Only the following formats are accepted: .pdf, .jpeg, .jpg, .png",

        (value) => !value || (value && SUPPORTED_FORMATS.includes(value.type))
      )
      .test(
        "fileSize",

        "File too large",

        (value) =>
          value === null || ((value) => value && value.size <= FILE_SIZE)
      ),
    contract: Yup.mixed()
      .test(
        "format",

        "Only the following formats are accepted: .pdf, .jpeg, .jpg, .png",

        (value) => !value || (value && SUPPORTED_FORMATS.includes(value.type))
      )
      .test(
        "fileSize",

        "File too large",

        (value) =>
          value === null || ((value) => value && value.size <= FILE_SIZE)
      ),
  });

  console.log("getClientInfo", getClientInfo);
  const handleSubmit = (data) => {
    data.id = client.contract.id;
    dispatch(editContracts(data));
  };

  const handleReferral = (data) => {
    data.id = client.contract.id;
    dispatch(editReferral(data));
  };

  const handleContract = (data) => {
    console.log("VALL", data);
    data.id = client.contract.id;
    dispatch(editContractDoc(data));
  };

  const handleCommunDoc = (data) => {
    data.id = client.contract.id;
    dispatch(editCommunication(data));
  };

  const handleSubmitPost = (data) => {
    data.id = client.id;
    dispatch(addContracts(data));
  };
  React.useEffect(() => {
    dispatch(
      ClientInfo({
        id: client?.id,
      })
    );
  }, [editContract, editContractInfo, editReferralDoc, editCommunicationDoc, clientContracts]);

  const Head = (props) => {
    return (
      <div className="border-b mb-5 text-left">
        <label className="text-xl font-medium text-gray-700 ">
          {props.title}
        </label>
      </div>
    );
  };
  if (
    !getClientInfo?.contract?.contractNumber &&
    !getClientInfo?.contract?.contractType &&
    !getClientInfo?.contract?.contractDailyRate &&
    !getClientInfo?.contract?.contractHourlyRate &&
    !getClientInfo?.contract?.ContractWeeklyDays &&
    !getClientInfo?.contract?.ContractWeeklyHours &&
    !getClientInfo?.contract?.site &&
    !getClientInfo?.contract?.startDate &&
    !getClientInfo?.contract?.endDate
  ) {
    return (
     <div className=" border p-10">
       <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          handleSubmitPost(values);
        }}
      >
        {({ setFieldValue, ...rest }) => (
          <Form className="text-left">
            <div className=" rounded">
              <Head title="Referral" />
              <FormikController
                control="input"
                type="file"
                name="referral"
                label="Upload Referral"
                value={null}
                onChange={(event) =>
                  setFieldValue("referral", event.target.files[0])
                }
              />
              <div className="mt-10">
                <Head title="Contracts" />
              </div>
              <div className="grid grid-cols-2 gap-2">
                <FormikController
                  control="input"
                  type="file"
                  name="contract"
                  label="Upload Service Contract"
                  value={null}
                  onChange={(event) =>
                    setFieldValue("contract", event.target.files[0])
                  }
                />

                <FormikController
                  control="input"
                  type="text"
                  name="contractNumber"
                  label="Contract No."
                />

                <FormikController
                  control="input"
                  type="text"
                  name="contractType"
                  label="Contract Type"
                />

                <FormikController
                  control="input"
                  type="text"
                  name="contractDailyRate"
                  label="Contract Daily Rate"
                />

                <FormikController
                  control="input"
                  type="text"
                  name="contractHourlyRate"
                  label="Contract Hourly Rate"
                />

                <FormikController
                  control="input"
                  type="text"
                  name="ContractWeeklyDays"
                  label="Contract Weekly Days"
                  placeholder="eg. Monday, Tuesday, Wednesday ..."
                />

                <FormikController
                  control="input"
                  type="text"
                  name="ContractWeeklyHours"
                  label="Contract Weekly Hours"
                />
                <FormikController
                  control="input"
                  type="text"
                  name="site"
                  label="Site of Client"
                />
                <FormikController
                  control="input"
                  type="date"
                  name="startDate"
                  label="Start Date of Contract"
                />

                <FormikController
                  control="input"
                  type="date"
                  name="endDate"
                  label="End Date of Contract"
                />
              </div>
              <div className="mt-10">
                <Head title="Communication" />
              </div>
              <FormikController
                control="input"
                type="file"
                label="Upload Communication Files"
                name="communicationFiles"
                value={null}
                onChange={(event) =>
                  setFieldValue("communicationFiles", event.target.files[0])
                }
              />
              <div className="text-left mt-5">
                <FormikController
                  control="select"
                  type="select"
                  name="clientTierType"
                  label="Select Client Tier*"
                  options={clientTiers}
                />
              </div>
            </div>

            <div className="mb-3 mt-5 flex justify-between">
              {isLoadingClientContracts ? (
                <PageLoader />
              ) : (
                <SubmitButton label="Update" />
              )}
            </div>
          </Form>
        )}
      </Formik>
     </div>
    );
  }

  return isLoadinggetClientInfo ? (
    <PageLoader />
  ) : (
    <div className="w-full p-5">
      <Head title="Referral" />
      {getClientInfo?.contract?.referral ? (
        <iframe
          src={getClientInfo.contract ? getClientInfo.contract.referral : ""}
          frameborder="0"
          height="300"
          width="100%"
        ></iframe>
      ) : (
        <div
          class="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 text-center border border-red-200 "
          role="alert"
        >
          No Document Uploaded
        </div>
      )}
      <Formik
        initialValues={referralValues}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => { handleReferral(values)
          
          resetForm();
        }}
      >
        {({ setFieldValue, ...rest }) => (
          <Form className="text-left">
            <div className="border p-5 rounded">
              <FormikController
                control="input"
                type="file"
                name="referral"
                label="Upload Referral"
                value={null}
                onChange={(event) =>
                  setFieldValue("referral", event.target.files[0])
                }
              />
            </div>
            <div className=" flex flex-row-reverse">
              {isLoadingReferral ? (
                <PageLoader />
              ) : (
                <SubmitButton label="Update" />
              )}
            </div>
          </Form>
        )}
      </Formik>
      <Formik
        initialValues={contractValues}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          handleContract(values);
          resetForm();
        }}
      >
        {({ setFieldValue, ...rest }) => (
          <Form className="text-left">
            <div className="border p-5 rounded">
              <div className="">
                <Head title="Contracts" />
              </div>
              {getClientInfo?.contract?.contract ? (
                <iframe
                  src={getClientInfo?.contract?.contract}
                  frameborder="0"
                  height="300"
                  width="100%"
                ></iframe>
              ) : (
                <div
                  class="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 text-center border border-red-200"
                  role="alert"
                >
                  No Document Uploaded
                </div>
              )}

              <div className="border p-5 rounded">
                <FormikController
                  control="input"
                  type="file"
                  name="contract"
                  label="Upload Service Contract"
                  value={null}
                  onChange={(event) =>
                    setFieldValue("contract", event.target.files[0])
                  }
                />
              </div>
            </div>
            <div className=" flex flex-row-reverse">
              {isLoadingEditDoc ? (
                <PageLoader />
              ) : (
                <SubmitButton label="Update" />
              )}
            </div>
          </Form>
        )}
      </Formik>

      <Formik
        initialValues={communValues}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          handleCommunDoc(values);
          resetForm();
        }}
      >
        {({ setFieldValue, ...rest }) => (
          <Form className="text-left">
            <div className="border p-5 rounded">
              <Head title="Communication" />
              {getClientInfo?.contract?.communicationFiles ? (
                <iframe
                  src={getClientInfo?.contract?.communicationFiles}
                  frameborder="0"
                  height="300"
                  width="100%"
                ></iframe>
              ) : (
                <div
                  class="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 text-center border border-red-200"
                  role="alert"
                >
                  No Document Uploaded
                </div>
              )}
              <div className="border p-5 rounded">
                <FormikController
                  control="input"
                  type="file"
                  label="Upload Communication Files"
                  name="communicationFiles"
                  value={null}
                  onChange={(event) =>
                    setFieldValue("communicationFiles", event.target.files[0])
                  }
                />
              </div>
            </div>
            <div className=" flex flex-row-reverse">
              {isLoadingCommunication ? (
                <PageLoader />
              ) : (
                <SubmitButton label="Update" />
              )}
            </div>
          </Form>
        )}
      </Formik>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {({ setFieldValue, ...rest }) => (
          <Form className="text-left">
            <div className="border p-5 rounded">
              <div className="grid grid-cols-2 gap-2">
                <FormikController
                  control="input"
                  type="text"
                  name="contractNumber"
                  label="Contract No."
                />

                <FormikController
                  control="input"
                  type="text"
                  name="contractType"
                  label="Contract Type"
                />

                <FormikController
                  control="input"
                  type="text"
                  name="contractDailyRate"
                  label="Contract Daily Rate"
                />

                <FormikController
                  control="input"
                  type="text"
                  name="contractHourlyRate"
                  label="Contract Hourly Rate"
                />

                <FormikController
                  control="input"
                  type="text"
                  name="ContractWeeklyDays"
                  label="Contract Weekly Days"
                />

                <FormikController
                  control="input"
                  type="text"
                  name="ContractWeeklyHours"
                  label="Contract Weekly Hours"
                />
                <FormikController
                  control="input"
                  type="text"
                  name="site"
                  label="Site of Client"
                />
                <FormikController
                  control="input"
                  type="date"
                  name="startDate"
                  label="Start Date of Contract"
                />

                <FormikController
                  control="input"
                  type="date"
                  name="endDate"
                  label="End Date of Contract"
                />
              </div>
            </div>
            <div className="flex flex-row-reverse">
              {isLoadingContracts ? (
                <PageLoader />
              ) : (
                <SubmitButton label="Update" />
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default Contracts;
