import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    getAdministeredMedication: {},
    isLoadingGetAdministeredMedication: false,
    errorGetAdministeredMedication: ''
}

const getAdministeredMedicationSlice = createSlice({
    name: 'getAdministeredMedication',
    initialState,
    reducers:{
        getAdministeredMedicationPending: (state) => {
            state.isLoadingGetAdministeredMedication = true;
        },
        getAdministeredMedicationSuccess: (state, {payload}) => {
            state.isLoadingGetAdministeredMedication = false;
            state.getAdministeredMedication = payload;
            state.errorGetAdministeredMedication = '';
        },
        getAdministeredMedicationFail: (state, {payload}) => {
            state.isLoadingGetAdministeredMedication = false;
            state.errorGetAdministeredMedication = payload;
        }
    }
})

export const {getAdministeredMedicationPending, getAdministeredMedicationSuccess, getAdministeredMedicationFail} = getAdministeredMedicationSlice.actions

export default getAdministeredMedicationSlice.reducer