import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile } from "../../pages/dashboard/userAction";
import { ImSwitch } from "react-icons/im";
import { Link, useNavigate  } from "react-router-dom";
import { logout } from "../../api/userApi";
import SendInvite from "../../pages/carers/AddCarer/SendInvite";
import { AiOutlineCaretDown } from "react-icons/ai";


export const DashboardNav = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { myAccount } = useSelector((state) => state.updateAccount);
  const { user } = useSelector((state) => state.user);
  const mail = user ? user.email : "";
  const rol = user ? user.role : "";
  function DisplayCurrentTime() {
    var date = new Date();
    var hours = date.getHours() > 12 ? date.getHours() - 12 : date.getHours();
    var am_pm = date.getHours() >= 12 ? "PM" : "AM";
    hours = hours < 10 ? "0" + hours : hours;
    var minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    var seconds = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    let time = hours + ":" + minutes + ":" + seconds + " " + am_pm;
    let val = time.split(' ')[1];
    let hr = Number(time.slice(0, 2));
    
    var result;
    switch(val){
      case 'AM':
        result = 'Morning';
        break;
      case 'PM':
        if(hr > 5){
          result = 'Evening';
        }else{
          result = 'Afternoon';
        }
        break;
    }
    return result;
    
  }

  const ProfileDropdown = () => {
    const [isOpen, setIsOpen] = useState(false);
  
    const toggleDropdown = () => {
      setIsOpen(!isOpen);
    };
  
    return (
      <div className="relative inline-block text-left mt-1">
        <button onClick={toggleDropdown} className="text-gray-700 font-medium inline-flex">
        {user.firstname}{' '}{user.lastname} <AiOutlineCaretDown className="mt-1.5 ml-2"/>
        </button>
        {isOpen && (
          <div className="origin-top-right absolute right-0 mt-2 w-70 rounded-md shadow-lg bg-yellow-50 ring-1 ring-black ring-opacity-5 p-5 ">
             <Link to='/my-account' className="font-semibold text-button hover:text-blue-900 underline ">My Profile </Link>  
           <span className="text-sm">{mail}</span>
            
            {/* {rol &&
                      rol.charAt(0).toUpperCase() + rol.slice(1).toLowerCase()}<hr className="mt-2 mb-2"/> */}<hr className="mt-2 "/>
            <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
            <button onClick={()=> handleLogout()} className="text-transparent bg-clip-text bg-gray-700  inline-flex font-semibold inline-flex hover:bg-red-600 mt-2 text-sm">
                Sign Out  <ImSwitch size={15} className=' ml-1 mt-0.5 text-red-500'/>
            </button>
            </div>
          </div>
        )}
      </div>
    );
  };

  React.useEffect(() => {
    dispatch(getUserProfile());
  }, [myAccount]);

  const handleLogout = () => {
    logout()
    navigate('/', { replace: true });
  }

  return (
    <div className="nav-bar bg-top border-gray-300 shadow-md py-7 px-8 ">
      <div className="flex justify-between">
        <h1 class="text-lg ml-64 font-medium text-gray-900 dark:text-white text-left ">
          <span class="text-transparent bg-clip-text bg-gray-700 text-gray-700">
            {props.title}
          </span>
        </h1>
        <div className="inline-flex">
        <div><SendInvite/> </div>
        <div className="ml-5"><ProfileDropdown/></div>
        </div>
      </div>
    </div>
  );
};
