import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    addClientTiers: {},
    isLoadingClientTier: false,
    errorClientTier: ''
}

const addClientTierSlice = createSlice({
    name: 'addClientTiers',
    initialState,
    reducers:{
        addClientTierPending: (state) => {
            state.isLoadingClientTier = true;
        },
        addClientTierSuccess: (state, {payload}) => {
            state.isLoadingClientTier = false;
            state.addClientTiers = payload;
            state.errorClientTier = '';
        },
        addClientTierFail: (state, {payload}) => {
            state.isLoadingClientTier = false;
            state.errorClientTier = payload;
        }
    }
})

export const {addClientTierPending, addClientTierSuccess, addClientTierFail} = addClientTierSlice.actions

export default addClientTierSlice.reducer