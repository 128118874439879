import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import PageLoader from '../../components/PageLoader';
import Pagination from "react-js-pagination";
import { getStaffBirthday } from './staffActions/staffBirthdaysAction';

function Birthdays() {
    const dispatch = useDispatch();
    const { staffBirthdays, isLoading } = useSelector(state => state.getbirthday)


    const itemsCountPerPage = 10;

    const [page, setPage] = React.useState(1);

    const handlePageChange = (pageNumber) => {
        setPage(pageNumber);
    }

    React.useEffect(() => {
        dispatch(getStaffBirthday());
    },[])
const staff = staffBirthdays;
const handleBirthday = (date) => {
    const birthday  = new Date(date);
    return birthday.getDate().toString().padStart(2, '0')+'/'+(birthday.getMonth() + 1).toString().padStart(2, '0')+'/'+birthday.getFullYear()
}

    let content = isLoading ? <div className=''><PageLoader /></div> : staff && <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
                <th scope="col" className="px-6 py-3">
                    Name
                </th>       
                <th scope="col" className="px-6 py-3">
                    Phone Number
                </th>
                <th scope="col" className="px-6 py-3">
                    Email
                </th>            
                <th scope="col" className="px-6 py-3">
                   Date Of Birth
                </th>               
            </tr>
        </thead>
        <tbody>
            {(staff && staff.data && staff.data.length > 0) ? staff.data.map((staff) => (
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-primary dark:hover:bg-gray-600">
                    <th scope="row" className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                        {staff.firstname} {staff.lastname}
                    </th>                   
                    <td className="px-6 py-4">
                        {staff.mobile}
                    </td>
                    <td className="px-6 py-4">
                        {staff.email}
                    </td>
                    <td className="px-6 py-4">
                        {handleBirthday(staff.dob)}
                    </td>              
                   
                </tr>
            )) : <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                <td colSpan="9" className="text-center px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap border rounded bg-red-100">No Data Found</td>
            </tr>}
        </tbody>
    </table>

    return (
        <div className='ml-72 mr-8 mt-10'>
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                {content}
            </div>
            <div>  
                 {(staff && staff.data && staff.data.length > 0 && !isLoading) &&
                    <div className="p-4 flex justify-end">
                        <Pagination
                            activePage={page}
                            itemsCountPerPage={itemsCountPerPage}
                            totalItemsCount={staff.totalItemsCount}
                            pageRangeDisplayed={1}
                            onChange={handlePageChange}
                        />
                    </div>}
                    </div>
        </div>
    )
}

export default Birthdays