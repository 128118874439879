import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    clockOutOverdue: {},
    isLoadingClockOutOverdue: false,
    errorClockOutOverdue: ''
}

const clockOutOverdueSlice = createSlice({
    name: 'clockOutOverdue',
    initialState,
    reducers:{
        clockOutOverduePending: (state) => {
            state.isLoadingClockOutOverdue = true;
        },
        clockOutOverdueSuccess: (state, {payload}) => {
            state.isLoadingClockOutOverdue = false;
            state.clockOutOverdue = payload;
            state.errorClockOutOverdue = '';
        },
        clockOutOverdueFail: (state, {payload}) => {
            state.isLoadingClockOutOverdue = false;
            state.errorClockOutOverdue = payload;
        }
    }
})

export const {clockOutOverduePending, clockOutOverdueSuccess, clockOutOverdueFail} = clockOutOverdueSlice.actions

export default clockOutOverdueSlice.reducer