import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    getStaffDbsApplications: {},
    isLoadingGetCarers: false,
    errorGetCarers: ''
}


const getStaffDbsSlice = createSlice({
    name: 'getStaffDbsApplications',
    initialState,
    reducers:{
        getStaffDbsPending: (state) => {
            state.isLoadingGetCarers = true;
        },
        getStaffDbsSuccess: (state, {payload}) => {
            state.isLoadingGetCarers = false;
            state.getStaffDbsApplications = payload;
            state.errorGetCarers = '';
        },
        getStaffDbsFail: (state, {payload}) => {
            state.isLoadingGetCarers = false;
            state.errorGetCarers = payload;
        }
    }
})



export const {getStaffDbsPending, getStaffDbsSuccess, getStaffDbsFail} = getStaffDbsSlice.actions

export default getStaffDbsSlice.reducer
