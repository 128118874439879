import {getStaffPending, getStaffSuccess, getStaffFail} from '../staffSlices/staffSlice'
import {getStaffUsers} from '../../../api/staffApi'

export const getStaff = (data) => async dispatch => {

    try {
        dispatch(getStaffPending());
        const result = await getStaffUsers(data);
        if(result){
            return dispatch(getStaffSuccess(result))
        }
    } catch (error) {
        dispatch(getStaffFail(error))
    }
}