import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    updateTimesheet: {},
    isLoadingEditTimesheets: false,
    errorEditTimesheets: ''
}

const editTimesheetsSlice = createSlice({
    name: 'updateTimesheet',
    initialState,
    reducers:{
       editTimesheetsPending: (state) => {
            state.isLoadingEditTimesheets = true;
        },
        editTimesheetsSuccess: (state, {payload}) => {
            state.isLoadingEditTimesheets = false;
            state.updateTimesheet = payload;
            state.errorEditTimesheets = '';
        },
        editTimesheetsFail: (state, {payload}) => {
            state.isLoadingEditTimesheets = false;
            state.errorEditTimesheets = payload;
        }
    }
})

export const {editTimesheetsPending, editTimesheetsSuccess, editTimesheetsFail} = editTimesheetsSlice.actions

export default editTimesheetsSlice.reducer