import React from "react";
import Pagination from "react-js-pagination";
import { useDispatch, useSelector } from "react-redux";
import getClientMedicationAudit from "../ClientActions/ClientMedicationAuditAction";
import getClientMarchart from "../ClientActions/ClientMarchartsAction";
import PageLoader from "../../../components/PageLoader";
// import ViewClientMarchart from "./ViewMarchart";
import MedicalReports from "./MedicalReports";

function MarchartAuditTrail({e,client}) {
  const dispach = useDispatch();

  const { getMedicationAudit, isLoadingGetMedicationRecords } = useSelector(
    (state) => state.medicationAudit
  );
  const { getClientMarcharts, isLoadingClientMarcharts } = useSelector(
    (state) => state.getclientmarcharts
  );
  const itemsCountPerPage = 20;
  const [page, setPage] = React.useState(1);

  React.useEffect(() => {
    dispach(
      getClientMedicationAudit({
        id: e.e,
        page,
        perPage: itemsCountPerPage,
      })
    );
    dispach(
        getClientMarchart({
          clientId: e.e,
          code: "CP06",
        })
      );
    
  }, [page]);

  console.log("getClientMarcharts", getClientMarcharts);

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };
  const handleDate = (date) => {
    const day = new Date(date);
    return (
      day.getDate().toString().padStart(2, "0") +
      "/" +
      (day.getMonth() + 1).toString().padStart(2, "0") +
      "/" +
      day.getFullYear()
    );
  };

  const handleTime = (date) => {
    const day = new Date(date);
    return (
      day.getHours() +
      ":" +
      day.getMinutes().toString().padStart(2, "0") +
      ":" +
      day.getSeconds().toString().padStart(2, "0")
    );
  };

  return (
    <>
      <div class="overflow-x-auto h-auto">
        <div class="bg-gray-100 flex items-center justify-center bg-gray-100 font-sans overflow-hidden">
          <div class="w-full">
            <div class="bg-white shadow-md rounded my-6">
              <table class="min-w-max w-full table-auto">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr className="text-left">
                    <th scope="col" className="px-6 py-3">
                      Name
                    </th>
                    <th scope="col" className="px-3 py-3">
                      Quantity
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Dosage
                    </th>
                    <th scope="col" className="px-6 py-3">
                     Created At
                    </th>
                    <th scope="col" className="px-6 py-3 scr-only">
                     
                    </th>
                  </tr>
                </thead>
          { isLoadingClientMarcharts ? <div className="pb-2"><PageLoader/></div> : <tbody>
                  {getClientMarcharts && getClientMarcharts.data?.length > 0 ? (
                    getClientMarcharts.data.map((med) => (
                      <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-primary dark:hover:bg-gray-600 text-left">
                        <th
                          scope="row"
                          className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap text-left"
                        >
                          {med.medication}
                        </th>
                        <th
                          scope="row"
                          className="px-3 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap"
                        >
                          {med.received} 
                               
                        </th>
                        <td className="px-6 py-4">
                         
                          {med.dosage}
                        </td>
                        <td className="px-6 py-4">
                        {handleDate(med.createdAt)}
                        
                        </td>
                        <td className="">
                        <MedicalReports e={med} client={client}/>
                        
                        </td>
                      
                      </tr>
                    ))
                  ) : (
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                      <td
                        colSpan="9"
                        className="text-center px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap border rounded bg-red-100"
                      >
                        No Data Found
                      </td>
                    </tr>
                  )}
                </tbody>}
              </table>
              {getMedicationAudit &&
                getMedicationAudit &&
                getMedicationAudit.data?.length > 0 &&
                !isLoadingGetMedicationRecords && (
                  <div className="flex justify-end p-lg-2">
                    <Pagination
                      activePage={page}
                      itemsCountPerPage={itemsCountPerPage}
                      totalItemsCount={getMedicationAudit.totalItemsCount}
                      pageRangeDisplayed={1}
                      onChange={handlePageChange}
                      itemClass="page-item"
                      linkClass="page-link"
                    />
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default MarchartAuditTrail;
