import { getTimesheets } from "../../../api/timesheetsApi";
import { getTimesheetsFail, getTimesheetsPending, getTimesheetsSuccess } from "../Slices/GetTimesheetsSlice";

 const getTimesheetList = (data) => async dispatch => {

    try {
        dispatch(getTimesheetsPending());
        const result = await getTimesheets(data);
        if(result){
            return dispatch(getTimesheetsSuccess(result))
        }
    } catch (error) {
        dispatch(getTimesheetsFail(error))
    }
} 

export default getTimesheetList