import axios from "axios";


export const getMedicationRecord = async (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = sessionStorage.getItem('access_token')
            const res = await axios.get(`${process.env.REACT_APP_ELMS_API_URL}medication/${data.id}?page=${data.page}&perPage=${data.perPage}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            resolve(res.data);

        } catch (error) {
            if(error.response.data.statusCode === 404){
                return null
             }
            
             reject(error)
        }
    })
}

export const getAdministered = async (data) => {
 
    return new Promise(async (resolve, reject) => {
        try {
            const token = sessionStorage.getItem('access_token')
            const res = await axios.get(`${process.env.REACT_APP_ELMS_API_URL}medication/fetch-administered/${data.id}?month=${data.month}&year=${data.year}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
      
           resolve(res.data);

        } catch (error) {
            if(error.response.data.statusCode === 404){
                return null
             }
            
             reject(error)
        }
    })
}



export const getMedicationAudit = async (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = sessionStorage.getItem('access_token')
            const res = await axios.get(`${process.env.REACT_APP_ELMS_API_URL}medication-audit/${data.id}?page=${data.page}&perPage=${data.perPage}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            resolve(res.data);
            console.log('AuditSuccess',res);
        } catch (error) {
            if(error.response.data.statusCode === 404){
                return null
             }
            
             reject(error)
             console.log('AuditError', error);
        }
    })
}


