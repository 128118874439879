import React from "react";
import { Sidebar } from "../../../layouts/auth/Sidebar";
import { TopNavigation } from "../../../layouts/auth/TopNavigation";
import SimpleSwitch from "../../../components/SimpleSwitch";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import ProgressBar from "../../clients/AddClient/ProgressBar";
import SubmitButton from "../../../components/Formik/SubmitButton";
import FormikController from "../../../components/Formik/FormikController";
import { useDispatch, useSelector } from "react-redux";
import TrainingHistoryAction from "../RegisterCarerActions/TrainingHistoryAction";
import PageLoader from "../../../components/PageLoader";

function CarerTraining({GetCarerInfo}) {

  console.log('TRAIN', GetCarerInfo);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { signUp, isLoadingSignUp, errorSignUp } = useSelector(
    (state) => state.signUpCarer
  );
  const { TrainingHistory, isLoadingTrainingHistory } = useSelector(
    (state) => state.TrainingHistory
  );
  
  const initialValues = {
    CareCertificate: false,
    MandatoryTraining: false,
    MedicationAdministration: false,
    InfectionControl: false,
    CovidCertificate: false,
    pegFeed: false,
    EndOfLifePalliative: false,
    SkinIntegrity: false,
    Pmva: false,
    Oxygen: false,
    Demetia: false,
    MentalHealth: false,
    CoughAssist: false,
    Tracheostomy: false,
    SpinalInjuries: false,
    BrainInjuries: false,
    LearningDisability: false,
    Catheter: false,
    Suctioning: false,
    StomaBag: false,
    EarNoseThroat: false,
    accessToken: signUp.access_token,

  };


  const validationSchema = Yup.object({});

  const Head = (props) => {
    return (
      <div className=" mb-5">
        <label className="text-xl font-medium text-gray-700 ">
          {props.title}
        </label>
      </div>
    );
  };

  const handleSubmit = (values) => {
    console.log("VALUEE", values);
    values.id = signUp.user.id
    dispatch(TrainingHistoryAction(values))
    navigate("/carer-background");
  };

  const handleUpdate = (values) => {
    console.log("VALUEE", values);
    values.id = GetCarerInfo.id
    dispatch(TrainingHistoryAction(values))
  };

  return (
    <div className="bg-gray-50 h-screen">
      {(GetCarerInfo && !GetCarerInfo?.trainingHistory) ? "" : <Sidebar />}
      {GetCarerInfo && !GetCarerInfo?.trainingHistory ? "" : <TopNavigation title="Add Carer" />}
      <div className="bg-gray-50">
      <div className={(GetCarerInfo && !GetCarerInfo?.trainingHistory) ? "mt-5" : "flex ml-80 mr-16 mt-5"}>
          {(GetCarerInfo && !GetCarerInfo?.trainingHistory) ? "" : <div className="w-40 border mt-5 p-3 rounded-lg bg-gray-50 h-screen">
            <ProgressBar number={90} />
          </div>}
          <div className="w-full p-5">
          <div className="text-left">
                        <Head title="Training History" />
                      </div>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values) =>(GetCarerInfo && !GetCarerInfo?.trainingHistory) ? handleUpdate(values) : handleSubmit(values)}
            >
              {({ formik, values }) => {
                return (
                  <Form className="text-left">
                    <div className="bg-white shadow p-5 rounded ">
                     
                      <div className="text-left font-semibold text-gray-600 mb-5">
                      Please tick to say YES
                    </div>
                      <div className="grid grid-cols-2 gap-2">
                  <SimpleSwitch
                    name="CareCertificate"
                    label="Care Certificate"
                    checked={values.CareCertificate === true}
                  />

                  <SimpleSwitch
                    name="MedicationAdministration"
                    label="Medication Administration"
                    checked={values.MedicationAdministration === true}
                  />

                  <SimpleSwitch
                    name="MovingHandling"
                    label="Moving & Handling"
                    checked={values.MovingHandling === true}
                  />

                  <SimpleSwitch
                    name="InfectionControl"
                    label="Infection Control"
                    checked={values.InfectionControl === true}
                  />

                  <SimpleSwitch
                    name="CovidCertificate"
                    label="Covid Certificate"
                    checked={values.CovidCertificate === true}
                  />
                  <SimpleSwitch
                    name="EndOfLifePalliative"
                    label="End Of Life Palliative"
                    checked={values.EndOfLifePalliative === true}
                  />
                  {/* <SimpleSwitch
                    name="MentalIllness"
                    label="MentalIllness"
                    checked={values.MentalIllness === true}
                  /> */}
                  <SimpleSwitch
                    name="SkinIntegrity"
                    label="Skin Integrity"
                    checked={values.SkinIntegrity === true}
                  />

                  <SimpleSwitch
                    name="Pmva"
                    label="Pmva"
                    checked={values.Pmva === true}
                  />

                  <SimpleSwitch
                    name="Oxygen"
                    label="Oxygen"
                    checked={values.Oxygen === true}
                  />

                  <SimpleSwitch
                    name="Demetia"
                    label="Demetia"
                    checked={values.Demetia === true}
                  />

                  <SimpleSwitch
                    name="MentalHealth"
                    label="Mental Health"
                    checked={values.MentalHealth === true}
                  />

                  <SimpleSwitch
                    name="CoughAssist"
                    label="Cough Assist"
                    checked={values.CoughAssist === true}
                  />
                  <SimpleSwitch
                    name="Tracheostomy"
                    label="Tracheostomy"
                    checked={values.Tracheostomy === true}
                  />
                  <SimpleSwitch
                    name="SpinalInjuries"
                    label="Spinal Injuries"
                    checked={values.SpinalInjuries === true}
                  />
                  <SimpleSwitch
                    name="BrainInjuries"
                    label="Brain Injuries"
                    checked={values.BrainInjuries === true}
                  />
                  <SimpleSwitch
                    name="LearningDisability"
                    label="Learning Disability"
                    checked={values.LearningDisability === true}
                  />
                  <SimpleSwitch
                    name="GastrointestinalDisease"
                    label="Gastro Intestinal Disease"
                    checked={values.Catheter === true}
                  />

                  <SimpleSwitch
                    name="Catheter"
                    label="Catheter"
                    checked={values.Catheter === true}
                  />
                  <SimpleSwitch
                    name="StomaBag"
                    label="Stoma Bag"
                    checked={values.StomaBag === true}
                  />
                  <SimpleSwitch
                    name="MandatoryTraining"
                    label="Mandatory Training"
                    checked={values.MandatoryTraining === true}
                  />
                  <SimpleSwitch
                    name="EarNoseThroat"
                    label="Ear, Nose & Throat"
                    checked={values.EarNoseThroat === true}
                  />
                </div>
                    </div>
                    <div className="mb-3 mt-5 flex flex-row-reverse">
                      <div></div>
                    { isLoadingTrainingHistory ? <PageLoader/> : <SubmitButton label={GetCarerInfo && !GetCarerInfo?.trainingHistory ? "Update": "Save & Next"} />}
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CarerTraining;
