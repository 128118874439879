import React from "react";

const SubmitButton = ({ label, children, type = "submit", ...rest }) => {
  return (
    <div className="mb-3 pt-3">
      <button
        type={type}
        className="bg-orange-500 text-white font-medium border w-full rounded p-1.5 pb-2 text-lg px-10"
      >
        {label}
      </button>
    </div>
  );
};

export default SubmitButton;
