import React from "react";
import { Sidebar } from "../../../layouts/auth/Sidebar";
import { TopNavigation } from "../../../layouts/auth/TopNavigation";
import { useNavigate } from "react-router-dom";
import FormikController from "../../../components/Formik/FormikController";
import SubmitButton from "../../../components/Formik/SubmitButton";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import ProgressBar from "../../clients/AddClient/ProgressBar";
import { useDispatch, useSelector } from "react-redux";
import NextofKinAction from "../RegisterCarerActions/NextofKinAction";
import UpdateNextofkinAction from "../RegisterCarerActions/UpdateNextofkinAction";
import PageLoader from "../../../components/PageLoader";

const NextofKin = ({GetCarerInfo}) => {
  // console.log('CarerNext',GetCarerInfo?.nextofkins?.length);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { signUp, isLoadingSignUp, errorSignUp } = useSelector(
    (state) => state.signUpCarer
  );
  const [indexes, setIndexes] = React.useState([]);
  const [counter, setCounter] = React.useState(0);

  const { nextofkin, isLoadingNextofkin } = useSelector(
    (state) => state.NextofKin
  );

  const initialValues = {
    firstname: "",
    middlename: "",
    lastname: "",
    relationship: "",
    email: "",
    phone: "",

  };
  const validationSchema = Yup.object({
    // firstname: Yup.string().matches(/^[A-Za-z ]*$/, 'Please enter valid name').max(40).required('This field is required'),
  });

  const handleSubmit = (values) => {

    // values.nextofkin.accessToken = signUp.access_token;
    values.nextofkin.id = signUp.user.id;
    dispatch(NextofKinAction(values.nextofkin));
    navigate("/carer-documents");
  };

  const handleUpdate = (values)=> {
    values.nextofkin.id =  GetCarerInfo.id ;
    console.log("NEXT", values.nextofkin);
    dispatch(NextofKinAction(values.nextofkin));
  }
  const addMarChart = () => {
    setIndexes((prevIndexes) => [...prevIndexes, counter]);
    setCounter((prevCounter) => prevCounter + 1);
  };

  const removeMarChart = (index) => () => {
    setIndexes((prevIndexes) => [
      ...prevIndexes.filter((item) => item !== index),
    ]);
    setCounter((prevCounter) => prevCounter - 1);
  };

  const clearMarCharts = () => {
    setIndexes([]);
  };

  const Head = (props) => {
    return (
      <div className="border-b mb-5">
        <label className="text-xl font-medium text-gray-700">
          {props.title}
        </label>
      </div>
    );
  };

  return (
    <div className="bg-gray-50 h-screen">
      {GetCarerInfo?.nextofkins?.length != 0 && <Sidebar />}
      {GetCarerInfo?.nextofkins?.length != 0 && <TopNavigation title="Add Carer" />}
      <div className="bg-gray-50 ">
        <div className={GetCarerInfo?.nextofkins?.length === 0 ? "mt-5" : "flex ml-80 mr-16 mt-5"}>
          {GetCarerInfo?.nextofkins?.length != 0 && <div className="w-40  mt-5 p-3 rounded-lg bg-gray-50 h-screen border">
            <ProgressBar number={30} />
          </div>}
          <div className="w-full p-5">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values) => GetCarerInfo?.nextofkins?.length === 0 ? handleUpdate(values) : handleSubmit(values)}
            >
              {({ formik, values }) => {
                return (
                  <Form className="">
                    {indexes.map((index) => {
                      const fieldName = `nextofkin[${index}]`;
                      return (
                        <fieldset name={fieldName} key={fieldName}>
                          <div className="mb-5 font-bold ml-6">
                            <div className="text-left font-semibold text-gray-600 py-1">{`NEXT OF KIN ${
                              index + 1
                            }`}</div>
                          </div>

                          <div className="rounded bg-white shadow p-5 rounded">
                            <div className="grid grid-cols-2 gap-2">
                              <FormikController
                                control="input"
                                type="text"
                                name={`${fieldName}.relationship`}
                                label="Relationship"
                              />

                              <FormikController
                                control="input"
                                type="text"
                                name={`${fieldName}.firstname`}
                                label="First name"
                              />
                              <FormikController
                                control="input"
                                type="text"
                                name={`${fieldName}.middlename`}
                                label="Middle name"
                                placeholder="Optional"
                              />

                              <FormikController
                                control="input"
                                type="text"
                                name={`${fieldName}.lastname`}
                                label="Last name"
                              />

                              <FormikController
                                control="input"
                                type="text"
                                name={`${fieldName}.phone`}
                                label="Mobile Number"
                              />

                              <FormikController
                                control="input"
                                type="email"
                                name={`${fieldName}.email`}
                                label="Email"
                                placeholder="example@yahoo.com"
                              />
                            </div>
                          </div>

                          <div className="text-left mt-3 border-b mb-5 ml-6 bg-gray-50">
                            <button
                              className="border border-button text-button font-medium p-3 px-5 bg-red-100 mb-4 rounded"
                              type="button"
                              onClick={removeMarChart(index)}
                            >
                              Remove
                            </button>
                          </div>
                        </fieldset>
                      );
                    })}
                    <div className="text-left py-5 ml-6 ">
                      <button
                        className="border rounded p-5 mr-3 border-green text-white bg-green font-medium"
                        type="button"
                        onClick={addMarChart}
                      >
                        Add Next of Kin
                      </button>
                      {counter ? (
                        <button
                          className="border rounded p-5 border-gray-300 bg-gray-500 text-white font-medium"
                          type="button"
                          onClick={clearMarCharts}
                        >
                          Clear Next of Kin
                        </button>
                      ) : (
                        <div></div>
                      )}
                    </div>

                    <div className="mb-3 mt-5 flex flex-row-reverse">
                      <div></div>
                      {isLoadingNextofkin ? <PageLoader/> : <SubmitButton label={GetCarerInfo?.nextofkins?.length === 0 ? "Save" : "Save & Next"} />}
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NextofKin;
