import {getSelectedCarersPending, getSelectedCarersSuccess, getSelectedCarersFail} from '../staffSlices/getSelectedCarersSlice'
import {getSelectedCarers} from '../../../api/staffApi'

 const getSelectedCarersAction = (data) => async dispatch => {

    try {
        dispatch(getSelectedCarersPending());
        const result = await getSelectedCarers(data);
        if(result){
            return dispatch(getSelectedCarersSuccess(result))
        }
    } catch (error) {
        dispatch(getSelectedCarersFail(error))
    }
}

export default getSelectedCarersAction