import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    editCommunicationDoc: {},
    isLoadingCommunication: false,
    errorEditCommunication: ''
}

const editCommuncationSlice = createSlice({
    name: 'editCommunicationDoc',
    initialState,
    reducers:{
        editCommuncationPending: (state) => {
            state.isLoadingCommunication = true;
        },
        editCommuncationSuccess: (state, {payload}) => {
            state.isLoadingCommunication = false;
            state.editCommunicationDoc = payload;
            state.errorEditCommunication = '';
        },
        editCommuncationFail: (state, {payload}) => {
            state.isLoadingCommunication = false;
            state.errorEditCommunication = payload;
        }
    }
})

export const {editCommuncationPending, editCommuncationSuccess, editCommuncationFail} = editCommuncationSlice.actions

export default editCommuncationSlice.reducer