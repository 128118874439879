import React from "react";
import { Sidebar } from "../../../layouts/auth/Sidebar";
import { TopNavigation } from "../../../layouts/auth/TopNavigation";
import SimpleSwitch from "../../../components/SimpleSwitch";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ProgressBar from "../../clients/AddClient/ProgressBar";
import SubmitButton from "../../../components/Formik/SubmitButton";
import FormikController from "../../../components/Formik/FormikController";
import HealthDeclarationAction from "../RegisterCarerActions/HealthDeclarationAction";

function CarerHealthDeclaration({ setShowModal }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { signUp, isLoadingSignUp, errorSignUp } = useSelector(
    (state) => state.signUpCarer
  );

  const Head = (props) => {
    return (
      <div className="border-b mb-5">
        <label className="text-xl font-medium text-gray-700">
          {props.title}
        </label>
      </div>
    );
  };

  //   const CardiovascularDisease = carer.carer.HasCardiovascularDisease
  //     ? carer.carer.HasCardiovascularDisease
  //     : false;
  //   const RespiratoryDisease = carer.carer.HasRespiratoryDisease
  //     ? carer.carer.HasRespiratoryDisease
  //     : false;
  //   const LiverGallbladderDisease = carer.carer.HasLiverGallbladderDisease
  //     ? carer.carer.HasLiverGallbladderDisease
  //     : false;
  //   const GastrointestinalDisease = carer.carer.HasGastrointestinalDisease
  //     ? carer.carer.HasGastrointestinalDisease
  //     : false;
  //   const ContagiousBloodDisease = carer.carer.HasContagiousBloodDisease
  //     ? carer.carer.ContagiousBloodDisease
  //     : false;
  //   const Allergies = carer.carer.HasAllergies ? carer.carer.HasAllergies : false;
  //   const MentalIllness = carer.carer.HasMentalIllness
  //     ? carer.carer.HasMentalIllness
  //     : false;
  //   const PreviousGeneralAnaesthesia = carer.carer.HasPreviousGeneralAnaesthesia
  //     ? carer.carer.HasPreviousGeneralAnaesthesia
  //     : false;
  //   const smoke = carer.carer.smoke ? carer.carer.smoke : false;
  //   const KidneyDisease = carer.carer.KidneyDisease
  //     ? carer.carer.KidneyDisease
  //     : false;
  //   const Diabetes = carer.carer.Diabetes ? carer.carer.Diabetes : false;
  //   const RheumatoidArthritis = carer.carer.RheumatoidArthritis
  //     ? carer.carer.RheumatoidArthritis
  //     : false;
  //   // const SecondCovidVaccine = carer.carer.SecondCovidVaccine ? carer.carer.SecondCovidVaccine : false;
  //   const OnMedication = carer.carer.OnMedication
  //     ? carer.carer.OnMedication
  //     : false;

  const initialValues = {
    HasCardiovascularDisease: false,
    CardiovascularDisease:"",
    HasRespiratoryDisease: false,
    RespiratoryDisease:"",
    HasLiverGallbladderDisease: false,
    LiverGallbladderDisease:"",
    HasGastrointestinalDisease: false,
    GastrointestinalDisease:"",
    HasContagiousBloodDisease: false,
    ContagiousBloodDisease:"",
    HasAllergies: false,
    Allergies:"",
    HasMentalIllness: false,
    MentalIllness:"",
    HasPreviousGeneralAnaesthesia: false,
    PreviousGeneralAnaesthesia:"",
    smoke: false,
    smokingHabit:"",
    KidneyDisease: false,
    Diabetes: false,
    RheumatoidArthritis: false,
    Alcohol: false,
    OnMedication: false,
    NameOfMedications:"",
    MedicationDailyDosage: "",
    SecondCovidVaccine:'2021-05-05'

  };

  const validationSchema = Yup.object({});

  const handleSubmit = (values) => {
    values.id = signUp.user.id
    console.log("VALUEE", values);
    dispatch(HealthDeclarationAction(values))
    navigate("/carer-equal-monitoring");
  };

  return (
    <div className="bg-gray-50 h-screen">
    <Sidebar />
    <TopNavigation title="Add Carer" />
    <div className="bg-gray-50">
      <div className="flex ml-80 mr-16 mt-5">
        <div className="w-40 border mt-5 p-3 rounded-lg bg-gray-50 h-screen">
          <ProgressBar number={80} />
        </div>
    <div className="w-full p-5">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => handleSubmit(values)}
      >
        {({ formik, values }) => {
          return (
<Form>
            <span className="text-left">
              <div className="bg-white shadow p-5 rounded ">
                <div className="">
                  <Head title="Health Declaration" />
                </div>
                <div className="grid grid-cols-1 gap-2">
                  <SimpleSwitch
                    name="HasCardiovascularDisease"
                    label="Cardiovascular Disease"
                    checked={values.HasCardiovascularDisease === true}
                  />
                  {values.HasCardiovascularDisease === true && (
                    <div className="mb-6">
                      <FormikController
                        control="input"
                        type="text"
                        name="CardiovascularDisease"
                        label="Please Specify"
                      />
                    </div>
                  )}

                  <SimpleSwitch
                    name="HasRespiratoryDisease"
                    label="Respiratory Disease"
                    checked={values.HasRespiratoryDisease === true}
                  />

                  {values.HasRespiratoryDisease === true && (
                    <div className="mb-6">
                      <FormikController
                        control="input"
                        type="text"
                        name="RespiratoryDisease"
                        label="Please Specify"
                      />
                    </div>
                  )}

                  <SimpleSwitch
                    name="HasLiverGallbladderDisease"
                    label="Liver Gallbladder Disease"
                    checked={values.HasLiverGallbladderDisease === true}
                  />

                  {values.HasLiverGallbladderDisease === true && (
                    <div className="mb-6">
                      <FormikController
                        control="input"
                        type="text"
                        name="LiverGallbladderDisease"
                        label="Please Specify"
                      />
                    </div>
                  )}

                  <SimpleSwitch
                    name="HasGastrointestinalDisease"
                    label="Gastrointestinal Disease"
                    checked={values.HasGastrointestinalDisease === true}
                  />

                  {values.HasGastrointestinalDisease === true && (
                    <div className="mb-6">
                      <FormikController
                        control="input"
                        type="text"
                        name="GastrointestinalDisease"
                        label="Please Specify"
                      />
                    </div>
                  )}

                  <SimpleSwitch
                    name="HasContagiousBloodDisease"
                    label="ContagiousBloodDisease"
                    checked={values.HasContagiousBloodDisease === true}
                  />
                  {values.HasContagiousBloodDisease === true && (
                    <div className="mb-6">
                      <FormikController
                        control="input"
                        type="text"
                        name="ContagiousBloodDisease"
                        label="Please Specify"
                      />
                    </div>
                  )}

                  <SimpleSwitch
                    name="HasAllergies"
                    label="Allergies"
                    checked={values.HasAllergies === true}
                  />
                  {values.HasAllergies === true && (
                    <div className="mb-6">
                      <FormikController
                        control="input"
                        type="text"
                        name="Allergies"
                        label="Please Specify"
                      />
                    </div>
                  )}

                  <SimpleSwitch
                    name="HasMentalIllness"
                    label="MentalIllness"
                    checked={values.HasMentalIllness === true}
                  />
                  {values.HasMentalIllness === true && (
                    <div className="mb-6">
                      <FormikController
                        control="input"
                        type="text"
                        name="MentalIllness"
                        label="Please Specify"
                      />
                    </div>
                  )}

                  <SimpleSwitch
                    name="HasPreviousGeneralAnaesthesia"
                    label="Previous General Anaesthesia"
                    checked={values.HasPreviousGeneralAnaesthesia === true}
                  />
                  {values.HasPreviousGeneralAnaesthesia === true && (
                    <div className="mb-6">
                      <FormikController
                        control="input"
                        type="text"
                        name="PreviousGeneralAnaesthesia"
                        label="Please Specify"
                      />
                    </div>
                  )}

                  <SimpleSwitch
                    name="smoke"
                    label="Smoke"
                    checked={values.smoke === true}
                  />

                  {values.smoke === true && (
                    <div className="mb-6">
                      <FormikController
                        control="input"
                        type="text"
                        name="smokingHabit"
                        label="Please Specify"
                      />
                    </div>
                  )}

                  <SimpleSwitch
                    name="Alcohol"
                    label="Drink Alcohol Daily"
                    checked={values.Alcohol === true}
                  />
                  <SimpleSwitch
                    name="KidneyDisease"
                    label="Kidney Disease"
                    checked={values.KidneyDisease === true}
                  />

                  <SimpleSwitch
                    name="Diabetes"
                    label="Diabetes"
                    checked={values.Diabetes === true}
                  />

                  <SimpleSwitch
                    name="RheumatoidArthritis"
                    label="Rheumatoid Arthritis"
                    checked={values.RheumatoidArthritis === true}
                  />
                   <SimpleSwitch
                    name="Vaccinated"
                    label="Are you fully Covid Vaccinated ?"
                    checked={values.Vaccinated === true}
                  />
                  {values.Vaccinated === true && (
                    <div className="mb-6">
                      <FormikController
                        control='input'
                        type='date'
                        name='SecondCovidVaccine'
                        label="Date of last Vaccination"
                      />
                    </div>
                  )}

                  <SimpleSwitch
                    name="OnMedication"
                    label="On Medication"
                    checked={values.OnMedication === true}
                  />
                  {values.OnMedication === true && (
                    <div className="mb-6">
                      <FormikController
                        control="input"
                        type="text"
                        name="NameOfMedications"
                        label="Name of Medication"
                      />
                    </div>
                  )}
                  {values.OnMedication === true && (
                    <div className="mb-6">
                      <FormikController
                        control="input"
                        type="text"
                        name="MedicationDailyDosage"
                        label="Daily Dosage"
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="mb-3 mt-5 flex flex-row-reverse">
                      <div></div>
                      <SubmitButton label="Save & Next" />
                    </div>
            </span>
         </Form> );
        }}
      </Formik>
    </div>
    </div>
    </div>
    </div>
  );
}

export default CarerHealthDeclaration;
