import {addClientPending, addClientSuccess, addClientFail} from '../ClientSlices/PersonalDetailsSlice'
import {addClientPersonalDetails} from '../../../api/clientApi'

 const addClient = (data) => async dispatch => {

    try {
        dispatch(addClientPending());
        const result = await addClientPersonalDetails(data);
        if(result){
            return dispatch(addClientSuccess(result))
        }
    } catch (error) {
        dispatch(addClientFail(error))
    }
} 

export default addClient