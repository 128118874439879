import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    staffBirthdays: {},
    isLoading: false,
    error: ''
}

const staffBirthdaysSlice = createSlice({
    name: 'staffBirthdays',
    initialState,
    reducers:{
        getStaffBirthdaysPending: (state) => {
            state.isLoading = true;
        },
        getStaffBirthdaysSuccess: (state, {payload}) => {
            state.isLoading = false;
            state.staffBirthdays = payload;
            state.error = '';
        },
        getStaffBirthdaysFail: (state, {payload}) => {
            state.isLoading = false;
            state.error = payload;
        }
    }
})

export const {getStaffBirthdaysPending, getStaffBirthdaysSuccess, getStaffBirthdaysFail} = staffBirthdaysSlice.actions

export default staffBirthdaysSlice.reducer