import React from 'react'
import EditCarer from './EditCarer'
import getCarerList from '../carers/CarerActions/GetCarersAction'
import { useDispatch, useSelector } from 'react-redux'
import Pagination from 'react-js-pagination'
import updateCarerStatus from '../carers/CarerActions/UpdateStatusAction'
import activateCarerStatus from '../carers/CarerActions/ActivateStatusAction'
import Contract from './Contract';
import PageLoader from "../../components/PageLoader";


function Archived() {
    const dispatch = useDispatch();
    const {getCarers, isLoadingGetCarers} = useSelector(state => state.getCarersList);
   const{updateStatus} = useSelector((state)=> state.updateCarerStatus);
   const{activateStatus} = useSelector((state)=> state.activateCarerStatus)
    const itemsCountPerPage = 5;
    const [page, setPage] = React.useState(1);
    const handlePageChange = (pageNumber) => {
       setPage(pageNumber);
   }
 
   
   React.useEffect(() => {
       dispatch(getCarerList({
           page,
           perPage: itemsCountPerPage,
           status: 'COMPLIANT'
       }))
   
   }, [page,updateStatus,activateStatus]);   

  const padLeadingZeros = (num, size) => {
    var s = num+"";
    while (s.length < size) s = "0" + s;
    return s;
}

const handleDate = (date) => {
    let format = new Date(date);
    return ("0"+format.getDate()).slice(-2)+'/'+("0"+(format.getMonth()+1)).slice(-2)+'/'+format.getFullYear()+' '+format.getHours()+':'+format.getMinutes();
}

const deactiveStatus = (carer) =>{
dispatch(updateCarerStatus({ status: 'SUSPENDED', id: carer.id }))
}

const activateStatuss = (carer) =>{
    dispatch(activateCarerStatus({ status: 'ACTIVE', id: carer.id }))
}
  return (
    <div className='ml-72 mr-8 mt-10'>
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
   { isLoadingGetCarers ? <PageLoader/> : <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                    <th scope="col" className="px-6 py-3">
                        Name
                    </th>
                    <th scope="col" className="px-6 py-3">
                        ID
                    </th>
                    <th scope="col" className="px-6 py-3">
                       Shift Type
                    </th>                  
                    <th scope="col" className="px-6 py-3">
                        Mobile Number
                    </th>
                    <th scope="col" className="px-6 py-3">
                        Address
                    </th>
                    <th scope="col" className="px-6 py-3">
                        Approved By
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Account Created On
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Status
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Contract
                    </th>
                    <th scope="col" className="px-6 py-3">
                    </th>
                    <th scope="col" className="px-6 py-3">
                        <span className="sr-only">Edit</span>
                    </th>
                </tr>
            </thead>
            <tbody>

             
            {getCarers && getCarers.data && getCarers.data.length > 0 ? getCarers.data.map((carer) => (
                        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-primary dark:hover:bg-gray-600">
                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                          { carer.firstname+' '+carer.lastname }
                        </th>
                        <td className="px-6 py-4">
                            E{ padLeadingZeros(carer.elmnumber, 5)}
                        </td>
                        <td className="px-6 py-4">
                            {carer.availability && carer.availability.liveIn ? 'Live In' : ''}  {carer.availability && carer.availability.day ? ' Day' : ''}  {carer.availability && carer.availability.night ? ' Night' : ''}
                        </td>                                                    
                        <td className="px-6 py-4">
                            {carer.mobile}
                        </td>
                        <td className="px-6 py-4">
                            {carer.addressLine1}
                        </td>
                        <td className="px-6 py-4">
                            {carer.admin ? carer.admin.firstname+' '+carer.admin.lastname : ''}
                        </td>   
                        <td className="px-6 py-4">
                            {handleDate(carer.createdAt)}
                        </td>
                       
                        <td className="px-6 py-4">
                        {carer.status.charAt(0).toUpperCase() + carer.status.slice(1).toLowerCase()}
                        </td>  
                        <td className="px-6 py-4 text-center">
                        {carer.contract ? <Contract carer={carer}/> : 'Pending ...' }
                        </td>    
                        <td className="px-6 py-4">
                        {carer.status === 'ACTIVE' ?
                            <button onClick={() => deactiveStatus(carer)} className="font-medium text-button dark:text-blue-500 hover:underline">De-activate</button> :
                            <button onClick={() => activateStatuss(carer)} className="font-medium text-button dark:text-blue-500 hover:underline">Activate</button>}
                    </td>                             
                        <td className="px-6 py-4 text-right">
                        <a href="#" className="font-medium text-secondary dark:text-blue-500 hover:underline"><EditCarer carer={carer}/></a>
                        </td>
                    </tr> 
            )): <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
            <td colSpan="11" className="text-center px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap border rounded bg-red-100">No Data Found</td>
        </tr>}               
               
            </tbody>
        </table>}
     
    </div>
    <div className=''>          
            <div>
                {(getCarers && getCarers.data && getCarers.data.length > 0 && !isLoadingGetCarers) &&
                    <div className="flex justify-end">
                        <Pagination
                            activePage={page}
                            itemsCountPerPage={itemsCountPerPage}
                            totalItemsCount={getCarers.totalItemsCount}
                            pageRangeDisplayed={1}
                            onChange={handlePageChange}
                            itemclassName="page-item"
                            linkclassName="page-link"
                        />
                    </div>}
            </div>
        </div>
    </div>
    )
}

export default Archived