import React from "react";
import FormikController from "../../components/Formik/FormikController";
import SubmitButton from "../../components/Formik/SubmitButton";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import PageLoader from "../../components/PageLoader";
import addStaff from "../../pages/staff/staffActions/addStaffAction";
import updateStaff from "../../pages/staff/staffActions/updateStaffAction";
import { BsFillPencilFill } from "react-icons/bs";
import { ImUserPlus } from "react-icons/im";

function AddStaff({ staff }) {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = React.useState(false);

  const {addStafff, isLoadingAddStaff, errorAddStaff } = useSelector(
    (state) => state.addStaff
  );

  const { isLoadingStaffUpdate, errorStaffUpdate } = useSelector(
    (state) => state.updateStaff
  );

  const handleOpen = (id) => {
    setShowModal(true);
  };

  const roles = [
    {
      key: "Administrator",
      value: "ADMINSTRATOR",
    },
    {
      key: "Manager",
      value: "MANAGER",
    },
    {
      key: "Office Staff",
      value: "OFFICE STAFF",
    },
    // {
    //   key: "Coordinator",
    //   value: "COORDINATOR",
    // },
    {
      key: "Regulator",
      value: "REGULATOR",
    },
  ];

  const initialValues = {
    firstname: staff ? staff.firstname : "",
    lastname: staff ? staff.lastname : "",
    middlename: staff ? staff.middlename : "",
    email: staff ? staff.email : "",
    mobile: staff ? staff.mobile : "",
    dob: staff ? staff.dob : "02-05-1994",
    addressLine1: staff ? staff.addressLine1 : "",
    addressLine2: staff ? staff.addressLine2 : "",
    postCode: staff ? staff.postCode : "",
    county: staff ? staff.county : "",
    country: staff ? staff.country : "",
    // role: staff ? staff.role : "",
  };

  const validationSchema = Yup.object({
    firstname: Yup.string().required("This field is required"),
    lastname: Yup.string().required("This field is required"),
    mobile: Yup.string().required("This field is required"),
    email: Yup.string()
      .email("Enter valid email address")
      .required("This field is required"),
    dob: Yup.string().required("Date of Birth is required"),
    addressLine1: Yup.string().required("Address Line 1 is required"),
    postCode: Yup.string().required("Post Code required"),
  });

  const handleUpdate = (values) => {
    values.id = staff.id;
    dispatch(updateStaff(values));
    setShowModal(false);
  };

  const handleSubmit = (values) => {
    dispatch(addStaff(values));
    setShowModal(false) 

    // if( addStafff.status ==='ACTIVE'){
    //   setShowModal(false) 
    // } else if (errorAddStaff){
    //   setShowModal(true)
    // }
    // addStafff.status ==='ACTIVE'  ?  setShowModal(false) : setShowModal(true)

  };
  return (
    <>
      {staff ? (
        <button
          className=" text-white bg-button border hover:bg-white hover:text-gray-700 hover:border hover:border-gray-300 hover:underline inline-flex decoration-secondary focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-1 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          type="button"
          onClick={() => handleOpen()}
        >
          Edit <BsFillPencilFill className="ml-1 mt-1 text-secondary" />
        </button>
      ) : (
        <button
          className="bg-button hover:bg-white text-white font-semibold hover:text-button inline-flex py-2 px-4 border border-secondary hover:border-button rounded"
          type="button"
          onClick={() => handleOpen()}
        >
          Add Staff <ImUserPlus size={20} className='mt-1 ml-2'/>
        </button>
      )}
      {showModal ? (
        <>
          <div className="justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              <div className="border-0 rounded-lg shadow-lg relative w-full bg-white outline-none focus:outline-none">
                <div className="items-start justify-between p-5 flex border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-xl font-semibold text-gray-500 text-left">
                    {staff ? "Edit User" : "Add User"}
                  </h3>
                  <button
                    className="p-1 ml-10  bg-transparent border-0 text-button float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    ×
                  </button>
                </div>

                <div className="relative p-6 text-gray-700">
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values, { resetForm }) => {
                      staff ? handleUpdate(values) : handleSubmit(values);
                      resetForm();
                    }}
                  >
                    {({values}) => (
                      <Form className="mt-2">
                        {errorAddStaff && (
                          <div
                            className="bg-red-100 border mb-5 text-center border-red-400 text-red-700 px-4 py-3 rounded relative"
                            role="alert"
                          >
                            <span className="block sm:inline">
                              {errorAddStaff.response.data.message}
                            </span>
                            <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                              {/* <svg className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" /></svg> */}
                            </span>
                          </div>
                        )}
                        {errorStaffUpdate && (
                          <div
                            className="bg-red-100 border mb-5 text-center border-red-400 text-red-700 px-4 py-3 rounded relative"
                            role="alert"
                          >
                            <span className="block sm:inline">
                              {errorStaffUpdate.response.data.message}
                            </span>
                            <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                              {/* <svg className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" /></svg> */}
                            </span>
                          </div>
                        )}
                        <div className="grid grid-cols-2 gap-2">
                          <div className="">
                            <FormikController
                              control="select"
                              type="select"
                              name="role"
                              label="Select Role"
                              options={roles}
                            />
                          </div>
                          <div className="">
                            <FormikController
                              control="input"
                              type="text"
                              name="firstname"
                              label="First name"
                            />
                          </div>
                          <div>
                            <FormikController
                              control="input"
                              type="text"
                              name="middlename"
                              label="Middle name"
                              placeholder="Optional"
                            />
                          </div>

                          <div>
                            <FormikController
                              control="input"
                              type="text"
                              name="lastname"
                              label="Last name"
                            />
                          </div>
                          <div>
                            <FormikController
                              control="input"
                              type="email"
                              name="email"
                              label="Email address"
                            />
                          </div>
                          <div>
                            <FormikController
                              control="input"
                              type="text"
                              name="mobile"
                              label="Mobile number"
                            />
                          </div>
                         { values.role !== 'REGULATOR' && <div>
                            <FormikController
                              control="input"
                              type="date"
                              name="dob"
                              label="Date Of Birth"
                            />
                          </div>}
                          <div>
                            <FormikController
                              control="input"
                              type="text"
                              name="postCode"
                              label="Post Code"
                            />
                          </div>
                          <div>
                            <FormikController
                              control="input"
                              type="text"
                              name="addressLine1"
                              label="Address Line 1"
                            />
                          </div>
                          <div>
                            <FormikController
                              control="input"
                              type="text"
                              name="addressLine2"
                              label="Address Line 2"
                              placeholder="Optional"
                            />
                          </div>
                          <div>
                            <FormikController
                              control="input"
                              type="text"
                              name="county"
                              label="County"
                            />
                          </div>
                          <div>
                            <FormikController
                              control="input"
                              type="text"
                              name="country"
                              label="Country"
                            />
                          </div>
                          <div></div>
                          <div className="mb-3 pt-1 ">
                            {isLoadingAddStaff || isLoadingStaffUpdate ? (
                              <PageLoader />
                            ) : staff ? (
                              <SubmitButton label="Edit User" />
                            ) : (
                              <SubmitButton label="Add User" />
                            )}
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>

          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}

export default AddStaff;
