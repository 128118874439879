import React from 'react'
import AddClient from './AddClient'
import ReferralContract from './ReferralContract'
import { useNavigate } from 'react-router-dom';

const Client = () => {
    const navigate = useNavigate(); 
  const [store, setStore] = React.useState(null);
  const client = 'Client Details'
  return (
        <div className=''>
          <div className=''>
            <AddClient store={store} setStore={setStore} client={client} navigate={navigate}/>
          </div>
          <div className='hidden'>
          <ReferralContract store={store}  client={client} setStore={setStore}/>
          </div>
        </div>
  )
}

export default Client