import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  EditMedicationCert: {},
  isLoadingEditMedicationCert: false,
  errorEditMedicationCert: "",
};

const EditMedicationCertSlice = createSlice({
  name: "EditMedicationCert",
  initialState,
  reducers: {
    EditMedicationCertPending: (state) => {
      state.isLoadingEditMedicationCert = true;
    },
    EditMedicationCertSuccess: (state, { payload }) => {
      state.isLoadingEditMedicationCert = false;
      state.EditMedicationCert = payload;
      state.errorEditMedicationCert = "";
    },
    EditMedicationCertFail: (state, { payload }) => {
      state.isLoadingEditMedicationCert = false;
      state.errorEditMedicationCert = payload;
    },
  },
});

export const { EditMedicationCertPending, EditMedicationCertSuccess, EditMedicationCertFail } =
  EditMedicationCertSlice.actions;

export default EditMedicationCertSlice.reducer;
