import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    editContractInfo: {},
    isLoadingContracts: false,
    errorEditContracts: ''
}

const editContractsSlice = createSlice({
    name: 'editContractInfo',
    initialState,
    reducers:{
        editContractsPending: (state) => {
            state.isLoadingContracts = true;
        },
        editContractsSuccess: (state, {payload}) => {
            state.isLoadingContracts = false;
            state.editContractInfo = payload;
            state.errorEditContracts = '';
        },
        editContractsFail: (state, {payload}) => {
            state.isLoadingContracts = false;
            state.errorEditContracts = payload;
        }
    }
})

export const {editContractsPending, editContractsSuccess, editContractsFail} = editContractsSlice.actions

export default editContractsSlice.reducer