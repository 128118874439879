import React from "react";
import { Sidebar } from "../../../layouts/auth/Sidebar";
import { TopNavigation } from "../../../layouts/auth/TopNavigation";
import SimpleSwitch from "../../../components/SimpleSwitch";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import ProgressBar from "../../clients/AddClient/ProgressBar";
import SubmitButton from "../../../components/Formik/SubmitButton";
import { useDispatch, useSelector } from "react-redux";
import EmployeesProcessingAction from "../RegisterCarerActions/EmployeesProcessingAction";
import GetCarerInfoAction from "../RegisterCarerActions/GetCarerInfoAction";
import PageLoader from "../../../components/PageLoader";

function CarerOptAgreement({carer}) {
 
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { signUp, isLoadingSignUp, errorSignUp } = useSelector(
    (state) => state.signUpCarer
  );
  const { EmployeesProcessing, isLoadingEmployeesProcessing } = useSelector(
    (state) => state.EmployeeProcessing
  );
  

  const {GetCarerInfo, isLoadingGetCarerInfo} = useSelector((state)=>state.GetCarerInfoSliceReducer)
  const initialValues = {
    HasAcceptedTerms: GetCarerInfo ? GetCarerInfo.HasAcceptedTerms : false,
    carerId: GetCarerInfo ? GetCarerInfo?.id : signUp?.user?.id,
  };

  console.log('carerID',GetCarerInfo);
  const Head = (props) => {
    return (
      <div className=" mb-5 text-left">
        <label className="text-xl font-medium text-gray-700">
          {props.title}
        </label>
      </div>
    );
  };

  React.useEffect(() => {
    dispatch(GetCarerInfoAction({id:carer?.carer?.id}))
  }, []);
  
  const validationSchema = Yup.object({});

  const handleSubmit = (values) => {
    console.log('val',values);
    dispatch(EmployeesProcessingAction(values))
    navigate("/recruitment");
  };
  const handleUpdate = (values) => {
    console.log('val',values);
    dispatch(EmployeesProcessingAction(values))
  };
  return (
    <div className="bg-gray-50 h-screen">
      {!carer && <Sidebar />} 
      {!carer && <TopNavigation title="Add Carer" />} 
      <div className="bg-gray-50"> 
      <div className={carer ? "" : "flex ml-80 mr-16 mt-5"}>
          {!carer && <div className="w-40 border mt-5 p-3 rounded-lg bg-gray-50">
            <ProgressBar number={98} />
          </div>}
          <div className="w-full p-5">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values) => (GetCarerInfo ) ? handleUpdate(values) : handleSubmit(values)}
            >
              {({ setFieldValue, formik, values }) => {
                return (
                  <Form className="text-left">
                    <div className="">
                        <Head title="48 Hour Opt Agreement" />
                      </div>
                    <div className="bg-white shadow p-5 rounded ">
                      
                      <div className="grid grid-cols-1 gap-2">
                        <div>
                      
                          <div style={{ fontWeight: "800", color: "green" }}>
                  1.0 : DEFINITIONS  OF CONTRACT 
                </div>
                          <div
                            style={{
                              fontWeight: "400",
                              marginBottom: 15,
                              borderBottomWidth: 0.4,
                              paddingBottom: 3,
                            }}
                          >
                            <div style={{ fontWeight: "800" }}>1.0 :</div> In
                            this Agreement the following definitions apply:
                          </div>

                          <div
                            style={{
                              fontWeight: "400",
                              marginBottom: 15,
                              borderBottomWidth: 0.4,
                              paddingBottom: 3,
                            }}
                          >
                            <div style={{ fontWeight: "800" }}>
                              Contractor :
                            </div>{" "}
                            Elms Health Solutions Ltd] of [1010 Regus Building,
                            Cambourne Business Park, Cambourne, Cambridge, CB23
                            6DP];
                          </div>

                          <div
                            style={{
                              fontWeight: "400",
                              marginBottom: 15,
                              borderBottomWidth: 0.4,
                              paddingBottom: 3,
                            }}
                          >
                            <div style={{ fontWeight: "800" }}>
                              Contractual Week :
                            </div>{" "}
                            means an average of 48 hours in each seven-day
                            period calculated over a 17-week reference period.
                          </div>
                          <div
                            style={{
                              fontWeight: "400",
                              marginBottom: 15,
                              borderBottomWidth: 0.4,
                              paddingBottom: 3,
                            }}
                          >
                            <div style={{ fontWeight: "800" }}>1.2 :</div>{" "}
                            References to the singular include the plural and
                            references to the masculine include the feminine and
                            vice versa.
                          </div>
                          <div
                            style={{
                              fontWeight: "400",
                              marginBottom: 15,
                              borderBottomWidth: 0.4,
                              paddingBottom: 3,
                            }}
                          >
                            <div style={{ fontWeight: "800" }}>1.3 :</div> The
                            headings contained in this Agreement are for
                            convenience only and do not affect their
                            interpretation.
                          </div>
                          <div
                            style={{
                              fontWeight: "400",
                              marginBottom: 15,
                              borderBottomWidth: 0.4,
                              paddingBottom: 3,
                            }}
                          >
                            <div style={{ fontWeight: "800" }}>2.0 :</div> The
                            Working Time Regulations 1998 [or the Working Time
                            (Northern Ireland) Regulations 1998] (as amended)
                            provide that the Employee shall not work in excess
                            of the Working Week unless s/he agrees in writing
                            that this limit should not apply.
                          </div>
                          <div
                            style={{
                              fontWeight: "400",
                              marginBottom: 15,
                              borderBottomWidth: 0.4,
                              paddingBottom: 3,
                            }}
                          >
                            <div style={{ fontWeight: "800" }}>Consent :</div>{" "}
                            The Employee hereby agrees that the Working Week
                            limit shall not apply.
                          </div>

                          <div style={{ fontWeight: "800", color: "green" }}>
                            3.0 : WITHDRAWAL OF CONSENT
                          </div>
                          <div
                            style={{
                              fontWeight: "400",
                              marginBottom: 15,
                              borderBottomWidth: 0.4,
                              paddingBottom: 3,
                            }}
                          >
                            <div style={{ fontWeight: "800" }}>3.1 :</div> The
                            Employee may end this Agreement by giving us [four
                            weeks] notice in writing.
                          </div>
                          <div
                            style={{
                              fontWeight: "400",
                              marginBottom: 15,
                              borderBottomWidth: 0.4,
                              paddingBottom: 3,
                            }}
                          >
                            <div style={{ fontWeight: "800" }}>3.2 :</div> For
                            the avoidance of doubt, any notice bringing this
                            Agreement to an end shall not be construed as notice
                            of termination of employment by the Employee.
                          </div>
                          <div
                            style={{
                              fontWeight: "400",
                              marginBottom: 15,
                              borderBottomWidth: 0.4,
                              paddingBottom: 3,
                            }}
                          >
                            <div style={{ fontWeight: "800" }}>3.3 :</div> Upon
                            the expiry of the notice period set out in Clause
                            4.1 the Working Week limit shall apply with
                            immediate effect.
                          </div>

                          <div style={{ fontWeight: "800", color: "green" }}>
                            4.0 : THE LAW
                          </div>
                          <div
                            style={{
                              fontWeight: "400",
                              marginBottom: 15,
                              borderBottomWidth: 0.4,
                              paddingBottom: 3,
                            }}
                          >
                            <div style={{ fontWeight: "800" }}>4.1 :</div> This
                            Agreement is governed by the law of [England &
                            Wales] and is subject to the exclusive jurisdiction
                            of the Courts of [England & Wales].
                          </div>
                          <div className="text-left font-semibold text-gray-600 mt-7">
                      Please tick to say YES
                    </div>
                          <div
                            style={{ flexDirection: "row", paddingVertical: 5 }} className="mt-7 bg-monday mb-5 "
                          >
                            <SimpleSwitch
                              name="HasAcceptedTerms"
                              label="l declare that l have read and understood the information above"
                              checked={values.HasAcceptedTerms === true}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mb-3 mt-5 flex flex-row-reverse">
                      <div></div>
                      {isLoadingEmployeesProcessing ? <PageLoader/> : <SubmitButton label={carer? "Update" : "Save & Next"} />}
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CarerOptAgreement;
