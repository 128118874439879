import React from "react";
import getriskAssess from "../ClientActions/getRiskAssessmentAction";
import { useSelector, useDispatch } from "react-redux";
import FormikController from "../../../components/Formik/FormikController";
import SubmitButton from "../../../components/Formik/SubmitButton";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import editRiskAssessments from "../ClientActions/EditRiskAssessmentAction";
import PageLoader from "../../../components/PageLoader";
import RiskAssessment  from "../../clients/AddClient/RiskAssessment";
import ClientInfo from '../ClientActions/GetClientInfoAction';

function EditRiskAssessment({ client }) {
  const validationSchema = Yup.object({});
  const [showModal, setShowModal] = React.useState(false);
  const [showMoving, setShowMoving] = React.useState(false);
  const [showRepositioning, setShowRepositioning] = React.useState(false);
  const [showNutrition, setShowNutrition] = React.useState(false);
  const dispatch = useDispatch();
  const { getriskAssessment,isLoadinggetriskAssessment } = useSelector((state) => state.getRiskAssess);
  const { riskAssessmentt,isLoadingriskAssessmentt } = useSelector(
    (state) => state.riskAssess
  );
 const { editRiskAssessment,isLoadingeditRiskAssessment } = useSelector((state) => state.updateRiskAssess);
 const {getClientInfo,isLoadinggetClientInfo} = useSelector(state => state.GetClientInfoSlice);
  const initialValues = {
    id: getriskAssessment.id,
    repositioningRiskAssessment: getriskAssessment ? getriskAssessment.repositioningRiskAssessment : false,
    movingAndHandlingLastReview : getriskAssessment ? getriskAssessment.movingAndHandlingLastReview : '',
    nutritionLastReview : getriskAssessment ? getriskAssessment.nutritionLastReview : "",
    repositioningLastReview : getriskAssessment ? getriskAssessment.repositioningLastReview : "",
    repositioningMedication : getriskAssessment ? getriskAssessment.repositioningMedication : "",
    movingAndHandlingMedication : getriskAssessment ? getriskAssessment.movingAndHandlingMedication : "",
    nutritionMedication : getriskAssessment ? getriskAssessment.nutritionMedication : "",
    movingAndHandlingCondition : getriskAssessment ? getriskAssessment.movingAndHandlingCondition : "",
    repositioningCondition : getriskAssessment ? getriskAssessment.repositioningCondition : "",
    nutritionCondition : getriskAssessment ? getriskAssessment.nutritionCondition : "",
    medicationRiskAssessment: getriskAssessment
      ? getriskAssessment.medicationRiskAssessment
      : false,
    personalCareRiskAssessment: getriskAssessment
      ? getriskAssessment.personalCareRiskAssessment
      : false,
    movingAndHandlingRiskAssessment: getriskAssessment
      ? getriskAssessment.movingAndHandlingRiskAssessment
      : false,
    nutritionAndHydrationRiskAssessment: getriskAssessment
      ? getriskAssessment.nutritionAndHydrationRiskAssessment
      : false,
    medicineSupportNeeds: getriskAssessment
      ? getriskAssessment.medicineSupportNeeds
      : false,
    medicineSupportNeedsComment: getriskAssessment
      ? getriskAssessment.medicineSupportNeedsComment
      : "",
    allergies: getriskAssessment ? getriskAssessment.allergies : false,
    allergiesComment: getriskAssessment
      ? getriskAssessment.allergiesComment
      : "",
    orderingAndMedicines: getriskAssessment
      ? getriskAssessment.orderingAndMedicines
      : false,
    orderingAndMedicinesComment: getriskAssessment
      ? getriskAssessment.orderingAndMedicinesComment
      : "",
    collectingReceiptTransport: getriskAssessment
      ? getriskAssessment.collectingReceiptTransport
      : false,
    collectingReceiptTransportComment: getriskAssessment
      ? getriskAssessment.collectingReceiptTransportComment
      : "",
    SafeAccessMedicines: getriskAssessment
      ? getriskAssessment.SafeAccessMedicines
      : false,
    SafeAccessMedicinesComment: getriskAssessment
      ? getriskAssessment.SafeAccessMedicinesComment
      : "",
    medicinesStoredInAccordance: getriskAssessment
      ? getriskAssessment.medicinesStoredInAccordance
      : false,
    medicinesStoredInAccordanceComment: getriskAssessment
      ? getriskAssessment.medicinesStoredInAccordanceComment
      : "",
    requireRefrigeration: getriskAssessment
      ? getriskAssessment.requireRefrigeration
      : false,
    requireRefrigerationComment: getriskAssessment
      ? getriskAssessment.requireRefrigerationComment
      : "",
    medicinesAndHeatSources: getriskAssessment
      ? getriskAssessment.medicinesAndHeatSources
      : false,
    medicinesAndHeatSourcesComment: getriskAssessment
      ? getriskAssessment.medicinesAndHeatSourcesComment
      : "",
    arrangementsDisposalDocumentation: getriskAssessment
      ? getriskAssessment.arrangementsDisposalDocumentation
      : false,
    arrangementsDisposalDocumentationComment: getriskAssessment
      ? getriskAssessment.arrangementsDisposalDocumentationComment
      : "",
    MedicinesUpToDate: getriskAssessment
      ? getriskAssessment.MedicinesUpToDate
      : false,
    MedicinesUpToDateComment: getriskAssessment
      ? getriskAssessment.MedicinesUpToDateComment
      : "",
    expiredAndUnwanted: getriskAssessment
      ? getriskAssessment.expiredAndUnwanted
      : false,
    expiredAndUnwantedComment: getriskAssessment
      ? getriskAssessment.expiredAndUnwantedComment
      : "",
    administerPrepareSpecified: getriskAssessment
      ? getriskAssessment.administerPrepareSpecified
      : false,
    administerPrepareSpecifiedComment: getriskAssessment
      ? getriskAssessment.administerPrepareSpecifiedComment
      : "",
    originalPackaging: getriskAssessment
      ? getriskAssessment.originalPackaging
      : false,
    originalPackagingComment: getriskAssessment
      ? getriskAssessment.originalPackagingComment
      : "",
    dispensedOriginalPackaging: getriskAssessment
      ? getriskAssessment.dispensedOriginalPackaging
      : false,
    dispensedOriginalPackagingComment: getriskAssessment
      ? getriskAssessment.dispensedOriginalPackagingComment
      : "",
    patientInformationLeaflet: getriskAssessment
      ? getriskAssessment.patientInformationLeaflet
      : false,
    patientInformationLeafletComment: getriskAssessment
      ? getriskAssessment.patientInformationLeafletComment
      : "",
    pharmacyLabel: getriskAssessment ? getriskAssessment.pharmacyLabel : false,
    pharmacyLabelComment: getriskAssessment
      ? getriskAssessment.pharmacyLabelComment
      : "",
    writtenAuthorization: getriskAssessment
      ? getriskAssessment.writtenAuthorization
      : false,
    writtenAuthorizationComment: getriskAssessment
      ? getriskAssessment.writtenAuthorizationComment
      : "",
    selfAdministerGP: getriskAssessment
      ? getriskAssessment.selfAdministerGP
      : false,
    selfAdministerGPComment: getriskAssessment
      ? getriskAssessment.selfAdministerGPComment
      : "",
    administerCovertly: getriskAssessment
      ? getriskAssessment.administerCovertly
      : false,
    administerCovertlyComment: getriskAssessment
      ? getriskAssessment.administerCovertlyComment
      : "",
    asRequiredInstructions: getriskAssessment
      ? getriskAssessment.asRequiredInstructions
      : false,
    asRequiredInstructionsComment: getriskAssessment
      ? getriskAssessment.asRequiredInstructionsComment
      : "",
    asRequiredApproriateOrRequest: getriskAssessment
      ? getriskAssessment.asRequiredApproriateOrRequest
      : false,
    asRequiredApproriateOrRequestComment: getriskAssessment
      ? getriskAssessment.asRequiredApproriateOrRequestComment
      : "",
    difficultySwallowingMedicine: getriskAssessment
      ? getriskAssessment.difficultySwallowingMedicine
      : false,
    difficultySwallowingMedicineComment: getriskAssessment
      ? getriskAssessment.difficultySwallowingMedicineComment
      : "",
    swallowWhole: getriskAssessment ? getriskAssessment.swallowWhole : false,
    swallowWholeComment: getriskAssessment
      ? getriskAssessment.swallowWholeComment
      : "",
    unlicensedMethod: getriskAssessment
      ? getriskAssessment.unlicensedMethod
      : false,
    unlicensedMethodComment: getriskAssessment
      ? getriskAssessment.unlicensedMethodComment
      : "",
    appropriateEquipment: getriskAssessment
      ? getriskAssessment.appropriateEquipment
      : false,
    appropriateEquipmentComment: getriskAssessment
      ? getriskAssessment.appropriateEquipmentComment
      : "",
    cytotoxicCytostatic: getriskAssessment
      ? getriskAssessment.cytotoxicCytostatic
      : false,
    cytotoxicCytostaticComment: getriskAssessment
      ? getriskAssessment.cytotoxicCytostaticComment
      : "",
    protectiveEquipment: getriskAssessment
      ? getriskAssessment.protectiveEquipment
      : false,
    protectiveEquipmentComment: getriskAssessment
      ? getriskAssessment.protectiveEquipmentComment
      : "",
    dosageSameTime: getriskAssessment
      ? getriskAssessment.dosageSameTime
      : false,
    dosageSameTimeComment: getriskAssessment
      ? getriskAssessment.dosageSameTimeComment
      : "",
    sensitiveSpecificTime: getriskAssessment
      ? getriskAssessment.sensitiveSpecificTime
      : false,
    sensitiveSpecificTimeComment: getriskAssessment
      ? getriskAssessment.sensitiveSpecificTimeComment
      : "",
    preparedMedicineAppropriateArrangements: getriskAssessment
      ? getriskAssessment.preparedMedicineAppropriateArrangements
      : false,
    preparedMedicineAppropriateArrangementsComment: getriskAssessment
      ? getriskAssessment.preparedMedicineAppropriateArrangementsComment
      : "",
    appropriateMeasuringDevice: getriskAssessment
      ? getriskAssessment.appropriateMeasuringDevice
      : false,
    appropriateMeasuringDeviceComment: getriskAssessment
      ? getriskAssessment.appropriateMeasuringDeviceComment
      : "",
    patches: getriskAssessment ? getriskAssessment.patches : false,
    patchesComment: getriskAssessment ? getriskAssessment.patchesComment : "",
    specialTechnique: getriskAssessment
      ? getriskAssessment.specialTechnique
      : false,
    specialTechniqueComment: getriskAssessment
      ? getriskAssessment.specialTechniqueComment
      : "",
    warfarin: getriskAssessment ? getriskAssessment.warfarin : false,
    warfarinComment: getriskAssessment ? getriskAssessment.warfarinComment : "",
    methotrexate: getriskAssessment ? getriskAssessment.methotrexate : false,
    methotrexateComment: getriskAssessment
      ? getriskAssessment.methotrexateComment
      : "",
    liquidWhiteParafin: getriskAssessment
      ? getriskAssessment.liquidWhiteParafin
      : false,
    liquidWhiteParafinComment: getriskAssessment
      ? getriskAssessment.liquidWhiteParafinComment
      : "",
    oxygen: getriskAssessment ? getriskAssessment.oxygen : false,
    oxygenComment: getriskAssessment ? getriskAssessment.oxygenComment : "",
    injectionTrainedAssessed: getriskAssessment
      ? getriskAssessment.injectionTrainedAssessed
      : false,
    injectionTrainedAssessedComment: getriskAssessment
      ? getriskAssessment.injectionTrainedAssessedComment
      : "",
    excessiveyTall: getriskAssessment
      ? getriskAssessment.excessiveyTall
      : false,
    excessivelyTallComment: getriskAssessment
      ? getriskAssessment.excessivelyTallComment
      : "",
    excessivelyHeavy: getriskAssessment
      ? getriskAssessment.excessivelyHeavy
      : false,
    excessivelyHeavyComment: getriskAssessment
      ? getriskAssessment.excessivelyHeavyComment
      : "",
    swollenFixedLimbs: getriskAssessment
      ? getriskAssessment.swollenFixedLimbs
      : false,
    swollenFixedLimbsComment: getriskAssessment
      ? getriskAssessment.swollenFixedLimbsComment
      : "",
    poorFeetState: getriskAssessment ? getriskAssessment.poorFeetState : false,
    poorFeetStateComment: getriskAssessment
      ? getriskAssessment.poorFeetStateComment
      : "",
    poorSkinCondition: getriskAssessment
      ? getriskAssessment.poorSkinCondition
      : false,
    poorSkinConditionComment: getriskAssessment
      ? getriskAssessment.poorSkinConditionComment
      : "",
    difficultyHolding: getriskAssessment
      ? getriskAssessment.difficultyHolding
      : false,
    difficultyHoldingComment: getriskAssessment
      ? getriskAssessment.difficultyHoldingComment
      : "",
    pressureSores: getriskAssessment ? getriskAssessment.pressureSores : false,
    pressureSoresComment: getriskAssessment
      ? getriskAssessment.pressureSoresComment
      : "",
    impairedHearing: getriskAssessment
      ? getriskAssessment.impairedHearing
      : false,
    impairedHearingComment: getriskAssessment
      ? getriskAssessment.impairedHearingComment
      : "",
    impairedSight: getriskAssessment ? getriskAssessment.impairedSight : false,
    impairedSightComment: getriskAssessment
      ? getriskAssessment.impairedSightComment
      : "",
    impairedSpeech: getriskAssessment
      ? getriskAssessment.impairedSpeech
      : false,
    impairedSpeechComment: getriskAssessment
      ? getriskAssessment.impairedSpeechComment
      : "",
    fits: getriskAssessment ? getriskAssessment.fits : false,
    fitsComment: getriskAssessment ? getriskAssessment.fitsComment : "",
    orthopaedic: getriskAssessment ? getriskAssessment.orthopaedic : false,
    orthopaedicComment: getriskAssessment
      ? getriskAssessment.orthopaedicComment
      : "",
    unstableUncoordinated: getriskAssessment
      ? getriskAssessment.unstableUncoordinated
      : false,
    unstableUncoordinatedComment: getriskAssessment
      ? getriskAssessment.unstableUncoordinatedComment
      : "",
    attachments: getriskAssessment ? getriskAssessment.attachments : false,
    attachmentsComment: getriskAssessment
      ? getriskAssessment.attachmentsComment
      : "",
    unpredictable: getriskAssessment ? getriskAssessment.unpredictable : false,
    unpredictableComment: getriskAssessment
      ? getriskAssessment.unpredictableComment
      : "",
    uncoorperative: getriskAssessment
      ? getriskAssessment.uncoorperative
      : false,
    uncoorperativeComment: getriskAssessment
      ? getriskAssessment.uncoorperativeComment
      : "",
    followingInstructions: getriskAssessment
      ? getriskAssessment.followingInstructions
      : false,
    followingInstructionsComment: getriskAssessment
      ? getriskAssessment.followingInstructionsComment
      : "",
    anxious: getriskAssessment ? getriskAssessment.anxious : false,
    anxiousComment: getriskAssessment ? getriskAssessment.anxiousComment : "",
    moodSwings: getriskAssessment ? getriskAssessment.moodSwings : false,
    moodSwingsComment: getriskAssessment
      ? getriskAssessment.moodSwingsComment
      : "",
    environmentSpaceRestricted: getriskAssessment
      ? getriskAssessment.environmentSpaceRestricted
      : false,
    environmentSpaceRestrictedComment: getriskAssessment
      ? getriskAssessment.environmentSpaceRestrictedComment
      : "",
    adequateLighting: getriskAssessment
      ? getriskAssessment.adequateLighting
      : false,
    adequateLightingComment: getriskAssessment
      ? getriskAssessment.adequateLightingComment
      : "",
    temperature: getriskAssessment ? getriskAssessment.temperature : false,
    temperatureComment: getriskAssessment
      ? getriskAssessment.temperatureComment
      : "",
    posture: getriskAssessment ? getriskAssessment.posture : false,
    postureComment: getriskAssessment ? getriskAssessment.postureComment : "",
    bedChair: getriskAssessment ? getriskAssessment.bedChair : false,
    bedChairComment: getriskAssessment ? getriskAssessment.bedChairComment : "",
    otherHazards: getriskAssessment ? getriskAssessment.otherHazards : false,
    otherHazardsComment: getriskAssessment
      ? getriskAssessment.otherHazardsComment
      : "",
    bedMovement: getriskAssessment ? getriskAssessment.bedMovement : false,
    bedMovementComment: getriskAssessment
      ? getriskAssessment.bedMovementComment
      : "",
    InOutOfBed: getriskAssessment ? getriskAssessment.InOutOfBed : false,
    InOutOfBedComment: getriskAssessment
      ? getriskAssessment.InOutOfBedComment
      : "",
    sittingBalance: getriskAssessment
      ? getriskAssessment.sittingBalance
      : false,
    sittingBalanceComment: getriskAssessment
      ? getriskAssessment.sittingBalanceComment
      : "",
    bedChairWheelchair: getriskAssessment
      ? getriskAssessment.bedChairWheelchair
      : false,
    bedChairWheelchairComment: getriskAssessment
      ? getriskAssessment.bedChairWheelchairComment
      : "",
    bathingShowering: getriskAssessment
      ? getriskAssessment.bathingShowering
      : false,
    bathingShoweringComment: getriskAssessment
      ? getriskAssessment.bathingShoweringComment
      : "",
    walking: getriskAssessment ? getriskAssessment.walking : false,
    walkingComment: getriskAssessment ? getriskAssessment.walkingComment : "",
    stairsSteps: getriskAssessment ? getriskAssessment.stairsSteps : false,
    stairsStepsComment: getriskAssessment
      ? getriskAssessment.stairsStepsComment
      : "",
    dressing: getriskAssessment ? getriskAssessment.dressing : false,
    dressingComment: getriskAssessment ? getriskAssessment.dressingComment : "",
    otherSafeSystemofWork: getriskAssessment
      ? getriskAssessment.otherSafeSystemofWork
      : false,
    otherSafeSystemofWorkComment: getriskAssessment
      ? getriskAssessment.otherSafeSystemofWorkComment
      : "",
    summary: getriskAssessment ? getriskAssessment.summary : "",
    difficultySwallowing: getriskAssessment
      ? getriskAssessment.difficultySwallowing
      : false,
    difficultySwallowingComment: getriskAssessment
      ? getriskAssessment.difficultySwallowingComment
      : "",
    appetiteLoss: getriskAssessment ? getriskAssessment.appetiteLoss : false,
    appetiteLossComment: getriskAssessment
      ? getriskAssessment.appetiteLossComment
      : "",
    hasPEG: getriskAssessment ? getriskAssessment.hasPEG : false,
    hasPEGComment: getriskAssessment ? getriskAssessment.hasPEGComment : "",
    haveTracheostomy: getriskAssessment
      ? getriskAssessment.haveTracheostomy
      : false,
    haveTracheostomyComment: getriskAssessment
      ? getriskAssessment.haveTracheostomyComment
      : "",
    cognitiveFunction: getriskAssessment
      ? getriskAssessment.cognitiveFunction
      : false,
    cognitiveFunctionComment: getriskAssessment
      ? getriskAssessment.cognitiveFunctionComment
      : "",
    alertness: getriskAssessment ? getriskAssessment.alertness : false,
    alertnessComment: getriskAssessment
      ? getriskAssessment.alertnessComment
      : "",
    posturalControl: getriskAssessment
      ? getriskAssessment.posturalControl
      : false,
    posturalControlComment: getriskAssessment
      ? getriskAssessment.posturalControlComment
      : "",
    generalHealth: getriskAssessment ? getriskAssessment.generalHealth : false,
    generalHealtComment: getriskAssessment
      ? getriskAssessment.generalHealtComment
      : "",
    fatigue: getriskAssessment ? getriskAssessment.fatigue : false,
    fatigueComment: getriskAssessment ? getriskAssessment.fatigueComment : "",
    oralHealth: getriskAssessment ? getriskAssessment.oralHealth : false,
    oralHealthComment: getriskAssessment
      ? getriskAssessment.oralHealthComment
      : "",
    respiratoryFunction: getriskAssessment
      ? getriskAssessment.respiratoryFunction
      : false,
    respiratoryFunctionComment: getriskAssessment
      ? getriskAssessment.respiratoryFunctionComment
      : "",
    behaviouralMentalHealth: getriskAssessment
      ? getriskAssessment.behaviouralMentalHealth
      : false,
    behaviouralMentalHealthComment: getriskAssessment
      ? getriskAssessment.behaviouralMentalHealthComment
      : "",
    pain: getriskAssessment ? getriskAssessment.pain : false,
    painComment: getriskAssessment ? getriskAssessment.painComment : "",
    pureDiet: getriskAssessment ? getriskAssessment.pureDiet : false,
    pureDietComment: getriskAssessment ? getriskAssessment.pureDietComment : "",
    drinkThichener: getriskAssessment
      ? getriskAssessment.drinkThichener
      : false,
    drinkThichenerComment: getriskAssessment
      ? getriskAssessment.drinkThichenerComment
      : "",
    medicalConditions: getriskAssessment
      ? getriskAssessment.medicalConditions
      : false,
    medicalConditionsComment: getriskAssessment
      ? getriskAssessment.medicalConditionsComment
      : "",
    excessiveSecretions: getriskAssessment
      ? getriskAssessment.excessiveSecretions
      : false,
    excessiveSecretionsComment: getriskAssessment
      ? getriskAssessment.excessiveSecretionsComment
      : "",
    repositioningExcessivelyTall: getriskAssessment ? getriskAssessment.repositioningExcessivelyTall : false,
    repositioningExcessivelyTallComment: getriskAssessment ? getriskAssessment.repositioningExcessivelyTallComment : "",
    repositioningExcessivelyHeavy: getriskAssessment ? getriskAssessment.repositioningExcessivelyHeavy : false,
    repositioningExcessivelyHeavyComment: getriskAssessment ? getriskAssessment.repositioningExcessivelyHeavyComment : "",
    repositioningSwollenFixedLimbs: getriskAssessment ? getriskAssessment.repositioningSwollenFixedLimbs : false,
    repositioningSwollenFixedLimbsComment: getriskAssessment ? getriskAssessment.repositioningSwollenFixedLimbsComment : "",
    repositioningPoorFeetState: getriskAssessment ? getriskAssessment.repositioningPoorFeetState : false,
    repositioningPoorFeetStateComment: getriskAssessment ? getriskAssessment.repositioningPoorFeetStateComment : "",
    repositioningPoorSkinCondition: getriskAssessment ? getriskAssessment.repositioningPoorSkinCondition : false,
    repositioningPoorSkinConditionComment: getriskAssessment ? getriskAssessment.repositioningPoorSkinConditionComment : "",
    repositioningDifficultyHolding: getriskAssessment ? getriskAssessment.repositioningDifficultyHolding : false,
    repositioningDifficultyHoldingComment: getriskAssessment ? getriskAssessment.repositioningDifficultyHoldingComment : "",
    repositioningPressureSores: getriskAssessment ? getriskAssessment.repositioningPressureSores : false,
    repositioningPressureSoresComment: getriskAssessment ? getriskAssessment.repositioningPressureSoresComment : "",
    repositioningImpairedHearing: getriskAssessment ? getriskAssessment.repositioningImpairedHearing : false,
    repositioningImpairedHearingComment: getriskAssessment ? getriskAssessment.repositioningImpairedHearingComment : "",
    repositioningImpairedSight: getriskAssessment ? getriskAssessment.repositioningImpairedSight : false,
    repositioningImpairedSightComment: getriskAssessment ? getriskAssessment.repositioningImpairedSightComment : "",
    repositioningmpairedSpeech: getriskAssessment ? getriskAssessment.repositioningmpairedSpeech : false,
    repositioningImpairedSpeechComment: getriskAssessment ? getriskAssessment.repositioningImpairedSpeechComment : "",
    repositioningFits: getriskAssessment ? getriskAssessment.repositioningFits : false,
    repositioningFitsComment: getriskAssessment ? getriskAssessment.repositioningFitsComment : "",
    repositioningOrthopaedic: getriskAssessment ? getriskAssessment.repositioningOrthopaedic : false,
    repositioningOrthopaedicComment: getriskAssessment ? getriskAssessment.repositioningOrthopaedicComment : "",
    repositioningUnstableUncoordinated: getriskAssessment ? getriskAssessment.repositioningUnstableUncoordinated : false,
    repositioningUnstableUncoordinatedComment: getriskAssessment ? getriskAssessment.repositioningUnstableUncoordinatedComment : "",
    repositioningAttachments: getriskAssessment ? getriskAssessment.repositioningAttachments : false,
    repositioningAttachmentsComment: getriskAssessment ? getriskAssessment.repositioningAttachmentsComment : "",
    repositioningUnpredictable: getriskAssessment ? getriskAssessment.repositioningUnpredictable : false,
    repositioningUnpredictableComment: getriskAssessment ? getriskAssessment.repositioningUnpredictableComment : "",
    repositioningUncoorperative: getriskAssessment ? getriskAssessment.repositioningUncoorperative : false,
    repositioningUncoorperativeComment: getriskAssessment ? getriskAssessment.repositioningUncoorperativeComment : "",
    repositioningFollowingInstructions: getriskAssessment ? getriskAssessment.repositioningFollowingInstructions : false,
    repositioningFollowingInstructionsComment: getriskAssessment ? getriskAssessment.repositioningFollowingInstructionsComment : "",
    repositioningAnxious: getriskAssessment ? getriskAssessment.repositioningAnxious : false,
    repositioningAnxiousComment: getriskAssessment ? getriskAssessment.repositioningAnxiousComment : "",
    repositioningMoodSwings: getriskAssessment ? getriskAssessment.repositioningMoodSwings : false,
    repositioningMoodSwingsComment: getriskAssessment ? getriskAssessment.repositioningMoodSwingsComment : "",
    repositioningEnvironmentSpaceRestricted: getriskAssessment ? getriskAssessment.repositioningEnvironmentSpaceRestricted : false,
    repositioningEnvironmentSpaceRestrictedComment: getriskAssessment ? getriskAssessment.repositioningEnvironmentSpaceRestrictedComment : "",
    repositioningAdequateLighting: getriskAssessment ? getriskAssessment.repositioningAdequateLighting : false,
    repositioningAdequateLightingComment: getriskAssessment ? getriskAssessment.repositioningAdequateLightingComment : "",
    repositioningTemperature: getriskAssessment ? getriskAssessment.repositioningTemperature : false,
    repositioningTemperatureComment: getriskAssessment ? getriskAssessment.repositioningTemperatureComment : "",
    repositioningPosture: getriskAssessment ? getriskAssessment.repositioningPosture : false,
    repositioningPostureComment: getriskAssessment ? getriskAssessment.repositioningPostureComment : "",
    repositioningBedChair: getriskAssessment ? getriskAssessment.repositioningBedChair : false,
    repositioningBedChairComment: getriskAssessment ? getriskAssessment.repositioningBedChairComment : "",
    repositioningOtherHazards: getriskAssessment ? getriskAssessment.repositioningOtherHazards : false,
    repositioningOtherHazardsComment: getriskAssessment ? getriskAssessment.repositioningOtherHazardsComment : "",
    repositioningBedMovement: getriskAssessment ? getriskAssessment.repositioningBedMovement : false,
    repositioningBedMovementAssistance: getriskAssessment ? getriskAssessment.repositioningBedMovementAssistance : "",
    repositioningBedMovementNumberOfStaff: getriskAssessment ? getriskAssessment.repositioningBedMovementNumberOfStaff : "",
    repositioningBedMovementEquipment: getriskAssessment ? getriskAssessment.repositioningBedMovementEquipment : "",
    repositioningBedMovementFurtherPrecautions: getriskAssessment ? getriskAssessment.repositioningBedMovementFurtherPrecautions : "",
    repositioningInOutOfBed: getriskAssessment ? getriskAssessment.repositioningInOutOfBed : false,
    repositioningInOutOfBedAssistance: getriskAssessment ? getriskAssessment.repositioningInOutOfBedAssistance : "",
    repositioningInOutOfBedNumberOfStaff: getriskAssessment ? getriskAssessment.repositioningInOutOfBedNumberOfStaff : "",
    repositioningInOutOfBedEquipment: getriskAssessment ? getriskAssessment.repositioningInOutOfBedEquipment : "",
    repositioningInOutOfBedFurtherPrecautions: getriskAssessment ? getriskAssessment.repositioningInOutOfBedFurtherPrecautions : "",
    repositioningSittingBalance: getriskAssessment ? getriskAssessment.repositioningSittingBalance : false,
    repositioningSittingBalanceAssistance: getriskAssessment ? getriskAssessment.repositioningSittingBalanceAssistance : "",
    repositioningSittingBalanceNumberOfStaff: getriskAssessment ? getriskAssessment.repositioningSittingBalanceNumberOfStaff : "",
    repositioningSittingBalanceEquipment: getriskAssessment ? getriskAssessment.repositioningSittingBalanceEquipment : "",
    repositioningSittingBalanceFurtherPrecautions: getriskAssessment ? getriskAssessment.repositioningSittingBalanceFurtherPrecautions : "",
    repositioningBedChairWheelchair: getriskAssessment ? getriskAssessment.repositioningBedChairWheelchair : false,
    repositioningBedChairWheelchairAssistance: getriskAssessment ? getriskAssessment.repositioningBedChairWheelchairAssistance : "",
    repositioningBedChairWheelchairNumberOfStaff: getriskAssessment ? getriskAssessment.repositioningBedChairWheelchairNumberOfStaff : "",
    repositioningBedChairWheelchairEquipment: getriskAssessment ? getriskAssessment.repositioningBedChairWheelchairEquipment : "",
    repositioningBedChairWheelchairFurtherPrecautions: getriskAssessment ? getriskAssessment.repositioningBedChairWheelchairFurtherPrecautions : "",
    repositioningBathingShowering: getriskAssessment ? getriskAssessment.repositioningBathingShowering : false,
    repositioningBathingShoweringAssistance: getriskAssessment ? getriskAssessment.repositioningBathingShoweringAssistance : "",
    repositioningBathingShoweringNumberOfStaff: getriskAssessment ? getriskAssessment.repositioningBathingShoweringNumberOfStaff : "",
    repositioningBathingShoweringEquipment: getriskAssessment ? getriskAssessment.repositioningBathingShoweringEquipment : "",
    repositioningBathingShoweringFurtherPrecautions: getriskAssessment ? getriskAssessment.repositioningBathingShoweringFurtherPrecautions : "",
    repositioningWalking: getriskAssessment ? getriskAssessment.repositioningWalking : false,
    repositioningWalkingAssistance: getriskAssessment ? getriskAssessment.repositioningWalkingAssistance : "",
    repositioningWalkingNumberOfStaff: getriskAssessment ? getriskAssessment.repositioningWalkingNumberOfStaff : "",
    repositioningWalkingEquipment: getriskAssessment ? getriskAssessment.repositioningWalkingEquipment : "",
    repositioningWalkingFurtherPrecautions: getriskAssessment ? getriskAssessment.repositioningWalkingFurtherPrecautions : "",
    repositioningStairsSteps: getriskAssessment ? getriskAssessment.repositioningStairsSteps : false,
    repositioningStairsStepsAssistance: getriskAssessment ? getriskAssessment.repositioningStairsStepsAssistance : "",
    repositioningStairsStepsNumberOfStaff: getriskAssessment ? getriskAssessment.repositioningStairsStepsNumberOfStaff : "",
    repositioningStairsStepsEquipment: getriskAssessment ? getriskAssessment.repositioningStairsStepsEquipment : "",
    repositioningStairsStepsFurtherPrecautions: getriskAssessment ? getriskAssessment.repositioningStairsStepsFurtherPrecautions : "",
    repositioningDressing: getriskAssessment ? getriskAssessment.repositioningDressing : false,
    repositioningDressingAssistance: getriskAssessment ? getriskAssessment.repositioningDressingAssistance : "",
    repositioningDressingNumberOfStaff: getriskAssessment ? getriskAssessment.repositioningDressingNumberOfStaff : "",
    repositioningDressingEquipment: getriskAssessment ? getriskAssessment.repositioningDressingEquipment : "",
    repositioningDressingFurtherPrecautions: getriskAssessment ? getriskAssessment.repositioningDressingFurtherPrecautions : "",
    repositioningOtherSafeSystemofWork: getriskAssessment ? getriskAssessment.repositioningOtherSafeSystemofWork : false,
    repositioningOtherSafeSystemofWorkAssistance: getriskAssessment ? getriskAssessment.repositioningOtherSafeSystemofWorkAssistance : "",
    repositioningOtherSafeSystemofWorkNumberOfStaff: getriskAssessment ? getriskAssessment.repositioningOtherSafeSystemofWorkNumberOfStaff : "",
    repositioningOtherSafeSystemofWorkEquipment: getriskAssessment ? getriskAssessment.repositioningOtherSafeSystemofWorkEquipment : "",
    repositioningOtherSafeSystemofWorkFurtherPrecautions: getriskAssessment ? getriskAssessment.repositioningOtherSafeSystemofWorkFurtherPrecautions : "",
    repositioningSummary: getriskAssessment ? getriskAssessment.repositioningSummary : false,
  };

  React.useEffect(
    (data) => {
      data = client.id;
      const timer = setTimeout(dispatch(getriskAssess(data)), 0);
      clearTimeout(timer);
    },


    [editRiskAssessment]
  );

  React.useEffect(()=>{
    dispatch(ClientInfo({
      id:client.id
    }))
  },[])

  const handleSubmit = (data) => {
    data.id = getriskAssessment.id;
    dispatch(editRiskAssessments(data));
    // setShowModal(false);
  };
  const handleOpen = (id) => {
    setShowModal(true);
  };
  
  const handleOpenMoving = (id) => {
    setShowMoving(true);
  };

  const handleOpenRepositioning = (id) => {
    setShowRepositioning(true);
  };
  const handleOpenNutrition = (id) => {
    setShowNutrition(true);}

  const Head = (props) => {
    return (
      <div className="text-left border-b">
        <label className="text-md font-bold text-button">{props.title}</label>
      </div>
    );
  };
  // (getClientInfo && !getClientInfo?.repositioningRiskAssessment && !getClientInfo?.medicationRiskAssessment && !getClientInfo?.nutritionAndHydrationRiskAssessment && !getClientInfo?.movingAndHandlingRiskAssessment) ? <RiskAssessment getClientInfo={getClientInfo}/> :

  return (
    <div>
      <div className="w-full p-5">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                handleSubmit(values);
              }}
            >
              {({ setFieldValue, values, ...rest }) => {
                return (
                  <Form className="text-left">
                   
                    <div className="border p-5 rounded flex flex-col space-y-2">
                      <div className="flex justify-between border bg-wednesday p-3 rounded">
                        {/* <Field
                          type="checkbox"
                          name="medicationRiskAssessment"
                          value={true}
                          checked={values.medicationRiskAssessment === true}
                        /> */}
                        <span className="ml-1 mt-1 text-lg font-semibold text-gray-500 ">
                          Medication Risk Assessment
                        </span>
                        <button
                          className=" text-white bg-button border hover:bg-white hover:text-gray-700 hover:border hover:border-gray-300 hover:underline decoration-secondary focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-1 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                          type="button"
                          onClick={() => handleOpen()}
                        >
                          View
                        </button>
                      </div>

                      {showModal ? (
                        <>
                          <div className="justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                            <div className="relative w-auto my-6 mx-auto max-w-7xl">
                              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                  <h3 className="text-xl font-semibold text-gray-500">
                                    <span className="text-green">
                                      Medication Risk Assessment
                                    </span>
                                  </h3>
                                  <button
                                    className="p-1 ml-10 bg-transparent border-0 text-button float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                    onClick={() => setShowModal(false)}
                                  >
                                    ×
                                  </button>
                                </div>

                                <div className="relative p-5 flex-auto">
                                  <div className="">
                                    <div></div>
                                  </div>
                                </div>
                                <div className="">
                                  {showModal && (
                                    <div className="mb-3 p-5">
                                      <div className="">
                                        <Head title="Please fill in the details" />
                                      </div>
                                      <div className="grid grid-cols-2 gap-2">
                                        <div>
                                          <label>
                                            <Field
                                              type="checkbox"
                                              name="medicineSupportNeeds"
                                              value={true}
                                              checked={
                                                values.medicineSupportNeeds ===
                                                true
                                              }
                                            />
                                            <span className="ml-1">
                                              {" "}
                                              01. Are the person's medicine
                                              support needs clearly identified
                                              in the provider's care plan ?
                                            </span>
                                          </label>

                                          <div>
                                            <div className="mt-2">
                                              <FormikController
                                                control="textArea"
                                                type="text"
                                                name="medicineSupportNeedsComment"
                                                label={
                                                  values.medicineSupportNeeds
                                                    ? "Details of action taken"
                                                    : "What action is required ?"
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div>
                                          <label>
                                            <Field
                                              type="checkbox"
                                              name="allergies"
                                              value={true}
                                              checked={
                                                values.allergies === true
                                              }
                                            />
                                            <span className="ml-1">
                                              {" "}
                                              02. Are the person's allergies (or
                                              'no known allergies') documented
                                              in the care plan and on the MAR ?
                                            </span>
                                          </label>

                                          <div>
                                            <div className="mt-2">
                                              <FormikController
                                                control="textArea"
                                                type="text"
                                                name="allergiesComment"
                                                label={
                                                  values.allergies
                                                    ? "Details of action taken"
                                                    : "What action is required ?"
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div>
                                          <label>
                                            <Field
                                              type="checkbox"
                                              name="orderingAndMedicines"
                                              value={true}
                                              checked={
                                                values.orderingAndMedicines ===
                                                true
                                              }
                                            />
                                            <span className="ml-1">
                                              {" "}
                                              03. Are arrangements for ordering
                                              and purchasing medicines
                                              documented in the care plan ?
                                            </span>
                                          </label>

                                          <div>
                                            <div className="mt-8">
                                              <FormikController
                                                control="textArea"
                                                type="text"
                                                name="orderingAndMedicinesComment"
                                                label={
                                                  values.orderingAndMedicines
                                                    ? "Details of action taken"
                                                    : "What action is required ?"
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>

                                        <div>
                                          <label>
                                            <Field
                                              type="checkbox"
                                              name="otc"
                                              value={true}
                                              checked={values.otc === true}
                                            />
                                            <span className="ml-1">
                                              {" "}
                                              04. If care workers are to
                                              purchase 'Over theOtc) medicine at
                                              the request of a prescriber or
                                              healthcare professional, is the
                                              written, signed authorisation
                                              available ?
                                            </span>
                                          </label>

                                          <div>
                                            <div className="mt-8">
                                              <FormikController
                                                control="textArea"
                                                type="text"
                                                name="otcComments"
                                                label={
                                                  values.otc
                                                    ? "Details of action taken"
                                                    : "What action is required ?"
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>

                                        <div>
                                          <label>
                                            <Field
                                              type="checkbox"
                                              name="collectingReceiptTransport"
                                              value={true}
                                              checked={
                                                values.collectingReceiptTransport ===
                                                true
                                              }
                                            />
                                            <span className="ml-1">
                                              {" "}
                                              05. Are the arrangements for
                                              collecting, receipt and transport
                                              of medicines documented in the
                                              care plan ?
                                            </span>
                                          </label>

                                          <div>
                                            <div className="mt-2">
                                              <FormikController
                                                control="textArea"
                                                type="text"
                                                name="collectingReceiptTransportComment"
                                                label={
                                                  values.collectingReceiptTransport
                                                    ? "Details of action taken"
                                                    : "What action is required ?"
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>

                                        <div>
                                          <label>
                                            <Field
                                              type="checkbox"
                                              name="medTrans"
                                              value={true}
                                              checked={values.medTrans === true}
                                            />
                                            <span className="ml-1">
                                              06. Where a care worker is
                                              responsible for transporting
                                              medicines, has a risk assessment
                                              been carried out for the task ?
                                            </span>
                                          </label>

                                          <div>
                                            <div className="mt-2">
                                              <FormikController
                                                control="textArea"
                                                type="text"
                                                name="medTransComment"
                                                label={
                                                  values.medTrans
                                                    ? "Details of action taken"
                                                    : "What action is required ?"
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>

                                        <div>
                                          <label>
                                            <Field
                                              type="checkbox"
                                              name="SafeAccessMedicines"
                                              value={true}
                                              checked={
                                                values.SafeAccessMedicines ===
                                                true
                                              }
                                            />
                                            <span className="ml-1">
                                              07. If it is not safe for the
                                              person to have access to their
                                              medicines, is this documented in
                                              the care plan and appropriate
                                              storage identified ?
                                            </span>
                                          </label>

                                          <div>
                                            <div className="mt-2">
                                              <FormikController
                                                control="textArea"
                                                type="text"
                                                name="SafeAccessMedicinesComment"
                                                label={
                                                  values.SafeAccessMedicines
                                                    ? "Details of action taken"
                                                    : "What action is required ?"
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>

                                        <div>
                                          <label>
                                            <Field
                                              type="checkbox"
                                              name="SafeAccessMedicines"
                                              value={true}
                                              checked={
                                                values.SafeAccessMedicines ===
                                                true
                                              }
                                            />
                                            <span className="ml-1">
                                              08. If it is not safe for the
                                              person to have access to their
                                              medicines, is this documented in
                                              the care plan and appropriate
                                              storage identified ?
                                            </span>
                                          </label>

                                          <div>
                                            <div className="mt-2">
                                              <FormikController
                                                control="textArea"
                                                type="text"
                                                name="SafeAccessMedicinesComment"
                                                label={
                                                  values.SafeAccessMedicines
                                                    ? "Details of action taken"
                                                    : "What action is required ?"
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>

                                        <div>
                                          <label>
                                            <Field
                                              type="checkbox"
                                              name="medicinesStoredInAccordance"
                                              value={true}
                                              checked={
                                                values.medicinesStoredInAccordance ===
                                                true
                                              }
                                            />
                                            <span className="ml-1">
                                              09. Are all medicines stored in
                                              accordance with the Patient
                                              Information Leaflet and the
                                              Medicines in Domiciliary Settings
                                              Policy ?
                                            </span>
                                          </label>

                                          <div>
                                            <div className="mt-2">
                                              <FormikController
                                                control="textArea"
                                                type="text"
                                                name="medicinesStoredInAccordanceComment"
                                                label={
                                                  values.medicinesStoredInAccordance
                                                    ? "Details of action taken"
                                                    : "What action is required ?"
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>

                                        <div>
                                          <label>
                                            <Field
                                              type="checkbox"
                                              name="requireRefrigeration"
                                              value={true}
                                              checked={
                                                values.requireRefrigeration ===
                                                true
                                              }
                                            />
                                            <span className="ml-1">
                                              10. Are medicines that require
                                              refrigeration stored in a
                                              refrigerator ?
                                            </span>
                                          </label>

                                          <div>
                                            <div className="mt-8">
                                              <FormikController
                                                control="textArea"
                                                type="text"
                                                name="requireRefrigerationComment"
                                                label={
                                                  values.requireRefrigeration
                                                    ? "Details of action taken"
                                                    : "What action is required ?"
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div>
                                          <label>
                                            <Field
                                              type="checkbox"
                                              name="medicinesAndHeatSources"
                                              value={true}
                                              checked={
                                                values.medicinesAndHeatSources ===
                                                true
                                              }
                                            />
                                            <span className="ml-1">
                                              11. Are all medicines kept away
                                              from heat sources ?
                                            </span>
                                          </label>

                                          <div>
                                            <div className="mt-2">
                                              <FormikController
                                                control="textArea"
                                                type="text"
                                                name="medicinesAndHeatSourcesComment"
                                                label={
                                                  values.medicinesAndHeatSources
                                                    ? "Details of action taken"
                                                    : "What action is required ?"
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>

                                        <div>
                                          <label>
                                            <Field
                                              type="checkbox"
                                              name="arrangementsDisposalDocumentation"
                                              value={true}
                                              checked={
                                                values.arrangementsDisposalDocumentation ===
                                                true
                                              }
                                            />
                                            <span className="ml-1">
                                              12. Are the arrangements for
                                              disposal of medicines documented
                                              in the care plan ?
                                            </span>
                                          </label>

                                          <div>
                                            <div className="mt-2">
                                              <FormikController
                                                control="textArea"
                                                type="text"
                                                name="arrangementsDisposalDocumentationComment"
                                                label={
                                                  values.arrangementsDisposalDocumentation
                                                    ? "Details of action taken"
                                                    : "What action is required ?"
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>

                                        <div>
                                          <label>
                                            <Field
                                              type="checkbox"
                                              name="MedicinesUpToDate"
                                              value={true}
                                              checked={
                                                values.MedicinesUpToDate ===
                                                true
                                              }
                                            />
                                            <span className="ml-1">
                                              13. Are all medicines in date
                                              including those with reduced
                                              expiry dates after opening ?
                                            </span>
                                          </label>

                                          <div>
                                            <div className="mt-2">
                                              <FormikController
                                                control="textArea"
                                                type="text"
                                                name="MedicinesUpToDateComment"
                                                label={
                                                  values.MedicinesUpToDate
                                                    ? "Details of action taken"
                                                    : "What action is required ?"
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>

                                        <div>
                                          <label>
                                            <Field
                                              type="checkbox"
                                              name="expiredAndUnwanted"
                                              value={true}
                                              checked={
                                                values.expiredAndUnwanted ===
                                                true
                                              }
                                            />
                                            <span className="ml-1">
                                              14. Have expired and unwanted
                                              medicines been removed from the
                                              premises ?
                                            </span>
                                          </label>

                                          <div>
                                            <div className="mt-2">
                                              <FormikController
                                                control="textArea"
                                                type="text"
                                                name="expiredAndUnwantedComment"
                                                label={
                                                  values.expiredAndUnwanted
                                                    ? "Details of action taken"
                                                    : "What action is required ?"
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>

                                        <div>
                                          <label>
                                            <Field
                                              type="checkbox"
                                              name="administerPrepareSpecified"
                                              value={true}
                                              checked={
                                                values.administerPrepareSpecified ===
                                                true
                                              }
                                            />
                                            <span className="ml-1">
                                              15. If medicines are to be
                                              administered, prepared or
                                              specified medicines prompted, is
                                              there a current MAR Chart
                                              available ?
                                            </span>
                                          </label>

                                          <div>
                                            <div className="mt-2">
                                              <FormikController
                                                control="textArea"
                                                type="text"
                                                name="administerPrepareSpecifiedComment"
                                                label={
                                                  values.administerPrepareSpecified
                                                    ? "Details of action taken"
                                                    : "What action is required ?"
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>

                                        <div>
                                          <label>
                                            <Field
                                              type="checkbox"
                                              name="originalPackaging"
                                              value={true}
                                              checked={
                                                values.originalPackaging ===
                                                true
                                              }
                                            />
                                            <span className="ml-1">
                                              16. Does the person's supply of
                                              medicines in their original
                                              dispensed packaging and OTC
                                              medicines in their original
                                              packaging ?
                                            </span>
                                          </label>

                                          <div>
                                            <div className="mt-2">
                                              <FormikController
                                                control="textArea"
                                                type="text"
                                                name="originalPackagingComment"
                                                label={
                                                  values.originalPackaging
                                                    ? "Details of action taken"
                                                    : "What action is required ?"
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>

                                        <div>
                                          <label>
                                            <Field
                                              type="checkbox"
                                              name="dispensedOriginalPackaging"
                                              value={true}
                                              checked={
                                                values.dispensedOriginalPackaging ===
                                                true
                                              }
                                            />
                                            <span className="ml-1">
                                              17. Are all dispensed medicines in
                                              their original dispensed packaging
                                              and OTC medicines in their
                                              original packaging ?
                                            </span>
                                          </label>

                                          <div>
                                            <div className="mt-2">
                                              <FormikController
                                                control="textArea"
                                                type="text"
                                                name="dispensedOriginalPackagingComment"
                                                label={
                                                  values.dispensedOriginalPackaging
                                                    ? "Details of action taken"
                                                    : "What action is required ?"
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>

                                        <div>
                                          <label>
                                            <Field
                                              type="checkbox"
                                              name="patientInformationLeaflet"
                                              value={true}
                                              checked={
                                                values.patientInformationLeaflet ===
                                                true
                                              }
                                            />
                                            <span className="ml-1">
                                              18. Is there a Patient Information
                                              Leaflet for each medicine ?
                                            </span>
                                          </label>

                                          <div>
                                            <div className="mt-8">
                                              <FormikController
                                                control="textArea"
                                                type="text"
                                                name="patientInformationLeafletComment"
                                                label={
                                                  values.patientInformationLeaflet
                                                    ? "Details of action taken"
                                                    : "What action is required ?"
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>

                                        <div>
                                          <label>
                                            <Field
                                              type="checkbox"
                                              name="pharmacyLabel"
                                              value={true}
                                              checked={
                                                values.pharmacyLabel === true
                                              }
                                            />
                                            <span className="ml-1">
                                              19. Are all dispensed medicines
                                              clearly labelled with a pharmacy
                                              label ?
                                            </span>
                                          </label>

                                          <div>
                                            <div className="mt-8">
                                              <FormikController
                                                control="textArea"
                                                type="text"
                                                name="pharmacyLabelComment"
                                                label={
                                                  values.pharmacyLabel
                                                    ? "Details of action taken"
                                                    : "What action is required ?"
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>

                                        <div>
                                          <label>
                                            <Field
                                              type="checkbox"
                                              name="writtenAuthorization"
                                              value={true}
                                              checked={
                                                values.writtenAuthorization ===
                                                true
                                              }
                                            />
                                            <span className="ml-1">
                                              20. Do OTC medicines that are to
                                              be administered by care workers
                                              have written authorisation from
                                              the prescriber, podiatrist or
                                              optometrist ?
                                            </span>
                                          </label>

                                          <div>
                                            <div className="mt-2">
                                              <FormikController
                                                control="textArea"
                                                type="text"
                                                name="writtenAuthorizationComment"
                                                label={
                                                  values.writtenAuthorization
                                                    ? "Details of action taken"
                                                    : "What action is required ?"
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>

                                        <div>
                                          <label>
                                            <Field
                                              type="checkbox"
                                              name="selfAdministerGP"
                                              value={true}
                                              checked={
                                                values.selfAdministerGP === true
                                              }
                                            />
                                            <span className="ml-1">
                                              21. If the person is
                                              self-administering OTC medicines,
                                              is their GP aware ?
                                            </span>
                                          </label>

                                          <div>
                                            <div className="mt-8">
                                              <FormikController
                                                control="textArea"
                                                type="text"
                                                name="selfAdministerGPComment"
                                                label={
                                                  values.selfAdministerGP
                                                    ? "Details of action taken"
                                                    : "What action is required ?"
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>

                                        <div>
                                          <label>
                                            <Field
                                              type="checkbox"
                                              name="administerCovertly"
                                              value={true}
                                              checked={
                                                values.administerCovertly ===
                                                true
                                              }
                                            />
                                            <span className="ml-1">
                                              22. If any medicines are to be
                                              administered covertly is this
                                              documented in the care plan ?
                                            </span>
                                          </label>

                                          <div>
                                            <div className="mt-2">
                                              <FormikController
                                                control="textArea"
                                                type="text"
                                                name="administerCovertlyComment"
                                                label={
                                                  values.administerCovertly
                                                    ? "Details of action taken"
                                                    : "What action is required ?"
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>

                                        <div>
                                          <label>
                                            <Field
                                              type="checkbox"
                                              name="asRequiredInstructions"
                                              value={true}
                                              checked={
                                                values.asRequiredInstructions ===
                                                true
                                              }
                                            />
                                            <span className="ml-1">
                                              23. Are there clear instructions
                                              for 'as-required' medicines ?
                                            </span>
                                          </label>

                                          <div>
                                            <div className="mt-8">
                                              <FormikController
                                                control="textArea"
                                                type="text"
                                                name="asRequiredInstructionsComment"
                                                label={
                                                  values.asRequiredInstructions
                                                    ? "Details of action taken"
                                                    : "What action is required ?"
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>

                                        <div>
                                          <label>
                                            <Field
                                              type="checkbox"
                                              name="asRequiredApproriateOrRequest"
                                              value={true}
                                              checked={
                                                values.asRequiredApproriateOrRequest ===
                                                true
                                              }
                                            />
                                            <span className="ml-1">
                                              23. Is it documented whether the
                                              'as required' medicine is to be
                                              offered on every appropriate visit
                                              or only at the request of the
                                              person ?
                                            </span>
                                          </label>

                                          <div>
                                            <div className="mt-2">
                                              <FormikController
                                                control="textArea"
                                                type="text"
                                                name="asRequiredApproriateOrRequestComment"
                                                label={
                                                  values.asRequiredApproriateOrRequest
                                                    ? "Details of action taken"
                                                    : "What action is required ?"
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>

                                        <div>
                                          <label>
                                            <Field
                                              type="checkbox"
                                              name="difficultySwallowingMedicine"
                                              value={true}
                                              checked={
                                                values.difficultySwallowingMedicine ===
                                                true
                                              }
                                            />
                                            <span className="ml-1">
                                              24. Is the person has difficulty
                                              swallowing their medicines, is
                                              thus documented in the care plan ?
                                            </span>
                                          </label>

                                          <div>
                                            <div className="mt-2">
                                              <FormikController
                                                control="textArea"
                                                type="text"
                                                name="difficultySwallowingMedicineComment"
                                                label={
                                                  values.difficultySwallowingMedicine
                                                    ? "Details of action taken"
                                                    : "What action is required ?"
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div>
                                          <label>
                                            <Field
                                              type="checkbox"
                                              name="swallowWhole"
                                              value={true}
                                              checked={
                                                values.swallowWhole === true
                                              }
                                            />
                                            <span className="ml-1">
                                              25. Are all tablets and capsules
                                              to be swallowed whole ? Answer
                                              'No' for sublingual tablets,
                                              'melts', orodispersible tablets,
                                              buccal tablets ?
                                            </span>
                                          </label>

                                          <div>
                                            <div className="mt-2">
                                              <FormikController
                                                control="textArea"
                                                type="text"
                                                name="swallowWholeComment"
                                                label={
                                                  values.swallowWhole
                                                    ? "Details of action taken"
                                                    : "What action is required ?"
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>

                                        <div>
                                          <label>
                                            <Field
                                              type="checkbox"
                                              name="unlicensedMethod"
                                              value={true}
                                              checked={
                                                values.unlicensedMethod === true
                                              }
                                            />
                                            <span className="ml-1">
                                              26. If a medicine is to be
                                              administered by an unlicensed
                                              method (e.g crushing or having a
                                              tablet) is there written
                                              authorisation from the prescriber
                                              to do so ? (e.g on the label)
                                            </span>
                                          </label>

                                          <div>
                                            <div className="mt-2">
                                              <FormikController
                                                control="textArea"
                                                type="text"
                                                name="unlicensedMethodComment"
                                                label={
                                                  values.unlicensedMethod
                                                    ? "Details of action taken"
                                                    : "What action is required ?"
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>

                                        <div>
                                          <label>
                                            <Field
                                              type="checkbox"
                                              name="appropriateEquipment"
                                              value={true}
                                              checked={
                                                values.appropriateEquipment ===
                                                true
                                              }
                                            />
                                            <span className="ml-1">
                                              27. Is appropriate equipment
                                              available
                                            </span>
                                          </label>

                                          <div>
                                            <div className="mt-8">
                                              <FormikController
                                                control="textArea"
                                                type="text"
                                                name="appropriateEquipmentComment"
                                                label={
                                                  values.appropriateEquipment
                                                    ? "Details of action taken"
                                                    : "What action is required ?"
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>

                                        <div>
                                          <label>
                                            <Field
                                              type="checkbox"
                                              name="cytotoxicCytostatic"
                                              value={true}
                                              checked={
                                                values.cytotoxicCytostatic ===
                                                true
                                              }
                                            />
                                            <span className="ml-1">
                                              28. If the person is taking any
                                              medicines on the
                                              Cytotoxic/Cytostatic medicines
                                              list (is this documented in the
                                              care plan and appropriate
                                              Precautions put in place as
                                              necessary? See Policy section
                                              9.2.)
                                            </span>
                                          </label>

                                          <div>
                                            <div className="mt-2">
                                              <FormikController
                                                control="textArea"
                                                type="text"
                                                name="cytotoxicCytostaticComment"
                                                label={
                                                  values.cytotoxicCytostatic
                                                    ? "Details of action taken"
                                                    : "What action is required ?"
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>

                                        <div>
                                          <label>
                                            <Field
                                              type="checkbox"
                                              name="protectiveEquipment"
                                              value={true}
                                              checked={
                                                values.protectiveEquipment ===
                                                true
                                              }
                                            />
                                            <span className="ml-1">
                                              29. Is personal protective
                                              equipment available when needed ?
                                            </span>
                                          </label>

                                          <div>
                                            <div className="mt-14">
                                              <FormikController
                                                control="textArea"
                                                type="text"
                                                name="protectiveEquipmentComment"
                                                label={
                                                  values.protectiveEquipment
                                                    ? "Details of action taken"
                                                    : "What action is required ?"
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>

                                        <div>
                                          <label>
                                            <Field
                                              type="checkbox"
                                              name="dosageSameTime"
                                              value={true}
                                              checked={
                                                values.dosageSameTime === true
                                              }
                                            />
                                            <span className="ml-1">
                                              30. If the person is taking a
                                              medicine/s that do not have the
                                              same dose at the same time every
                                              day (e.g for weekly, monthly,
                                              reducing or other changing doses)
                                              is this clearly documented on the
                                              MAR Chart ?
                                            </span>
                                          </label>

                                          <div>
                                            <div className="mt-2">
                                              <FormikController
                                                control="textArea"
                                                type="text"
                                                name="dosageSameTimeComment"
                                                label={
                                                  values.dosageSameTime
                                                    ? "Details of action taken"
                                                    : "What action is required ?"
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>

                                        <div>
                                          <label>
                                            <Field
                                              type="checkbox"
                                              name="sensitiveSpecificTime"
                                              value={true}
                                              checked={
                                                values.sensitiveSpecificTime ===
                                                true
                                              }
                                            />
                                            <span className="ml-1">
                                              31. If any medicines are time
                                              sensitive and have to be
                                              administered at a specific time,
                                              is this documented in the care
                                              plan ?
                                            </span>
                                          </label>

                                          <div>
                                            <div className="mt-2">
                                              <FormikController
                                                control="textArea"
                                                type="text"
                                                name="sensitiveSpecificTimeComment"
                                                label={
                                                  values.sensitiveSpecificTime
                                                    ? "Details of action taken"
                                                    : "What action is required ?"
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>

                                        <div>
                                          <label>
                                            <Field
                                              type="checkbox"
                                              name="preparedMedicineAppropriateArrangements"
                                              value={true}
                                              checked={
                                                values.preparedMedicineAppropriateArrangements ===
                                                true
                                              }
                                            />
                                            <span className="ml-1">
                                              32. If medicines are to be
                                              prepared and left for the person
                                              to take themselves at a later
                                              time, has a risk assessment been
                                              undertaken, are appropriate
                                              arrangements in place and is this
                                              documented in the care plan ?
                                            </span>
                                          </label>

                                          <div>
                                            <div className="mt-2">
                                              <FormikController
                                                control="textArea"
                                                type="text"
                                                name="preparedMedicineAppropriateArrangementsComment"
                                                label={
                                                  values.preparedMedicineAppropriateArrangements
                                                    ? "Details of action taken"
                                                    : "What action is required ?"
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>

                                        <div>
                                          <label>
                                            <Field
                                              type="checkbox"
                                              name="appropriateMeasuringDevice"
                                              value={true}
                                              checked={
                                                values.appropriateMeasuringDevice ===
                                                true
                                              }
                                            />
                                            <span className="ml-1">
                                              33. Liquid medicines, sachets,
                                              granules: is an appropriate
                                              measuring device available for
                                              administration ?. (e.g 5ml
                                              medicine spoon, oral syringe)
                                            </span>
                                          </label>

                                          <div>
                                            <div className="mt-2">
                                              <FormikController
                                                control="textArea"
                                                type="text"
                                                name="appropriateMeasuringDeviceComment"
                                                label={
                                                  values.appropriateMeasuringDevice
                                                    ? "Details of action taken"
                                                    : "What action is required ?"
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>

                                        <div>
                                          <label>
                                            <Field
                                              type="checkbox"
                                              name="patches"
                                              value={true}
                                              checked={values.patches === true}
                                            />
                                            <span className="ml-1">
                                              34. Patches: Are there clear
                                              instructions regarding :<br />
                                              * How to apply them
                                              <br />
                                              * How often to change them
                                              <br />
                                              * Is there a body map
                                              <br />
                                              * Where to place them on the body
                                              <br />
                                              * If to be put on in a different
                                              place from last time
                                              <br />
                                              * A clear method of recording when
                                              the previous patch was removed
                                              <br />* Process set up for
                                              disposal
                                            </span>
                                          </label>

                                          <div>
                                            <div className="mt-2">
                                              <FormikController
                                                control="textArea"
                                                type="text"
                                                name="patchesComment"
                                                label={
                                                  values.patches
                                                    ? "Details of action taken"
                                                    : "What action is required ?"
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>

                                        <div>
                                          <label>
                                            <Field
                                              type="checkbox"
                                              name="specialTechnique"
                                              value={true}
                                              checked={
                                                values.specialTechnique === true
                                              }
                                            />
                                            <span className="ml-1">
                                              35. If any medicines need to be
                                              administered by a special
                                              technique (e.g medicines in a PEG,
                                              rectally, oxygen) is this
                                              documented in the provider's care
                                              plan and are staff trained amnd
                                              assessed as competent to undertake
                                              the task ?
                                            </span>
                                          </label>

                                          <div>
                                            <div className="mt-2">
                                              <FormikController
                                                control="textArea"
                                                type="text"
                                                name="specialTechniqueComment"
                                                label={
                                                  values.specialTechnique
                                                    ? "Details of action taken"
                                                    : "What action is required ?"
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>

                                        <div>
                                          <label>
                                            <Field
                                              type="checkbox"
                                              name="warfarin"
                                              value={true}
                                              checked={values.warfarin === true}
                                            />
                                            <span className="ml-1">
                                              36. Warfarin: <br />
                                              * Is the current INR available in
                                              the person's yellow book or
                                              anticoagulant record
                                              <br />
                                              * Is the dose of warfrarin to be
                                              administered clearly stated ?
                                              <br />
                                            </span>
                                          </label>

                                          <div>
                                            <div className="mt-2">
                                              <FormikController
                                                control="textArea"
                                                type="text"
                                                name="warfarinComment"
                                                label={
                                                  values.warfarin
                                                    ? "Details of action taken"
                                                    : "What action is required ?"
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>

                                        <div>
                                          <label>
                                            <Field
                                              type="checkbox"
                                              name="methotrexate"
                                              value={true}
                                              checked={
                                                values.methotrexate === true
                                              }
                                            />
                                            <span className="ml-1">
                                              42. Methotrexate: <br />
                                              * Are the tablets 2.5mg strength ?
                                              <br />
                                              * Is the number of tablets for the
                                              dose clear ?
                                              <br />
                                              * Is it clear that the dose should
                                              be taken once a week ?<br />
                                              * Is it clear which day of the
                                              week it should be taken ?<br />
                                            </span>
                                          </label>

                                          <div>
                                            <div className="mt-2">
                                              <FormikController
                                                control="textArea"
                                                type="text"
                                                name="methotrexateComment"
                                                label={
                                                  values.methotrexate
                                                    ? "Details of action taken"
                                                    : "What action is required ?"
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div>
                                          <label>
                                            <Field
                                              type="checkbox"
                                              name="liquidWhiteParafin"
                                              value={true}
                                              checked={
                                                values.liquidWhiteParafin ===
                                                true
                                              }
                                            />
                                            <span className="ml-1">
                                              43. Liquid paraffin and white soft
                                              paraffin products: is the person
                                              aware of the fire risk associated
                                              with use of these products ?
                                            </span>
                                          </label>

                                          <div>
                                            <div className="mt-2">
                                              <FormikController
                                                control="textArea"
                                                type="text"
                                                name="liquidWhiteParafinComment"
                                                label={
                                                  values.liquidWhiteParafin
                                                    ? "Details of action taken"
                                                    : "What action is required ?"
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>

                                        <div>
                                          <label>
                                            <Field
                                              type="checkbox"
                                              name="oxygen"
                                              value={true}
                                              checked={values.oxygen === true}
                                            />
                                            <span className="ml-1">
                                              44. If the person is using oxygen,
                                              are the necessary precautions in
                                              place to ensure that it is used
                                              safely and has this been risk
                                              assessed ?
                                            </span>
                                          </label>

                                          <div>
                                            <div className="mt-2">
                                              <FormikController
                                                control="textArea"
                                                type="text"
                                                name="oxygenComment"
                                                label={
                                                  values.oxygen
                                                    ? "Details of action taken"
                                                    : "What action is required ?"
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div>
                                          <label>
                                            <Field
                                              type="checkbox"
                                              name="injectionTrainedAssessed"
                                              value={true}
                                              checked={
                                                values.injectionTrainedAssessed ===
                                                true
                                              }
                                            />
                                            <span className="ml-1">
                                              45. If a care worker is to
                                              administer an injection are they
                                              trained and assessed as competent
                                              to do so and is the relevant
                                              support from healthcare
                                              professionals in place ?
                                            </span>
                                          </label>

                                          <div>
                                            <div className="mt-2">
                                              <FormikController
                                                control="textArea"
                                                type="text"
                                                name="injectionTrainedAssessedComment"
                                                label={
                                                  values.injectionTrainedAssessed
                                                    ? "Details of action taken"
                                                    : "What action is required ?"
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div>
                                          <label>
                                            <Field
                                              type="checkbox"
                                              name="injectionTrainedAssessed"
                                              value={true}
                                              checked={
                                                values.injectionTrainedAssessed ===
                                                true
                                              }
                                            />
                                            <span className="ml-1">
                                              45. If a care worker is to
                                              administer an injection are they
                                              trained and assessed as competent
                                              to do so and is the relevant
                                              support from healthcare
                                              professionals in place ?
                                            </span>
                                          </label>

                                          <div>
                                            <div className="mt-2">
                                              <FormikController
                                                control="textArea"
                                                type="text"
                                                name="injectionTrainedAssessedComment"
                                                label={
                                                  values.injectionTrainedAssessed
                                                    ? "Details of action taken"
                                                    : "What action is required ?"
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div></div>
                                       <div className="flex flex-row-reverse"><div><button className="bg-button text-white w-full rounded p-1.5 text-lg px-10 mt-10" onClick={()=> setShowModal(false)}>Save</button></div> </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                        </>
                      ) : null}

                      <label className="flex justify-between border bg-wednesday p-3 rounded">
                        {/* <Field
                          type="checkbox"
                          name="movingAndHandlingRiskAssessment"
                          value={true}
                          checked={
                            values.movingAndHandlingRiskAssessment === true
                          }
                        /> */}
                        <span className="ml-1 text-lg font-semibold text-gray-500 mt-1">
                          Moving & Handling Risk Assessment
                        </span>
                        <button
                          className=" text-white bg-button border hover:bg-white hover:text-gray-700 hover:border hover:border-gray-300 hover:underline decoration-secondary focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-1 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                          type="button"
                          onClick={() => handleOpenMoving()}
                        >
                          View
                        </button>
                      </label>
                      {showMoving ? (
                        <>
                          <div className="justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                            <div className="relative w-auto my-6 mx-auto max-w-7xl">
                              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                  <h3 className="text-xl font-semibold text-gray-500">
                                    <span className="text-green">
                                    Moving & Handling Risk Assessment
                                    </span>
                                  </h3>
                                  <button
                                    className="p-1 ml-10 bg-transparent border-0 text-button float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                    onClick={() => setShowMoving(false)}
                                  >
                                    ×
                                  </button>
                                </div>

                                <div className="relative px-5 flex-auto">
                                  <div className="">
                                    <div></div>
                                  </div>
                                </div>
                                <div className="">
                                  {showMoving && (
                                    <div className="mb-3 p-5 mt-5">
                                      <div className="">
                                        <Head title="Please fill in the details" />
                                      </div>
                                      <div className="">
                                      
                        <div className="mt-2 ">
                        

                          <div className="grid grid-cols-2 gap-2 mt-3">
                            <FormikController
                              control="textArea"
                              type="text"
                              name="movingAndHandlingCondition"
                              label="Condition"
                            />
                            <FormikController
                              control="textArea"
                              type="text"
                              name="movingAndHandlingMedication"
                              label="Medication"
                            />
                          </div>
                          <FormikController
                            control="textArea"
                            type="text"
                            name="movingAndHandlingLastReview"
                            label="Are there any changes since the lasty review ?"
                          />
                          <div className="text-left">
                            <span className="font-medium">
                              Please specify any Physical Hazards
                            </span>
                            <br />
                            <div className="border rounded p-3 text-left">
                              <label>
                                <Field
                                  type="checkbox"
                                  name="excessiveyTall"
                                  value={true}
                                  checked={values.excessiveyTall === true}
                                />
                                <span className="ml-2">
                                  Is the individual excessively tall ?
                                </span>
                              </label>
                              {values.excessiveyTall === true && (
                                <div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="excessivelyTallComment"
                                      label="Comments"
                                    />
                                  </div>
                                </div>
                              )}
                              <br />
                              <label>
                                <Field
                                  type="checkbox"
                                  name="excessivelyHeavy"
                                  value={true}
                                  checked={values.excessivelyHeavy === true}
                                />
                                <span className="ml-2">
                                  Is the individual excessively heavy ?
                                </span>
                              </label>
                              {values.excessivelyHeavy === true && (
                                <div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="excessivelyHeavyComment"
                                      label="Comments"
                                    />
                                  </div>
                                </div>
                              )}
                              <br />
                              <label>
                                <Field
                                  type="checkbox"
                                  name="swollenFixedLimbs"
                                  value={true}
                                  checked={values.swollenFixedLimbs === true}
                                />
                                <span className="ml-2">
                                  Does the individual have swollen/fixed limbs ?
                                </span>
                              </label>
                              {values.swollenFixedLimbs === true && (
                                <div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="swollenFixedLimbsComment"
                                      label="Comments"
                                    />
                                  </div>
                                </div>
                              )}
                              <br />
                              <label>
                                <Field
                                  type="checkbox"
                                  name="poorFeetState"
                                  value={true}
                                  checked={values.poorFeetState === true}
                                />
                                <span className="ml-2">
                                  Does the individual have poor state of feet ?
                                </span>
                              </label>
                              {values.poorFeetState === true && (
                                <div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="poorFeetStateComment"
                                      label="Comments"
                                    />
                                  </div>
                                </div>
                              )}
                              <br />
                              <label>
                                <Field
                                  type="checkbox"
                                  name="poorSkinCondition"
                                  value={true}
                                  checked={values.poorSkinCondition === true}
                                />
                                <span className="ml-2">
                                  Is the skin condition poor ?
                                </span>
                              </label>

                              {values.poorSkinCondition === true && (
                                <div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="poorSkinConditionComment"
                                      label="Comments"
                                    />
                                  </div>
                                </div>
                              )}
                              <br />
                              <label>
                                <Field
                                  type="checkbox"
                                  name="difficultyHolding"
                                  value={true}
                                  checked={values.difficultyHolding === true}
                                />
                                <span className="ml-2">
                                  Are there difficulties holding the individual
                                  ?
                                </span>
                              </label>
                              {values.difficultyHolding === true && (
                                <div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="difficultyHoldingComment"
                                      label="Comments"
                                    />
                                  </div>
                                </div>
                              )}
                              <br />
                              <label>
                                <Field
                                  type="checkbox"
                                  name="pressureSores"
                                  value={true}
                                  checked={values.pressureSores === true}
                                />
                                <span className="ml-2">
                                  Is the individual at risk of pressure sores ?
                                </span>
                              </label>

                              {values.pressureSores === true && (
                                <div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="pressureSoresComment"
                                      label="Comments"
                                    />
                                  </div>
                                </div>
                              )}
                              <br />
                              <label>
                                <Field
                                  type="checkbox"
                                  name="impairedHearing"
                                  value={true}
                                  checked={values.impairedHearing === true}
                                />
                                <span className="ml-2">
                                  Is the individual hearing impaired ?
                                </span>
                              </label>
                              {values.impairedHearing === true && (
                                <div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="impairedHearingComment"
                                      label="Comments"
                                    />
                                  </div>
                                </div>
                              )}
                              <br />
                              <label>
                                <Field
                                  type="checkbox"
                                  name="impairedSight"
                                  value={true}
                                  checked={values.impairedSight === true}
                                />
                                <span className="ml-2">
                                  Is the individual sight impaired ?
                                </span>
                              </label>

                              {values.impairedSight === true && (
                                <div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="impairedSightComment"
                                      label="Comments"
                                    />
                                  </div>
                                </div>
                              )}
                              <br />
                              <label>
                                <Field
                                  type="checkbox"
                                  name="impairedSpeech"
                                  value={true}
                                  checked={values.impairedSpeech === true}
                                />
                                <span className="ml-2">
                                  Is the individual speech impaired ?
                                </span>
                              </label>

                              {values.impairedSpeech === true && (
                                <div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="impairedSpeechComment"
                                      label="Comments"
                                    />
                                  </div>
                                </div>
                              )}
                              <br />
                              <label>
                                <Field
                                  type="checkbox"
                                  name="fits"
                                  value={true}
                                  checked={values.fits === true}
                                />
                                <span className="ml-2">
                                  Does the individual suffer from fits/
                                  involuntary movements ?
                                </span>
                              </label>

                              {values.fits === true && (
                                <div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="fitsComment"
                                      label="Comments"
                                    />
                                  </div>
                                </div>
                              )}
                              <br />
                              <label>
                                <Field
                                  type="checkbox"
                                  name="orthopaedic"
                                  value={true}
                                  checked={values.orthopaedic === true}
                                />
                                <span className="ml-2">
                                  Are there orthopaedic considerations ?
                                </span>
                              </label>

                              {values.orthopaedic === true && (
                                <div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="orthopaedicComment"
                                      label="Comments"
                                    />
                                  </div>
                                </div>
                              )}

                              <br />
                              <label>
                                <Field
                                  type="checkbox"
                                  name="unstableUncoordinated"
                                  value={true}
                                  checked={
                                    values.unstableUncoordinated === true
                                  }
                                />
                                <span className="ml-2">
                                  Is the individual unstable/ uncoordinated ?
                                </span>
                              </label>

                              {values.unstableUncoordinated === true && (
                                <div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="unstableUncoordinatedComment"
                                      label="Comments"
                                    />
                                  </div>
                                </div>
                              )}

                              <br />
                              <label>
                                <Field
                                  type="checkbox"
                                  name="attachments"
                                  value={true}
                                  checked={values.attachments === true}
                                />
                                <span className="ml-2">
                                  Are there 'Attachments' to consider e.g VI's/
                                  Catheters/ Oxygen cylinders etc ?
                                </span>
                              </label>

                              {values.attachments === true && (
                                <div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="attachmentsComment"
                                      label="Comments"
                                    />
                                  </div>
                                </div>
                              )}

                              <br />
                              <label>
                                <Field
                                  type="checkbox"
                                  name="unpredictable"
                                  value={true}
                                  checked={values.unpredictable === true}
                                />
                                <span className="ml-2">
                                  Is the individual unpredictable ?
                                </span>
                              </label>

                              {values.unpredictable === true && (
                                <div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="unpredictableComment"
                                      label="Comments"
                                    />
                                  </div>
                                </div>
                              )}
                              <br />
                              <label>
                                <Field
                                  type="checkbox"
                                  name="uncoorperative"
                                  value={true}
                                  checked={values.uncoorperative === true}
                                />
                                <span className="ml-2">
                                  Is the individual uncooperative ?
                                </span>
                              </label>

                              {values.uncoorperative === true && (
                                <div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="uncoorperativeComment"
                                      label="Comments"
                                    />
                                  </div>
                                </div>
                              )}
                              <br />
                              <label>
                                <Field
                                  type="checkbox"
                                  name="followingInstructions"
                                  value={true}
                                  checked={
                                    values.followingInstructions === true
                                  }
                                />
                                <span className="ml-2">
                                  Does the individual have difficulty following
                                  instruction ?
                                </span>
                              </label>

                              {values.followingInstructions === true && (
                                <div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="followingInstructionsComment"
                                      label="Comments"
                                    />
                                  </div>
                                </div>
                              )}

                              <br />
                              <label>
                                <Field
                                  type="checkbox"
                                  name="anxious"
                                  value={true}
                                  checked={values.anxious === true}
                                />
                                <span className="ml-2">
                                  Is the individual anxious ?
                                </span>
                              </label>

                              {values.anxious === true && (
                                <div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="anxiousComment"
                                      label="Comments"
                                    />
                                  </div>
                                </div>
                              )}
                              <br />
                              <label>
                                <Field
                                  type="checkbox"
                                  name="moodSwings"
                                  value={true}
                                  checked={values.moodSwings === true}
                                />
                                <span className="ml-2">
                                  Is the individual prone to mood swings ?
                                </span>
                              </label>

                              {values.moodSwings === true && (
                                <div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="moodSwingsComment"
                                      label="Comments"
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="text-left mt-3">
                            <span className="font-medium">
                              Please specify any Environmental Hazards
                            </span>
                            <br />
                            <div className="border rounded p-3 text-left">
                              <label>
                                <Field
                                  type="checkbox"
                                  name="environmentSpaceRestricted"
                                  value={true}
                                  checked={
                                    values.environmentSpaceRestricted === true
                                  }
                                />
                                <span className="ml-2">
                                  Is the environment space restricted ?
                                </span>
                              </label>
                              {values.environmentSpaceRestricted === true && (
                                <div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="environmentSpaceRestrictedComment"
                                      label="Comments"
                                    />
                                  </div>
                                </div>
                              )}
                              <br />
                              <label>
                                <Field
                                  type="checkbox"
                                  name="adequateLighting"
                                  value={true}
                                  checked={values.adequateLighting === true}
                                />
                                <span className="ml-2">
                                  Is the lighting adequate ?
                                </span>
                              </label>
                              {values.adequateLighting === true && (
                                <div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="adequateLightingComment"
                                      label="Comments"
                                    />
                                  </div>
                                </div>
                              )}
                              <br />
                              <label>
                                <Field
                                  type="checkbox"
                                  name="temperature"
                                  value={true}
                                  checked={values.temperature === true}
                                />
                                <span className="ml-2">
                                  Are there concerns pertaining to temperature ?
                                </span>
                              </label>
                              {values.temperature === true && (
                                <div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="temperatureComment"
                                      label="Comments"
                                    />
                                  </div>
                                </div>
                              )}
                              <br />
                              <label>
                                <Field
                                  type="checkbox"
                                  name="posture"
                                  value={true}
                                  checked={values.posture === true}
                                />
                                <span className="ml-2">
                                  Are there constraints on posture ?
                                </span>
                              </label>
                              {values.posture === true && (
                                <div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="postureComment"
                                      label="Comments"
                                    />
                                  </div>
                                </div>
                              )}
                              <br />
                              <label>
                                <Field
                                  type="checkbox"
                                  name="bedChair"
                                  value={true}
                                  checked={values.bedChair === true}
                                />
                                <span className="ml-2">
                                  Is the bed/ chair the wrong height ?
                                </span>
                              </label>

                              {values.bedChair === true && (
                                <div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="bedChairComment"
                                      label="Comments"
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="text-left mt-3">
                            <span className="font-medium">
                              Please specify any Other Hazards
                            </span>
                            <br />
                            <div className="border rounded p-3 text-left">
                              <label>
                                <Field
                                  type="checkbox"
                                  name="otherHazards"
                                  value={true}
                                  checked={values.otherHazards === true}
                                />
                                <span className="ml-2">Other ?</span>
                              </label>
                              {values.otherHazards === true && (
                                <div>
                                  {/* <div className="mt-2">
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="otherHazardsComment"
                                      label="Are there any cultural aspects that may affect moving and handling ?"
                                    />
                                  </div> */}
                                  <div className="mt-2">
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="otherHazardsComment"
                                      label="Comments"
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="text-left mt-3">
                            <span className="font-medium">
                              Safe System of Work
                            </span>
                            <br />
                            <div className="border rounded p-3 text-left">
                              <label>
                                <Field
                                  type="checkbox"
                                  name="bedMovement"
                                  value={true}
                                  checked={values.bedMovement === true}
                                />
                                <span className="ml-2">
                                  Moving in bed/movement up the bed
                                </span>
                              </label>
                              {values.bedMovement === true && (
                                <div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="bedMovementComment"
                                      label="Comments"
                                    />
                                  </div>
                                  {/* <div className="mt-2">
                                    <FormikController
                                      control="input"
                                      type="text"
                                      name="moveStaff"
                                      label="Number of Staff"
                                    />
                                  </div>
                                  <div className="grid grid-cols-2 gap-2">
                                    <div className="mt-2">
                                      <FormikController
                                        control="textArea"
                                        type="text"
                                        name="movEquip"
                                        label="Equipment"
                                      />
                                    </div>
                                    <div className="mt-2">
                                      <FormikController
                                        control="textArea"
                                        type="text"
                                        name="movPrec"
                                        label="Further Precautions"
                                      />
                                    </div>
                                  </div> */}
                                </div>
                              )}
                              <br />
                              <label>
                                <Field
                                  type="checkbox"
                                  name="InOutOfBed"
                                  value={true}
                                  checked={values.InOutOfBed === true}
                                />
                                <span className="ml-2">In / Out of bed</span>
                              </label>
                              {values.InOutOfBed === true && (
                                <div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="InOutOfBedComment"
                                      label="Comments"
                                    />
                                  </div>
                                </div>
                              )}
                              <br />
                              <label>
                                <Field
                                  type="checkbox"
                                  name="sittingBalance"
                                  value={true}
                                  checked={values.sittingBalance === true}
                                />
                                <span className="ml-2">Sitting balance</span>
                              </label>
                              {values.sittingBalance === true && (
                                <div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="sittingBalanceComment"
                                      label="Comments"
                                    />
                                  </div>
                                </div>
                              )}
                              <br />
                              <label>
                                <Field
                                  type="checkbox"
                                  name="bedChairWheelchair"
                                  value={true}
                                  checked={values.bedChairWheelchair === true}
                                />
                                <span className="ml-2">
                                  Bed / Chair to wheelchair / Commode/ Toilet
                                </span>
                              </label>
                              {values.bedChairWheelchair === true && (
                                <div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="bedChairWheelchairComment"
                                      label="Comments"
                                    />
                                  </div>
                                </div>
                              )}
                              <br />
                              <label>
                                <Field
                                  type="checkbox"
                                  name="bathingShowering"
                                  value={true}
                                  checked={values.bathingShowering === true}
                                />
                                <span className="ml-2">Bathing/ Showering</span>
                              </label>
                              {values.bathingShowering === true && (
                                <div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="bathingShoweringComment"
                                      label="Comments"
                                    />
                                  </div>
                                </div>
                              )}
                              <br />
                              <label>
                                <Field
                                  type="checkbox"
                                  name="walking"
                                  value={true}
                                  checked={values.walking === true}
                                />
                                <span className="ml-2">
                                  Walking (inside/outside)
                                </span>
                              </label>
                              {values.walking === true && (
                                <div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="walkingComment"
                                      label="Comments"
                                    />
                                  </div>
                                </div>
                              )}
                              <br />
                              <label>
                                <Field
                                  type="checkbox"
                                  name="stairsSteps"
                                  value={true}
                                  checked={values.stairsSteps === true}
                                />
                                <span className="ml-2">Stairs / Steps</span>
                              </label>
                              {values.stairsSteps === true && (
                                <div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="stairsStepsComment"
                                      label="Comments"
                                    />
                                  </div>
                                </div>
                              )}
                              <br />
                              <label>
                                <Field
                                  type="checkbox"
                                  name="dressing"
                                  value={true}
                                  checked={values.dressing === true}
                                />
                                <span className="ml-2">Dressing</span>
                              </label>
                              {values.dressing === true && (
                                <div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="dressingComment"
                                      label="Comments"
                                    />
                                  </div>
                                </div>
                              )}
                              <br />
                              <label>
                                <Field
                                  type="checkbox"
                                  name="otherSafeSystemofWork"
                                  value={true}
                                  checked={
                                    values.otherSafeSystemofWork === true
                                  }
                                />
                                <span className="ml-2">Other</span>
                              </label>
                              {values.otherSafeSystemofWork === true && (
                                <div>
                                  <div className="grid grid-cols-2 gap-2">
                                    <div className="mt-2">
                                      <FormikController
                                        control="textArea"
                                        type="text"
                                        name="otherSafeSystemofWorkComment"
                                        label="Comments"
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className="grid grid-cols-1 gap-2 mt-2">
                              <FormikController
                                control="textArea"
                                type="text"
                                name="summary"
                                label="Summary"
                              />
                              {/* <FormikController
                                control="textArea"
                                type="text"
                                name="summm"
                                label="Carer's Responsibilities"
                              /> */}
                            </div>
                          </div>
                        </div>
                      
                                       
                                      </div> <div></div>
                                       <div className="flex flex-row-reverse "><div><button className="bg-button text-white w-full rounded p-1.5 text-lg px-10 mt-10" onClick={()=> setShowMoving(false)}>Save</button></div> </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            
                          </div>

                          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                        </>
                      ) : null}

                     
                      <label className="flex justify-between border bg-wednesday p-3 rounded">
                        {/* <Field
                          type="checkbox"
                          name="repositioningRiskAssessment"
                          value={true}
                          checked={values.repositioningRiskAssessment === true}
                        /> */}
                        <span className="ml-1 text-lg font-semibold text-gray-500 mt-1">
                          Repositioning Risk Assessment
                        </span>
                        <button
                          className=" text-white bg-button border hover:bg-white hover:text-gray-700 hover:border hover:border-gray-300 hover:underline decoration-secondary focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-1 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                          type="button"
                          onClick={() => handleOpenRepositioning()}
                        >
                          View
                        </button>
                      </label>
                      {showRepositioning ? (
                        <>
                          <div className="justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                            <div className="relative w-auto my-6 mx-auto max-w-7xl">
                              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                  <h3 className="text-xl font-semibold text-gray-500">
                                    <span className="text-green">
                                    Repositioning Risk Assessment
                                    </span>
                                  </h3>
                                  <button
                                    className="p-1 ml-10 bg-transparent border-0 text-button float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                    onClick={() => setShowRepositioning(false)}
                                  >
                                    ×
                                  </button>
                                </div>

                                <div className="relative p-5 flex-auto">
                                  <div className="">
                                    <div></div>
                                  </div>
                                </div>
                                <div className="">
                                {showRepositioning && (
                        <div className=" px-5">
                          <div className="">
                            <Head title="Please fill in the details" />
                          </div>

                          <div className="grid grid-cols-2 gap-2 mt-3">
                            <FormikController
                              control="textArea"
                              type="text"
                              name="repositioningCondition"
                              label="Condition"
                            />
                            <FormikController
                              control="textArea"
                              type="text"
                              name="repositioningMedication"
                              label="Medication"
                            />
                          </div>
                          <FormikController
                            control="textArea"
                            type="text"
                            name="repositioningLastReview"
                            label="Are there any changes since the lasty review ?"
                          />
                          <div className="text-left">
                            <span className="font-medium">
                              Please specify any Physical Hazards
                            </span>
                            <br />
                            <div className="border rounded p-3 text-left">
                              <label>
                                <Field
                                  type="checkbox"
                                  name="repositioningExcessivelyTall"
                                  value={true}
                                  checked={
                                    values.repositioningExcessivelyTall === true
                                  }
                                />
                                <span className="ml-2">
                                  Is the individual excessively tall ?
                                </span>
                              </label>
                              {values.repositioningExcessivelyTall === true && (
                                <div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="repositioningExcessivelyTallComment"
                                      label="Comments"
                                    />
                                  </div>
                                </div>
                              )}
                              <br />
                              <label>
                                <Field
                                  type="checkbox"
                                  name="repositioningExcessivelyHeavy"
                                  value={true}
                                  checked={
                                    values.repositioningExcessivelyHeavy ===
                                    true
                                  }
                                />
                                <span className="ml-2">
                                  Is the individual excessively heavy ?
                                </span>
                              </label>
                              {values.repositioningExcessivelyHeavy ===
                                true && (
                                <div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="repositioningExcessivelyHeavyComment"
                                      label="Comments"
                                    />
                                  </div>
                                </div>
                              )}
                              <br />
                              <label>
                                <Field
                                  type="checkbox"
                                  name="repositioningSwollenFixedLimbs"
                                  value={true}
                                  checked={
                                    values.repositioningSwollenFixedLimbs ===
                                    true
                                  }
                                />
                                <span className="ml-2">
                                  Does the individual have swollen/fixed limbs ?
                                </span>
                              </label>
                              {values.repositioningSwollenFixedLimbs ===
                                true && (
                                <div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="repositioningSwollenFixedLimbsComment"
                                      label="Comments"
                                    />
                                  </div>
                                </div>
                              )}
                              <br />
                              <label>
                                <Field
                                  type="checkbox"
                                  name="repositioningPoorFeetState"
                                  value={true}
                                  checked={
                                    values.repositioningPoorFeetState === true
                                  }
                                />
                                <span className="ml-2">
                                  Does the individual have poor state of feet ?
                                </span>
                              </label>
                              {values.repositioningPoorFeetState === true && (
                                <div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="repositioningPoorFeetStateComment"
                                      label="Comments"
                                    />
                                  </div>
                                </div>
                              )}
                              <br />
                              <label>
                                <Field
                                  type="checkbox"
                                  name="repositioningPoorSkinCondition"
                                  value={true}
                                  checked={
                                    values.repositioningPoorSkinCondition ===
                                    true
                                  }
                                />
                                <span className="ml-2">
                                  Is the skin condition poor ?
                                </span>
                              </label>

                              {values.repositioningPoorSkinCondition ===
                                true && (
                                <div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="repositioningPoorSkinConditionComment"
                                      label="Comments"
                                    />
                                  </div>
                                </div>
                              )}
                              <br />
                              <label>
                                <Field
                                  type="checkbox"
                                  name="repositioningDifficultyHolding"
                                  value={true}
                                  checked={
                                    values.repositioningDifficultyHolding ===
                                    true
                                  }
                                />
                                <span className="ml-2">
                                  Are there difficulties holding the individual
                                  ?
                                </span>
                              </label>
                              {values.repositioningDifficultyHolding ===
                                true && (
                                <div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="repositioningDifficultyHoldingComment"
                                      label="Comments"
                                    />
                                  </div>
                                </div>
                              )}
                              <br />
                              <label>
                                <Field
                                  type="checkbox"
                                  name="repositioningPressureSores"
                                  value={true}
                                  checked={
                                    values.repositioningPressureSores === true
                                  }
                                />
                                <span className="ml-2">
                                  Is the individual at risk of pressure sores ?
                                </span>
                              </label>

                              {values.repositioningPressureSores === true && (
                                <div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="repositioningPressureSoresComment"
                                      label="Comments"
                                    />
                                  </div>
                                </div>
                              )}
                              <br />
                              <label>
                                <Field
                                  type="checkbox"
                                  name="repositioningImpairedHearing"
                                  value={true}
                                  checked={
                                    values.repositioningImpairedHearing === true
                                  }
                                />
                                <span className="ml-2">
                                  Is the individual hearing impaired ?
                                </span>
                              </label>
                              {values.repositioningImpairedHearing === true && (
                                <div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="repositioningImpairedHearingComment"
                                      label="Comments"
                                    />
                                  </div>
                                </div>
                              )}
                              <br />
                              <label>
                                <Field
                                  type="checkbox"
                                  name="repositioningImpairedSight"
                                  value={true}
                                  checked={
                                    values.repositioningImpairedSight === true
                                  }
                                />
                                <span className="ml-2">
                                  Is the individual sight impaired ?
                                </span>
                              </label>

                              {values.repositioningImpairedSight === true && (
                                <div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="repositioningImpairedSightComment"
                                      label="Comments"
                                    />
                                  </div>
                                </div>
                              )}
                              <br />
                              <label>
                                <Field
                                  type="checkbox"
                                  name="repositioningmpairedSpeech"
                                  value={true}
                                  checked={
                                    values.repositioningmpairedSpeech === true
                                  }
                                />
                                <span className="ml-2">
                                  Is the individual speech impaired ?
                                </span>
                              </label>

                              {values.repositioningmpairedSpeech === true && (
                                <div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="repositioningImpairedSpeechComment"
                                      label="Comments"
                                    />
                                  </div>
                                </div>
                              )}
                              <br />
                              <label>
                                <Field
                                  type="checkbox"
                                  name="repositioningFits"
                                  value={true}
                                  checked={values.repositioningFits === true}
                                />
                                <span className="ml-2">
                                  Does the individual suffer from fits/
                                  involuntary movements ?
                                </span>
                              </label>

                              {values.repositioningFits === true && (
                                <div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="repositioningFitsComment"
                                      label="Comments"
                                    />
                                  </div>
                                </div>
                              )}
                              <br />
                              <label>
                                <Field
                                  type="checkbox"
                                  name="repositioningOrthopaedic"
                                  value={true}
                                  checked={
                                    values.repositioningOrthopaedic === true
                                  }
                                />
                                <span className="ml-2">
                                  Are there orthopaedic considerations ?
                                </span>
                              </label>

                              {values.repositioningOrthopaedic === true && (
                                <div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="repositioningOrthopaedicComment"
                                      label="Comments"
                                    />
                                  </div>
                                </div>
                              )}

                              <br />
                              <label>
                                <Field
                                  type="checkbox"
                                  name="repositioningUnstableUncoordinated"
                                  value={true}
                                  checked={
                                    values.repositioningUnstableUncoordinated ===
                                    true
                                  }
                                />
                                <span className="ml-2">
                                  Is the individual unstable/ uncoordinated ?
                                </span>
                              </label>

                              {values.repositioningUnstableUncoordinated ===
                                true && (
                                <div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="repositioningUnstableUncoordinatedComment"
                                      label="Comments"
                                    />
                                  </div>
                                </div>
                              )}

                              <br />
                              <label>
                                <Field
                                  type="checkbox"
                                  name="repositioningAttachments"
                                  value={true}
                                  checked={
                                    values.repositioningAttachments === true
                                  }
                                />
                                <span className="ml-2">
                                  Are there 'Attachments' to consider e.g VI's/
                                  Catheters/ Oxygen cylinders etc ?
                                </span>
                              </label>

                              {values.repositioningAttachments === true && (
                                <div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="repositioningAttachmentsComment"
                                      label="Comments"
                                    />
                                  </div>
                                </div>
                              )}

                              <br />
                              <label>
                                <Field
                                  type="checkbox"
                                  name="repositioningUnpredictable"
                                  value={true}
                                  checked={
                                    values.repositioningUnpredictable === true
                                  }
                                />
                                <span className="ml-2">
                                  Is the individual unpredictable ?
                                </span>
                              </label>

                              {values.repositioningUnpredictable === true && (
                                <div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="repositioningUnpredictableComment"
                                      label="Comments"
                                    />
                                  </div>
                                </div>
                              )}
                              <br />
                              <label>
                                <Field
                                  type="checkbox"
                                  name="repositioningUncoorperative"
                                  value={true}
                                  checked={
                                    values.repositioningUncoorperative === true
                                  }
                                />
                                <span className="ml-2">
                                  Is the individual uncooperative ?
                                </span>
                              </label>

                              {values.repositioningUncoorperative === true && (
                                <div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="repositioningUncoorperativeComment"
                                      label="Comments"
                                    />
                                  </div>
                                </div>
                              )}
                              <br />
                              <label>
                                <Field
                                  type="checkbox"
                                  name="repositioningFollowingInstructions"
                                  value={true}
                                  checked={
                                    values.repositioningFollowingInstructions ===
                                    true
                                  }
                                />
                                <span className="ml-2">
                                  Does the individual have difficulty following
                                  instruction ?
                                </span>
                              </label>

                              {values.repositioningFollowingInstructions ===
                                true && (
                                <div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="repositioningFollowingInstructionsComment"
                                      label="Comments"
                                    />
                                  </div>
                                </div>
                              )}

                              <br />
                              <label>
                                <Field
                                  type="checkbox"
                                  name="repositioningAnxious"
                                  value={true}
                                  checked={values.repositioningAnxious === true}
                                />
                                <span className="ml-2">
                                  Is the individual anxious ?
                                </span>
                              </label>

                              {values.repositioningAnxious === true && (
                                <div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="repositioningAnxiousComment"
                                      label="Comments"
                                    />
                                  </div>
                                </div>
                              )}
                              <br />
                              <label>
                                <Field
                                  type="checkbox"
                                  name="repositioningMoodSwings"
                                  value={true}
                                  checked={
                                    values.repositioningMoodSwings === true
                                  }
                                />
                                <span className="ml-2">
                                  Is the individual prone to mood swings ?
                                </span>
                              </label>

                              {values.repositioningMoodSwings === true && (
                                <div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="repositioningMoodSwingsComment"
                                      label="Comments"
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="text-left mt-3">
                            <span className="font-medium">
                              Please specify any Environmental Hazards
                            </span>
                            <br />
                            <div className="border rounded p-3 text-left">
                              <label>
                                <Field
                                  type="checkbox"
                                  name="repositioningEnvironmentSpaceRestricted"
                                  value={true}
                                  checked={
                                    values.repositioningEnvironmentSpaceRestricted ===
                                    true
                                  }
                                />
                                <span className="ml-2">
                                  Is the environment space restricted ?
                                </span>
                              </label>
                              {values.repositioningEnvironmentSpaceRestricted ===
                                true && (
                                <div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="repositioningEnvironmentSpaceRestrictedComment"
                                      label="Comments"
                                    />
                                  </div>
                                </div>
                              )}
                              <br />
                              <label>
                                <Field
                                  type="checkbox"
                                  name="repositioningAdequateLighting"
                                  value={true}
                                  checked={
                                    values.repositioningAdequateLighting ===
                                    true
                                  }
                                />
                                <span className="ml-2">
                                  Is the lighting adequate ?
                                </span>
                              </label>
                              {values.repositioningAdequateLighting ===
                                true && (
                                <div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="repositioningAdequateLightingComment"
                                      label="Comments"
                                    />
                                  </div>
                                </div>
                              )}
                              <br />
                              <label>
                                <Field
                                  type="checkbox"
                                  name="repositioningTemperature"
                                  value={true}
                                  checked={
                                    values.repositioningTemperature === true
                                  }
                                />
                                <span className="ml-2">
                                  Are there concerns pertaining to temperature ?
                                </span>
                              </label>
                              {values.repositioningTemperature === true && (
                                <div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="repositioningTemperatureComment"
                                      label="Comments"
                                    />
                                  </div>
                                </div>
                              )}
                              <br />
                              <label>
                                <Field
                                  type="checkbox"
                                  name="repositioningPosture"
                                  value={true}
                                  checked={values.repositioningPosture === true}
                                />
                                <span className="ml-2">
                                  Are there constraints on posture ?
                                </span>
                              </label>
                              {values.repositioningPosture === true && (
                                <div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="repositioningPostureComment"
                                      label="Comments"
                                    />
                                  </div>
                                </div>
                              )}
                              <br />
                              <label>
                                <Field
                                  type="checkbox"
                                  name="repositioningBedChair"
                                  value={true}
                                  checked={
                                    values.repositioningBedChair === true
                                  }
                                />
                                <span className="ml-2">
                                  Is the bed/ chair the wrong height ?
                                </span>
                              </label>

                              {values.repositioningBedChair === true && (
                                <div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="repositioningBedChairComment"
                                      label="Comments"
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="text-left mt-3">
                            <span className="font-medium">
                              Please specify any Other Hazards
                            </span>
                            <br />
                            <div className="border rounded p-3 text-left">
                              <label>
                                <Field
                                  type="checkbox"
                                  name="repositioningOtherHazards"
                                  value={true}
                                  checked={
                                    values.repositioningOtherHazards === true
                                  }
                                />
                                <span className="ml-2">Other ?</span>
                              </label>
                              {values.repositioningOtherHazards === true && (
                                <div>
                                  {/* <div className="mt-2">
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="otherHazardsComment"
                                      label="Are there any cultural aspects that may affect moving and handling ?"
                                    />
                                  </div> */}
                                  <div className="mt-2">
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="repositioningOtherHazardsComment"
                                      label="Comments"
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="text-left mt-3">
                            <span className="font-medium">
                              Safe System of Work
                            </span>
                            <br />
                            <div className="border rounded p-3 text-left">
                              <label>
                                <Field
                                  type="checkbox"
                                  name="repositioningBedMovement"
                                  value={true}
                                  checked={
                                    values.repositioningBedMovement === true
                                  }
                                />
                                <span className="ml-2">
                                  Moving in bed/movement up the bed
                                </span>
                              </label>
                              {values.repositioningBedMovement === true && (
                                <div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="repositioningBedMovementAssistance"
                                      label="Comments"
                                    />
                                  </div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="input"
                                      type="text"
                                      name="repositioningBedMovementNumberOfStaff"
                                      label="Number of Staff"
                                    />
                                  </div>
                                  <div className="grid grid-cols-2 gap-2">
                                    <div className="mt-2">
                                      <FormikController
                                        control="textArea"
                                        type="text"
                                        name="repositioningBedMovementEquipment"
                                        label="Equipment"
                                      />
                                    </div>
                                    <div className="mt-2">
                                      <FormikController
                                        control="textArea"
                                        type="text"
                                        name="repositioningBedMovementFurtherPrecautions"
                                        label="Further Precautions"
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                              <br />
                              <label>
                                <Field
                                  type="checkbox"
                                  name="repositioningInOutOfBed"
                                  value={true}
                                  checked={
                                    values.repositioningInOutOfBed === true
                                  }
                                />
                                <span className="ml-2">In / Out of bed</span>
                              </label>
                              {values.repositioningInOutOfBed === true && (
                                <div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="repositioningInOutOfBedAssistance"
                                      label="Comments"
                                    />
                                  </div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="input"
                                      type="text"
                                      name="repositioningInOutOfBedNumberOfStaff"
                                      label="Number of Staff"
                                    />
                                  </div>
                                  <div className="grid grid-cols-2 gap-2">
                                    <div className="mt-2">
                                      <FormikController
                                        control="textArea"
                                        type="text"
                                        name="repositioningInOutOfBedEquipment"
                                        label="Equipment"
                                      />
                                    </div>
                                    <div className="mt-2">
                                      <FormikController
                                        control="textArea"
                                        type="text"
                                        name="repositioningInOutOfBedFurtherPrecautions"
                                        label="Further Precautions"
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                              <br />
                              <label>
                                <Field
                                  type="checkbox"
                                  name="repositioningSittingBalance"
                                  value={true}
                                  checked={
                                    values.repositioningSittingBalance === true
                                  }
                                />
                                <span className="ml-2">Sitting balance</span>
                              </label>
                              {values.repositioningSittingBalance === true && (
                                <div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="repositioningSittingBalanceAssistance"
                                      label="Comments"
                                    />
                                  </div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="input"
                                      type="text"
                                      name="repositioningSittingBalanceNumberOfStaff"
                                      label="Number of Staff"
                                    />
                                  </div>
                                  <div className="grid grid-cols-2 gap-2">
                                    <div className="mt-2">
                                      <FormikController
                                        control="textArea"
                                        type="text"
                                        name="repositioningSittingBalanceEquipment"
                                        label="Equipment"
                                      />
                                    </div>
                                    <div className="mt-2">
                                      <FormikController
                                        control="textArea"
                                        type="text"
                                        name="repositioningSittingBalanceFurtherPrecautions"
                                        label="Further Precautions"
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                              <br />
                              <label>
                                <Field
                                  type="checkbox"
                                  name="repositioningBedChairWheelchair"
                                  value={true}
                                  checked={
                                    values.repositioningBedChairWheelchair ===
                                    true
                                  }
                                />
                                <span className="ml-2">
                                  Bed / Chair to wheelchair / Commode/ Toilet
                                </span>
                              </label>
                              {values.repositioningBedChairWheelchair ===
                                true && (
                                <div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="repositioningBedChairWheelchairAssistance"
                                      label="Comments"
                                    />
                                  </div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="input"
                                      type="text"
                                      name="repositioningBedChairWheelchairNumberOfStaff"
                                      label="Number of Staff"
                                    />
                                  </div>
                                  <div className="grid grid-cols-2 gap-2">
                                    <div className="mt-2">
                                      <FormikController
                                        control="textArea"
                                        type="text"
                                        name="repositioningBedChairWheelchairEquipment"
                                        label="Equipment"
                                      />
                                    </div>
                                    <div className="mt-2">
                                      <FormikController
                                        control="textArea"
                                        type="text"
                                        name="repositioningBedChairWheelchairFurtherPrecautions"
                                        label="Further Precautions"
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                              <br />
                              <label>
                                <Field
                                  type="checkbox"
                                  name="repositioningBathingShowering"
                                  value={true}
                                  checked={
                                    values.repositioningBathingShowering ===
                                    true
                                  }
                                />
                                <span className="ml-2">Bathing/ Showering</span>
                              </label>
                              {values.repositioningBathingShowering ===
                                true && (
                                <div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="repositioningBathingShoweringAssistance"
                                      label="Comments"
                                    />
                                  </div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="input"
                                      type="text"
                                      name="repositioningBathingShoweringNumberOfStaff"
                                      label="Number of Staff"
                                    />
                                  </div>
                                  <div className="grid grid-cols-2 gap-2">
                                    <div className="mt-2">
                                      <FormikController
                                        control="textArea"
                                        type="text"
                                        name="repositioningBathingShoweringEquipment"
                                        label="Equipment"
                                      />
                                    </div>
                                    <div className="mt-2">
                                      <FormikController
                                        control="textArea"
                                        type="text"
                                        name="repositioningBathingShoweringFurtherPrecautions"
                                        label="Further Precautions"
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                              <br />
                              <label>
                                <Field
                                  type="checkbox"
                                  name="repositioningWalking"
                                  value={true}
                                  checked={values.repositioningWalking === true}
                                />
                                <span className="ml-2">
                                  Walking (inside/outside)
                                </span>
                              </label>
                              {values.repositioningWalking === true && (
                                <div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="repositioningWalkingAssistance"
                                      label="Comments"
                                    />
                                  </div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="input"
                                      type="text"
                                      name="repositioningWalkingNumberOfStaff"
                                      label="Number of Staff"
                                    />
                                  </div>
                                  <div className="grid grid-cols-2 gap-2">
                                    <div className="mt-2">
                                      <FormikController
                                        control="textArea"
                                        type="text"
                                        name="repositioningWalkingEquipment"
                                        label="Equipment"
                                      />
                                    </div>
                                    <div className="mt-2">
                                      <FormikController
                                        control="textArea"
                                        type="text"
                                        name="repositioningWalkingFurtherPrecautions"
                                        label="Further Precautions"
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                              <br />
                              <label>
                                <Field
                                  type="checkbox"
                                  name="repositioningStairsSteps"
                                  value={true}
                                  checked={
                                    values.repositioningStairsSteps === true
                                  }
                                />
                                <span className="ml-2">Stairs / Steps</span>
                              </label>
                              {values.repositioningStairsSteps === true && (
                                <div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="repositioningStairsStepsAssistance"
                                      label="Comments"
                                    />
                                  </div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="input"
                                      type="text"
                                      name="repositioningStairsStepsNumberOfStaff"
                                      label="Number of Staff"
                                    />
                                  </div>
                                  <div className="grid grid-cols-2 gap-2">
                                    <div className="mt-2">
                                      <FormikController
                                        control="textArea"
                                        type="text"
                                        name="repositioningStairsStepsEquipment"
                                        label="Equipment"
                                      />
                                    </div>
                                    <div className="mt-2">
                                      <FormikController
                                        control="textArea"
                                        type="text"
                                        name="repositioningStairsStepsFurtherPrecautions"
                                        label="Further Precautions"
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                              <br />
                              <label>
                                <Field
                                  type="checkbox"
                                  name="repositioningDressing"
                                  value={true}
                                  checked={
                                    values.repositioningDressing === true
                                  }
                                />
                                <span className="ml-2">Dressing</span>
                              </label>
                              {values.repositioningDressing === true && (
                                <div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="repositioningDressingAssistance"
                                      label="Comments"
                                    />
                                  </div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="input"
                                      type="text"
                                      name="repositioningDressingNumberOfStaff"
                                      label="Number of Staff"
                                    />
                                  </div>
                                  <div className="grid grid-cols-2 gap-2">
                                    <div className="mt-2">
                                      <FormikController
                                        control="textArea"
                                        type="text"
                                        name="repositioningDressingEquipment"
                                        label="Equipment"
                                      />
                                    </div>
                                    <div className="mt-2">
                                      <FormikController
                                        control="textArea"
                                        type="text"
                                        name="repositioningDressingFurtherPrecautions"
                                        label="Further Precautions"
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                              <br />
                              <label>
                                <Field
                                  type="checkbox"
                                  name="repositioningOtherSafeSystemofWork"
                                  value={true}
                                  checked={
                                    values.repositioningOtherSafeSystemofWork ===
                                    true
                                  }
                                />
                                <span className="ml-2">Other</span>
                              </label>
                              {values.repositioningOtherSafeSystemofWork ===
                                true && (
                                <div>
                                  <div className="grid grid-cols-2 gap-2">
                                    <div className="mt-2">
                                      <FormikController
                                        control="textArea"
                                        type="text"
                                        name="repositioningOtherSafeSystemofWorkAssistance"
                                        label="Comments"
                                      />
                                    </div>
                                    <div className="mt-2">
                                      <FormikController
                                        control="input"
                                        type="text"
                                        name="repositioningOtherSafeSystemofWorkNumberOfStaff"
                                        label="Number of Staff"
                                      />
                                    </div>
                                    <div className="grid grid-cols-2 gap-2">
                                      <div className="mt-2">
                                        <FormikController
                                          control="textArea"
                                          type="text"
                                          name="repositioningOtherSafeSystemofWorkEquipment"
                                          label="Equipment"
                                        />
                                      </div>
                                      <div className="mt-2">
                                        <FormikController
                                          control="textArea"
                                          type="text"
                                          name="repositioningOtherSafeSystemofWorkFurtherPrecautions"
                                          label="Further Precautions"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className="grid grid-cols-1 gap-2 mt-2">
                              <FormikController
                                control="textArea"
                                type="text"
                                name="repositioningSummary"
                                label="Summary"
                              />
                            </div>
                          </div><div></div>
                                       <div className="flex flex-row-reverse mb-10"><div><button className="bg-button text-white w-full rounded p-1.5 text-lg px-10 mt-10" onClick={()=> setShowRepositioning(false)}>Save</button></div> </div>
                        </div>
                      )}
                                </div>
                              </div>
                            </div>
                            
                          </div>

                          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                        </>
                      ) : null}
            
                      <label className="flex justify-between border bg-wednesday p-3 rounded">
                        {/* <Field
                          type="checkbox"
                          name="nutritionAndHydrationRiskAssessment"
                          value={true}
                          checked={
                            values.nutritionAndHydrationRiskAssessment === true
                          }
                        /> */}
                        <span className="ml-1 text-lg font-semibold text-gray-500 mt-1 ">
                          Nutrition & Hydration Risk Assessment
                        </span>
                        <button
                          className=" text-white bg-button border hover:bg-white hover:text-gray-700 hover:border hover:border-gray-300 hover:underline decoration-secondary focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-1 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                          type="button"
                          onClick={() => handleOpenNutrition()}
                        >
                          View
                        </button>
                      </label>
                      {showNutrition ? (
                        <>
                          <div className="justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                            <div className="relative w-auto my-6 mx-auto max-w-7xl">
                              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                  <h3 className="text-xl font-semibold text-gray-500">
                                    <span className="text-green">
                                    Nutrition & Hydration Risk Assessment
                                    </span>
                                  </h3>
                                  <button
                                    className="p-1 ml-10 bg-transparent border-0 text-button float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                    onClick={() => setShowNutrition(false)}
                                  >
                                    ×
                                  </button>
                                </div>

                                <div className="relative p-5 flex-auto">
                                  <div className="">
                                    <div></div>
                                  </div>
                                </div>
                                <div className="">
                                {showNutrition && (
                        <div className=" px-5">
                          <div className="">
                            <Head title="Please fill in the details" />
                          </div>

                          <div className="grid grid-cols-2 gap-2 mt-3">
                            <FormikController
                              control="textArea"
                              type="text"
                              name="nutritionCondition"
                              label="Condition"
                            />
                            <FormikController
                              control="textArea"
                              type="text"
                              name="nutritionMedication"
                              label="Medication"
                            />
                          </div>
                          <FormikController
                            control="textArea"
                            type="text"
                            name="nutritionLastReview"
                            label="Are there any changes since the lasty review ?"
                          />
                          <div className="text-left">
                            <span className="font-medium">
                              Please specify any Physical Hazards
                            </span>
                            <br />
                            <div className="border rounded p-3 text-left">
                              <label>
                                <Field
                                  type="checkbox"
                                  name="difficultySwallowing"
                                  value={true}
                                  checked={values.difficultySwallowing === true}
                                />
                                <span className="ml-2">
                                  Is the individual having difficulties
                                  swallowing ?
                                </span>
                              </label>
                              {values.difficultySwallowing === true && (
                                <div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="difficultySwallowingComment"
                                      label="Comments"
                                    />
                                  </div>
                                </div>
                              )}
                              <br />
                              <label>
                                <Field
                                  type="checkbox"
                                  name="appetiteLoss"
                                  value={true}
                                  checked={values.appetiteLoss === true}
                                />
                                <span className="ml-2">
                                  Is the individual experiencing loose of
                                  appetite ?
                                </span>
                              </label>
                              {values.appetiteLoss === true && (
                                <div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="appetiteLossComment"
                                      label="Comments"
                                    />
                                  </div>
                                </div>
                              )}
                              <br />
                              <label>
                                <Field
                                  type="checkbox"
                                  name="impairedSpeech"
                                  value={true}
                                  checked={values.impairedSpeech === true}
                                />
                                <span className="ml-2">
                                  Is the individual speech impaired ?
                                </span>
                              </label>

                              {values.impairedSpeech === true && (
                                <div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="impairedSpeechComment"
                                      label="Comments"
                                    />
                                  </div>
                                </div>
                              )}
                              <br />
                              <label>
                                <Field
                                  type="checkbox"
                                  name="orthopaedic"
                                  value={true}
                                  checked={values.orthopaedic === true}
                                />
                                <span className="ml-2">
                                  Are there orthopaedic considerations ?
                                </span>
                              </label>

                              {values.orthopaedic === true && (
                                <div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="orthopaedicComment"
                                      label="Comments"
                                    />
                                  </div>
                                </div>
                              )}
                              <br />
                              <label>
                                <Field
                                  type="checkbox"
                                  name="poorSkinCondition"
                                  value={true}
                                  checked={values.poorSkinCondition === true}
                                />
                                <span className="ml-2">
                                  Is the skin condition poor ?
                                </span>
                              </label>

                              {values.poorSkinCondition === true && (
                                <div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="poorSkinConditionComment"
                                      label="Comments"
                                    />
                                  </div>
                                </div>
                              )}

                              <br />
                              <label>
                                <Field
                                  type="checkbox"
                                  name="hasPEG"
                                  value={true}
                                  checked={values.hasPEG === true}
                                />
                                <span className="ml-2">
                                  Does the individual have a PEG?
                                </span>
                              </label>

                              {values.hasPEG === true && (
                                <div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="hasPEGComment"
                                      label="Comments"
                                    />
                                  </div>
                                </div>
                              )}
                              <br />
                              <label>
                                <Field
                                  type="checkbox"
                                  name="haveTracheostomy"
                                  value={true}
                                  checked={values.haveTracheostomy === true}
                                />
                                <span className="ml-2">
                                  Does the individual have a Tracheostomy ?
                                </span>
                              </label>

                              {values.haveTracheostomy === true && (
                                <div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="haveTracheostomyComment"
                                      label="Comments"
                                    />
                                  </div>
                                </div>
                              )}

                              <br />
                              <label>
                                <Field
                                  type="checkbox"
                                  name="pressureSores"
                                  value={true}
                                  checked={values.pressureSores === true}
                                />
                                <span className="ml-2">
                                  Is the individual at risk of a pressure sores
                                  ?
                                </span>
                              </label>

                              {values.pressureSores === true && (
                                <div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="pressureSoresComment"
                                      label="Comments"
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="text-left mt-3">
                            <span className="font-medium">
                              Please specify any Chocking Risk Assessment
                            </span>
                            <br />
                            <div className="border rounded p-3 text-left">
                              <label>
                                <Field
                                  type="checkbox"
                                  name="cognitiveFunction"
                                  value={true}
                                  checked={values.cognitiveFunction === true}
                                />
                                <span className="ml-2">Cognitive Function</span>
                              </label>
                              {values.cognitiveFunction === true && (
                                <div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="cognitiveFunctionComment"
                                      label="Comments"
                                    />
                                  </div>
                                </div>
                              )}
                              <br />
                              <label>
                                <Field
                                  type="checkbox"
                                  name="alertness"
                                  value={true}
                                  checked={values.alertness === true}
                                />
                                <span className="ml-2">Alertness</span>
                              </label>
                              {values.alertness === true && (
                                <div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="alertnessComment"
                                      label="Comments"
                                    />
                                  </div>
                                </div>
                              )}
                              <br />
                              <label>
                                <Field
                                  type="checkbox"
                                  name="posturalControl"
                                  value={true}
                                  checked={values.posturalControl === true}
                                />
                                <span className="ml-2">Postural Control</span>
                              </label>
                              {values.posturalControl === true && (
                                <div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="posturalControlComment"
                                      label="Comments"
                                    />
                                  </div>
                                </div>
                              )}
                              <br />
                              <label>
                                <Field
                                  type="checkbox"
                                  name="generalHealth"
                                  value={true}
                                  checked={values.generalHealth === true}
                                />
                                <span className="ml-2">General Health</span>
                              </label>
                              {values.generalHealth === true && (
                                <div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="generalHealtComment"
                                      label="Comments"
                                    />
                                  </div>
                                </div>
                              )}
                              <br />
                              <label>
                                <Field
                                  type="checkbox"
                                  name="fatigue"
                                  value={true}
                                  checked={values.fatigue === true}
                                />
                                <span className="ml-2">Fatigue</span>
                              </label>

                              {values.fatigue === true && (
                                <div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="fatigueComment"
                                      label="Comments"
                                    />
                                  </div>
                                </div>
                              )}

                              <br />
                              <label>
                                <Field
                                  type="checkbox"
                                  name="oralHealth"
                                  value={true}
                                  checked={values.oralHealth === true}
                                />
                                <span className="ml-2">Oral Health</span>
                              </label>

                              {values.oralHealth === true && (
                                <div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="oralHealthComment"
                                      label="Comments"
                                    />
                                  </div>
                                </div>
                              )}
                              <br />
                              <label>
                                <Field
                                  type="checkbox"
                                  name="respiratoryFunction"
                                  value={true}
                                  checked={values.respiratoryFunction === true}
                                />
                                <span className="ml-2">
                                  Respiratory Function
                                </span>
                              </label>

                              {values.respiratoryFunction === true && (
                                <div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="respiratoryFunctionComment"
                                      label="Comments"
                                    />
                                  </div>
                                </div>
                              )}
                              <br />
                              <label>
                                <Field
                                  type="checkbox"
                                  name="behaviouralMentalHealth"
                                  value={true}
                                  checked={
                                    values.behaviouralMentalHealth === true
                                  }
                                />
                                <span className="ml-2">
                                  Behavioural Mental Health
                                </span>
                              </label>

                              {values.behaviouralMentalHealth === true && (
                                <div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="behaviouralMentalHealthComment"
                                      label="Comments"
                                    />
                                  </div>
                                </div>
                              )}

                              <br />
                              <label>
                                <Field
                                  type="checkbox"
                                  name="pain"
                                  value={true}
                                  checked={values.pain === true}
                                />
                                <span className="ml-2">Pain</span>
                              </label>

                              {values.pain === true && (
                                <div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="painComment"
                                      label="Comments"
                                    />
                                  </div>
                                </div>
                              )}
                              <br />
                              <label>
                                <Field
                                  type="checkbox"
                                  name="pureDiet"
                                  value={true}
                                  checked={values.pureDiet === true}
                                />
                                <span className="ml-2">
                                  Is the individual on a pureed diet ?
                                </span>
                              </label>

                              {values.pureDiet === true && (
                                <div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="pureDietComment"
                                      label="Comments"
                                    />
                                  </div>
                                </div>
                              )}

                              <br />
                              <label>
                                <Field
                                  type="checkbox"
                                  name="drinkThichener"
                                  value={true}
                                  checked={values.drinkThichener === true}
                                />
                                <span className="ml-2">
                                  Does the individual have thickener in their
                                  drinks ?
                                </span>
                              </label>

                              {values.drinkThichener === true && (
                                <div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="drinkThichenerComment"
                                      label="Comments"
                                    />
                                  </div>
                                </div>
                              )}

                              <br />
                              <label>
                                <Field
                                  type="checkbox"
                                  name="medicalConditions"
                                  value={true}
                                  checked={values.medicalConditions === true}
                                />
                                <span className="ml-2">Medical Conditions</span>
                              </label>

                              {values.medicalConditions === true && (
                                <div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="medicalConditionsComment"
                                      label="Comments"
                                    />
                                  </div>
                                </div>
                              )}

                              <br />
                              <label>
                                <Field
                                  type="checkbox"
                                  name="excessiveSecretions"
                                  value={true}
                                  checked={values.excessiveSecretions === true}
                                />
                                <span className="ml-2">
                                  Excessive Secretions
                                </span>
                              </label>

                              {values.excessiveSecretions === true && (
                                <div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="excessiveSecretionsComment"
                                      label="Comments"
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="text-left mt-3">
                            <span className="font-medium">
                              Please specify any Other Hazards
                            </span>
                            <br />
                            <div className="border rounded p-3 text-left">
                              <label>
                                <Field
                                  type="checkbox"
                                  name="otherHazards"
                                  value={true}
                                  checked={values.otherHazards === true}
                                />
                                <span className="ml-2">Other ?</span>
                              </label>
                              {values.otherHazards === true && (
                                <div>
                                  <div className="mt-2">
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="otherHazardsComment"
                                      label="Comments"
                                    />
                                  </div>
                                </div>
                              )}
                            </div>

                            <div className="grid grid-cols-1 gap-2 mt-2">
                              <FormikController
                                control="textArea"
                                type="text"
                                name="summmary"
                                label="Summary"
                              />
                            </div>
                          </div>                                        <div></div>
                                       <div className="flex flex-row-reverse mb-5"><div><button className="bg-button text-white w-full rounded p-1.5 text-lg px-10 mt-10" onClick={()=> setShowNutrition(false)}>Save</button></div> </div>
                        </div>
                      )}
                                </div>
                              </div>
                            </div>
                            
                          </div>

                          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                        </>
                      ) : null}                     
                    </div>
                    <div className="mb-3 mt-5 flex justify-between">
                      <div className="mb-3 pt-3">
                        {/* <button
                          className="bg-button text-white w-full rounded p-1.5 text-lg px-10"
                          onClick={() => goBack()}
                        >
                          Back
                        </button> */}
                      </div>
                      <SubmitButton label="Update" />
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>    </div>
  );
}

export default EditRiskAssessment;
