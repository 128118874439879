import axios from "axios";
import jwt_decode from 'jwt-decode';
import { Navigate } from "react-router-dom";


export const userLogin = async (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = await axios.post(`${process.env.REACT_APP_ELMS_API_URL}admins/auth`, data)
            resolve(res.data);

            if (res.status === 201) {
                sessionStorage.setItem('access_token', res.data.access_token);
                localStorage.setItem('refreshToken', res.data.refreshToken);
            }

        } catch (error) {
            reject(error)
        }
    })
}

export const fetchUser = async (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = sessionStorage.getItem('access_token')
            const decoded = jwt_decode(token);
            const res = await axios.get(`${process.env.REACT_APP_ELMS_API_URL}admins/${decoded.userId}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            resolve(res.data);

        } catch (error) {
            if(error.response?.data?.statusCode === 404){
                return null
             }
             if(error.response?.data?.statusCode === 401){
                <Navigate replace to="/login"/>
             }
            
             reject(error)
        }
    })
}

export const updateMyAccount = async (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = sessionStorage.getItem('access_token')
            const res = await axios.patch(`${process.env.REACT_APP_ELMS_API_URL}admins/${data.id}`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            resolve(res.data);

        } catch (error) {
            if(error?.response?.data?.statusCode === 401){
                <Navigate replace to="/login"/>
             }
            
            reject(error)
        }
    })
}

export const resetEmail = async (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = sessionStorage.getItem('access_token')
            const res = await axios.patch(`${process.env.REACT_APP_ELMS_API_URL}admins/reset/password`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            resolve(res.data);

        } catch (error) {
            if(error?.response?.data?.statusCode === 401){
                <Navigate replace to="/login"/>
             }
            
            reject(error)
        }
    })
}

export const changePassword = async (data) => {
    console.log('data',data);
    return new Promise(async (resolve, reject) => {
        try {
            const token = sessionStorage.getItem('access_token')
            const res = await axios.patch(`${process.env.REACT_APP_ELMS_API_URL}admins/change/password`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            resolve(res.data);

        } catch (error) {
            if(error?.response?.data?.statusCode === 401){
                <Navigate replace to="/login"/>
             }
            
            reject(error)
        }
    })
}



export const logout = () => {
    sessionStorage.removeItem('access_token');
    localStorage.removeItem('refreshToken');
    <Navigate replace to="/login"/>
}