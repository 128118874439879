import React from 'react'
import EditCarePlan from '../AddClient/EditCarePlan';
import SelectCarePlan from '../AddClient/SelectCarePlan';
import getCarePlann from '../../careplans/carePlanActions/getCarePlanAction'
import { useDispatch, useSelector } from 'react-redux';
import EditMarChart from '../AddClient/EditMarChart';
import addedPlans from '../ClientActions/GetAddedCarePlansAction'
import PageLoader from '../../../components/PageLoader';
// import fetchClients from '../ClientActions/GetClientsAction'

function CarePlans({ client }) {

  const dispatch = useDispatch();
  const { editCarePlan,isLoadingUpdateCarePlan } = useSelector(state => state.updateCarePlans);
  const { addClientCarePlans,isLoadingAddCarePlan } = useSelector(state => state.addClientCarePlan)
  const { getCarePlan, isLoadingGetCarePlan } = useSelector(state => state.getCarePlan)
  const {addedCarePlans, isLoadingAddedPlans} = useSelector(state => state.addedclientcareplans)
  const [page, setPage] = React.useState(1);
  const itemsCountPerPage = 50;

  React.useEffect(() => {
    dispatch(getCarePlann({
      page,
      perPage: itemsCountPerPage
    }))
    dispatch(addedPlans({
      clientId: client.id
    }))
  //   dispatch(fetchClients({
  //     status: 'ACTIVE',
  //     page,
  //     perPage: itemsCountPerPage
  // }))
  }, [editCarePlan, addClientCarePlans])

  const compareArrays = (careId) => {
    const result = Array.isArray(addedCarePlans) && addedCarePlans?.find((e) => e.careplan.id === careId);
    const value = result ? result.careplan.id : '';
    return value;
  }

  const Head = (props) => {
    return (
        <div className='text-left border-b'><label className='text-xl font-medium text-gray-700'>{props.title}</label></div>
    )
}
const uniqueIds = [];


const unique = Array.isArray(addedCarePlans) && addedCarePlans?.filter(element => {
  const isDuplicate = uniqueIds.includes(element.careplan.code);

  if (!isDuplicate) {
    uniqueIds.push(element.careplan.code);

    return true;
  }

  return false;
});
// console.log('unique',unique);
  return ((isLoadingAddCarePlan || isLoadingUpdateCarePlan) ? <PageLoader/> :
    <div className="text-center">
       <div className='px-5 font-bold'><Head title='Added Careplans'/></div>
      <div className='grid grid-cols-4 gap-1 p-5'>
        {isLoadingAddedPlans ? <PageLoader/> : Array.isArray(unique) && unique.length > 0 ? unique.map((e) => {
          return (<> 
            <p key={e.id} className={`${e.careplan.code === 'CP06' ?"block p-6 max-w-sm bg-plan rounded-lg border border-gray-200 hover:bg-gray-500 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700  ": "block p-6 max-w-sm bg-other rounded-lg border hover:text-gray-500 border-gray-200 hover:bg-gray-500 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"}`}>
              <h5 className={`${e.careplan.code === 'CP06' ? "mb-2 text-2xl font-bold tracking-tight text-white dark:text-white " : "mb-2 text-2xl font-bold tracking-tight text-white dark:text-white"}`}>{e.careplan.code}</h5>
              <p className={`${e.careplan.code === 'CP06' ? " text-white font-bold dark:text-gray-400 mb-3" : " text-white dark:text-gray-400 mb-3 font-bold"}`}>{e.careplan.title} </p>
            { e.careplan.code === 'CP06' ? <EditMarChart client={client} /> : <EditCarePlan e={e} client={client}/>}
            </p> 
          </>)
        }) :  <div className='flex ml-4'><span className='bg-red-100 p-3 rounded font-medium'> No Careplans Added</span></div>
        } 
      </div>
      <div className='px-5 font-bold'><Head title='All Careplans'/></div>
      <div className='grid grid-cols-4 gap-1 p-5'>
        {isLoadingGetCarePlan ? <PageLoader/> : getCarePlan && !isLoadingGetCarePlan && getCarePlan.data && getCarePlan.data.map((care) => {

          return (care.code === 'CP06') &&
            <p key={care.id} className="block p-6 max-w-sm bg-yellow-100 rounded-lg border border-gray-200  hover:bg-yellow-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
              <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-700 dark:text-white">{care.code}</h5>
              <p className="font-medium text-gray-700 dark:text-gray-400">{care.title}</p>
              {<SelectCarePlan care={care} client={client} />}
            </p>
        })}
        { isLoadingGetCarePlan ? <PageLoader/> : getCarePlan && getCarePlan.data && getCarePlan.data.map((care) => {          
          return (care.id !== compareArrays(care.id) && care.code !== 'CP06') &&
            <p key={care.id} className="block p-6 max-w-sm bg-primary rounded-lg border border-gray-200  hover:bg-yellow-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
              <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-700 dark:text-white">{care.code}</h5>
              <p className="font-medium text-gray-700 dark:text-gray-400">{care.title}</p>
              {<SelectCarePlan care={care} client={client} />}
            </p>
        })
        }

      </div>
    </div>
  )
}

export default CarePlans