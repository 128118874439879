import {GetEthnicityPending, GetEthnicitySuccess, GetEthnicityFail} from '../RegisterCarerSlices/GetEthnicitySlice'
import {GetEthnicity} from '../../api/carerApi'

 const GetEthnicityAction = (data) => async dispatch => {

    try {
        dispatch(GetEthnicityPending());
        const result = await GetEthnicity(data);
        if(result){
            return dispatch(GetEthnicitySuccess(result))
        }
    } catch (error) {
        dispatch(GetEthnicityFail(error))
    }
} 

export default GetEthnicityAction