import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import getCarePlann from '../../careplans/carePlanActions/getCarePlanAction'
import Pagination from "react-js-pagination";
import SelectCarePlan from './SelectCarePlan';
import EditCarePlan from './EditCarePlan';
import { useNavigate } from 'react-router-dom';
import addedPlans from '../../clients/ClientActions/GetAddedCarePlansAction'
import EditMarChart from './EditMarChart';
import { ImBlocked } from "react-icons/im";


function CarePlan() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { addCare } = useSelector(state => state.addCarePlan);
  const { addedCarePlans } = useSelector(state => state.addedCarePlan);
  const { updateCarePlan } = useSelector(state => state.updateCarePlan)
  const { addClientCarePlans} = useSelector(state => state.addClientCarePlan)
  const { getCarePlan, isLoadingGetCarePlan } = useSelector(state => state.getCarePlan)
  const { addClientPersonalDetails } = useSelector(state => state.addClientPersonal);
  const { editCarePlan} = useSelector(state => state.updateCarePlans);
  const {editPrescription} = useSelector(state => state.editPrescriptionDoc)
  const itemsCountPerPage = 50;

  const [page, setPage] = React.useState(1);

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  }


  const handleSubmit = () => {
    navigate('/client-tiers')
  }
  React.useEffect(() => {
    dispatch(addedPlans({
      clientId: addClientPersonalDetails ? addClientPersonalDetails.id : ''
    }));
    dispatch(getCarePlann({
      page,
      perPage: itemsCountPerPage
    }))

  }, [addCare, updateCarePlan, page, dispatch, addClientPersonalDetails,addClientCarePlans,editCarePlan, editPrescription]);

  const compareArrays = (careId) => {
      const result = Array.isArray(addedCarePlans) &&  addedCarePlans.find((e)=> e.careplan.id === careId);
      const value = result ? result.careplan.id : '';
      return value;
  }

  const Head = (props) => {
    return (
        <div className='text-left border-b bg-indigo-100  p-2 rounded '><label className='text-lg font-bold text-gray-700 '>{props.title}</label></div>
    )
}
const uniqueIds = [];


const unique = Array.isArray(addedCarePlans) && addedCarePlans?.filter(element => {
  const isDuplicate = uniqueIds.includes(element.careplan.code);

  if (!isDuplicate) {
    uniqueIds.push(element.careplan.code);

    return true;
  }

  return false;
});

  return (
    <div>
       <h1 class="mb-4 text-xl font-bold text-gray-900 dark:text-white text-left border-b"><span class="text-transparent bg-clip-text bg-gradient-to-r to-emerald-600 from-orange-600">Added Careplans</span></h1>
       <div className='grid grid-cols-5 gap-1'>
        {Array.isArray(unique) && unique.length > 0 ? unique.map((e) => {
          return (<>
            <p key={e.id} className={`${e.careplan.code === 'CP06' ?"block p-6 max-w-sm bg-plan rounded-lg border border-gray-200 hover:bg-gray-500 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700  ": "block p-6 max-w-sm bg-other rounded-lg border hover:text-gray-500 border-gray-200 hover:bg-gray-500 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700"}`}>
              <h5 className={`${e.careplan.code === 'CP06' ? "mb-2 text-2xl font-bold tracking-tight text-white dark:text-white " : "mb-2 text-2xl font-bold tracking-tight text-white dark:text-white "}`}>{e.careplan.code}</h5>
              <p className={`${e.careplan.code === 'CP06' ? " text-white font-bold dark:text-gray-400 mb-3" : " text-white dark:text-gray-400 mb-3 font-bold"}`}>{e.careplan.title} </p>
            { e.careplan.code === 'CP06' ? <EditMarChart client={addClientPersonalDetails} /> : <EditCarePlan e={e}/>}
            </p>
          </>)
        }) : <div id="alert-border-2" className="flex p-4 mb-4 rounded-lg text-red-700 bg-gray-100 border-t-4 border-red-500 dark:text-red-400 dark:bg-gray-800" role="alert">
        {/* <svg class="flex-shrink-0 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg> */}
        <div class="ml-3 text-sm font-medium inline-flex">
          No Careplans Added <ImBlocked className='ml-2 ' size={20}/>
        </div>
      
    </div>
        }

      </div>
      <h1 class="mb-4 text-xl font-bold text-gray-900 dark:text-white text-left mt-4 border-b"><span class="text-transparent bg-clip-text bg-gradient-to-r to-emerald-600 from-orange-600">All Careplans</span></h1>
      <div className='grid grid-cols-5 gap-1'>
      {getCarePlan && getCarePlan.data && getCarePlan.data.map((care) => {
           
           return (care.code === 'CP06') &&
             <p key={care.id} className="block p-6 max-w-sm bg-yellow-100 rounded-lg border border-gray-200  hover:bg-yellow-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
               <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-700 dark:text-white">{care.code}</h5>
               <p className="font-medium text-gray-700 dark:text-gray-400 h-20">{care.title}</p>
               { <SelectCarePlan care={care} />}
             </p>
         })}
        {getCarePlan && getCarePlan.data && getCarePlan.data.map((care) => {
           
          return (care.id !== compareArrays(care.id) && care.code !== 'CP06') &&
            <p key={care.id} className="block p-6 max-w-sm bg-primary rounded-lg border border-gray-200  hover:bg-yellow-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
              <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-700 dark:text-white">{care.code}</h5>
              <p className="font-medium text-gray-700 dark:text-gray-400 h-20">{care.title}</p>
              { <SelectCarePlan care={care} />}
            </p>
        })}

      </div>
      {(getCarePlan && getCarePlan.data && getCarePlan.data.length > 0 && !isLoadingGetCarePlan) &&
        <div className="flex justify-end">
          <Pagination
            activePage={page}
            itemsCountPerPage={itemsCountPerPage}
            totalItemsCount={getCarePlan.totalItemsCount}
            pageRangeDisplayed={1}
            onChange={handlePageChange}
            itemClass="page-item"
            linkClass="page-link"
          />
        </div>}

      <div className="text-right mt-10"><button className="bg-button text-white  rounded p-1.5 text-lg px-10" onClick={() => handleSubmit()}>Save & Next</button></div>
    </div>
  )
}

export default CarePlan