import React from "react";
import { Sidebar } from "../../../layouts/auth/Sidebar";
import { TopNavigation } from "../../../layouts/auth/TopNavigation";
import { useNavigate } from "react-router-dom";
import FormikController from "../../../components/Formik/FormikController";
import SubmitButton from "../../../components/Formik/SubmitButton";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import ProgressBar from "../../clients/AddClient/ProgressBar";
import { FaJenkins } from "react-icons/fa";
import Postcoder from "react-address-lookup";
import { useDispatch, useSelector } from "react-redux";
import PersonalDetailsAction from "../RegisterCarerActions/PersonalDetailsAction";
import UpdatePersonalInfoAction from "../RegisterCarerActions/UpdatePersonalDetailsAction";

const AddPersonal = () => {
  const { signUp, isLoadingSignUp, errorSignUp } = useSelector(
    (state) => state.signUpCarer
  );
 
  
  const navigate = useNavigate();
  const dispatch = useDispatch();

  React.useEffect(() => {}, []);

  const gloves = [
    { key: "Small", value: "Small" },
    { key: "Medium", value: "Medium" },
    { key: "Large", value: "Large" },
    { key: "X-Large", value: "X-Large" },
  ];

  const gender = [
    { key: "Male", value: "Male" },
    { key: "Female", value: "Female" },
    { key: "Other", value: "Other" },
  ];

  const initialValues = {
    firstname: "",
    middlename: "",
    lastname: "",
    email: "",
    mobile: "",
    gender: "",
    dob: "",
    selfEmployed: true,
    nationalInsuranceNumber: "",
    dogTolerant: true,
    catTolerant: true,
    smoker: true,
    ownTransport: true,
    postCode: "",
    addressLine1: "",
    addressLine2: "",
    town: "",
    county: "",
    country: "",
    glove: "",
    CriminalRecordDeclaration: true,
    ethnicityId: "",
    religionId: "",
  };

  const validationSchema = Yup.object({
    // firstname: Yup.string().matches(/^[A-Za-z ]*$/, 'Please enter valid name').max(40).required('This field is required'),
    // lastname: Yup.string()
    // .matches(/^[A-Za-z ]*$/, 'Please enter valid name')
    // .max(40).required('This field is required'),
    // mobile: Yup.string().matches(phoneRegExp, 'Phone number is not valid').required('This field is required'),
    // middlename: Yup.string()
    // .matches(/^[A-Za-z ]*$/, 'Please enter valid name')
    // .max(40).required('This field is required'),
    // landline: Yup.string().matches(phoneRegExp, 'Phone number is not valid').required('This field is required'),
    // addressLine1: Yup.string().required('This field is required'),
    // addressLine2: Yup.string().required('This field is required'),
    // town: Yup.string().required('This field is required'),
    // county: Yup.string().required('This field is required'),
    // gender: Yup.string().required('This field is required'),
    // postcode: Yup.string().required('This field is required'),
    // dob: Yup.string().required('This field is required'),
  });

  const handleSubmit = (values) => {
    values.id = signUp.user.id;
    values.email = signUp.user.email;
    console.log("Personal", values);
    dispatch(PersonalDetailsAction(values));
    navigate("/carer-availability");
  };

  const handleUpdate = (values) => {
    console.log("VAL", values);
    dispatch(UpdatePersonalInfoAction(values));
  };

  const Head = (props) => {
    return (
      <div className="border-b mb-5">
        <label className="text-xl font-medium text-gray-700">
          {props.title}
        </label>
      </div>
    );
  };

  return (
    <div className="bg-gray-50 h-screen">
      <Sidebar />
      <TopNavigation title="Add Carer" />
      <div className="bg-gray-50 ">
        <div className="flex ml-80 mr-16 mt-5">
          <div className="w-40  border mt-5 p-3 rounded-lg bg-gray-50">
            <ProgressBar number={10} />
          </div>
          <div className="w-full p-5">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values) => handleSubmit(values)}
            >
              {({ formik, values }) => {
                const logAddress = (addr) => {
                  formik.setFieldValue("addressLine1", addr.addressline1);
                  formik.setFieldValue("addressLine2", addr.addressline2);
                  formik.setFieldValue("county", addr.county);
                  formik.setFieldValue("country", addr.country);
                  formik.setFieldValue("town", addr.posttown);
                  formik.setFieldValue("postcode", addr.postcode);
                };
                return (
                  <Form className="text-left">
                 

                    <div className="rounded">
                      <div className="text-left font-semibold text-gray-600 py-1">
                        PERSONAL DETAILS
                      </div>
                      <div className="grid grid-cols-2 gap-2 bg-white shadow p-3 rounded">
                        <FormikController
                          control="select"
                          type="select"
                          name="gender"
                          label="Gender*"
                          options={gender}
                        />

                        <FormikController
                          control="input"
                          type="text"
                          name="firstname"
                          label="First name*"
                        />
                        <FormikController
                          control="input"
                          type="text"
                          name="middlename"
                          label="Middle name"
                          placeholder="Optional"
                        />

                        <FormikController
                          control="input"
                          type="text"
                          name="lastname"
                          label="Last name*"
                        />

                        <FormikController
                          control="input"
                          type="date"
                          name="dob"
                          label="Date of Birth*"
                        />

                        <FormikController
                          control="input"
                          type="text"
                          name="preference"
                          label="Prefer to be called"
                          placeholder="Optional"
                        />

                        <FormikController
                          control="input"
                          type="text"
                          name="languages"
                          label="Languages Spoken*"
                          placeholder="eg. English, French, Brittish"
                        />

                        <FormikController
                          control="select"
                          type="select"
                          name="glove"
                          label="Glove Size*"
                          options={gloves}
                        />
                      </div>
                      <div className="text-left font-semibold text-gray-600 py-1 mt-5">
                        CONTACT DETAILS
                      </div>
                      <div className=" p-3 rounded bg-white shadow">
                        <div className="grid grid-cols-2 gap-2">
                          <FormikController
                            control="input"
                            type="text"
                            name="mobile"
                            label="Mobile number*"
                          />
                          <FormikController
                            control="input"
                            type="text"
                            name="nationalInsurance"
                            label="National Insurance Number"
                          />
                        </div>
                        <div className="mt-2">
                          <label className="font-medium ">
                            Search post code
                          </label>
                          <Postcoder
                            apiKey={process.env.REACT_APP_POSTCODER}
                            addressSelectedCallback={logAddress}
                          />
                          <p className="text-sm text-gray-500">
                            Searches and allow you to select your address from
                            the dropdown
                          </p>
                        </div>
                        <div className="grid grid-cols-3 gap-2 mt-5">
                          <div>
                            <FormikController
                              control="input"
                              type="text"
                              name="addressLine1"
                              placeholder=""
                              label="Address line 1 *"
                            />
                          </div>
                          <div>
                            <FormikController
                              control="input"
                              type="text"
                              name="addressLine2"
                              placeholder=""
                              label="Address line 2"
                            />
                          </div>
                          <div>
                            <FormikController
                              control="input"
                              type="text"
                              name="town"
                              placeholder=""
                              label="Town *"
                            />
                          </div>
                          <div>
                            <FormikController
                              control="input"
                              type="text"
                              name="county"
                              placeholder=""
                              label="County "
                            />
                          </div>

                          <div>
                            <FormikController
                              control="input"
                              type="text"
                              name="postCode"
                              placeholder=""
                              label="Post code *"
                            />
                          </div>
                          <div>
                            <FormikController
                              control="input"
                              type="text"
                              name="country"
                              placeholder=""
                              label="Country *"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="font-medium mt-5 ">
                        Please click to say YES
                      </div>

                      <div className="shadow rounded p-3 bg-white">
                        <label>
                          <Field
                            type="checkbox"
                            name="selfEmployed"
                            value={true}
                            checked={values.selfEmployed === true}
                          />
                          <span className="ml-2">Self Employed</span>
                        </label>
                        <br />
                        <label>
                          <Field
                            type="checkbox"
                            name="dogTolerant"
                            value={true}
                            checked={values.dogTolerant === true}
                          />
                          <span className="ml-2">Tolerant to Dogs</span>
                        </label>
                        <br />
                        <label>
                          <Field
                            type="checkbox"
                            name="catTolerant"
                            value={true}
                            checked={values.catTolerant === true}
                          />
                          <span className="ml-2">Tolerant to Cats</span>
                        </label>
                        <br />

                        <label>
                          <Field
                            type="checkbox"
                            name="ownTransport"
                            value={true}
                            checked={values.ownTransport === true}
                          />
                          <span className="ml-2">Own Transport</span>
                        </label>
                        <br />
                        <label>
                          <Field
                            type="checkbox"
                            name="smoker"
                            value={true}
                            checked={values.smoker === true}
                          />
                          <span className="ml-2">Smoker</span>
                        </label>
                        <br />
                      </div>
                    </div>
                    <div className="mb-3 mt-5 flex flex-row-reverse">
                      <div></div>
                      <SubmitButton label="Save & Next" />
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddPersonal;
