import './App.css';
import { BrowserRouter as Router, Routes, Route, Link,useNavigate } from 'react-router-dom';
import Login from './pages/auth/Login'
import ResetPassword from './pages/auth/ResetPassword';
import {Dashboard}  from './pages/dashboard/Dashboard';
import Clients from './pages/clients/Clients';
import Carers from './pages/carers/Carers';
import Staff from './pages/staff/Staff';
import { logout as Outman} from "./api/userApi";
import Finance from './pages/finance/Finance';
import Recruitment from './pages/recruitment/Recruitment';
import Configurations from './pages/configurations/Configurations';
import AddClient from './pages/clients/AddClient/AddClient';
import ReferralContract from './pages/clients/AddClient/ReferralContract';
import KeyContacts from './pages/clients/AddClient/KeyContacts';
import PropertyAccess from './pages/clients/AddClient/PropertyAccess';
import { loginSucess } from './pages/auth/LoginSlice';
import { useSelector } from 'react-redux'
import { PrivateRoute } from './components/PrivateRoute';
import Account from './pages/myaccount/Account'
import Careplans from './pages/careplans/Careplans'
import ClientCarePlan from './pages/clients/AddClient/ClientCarePlan'
import ClientTier from './pages/clients/AddClient/ClientTier'
import Active from './pages/clients/Active'
import Client from './pages/clients/AddClient/Client'
import AllClient from './pages/clients/EditClient/AllClient'
import ViewApplication from './pages/dashboard/ViewApplication';
import RiskAssessment from './pages/clients/AddClient/RiskAssessment';
import AssignedClients from './pages/staff/AssignedClients';
import AssignedCarers from './pages/staff/AssignedCarers';
import React from 'react';
import { BroadcastChannel } from 'broadcast-channel';
import { ToastProvider, useToasts } from 'react-toast-notifications';
import AddCarer from './pages/carers/AddCarer/AddCarer';
import PersonalDetails from './pages/carers/AddCarer/PersonalDetails';
import CarerAvailability from './pages/carers/AddCarer/CarerAvailability';
import NextofKin from './pages/carers/AddCarer/NextofKin';
import CarerDocuments from './pages/carers/AddCarer/CarerDocuments';
import CarerEducationTraining from './pages/carers/AddCarer/CarerEducationTraining';
import CarerEmployment from './pages/carers/AddCarer/CarerEmployment';
import CarerCriminalRecord from './pages/carers/AddCarer/CarerCriminalRecord';
import CarerDisability from './pages/carers/AddCarer/CarerDisability';
import CarerHealthDeclaration from './pages/carers/AddCarer/CarerHealthDeclaration';
import CarerEqualMonitoring from './pages/carers/AddCarer/CarerEqualMonitoring';
import CarerDataConsent from './pages/carers/AddCarer/CarerDataConsent';
import CarerTraining from './pages/carers/AddCarer/CarerTraining';
import CarerBackground from './pages/carers/AddCarer/CarerBackground';
import CarerOptAgreement from './pages/carers/AddCarer/CarerOptAgreement';


function App() {
  const navigate = useNavigate();

  const { isAuth } = useSelector((state) => state.login);
  const { user } = useSelector((state) => state.user);
  const [role, setRole] = React.useState('')
  const rol =  user ? user.role : ""
  
  const checkRole = async () => {
    const rol = await user ? user.role : ""
    setRole(rol)
  }
 
  const logoutChannel = new BroadcastChannel('logout');


  const CheckToken = () => {
    // const { addToast } = useToasts();
    const token = localStorage.getItem('refreshToken')
    // if(!token) return  addToast('Successfully', { appearance: 'success' });
    return token

}

   const logout = () => {
    Outman();
    navigate('/', { replace: true });
  logoutChannel.postMessage("Logout")
  localStorage.removeItem('refreshToken')
  window.location.href = window.location.origin + "/";
}

  
  const logoutAllTabs = () => {
  logoutChannel.onmessage = () => {
      logout();
      CheckToken();
      logoutChannel.close();

  }
}
  React.useState(()=>{
    checkRole();
    logoutAllTabs();
  },[CheckToken]);
  // console.log('rol',rol);
 
  return (

    <div className="App">
      {/* <Router > */}
        <Routes navigate={navigate}>
          <Route exact path='/' element={<Login />}></Route>
          <Route exact path='/reset-password' element={<ResetPassword />}></Route>
          <Route exact path='/dashboard' element= {<PrivateRoute><Dashboard/></PrivateRoute> }></Route> 
          <Route exact path='/clients' element={<PrivateRoute><Clients/></PrivateRoute>}></Route>
          <Route exact path='/carers' element={ <PrivateRoute><Carers /></PrivateRoute> }></Route>
          <Route exact path='/staff' element={ <PrivateRoute><Staff /></PrivateRoute> }></Route>
          <Route exact path='/finance' element={ <PrivateRoute><Finance /></PrivateRoute> }></Route>
          <Route exact path='/recruitment' element={ <PrivateRoute><Recruitment /></PrivateRoute> }></Route>
          <Route exact path='/configurations' element={ <PrivateRoute><Configurations /></PrivateRoute> }></Route>
          <Route exact path='/add-client' element={ <PrivateRoute><AddClient /></PrivateRoute> }></Route>
          <Route exact path='/referral-contracts' element={ <PrivateRoute><ReferralContract /></PrivateRoute>}></Route>
          <Route exact path='/key-contacts' element={ <PrivateRoute><KeyContacts /></PrivateRoute> }></Route>
          <Route exact path='/property-access' element={ <PrivateRoute><PropertyAccess /></PrivateRoute> }></Route>
          <Route exact path='/my-account' element={<PrivateRoute><Account /></PrivateRoute> }></Route>
          <Route exact path='/care-plans' element={ <PrivateRoute><Careplans /></PrivateRoute>}></Route>
          <Route exact path='/care-plan' element={ <PrivateRoute><ClientCarePlan /></PrivateRoute>}></Route>
          <Route exact path='/client-tiers' element={ <PrivateRoute><ClientTier /></PrivateRoute>}></Route>
          <Route exact path='/active-clients' element={ <PrivateRoute><Active /></PrivateRoute>}></Route>
          <Route exact path='/client' element={ <PrivateRoute><Client /></PrivateRoute>}></Route>
          <Route exact path='/edit-client' element={ <PrivateRoute><AllClient /></PrivateRoute>}></Route>
          <Route exact path='/new-applications' element={ <PrivateRoute><ViewApplication /></PrivateRoute>}></Route>
          <Route exact path='/risk-assessment' element={ <PrivateRoute><RiskAssessment /></PrivateRoute>}></Route>
          <Route exact path='/assigned-clients' element={rol === 'REGULATOR' ? <PrivateRoute><AssignedClients /></PrivateRoute> : <Login/>}></Route>
          <Route exact path='/assigned-carers' element={<PrivateRoute><AssignedCarers /></PrivateRoute>}></Route>
          <Route exact path='/add-carer' element={<PrivateRoute><AddCarer /></PrivateRoute>}></Route>
          <Route exact path='/carer-info' element={<PrivateRoute><PersonalDetails /></PrivateRoute>}></Route>
          <Route exact path='/carer-availability' element={<PrivateRoute><CarerAvailability /></PrivateRoute>}></Route>
          <Route exact path='/nextofkin' element={<PrivateRoute><NextofKin /></PrivateRoute>}></Route>
          <Route exact path='/carer-documents' element={<PrivateRoute><CarerDocuments /></PrivateRoute>}></Route>
          <Route exact path='/carer-education' element={<PrivateRoute><CarerEducationTraining /></PrivateRoute>}></Route>
          <Route exact path='/carer-employment' element={<PrivateRoute><CarerEmployment /></PrivateRoute>}></Route>
          <Route exact path='/carer-criminalrecord' element={<PrivateRoute><CarerCriminalRecord /></PrivateRoute>}></Route>
          <Route exact path='/carer-disability' element={<PrivateRoute><CarerDisability /></PrivateRoute>}></Route>
          <Route exact path='/carer-health-declaration' element={<PrivateRoute><CarerHealthDeclaration /></PrivateRoute>}></Route>
          <Route exact path='/carer-equal-monitoring' element={<PrivateRoute><CarerEqualMonitoring /></PrivateRoute>}></Route>
          <Route exact path='/carer-data-consent' element={<PrivateRoute><CarerDataConsent /></PrivateRoute>}></Route>
          <Route exact path='/carer-training' element={<PrivateRoute><CarerTraining /></PrivateRoute>}></Route>
          <Route exact path='/carer-background' element={<PrivateRoute><CarerBackground /></PrivateRoute>}></Route>
          <Route exact path='/carer-agreement' element={<PrivateRoute><CarerOptAgreement /></PrivateRoute>}></Route>
        </Routes>
      {/* </Router> */}
    </div>
  );
}

export default App;
