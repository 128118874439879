import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    resetEmailAddress: {},
    isLoadingEmailReset: false,
    errorEmailReset: ''
}

const resetEmailSlice = createSlice({
    name: 'resetEmailAddress',
    initialState,
    reducers:{
        resetEmailPending: (state) => {
            state.isLoadingEmailReset = true;
        },
        resetEmailSuccess: (state, {payload}) => {
            state.isLoadingEmailReset = false;
            state.resetEmailAddress = payload;
            state.errorEmailReset = '';
        },
        resetEmailFail: (state, {payload}) => {
            state.isLoadingEmailReset = false;
            state.errorEmailReset = payload;
        }
    }
})

export const {resetEmailPending, resetEmailSuccess, resetEmailFail} = resetEmailSlice.actions

export default resetEmailSlice.reducer