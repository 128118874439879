import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  EditDbs: {},
  isLoadingEditDbs: false,
  errorEditDbs: "",
};

const EditDbsSlice = createSlice({
  name: "EditDbs",
  initialState,
  reducers: {
    EditDbsPending: (state) => {
      state.isLoadingEditDbs = true;
    },
    EditDbsSuccess: (state, { payload }) => {
      state.isLoadingEditDbs = false;
      state.EditDbs = payload;
      state.errorEditDbs = "";
    },
    EditDbsFail: (state, { payload }) => {
      state.isLoadingEditDbs = false;
      state.errorEditDbs = payload;
    },
  },
});

export const { EditDbsPending, EditDbsSuccess, EditDbsFail } =
  EditDbsSlice.actions;

export default EditDbsSlice.reducer;
