import React,{useState} from "react";
import { BsFillEyeFill } from "react-icons/bs";
import FormikController from "../../../components/Formik/FormikController";
import SubmitButton from "../../../components/Formik/SubmitButton";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Field } from "formik";
import { getStaff } from "../../staff/staffActions/staffAction";
import { useDispatch, useSelector } from "react-redux";
import editClientCarePlan from "../../clients/ClientActions/EditCarePlansAction";
import addedPlans from "../../clients/ClientActions/GetAddedCarePlansAction";
import getMarCharts from "../../clients/ClientActions/GetMarChartsAction";
import editPrescriptionDoc from "../../clients/ClientActions/EditPrescriptionAction";

export default function EditCarePlan({ e, client }) {

  const dispatch = useDispatch();
  const specialityArray = new Array("CP06");
  const [showModal, setShowModal] = React.useState(false);
  const [indexes, setIndexes] = React.useState([]);
  const [counter, setCounter] = React.useState(0);
  const { user } = useSelector((state) => state.user);

  const [text, setText] = useState('');

  const handleInputChange = (event) => {
    setText(event.target.value);
    calculateTextareaHeight(event); // Pass the event to calculateTextareaHeight
  };

  const calculateTextareaHeight = (event) => {
    const minHeight = 40;
    const maxHeight = 200;
    const currentHeight = Math.max(minHeight, Math.min(maxHeight, event.target.scrollHeight));
    event.target.style.height = `${currentHeight}px`;
  };

  const [background, setBackground] = useState('');

  const handleBackgroundChange = (event) => {
    setBackground(event.target.value);
    calculateBackgroundHeight(event); // Pass the event to calculateTextareaHeight
  };

  const calculateBackgroundHeight = (event) => {
    const minHeight = 40;
    const maxHeight = 200;
    const currentHeight = Math.max(minHeight, Math.min(maxHeight, event.target.scrollHeight));
    event.target.style.height = `${currentHeight}px`;
  };

  const [risk, setRisk] = useState('');

  const handleRiskChange = (event) => {
    setRisk(event.target.value);
    calculateRiskHeight(event); // Pass the event to calculateTextareaHeight
  };

  const calculateRiskHeight = (event) => {
    const minHeight = 40;
    const maxHeight = 200;
    const currentHeight = Math.max(minHeight, Math.min(maxHeight, event.target.scrollHeight));
    event.target.style.height = `${currentHeight}px`;
  };

  const [aim, setAim] = useState('');

  const handleAimChange = (event) => {
    setAim(event.target.value);
    calculateAimHeight(event); // Pass the event to calculateTextareaHeight
  };

  const calculateAimHeight = (event) => {
    const minHeight = 40;
    const maxHeight = 200;
    const currentHeight = Math.max(minHeight, Math.min(maxHeight, event.target.scrollHeight));
    event.target.style.height = `${currentHeight}px`;
  };

  const [objective, setObjective] = useState('');

  const handleObjectiveChange = (event) => {
    setObjective(event.target.value);
    calculateObjectiveHeight(event); // Pass the event to calculateTextareaHeight
  };

  const calculateObjectiveHeight = (event) => {
    const minHeight = 40;
    const maxHeight = 200;
    const currentHeight = Math.max(minHeight, Math.min(maxHeight, event.target.scrollHeight));
    event.target.style.height = `${currentHeight}px`;
  };

  const [outcome, setOutcome] = useState('');

  const handleOutcomeChange = (event) => {
    setOutcome(event.target.value);
    calculateOutcomeHeight(event); // Pass the event to calculateTextareaHeight
  };

  const calculateOutcomeHeight = (event) => {
    const minHeight = 40;
    const maxHeight = 200;
    const currentHeight = Math.max(minHeight, Math.min(maxHeight, event.target.scrollHeight));
    event.target.style.height = `${currentHeight}px`;
  };
  const { addClientPersonalDetails } = useSelector(
    (state) => state.addClientPersonal
  );
  const { addClientCarePlans, isLoadingAddCarePlan, errorAddCarePlan } =
    useSelector((state) => state.addClientCarePlan);
  const { addedCarePlans } = useSelector((state) => state.addedCarePlan);
  const { editPrescription, isLoadingEditPres } = useSelector(
    (state) => state.editPrescriptionDoc
  );
  const { editCarePlan, isLoadingCarePlan, errorEditPlan } = useSelector(
    (state) => state.updateCarePlans
  );

  const { staff, isLoading } = useSelector((state) => state.getstaff);

  const prescriptionDoc = {
    prescriptions: "",
  };
  let admins =
    staff && staff.data
      ? staff.data.map((cxt) => {
          let obj = {
            key: cxt.firstname + " " + cxt.lastname,
            value: cxt.id,
          };
          return obj;
        })
      : [];

  const handleDocuments = (data) => {
    data.id = e.id;
    dispatch(editPrescriptionDoc(data));
    setShowModal(false);
  };

  const itemsCountPerPage = 10;

  const [page, setPage] = React.useState(1);

  React.useEffect(() => {
    // dispatch(
    //   getMarCharts({
    //     id: e?.careplan.id,
    //     clientId: addClientPersonalDetails ? addClientPersonalDetails.id : "",
    //   })
    // );
    dispatch(
      getStaff({
        page,
        perPage: itemsCountPerPage,
      })
    );
    setText(e ? e?.routine : "");
    setBackground(e ? e?.background : "");
    setRisk(e ? e?.risks : "");
    setAim(e ? e?.aim : "");
    setObjective(e ? e?.objective : "");
    setOutcome(e ? e?.outcome : "");
  }, [
    text,
    page,
    addClientPersonalDetails,
    addClientCarePlans,
    addedCarePlans,
    editCarePlan,
    editPrescription,
  ]);
  // console.log('e',e);
  const addMarChart = () => {
    setIndexes((prevIndexes) => [...prevIndexes, counter]);
    setCounter((prevCounter) => prevCounter + 1);
  };

  const removeMarChart = (index) => () => {
    setIndexes((prevIndexes) => [
      ...prevIndexes.filter((item) => item !== index),
    ]);
    setCounter((prevCounter) => prevCounter - 1);
  };

  const clearMarCharts = () => {
    setIndexes([]);
  };

  const handleOpen = (id) => {
    setShowModal(true);
    // dispatch(
    //   addedPlans({
    //     id: e?.id,
    //     clientId: addClientPersonalDetails.id,
    //   })
    // );
  };

  let left = e && e?.repositionLeftSideSkinInspection == 'true' ? true : false;
  let right = e && e?.repositionRightSideSkinInspection == 'true' ? true : false;
  let back = e && e?.repositionBackSkinInspection == 'true' ? true : false;
  // let enable = e && e?.enableAdministeredQuantity == 'true' ? true : false;

  const initialValues = {
    careplanId: e && e?.careplan ? e.careplan.id : "",
    background: background ? background : "",
    risks: risk ? risk : "",
    riskAssessment: e && e.riskAssessment ? e.riskAssessment : "",
    aim: aim ? aim : "",
    objective: objective ? objective : "",
    outcome: outcome ? outcome : "",
    behaviourTime: e ? e.behaviourTime : "",
    nonCompliant: e ? e.nonCompliant : "",
    verballyAggressive: e ? e.verballyAggressive : "",
    reputationOfBehaviour: e ? e.reputationOfBehaviour : "",
    otherBehaviour: e ? e.otherBehaviour : "",
    behaviourComment: e ? e.behaviourComment : "",
    morningBowelTime: e ? e.morningBowelTime : "",
    morningBowelType: e ? e.morningBowelType : "",
    morningBowelAmount: e ? e.morningBowelAmount : "",
    afternoonBowelTime: e ? e.afternoonBowelTime : "",
    afternoonBowelType: e ? e.afternoonBowelType : "",
    afternoonBowelAmount: e ? e.afternoonBowelAmount : "",
    nightBowelTime: e ? e.nightBowelTime : "",
    nightBowelType: e ? e.nightBowelType : "",
    nightBowelAmount: e ? e.nightBowelAmount : "",
    repositionLeftSideTime: e ? e.repositionLeftSideTime : "",
    repositionLeftSideSkinInspection: left,
    repositionBackTime: e ? e.repositionBackTime : "",
    repositionBackSkinInspection: back,
    repositionRightSideTime: e ? e.repositionRightSideTime : "",
    repositionRightSideSkinInspection: right,
    fluidIntakeTime: e ? e.fluidIntakeTime : "",
    fluidIntake: e ? e.fluidIntake : "",
    fluidIntakeComments: e ? e.fluidIntakeComments : "",
    foodIntakeTime: e ? e.foodIntakeTime : "",
    food: e ? e.food : "",
    foodIntakeAmount: e ? e.foodIntakeAmount : "",
    foodIntakeComments: e ? e.foodIntakeComments : "",
    prescriptions: e ? e.prescriptions : "",
    medication: e ? e.medication : "",
    medicationType: e ? e.medicationType : "",
    administration: e ? e.administration : "",
    dosage: e ? e.dosage : "",
    routine: text ? text : "",
    morning: e ? e.morning : false,
    lunch: e ? e.lunch : false,
    evening: e ? e.evening : false,
    bed: e ? e.bed : false,
    alternateHours: e ? e.alternateHours : false,
    alternateDays: e ? e.alternateDays : false,
    weekly: e ? e.weekly : false,
    prn: e ? e.prn : false,
    enableAdministeredQuantity: e ? e.enableAdministeredQuantity : false,
    // notes: e ? e.notes : "",
    received: e ? e.received.toString() : "0",
    dailyBalance: e ? e.dailyBalance : "",
    weeklyBalance: e ? e.weeklyBalance : "",
    adminAdjustments: e ? e.adminAdjustments : "",
    // adminId: user ? user.id : '',
  };


  let cname = addClientPersonalDetails
  ? addClientPersonalDetails.firstname + " "+
    addClientPersonalDetails.lastname
  : "";

  let clientname = client
  ? client.firstname +" "+
    client.lastname
  : ""

  let cdob = addClientPersonalDetails
  ? addClientPersonalDetails.dob 
  : "";

  let clientdob = client
  ? client.dob 
  : ""

  const validationSchema = Yup.object({});

  const handleDate = (date) => {
    let format = new Date(date);
    return ("0"+format.getDate()).slice(-2)+'/'+("0"+(format.getMonth()+1)).slice(-2)+'/'+format.getFullYear()+' '+format.getHours()+':'+format.getMinutes();
}

  const handleSubmit = (values) => {
    values.id = e.id;
    values.repositionLeftSideSkinInspection = values.repositionLeftSideSkinInspection.toString();
    values.repositionRightSideSkinInspection = values.repositionRightSideSkinInspection.toString();
    values.repositionBackSkinInspection = values.repositionBackSkinInspection.toString();
    // console.log('values@',values);
    dispatch(editClientCarePlan(values));
    setShowModal(false);
  };

  const Head = (props) => {
    return (
      <div className="text-left border-b">
        <label className="text-xl font-medium text-gray-700">
          {props.title}
        </label>
      </div>
    );
  };
  return (
    <>
      {
        <button
          className=" text-gray-600 bg-white shadow mt-2 hover:bg-white inline-flex hover:text-gray-700 hover:border hover:border-gray-300 hover:underline decoration-secondary focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-3 mr-1 py-1 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          type="button"
          onClick={() => handleOpen(e.careplan.id)}
        >
          View <BsFillEyeFill className="ml-1 mt-0.5 text-secondary" />
        </button>
      }
      {showModal ? (
        <>
          <div className="justify-center items-center overflow-x-auto overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-4xl">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-xl font-semibold text-gray-500">
                    {e.careplan.code} {e.careplan.title}
                    
                  </h3>
                  
                  <button
                    className="p-1 ml-10 bg-transparent border-0 text-button float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    ×
                  </button>
                </div>

                <div className="relative p-6 flex-auto">
                  <div className="relative p-3 flex-auto text-gray-700">
                    {e.careplan.code === "CP06" && (
                      <iframe
                        src={e && e.prescriptions ? e.prescriptions : ""}
                        frameborder="0"
                        height="300"
                        width="100%"
                      ></iframe>
                    )}
                    {e.careplan.code === "CP06" && (
                      <Formik
                        initialValues={prescriptionDoc}
                        validationSchema={validationSchema}
                        onSubmit={(values, { resetForm }) => {
                          handleDocuments(values);
                          resetForm();
                        }}
                      >
                        {({ setFieldValue, ...rest }) => (
                          <Form className="text-left">
                            <div className="border p-5 rounded">
                              <Head title="Prescription" />
                              <FormikController
                                control="input"
                                type="file"
                                name="prescriptions"
                                label="Prescription"
                                value={null}
                                onChange={(event) =>
                                  setFieldValue(
                                    "prescriptions",
                                    event.target.files[0]
                                  )
                                }
                              />
                            </div>
                            <div className=" flex flex-row-reverse">
                              {<SubmitButton label="Update" />}
                            </div>
                          </Form>
                        )}
                      </Formik>
                    )}
                    <Formik
                      initialValues={initialValues}
                      enableReinitialize
                      validationSchema={validationSchema}
                      onSubmit={(values, { resetForm }) => {
                        handleSubmit(values);
                        resetForm();
                      }}
                    >
                      {({ setFieldValue, values }) => (
                        <Form className="">
                             <div className="grid grid-cols-3 gap-2 mb-3 border-b">
                <div>
                  <FormikController
                    control="input"
                    type="text"
                    name="name"
                    label="Client Name"
                    value={ client
                        ? clientname
                        : cname
                    }
                    disabled
                  />
                </div>
                <div>
                  <FormikController
                    control="input"
                    type="text"
                    name="dob"
                    label="Date of Birth"
                    value={
                      client
                        ? clientdob
                        : cdob
                    }
                    disabled
                  />
                </div>
                <div>
                  <FormikController
                    control="input"
                    type="text"
                    name="dob"
                    label="Date of next review"
                    value={addClientPersonalDetails ? "Pending ..." : ""}
                    disabled
                  />
                </div>
              </div>
                          {e.careplan.code === "CP06" && (
                            <React.Fragment>
                              <div className="mb-5 font-bold">
                                <Head title="MAR Chart" />
                              </div>

                              <div className="mt-2">
                                <Head title="Medication Details" />
                              </div>
                              <div className="grid grid-cols-2 gap-2">
                                <div>
                                  <FormikController
                                    control="input"
                                    type="text"
                                    name="medication"
                                    label="Medication Name"
                                  />
                                </div>
                                <div>
                                  <FormikController
                                    control="input"
                                    type="text"
                                    name="medicationType"
                                    label="Type of Medication"
                                  />
                                </div>
                                <div>
                                  <FormikController
                                    control="textArea"
                                    type="text"
                                    name="administration"
                                    label="How is it Administered ?"
                                  />
                                </div>
                                <div>
                                  <FormikController
                                    control="textArea"
                                    type="text"
                                    name="dosage"
                                    label="Dosage"
                                  />
                                </div>
                              </div>

                              <div className="mt-2">
                                <Head title="Time Schedule" />
                              </div>
                              <div className="grid grid-cols-1 gap-2 mt-2">
                                <div className="text-left">
                                  <span className="font-medium">Time</span>
                                  <br />
                                  <div className="border rounded p-3">
                                    <label>
                                      <Field
                                        type="checkbox"
                                        name="morning"
                                        value={true}
                                        checked={values.morning === true}
                                      />
                                      <span className="ml-2">Morning</span>
                                    </label>
                                    <br />
                                    <label>
                                      <Field
                                        type="checkbox"
                                        name="lunch"
                                        value={true}
                                        checked={values.lunch === true}
                                      />
                                      <span className="ml-2">Lunch Time</span>
                                    </label>
                                    <br />
                                    <label>
                                      <Field
                                        type="checkbox"
                                        name="evening"
                                        value={true}
                                        checked={values.evening === true}
                                      />
                                      <span className="ml-2">Evening</span>
                                    </label>
                                    <br />
                                    <label>
                                      <Field
                                        type="checkbox"
                                        name="bed"
                                        value={true}
                                        checked={values.bed === true}
                                      />
                                      <span className="ml-2">Bed Time</span>
                                    </label>
                                    <br />
                                    <label>
                                      <Field
                                        type="checkbox"
                                        name="alternateHours"
                                        value={true}
                                        checked={values.alternateHours === true}
                                      />
                                      <span className="ml-2">
                                        Alternate Hours
                                      </span>
                                    </label>
                                    <br />
                                    <label>
                                      <Field
                                        type="checkbox"
                                        name="alternateDays"
                                        value={true}
                                        checked={values.alternateDays === true}
                                      />
                                      <span className="ml-2">
                                        Alternate Days{" "}
                                      </span>
                                    </label>
                                    <br />
                                    <label>
                                      <Field
                                        type="checkbox"
                                        name="weekly"
                                        value={true}
                                        checked={values.weekly === true} 
                                      />                                     
                                      <span className="ml-2">Weekly</span>
                                    </label>
                                    <br />
                                    <label>
                                      <Field
                                        type="checkbox"
                                        name="prn"
                                        value={true}
                                        checked={values.prn === true}
                                      />
                                      <span className="ml-2">
                                        PRN (As needed)
                                      </span>
                                    </label>
                                    <br />
                                    <label>
                                      <Field
                                        type="checkbox"
                                        name="enableAdministeredQuantity"
                                        value={true}
                                        checked={
                                          values.enableAdministeredQuantity ===
                                          true
                                        }
                                      />
                                      <span className="ml-2">
                                        Enable Care Worker to enter medication
                                        quantity they administered
                                      </span>
                                    </label>
                                    <br />
                                  </div>
                                </div>
                              </div>
                              <div className="mt-3">
                                <Head title="Administration Notes" />
                              </div>
                              <div className="grid grid-cols-1 gap-2 mt-2">
                                <div>
                                  <FormikController
                                    control="textArea"
                                    type="text"
                                    name="notes"
                                    label="Notes"
                                  />
                                </div>
                              </div>

                              <div className="mt-3">
                                <Head title="Medication Audit" />
                              </div>
                              <div className="grid grid-cols-4 gap-2 mt-2">
                                <div>
                                  <FormikController
                                    control="input"
                                    type="text"
                                    name="received"
                                    label="Received"
                                  />
                                </div>
                                <div>
                                  <FormikController
                                    control="input"
                                    type="text"
                                    name="dailyBalance"
                                    label="Daily Balance"
                                  />
                                </div>
                                <div>
                                  <FormikController
                                    control="input"
                                    type="text"
                                    name="weeklyBalance"
                                    label="Weekly Balance"
                                  />
                                </div>
                                <div>
                                  <FormikController
                                    control="input"
                                    type="text"
                                    name="adminAdjustments"
                                    label="Admin Adjustments"
                                  />
                                </div>
                              </div>
                              <div className="mt-4">
                                <Head title="MAR Chart Verification" />
                              </div>
                              <div className="grid grid-cols-1 gap-2">
                                <div>
                                  <FormikController
                                    control="select"
                                    type="select"
                                    name="verify"
                                    label="Select Administrator to Verify MAR Chart*"
                                    options={admins}
                                  />
                                </div>
                              </div>
                            </React.Fragment>
                          )}

                          {e.careplan.code === "CP00" && (
                            <div className="grid grid-cols-1 gap-2 mt-2">
                              <div>
                                <FormikController
                                  control="textArea"
                                  type="text"
                                  name="routine"
                                  label="Routine"
                                  value={text}
                                  onChange={handleInputChange} 
                                  style={{ minHeight: '50px', maxHeight: '200px', resize: 'none' }}
                                />
                              </div>
                            </div>
                          )}
                          {!specialityArray.includes(e.careplan.code) &&
                            e.careplan.code != "CP00" && (
                              <div>
                                <div className="grid grid-cols-1 gap-2 mt-2">
                                  <div>
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="background"
                                      label="Background"
                                      value={background}
                                      onChange={handleBackgroundChange}
                                      // style={{height:150}}
                                    />
                                  </div>

                                  <div>
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="risks"
                                      label="Risks"
                                      value={risk}
                                    onChange={handleRiskChange}
                                    />
                                  </div>
                                </div>
                                <div className="grid grid-cols-2 gap-2 mt-2">
                                  <div>
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="aim"
                                      label="Aim"
                                      value={aim}
                                    onChange={handleAimChange}
                                    />
                                  </div>
                                  <div>
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="objective"
                                      label="Objective"
                                      value={objective}
                                      onChange={handleObjectiveChange}
                                    />
                                  </div>
                                </div>

                                <div className="grid grid-cols-1 gap-2 mt-2">
                                  <div>
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="outcome"
                                      label="Outcome"
                                      value={outcome}
                                    onChange={handleOutcomeChange}
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                          {e.careplan.code === "CP03" && (
                            <div>
                              <div className="mt-5 mb-5">
                                <Head title="Fluid Intake Log" />
                              </div>
                              <div className="grid grid-cols-2 gap-2 mt-2">
                                <div>
                                  <FormikController
                                    control="input"
                                    type="time"
                                    name="fluidIntakeTime"
                                    label="Time"
                                  />
                                </div>
                                <div>
                                  <FormikController
                                    control="input"
                                    type="text"
                                    name="fluidIntake"
                                    label="Intake"
                                  />
                                </div>
                              </div>
                              <div className="grid grid-cols-1 gap-2 mt-2">
                                <div>
                                  <FormikController
                                    control="textArea"
                                    type="text"
                                    name="fluidIntakeComments"
                                    label="Comments"
                                  />
                                </div>
                              </div>

                              <div className="mt-5 mb-5">
                                <Head title="Food Intake Log" />
                              </div>
                              <div className="grid grid-cols-3 gap-2 mt-2">
                                <div>
                                  <FormikController
                                    control="input"
                                    type="time"
                                    name="foodIntakeTime"
                                    label="Time"
                                  />
                                </div>
                                <div>
                                  <FormikController
                                    control="input"
                                    type="text"
                                    name="food"
                                    label="Food"
                                  />
                                </div>
                                <div>
                                  <FormikController
                                    control="input"
                                    type="text"
                                    name="foodIntakeAmount"
                                    label="Amount"
                                  />
                                </div>
                              </div>
                              <div className="grid grid-cols-1 gap-2 mt-2">
                                <div>
                                  <FormikController
                                    control="textArea"
                                    type="text"
                                    name="foodIntakeComments"
                                    label="Comments"
                                  />
                                </div>
                              </div>
                            </div>
                          )}

                          {e.careplan.code === "CP05" && (
                            <div>
                              <div className="mt-5 mb-5">
                                <Head title="Behaviour Chart" />
                              </div>
                              <div className="grid grid-cols-1 gap-2 mt-2">
                                <div>
                                  <FormikController
                                    control="input"
                                    type="time"
                                    name="behaviourTime"
                                    label="Time"
                                  />
                                </div>
                              </div>
                              <div className="grid grid-cols-1 gap-2 mt-2">
                                <div className="text-left">
                                  <span className="font-medium">
                                    Does the person have these behaviours
                                  </span>
                                  <br />
                                  <div className="border rounded p-3">
                                    <label>
                                      <Field
                                        type="checkbox"
                                        name="nonCompliant"
                                        value={true}
                                        checked={values.nonCompliant === true}
                                      />
                                      <span className="ml-2">
                                        Non-Compliant
                                      </span>
                                    </label>
                                    <br />
                                    <label>
                                      <Field
                                        type="checkbox"
                                        name="verballyAggressive"
                                        value={true}
                                        checked={
                                          values.verballyAggressive === true
                                        }
                                      />
                                      <span className="ml-2">
                                        Verbally Aggressive
                                      </span>
                                    </label>
                                    <br />
                                    <label>
                                      <Field
                                        type="checkbox"
                                        name="reputationOfBehaviour"
                                        value={true}
                                        checked={
                                          values.reputationOfBehaviour === true
                                        }
                                      />
                                      <span className="ml-2">
                                        Reputation of Activity
                                      </span>
                                    </label>
                                    <br />
                                    <label>
                                      <Field
                                        type="checkbox"
                                        name="otherBehaviour"
                                        value={true}
                                        checked={values.otherBehaviour === true}
                                      />
                                      <span className="ml-2">Other</span>
                                    </label>

                                    {values.otherBehaviour === true && (
                                      <div className="mt-2">
                                        <FormikController
                                          control="textArea"
                                          type="text"
                                          name="behaviourComment"
                                          label="Please specificy your other behaviours"
                                        />
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}

                          {e.careplan.code === "CP14" && (
                            <div>
                              <div className="mt-5 mb-5">
                                <Head title="Bowel Movement Monthly Monitoring Record" />
                              </div>
                              <div className="text-left">
                                <Head title="Morning" />
                              </div>
                              <div className="grid grid-cols-3 gap-2 mt-2">
                                <div>
                                  <FormikController
                                    control="input"
                                    type="time"
                                    name="morningBowelTime"
                                    label="Time"
                                  />
                                </div>
                                <div>
                                  <FormikController
                                    control="input"
                                    type="text"
                                    name="morningBowelType"
                                    label="Type"
                                  />
                                </div>
                                <div>
                                  <FormikController
                                    control="input"
                                    type="text"
                                    name="morningBowelAmount"
                                    label="Amount"
                                  />
                                </div>
                              </div>

                              <div className="text-left mt-5">
                                <Head title="Afternoon" />
                              </div>
                              <div className="grid grid-cols-3 gap-2 mt-2">
                                <div>
                                  <FormikController
                                    control="input"
                                    type="time"
                                    name="afternoonBowelTime"
                                    label="Time"
                                  />
                                </div>
                                <div>
                                  <FormikController
                                    control="input"
                                    type="text"
                                    name="afternoonBowelType"
                                    label="Type"
                                  />
                                </div>
                                <div>
                                  <FormikController
                                    control="input"
                                    type="text"
                                    name="afternoonBowelAmount"
                                    label="Amount"
                                  />
                                </div>
                              </div>

                              <div className="text-left mt-5">
                                <Head title="Night" />
                              </div>
                              <div className="grid grid-cols-3 gap-2 mt-2">
                                <div>
                                  <FormikController
                                    control="input"
                                    type="time"
                                    name="nightBowelTime"
                                    label="Time"
                                  />
                                </div>
                                <div>
                                  <FormikController
                                    control="input"
                                    type="text"
                                    name="nightBowelType"
                                    label="Type"
                                  />
                                </div>
                                <div>
                                  <FormikController
                                    control="input"
                                    type="text"
                                    name="nightBowelAmount"
                                    label="Amount"
                                  />
                                </div>
                              </div>
                            </div>
                          )}

                          {e.careplan.code === "CP09" && (
                            <div>
                              <div className="text-left mt-5 mb-5">
                                <Head title="Repositioning & Skin Inspection Log" />
                              </div>
                              <div className="grid grid-cols-1 gap-2 mt-2">
                                <div className="text-left">
                                  <span className="font-medium">
                                    Does the person have these needs
                                  </span>
                                  <br />
                                  <div className="border rounded p-3">
                                    <label>
                                      <Field
                                        type="checkbox"
                                        name="repositionLeftSideSkinInspection"
                                        value={true}
                                        checked={values.repositionLeftSideSkinInspection === true}
                                      />
                                      <span className="ml-2">Left Side</span>
                                    </label>
                                    <br />
                                    <label>
                                      <Field
                                        type="checkbox"
                                        name="repositionBackSkinInspection"
                                        value={true}
                                        checked={values.repositionBackSkinInspection === true}
                                      />
                                      <span className="ml-2">Back Side</span>
                                    </label>
                                    <br />
                                    <label>
                                      <Field
                                        type="checkbox"
                                        name="repositionRightSideSkinInspection"
                                        value={true}
                                        checked={values.repositionRightSideSkinInspection === true}
                                      />
                                      <span className="ml-2">Right Side</span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                              {(values.repositionLeftSideSkinInspection || values.repositionRightSideSkinInspection || values.repositionBackSkinInspection) && (
                                <div className="mt-3">
                                  <FormikController
                                    control="textArea"
                                    type="text"
                                    name="repositionRightSideTime"
                                    label="Skin Inspection Comments"
                                  />
                                </div>
                              )}
                              {/* {values.left === true && (
                                <div className="grid grid-cols-1 gap-2 mt-2">
                                  <div className="text-left mt-3 mb-3">
                                    <Head title="Left Side Details" />
                                  </div>
                                  <div>
                                    <FormikController
                                      control="input"
                                      type="time"
                                      name="repositionLeftSideTime"
                                      label="Time"
                                    />
                                  </div>
                                  <div>
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="repositionLeftSideSkinInspection"
                                      label="Skin Inspection Comments"
                                    />
                                  </div>
                                </div>
                              )} */}
                              {/* {values.back === true && (
                                <div className="grid grid-cols-1 gap-2 mt-2">
                                  <div className="text-left mt-3 mb-3">
                                    <Head title="Back Side Details" />
                                  </div>
                                  <div>
                                    <FormikController
                                      control="input"
                                      type="time"
                                      name="repositionBackTime"
                                      label="Time"
                                    />
                                  </div>
                                  <div>
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="repositionBackSkinInspection"
                                      label="Skin Inspection Comments"
                                    />
                                  </div>
                                </div>
                              )} */}
                              {/* {values.right === true && (
                                <div className="grid grid-cols-1 gap-2 mt-2">
                                  <div className="text-left mt-3 mb-3">
                                    <Head title="Right Side Details" />
                                  </div>
                                  <div>
                                    <FormikController
                                      control="input"
                                      type="time"
                                      name="repositionRightSideTime"
                                      label="Time"
                                    />
                                  </div>
                                  <div>
                                    <FormikController
                                      control="textArea"
                                      type="text"
                                      name="repositionRightSideSkinInspection"
                                      label="Skin Inspection Comments"
                                    />
                                  </div>
                                </div>
                              )} */}
                            </div>
                          )}

                          <div className="mb-3 mt-5 flex flex-row-reverse">
                            <div></div>
                            <SubmitButton label="Update" />
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                  <h5 className="font-semibold">Created At - {e ? handleDate(e?.createdAt) : "..."}</h5>
                </div>
              </div>
            </div>
          </div>

          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
