import {updateBackgroundPending, updateBackgroundSuccess, updateBackgroundFail} from '../RegisterCarerSlices/UpdateBackgroundSlice'
import {UpdateBackground} from '../../api/carerApi'

 const UpdateBackgroundAction = (data) => async dispatch => {

    try {
        dispatch(updateBackgroundPending());
        const result = await UpdateBackground(data);
        if(result){
            return dispatch(updateBackgroundSuccess(result))
        }
    } catch (error) {
        dispatch(updateBackgroundFail(error))
    }
} 

export default UpdateBackgroundAction