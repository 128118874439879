import {addClientTierPending, addClientTierSuccess, addClientTierFail} from '../ConfigSlices/AddClientTierSlice'
import {addClientTiers} from '../../../api/clientApi'

 const addClientTier = (data) => async dispatch => {

    try {
        dispatch(addClientTierPending());
        const result = await addClientTiers(data);
        if(result){
            return dispatch(addClientTierSuccess(result))
        }
    } catch (error) {
        dispatch(addClientTierFail(error))
    }
} 

export default addClientTier