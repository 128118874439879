import React from 'react'
import FormikController from '../../../components/Formik/FormikController';
import SubmitButton from '../../../components/Formik/SubmitButton';
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Postcoder from "react-address-lookup";
import { useDispatch, useSelector } from 'react-redux';
import PageLoader from '../../../components/PageLoader';
import editContacts from '../ClientActions/EditContactsAction'
import ClientInfo from '../ClientActions/GetClientInfoAction';
import KeyContacts from '../AddClient/KeyContacts';

function Contacts({client}) {
  
  const dispatch = useDispatch();
  const {editClientContacts, isLoadingContacts, errorEditContacts} = useSelector(state => state.updateContacts);
  const {getClientInfo,isLoadinggetClientInfo} = useSelector(state => state.GetClientInfoSlice);
  const {clientContacts} = useSelector(state => state.addContact);
  

  React.useEffect(()=>{
    dispatch(ClientInfo({
     id:client.id
   }))
     },[editClientContacts,clientContacts])

  const initialValues = {
      GeneralPractionerPhoneNumber:getClientInfo && getClientInfo.contacts ? getClientInfo?.contacts.GeneralPractionerPhoneNumber :"",
      GeneralPractionerEmail:getClientInfo && getClientInfo.contacts ? getClientInfo?.contacts.GeneralPractionerEmail :"",
      GeneralPractionerAddressLine1:getClientInfo && getClientInfo.contacts ? getClientInfo?.contacts.GeneralPractionerAddressLine1 : "",
      GeneralPractionerAddressLine2:getClientInfo && getClientInfo.contacts ? getClientInfo?.contacts.GeneralPractionerAddressLine2 : "",
      GeneralPractionerTown:getClientInfo && getClientInfo.contacts ? getClientInfo?.contacts.GeneralPractionerTown : "",
      GeneralPractionerCounty:getClientInfo && getClientInfo.contacts ? getClientInfo?.contacts.GeneralPractionerCounty : "",
      GeneralPractionerPostcode:getClientInfo && getClientInfo.contacts ? getClientInfo?.contacts.GeneralPractionerPostcode : "",
      HealthProfessionalsPhoneNumber:getClientInfo && getClientInfo.contacts ? getClientInfo?.contacts.HealthProfessionalsPhoneNumber : "",
      HealthProfessionalsEmail:getClientInfo && getClientInfo.contacts ? getClientInfo?.contacts.HealthProfessionalsEmail : "",
      HealthProfessionalsAddressLine1:getClientInfo && getClientInfo.contacts ? getClientInfo?.contacts.HealthProfessionalsAddressLine1 : "",
      HealthProfessionalsAddressLine2:getClientInfo && getClientInfo.contacts ? getClientInfo?.contacts.HealthProfessionalsAddressLine2 : "",
      HealthProfessionalsTown:getClientInfo && getClientInfo.contacts ? getClientInfo?.contacts.HealthProfessionalsTown : "",
      HealthProfessionalsCounty:getClientInfo && getClientInfo.contacts ? getClientInfo?.contacts.HealthProfessionalsCounty : "",
      HealthProfessionalsPostcode:getClientInfo && getClientInfo.contacts ? getClientInfo?.contacts.HealthProfessionalsPostcode : "",
      PharmacyPhoneNumber:getClientInfo && getClientInfo.contacts ? getClientInfo?.contacts.PharmacyPhoneNumber : "",
      PharmacyEmail:getClientInfo && getClientInfo.contacts ? getClientInfo?.contacts.PharmacyEmail : "",
      PharmacyAddressLine1:getClientInfo && getClientInfo.contacts ? getClientInfo?.contacts.PharmacyAddressLine1 : "",
      PharmacyAddressLine2:getClientInfo && getClientInfo.contacts ? getClientInfo?.contacts.PharmacyAddressLine2 : "",
      PharmacyTown:getClientInfo && getClientInfo.contacts ? getClientInfo?.contacts.PharmacyTown : "",
      PharmacyCounty:getClientInfo && getClientInfo.contacts ? getClientInfo?.contacts.PharmacyCounty : "",
      PharmacyPostcode:getClientInfo && getClientInfo.contacts ? getClientInfo?.contacts.PharmacyPostcode : ""
  }

  const {addClientPersonalDetails} = useSelector(state =>state.addClientPersonal);

  React.useEffect(() => {

  }, [addClientPersonalDetails]);
 

  const validationSchema = Yup.object({

  })

  const handleSubmit = (data) => {
    data.id = client.id
    dispatch(editContacts(data))
  }

  const Head = (props) => {
      return (
          <div className='border-b mb-5'><label className='text-xl font-medium text-gray-700'>{props.title}</label></div>
      )
  }
  return ( isLoadinggetClientInfo ? <PageLoader/> : !getClientInfo.contacts ? <KeyContacts getClientInfo={getClientInfo}/> :
    <div className='w-full p-5'>
    <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
            handleSubmit(values);
        }}
    >
        {(formik) => {
            const logAddress1 = (addr) => {
                formik.setFieldValue("GeneralPractionerAddressLine1", addr.addressline1);
                formik.setFieldValue("GeneralPractionerAddressLine2", addr.addressline2);
                formik.setFieldValue("GeneralPractionerCounty", addr.county);
                formik.setFieldValue("GeneralPractionerTown", addr.posttown);
                formik.setFieldValue("GeneralPractionerPostcode", addr.postcode);

            };
            const logAddress2 = (addr) => {
                formik.setFieldValue("HealthProfessionalsAddressLine1", addr.addressline1);
                formik.setFieldValue("HealthProfessionalsAddressLine2", addr.addressline2);
                formik.setFieldValue("HealthProfessionalsCounty", addr.county);
                formik.setFieldValue("HealthProfessionalsTown", addr.posttown);
                formik.setFieldValue("HealthProfessionalsPostcode", addr.postcode);

            };
            const logAddress3 = (addr) => {
                formik.setFieldValue("PharmacyAddressLine1", addr.addressline1);
                formik.setFieldValue("PharmacyAddressLine2", addr.addressline2);
                formik.setFieldValue("PharmacyCounty", addr.county);
                formik.setFieldValue("PharmacyTown", addr.posttown);
                formik.setFieldValue("PharmacyPostcode", addr.postcode);

            };
            return (
                <Form className='text-left'>
                    <div className="border p-5 rounded">

                        <div className=''><Head title='GP Contact Details' /></div>
                        <div className='grid grid-cols-3 gap-2'>
                            <FormikController
                                control='input'
                                type='text'
                                name='GeneralPractionerPhoneNumber'
                                label='Phone number'
                            />
                            <FormikController
                                control='input'
                                type='text'
                                name='GeneralPractionerEmail'
                                label='Email address'
                            />
                        </div>
                        <div className="mt-3 ml-4">
                            <label className='font-medium'>Search post code</label>
                            <Postcoder
                                apiKey={process.env.REACT_APP_POSTCODER}
                                addressSelectedCallback={logAddress1}
                            />
                        </div>
                        <div className='grid grid-cols-3 gap-2 mt-5'>
                            <div>
                                <FormikController
                                    control='input'
                                    type='text'
                                    name="GeneralPractionerAddressLine1"
                                    placeholder=""
                                    label="Address line 1 *"
                                />
                            </div>
                            <div>
                                <FormikController
                                    control='input'
                                    type='text'
                                    name="GeneralPractionerAddressLine2"
                                    placeholder=""
                                    label="Address line 2"
                                />
                            </div>
                            <div>
                                <FormikController
                                    control='input'
                                    type='text'
                                    name="GeneralPractionerTown"
                                    placeholder=""
                                    label="Town *" />
                            </div>
                            <div>
                                <FormikController
                                    control='input'
                                    type='text'
                                    name="GeneralPractionerCounty"
                                    placeholder=""
                                    label="County *" />
                            </div>
                            <div>
                                <FormikController
                                    control='input'
                                    type='text'
                                    name="GeneralPractionerPostcode"
                                    placeholder=""
                                    label="Post code *"
                                />
                            </div>
                        </div>

                        <div className='mt-10'><Head title='Community Health Professionals Details' /></div>
                        <div className='grid grid-cols-3 gap-2'>
                            <FormikController
                                control='input'
                                type='text'
                                name='HealthProfessionalsPhoneNumber'
                                label='Phone number'
                            />
                            <FormikController
                                control='input'
                                type='text'
                                name='HealthProfessionalsEmail'
                                label='Email address'
                            />
                        </div>
                        <div className="mt-3 ml-4">
                            <label className='font-medium'>Search post code</label>
                            <Postcoder
                                apiKey={process.env.REACT_APP_POSTCODER}
                                addressSelectedCallback={logAddress2}
                            />
                        </div>
                        <div className='grid grid-cols-3 gap-2 mt-5'>
                            <div>
                                <FormikController
                                    control='input'
                                    type='text'
                                    name="HealthProfessionalsAddressLine1"
                                    placeholder=""
                                    label="Address line 1 *"
                                />
                            </div>
                            <div>
                                <FormikController
                                    control='input'
                                    type='text'
                                    name="HealthProfessionalsAddressLine2"
                                    placeholder=""
                                    label="Address line 2"
                                />
                            </div>
                            <div>
                                <FormikController
                                    control='input'
                                    type='text'
                                    name="HealthProfessionalsTown"
                                    placeholder=""
                                    label="Town *" />
                            </div>
                            <div>
                                <FormikController
                                    control='input'
                                    type='text'
                                    name="HealthProfessionalsCounty"
                                    placeholder=""
                                    label="County *" />
                            </div>
                            <div>
                                <FormikController
                                    control='input'
                                    type='text'
                                    name="HealthProfessionalsPostcode"
                                    placeholder=""
                                    label="Post code *"
                                />
                            </div>
                        </div>

                        <div className='mt-10'><Head title='Pharmacy Contact Details' /></div>
                        <div className='grid grid-cols-3 gap-2'>
                            <FormikController
                                control='input'
                                type='text'
                                name='PharmacyPhoneNumber'
                                label='Phone number'
                            />
                            <FormikController
                                control='input'
                                type='text'
                                name='PharmacyEmail'
                                label='Email address'
                            />
                        </div>
                        <div className="mt-3 ml-4">
                            <label className='font-medium'>Search post code</label>
                            <Postcoder
                                apiKey={process.env.REACT_APP_POSTCODER}
                                addressSelectedCallback={logAddress3}
                            />
                        </div>
                        <div className='grid grid-cols-3 gap-2 mt-5'>
                            <div>
                                <FormikController
                                    control='input'
                                    type='text'
                                    name="PharmacyAddressLine1"
                                    placeholder=""
                                    label="Address line 1 *"
                                />
                            </div>
                            <div>
                                <FormikController
                                    control='input'
                                    type='text'
                                    name="PharmacyAddressLine2"
                                    placeholder=""
                                    label="Address line 2"
                                />
                            </div>
                            <div>
                                <FormikController
                                    control='input'
                                    type='text'
                                    name="PharmacyTown"
                                    placeholder=""
                                    label="Town *" />
                            </div>
                            <div>
                                <FormikController
                                    control='input'
                                    type='text'
                                    name="PharmacyCounty"
                                    placeholder=""
                                    label="County *" />
                            </div>
                            <div>
                                <FormikController
                                    control='input'
                                    type='text'
                                    name="PharmacyPostcode"
                                    placeholder=""
                                    label="Post code *"
                                />
                            </div>
                        </div>

                    </div>
                    <div className="mb-3 mt-5 flex flex-row-reverse">
                        {isLoadingContacts ? <PageLoader /> : <SubmitButton label='Update' />}
                    </div>
                </Form>
            )
        }}
    </Formik>
</div>
  )
}

export default Contacts