import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    addClientPersonalDetails: {},
    isLoadingAddClient: false,
    errorAddClient: ''
}

const addClientSlice = createSlice({
    name: 'addClientPersonalDetails',
    initialState,
    reducers:{
        addClientPending: (state) => {
            state.isLoadingAddClient = true;
        },
        addClientSuccess: (state, {payload}) => {
            state.isLoadingAddClient = false;
            state.addClientPersonalDetails = payload;
            state.errorAddClient = '';
        },
        addClientFail: (state, {payload}) => {
            state.isLoadingAddClient = false;
            state.errorAddClient = payload;
        }
    }
})

export const {addClientPending, addClientSuccess, addClientFail} = addClientSlice.actions

export default addClientSlice.reducer