import axios from "axios";
import { Navigate } from "react-router-dom";

const BASE_URI = process.env.REACT_APP_ELMS_API_URL;

export const getStaffUsers = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = sessionStorage.getItem("access_token");
      const res = await axios.get(
        `${BASE_URI}admins?page=${data.page}&perPage=${data.perPage}&role=${data.role}`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      resolve(res.data);
    } catch (error) {
      if (error?.response?.data?.statusCode === 404) {
        return null;
      }
      if (error?.response?.data?.statusCode === 401) {
        <Navigate replace to="/login" />;
      }

      reject(error);
    }
  });
};

export const updateStatus = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = sessionStorage.getItem("access_token");
      const res = await axios.patch(
        `${process.env.REACT_APP_ELMS_API_URL}admins/${data.id}/status`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      resolve(res.data);
    } catch (error) {
      if (error?.response?.data?.statusCode === 401) {
        <Navigate replace to="/login" />;
      }

      reject(error);
    }
  });
};

export const addStaffMembers = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = sessionStorage.getItem("access_token");
      const res = await axios.post(
        `${process.env.REACT_APP_ELMS_API_URL}admins`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      resolve(res.data);
    } catch (error) {
      if (error?.response?.data?.statusCode === 401) {
        <Navigate replace to="/login" />;
      }

      reject(error);
    }
  });
};

export const updateStaff = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = sessionStorage.getItem("access_token");
      const res = await axios.patch(
        `${process.env.REACT_APP_ELMS_API_URL}admins/${data.id}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      resolve(res.data);
    } catch (error) {
      if (error?.response?.data?.statusCode === 401) {
        <Navigate replace to="/login" />;
      }

      reject(error);
    }
  });
};

export const getStaffBirthdays = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = sessionStorage.getItem("access_token");
      const res = await axios.get(`${BASE_URI}admins/staff/birthdays`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      resolve(res.data);
    } catch (error) {
      if (error?.response?.data?.statusCode === 404) {
        return null;
      }
      if (error?.response?.data?.statusCode === 401) {
        <Navigate replace to="/login" />;
      }

      reject(error);
    }
  });
};

export const assignCarer = async (data) => {
  const values = data.selected;
  let ids = [];
  values.map((key) => {
    ids.push({ carerId: key.value });
  });
  return new Promise(async (resolve, reject) => {
    try {
      const token = sessionStorage.getItem("access_token");
      const res = await axios.post(
        `${BASE_URI}regulator-carers?regulatorId=${data.regulatorId}`,
        ids,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      resolve(res.data);
    } catch (error) {
      if (error?.response?.data?.statusCode === 401) {
        <Navigate replace to="/login" />;
      }

      reject(error);
    }
  });
};

export const assignClient = async (data) => {
  const values = data.selected;
  let ids = [];
  values.map((key) => {
    ids.push({ clientId: key.value });
  });

  return new Promise(async (resolve, reject) => {
    try {
      const token = sessionStorage.getItem("access_token");
      const res = await axios.post(
        `${BASE_URI}regulator-clients?regulatorId=${data.regulatorId}`,
        ids,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      resolve(res.data);
    } catch (error) {
      if (error?.response?.data?.statusCode === 401) {
        <Navigate replace to="/login" />;
      }

      reject(error);
    }
  });
};

export const getRegulatorClients = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = sessionStorage.getItem("access_token");
      const res = await axios.get(
        `${BASE_URI}regulator-clients?page=${data.page}&perPage=${data.perPage}`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      resolve(res.data);
    } catch (error) {
      if (error?.response?.data?.statusCode === 404) {
        return null;
      }
      if (error?.response?.data?.statusCode === 401) {
        <Navigate replace to="/login" />;
      }

      reject(error);
    }
  });
};

export const getSelectedCarers = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = sessionStorage.getItem("access_token");
      const res = await axios.get(
        `${BASE_URI}regulator-carers/selected/carers?page=${data.page}&perPage=${data.perPage}`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      resolve(res.data);
    } catch (error) {
      if (error?.response?.data?.statusCode === 404) {
        return null;
      }
      if (error?.response?.data?.statusCode === 401) {
        <Navigate replace to="/login" />;
      }

      reject(error);
    }
  });
};

export const getSelectedClients = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = sessionStorage.getItem("access_token");
      const res = await axios.get(
        `${BASE_URI}regulator-clients/selected/clients?page=${data.page}&perPage=${data.perPage}`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      resolve(res.data);
    } catch (error) {
      if (error?.response?.data?.statusCode === 404) {
        return null;
      }
      if (error?.response?.data?.statusCode === 401) {
        <Navigate replace to="/login" />;
      }

      reject(error);
    }
  });
};

export const updateRegulatorClients = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = sessionStorage.getItem("access_token");
      const res = await axios.patch(
        `${BASE_URI}regulator-clients/${data.id}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      resolve(res.data);
    } catch (error) {
      if (error?.response?.data?.statusCode === 401) {
        <Navigate replace to="/login" />;
      }

      reject(error);
    }
  });
};

export const updateRegulatorclientStatus = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = sessionStorage.getItem("access_token");
      const res = await axios.patch(
        `${BASE_URI}regulator-clients/change/status/${data.id}?status=${data.status}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      resolve(res.data);
    } catch (error) {
      if (error?.response?.data?.statusCode === 401) {
        <Navigate replace to="/login" />;
      }

      reject(error);
    }
  });
};

export const updateRegulatorCarerStatus = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = sessionStorage.getItem("access_token");
      const res = await axios.patch(
        `${BASE_URI}regulator-carers/change/status/${data.id}?status=${data.status}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      resolve(res.data);
    } catch (error) {
      if (error?.response?.data?.statusCode === 401) {
        <Navigate replace to="/login" />;
      }

      reject(error);
    }
  });
};
export const getRegulatorCarers = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = sessionStorage.getItem("access_token");
      const res = await axios.get(
        `${BASE_URI}regulator-carers/?page=${data.page}&perPage=${data.perPage}`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      resolve(res.data);
    } catch (error) {
      if (error?.response?.data?.statusCode === 404) {
        return null;
      }
      if (error?.response?.data?.statusCode === 401) {
        <Navigate replace to="/login" />;
      }

      reject(error);
    }
  });
};

export const updateRegulatorCarers = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = sessionStorage.getItem("access_token");
      const res = await axios.patch(
        `${BASE_URI}regulator-carers/${data.id}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      resolve(res.data);
    } catch (error) {
      if (error?.response?.data?.statusCode === 401) {
        <Navigate replace to="/login" />;
      }

      reject(error);
    }
  });
};
