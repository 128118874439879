import axios from "axios";
import { Navigate } from 'react-router-dom';
import { getBase64String } from "../carers/AddCarer/base64Encoder";
import { useDispatch, useSelector } from "react-redux";

const BASE_URI = process.env.REACT_APP_ELMS_API_URL;

export const signUpCarer = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {

      const res = await axios.post(`${BASE_URI}users`, data, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }); 
     
      resolve(res.data);
      console.log('SUCCESS',res);
    } catch (error) {
      reject(error);
      console.log('ERROR',error);
    }
  });
};

export const Login = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await axios.post(`${BASE_URI}auth`, data, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      console.log('TOKENSUCCESS',res);
      resolve(res.data);

    } catch (error) {
      reject(error);
      console.log('TOKENERROR',error);
    }
  });
};
// export const addClientPersonalDetailss = async (data) => {
//   return new Promise(async (resolve, reject) => {
//       try {
//           const token = sessionStorage.getItem('access_token')
//           const res = await axios.post(`${process.env.REACT_APP_ELMS_API_URL}clients`, data, {
//               headers: {
//                   'Content-Type': 'application/json',
//                   'Accept': 'application/json',
//                   'Authorization': `Bearer ${token}`
//               }
//           })
//           resolve(res.data);

//       } catch (error) {
//           if(error.response.data.statusCode === 401){          
//               <Navigate replace to="/login"/>
//            }
//           reject(error)
//       }
//   })
// }

export const PersonalDetails = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = sessionStorage.getItem('access_token')
      const res = await axios.patch(`${BASE_URI}users/${data.id}`, data, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          'Authorization': `Bearer ${token}`
        },
      });
      resolve(res.data);
      console.log("PersnalSucess", res);
    } catch (error) {
      reject(error);
      console.log("PersnalError", error);
    }
  });
};

export const CarerTypeAvailability = async (data) => {

  return new Promise(async (resolve, reject) => {
    try {
      const token = sessionStorage.getItem('access_token')
      const res = await axios.post(
        `${BASE_URI}carer-type-availability/${data.id}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "Authorization": `Bearer ${token}`,
          },
        }
      );
      resolve(res.data);
      console.log("AvalilbilitySucess", res);
    } catch (error) {
      reject(error);
      console.log("AvalilbilityError", error);
    }
  });
};

export const NextofKin = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = sessionStorage.getItem('access_token')
      const res = await axios.post(
        `${BASE_URI}carer-next-of-kin/${data.id}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "Authorization": `Bearer ${token}`,
          },
        }
      );
      resolve(res.data);
      console.log("NextofkinSucess", res);
    } catch (error) {
      reject(error);
      console.log("NextofkinError", error);
    }
  });
};




export const EmploymentHistory = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = sessionStorage.getItem('access_token')
      const res = await axios.post(`${BASE_URI}employment-history/${data.id}`, data, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      resolve(res.data);
      console.log('ESUCCESS',res.data);
    } catch (error) {
      reject(error);
      console.log('EERROR',error);
    }
  });
};

export const Documents = async (data) => {
 
  return new Promise(async (resolve, reject) => {
    try {
      const token = sessionStorage.getItem('access_token')

      const res = await axios.post(`${BASE_URI}carer-documents/${data.id}`, data, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      resolve(res.data);
      console.log('DOCUSUCCESS',res.data);
    } catch (error) {
      reject(error);
      console.log('DOCUERROR',error);
    }
  });
};

export const DisabilityChecklist = async (data) => {
  console.log("data", data);
  return new Promise(async (resolve, reject) => {
    try {
      const token = sessionStorage.getItem('access_token')
      const res = await axios.post(
        `${BASE_URI}users/disability/checklist/${data.id}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "Authorization": `Bearer ${token}`,
          },
        }
      );
      resolve(res.data);
      console.log("DisabilitySucess", res);
    } catch (error) {
      reject(error);
      console.log("DisabilityError", error);
    }
  });
};




export const EducationTraining = async (data) => {
  return new Promise(async (resolve, reject) => {
    console.log("EDUAPI : ", data);
    try {
      const token = sessionStorage.getItem('access_token')
      const res = await axios.post(`${BASE_URI}eduction-training/${data.id}`, data, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Authorization": `Bearer ${token}`,
        },
      });
      resolve(res.data);
      console.log('EDUTRAINSUCCESS',res.data);
    } catch (error) {
      reject(error);
      console.log('EDUTRAINERROR',error);
    }
  });
};

// export const EducationTraining = async (data) => {
//   console.log('data',data);
//   return new Promise(async (resolve, reject) => {
//     try {

//       var formData = new FormData();
//       formData.append('diplomaCertificate', data.diplomaCertificate);
//       formData.append('CareCertificate', data.CareCertificate);
//       formData.append('MandatoryTrainingCertificate', data.MandatoryTrainingCertificate);
//       formData.append('MedicationTrainingCertificate', data.MedicationTrainingCertificate);
//       formData.append('MovingAndHandlingCertificate', data.MovingAndHandlingCertificate);
//       formData.append('PegFeedCertificate', data.PegFeedCertificate);
//       formData.append('GCSEOLevel', data.GCSEOLevel);
//       formData.append('GCSEOLevelNumberOfSubjects', data.GCSEOLevelNumberOfSubjects);
//       formData.append('GCSEOLevelMaths', data.GCSEOLevelMaths);
//       formData.append('GCSEOLevelEnglish', data.GCSEOLevelEnglish);
//       formData.append('ALevel', data.ALevel);
//       formData.append('diplomaName', data.diplomaName);
//       formData.append('MandatoryTrainingCertification', data.MandatoryTrainingCertification);
//       formData.append('MedicationTrainingCertification', data.MedicationTrainingCertification);
//       formData.append('MovingAndHandlingCertification', data.MovingAndHandlingCertification);
//       formData.append('PegFeedCertification', data.PegFeedCertification);
//       console.log('formData',formData);

//       const res = await axios.post(`${BASE_URI}eduction-training`, formData, {
//         headers: {
//           "Content-Type": "application/json",
//           Accept: "application/json",
//           "Authorization": `Bearer ${data.accessToken}`,
//         },
//       });
//       resolve(res.data);
//       console.log('EDUTRAINSUCCESS',res);
//     } catch (error) {
//       reject(error);
//       console.log('EDUTRAINERROR',error);
//     }
//   });
// };



export const CriminalRecord = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = sessionStorage.getItem('access_token')
      const res = await axios.patch(
        `${BASE_URI}users/criminal/record/declaration/${data.carerId}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      resolve(res.data);
      console.log('CRECORDSUCCESS',res);
    } catch (error) {
      reject(error);
      console.log('CRECORDERROR',error);
    }
  });
};

export const HealthDeclaration = async (data) => {
  console.log("data", data);
  return new Promise(async (resolve, reject) => {
    try {
      const token = sessionStorage.getItem('access_token')
      const res = await axios.post(
        `${BASE_URI}users/health-declaration/${data.id}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      resolve(res.data);
      console.log("HealthSucess", res);
    } catch (error) {
      reject(error);
      console.log("HealthError", error);
    }
  });
};

export const EqualMonitoring = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = sessionStorage.getItem('access_token')
      const res = await axios.post(
        `${BASE_URI}users/equal/opportunities/${data.id}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      resolve(res.data);
      console.log("EQUALSucess", res);
    } catch (error) {
      reject(error);
      console.log("EQUALError", error);
    }
  });
};

export const EmployeesProcessing = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = sessionStorage.getItem('access_token')
      const res = await axios.patch(`${BASE_URI}users/terms/agreement/${data.carerId}`, data, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      resolve(res.data);
      console.log("AgreementSucess", res);
    } catch (error) {
      reject(error);
      console.log("AgreementError", error);
    }
  });
};

export const TrainingHistory = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = sessionStorage.getItem('access_token')
      const res = await axios.post(`${BASE_URI}training-history/${data.id}`, data, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      resolve(res.data);
      console.log("TrainingSucess", res);
    } catch (error) {
      reject(error);
      console.log("TrainingError", error);
    }
  });
};

export const Background = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = sessionStorage.getItem('access_token')
      const res = await axios.post(`${BASE_URI}carer-background/${data.id}`, data, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      resolve(res.data);
      console.log("BackgroundSucess", res);
    } catch (error) {
      reject(error);
      console.log("BackgroundError", error);
    }
  });
};

export const SendInvitation = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = sessionStorage.getItem('access_token')
      const res = await axios.post(`${BASE_URI}invites`, data, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      resolve(res.data);
      console.log("InviteSucess", res);
    } catch (error) {
      reject(error);
      console.log("InviteError", error);
    }
  });
};

export const UpdateAudit = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = sessionStorage.getItem('access_token')
      const res = await axios.patch(`${BASE_URI}medication-audit/${data.id}`, data, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      resolve(res.data);
      console.log("UpdateAuditSucess", res);
    } catch (error) {
      reject(error);
      console.log("UpdateAuditError", error);
    }
  });
};
export const TermsAgreement = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = sessionStorage.getItem('access_token')
      const res = await axios.patch(
        `${BASE_URI}users/personal/data/consent/${data.id}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("ConsentSucess", res);
      resolve(res.data);
    } catch (error) {
      console.log("ConsentError", error);
      reject(error);
    }
  });
};
// export const DbsApplication = async (data) => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       const res = await axios.post(`${BASE_URI}carer-dbs-application`, data, {
//         headers: {
//           "Content-Type": "application/json",
//           Accept: "application/json",
//           Authorization: `Bearer ${data.accessToken}`,
//         },
//       });
//       resolve(res.data);
//     } catch (error) {
//       reject(error);
//     }
//   });
// };



export const GetReligion = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await axios.get(
        `${BASE_URI}religion?page=${data.page}&perPage=${data.perPage}`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${data.accessToken}`,
          },
        }
      );
      resolve(res.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const GetEthnicity = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await axios.get(
        `${BASE_URI}ethnicity?page=${data.page}&perPage=${data.perPage}`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${data.accessToken}`,
          },
        }
      );
      resolve(res.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const UpdatePersonal = async (data) => {
  // console.log('data',data);
  return new Promise(async (resolve, reject) => {
    try {
      const token = sessionStorage.getItem('access_token')
      const res = await axios.patch(`${BASE_URI}users/${data.id}`, data, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      resolve(res.data);
      console.log('EDITPERSONALSUCCESS',res.data);
    } catch (error) {
      reject(error);
      console.log('EDITPERSONALERROR',error)
    }
  });
};
export const UpdateNextofkinDetails = async (data) => {
  // console.log('data',data);
  return new Promise(async (resolve, reject) => {
    try {
      const token = sessionStorage.getItem('access_token')
      const res = await axios.patch(
        `${BASE_URI}carer-next-of-kin/${data.id}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      resolve(res.data);
      console.log('EDITnextofkinSUCCESS',res);
    } catch (error) {
      reject(error);
      console.log('EDITnextofkinERROR',error)
    }
  });
};

export const UpdateAvailability = async (data) => {

  return new Promise(async (resolve, reject) => {
    
    try {
      const token = sessionStorage.getItem('access_token')
      const res = await axios.patch(
        `${BASE_URI}carer-type-availability/${data.id}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      resolve(res.data);
      console.log("AvailabilitySucess", res);
    } catch (error) {
      reject(error);
      console.log("AvailabilityError", error);
    }
  });
};

export const UpdateHealthDeclaration = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = sessionStorage.getItem('access_token')
      const res = await axios.patch(
        `${BASE_URI}users/health-declaration/${data.id}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      resolve(res.data);
      console.log("HealthSucess", res);
    } catch (error) {
      reject(error);
      console.log("HealthError", error);
    }
  });
};

export const UpdateBackground = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = sessionStorage.getItem('access_token')
      const res = await axios.patch(
        `${BASE_URI}carer-background/${data.id}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      resolve(res.data);
      console.log("EDITBackgroundSucess", res);
    } catch (error) {
      reject(error);
      console.log("EDITBackgroundError", error);
    }
  });
};

export const GetCarerNextOfKin = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = sessionStorage.getItem('access_token')
      const res = await axios.get(
        `${BASE_URI}carer-next-of-kin/${data.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log('GetNextSuccess',res);
      resolve(res.data);
    } catch (error) {
      console.log('GetNextError',error);
      reject(error);
    }
  });
};

export const UpdateTraining = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = sessionStorage.getItem('access_token')
      const res = await axios.patch(
        `${BASE_URI}training-history/${data.id}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log('TRAINSuccess',res);
      resolve(res.data);
    } catch (error) {
      reject(error);
      console.log('TRAINError',error);
    }
  });
};

export const UpdateWorkHistory = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = sessionStorage.getItem('access_token')
      const res = await axios.patch(
        `${BASE_URI}employment-history/${data.id}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log('EHSuccess',res);
      resolve(res.data);
    } catch (error) {
      console.log('EHError',error);
      reject(error);
    }
  });
};

// export const UpdateDisability = async (data) => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       let acc = await AsyncStorage.getItem("AccessToken");
//       const res = await axios.patch(
//         `${BASE_URI}users/disability/checklist/${data.id}`,
//         data,
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Accept: "application/json",
//             Authorization: `Bearer ${acc}`,
//           },
//         }
//       );
//       resolve(res.data);
//     } catch (error) {
//       reject(error);
//     }
//   });
// };







// export const UserDetails = async (data) => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       let token = await AsyncStorage.getItem("AccessToken");
//       const res = await axios.get(`${BASE_URI}users/${data.id}`, {
//         headers: {
//           "Content-Type": "application/json",
//           Accept: "application/json",
//           Authorization: `Bearer ${token}`,
//         },
//       });
//       resolve(res.data);
//     } catch (error) {
//       reject(error);
//     }
//   });
// };



// //CHECKLIST

// export const UnderstandRole = async (data) => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       let token = await AsyncStorage.getItem("AccessToken");
//       const res = await axios.post(`${BASE_URI}carer-role`, data, {
//         headers: {
//           "Content-Type": "application/json",
//           Accept: "application/json",
//           Authorization: `Bearer ${token}`,
//         },
//       });
//       resolve(res.data);
//     } catch (error) {
//       reject(error);
//     }
//   });
// };

// export const PersonalDevelopment = async (data) => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       let token = await AsyncStorage.getItem("AccessToken");
//       const res = await axios.post(
//         `${BASE_URI}carer-personal-development`,
//         data,
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Accept: "application/json",
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );
//       resolve(res.data);
//     } catch (error) {
//       reject(error);
//     }
//   });
// };

// export const DutyofCare = async (data) => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       let token = await AsyncStorage.getItem("AccessToken");
//       const res = await axios.post(`${BASE_URI}duty-of-care`, data, {
//         headers: {
//           "Content-Type": "application/json",
//           Accept: "application/json",
//           Authorization: `Bearer ${token}`,
//         },
//       });
//       resolve(res.data);
//     } catch (error) {
//       reject(error);
//     }
//   });
// };

// export const EqualDiversity = async (data) => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       let token = await AsyncStorage.getItem("AccessToken");
//       const res = await axios.post(`${BASE_URI}equality-diversity`, data, {
//         headers: {
//           "Content-Type": "application/json",
//           Accept: "application/json",
//           Authorization: `Bearer ${token}`,
//         },
//       });
//       resolve(res.data);
//     } catch (error) {
//       reject(error);
//     }
//   });
// };

// export const WorkCentered = async (data) => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       let token = await AsyncStorage.getItem("AccessToken");
//       const res = await axios.post(`${BASE_URI}person-centered`, data, {
//         headers: {
//           "Content-Type": "application/json",
//           Accept: "application/json",
//           Authorization: `Bearer ${token}`,
//         },
//       });
//       resolve(res.data);
//     } catch (error) {
//       reject(error);
//     }
//   });
// };

// export const Communication = async (data) => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       let token = await AsyncStorage.getItem("AccessToken");
//       const res = await axios.post(`${BASE_URI}communication`, data, {
//         headers: {
//           "Content-Type": "application/json",
//           Accept: "application/json",
//           Authorization: `Bearer ${token}`,
//         },
//       });
//       resolve(res.data);
//     } catch (error) {
//       reject(error);
//     }
//   });
// };

// export const PrivacyDignity = async (data) => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       let token = await AsyncStorage.getItem("AccessToken");
//       const res = await axios.post(`${BASE_URI}privacy-and-dignity`, data, {
//         headers: {
//           "Content-Type": "application/json",
//           Accept: "application/json",
//           Authorization: `Bearer ${token}`,
//         },
//       });
//       resolve(res.data);
//     } catch (error) {
//       reject(error);
//     }
//   });
// };

// export const FluidsNutrition = async (data) => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       let token = await AsyncStorage.getItem("AccessToken");
//       const res = await axios.post(`${BASE_URI}fluids-and-nutrition`, data, {
//         headers: {
//           "Content-Type": "application/json",
//           Accept: "application/json",
//           Authorization: `Bearer ${token}`,
//         },
//       });
//       resolve(res.data);
//     } catch (error) {
//       reject(error);
//     }
//   });
// };

// export const Awareness = async (data) => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       let token = await AsyncStorage.getItem("AccessToken");
//       const res = await axios.post(
//         `${BASE_URI}mental-health-dementia-and-learning-disabilities`,
//         data,
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Accept: "application/json",
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );
//       resolve(res.data);
//     } catch (error) {
//       reject(error);
//     }
//   });
// };

// export const SafeguardingAdults = async (data) => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       let token = await AsyncStorage.getItem("AccessToken");
//       const res = await axios.post(`${BASE_URI}safeguarding-adults`, data, {
//         headers: {
//           "Content-Type": "application/json",
//           Accept: "application/json",
//           Authorization: `Bearer ${token}`,
//         },
//       });
//       resolve(res.data);
//     } catch (error) {
//       reject(error);
//     }
//   });
// };

// export const SafeguardingChildren = async (data) => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       let token = await AsyncStorage.getItem("AccessToken");
//       const res = await axios.post(`${BASE_URI}health-and-safety`, data, {
//         headers: {
//           "Content-Type": "application/json",
//           Accept: "application/json",
//           Authorization: `Bearer ${token}`,
//         },
//       });
//       resolve(res.data);
//     } catch (error) {
//       reject(error);
//     }
//   });
// };

// export const HandlingInformation = async (data) => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       let token = await AsyncStorage.getItem("AccessToken");
//       const res = await axios.post(`${BASE_URI}handling-information`, data, {
//         headers: {
//           "Content-Type": "application/json",
//           Accept: "application/json",
//           Authorization: `Bearer ${token}`,
//         },
//       });
//       resolve(res.data);
//     } catch (error) {
//       reject(error);
//     }
//   });
// };

// export const ContractSignature = async (data) => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       let token = await AsyncStorage.getItem("AccessToken");
//       const res = await axios.post(`${BASE_URI}carer-contracts`, data, {
//         headers: {
//           "Content-Type": "application/json",
//           Accept: "application/json",
//           Authorization: `Bearer ${token}`,
//         },
//       });
//       resolve(res.data);
//     } catch (error) {
//       reject(error);
//     }
//   });
// };

// //CARER DASHBOARD

// export const GetCarerShifts = async (data) => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       let token = await AsyncStorage.getItem("AccessToken");
//       const res = await axios.get(`${BASE_URI}shifts/carer/shifts`, {
//         headers: {
//           "Content-Type": "application/json",
//           Accept: "application/json",
//           Authorization: `Bearer ${token}`,
//         },
//       });
//       resolve(res.data);
//     } catch (error) {
//       reject(error);
//     }
//   });
// };

// //SHIFTS

// export const ClockIn = async (data) => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       let token = await AsyncStorage.getItem("AccessToken");
//       const res = await axios.post(`${BASE_URI}shifts`, data, {
//         headers: {
//           "Content-Type": "application/json",
//           Accept: "application/json",
//           Authorization: `Bearer ${token}`,
//         },
//       });
//       resolve(res.data);
//     } catch (error) {
//       reject(error);
//     }
//   });
// };

// export const ClockOut = async (data) => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       let token = await AsyncStorage.getItem("AccessToken");
//       const res = await axios.patch(
//         `${BASE_URI}shifts/${data.id}/clockout`,
//         data,
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Accept: "application/json",
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );
//       resolve(res.data);
//       console.log('ClockoutSuccess',res.data);
//     } catch (error) {
//       reject(error);
//       console.log('ClockoutError',error);
//     }
//   });
// }; 


// export const StartBreak = async (data) => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       let token = await AsyncStorage.getItem("AccessToken");
//       const res = await axios.patch(
//         `${BASE_URI}shifts/${data.id}/start/break`,
//         data,
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Accept: "application/json",
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );
//       resolve(res.data);
//     } catch (error) {
//       reject(error);
//     }
//   });
// };

// export const EndBreak = async (data) => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       let token = await AsyncStorage.getItem("AccessToken");
//       const res = await axios.patch(
//         `${BASE_URI}shifts/${data.id}/end/break`,
//         data,
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Accept: "application/json",
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );
//       resolve(res.data);
//     } catch (error) {
//       reject(error);
//     }
//   });
// };

// //MARCHARTS

// export const GetMarCharts = async (data) => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       let token = await AsyncStorage.getItem("AccessToken");
//       const res = await axios.get(
//         `${BASE_URI}client-care-plans/get/marcharts?code=${data.code}&clientId=${data.clientId}`,
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Accept: "application/json",
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );
//       resolve(res.data);
//     } catch (error) {
//       reject(error);
//     }
//   });
// };

// export const MedicationProtocol = async (data) => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       let token = await AsyncStorage.getItem("AccessToken");
//       const res = await axios.post(`${BASE_URI}medication-protocol`, data, {
//         headers: {
//           "Content-Type": "application/json",
//           Accept: "application/json",
//           Authorization: `Bearer ${token}`,
//         },
//       });
//       resolve(res.data);
//     } catch (error) {
//       reject(error);
//     }
//   });
// };

// export const MedicationAudit = async (data) => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       let token = await AsyncStorage.getItem("AccessToken");
//       const res = await axios.post(`${BASE_URI}medication-audit`, data, {
//         headers: {
//           "Content-Type": "application/json",
//           Accept: "application/json",
//           Authorization: `Bearer ${token}`,
//         },
//       });
//       resolve(res.data);
//     } catch (error) {
//       reject(error);
//     }
//   });
// };

// export const Administer = async (data) => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       let token = await AsyncStorage.getItem("AccessToken");
//       const res = await axios.post(`${BASE_URI}medication`, data, {
//         headers: {
//           "Content-Type": "application/json",
//           Accept: "application/json",
//           Authorization: `Bearer ${token}`,
//         },
//       });
//       resolve(res.data);
//     } catch (error) {
//       reject(error);
//     }
//   });
// };

// export const AddMedication = async (data) => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       let token = await AsyncStorage.getItem("AccessToken");
//       const res = await axios.patch(`${BASE_URI}medication/${data.id}`, data, {
//         headers: {
//           "Content-Type": "application/json",
//           Accept: "application/json",
//           Authorization: `Bearer ${token}`,
//         },
//       });
//       resolve(res.data);
//     } catch (error) {
//       reject(error);
//     }
//   });
// };

// export const CheckProtocol = async (data) => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       let token = await AsyncStorage.getItem("AccessToken");
//       const res = await axios.get(
//         `${BASE_URI}medication-protocol/carer/protocol/${data.id}`,
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Accept: "application/json",
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );
//       resolve(res.data);
//     } catch (error) {
//       reject(error);
//     }
//   });
// };

// export const GetCarePlans = async (data) => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       let token = await AsyncStorage.getItem("AccessToken");
//       const res = await axios.get(
//         `${BASE_URI}client-care-plans/get/client/care/plan?clientId=${data.clientId}`,
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Accept: "application/json",
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );
//       resolve(res.data);
//     } catch (error) {
//       reject(error);
//     }
//   });
// };

// export const GetMessages = async (data) => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       let token = await AsyncStorage.getItem("AccessToken");
//       const res = await axios.get(
//         `${BASE_URI}messages?page=${data.page}&perPage=${data.perPage}&clientId=${data.id}`,
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Accept: "application/json",
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );
//       resolve(res.data);
//     } catch (error) {
//       reject(error);
//     }
//   });
// };

// export const PostMessages = async (data) => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       let token = await AsyncStorage.getItem("AccessToken");
//       const res = await axios.post(`${BASE_URI}messages`, data, {
//         headers: {
//           "Content-Type": "application/json",
//           Accept: "application/json",
//           Authorization: `Bearer ${token}`,
//         },
//       });
//       resolve(res.data);
//     } catch (error) {
//       reject(error);
//     }
//   });
// };

// export const AdministeredMeds = async (data) => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       let token = await AsyncStorage.getItem("AccessToken");
//       const res = await axios.get(
//         `${BASE_URI}medication/administered/today/${data.id}`,
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Accept: "application/json",
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );
//       resolve(res.data);
//     } catch (error) {
//       reject(error);
//     }
//   });
// };

// export const TimeSheets = async (data) => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       let token = await AsyncStorage.getItem("AccessToken");
//       const res = await axios.get(
//         `${BASE_URI}timesheets/submitted/list?page=${data.page}&perPage=${data.perPage}`,
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Accept: "application/json",
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );
//       resolve(res.data);
//     } catch (error) {
//       reject(error);
//     }
//   });
// };

// export const AddNotes = async (data) => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       let token = await AsyncStorage.getItem("AccessToken");
//       const res = await axios.post(
//         `${BASE_URI}careplan-activity-notes`,
//         data,
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Accept: "application/json",
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );
//       resolve(res.data);
//     } catch (error) {
//       reject(error);
//     }
//   });
// };

// export const GetNotes = async (data) => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       let token = await AsyncStorage.getItem("AccessToken");
//       const res = await axios.get(
//         `${BASE_URI}careplan-activity-notes/${data.id}`,
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Accept: "application/json",
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );
//       resolve(res.data);
//     } catch (error) {
//       reject(error);
//     }
//   });
// };

// export const UpdateNotes = async (data) => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       let token = await AsyncStorage.getItem("AccessToken");
//       const res = await axios.patch(
//         `${BASE_URI}careplan-activity-notes/${data.id}`,
//         data,
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Accept: "application/json",
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );
//       resolve(res.data);
//     } catch (error) {
//       reject(error);
//     }
//   });
// };

// export const GetRiskAssess = async (data) => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       let token = await AsyncStorage.getItem("AccessToken");
//       const res = await axios.get(
//         `${BASE_URI}risk-assessment/by-client/${data.id}`,
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Accept: "application/json",
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );
//       resolve(res.data);
//     } catch (error) {
//       reject(error);
//     }
//   });
// };

// export const ResetPassword = async (data) => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       let token = await AsyncStorage.getItem("AccessToken");
//       const res = await axios.patch(`${BASE_URI}users/reset/password`, data, {
//         headers: {
//           "Content-Type": "application/json",
//           Accept: "application/json",
//           Authorization: `Bearer ${token}`,
//         },
//       });
//       resolve(res.data);
//     } catch (error) {
//       reject(error);
//     }
//   });
// };

// export const UpdateResume = async (data) => {
//   const documents = {
//     file: data.file
//       ? "data:" + data.file.type + "," + (await getBase64String(data.file.uri))
//       : "",
//   };
//   return new Promise(async (resolve, reject) => {
//     try {
//       let acc = await AsyncStorage.getItem("AccessToken");
//       console.log("SSE", documents);
//       const res = await axios.patch(
//         `${BASE_URI}carer-documents/${data.id}/resume`,
//         documents,
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Accept: "application/json",
//             Authorization: `Bearer ${acc}`,
//           },
//         }
//       );
//       resolve(res.data);
//     } catch (error) {
//       reject(error);
//     }
//   });
// };

// export const UpdateDbs = async (data) => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       const education = {
//         file: data.file
//           ? "data:" +
//             data.file.type +
//             "," +
//             (await getBase64String(data.file.uri))
//           : "",
//       };
//       let acc = await AsyncStorage.getItem("AccessToken");

//       const res = await axios.patch(
//         `${BASE_URI}carer-documents/${data.id}/dbs`,
//         education,
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Accept: "application/json",
//             Authorization: `Bearer ${acc}`,
//           },
//         }
//       );
//       resolve(res.data);
//     } catch (error) {
//       reject(error);
//     }
//   });
// };

// export const UpdatePassport = async (data) => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       const education = {
//         file: data.file
//           ? "data:" +
//             data.file.type +
//             "," +
//             (await getBase64String(data.file.uri))
//           : "",
//       };
//       let acc = await AsyncStorage.getItem("AccessToken");

//       const res = await axios.patch(
//         `${BASE_URI}carer-documents/${data.id}/passport`,
//         education,
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Accept: "application/json",
//             Authorization: `Bearer ${acc}`,
//           },
//         }
//       );
//       resolve(res.data);
//     } catch (error) {
//       reject(error);
//     }
//   });
// };

// export const UpdatePermit = async (data) => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       let acc = await AsyncStorage.getItem("AccessToken");
//       const education = {
//         file: data.file
//           ? "data:" +
//             data.file.type +
//             "," +
//             (await getBase64String(data.file.uri))
//           : "",
//       };
//       const res = await axios.patch(
//         `${BASE_URI}carer-documents/${data.id}/permit`,
//         education,
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Accept: "application/json",
//             Authorization: `Bearer ${acc}`,
//           },
//         }
//       );
//       resolve(res.data);
//     } catch (error) {
//       reject(error);
//     }
//   });
// };

// export const UpdateRtw = async (data) => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       let acc = await AsyncStorage.getItem("AccessToken");
//       const education = {
//         file: data.file
//           ? "data:" +
//             data.file.type +
//             "," +
//             (await getBase64String(data.file.uri))
//           : "",
//       };
//       const res = await axios.patch(
//         `${BASE_URI}carer-documents/${data.id}/rightToWork`,
//         education,
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Accept: "application/json",
//             Authorization: `Bearer ${acc}`,
//           },
//         }
//       );
//       resolve(res.data);
//     } catch (error) {
//       reject(error);
//     }
//   });
// };

// export const UpdateDiploma = async (data) => {
//   console.log("DATA", data);
//   return new Promise(async (resolve, reject) => {
//     try {
//       let acc = await AsyncStorage.getItem("AccessToken");
//       const education = {
//         file: data.file
//           ? "data:" +
//             data.file.type +
//             "," +
//             (await getBase64String(data.file.uri))
//           : "",
//       };
//       const res = await axios.patch(
//         `${BASE_URI}eduction-training/${data.id}/diploma/certificate`,
//         education,
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Accept: "application/json",
//             Authorization: `Bearer ${acc}`,
//           },
//         }
//       );
//       resolve(res.data);
//     } catch (error) {
//       reject(error);
//     }
//   });
// };

// export const UpdateCareCert = async (data) => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       const education = {
//         file: data.file
//           ? "data:" +
//             data.file.type +
//             "," +
//             (await getBase64String(data.file.uri))
//           : "",
//       };
//       let acc = await AsyncStorage.getItem("AccessToken");

//       const res = await axios.patch(
//         `${BASE_URI}eduction-training/${data.id}/care/certificate`,
//         education,
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Accept: "application/json",
//             Authorization: `Bearer ${acc}`,
//           },
//         }
//       );
//       resolve(res.data);
//     } catch (error) {
//       reject(error);
//     }
//   });
// };

// export const UpdateMandCert = async (data) => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       let acc = await AsyncStorage.getItem("AccessToken");
//       const education = {
//         file: data.file
//           ? "data:" +
//             data.file.type +
//             "," +
//             (await getBase64String(data.file.uri))
//           : "",
//       };
//       const res = await axios.patch(
//         `${BASE_URI}eduction-training/${data.id}/mandatory/training`,
//         education,
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Accept: "application/json",
//             Authorization: `Bearer ${acc}`,
//           },
//         }
//       );
//       resolve(res.data);
//     } catch (error) {
//       reject(error);
//     }
//   });
// };

// export const UpdateMedCert = async (data) => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       let acc = await AsyncStorage.getItem("AccessToken");
//       const education = {
//         file: data.file
//           ? "data:" +
//             data.file.type +
//             "," +
//             (await getBase64String(data.file.uri))
//           : "",
//       };
//       const res = await axios.patch(
//         `${BASE_URI}eduction-training/${data.id}/medication/training`,
//         education,
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Accept: "application/json",
//             Authorization: `Bearer ${acc}`,
//           },
//         }
//       );
//       resolve(res.data);
//     } catch (error) {
//       reject(error);
//     }
//   });
// };

// export const UpdateMovCert = async (data) => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       let acc = await AsyncStorage.getItem("AccessToken");
//       const education = {
//         file: data.file
//           ? "data:" +
//             data.file.type +
//             "," +
//             (await getBase64String(data.file.uri))
//           : "",
//       };
//       const res = await axios.patch(
//         `${BASE_URI}eduction-training/${data.id}/moving/handling`,
//         education,
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Accept: "application/json",
//             Authorization: `Bearer ${acc}`,
//           },
//         }
//       );
//       resolve(res.data);
//     } catch (error) {
//       reject(error);
//     }
//   });
// };

// export const UpdatePegCert = async (data) => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       let acc = await AsyncStorage.getItem("AccessToken");
//       const education = {
//         file: data.file
//           ? "data:" +
//             data.file.type +
//             "," +
//             (await getBase64String(data.file.uri))
//           : "",
//       };
//       const res = await axios.patch(
//         `${BASE_URI}eduction-training/${data.id}/peg/feed`,
//         education,
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Accept: "application/json",
//             Authorization: `Bearer ${acc}`,
//           },
//         }
//       );
//       resolve(res.data);
//     } catch (error) {
//       reject(error);
//     }
//   });
// };

// export const GetDocuments = async (data) => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       const res = await axios.get(`${BASE_URI}carer-documents/${data.id}`, {
//         headers: {
//           "Content-Type": "application/json",
//           Accept: "application/json",
//           Authorization: `Bearer ${data.accessToken}`,
//         },
//       });
//       resolve(res.data);
//     } catch (error) {
//       reject(error);
//     }
//   });
// };

// export const RiskCarePlanDeclare = async (data) => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       let token = await AsyncStorage.getItem("AccessToken");
//       const res = await axios.post(`${BASE_URI}careplan-risk-understand-declaration`, data, {
//         headers: {
//           "Content-Type": "application/json",
//           Accept: "application/json",
//           Authorization: `Bearer ${token}`,
//         },
//       });
//       resolve(res.data);
//       console.log('SUCCESS',res);
//     } catch (error) {
//       reject(error);
//       console.log('ERROR',error);
//     }
//   });
// };


// export const GetCarePlanDeclare = async (data) => {
//   return new Promise(async (resolve, reject) => {
//     try {
//       let token = await AsyncStorage.getItem("AccessToken");
//       const res = await axios.get(`${BASE_URI}careplan-risk-understand-declaration/${data.id}`, {
//         headers: {
//           "Content-Type": "application/json",
//           Accept: "application/json",
//           Authorization: `Bearer ${token}`,
//         },
//       });
//       resolve(res.data);
//       console.log('SUCCESS',res);
//     } catch (error) {
//       reject(error);
//       console.log('ERROR',error);
//     }
//   });
// };