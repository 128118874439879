import React from 'react'
import { Sidebar } from '../../../layouts/auth/Sidebar'
import { TopNavigation } from '../../../layouts/auth/TopNavigation'
import { useNavigate } from 'react-router-dom';
import FormikController from '../../../components/Formik/FormikController';
import SubmitButton from '../../../components/Formik/SubmitButton';
import { Formik, Form } from "formik";
import * as Yup from "yup";
import ProgressBar from './ProgressBar';
import { FaJenkins } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import getCarerTier from '../../configurations/ConfigActions/GetCarerTierAction';
import addTiers from '../../clients/ClientActions/ClientTierAction'
import getCarerList from '../../carers/CarerActions/GetCarersAction'

function ClientTier() {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const { getCarerTiers } = useSelector(state => state.getCarerTier);
    const [inValid, setInvalid] = React.useState('');
    const [inEndValid, setEndInvalid] = React.useState('');
    const [startTime, setStartTime] = React.useState('');
    const [endTime, setEndTime] = React.useState('');
    const { addClientPersonalDetails } = useSelector(state => state.addClientPersonal);
    const { getCarers } = useSelector(state => state.getCarersList);
    const [indexes, setIndexes] = React.useState([]);
    const [counter, setCounter] = React.useState(0);

    const itemsCountPerPage = 20;
    const [page, setPage] = React.useState(1);

    React.useEffect(() => {
        dispatch(getCarerTier({
            page,
            perPage: itemsCountPerPage
        }))

        dispatch(getCarerList({
            page,
            perPage: itemsCountPerPage,
            status: 'COMPLIANT'
        }))

    }, [page, addClientPersonalDetails, counter]);

    let carers = getCarers && getCarers.data ? getCarers.data.map(cxt => {
        let obj = {
            key: cxt.firstname+" "+cxt.lastname,
            value: cxt.id
        }
        return (obj);
    }) : [];

    const shifts = [
        {
            key: 'Day Shift',
            value: 'day'
        },
        {
            key: 'Night Shift',
            value: 'night'
        },
        {
            key: 'Live-In Shift',
            value: 'livein'
        },
    ];

    let carerTiers = getCarerTiers && getCarerTiers.data ? getCarerTiers.data.map(cxt => {
        let obj = {
            key: cxt.type ,
            value: cxt.id
        }
        return (obj);
    }) : [];

    const initialValues = {
        // shiftType:'',
        // shiftStartDate : '',
        // shiftEndDate : "",
        // shiftStartTime :'',
        // shiftEndTime : '',
        // careWorkerTierId : '',
        // careWorkerId : ''
    }

    function checkDate() {
        var selectedText = document.getElementById('datepicker').value;
        var selectedDate = new Date(selectedText);
        var now = new Date();
        if (selectedDate < now) {
      return setInvalid("Date must be in the future");
        }
        else{
            setInvalid('') 
        }
      }

      function  checkEndDate() {
        var selectedText = document.getElementById('datendpicker').value;
        var selectedDate = new Date(selectedText);
        var now = new Date();
        if (selectedDate < now) {
      return setEndInvalid("Date must be in the future");
        }
        else {
            setEndInvalid('')
        }
      }
     

    const validationSchema = Yup.object({

    });

    const addMarChart = () => {
        setIndexes(prevIndexes => [...prevIndexes, counter]);
        setCounter(prevCounter => prevCounter + 1);
    };

    const removeMarChart = index => () => {
        setIndexes(prevIndexes => [...prevIndexes.filter(item => item !== index)]);
        setCounter(prevCounter => prevCounter - 1);
    };

    const clearMarCharts = () => {
        setIndexes([]);
    };


    const handleSubmit = (values) => {
        console.log('VALUES',values);
        values.tiers.id = addClientPersonalDetails.id
        dispatch(addTiers(values.tiers))
        navigate('/clients')
    }

    const Head = (props) => {
        return (
            <div className='bg-gray-100 p-3 rounded mb-5 text-left'><label className='text-lg font-semibold text-gray-700'>{props.title}</label></div>
        )
    }

    var dateInPast = () => {
        // var today = new Date();
        // var comT = today.toLocaleTimeString()

        // var selectedTime = document.getElementById('datend').value;
        // if (selectedTime <= comT) {
        //     setStartTime('Time must be in the future')
        // }
        // else {
        //     setStartTime('')
        // }
      
      };

      var endTimePast = () => {
        // var today = new Date();
        // var comT = today.toLocaleTimeString()

        // var selectedTime = document.getElementById('datendd').value;
        // if (selectedTime <= comT) {
        //     setEndTime('Time must be in the future')
        // }else {
        //     setEndTime('')
        // }
      
      };

      React.useEffect (()=>{

      },[inValid, inEndValid, startTime, endTime])
      
      
 
    return (
        <div><Sidebar /><TopNavigation title='Add Client' />
            <div className='bg-white h-screen'>
                <div className="flex ml-80 mr-16 mt-5">
                    <div className='w-40 mr-3'><ProgressBar number={95} /></div>
                    <div className=''><div className=' w-full p-5'>
                        <nav className="flex py-3 px-5 mb-2 text-gray-700 bg-gray-50 rounded-lg border border-gray-200 dark:bg-gray-800 dark:border-gray-700" aria-label="Breadcrumb">
                            <ol className="inline-flex items-center space-x-1 md:space-x-3">
                                <li className="inline-flex items-center">
                                    <a href="#" className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
                                        <FaJenkins className="mr-2 w-4 h-4 font-bold" />  {addClientPersonalDetails.firstname} {addClientPersonalDetails.lastname}
                                    </a>
                                </li>
                                <li>
                                    <div className="flex items-center">
                                        <svg className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                                        <a href="#" className="ml-1 text-sm font-medium text-gray-700 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white">Referral Contract</a>
                                        <svg className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>

                                    </div>
                                </li>
                                <li>
                                    <div className="flex items-center">
                                        <a href="#" className=" text-sm font-medium text-gray-700 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white">Key Contacts</a>
                                        <svg className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>

                                    </div>
                                </li>
                                <li>
                                    <div className="flex items-center">
                                        <a href="#" className=" text-sm font-medium text-gray-700 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white">Property Access</a>
                                        <svg className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>

                                    </div>
                                </li>
                                <li>
                                    <div className="flex items-center">
                                        <a href="#" className=" text-sm font-medium text-gray-700 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white">Care Plans</a>
                                        <svg className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>

                                    </div>
                                </li>
                                <li>
                                    <div className="flex items-center">
                                        <a href="#" className=" text-sm font-medium text-gray-700 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white">Task Plans</a>
                                        <svg className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>

                                    </div>
                                </li>
                            </ol>
                        </nav></div>
                        <Formik
                            initialValues={initialValues}
                            enableReinitialize
                            validationSchema={validationSchema}
                            onSubmit={(values, { resetForm }) => {
                                handleSubmit(values);
                                resetForm();
                            }}
                        >
                            {({ setFieldValue, values }) => (
                                <Form className=''>
                                    {indexes.map(index => {
                                        const fieldName = `tiers[${index}]`;
                                        return (
                                            <fieldset name={fieldName} key={fieldName}>
                                                <div className='mb-5 font-bold ml-6'><Head title={`Shift Type ${(index + 1)}`} /></div>
                                                <div className='border rounded p-5 ml-6'>
                                                    {/* <div className=''><Head title='Client Tiers' /></div> */}
                                                    <div className="">
                                                        <FormikController
                                                            control='select'
                                                            type='select'
                                                            name={`${fieldName}.shiftType`}
                                                            label='Select Shift'
                                                            options={shifts}
                                                        />
                                                    </div>
                                                    <div>
                                                        <FormikController
                                                            control='input'
                                                            type='date'
                                                            id="datepicker"
                                                            name={`${fieldName}.shiftStartDate`}
                                                            label='Shift Start Date'
                                                            // onChange={()=> checkDate()}
                                                        />
                                                    </div>
                                                   <div className='text-left text-sm text-red-600'> {inValid}</div>
                                                    <div>
                                                        <FormikController
                                                            control='input'
                                                            type='date'
                                                            id="datendpicker"
                                                            name={`${fieldName}.shiftEndDate`}
                                                            label='Shift End Date'
                                                            // onChange={()=> checkEndDate()}
                                                        />
                                                    </div>
                                                    <div className='text-left text-sm text-red-600'> {inEndValid}</div>
                                                    <div>
                                                        <FormikController
                                                            control='input'
                                                            type='time'
                                                            id="datend"
                                                            name={`${fieldName}.shiftStartTime`}
                                                            label='Shift Start Time'
                                                            // onChange={()=>dateInPast()}
                                                        />
                                                    </div>
                                                    <div className='text-left text-sm text-red-600'> {startTime}</div>
                                                    <div>
                                                        <FormikController
                                                            control='input'
                                                            type='time'
                                                            id="datendd"
                                                            name={`${fieldName}.shiftEndTime`}
                                                            label='Shift End Time'
                                                            // onChange={()=>endTimePast()}
                                                        />
                                                    </div>
                                                    <div className='text-left text-sm text-red-600'> {endTime}</div>
                                                    <div>
                                                        <FormikController
                                                            control='select'
                                                            type='select'
                                                            name={`${fieldName}.careWorkerTierId`}
                                                            label='Care Worker Tier Rate*'
                                                            options={carerTiers}
                                                        />
                                                    </div>
                                                    <div className='text-left'>
                                                        <FormikController
                                                            control='select'
                                                            type='select'
                                                            name={`${fieldName}.careWorkerId`}
                                                            label='Select Care Worker*'
                                                            options={carers}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='text-left mt-3 border-b mb-5 ml-6'><button className='border border-button text-button font-medium p-3 px-5 bg-red-100 mb-4 rounded' type="button" onClick={removeMarChart(index)}>
                                                    Remove
                                                </button></div>
                                            </fieldset>
                                        )
                                    })}
                                    <div className="text-left py-5 ml-6">
                                        <button className='border rounded p-5 mr-3 border-green text-white bg-green font-medium' type="button" onClick={addMarChart}>
                                        Allocate Shift Types
                                        </button>
                                        {counter ? <button className='border rounded p-5 border-gray-300 bg-gray-500 text-white font-medium' type="button" onClick={clearMarCharts}>
                                            Clear Shift Allocation
                                        </button> : <div></div>}
                                    </div>

                                    {counter ?  <div className="mb-3 mt-5 flex flex-row-reverse">
                                        <div></div>
                                        <SubmitButton label='Create Client' />
                                    </div>: <div className="mb-3 mt-5 flex flex-row-reverse">
                                        <div></div>
                                        
                                    </div>}
                                </Form>
                            )
                            }
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ClientTier