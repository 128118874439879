import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  Login: {},
  isLoadingLogin: false,
  errorLogin: "",
  clearLogin: "",
};

const LoginSlice = createSlice({
  name: "Login",
  initialState,
  reducers: {
    LoginPending: (state) => {
      state.isLoadingLogin = true;
    },
    LoginSuccess: (state, { payload }) => {
      state.isLoadingLogin = false;
      state.Login = payload;
      state.errorLogin = "";
    },
    LoginFail: (state, { payload }) => {
      console.log("hello", payload);
      state.isLoadingLogin = false;
      state.errorLogin = payload;
    },
    ClearLogin: (state, { payload }) => {
      state.isLoadingLogin = false;
      state.Login = {};
      state.errorLogin = "";
    },
  },
});

export const { LoginPending, LoginSuccess, LoginFail } = LoginSlice.actions;

export default LoginSlice.reducer;
