import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  Background: {},
  isLoadingBackground: false,
  errorBackground: "",
};

const BackgroundSlice = createSlice({
  name: "Background",
  initialState,
  reducers: {
    BackgroundPending: (state) => {
      state.isLoadingBackground = true;
    },
    BackgroundSuccess: (state, { payload }) => {
      state.isLoadingBackground = false;
      state.Background = payload;
      state.errorBackground = "";
    },
    BackgroundFail: (state, { payload }) => {
      state.isLoadingBackground = false;
      state.errorBackground = payload;
    },
  },
});

export const { BackgroundPending, BackgroundSuccess, BackgroundFail } =
  BackgroundSlice.actions;

export default BackgroundSlice.reducer;
