import {clientTiersPending, clientTiersSuccess, clientTiersFail} from '../ClientSlices/ClientTierSlice'
import {clientTiers} from '../../../api/clientApi'

 const addTiers = (data) => async dispatch => {

    try {
        dispatch(clientTiersPending());
        const result = await clientTiers(data);
        if(result){
            return dispatch(clientTiersSuccess(result))
        }
    } catch (error) {
        dispatch(clientTiersFail(error))
    }
} 

export default addTiers