import { addMessage } from "../../../api/messagesApi";
import { addMessageFail, addMessagePending, addMessageSuccess } from "../DashboardSlices/AddMessageSlice";

 const addNewMessage = (data) => async dispatch => {

    try {
        dispatch(addMessagePending());
        const result = await addMessage(data);
        if(result){
            return dispatch(addMessageSuccess(result))
        }
    } catch (error) {
        dispatch(addMessageFail(error))
    }
} 

export default addNewMessage