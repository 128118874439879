import React from "react";
import PersonalDetails from "./PersonalDetails";
import Contracts from "./Contracts";
import Contacts from "./Contacts";
import Property from "./Property";
import CarePlans from "./CarePlans";
import Tiers from "./Tiers";
import { useSelector,useDispatch } from "react-redux";
import MARChart from "./MARChart";
import Messages from "../AddClient/Messages";
import RiskAssessment from "./RiskAssessment";
import RecentVisits from "./RecentVisits";
import ClientInfo from '../ClientActions/GetClientInfoAction';
import KeyContacts from "../AddClient/KeyContacts";

export default function EditClient({ client }) {
  const dispatch = useDispatch();

  const [showModal, setShowModal] = React.useState(false);
  // const {getClientInfo} = useSelector(state => state.GetClientInfoSlice);
  const handleOpen = (id) => {
    setShowModal(true);
  };

  const { editCarePlan } = useSelector((state) => state.updateCarePlans);

  React.useEffect(() => {
    // dispatch(ClientInfo({
    //   id:client?.id
    // }))
  }, [editCarePlan,]);

  const [active, setActive] = React.useState("personal");

  let content;

  switch (active) {
    case "personal":
      content = <PersonalDetails client={client} setShowModal={setShowModal} />;
      break;
    case "contracts":
      content = <Contracts client={client} setShowModal={setShowModal} />;
      break;
    case "contacts":
      content = <Contacts client={client} setShowModal={setShowModal} />;
      break;
    case "property":
      content = <Property client={client} setShowModal={setShowModal} />;
      break;
    case "careplans":
      content = <CarePlans client={client} setShowModal={setShowModal} />;
      break;
    case "tiers":
      content = <Tiers client={client} setShowModal={setShowModal} />;
      break;
    case "marchart":
      content = <MARChart client={client} setShowModal={setShowModal} />;
      break;
    case "risk":
      content = <RiskAssessment client={client} setShowModal={setShowModal} />;
      break;
      case "visits":
      content = <RecentVisits client={client} setShowModal={setShowModal} />;
      break;
    case "messages":
      content = <Messages client={client} setShowModal={setShowModal} />;
      break;
    default:
      break;
  }

  return (
    <>
      <button
        className=" text-white bg-button border hover:bg-white hover:text-gray-700 hover:border hover:border-gray-300 hover:underline decoration-secondary focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-1 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        type="button"
        onClick={() => handleOpen(client.id)}
      >
        View More
      </button>
      {showModal ? (
        <>
          <div className="justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-7xl ">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-xl font-semibold text-gray-500">
                    <span className="text-green">
                      {client.firstname} {client.lastname}
                    </span>
                    's Profile
                  </h3>
                  <button
                    className="p-1 ml-10 bg-transparent border-0 text-button float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    ×
                  </button>
                </div>

                <div className="relative p-5 flex-auto">
                  <div className="">
                    <div>
                      <ul className="flex flex-wrap border p-1 rounded bg-orange-50">
                        <li className="mr-1">
                          <button
                            onClick={() => setActive("personal")}
                            className={`inline-block p-4 focus:outline-none rounded-t-lg border-b-2 ${
                              active === "personal"
                                ? "border-secondary text-white font-medium dark:text-button dark:border-button bg-orange-500"
                                : "border hover:text-button hover:border-secondary hover:bg-white hover:border-b-button  text-gray-500  dark:hover:text-gray-300 font-medium"
                            }`}
                          >
                            Personal Details 
                          </button>
                        </li>
                        <li className="mr-1">
                          <button
                            onClick={() => setActive("contracts")}
                            className={`inline-block p-4 focus:outline-none rounded-t-lg border-b-2 ${
                              active === "contracts"
                                ? "border-secondary text-white font-medium bg-orange-500 dark:text-button dark:border-button"
                                : "border hover:text-button hover:border-secondary hover:bg-white hover:border-b-button  text-gray-500  dark:hover:text-gray-300 font-medium"
                            }`}
                          >
                            Contracts
                          </button>
                        </li>
                        <li className="mr-1">
                          <button
                            onClick={() => setActive("contacts")}
                            className={`inline-block p-4 focus:outline-none rounded-t-lg border-b-2 ${
                              active === "contacts"
                                ? "border-secondary text-white font-medium bg-orange-500 dark:text-button dark:border-button"
                                : "border hover:text-button hover:border-secondary hover:bg-white hover:border-b-button  text-gray-500  dark:hover:text-gray-300 font-medium"
                            }`}
                          >
                            Contacts
                          </button>
                        </li>
                        <li className="mr-1">
                          <button
                            onClick={() => setActive("property")}
                            className={`inline-block p-4 focus:outline-none rounded-t-lg border-b-2 ${
                              active === "property"
                                ? "border-secondary text-white font-medium bg-orange-500 dark:text-button dark:border-button"
                                : "border hover:text-button hover:border-secondary hover:bg-white hover:border-b-button  text-gray-500  dark:hover:text-gray-300 font-medium"
                            }`}
                          >
                            Property Access
                          </button>
                        </li>
                        <li className="mr-1">
                          <button
                            onClick={() => setActive("careplans")}
                            className={`inline-block p-4 focus:outline-none rounded-t-lg border-b-2 ${
                              active === "careplans"
                                ? "border-secondary text-white font-medium bg-orange-500 dark:text-button dark:border-button"
                                : "border hover:text-button hover:border-secondary hover:bg-white hover:border-b-button  text-gray-500  dark:hover:text-gray-300 font-medium"
                            }`}
                          >
                            Care Plans
                          </button>
                        </li>
                        <li className="mr-1">
                          <button
                            onClick={() => setActive("tiers")}
                            className={`inline-block p-4 focus:outline-none rounded-t-lg border-b-2 ${
                              active === "tiers"
                                ? "border-secondary text-white font-medium bg-orange-500 dark:text-button dark:border-button"
                                : "border hover:text-button hover:border-secondary hover:bg-white hover:border-b-button  text-gray-500  dark:hover:text-gray-300 font-medium"
                            }`}
                          >
                          Allocate Shift Types
                          </button>
                        </li>
                        <li className="mr-1">
                          <button
                            onClick={() => setActive("messages")}
                            className={`inline-block p-4 focus:outline-none rounded-t-lg border-b-2 ${
                              active === "messages"
                                ? "border-secondary text-white font-medium bg-orange-500 dark:text-button dark:border-button"
                                : "border hover:text-button hover:border-secondary hover:bg-white hover:border-b-button  text-gray-500  dark:hover:text-gray-300 font-medium"
                            }`}
                          >
                            Messages
                          </button>
                        </li>

                        <li className="mr-1">
                          <button
                            onClick={() => setActive("marchart")}
                            className={`inline-block p-4 focus:outline-none rounded-t-lg border-b-2 ${
                              active === "marchart"
                                ? "border-secondary text-white font-medium bg-orange-500 dark:text-button dark:border-button"
                                : "border hover:text-button hover:border-secondary hover:bg-white hover:border-b-button  text-gray-500  dark:hover:text-gray-300 font-medium"
                            }`}
                          >
                            MAR CHART
                          </button>
                        </li>
                        <li className="mr-1">
                          <button
                            onClick={() => setActive("risk")}
                            className={`inline-block p-4 focus:outline-none rounded-t-lg border-b-2 ${
                              active === "risk"
                                ? "border-secondary text-white font-medium bg-orange-500 dark:text-button dark:border-button"
                                : "border hover:text-button hover:border-secondary hover:bg-white hover:border-b-button  text-gray-500  dark:hover:text-gray-300 font-medium"
                            }`}
                          >
                            Risk Assessment
                          </button>
                        </li>
                        <li className="mr-1">
                          <button
                            onClick={() => setActive("visits")}
                            className={`inline-block p-4 focus:outline-none rounded-t-lg border-b-2 ${
                              active === "visits"
                                ? "border-secondary text-white font-medium bg-orange-500 dark:text-button dark:border-button"
                                : "border hover:text-button hover:border-secondary hover:bg-white hover:border-b-button  text-gray-500  dark:hover:text-gray-300 font-medium"
                            }`}
                          >
                            Recent Visits
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="text-gray-500 ">{content}</div>
              </div>
            </div>
          </div>

          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
