import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    updateStaff: {},
    isLoadingStaffUpdate: false,
    errorStaffUpdate: ''
}

const updateStaffSlice = createSlice({
    name: 'updateStaff',
    initialState,
    reducers:{
        updateStaffPending: (state) => {
            state.isLoadingStaffUpdate = true;
        },
        updateStaffSuccess: (state, {payload}) => {
            state.isLoadingStaffUpdate = false;
            state.updateStaff = payload;
            state.errorStaffUpdate = '';
        },
        updateStaffFail: (state, {payload}) => {
            state.isLoadingStaffUpdate = false;
            state.errorStaffUpdate = payload;
        }
    }
})

export const {updateStaffPending, updateStaffSuccess, updateStaffFail} = updateStaffSlice.actions

export default updateStaffSlice.reducer