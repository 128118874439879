import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    addStafff: {},
    isLoadingAddStaff: false,
    errorAddStaff: ''
}

const addStaffSlice = createSlice({
    name: 'addStafff',
    initialState,
    reducers:{
        addStaffPending: (state) => {
            state.isLoadingAddStaff = true;
        },
        addStaffSuccess: (state, {payload}) => {
            state.isLoadingAddStaff = false;
            state.addStafff = payload;
            state.errorAddStaff = '';
        },
        addStaffFail: (state, {payload}) => {
            state.isLoadingAddStaff = false;
            state.errorAddStaff = payload;
        }
    }
})

export const {addStaffPending, addStaffSuccess, addStaffFail} = addStaffSlice.actions

export default addStaffSlice.reducer