import {clockOutOverduePending, clockOutOverdueSuccess, clockOutOverdueFail} from '../DashboardSlices/ClockOutOverdueSlice'
import {getClockOutOverdue} from '../../../api/carerApi'

 const ClockOutOverdueAction = (data) => async dispatch => {

    try {
        dispatch(clockOutOverduePending());
        const result = await getClockOutOverdue(data);
        if(result){
            return dispatch(clockOutOverdueSuccess(result))
        }
    } catch (error) {
        dispatch(clockOutOverdueFail(error))
    }
} 

export default ClockOutOverdueAction