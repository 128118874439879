import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    carerClockOut: {},
    isLoadingClockOut: false,
    errorClockOut: ''
}

const clockOutSlice = createSlice({
    name: 'carerClockOut',
    initialState,
    reducers:{
        clockOutPending: (state) => {
            state.isLoadingClockOut = true;
        },
        clockOutSuccess: (state, {payload}) => {
            state.isLoadingClockOut = false;
            state.carerClockOut = payload;
            state.errorClockOut = '';
        },
        clockOutFail: (state, {payload}) => {
            state.isLoadingClockOut = false;
            state.errorClockOut = payload;
        }
    }
})

export const {clockOutPending, clockOutSuccess, clockOutFail} = clockOutSlice.actions

export default clockOutSlice.reducer