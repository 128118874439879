import {getMarChartsPending, getMarChartsSuccess, getMarChartsFail} from '../ClientSlices/GetMarChartsSlice'
import {getAddedMarCharts} from '../../../api/clientApi'

 const getMarCharts = (data) => async dispatch => {

    try {
        dispatch(getMarChartsPending());
        const result = await getAddedMarCharts(data);
        if(result){
            return dispatch(getMarChartsSuccess(result))
        }
    } catch (error) {
        dispatch(getMarChartsFail(error))
    }
} 

export default getMarCharts