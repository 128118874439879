import React from 'react'
import EditCarerNextofKin from '../../carers/AddCarer/EditCarerNextofKin';
import { useDispatch, useSelector } from "react-redux";
import PageLoader from '../../../components/PageLoader';
import GetCarerInfoAction from '../../carers/RegisterCarerActions/GetCarerInfoAction';
import NextofKin from '../../carers/AddCarer/NextofKin'

function NextOfKin({carer}) {
    const dispatch = useDispatch();
const cares = carer?.carer;
const { updateNextofkin, isLoadingupdateNextofkin } = useSelector(
    (state) => state.UpdateNextofkin
  );
  const { nextofkin, isLoadingNextofkin } = useSelector(
    (state) => state.NextofKin
  );
  const {GetCarerInfo, isLoadingGetCarerInfo} = useSelector((state)=>state.GetCarerInfoSliceReducer)
// console.log('nextofkins',GetCarerInfo.nextofkins);
  React.useEffect(() => {
    dispatch(GetCarerInfoAction({id:carer?.carer?.id}))
  }, [updateNextofkin,nextofkin]);

  console.log('GetCarerInfo',GetCarerInfo);
  return ( GetCarerInfo.nextofkins.length === 0 ? <NextofKin GetCarerInfo={GetCarerInfo}/> : 
    <div className='w-full p-5'>
       <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
           <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
               <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                   <tr>
                       <th scope="col" className="px-6 py-3">
                         Name
                       </th>
                       <th scope="col" className="px-6 py-3">
                         Surname
                       </th>
                       <th scope="col" className="px-6 py-3">
                          R/ship
                       </th>                  
                       <th scope="col" className="px-6 py-3">
                           Phone
                       </th>
                       <th scope="col" className="px-6 py-3">
                           Email
                       </th>
                       <th scope="col" className="px-6 py-3">
                           
                       </th>
                       {/* <th scope="col" className="px-4 py-3">
                          Post Code
                       </th>
                       <th scope="col" className="px-4 py-3">
                         Address Line 1
                       </th>
                       <th scope="col" className="px-4 py-3">
                         County
                       </th> */}
                       {/* <th scope="col" className="px-4 py-3">
                         Town
                       </th> */}
                   </tr>
               </thead>
               <tbody>
            {GetCarerInfo.nextofkins && GetCarerInfo.nextofkins.length > 0 ? GetCarerInfo.nextofkins.map((kin) =>(
            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-primary dark:hover:bg-gray-600">
                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                            {kin.firstname}  {kin.middlename}  
                        </th>
                        <td className="px-6 py-4">
                            {kin.lastname}
                        </td>
                        <td className="px-6 py-4">
                            {kin.relationship}
                        </td>                                                    
                        <td className="px-6 py-4">
                            {kin.phone}
                        </td>
                        <td className="px-6 py-4">
                            {kin.email ? kin.email : "-"}
                        </td>
                        <td className="px-6 py-4">
                        <EditCarerNextofKin kin ={kin}/>
                        </td>
                        {/* <td className="px-6 py-4">
                            {kin.postCode}
                        </td>
                        <td className="px-6 py-4">
                            {kin.addressLine1}
                        </td>
                        <td className="px-6 py-4">
                            {kin.town}
                        </td>   
                        <td className="px-6 py-4">
                            {kin.county}
                        </td>                                 */}
                     
                    </tr> 
            )): <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
            <td colSpan="9" className="text-center px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap border rounded bg-red-100">No Data Found</td>
        </tr>}               
               
            </tbody>
        </table>
  
        </div>
    </div>
 
  )
  

        
}

export default NextOfKin