import React from "react";
import { Sidebar } from "../../../layouts/auth/Sidebar";
import { TopNavigation } from "../../../layouts/auth/TopNavigation";
import SimpleSwitch from "../../../components/SimpleSwitch";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import ProgressBar from "../../clients/AddClient/ProgressBar";
import SubmitButton from "../../../components/Formik/SubmitButton";
import FormikController from "../../../components/Formik/FormikController";
import GetReligionAction from "../RegisterCarerActions/GetReligionAction";
import GetEthnicityAction from "../RegisterCarerActions/GetEthnicityAction";
import { useDispatch, useSelector } from "react-redux";
import EqualMonitoringAction from "../RegisterCarerActions/EqualMonitoringAction";

function CarerEqualMonitoring({ carer }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const { signUp, isLoadingSignUp, errorSignUp } = useSelector(
    (state) => state.signUpCarer
  );

  const [page] = React.useState(0);
  const itemsCountPerPage = 20;

  const { GetReligion } = useSelector((state) => state.GetReligion);
  const { GetEthnicity } = useSelector((state) => state.GetEthnicity);

  const initialValues = {
    ethnicityId: "",
    religionId: "",
    id: signUp.user.id,
  };

  React.useEffect(() => {
    dispatch(
      GetReligionAction({
        page,
        perPage: itemsCountPerPage,
      })
    );
    dispatch(
      GetEthnicityAction({
        page,
        perPage: itemsCountPerPage,
      })
    );
  }, [page]);
  const validationSchema = Yup.object({});

    let religion = Array.isArray(GetReligion.data)
    ? GetReligion.data.map((cxt) => {
        let obj = {
          key: cxt.religion,
          value: cxt.id,
        };

        return obj;
      })
    : [];

  let ethnicity = Array.isArray(GetEthnicity.data)
    ? GetEthnicity.data.map((cxt) => {
        let obj = {
          key: cxt.ethnicity,
          value: cxt.id,
        };

        return obj;
      })
    : [];

  const Head = (props) => {
    return (
      <div className=" mb-5">
        <label className="text-xl font-medium text-gray-700">
          {props.title}
        </label>
      </div>
    );
  };

  const handleSubmit = (values) => {
    console.log("VALUEE", values);
    dispatch(EqualMonitoringAction(values));
    navigate("/carer-training");
  };

  return (
    <div className="bg-gray-50 h-screen">
      <Sidebar />
      <TopNavigation title="Add Carer" />
      <div className="bg-gray-50">
        <div className="flex ml-80 mr-16 mt-5">
          <div className="w-40 border mt-5 p-3 rounded-lg bg-gray-50 h-screen">
            <ProgressBar number={85} />
          </div>
          <div className="w-full p-5">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values) => handleSubmit(values)}
            >
              {({ formik, values }) => {
                return (
                  <Form className="text-left">
                     <div className="">
                        <Head title="Equal Opportunites Monitoring" />
                      </div>
                    <div className=" bg-white shadow p-5 rounded ">
                     
                     
                      <div className="grid grid-cols-2 gap-2 ">
                        <FormikController
                          control="select"
                          type="select"
                          name="religionId"
                          label="Religion / Belief "
                          options={religion}
                        />
  <FormikController
                          control="select"
                          type="select"
                          name="ethnicityId"
                          label="Ethnic Group"
                          options={ethnicity}
                        />
                      
                      </div>
                    </div>
                    <div className="mb-3 mt-5 flex flex-row-reverse">
                      <div></div>
                      <SubmitButton label="Save & Next" />
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CarerEqualMonitoring;
