import { useUpdateTimesheet } from "../../../api/timesheetsApi";
import { editTimesheetsFail, editTimesheetsPending, editTimesheetsSuccess } from "../Slices/TimesheetsSlice";

const updateTimesheets = (data) => async dispatch => {

    try {
        dispatch(editTimesheetsPending());
        const result = await useUpdateTimesheet(data);
        if(result){
            return dispatch(editTimesheetsSuccess(result))
        }
    } catch (error) {
        dispatch(editTimesheetsFail(error))
    }
} 

export default updateTimesheets