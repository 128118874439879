import {getRegulatorClientPending, getRegulatorClientSuccess, getRegulatorClientFail} from '../staffSlices/getRegulatorClientsSlice'
import {getRegulatorClients} from '../../../api/staffApi'

 const getRegulatorAction = (data) => async dispatch => {

    try {
        dispatch(getRegulatorClientPending());
        const result = await getRegulatorClients(data);
        if(result){
            return dispatch(getRegulatorClientSuccess(result))
        }
    } catch (error) {
        dispatch(getRegulatorClientFail(error))
    }
}

export default getRegulatorAction