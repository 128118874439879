import {getriskAssessmentPending, getriskAssessmentSuccess, getriskAssessmentFail} from '../ClientSlices/getRiskAssessmentSlice'
import {getRiskAssess} from '../../../api/clientApi'

 const getriskAssess = (data) => async dispatch => {

    try {
        dispatch(getriskAssessmentPending());
        const result = await getRiskAssess(data);
        if(result){
            return dispatch(getriskAssessmentSuccess(result))
        }
    } catch (error) {
        dispatch(getriskAssessmentFail(error))
    }
} 

export default getriskAssess