import React from "react";
import FormikController from "../../../components/Formik/FormikController";
import SubmitButton from "../../../components/Formik/SubmitButton";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Field } from "formik";
import editClientProperty from "../ClientActions/EditPropertyAction";
import PageLoader from "../../../components/PageLoader";
import editKycDocument from "../ClientActions/EditKycAction";
import ClientInfo from '../ClientActions/GetClientInfoAction';
import PropertyAccess from "../AddClient/PropertyAccess";

function Property({ client }) {

  const dispatch = useDispatch();
  const { editProperty, isLoadingProperty, errorEditProperty } = useSelector(
    (state) => state.updateProperty
  );

  const { clientProperty } = useSelector((state) => state.addProperty);
 
  const {getClientInfo,isLoadinggetClientInfo} = useSelector(state => state.GetClientInfoSlice);
  const { editKyc, isLoadingEditKyc } = useSelector(
    (state) => state.editKycDoc
  );
  const initialValues = {
    pathwaysAndDriveways:
      getClientInfo && getClientInfo.kyc ? getClientInfo.kyc.pathwaysAndDriveways : false,
    pathwaysAndDrivewaysComment:
      getClientInfo && getClientInfo.kyc ? getClientInfo.kyc.pathwaysAndDrivewaysComment : "",
    doorwaysEntryExits:
      getClientInfo && getClientInfo.kyc ? getClientInfo.kyc.doorwaysEntryExits : false,
    doorwaysEntryExitsComment:
      getClientInfo && getClientInfo.kyc ? getClientInfo.kyc.doorwaysEntryExitsComment : "",
    Entrance: getClientInfo && getClientInfo.kyc ? getClientInfo.kyc.Entrance : false,
    EntranceComment: getClientInfo && getClientInfo.kyc ? getClientInfo.kyc.EntranceComment : "",
    stairwaysAndStairwells:
      getClientInfo && getClientInfo.kyc ? getClientInfo.kyc.stairwaysAndStairwells : false,
    stairwaysAndStairwellsComment:
      getClientInfo && getClientInfo.kyc ? getClientInfo.kyc.stairwaysAndStairwellsComment : "",
    electricalHazards:
      getClientInfo && getClientInfo.kyc ? getClientInfo.kyc.electricalHazards : false,
    electricalHazardsComment:
      getClientInfo && getClientInfo.kyc ? getClientInfo.kyc.electricalHazardsComment : "",
    trippoingFallingStumbling:
      getClientInfo && getClientInfo.kyc ? getClientInfo.kyc.trippoingFallingStumbling : false,
    trippingFallingStumblingComment:
      getClientInfo && getClientInfo.kyc ? getClientInfo.kyc.trippingFallingStumblingComment : "",
    kitchens: getClientInfo && getClientInfo.kyc ? getClientInfo.kyc.kitchens : false,
    kitchensComment: getClientInfo && getClientInfo.kyc ? getClientInfo.kyc.kitchensComment : "",
    bathrooms: getClientInfo && getClientInfo.kyc ? getClientInfo.kyc.bathrooms : false,
    bathroomsComment: getClientInfo && getClientInfo.kyc ? getClientInfo.kyc.bathroomsComment : "",
    otherAreas: getClientInfo && getClientInfo.kyc ? getClientInfo.kyc.otherAreas : false,
    otherAreasComment: getClientInfo && getClientInfo.kyc ? getClientInfo.kyc.otherAreasComment : "",
    propertyDescription : getClientInfo && getClientInfo.kyc ? getClientInfo.kyc.propertyDescription : "",
    propertyKeyCode : getClientInfo && getClientInfo.kyc ? getClientInfo.kyc.propertyKeyCode : "",

  };

  const property = {
    documents: "",
  };
  const FILE_SIZE = 1024 * 1024 * 10;
  const SUPPORTED_FORMATS = [
    "application/pdf",
    "image/jpg",
    "image/jpeg",
    "image/png",
  ];

  const validationSchema = Yup.object({
    documents: Yup.mixed()
      .test(
        "format",

        "Only the following formats are accepted: .pdf, .jpeg, .jpg, .png",

        (value) => !value || (value && SUPPORTED_FORMATS.includes(value.type))
      )
      .test(
        "fileSize",

        "File too large",

        (value) =>
          value === null || ((value) => value && value.size <= FILE_SIZE)
      ),
  });

  React.useEffect(() => {
    dispatch(ClientInfo({
      id:client.id
    }))
  }, [editProperty, editKyc,clientProperty]);

  // console.log('getClientInfo',getClientInfo);

  const handleSubmit = (data) => {
    console.log('infodata',data);
    data.id = getClientInfo?.kyc?.id;;
    dispatch(editClientProperty(data));
  };

  const handleDocuments = (data) => {
    
    data.id = getClientInfo?.kyc?.id;
    console.log('docdata',data);
    dispatch(editKycDocument(data));
  };
  const Head = (props) => {
    return (
      <div className="border-b mb-5 text-left">
        <label className="text-xl font-medium text-gray-700">
          {props.title}
        </label>
      </div>
    );
  };



  return (!getClientInfo?.kyc ? <PropertyAccess getClientInfo={getClientInfo}/> :
    <div className="w-full p-5">
       <Head title="Document" />
     {getClientInfo?.kyc?.documents ? <iframe
        src={getClientInfo.kyc ? getClientInfo.kyc.documents : ""}
        frameborder="0"
        height="300"
        width="100%"
      ></iframe> : <div class="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 text-center border border-red-200" role="alert">
      No File Uploaded
   </div>}
      <Formik
        initialValues={property}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          handleDocuments(values);
          resetForm();
        }}
      >
        {({ setFieldValue, ...rest }) => (
          <Form className="text-left">
            <div className="border p-5 rounded">
             
              <FormikController
                control="input"
                type="file"
                name="documents"
                label="Upload Documents"
                value={null}
                onChange={(event) =>
                  setFieldValue("documents", event.target.files[0])
                }
              />
            </div>
            <div className=" flex flex-row-reverse">
              {isLoadingEditKyc ? (
                <PageLoader />
              ) : (
                <SubmitButton label="Update" />
              )}
            </div>
          </Form>
        )}
      </Formik>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {({ setFieldValue, values, ...rest }) => {
          return (
            <Form className="text-left">
              <div className="border p-5 rounded">
                <div className="">
                  <Head title="Property Risk Assessment" />
                </div>
                <div className="grid grid-cols-1 gap-2 border p-3 rounded mb-5">
                  <div>
                    <label>
                      <Field
                        type="checkbox"
                        name="pathwaysAndDriveways"
                        value={true}
                        checked={values.pathwaysAndDriveways === true}
                      />
                      <span className="ml-2"> Pathways & Drives Detail</span>
                    </label>
                    {values.pathwaysAndDriveways && (
                      <div>
                        <div className="mt-2">
                          <FormikController
                            control="textArea"
                            type="text"
                            name="pathwaysAndDrivewaysComment"
                            label="Action required to minimise the risk"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <div>
                    <label>
                      <Field
                        type="checkbox"
                        name="doorwaysEntryExits"
                        value={true}
                        checked={values.doorwaysEntryExits === true}
                      />
                      <span className="ml-2">    Doorways, Entry & Exits</span>
                    </label>
                    {values.doorwaysEntryExits === true && (
                      <div>
                        <div className="mt-2">
                          <FormikController
                            control="textArea"
                            type="text"
                            name="doorwaysEntryExitsComment"
                            label="Action required to minimise the risk"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <div>
                    <label>
                      <Field
                        type="checkbox"
                        name="Entrance"
                        value={true}
                        checked={values.Entrance === true}
                      />
                      <span className="ml-2"> Entrance</span>
                    </label>
                    {values.Entrance === true && (
                      <div>
                        <div className="mt-2">
                          <FormikController
                            control="textArea"
                            type="text"
                            name="EntranceComment"
                            label="Action required to minimise the risk"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <div>
                    <label>
                      <Field
                        type="checkbox"
                        name="stairwaysAndStairwells"
                        value={true}
                        checked={values.stairwaysAndStairwells === true}
                      />
                      <span className="ml-2"> Stairways & Stairwells</span>
                    </label>
                    {values.stairwaysAndStairwells === true && (
                      <div>
                        <div className="mt-2">
                          <FormikController
                            control="textArea"
                            type="text"
                            name="stairwaysAndStairwellsComment"
                            label="Action required to minimise the risk"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <div>
                    <label>
                      <Field
                        type="checkbox"
                        name="electricalHazards"
                        value={true}
                        checked={values.electricalHazards === true}
                      />
                      <span className="ml-2"> Electrical hazards</span>
                    </label>
                    {values.electricalHazards === true && (
                      <div>
                        <div className="mt-2">
                          <FormikController
                            control="textArea"
                            type="text"
                            name="electricalHazardsComment"
                            label="Action required to minimise the risk"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <div>
                    <label>
                      <Field
                        type="checkbox"
                        name="trippoingFallingStumbling"
                        value={true}
                        checked={values.trippoingFallingStumbling === true}
                      />
                      <span className="ml-2">  Tripping, Falling & Stumbling</span>
                    </label>
                    {values.trippoingFallingStumbling === true && (
                      <div>
                        <div className="mt-2">
                          <FormikController
                            control="textArea"
                            type="text"
                            name="trippingFallingStumblingComment"
                            label="Action required to minimise the risk"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <div>
                    <label>
                      <Field
                        type="checkbox"
                        name="kitchens"
                        value={true}
                        checked={values.kitchens === true}
                      />
                      <span className="ml-2">  Kitchens</span>
                    </label>
                    {values.kitchens === true && (
                      <div>
                        <div className="mt-2">
                          <FormikController
                            control="textArea"
                            type="text"
                            name="kitchensComment"
                            label="Action required to minimise the risk"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <div>
                    <label>
                      <Field
                        type="checkbox"
                        name="bathrooms"
                        value={true}
                        checked={values.bathrooms === true}
                      />
                      <span className="ml-2">  Bathrooms</span>
                    </label>
                    {values.bathrooms === true && (
                      <div>
                        <div className="mt-2">
                          <FormikController
                            control="textArea"
                            type="text"
                            name="bathroomsComment"
                            label="Action required to minimise the risk"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <div>
                    <label>
                      <Field
                        type="checkbox"
                        name="otherAreas"
                        value={true}
                        checked={values.otherAreas === true}
                      />
                      <span className="ml-2">  Other areas</span>
                    </label>
                    {values.otherAreas === true && (
                      <div>
                        <div className="mt-2">
                          <FormikController
                            control="textArea"
                            type="text"
                            name="otherAreasComment"
                            label="Action required to minimise the risk"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="">
                  <Head title="Property Access Instruction" />
                </div>
                <div className="grid grid-cols-2 gap-2">
                  <FormikController
                    control="textArea"
                    type="text"
                    name="propertyDescription"
                    label="Description"
                  />
                  {/* <FormikController
                    control="textArea"
                    type="text"
                    name="propertyRisk"
                    label="Property Risk Assessment"
                  /> */}
                  <FormikController
                    control="input"
                    type="text"
                    name="propertyKeyCode"
                    label="Key Code Number"
                  />
                </div>

                {/* <div className="mt-10">
                  <Head title="Diagnosis Details" />
                </div>
                <div className="grid grid-cols-3 gap-2">
                  <FormikController
                    control="textArea"
                    type="text"
                    name="diagnosisDetails"
                    label="Diagnosis Details"
                  />
                  <FormikController
                    control="textArea"
                    type="text"
                    name="historyBackground"
                    label="History & Background"
                  />
                  <FormikController
                    control="textArea"
                    type="text"
                    name="likeDislikes"
                    label="Likes & Dislikes"
                  />

                  <FormikController
                    control="input"
                    type="text"
                    name="mobility"
                    label="Mobility"
                  />
                  <FormikController
                    control="input"
                    type="text"
                    name="whoAmI"
                    label="Who Am I ?"
                  />
                </div> */}
                {/* <div className="mt-10">
                  <Head title="Allergies" />
                </div>
                <div className="grid grid-cols-3 gap-2">
                  <FormikController
                    control="textArea"
                    type="text"
                    name="knownAllergies"
                    label="Known Allergies"
                  />
                  <FormikController
                    control="textArea"
                    type="text"
                    name="painExpression"
                    label="How does the person express pain"
                  />
                  <FormikController
                    control="textArea"
                    type="text"
                    name="safetyIssues"
                    label="Identify any issues relating to safety(e.g wandering, choking)"
                  />
                  <div className="">
                    <span className="font-medium">
                      Does the person suffer from any medical conditions below
                    </span>
                    <br />
                    <div className="border rounded p-3">
                      <label>
                        <Field
                          type="checkbox"
                          name="Diabetes"
                          value={true}
                          checked={values.Diabetes === true}
                        />
                        <span className="ml-2">Diabetes</span>
                      </label>
                      <br />
                      <label>
                        <Field
                          type="checkbox"
                          name="Epilepsy"
                          value={true}
                          checked={values.Epilepsy === true}
                        />
                        <span className="ml-2">Epilepsy</span>
                      </label>
                      <br />
                      <label>
                        <Field
                          type="checkbox"
                          name="Asthma"
                          value={true}
                          checked={values.Asthma === true}
                        />
                        <span className="ml-2">Asthma</span>
                      </label>
                      <br />
                      <label>
                        <Field
                          type="checkbox"
                          name="SpinaBifina"
                          value={true}
                          checked={values.SpinaBifina === true}
                        />
                        <span className="ml-2">Spina Bifida</span>
                      </label>
                      <br />
                      <label>
                        <Field
                          type="checkbox"
                          name="OtherMedical"
                          value={true}
                          checked={values.OtherMedical === true}
                        />
                        <span className="ml-2">Other</span>
                      </label>

                      {values.OtherMedical === true && (
                        <div className="mt-2">
                          <FormikController
                            control="textArea"
                            type="text"
                            name="otherDetails"
                            label="Please specificy your other needs"
                          />
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="">
                    <span className="font-medium">Special Dietary Needs</span>
                    <br />
                    <div className="border rounded p-3">
                      <label>
                        <Field
                          type="checkbox"
                          name="Diabetes"
                          value={true}
                          checked={values.dietDiebetes === true}
                        />
                        <span className="ml-2">Diabetes</span>
                      </label>
                      <br />
                      <label>
                        <Field
                          type="checkbox"
                          name="vegetarian"
                          value={true}
                          checked={values.vegetarian === true}
                        />
                        <span className="ml-2">Vegetarian</span>
                      </label>
                      <br />
                      <label>
                        <Field
                          type="checkbox"
                          name="vegan"
                          value={true}
                          checked={values.vegan === true}
                        />
                        <span className="ml-2">Vegan</span>
                      </label>
                      <br />
                      <label>
                        <Field
                          type="checkbox"
                          name="specialDiet"
                          value={true}
                          checked={values.specialDiet === true}
                        />
                        <span className="ml-2">Specialized</span>
                      </label>
                      <br />
                      {values.specialDiet === true && (
                        <div className="mt-2">
                          <FormikController
                            control="textArea"
                            type="text"
                            name="specialDietDetails"
                            label="Please specificy your other diet needs"
                          />
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="">
                    <span className="font-medium">Additional Information</span>
                    <br />
                    <div className="border rounded p-3">
                      <label>
                        <Field
                          type="checkbox"
                          name="smoker"
                          value={true}
                          checked={values.smoker === true}
                        />
                        <span className="ml-2">Smoker</span>
                      </label>
                      <br />
                      <label>
                        <Field
                          type="checkbox"
                          name="cat"
                          value={true}
                          checked={values.cat === true}
                        />
                        <span className="ml-2">Cat</span>
                      </label>
                      <br />
                      <label>
                        <Field
                          type="checkbox"
                          name="dog"
                          value={true}
                          checked={values.dog === true}
                        />
                        <span className="ml-2">Dog</span>
                      </label>
                      <br />
                    </div>
                  </div>

                  <div className="mt-2">
                    <FormikController
                      control="textArea"
                      type="text"
                      name="eatingDrinkingNeeds"
                      label="Eating | Drinking Needs (eg. has Dysphagia)"
                    />
                  </div>
                  <div className="mt-2">
                    <FormikController
                      control="textArea"
                      type="text"
                      name="specialistEquipment"
                      label="Specify any specialist equipment for moving & handling positioning"
                    />
                  </div>
                </div> */}
              </div>
              <div className="mb-3 mt-5 flex flex-row-reverse">
                {isLoadingProperty ? (
                  <PageLoader />
                ) : (
                  <SubmitButton label="Update" />
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default Property;
