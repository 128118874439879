import axios from "axios";
import { Navigate } from 'react-router-dom';
const BASE_URL = process.env.REACT_APP_ELMS_API_URL


export const addCarePlan = async (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = sessionStorage.getItem('access_token')
            const res = await axios.post(`${process.env.REACT_APP_ELMS_API_URL}care-plans`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            resolve(res.data);

        } catch (error) {
            if(error?.response?.data?.statusCode === 401){
                <Navigate replace to="/login"/>
             }
            
            reject(error)
        }
    })
}

export const getCarePlan = async (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = sessionStorage.getItem('access_token')
            const res = await axios.get(`${BASE_URL}care-plans?page=${data.page}&perPage=${data.perPage}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            resolve(res.data);

        } catch (error) {
            if(error?.response?.data?.statusCode === 404){
                return null
             }
             if(error?.response?.data?.statusCode === 401){
                <Navigate replace to="/login"/>
             }
            
            
             reject(error)
        }
    })
}

export const getClientMarcharts = async (data) => {   
   
    return new Promise(async (resolve, reject) => {
        if (data.clientId === 'undefined') {
        return []
        }
        try {
            const token = sessionStorage.getItem('access_token')
            const res = await axios.get(`${BASE_URL}client-care-plans/get/marcharts?code=${data.code}&clientId=${data.clientId}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
                   
            resolve(res.data);
console.log('MARSuccess',res);
        } catch (error) {
          
            if(error?.response?.data?.statusCode === 404){
                return null
             }

             if(error?.response?.data?.statusCode === 401){
                <Navigate replace to="/login"/>
             }
            
             reject(error)
             console.log('MARError',error);
        }
    })
}

export const updateCarePlan = async (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = sessionStorage.getItem('access_token')
            const res = await axios.patch(`${process.env.REACT_APP_ELMS_API_URL}care-plans/${data.id}`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            resolve(res.data);

        } catch (error) {
            if(error?.response?.data?.statusCode === 401){
                <Navigate replace to="/login"/>
             }
            
            reject(error)
        }
    })
}

export const getAssignedMarcharts = async (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = sessionStorage.getItem('access_token')
            const res = await axios.get(`${BASE_URL}client-care-plans/assigned/admin`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            
            resolve(res.data);

        } catch (error) {
          
            if(error?.response?.data?.statusCode === 404){
                return null
             }
             if(error?.response?.data?.statusCode === 401){
                <Navigate replace to="/login"/>
             }
            
            
             reject(error)
        }
    })
    
}

export const getMedication = async (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = sessionStorage.getItem('access_token')
            const res = await axios.get(`${process.env.REACT_APP_ELMS_API_URL}client-care-plans/administered/${data.id}?day=${data.day}&perPage=${data.month}&year=${data.year}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            resolve(res.data);

        } catch (error) {
            if(error?.response?.data?.statusCode === 404){
                return null
             }
            
             reject(error)
        }
    })
}

export const GetMedicationAudit = async (data) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = sessionStorage.getItem('access_token')
            const res = await axios.get(`${BASE_URL}medication-audit/${data.id}?page=${data.page}&perPage=${data.perPage}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
            resolve(res.data);

        } catch (error) {
            if(error?.response?.data?.statusCode === 404){
                return null
             }
             if(error?.response?.data?.statusCode === 401){
                <Navigate replace to="/login"/>
             }
            
            
             reject(error)
        }
    })
}