import {updateNextofkinPending, updateNextofkinSuccess, updateNextofkinFail} from '../RegisterCarerSlices/UpdateNextofkinSlice'
import {UpdateNextofkinDetails} from '../../api/carerApi'

 const UpdateNextofkinAction = (data) => async dispatch => {
    // console.log('git',data); 
    try {
        dispatch(updateNextofkinPending());
        const result = await UpdateNextofkinDetails(data);
        if(result){
            return dispatch(updateNextofkinSuccess(result))
        }
    } catch (error) {
        dispatch(updateNextofkinFail(error))
        console.log('ERRT',error); 
    }
} 

export default UpdateNextofkinAction