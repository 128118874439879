import React from 'react'
import { Sidebar } from '../../layouts/auth/Sidebar'
import { TopNavigation } from '../../layouts/auth/TopNavigation'
import Invoices from './Invoices'
import Timesheets from './Timesheets'

const Finance = () => {
  const [active, setActive] = React.useState('timesheets');

  let content;

  switch (active) {
    case 'invoices':
      content = <Invoices />
      break;
    case 'timesheets':
      content = <Timesheets />
      break;
    default:
      break;
  }
  return (
    <div><Sidebar /><TopNavigation title='Finance' />
      <div className='bg-white h-screen'>
        <ul className="flex flex-wrap -mb-px ml-80 pt-10">
        <li className="mr-2">
            <button onClick={() => setActive('timesheets')} className={`inline-block py-2 px-4 focus:outline-none rounded-t-lg border-b-2 ${active === 'timesheets' ? "border-secondary text-white font-medium dark:text-button dark:border-button bg-orange-500" : "border-transparent hover:text-button hover:border-button dark:hover:text-gray-300"}`}>Timesheets</button>
          </li>
          <li className="mr-2">
            <button onClick={() => setActive('invoices')} className={`inline-block py-2 px-4 focus:outline-none rounded-t-lg border-b-2 ${active === 'invoices' ? "border-secondary text-white font-medium dark:text-button dark:border-button bg-orange-500" : "border-transparent hover:text-button hover:border-button dark:hover:text-gray-300"}`}>Invoices</button>
          </li>
        </ul>
        <div className="">
          {content}
        </div>
      </div>
    </div>
  )
}

export default Finance