import React,{useState} from "react";
import { BsPlusLg } from "react-icons/bs";
import FormikController from "../../../components/Formik/FormikController";
import SubmitButton from "../../../components/Formik/SubmitButton";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Field } from "formik";
import { getStaff } from "../../staff/staffActions/staffAction";
import { useDispatch, useSelector } from "react-redux";
import addCarePlans from "../../clients/ClientActions/ClientCarePlanAction";
import addedPlans from "../../clients/ClientActions/GetAddedCarePlansAction";
import getMarCharts from "../../clients/ClientActions/GetMarChartsAction";

export default function SelectCarePlan({ care, client }) {

  const dispatch = useDispatch();
  const [showModal, setShowModal] = React.useState(false);
  const [req, setReq] = React.useState();

  const [text, setText] = useState('');

  const handleInputChange = (event) => {
    setText(event.target.value);
    calculateTextareaHeight(event); // Pass the event to calculateTextareaHeight
  };

  const calculateTextareaHeight = (event) => {
    const minHeight = 40;
    const maxHeight = 200;
    const currentHeight = Math.max(minHeight, Math.min(maxHeight, event.target.scrollHeight));
    event.target.style.height = `${currentHeight}px`;
  };
  const [background, setBackground] = useState('');

  const handleBackgroundChange = (event) => {
    setBackground(event.target.value);
    calculateBackgroundHeight(event); // Pass the event to calculateTextareaHeight
  };

  const calculateBackgroundHeight = (event) => {
    const minHeight = 40;
    const maxHeight = 200;
    const currentHeight = Math.max(minHeight, Math.min(maxHeight, event.target.scrollHeight));
    event.target.style.height = `${currentHeight}px`;
  };

  const [risk, setRisk] = useState('');

  const handleRiskChange = (event) => {
    setRisk(event.target.value);
    calculateRiskHeight(event); // Pass the event to calculateTextareaHeight
  };

  const calculateRiskHeight = (event) => {
    const minHeight = 40;
    const maxHeight = 200;
    const currentHeight = Math.max(minHeight, Math.min(maxHeight, event.target.scrollHeight));
    event.target.style.height = `${currentHeight}px`;
  };

  const [aim, setAim] = useState('');

  const handleAimChange = (event) => {
    setAim(event.target.value);
    calculateAimHeight(event); // Pass the event to calculateTextareaHeight
  };

  const calculateAimHeight = (event) => {
    const minHeight = 40;
    const maxHeight = 200;
    const currentHeight = Math.max(minHeight, Math.min(maxHeight, event.target.scrollHeight));
    event.target.style.height = `${currentHeight}px`;
  };

  const [objective, setObjective] = useState('');

  const handleObjectiveChange = (event) => {
    setObjective(event.target.value);
    calculateObjectiveHeight(event); // Pass the event to calculateTextareaHeight
  };

  const calculateObjectiveHeight = (event) => {
    const minHeight = 40;
    const maxHeight = 200;
    const currentHeight = Math.max(minHeight, Math.min(maxHeight, event.target.scrollHeight));
    event.target.style.height = `${currentHeight}px`;
  };

  const [outcome, setOutcome] = useState('');

  const handleOutcomeChange = (event) => {
    setOutcome(event.target.value);
    calculateOutcomeHeight(event); // Pass the event to calculateTextareaHeight
  };

  const calculateOutcomeHeight = (event) => {
    const minHeight = 40;
    const maxHeight = 200;
    const currentHeight = Math.max(minHeight, Math.min(maxHeight, event.target.scrollHeight));
    event.target.style.height = `${currentHeight}px`;
  };
  const { addClientPersonalDetails } = useSelector(
    (state) => state.addClientPersonal
  );
  const { addClientCarePlans, isLoadingAddCarePlan, errorAddCarePlan } =
    useSelector((state) => state.addClientCarePlan);
  const { addedCarePlans } = useSelector((state) => state.addedCarePlan);

  const { staff, isLoading } = useSelector((state) => state.getstaff);

  let admins =
    staff && staff.data
      ? staff.data.map((cxt) => {
          let obj = {
            key: cxt.firstname + " " + cxt.lastname,
            value: cxt.id,
          };
          return obj;
        })
      : [];

  const typeofMedication = [
    {
      key: "Tablet",
      value: "Tablet",
    },
    {
      key: "Capsules",
      value: "Capsules",
    },

    {
      key: "Drops",
      value: "Drops",
    },
    {
      key: "Inhalers",
      value: "Inhalers",
    },

    {
      key: "Suppositories",
      value: "Suppositories",
    },
    {
      key: "Liquid Oral Solutions",
      value: "Liquid Oral Solutions",
    },
    {
      key: "Liquid Oral Suspension",
      value: "Liquid Oral Suspension",
    },
    {
      key: "Implants or patches",
      value: "Implants",
    },
    {
      key: "Topical medicines / Creams",
      value: "Creams",
    },
  ];

  const AdministerMeds = [
    {
      key: "Oral",
      value: "Oral",
    },
    {
      key: "PEG Tube",
      value: "PEG Tube",
    },
    {
      key: "PEJ Tube",
      value: "PEJ Tube",
    },
    {
      key: "Topical",
      value: "Topical",
    },
    {
      key: "Inhalation",
      value: "Inhalation",
    },
    {
      key: "Instillation",
      value: "Instillation",
    },
    {
      key: "Injection",
      value: "Injection",
    },
    {
      key: "Transdermal Administration ",
      value: "Transdermal Administration ",
    },
  ];

  const itemsCountPerPage = 10;

  const [page, setPage] = React.useState(1);

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  React.useEffect(() => {
    dispatch(
      getMarCharts({
        clientId: addClientPersonalDetails ? addClientPersonalDetails.id : "",
      })
    );
    dispatch(
      getStaff({
        page,
        perPage: itemsCountPerPage,
      })
    );
  }, [addClientPersonalDetails, addClientCarePlans, addedCarePlans]);

  const handleOpen = (id) => {
    setShowModal(true);
    dispatch(
      addedPlans({
        id: care.id,
        clientId: addClientPersonalDetails.id,
      })
    );
  };

  const initialValues = {
    careplanId: care.id,
    background: background ? background : "",
    risks: risk ? risk : "",
    riskAssessment: "",
    aim: aim ? aim : "",
    objective: objective ? objective : "",
    outcome: outcome ? outcome : "",
    behaviourTime: "",
    nonCompliant: "",
    verballyAggressive: "",
    reputationOfBehaviour: "",
    otherBehaviour: "",
    behaviourComment: "",
    morningBowelTime: "",
    morningBowelType: "",
    morningBowelAmount: "",
    afternoonBowelTime: "",
    afternoonBowelType: "",
    afternoonBowelAmount: "",
    nightBowelTime: "",
    nightBowelType: "",
    nightBowelAmount: "",
    repositionLeftSideTime: "",
    repositionLeftSideSkinInspection: "",
    repositionBackTime: "",
    repositionBackSkinInspection: "",
    repositionRightSideTime: "",
    repositionRightSideSkinInspection: "",
    fluidIntakeTime: "",
    fluidIntake: "",
    fluidIntakeComments: "",
    foodIntakeTime: "",
    food: "",
    foodIntakeAmount: "",
    foodIntakeComments: "",
    prescriptions: "",
    medication: "",
    medicationType: "",
    administration: "",
    dosage: "",
    routine: text ? text : "",
    morning: false,
    lunch: false,
    evening: false,
    bed: false,
    alternateHours: false,
    alternateDays: false,
    weekly: false,
    prn: false,
    enableAdministeredQuantity: false,
    enableMedicationTime : false,
    notes: "",
    received: 0,
    dailyBalance: 0,
    weeklyBalance: 0,
    adminAdjustments: 0,
    intervals: 0,
    allergies: (addClientPersonalDetails ? addClientPersonalDetails.knownAllergies : "") ? (addClientPersonalDetails ? addClientPersonalDetails.knownAllergies : ""): (client ? client.knownAllergies : ""),
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
    continuous: false,
    start_time: "00:00",
    end_time: "00:00",
    startDate:"2010-09-02",
    adminId: "",
    maxperDay:0,
    nextReviewDate :"",
    gpReview : false,
    medicineReason : ''
  };

  const validationSchema = Yup.object({});

  let careplanId = care ? care.code : "";
  let clientCare =
    addedCarePlans && addedCarePlans.careplan ? addedCarePlans.careplan.id : "";
  let result = careplanId === clientCare;

  let cname = addClientPersonalDetails
  ? addClientPersonalDetails.firstname +
    addClientPersonalDetails.lastname
  : "";

  let clientname = client
  ? client.firstname +
    client.lastname
  : ""

  let cdob = addClientPersonalDetails
  ? addClientPersonalDetails.dob 
  : "";

  let clientdob = client
  ? client.dob 
  : ""

  const handleSubmit = (values) => {
    
    values.id = client ? client.id : addClientPersonalDetails.id;
    values.maxperDay = values.maxperDay.toString()
    console.log("CALIS", values);
    if (
      care.code === "CP06" &&
      !values.morning &&
      !values.lunch &&
      !values.evening &&
      !values.bed &&
      !values.alternateDays &&
      !values.alternateHours &&
      !values.weekly &&
      !values.prn
    ) {
      setReq("Please Select Administration Time");
    } else {
      dispatch(addCarePlans(values));
      setShowModal(false);
    }
  };


  const review = 
  [
 { key: "GP Review", value: "GP Review" },
 { key: "Date", value: "Date" },
   ];

  const Head = (props) => {
    return (
      <div className="text-left border-b">
        <label className="text-lg font-bold text-orange-900">
          {props.title}
        </label>
      </div>
    );
  };
  return (
    <>
      {
        <button
          className=" text-white bg-button border hover:bg-white inline-flex hover:text-gray-700 hover:border hover:border-gray-300 hover:underline decoration-secondary focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 mr-1 py-1 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          type="button"
          onClick={() => handleOpen(care.id)}
        >
          Add <BsPlusLg className="ml-1 mt-0.5 text-secondary" />
        </button>
      }
      {showModal ? (
        <>
          <div className="justify-center items-center overflow-x-auto overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-7xl">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-xl font-semibold text-gray-500">
                    {care.code} {care.title}
                  </h3>
                  <button
                    className="p-1 ml-10 bg-transparent border-0 text-button float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    ×
                  </button>
                </div>

                <div className="relative p-6 flex-auto">
                  <div className="relative p-3 flex-auto text-gray-700">
                    <Formik
                      initialValues={initialValues}
                      enableReinitialize
                      validationSchema={validationSchema}
                      onSubmit={(values, { resetForm }) => {
                        handleSubmit(values);
                        resetForm();
                      }}
                    >
                      {({ setFieldValue, values }) => (
                        <Form className="">
                          {care?.code !== "CP00" && care?.code !== "CP06" && (
                            <div>
                              <div className="">
                                <Head title="Care Plan" />
                              </div>
                              
                              <div className="grid grid-cols-3 gap-2 mt-2">
                                <div>
                                  <FormikController
                                    control="input"
                                    type="text"
                                    name="name"
                                    label="Client Name"
                                    value={
                                      client
                                        ? clientname
                                        : cname
                                    }
                                    disabled
                                  />
                                </div>
                                <div>
                                  <FormikController
                                    control="input"
                                    type="text"
                                    name="dob"
                                    label="Date of Birth"
                                    value={
                                      client
                                        ? clientdob
                                        : cdob
                                    }
                                    disabled
                                  />
                                </div>
                                <div>
                                  <FormikController
                                    control="input"
                                    type="text"
                                    name="dob"
                                    label="Date of next review"
                                    value={
                                      addClientPersonalDetails
                                        ? "Pending ..."
                                        : ""
                                    }
                                    disabled
                                  />
                                </div>
                               
                              </div>
                              <div className=" mt-2">
                               <div>
                                  <FormikController
                                    control="textArea"
                                    type="text"
                                    name="background"
                                    label="Background"
                                    value={background}
                                    onChange={handleBackgroundChange}
                                    
                                  />
                                </div>
                               </div>
                              <div className="grid grid-cols-2 gap-2 mt-2">
                              <div>
                                  <FormikController
                                    control="textArea"
                                    type="text"
                                    name="risks"
                                    label="Risks"
                                    value={risk}
                                    onChange={handleRiskChange}
                                  />
                                </div>
                                <div>
                                  <FormikController
                                    control="textArea"
                                    type="text"
                                    name="aim"
                                    label="Aim"
                                    value={aim}
                                    onChange={handleAimChange}
                                  />
                                </div>
                                <div>
                                  <FormikController
                                    control="textArea"
                                    type="text"
                                    name="objective"
                                    label="Objective"
                                    value={objective}
                                    onChange={handleObjectiveChange}
                                  />
                                </div>
                                <div>
                                  <FormikController
                                    control="textArea"
                                    type="text"
                                    name="outcome"
                                    label="Outcome"
                                    value={outcome}
                                    onChange={handleOutcomeChange}
                                  />
                                </div>
                              </div>
                            </div>
                          )}

                          {care.code === "CP06" && (
                            <div className="mb-5 font-bold">
                              <Head title="MAR Chart" />
                            </div>
                          )}

                          {care.code === "CP06" && (
                            <React.Fragment>
                              <div className="grid grid-cols-1 gap-2">
                                <div>
                                  <FormikController
                                    control="input"
                                    type="file"
                                    name="prescriptions"
                                    label="Upload Prescription Label"
                                    value={null}
                                    onChange={(event) =>
                                      setFieldValue(
                                        "prescriptions",
                                        event.target.files[0]
                                      )
                                    }
                                  />
                                </div>
                              </div>
                              <div className="mt-2">
                                <Head title="Medication Details" />
                              </div>
                              <div className="grid grid-cols-2 gap-2 mt-5">
                                <div>
                                  <FormikController
                                    control="input"
                                    type="text"
                                    name="medication"
                                    label="Medication Name"
                                  />
                                </div>
                                <div>
                                  <FormikController
                                    control="select"
                                    type="select"
                                    name="medicationType"
                                    label="Type of Medication"
                                    options={typeofMedication}
                                  />
                                </div>
                                <div>
                                  <FormikController
                                    control="select"
                                    type="select"
                                    name="administration"
                                    label="How is it Administered ?"
                                    options={AdministerMeds}
                                  />
                                </div>
                                <div>
                                  <FormikController
                                    control="input"
                                    type="text"
                                    name="dosage"
                                    label="Dosage"
                                  />
                                </div>
                              </div>
                              <div className="mt-2">
                                <FormikController
                                  control="textArea"
                                  type="text"
                                  name="allergies"
                                  label="Medication Allergies"
                                  disabled
                                />
                              </div>
                              <div className="mt-2">
                                <Head title="Time Schedule" />
                              </div>

                              <div className="grid grid-cols-1 gap-2 mt-2">
                                <div className="text-left">
                                  {req && (
                                    <div
                                      class="p-4 mb-4 text-sm text-center text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800"
                                      role="alert"
                                    >
                                      <span class="font-medium">{req}</span>
                                    </div>
                                  )}
                                  <span className="font-medium">Time</span>
                                  <br />
                                  <div className="border rounded p-3">
                                    {!values.alternateDays &&
                                      !values.weekly &&
                                      !values.alternateHours &&
                                      !values.prn && (
                                        <div>
                                          <label>
                                            <Field
                                              type="checkbox"
                                              name="morning"
                                              value={true}
                                              checked={values.morning === true}
                                            />
                                            <span className="ml-2">
                                              Morning
                                            </span>
                                          </label>
                                          <br />
                                          <label>
                                            <Field
                                              type="checkbox"
                                              name="lunch"
                                              value={true}
                                              checked={values.lunch === true}
                                            />
                                            <span className="ml-2">
                                              Lunch Time
                                            </span>
                                          </label>
                                          <br />
                                          <label>
                                            <Field
                                              type="checkbox"
                                              name="evening"
                                              value={true}
                                              checked={values.evening === true}
                                            />
                                            <span className="ml-2">
                                              Evening
                                            </span>
                                          </label>
                                          <br />
                                          <label>
                                            <Field
                                              type="checkbox"
                                              name="bed"
                                              value={true}
                                              checked={values.bed === true}
                                            />
                                            <span className="ml-2">
                                              Bed Time
                                            </span>
                                          </label>
                                        </div>
                                      )}
                                    {/* <br /> */}
                                    {!values.alternateDays &&
                                      !values.weekly &&
                                      !values.prn &&
                                      !values.morning &&
                                      !values.lunch &&
                                      !values.evening &&
                                      !values.bed && (
                                        <label>
                                          <Field
                                            type="checkbox"
                                            name="alternateHours"
                                            value={true}
                                            checked={
                                              values.alternateHours === true
                                            }
                                          />
                                          <span className="ml-2">
                                            Fixed Hours
                                          </span>
                                        </label>
                                        
                                      )}
                                    <br />
                                    {(values.morning ||
                                      values.lunch ||
                                      values.evening ||
                                      values.bed || values.prn) && <div className="flex justify-between bg-orange-50 p-5 border border-orange-300 rounded">
                                            
                         { !values.gpReview &&   <FormikController
                                    control="input"
                                    type="date"
                                    name="nextReviewDate"
                                    label="Review Date"
                                  />}
                                     <label className=" ">
                              <Field
                                type="checkbox"
                                name="gpReview"
                                value={true}
                                checked={values.gpReview === true}
                              />
                              <span className="ml-2">GP Review</span>
                            </label>
                         
                                     </div>}
                                 
                                    {values.alternateHours &&
                                      !values.alternateDays  && (
                                      <div className="border p-5 rounded bg-monday">
                                        {!values.continuous &&  <div className="grid grid-cols-3 gap-5 mb-5">
                                          <div>
                                          <FormikController
                                            control="input"
                                            type="text"
                                            name="intervals"
                                            label={"Number of Hours"}
                                          />
                                        </div>
                                        <div>
                                          <FormikController
                                            control="input"
                                            type="time"
                                            name="start_time"
                                            label={"Start Time"}
                                          />
                                        </div>
                                        <div>
                                          <FormikController
                                            control="input"
                                            type="time"
                                            name="end_time"
                                            label={"End Time"}
                                          />
                                        </div>
                                        </div>}
                                        <label className="mt-5">
                                          <Field
                                            type="checkbox"
                                            name="continuous"
                                            value={true}
                                            checked={
                                              values.continuous === true
                                            }
                                          />
                                          <span className="ml-2 font-bold text-button text-lg bg-white p-2 rounded border border-button">
                                            Continuous Hours for Alternate Days
                                          </span>
                                        </label>
                                        {values.continuous && <div>
                                     <div className="grid grid-cols-2 gap-5 mt-5">
                                     <div>
                                          <FormikController
                                            control="input"
                                            type="date"
                                            name="startDate"
                                            label={"Start Date"}
                                          />
                                        </div>
                                          <div>
                                          <FormikController
                                            control="input"
                                            type="text"
                                            name="intervals"
                                            label={"Number of Hours"}
                                          />
                                        </div> 
                                        
                                     </div>
<div className="border p-5 rounded">
  <div className="font-medium">Please Select Administration Time</div>
<label className="">
                                            <Field
                                              type="checkbox"
                                              name="morning"
                                              value={true}
                                              checked={values.morning === true}
                                            />
                                            <span className="ml-2">
                                              Morning
                                            </span>
                                          </label>
                                          <br />
                                          <label>
                                            <Field
                                              type="checkbox"
                                              name="lunch"
                                              value={true}
                                              checked={values.lunch === true}
                                            />
                                            <span className="ml-2">
                                              Lunch Time
                                            </span>
                                          </label>
                                          <br />
                                          <label>
                                            <Field
                                              type="checkbox"
                                              name="evening"
                                              value={true}
                                              checked={values.evening === true}
                                            />
                                            <span className="ml-2">
                                              Evening
                                            </span>
                                          </label>
                                          <br />
                                          <label>
                                            <Field
                                              type="checkbox"
                                              name="bed"
                                              value={true}
                                              checked={values.bed === true}
                                            />
                                            <span className="ml-2">
                                              Bed Time
                                            </span>
                                          </label>
</div>
                                      </div>}
                                      </div>
                                      )}
                                    {!values.weekly &&
                                      !values.alternateHours &&
                                      !values.prn && (
                                        <label>
                                          <Field
                                            type="checkbox"
                                            name="alternateDays"
                                            value={true}
                                            checked={
                                              values.alternateDays === true
                                            }
                                          />
                                          <span className="ml-2">
                                            Alternate Days{" "}
                                          </span>
                                        </label>
                                      )}
                                    {/* <hr className=""/> */}
                                    {values.alternateDays &&
                                      !values.alternateHours && (
                                        <div className="space-x-10 mt-4 border rounded bg-gray-50 p-5">
                                          <label>
                                            <Field
                                              type="checkbox"
                                              name="monday"
                                              value={true}
                                              checked={values.monday === true}
                                            />
                                            <span className="ml-2 border p-1 pb-2 px-5 rounded bg-monday ">
                                              Monday
                                            </span>
                                          </label>
                                          <label>
                                            <Field
                                              type="checkbox"
                                              name="tuesday"
                                              value={true}
                                              checked={values.tuesday === true}
                                            />
                                            <span className="ml-2 border p-1 pb-2 px-5 rounded bg-days ">
                                              Tuesday
                                            </span>
                                          </label>
                                          <label>
                                            <Field
                                              type="checkbox"
                                              name="wednesday"
                                              value={true}
                                              checked={
                                                values.wednesday === true
                                              }
                                            />
                                            <span className="ml-2 border p-1 pb-2 px-5 rounded bg-wednesday ">
                                              Wednesday
                                            </span>
                                          </label>
                                          <label>
                                            <Field
                                              type="checkbox"
                                              name="thursday"
                                              value={true}
                                              checked={values.thursday === true}
                                            />
                                            <span className="ml-2 border p-1 pb-2 px-5 rounded bg-thursday ">
                                              Thursday
                                            </span>
                                          </label>
                                          <label>
                                            <Field
                                              type="checkbox"
                                              name="friday"
                                              value={true}
                                              checked={values.friday === true}
                                            />
                                            <span className="ml-2 border p-1 pb-2 px-5 rounded bg-friday ">
                                              Friday
                                            </span>
                                          </label>
                                          <label>
                                            <Field
                                              type="checkbox"
                                              name="saturday"
                                              value={true}
                                              checked={values.saturday === true}
                                            />
                                            <span className="ml-2 border p-1 pb-2 px-5 rounded bg-saturday ">
                                              Saturday
                                            </span>
                                          </label>
                                          <label>
                                            <Field
                                              type="checkbox"
                                              name="sunday"
                                              value={true}
                                              checked={values.sunday === true}
                                            />
                                            <span className="ml-2 border p-1 pb-2 px-5 rounded bg-sunday ">
                                              Sunday
                                            </span>
                                          </label>
                                        </div>
                                      )}
                                    <br />
                                    {!values.alternateDays &&
                                      !values.alternateHours &&
                                      !values.prn && (
                                        <label>
                                          <Field
                                            type="checkbox"
                                            name="weekly"
                                            value={true}
                                            checked={values.weekly === true}
                                          />
                                          <span className="ml-2">Weekly</span>
                                        </label>
                                      )}
                                    {(values.monday ||
                                      values.tuesday ||
                                      values.wednesday ||
                                      values.thursday ||
                                      values.friday ||
                                      values.saturday ||
                                      (values.sunday &&
                                        values.alternateDays)) && (
                                      <div className="font-medium">
                                        Please Select Administration Time
                                      </div>
                                    )}
                                    <div className="">
                                      {(values.monday ||
                                        values.tuesday ||
                                        values.wednesday ||
                                        values.thursday ||
                                        values.friday ||
                                        values.saturday ||
                                        (values.sunday &&
                                          (!values.alternateDays || values.alternateDays))) && (
                                        <div className="space-x-10 border bg-monday p-4 ">
                                          {/* <div className="pb-2 font-bold ml-10">MONDAYhh</div> */}
                                          <label className="ml-10">
                                            <Field
                                              type="checkbox"
                                              name="morning"
                                              value={true}
                                              checked={values.morning === true}
                                            />
                                            <span className="ml-2">
                                              Morning
                                            </span>
                                          </label>
                                          <br />
                                          <label>
                                            <Field
                                              type="checkbox"
                                              name="lunch"
                                              value={true}
                                              checked={values.lunch === true}
                                            />
                                            <span className="ml-2">
                                              Lunch Time
                                            </span>
                                          </label>
                                          <br />
                                          <label>
                                            <Field
                                              type="checkbox"
                                              name="evening"
                                              value={true}
                                              checked={values.evening === true}
                                            />
                                            <span className="ml-2">
                                              Evening
                                            </span>
                                          </label>
                                          <br />
                                          <label>
                                            <Field
                                              type="checkbox"
                                              name="bed"
                                              value={true}
                                              checked={values.bed === true}
                                            />
                                            <span className="ml-2">
                                              Bed Time
                                            </span>
                                          </label>
                                          <br />
                                        </div>
                                      )}
                                    </div>

                                    {/* <br /> */}
                                    {values.weekly &&
                                      !values.alternateHours && (
                                        <div className="space-x-10 mt-4 border p-4">
                                       {(!values.tuesday && !values.wednesday && !values.thursday && !values.friday && !values.saturday && !values.sunday) && <label>
                                            <Field
                                              type="checkbox"
                                              name="monday"
                                              value={true}
                                              checked={values.monday === true}
                                            />
                                            <span className="ml-2 border p-1 pb-2 px-5 rounded bg-monday ">
                                              Monday
                                            </span>
                                          </label>}
                                     {(!values.monday && !values.wednesday && !values.thursday && !values.friday && !values.saturday && !values.sunday) &&   <label>
                                            <Field
                                              type="checkbox"
                                              name="tuesday"
                                              value={true}
                                              checked={values.tuesday === true}
                                            />
                                            <span className="ml-2 border p-1 pb-2 px-5 rounded bg-days ">
                                              Tuesday
                                            </span>
                                          </label>}
                                       {(!values.tuesday && !values.monday && !values.thursday && !values.friday && !values.saturday && !values.sunday) &&   <label>
                                            <Field
                                              type="checkbox"
                                              name="wednesday"
                                              value={true}
                                              checked={
                                                values.wednesday === true
                                              }
                                            />
                                            <span className="ml-2 border p-1 pb-2 px-5 rounded bg-wednesday ">
                                              Wednesday
                                            </span>
                                          </label>}
                                       { (!values.tuesday && !values.wednesday && !values.monday && !values.friday && !values.saturday && !values.sunday) &&  <label>
                                            <Field
                                              type="checkbox"
                                              name="thursday"
                                              value={true}
                                              checked={values.thursday === true}
                                            />
                                            <span className="ml-2 border p-1 pb-2 px-5 rounded bg-thursday ">
                                              Thursday
                                            </span>
                                          </label>}
                                     { (!values.tuesday && !values.wednesday && !values.thursday && !values.monday && !values.saturday && !values.sunday) &&    <label>
                                            <Field
                                              type="checkbox"
                                              name="friday"
                                              value={true}
                                              checked={values.friday === true}
                                            />
                                            <span className="ml-2 border p-1 pb-2 px-5 rounded bg-friday ">
                                              Friday
                                            </span>
                                          </label>}
                                   { (!values.tuesday && !values.wednesday && !values.thursday && !values.friday && !values.monday && !values.sunday) &&      <label>
                                            <Field
                                              type="checkbox"
                                              name="saturday"
                                              value={true}
                                              checked={values.saturday === true}
                                            />
                                            <span className="ml-2 border p-1 pb-2 px-5 rounded bg-saturday ">
                                              Saturday
                                            </span>
                                          </label>}
                                      { (!values.tuesday && !values.wednesday && !values.thursday && !values.friday && !values.saturday && !values.monday) &&   <label>
                                            <Field
                                              type="checkbox"
                                              name="sunday"
                                              value={true}
                                              checked={values.sunday === true}
                                            />
                                            <span className="ml-2 border p-1 pb-2 px-5 rounded bg-sunday ">
                                              Sunday
                                            </span>
                                          </label>}
                                        </div>
                                      )}
                                    {/* {(values.monday || values.tuesday || values.wednesday || values.thursday || values.friday || values.saturday || values.sunday && values.weekly && !values.alternateDays ) &&
                                      (
                                        <div className="space-x-10 border bg-monday p-4 ">
                                          <div className="pb-2 font-bold ml-10">Week</div>
                                              <label className="ml-10">
                                      <Field
                                        type="checkbox"
                                        name="morning"
                                        value={true}
                                        checked={values.morning === true}
                                      />
                                      <span className="ml-2">Morning</span>
                                    </label>
                                    <br />
                                    <label >
                                      <Field
                                        type="checkbox"
                                        name="lunch"
                                        value={true}
                                        checked={values.lunch === true}
                                      />
                                      <span className="ml-2">Lunch Time</span>
                                    </label>
                                    <br />
                                    <label>
                                      <Field
                                        type="checkbox"
                                        name="evening"
                                        value={true}
                                        checked={values.evening === true}
                                      />
                                      <span className="ml-2">Evening</span>
                                    </label>
                                    <br />
                                    <label>
                                      <Field
                                        type="checkbox"
                                        name="bed"
                                        value={true}
                                        checked={values.bed === true}
                                      />
                                      <span className="ml-2">Bed Time</span>
                                    </label>
                                    <br />
                                        </div>
                                      )} */}
                                      {values.prn && 
                                      <div className="grid grid-cols-3 gap-2">
                                         <FormikController
                                    control="input"
                                    type="text"
                                    name="maxperDay"
                                    label="Maximum dose in 24 Hours"
                                  />
                                      <FormikController
                                    control="input"
                                    type="text"
                                    name="intervals"
                                    label="Minimum intervals between doses"
                                  />
                                   <FormikController
                                    control="input"
                                    type="text"
                                    name="medicineReason"
                                    label="Reason for Medicine"
                                  />
                                        </div>}
                             
                                        
                                    {!values.alternateDays &&
                                      !values.alternateHours &&
                                      !values.weekly &&
                                      !values.morning &&
                                      !values.lunch &&
                                      !values.evening &&
                                      !values.bed && (
                                        <label>
                                          <Field
                                            type="checkbox"
                                            name="prn"
                                            value={true}
                                            checked={values.prn === true}
                                          />
                                          <span className="ml-2">
                                            PRN (As needed)
                                          </span>
                                        </label>
                                      )}
                                    <br />
                                    <label>
                                      <Field
                                        type="checkbox"
                                        name="enableAdministeredQuantity"
                                        value={true}
                                        checked={
                                          values.enableAdministeredQuantity ===
                                          true
                                        }
                                      />
                                      <span className="ml-2">
                                        Enable Care Worker to enter medication
                                        quantity they administered
                                      </span>
                                    </label>
                                    <br />
                                    {/* <label>
                                      <Field
                                        type="checkbox"
                                        name="enableMedicationTime"
                                        value={true}
                                        checked={values.enableMedicationTime === true}
                                      />
                                      <span className="ml-2">
                                        Disable Administration Time
                                      </span>
                                    </label> */}
                                  </div>
                                </div>
                              </div>
                              <div className="mt-3">
                                <Head title="Administration Notes" />
                              </div>
                              <div className="grid grid-cols-1 gap-2 mt-2">
                                <div>
                                  <FormikController
                                    control="textArea"
                                    type="text"
                                    name="notes"
                                    label="Notes"
                                  />
                                </div>
                              </div>

                              {/* <div className="mt-3">
                                <Head title="Medication Audit" />
                              </div>
                              <div className="grid grid-cols-4 gap-2 mt-2">
                                <div>
                                  <FormikController
                                    control="input"
                                    type="number"
                                    name="received"
                                    label="Received"
                                  />
                                </div>
                                <div>
                                  <FormikController
                                    control="input"
                                    type="number"
                                    name="dailyBalance"
                                    label="Balance"
                                  />
                                </div>
                                <div>
                                  <FormikController
                                    control="input"
                                    type="number"
                                    name="weeklyBalance"
                                    label="Weekly Balance"
                                  />
                                </div>
                                <div>
                                  <FormikController
                                    control="input"
                                    type="number"
                                    name="adminAdjustments"
                                    label="Admin Adjustments"
                                  />
                                </div>
                              </div> */}
                              <div className="mt-4">
                                <Head title="MAR Chart Verification" />
                              </div>
                              <div className="grid grid-cols-1 gap-2">
                                <div>
                                  <FormikController
                                    control="select"
                                    type="select"
                                    name="verify"
                                    label="Select Administrator to Verify MAR Chart*"
                                    options={admins}
                                  />
                                </div>
                              </div>
                            </React.Fragment>
                          )}

                          {/* {addClientCarePlans && care.code === 'CP06' && <div className="text-left py-5">
                                                        <button className='border rounded p-5 mr-3 border-green text-white bg-green font-medium' type="button" onClick={addMarChart}>
                                                            Add MAR Chart
                                                        </button>
                                                        <button className='border rounded p-5 border-gray-300 bg-gray-500 text-white font-medium' type="button" onClick={clearMarCharts}>
                                                            Clear Mar Charts
                                                        </button>
                                                    </div>} */}

                          {care.code === "CP00" && (
                            <div className="grid grid-cols-1 gap-2 mt-2">
                              <div>
                                <FormikController
                                  control="textArea"
                                  type="text"
                                  name="routine"
                                  label="Routine"
                                  value={text}
                                  onChange={handleInputChange}
                                />
                              </div>
                            </div>
                          )}

                          {care.code === "CP03" && (
                            <div>
                              <div className="mt-5 mb-5">
                                <Head title="Fluid Intake Log" />
                              </div>
                              <div className="grid grid-cols-2 gap-2 mt-2">
                                <div>
                                  <FormikController
                                    control="input"
                                    type="time"
                                    name="fluidIntakeTime"
                                    label="Time"
                                  />
                                </div>
                                <div>
                                  <FormikController
                                    control="input"
                                    type="text"
                                    name="fluidIntake"
                                    label="Intake"
                                  />
                                </div>
                              </div>
                              <div className="grid grid-cols-1 gap-2 mt-2">
                                <div>
                                  <FormikController
                                    control="textArea"
                                    type="text"
                                    name="fluidIntakeComments"
                                    label="Comments"
                                  />
                                </div>
                              </div>

                              <div className="mt-5 mb-5">
                                <Head title="Food Intake Log" />
                              </div>
                              <div className="grid grid-cols-3 gap-2 mt-2">
                                <div>
                                  <FormikController
                                    control="input"
                                    type="time"
                                    name="foodIntakeTime"
                                    label="Time"
                                  />
                                </div>
                                <div>
                                  <FormikController
                                    control="input"
                                    type="text"
                                    name="food"
                                    label="Food"
                                  />
                                </div>
                                <div>
                                  <FormikController
                                    control="input"
                                    type="text"
                                    name="foodIntakeAmount"
                                    label="Amount"
                                  />
                                </div>
                              </div>
                              <div className="grid grid-cols-1 gap-2 mt-2">
                                <div>
                                  <FormikController
                                    control="textArea"
                                    type="text"
                                    name="foodIntakeComments"
                                    label="Comments"
                                  />
                                </div>
                              </div>
                            </div>
                          )}

                          {care.code === "CP05" && (
                            <div>
                              <div className="mt-5 mb-5">
                                <Head title="Behaviour Chart" />
                              </div>
                              <div className="grid grid-cols-1 gap-2 mt-2">
                                <div>
                                  <FormikController
                                    control="input"
                                    type="time"
                                    name="behaviourTime"
                                    label="Time"
                                  />
                                </div>
                              </div>
                              <div className="grid grid-cols-1 gap-2 mt-2">
                                <div className="text-left">
                                  <span className="font-medium">
                                    Does the person have these behaviours
                                  </span>
                                  <br />
                                  <div className="border rounded p-3">
                                    <label>
                                      <Field
                                        type="checkbox"
                                        name="nonCompliant"
                                        value={true}
                                        checked={values.nonCompliant === true}
                                      />
                                      <span className="ml-2">
                                        Non-Compliant
                                      </span>
                                    </label>
                                    <br />
                                    <label>
                                      <Field
                                        type="checkbox"
                                        name="verballyAggressive"
                                        value={true}
                                        checked={
                                          values.verballyAggressive === true
                                        }
                                      />
                                      <span className="ml-2">
                                        Verbally Aggressive
                                      </span>
                                    </label>
                                    <br />
                                    <label>
                                      <Field
                                        type="checkbox"
                                        name="reputationOfBehaviour"
                                        value={true}
                                        checked={
                                          values.reputationOfBehaviour === true
                                        }
                                      />
                                      <span className="ml-2">
                                        Reputation of Activity
                                      </span>
                                    </label>
                                    <br />
                                    <label>
                                      <Field
                                        type="checkbox"
                                        name="otherBehaviour"
                                        value={true}
                                        checked={values.otherBehaviour === true}
                                      />
                                      <span className="ml-2">Other</span>
                                    </label>

                                    {values.otherBehaviour === true && (
                                      <div className="mt-2">
                                        <FormikController
                                          control="textArea"
                                          type="text"
                                          name="behaviourComment"
                                          label="Please specificy your other behaviours"
                                        />
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}

                          {care.code === "CP14" && (
                            <div>
                              <div className="mt-5 mb-5">
                                <Head title="Bowel Movement Monthly Monitoring Record" />
                              </div>
                              <div className="text-left">
                                <Head title="Morning" />
                              </div>
                              <div className="grid grid-cols-3 gap-2 mt-2">
                                <div>
                                  <FormikController
                                    control="input"
                                    type="time"
                                    name="morningBowelTime"
                                    label="Time"
                                  />
                                </div>
                                <div>
                                  <FormikController
                                    control="input"
                                    type="text"
                                    name="morningBowelType"
                                    label="Type"
                                  />
                                </div>
                                <div>
                                  <FormikController
                                    control="input"
                                    type="text"
                                    name="morningBowelAmount"
                                    label="Amount"
                                  />
                                </div>
                              </div>

                              <div className="text-left mt-5">
                                <Head title="Afternoon" />
                              </div>
                              <div className="grid grid-cols-3 gap-2 mt-2">
                                <div>
                                  <FormikController
                                    control="input"
                                    type="time"
                                    name="afternoonBowelTime"
                                    label="Time"
                                  />
                                </div>
                                <div>
                                  <FormikController
                                    control="input"
                                    type="text"
                                    name="afternoonBowelType"
                                    label="Type"
                                  />
                                </div>
                                <div>
                                  <FormikController
                                    control="input"
                                    type="text"
                                    name="afternoonBowelAmount"
                                    label="Amount"
                                  />
                                </div>
                              </div>

                              <div className="text-left mt-5">
                                <Head title="Night" />
                              </div>
                              <div className="grid grid-cols-3 gap-2 mt-2">
                                <div>
                                  <FormikController
                                    control="input"
                                    type="time"
                                    name="nightBowelTime"
                                    label="Time"
                                  />
                                </div>
                                <div>
                                  <FormikController
                                    control="input"
                                    type="text"
                                    name="nightBowelType"
                                    label="Type"
                                  />
                                </div>
                                <div>
                                  <FormikController
                                    control="input"
                                    type="text"
                                    name="nightBowelAmount"
                                    label="Amount"
                                  />
                                </div>
                              </div>
                            </div>
                          )}

                          {care.code === "CP09" && (
                            <div>
                              <div className="text-left mt-5 mb-5">
                                <Head title="Repositioning & Skin Inspection Log" />
                              </div>
                              <div className="grid grid-cols-1 gap-2 mt-2">
                                <div className="text-left">
                                  <span className="font-medium">
                                    Does the person have these needs
                                  </span>
                                  <br />
                                  <div className="border rounded p-3">
                                    <label>
                                      <Field
                                        type="checkbox"
                                        name="repositionLeftSideSkinInspection"
                                        value={true}
                                        checked={values.repositionLeftSideSkinInspection === true}
                                      />
                                      <span className="ml-2">Left Side</span>
                                    </label>
                                    <br />
                                    <label>
                                      <Field
                                        type="checkbox"
                                        name="repositionBackSkinInspection"
                                        value={true}
                                        checked={values.repositionBackSkinInspection === true}
                                      />
                                      <span className="ml-2">Back</span>
                                    </label>
                                    <br />
                                    <label>
                                      <Field
                                        type="checkbox"
                                        name="repositionRightSideSkinInspection"
                                        value={true}
                                        checked={values.repositionRightSideSkinInspection === true}
                                      />
                                      <span className="ml-2">Right Side</span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                              {(values.repositionLeftSideSkinInspection || values.repositionRightSideSkinInspection || values.repositionBackSkinInspection) && (
                                <div>
                                  <FormikController
                                    control="textArea"
                                    type="text"
                                    name="repositionRightSideTime"
                                    label="Skin Inspection Comments"
                                  />
                                </div>
                              )}
                            </div>
                          )}

                          <div className="mb-3 mt-5 flex flex-row-reverse">
                            <div></div>
                            <SubmitButton label="Submit" />
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
