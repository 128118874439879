import {editRiskAssessmentPending, editRiskAssessmentSuccess, editRiskAssessmentFail} from '../ClientSlices/EditRiskAssessmentSlice'
import {updateRiskAssess} from '../../../api/clientApi'

 const editRiskAssessments = (data) => async dispatch => {

    try {
        dispatch(editRiskAssessmentPending());
        const result = await updateRiskAssess(data);
        if(result){
            return dispatch(editRiskAssessmentSuccess(result))
        }
    } catch (error) {
        dispatch(editRiskAssessmentFail(error))
    }
} 

export default editRiskAssessments