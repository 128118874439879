import {signUpPending, signUpSuccess, signUpFail} from '../RegisterCarerSlices/SignUpSlice'
import {signUpCarer} from '../../api/carerApi'

 const signUpAction = (data) => async dispatch => {

    try {
        dispatch(signUpPending());
        const result = await signUpCarer(data);
        if(result){
            return dispatch(signUpSuccess(result))
        }
    } catch (error) {
        dispatch(signUpFail(error))
    }
} 

export default signUpAction