import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  updateNextofkin: {},
  isLoadingupdateNextofkin: false,
  errorupdateNextofkin: "",
};

const updateNextofkinSlice = createSlice({
  name: "updateNextofkin",
  initialState,
  reducers: {
    updateNextofkinPending: (state) => {
      state.isLoadingupdateNextofkin = true;
    },
    updateNextofkinSuccess: (state, { payload }) => {
      state.isLoadingupdateNextofkin = false;
      state.updateNextofkin = payload;
      state.errorupdateNextofkin = "";
    },
    updateNextofkinFail: (state, { payload }) => {
      state.isLoadingupdateNextofkin = false;
      state.errorupdateNextofkin = payload;
    },
  },
});

export const { updateNextofkinPending, updateNextofkinSuccess, updateNextofkinFail } =
  updateNextofkinSlice.actions;

export default updateNextofkinSlice.reducer;
