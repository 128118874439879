import {editPropertyPending, editPropertySuccess, editPropertyFail} from '../ClientSlices/EditPropertySlice'
import {updateProperty} from '../../../api/clientApi'

 const editClientProperty = (data) => async dispatch => {

    try {
        dispatch(editPropertyPending());
        const result = await updateProperty(data);
        if(result){
            return dispatch(editPropertySuccess(result))
        }
    } catch (error) {
        dispatch(editPropertyFail(error))
    }
} 

export default editClientProperty