import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import getInvoiceList from '../Actions/GetInvoicesAction';
import Pagination from 'react-js-pagination';
import EditInvoice from '../EditInvoice';
import PageLoader from "../../../components/PageLoader";


const Pending = () => {
    const dispatch = useDispatch();
    const {getInvoices, isLoadingGetInvoices} = useSelector(state => state.getInvoiceList);
   
    const itemsCountPerPage = 10;
    const [page, setPage] = React.useState(1)
   
    const handlePageChange = (pageNumber)=>{
       setPage(pageNumber);
    }
   
    React.useEffect(() =>{
       dispatch(getInvoiceList({
           page,
           perPage: itemsCountPerPage,
        //    invoicestatus: 'PENDING'
       }))
    }, [page])
   
    const handleDate = (date) => {
       let format = new Date(date);
       return ("0"+format.getDate()).slice(-2)+'/'+("0"+(format.getMonth()+1)).slice(-2)+'/'+format.getFullYear()+' '+format.getHours()+':'+format.getMinutes();
   }


   

    return (
        <div className='ml-72 mr-8 mt-10'>
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
              {isLoadingGetInvoices ? <PageLoader/>  : <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" className="px-6 py-3">
                                Name
                            </th>
                            <th scope="col" className="px-3 py-3">
                                Service Contract No.
                            </th>
                            <th scope="col" className="px-3 py-3">
                                Start Date
                            </th>
                            <th scope="col" className="px-3 py-3">
                                End Date
                            </th>                       
                            <th scope="col" className="px-3 py-3">
                                Weekly Hours
                            </th>
                            <th scope="col" className="px-3 py-3">
                                Weekly Days
                            </th>
                            <th scope="col" className="px-3 py-3">
                                Package Rate
                            </th>
                            <th scope="col" className="px-3 py-3">
                                Daily Package
                            </th>
                            <th scope="col" className="px-3 py-3">
                                Weekly Package
                            </th>
                            <th scope="col" className="px-3 py-3">
                                Total Invoice
                            </th>
                            <th scope="col" className="px-3 py-3">
                                <span className="sr-only">Edit</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {getInvoices && getInvoices.data && getInvoices.data.length > 0 ? getInvoices.data.map((invoice) =>(
                            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-primary dark:hover:bg-gray-600">
                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap">
                               {invoice.firstname} {invoice.middlename} {invoice.lastname}
                            </th>
                            <td className="px-3 py-4">
                               {invoice.contractNumber}
                            </td>
                            <td className="px-3 py-4">
                                {handleDate(invoice.startdate)}
                            </td>
                            <td className="px-3 py-4">
                            {handleDate(invoice.enddate)}
                            </td>                         
                            <td className="px-3 py-4">
                               {invoice.ContractWeeklyHours}
                            </td>
                            <td className="px-3 py-4">
                               {invoice.ContractWeeklyDays}
                            </td>
                            <td className="px-3 py-4">
                                {invoice.packagerate}
                            </td>
                            <td className="px-3 py-4">
                                {invoice.contractDailyRate}
                            </td>
                            <td className="px-3 py-4">
                                {invoice.contractWeeklyRate}
                            </td>
                            <td className="px-3 py-4">
                                {invoice.invoice}
                            </td>
                            <td className="px-6 py-4 text-right">
                                <a href="#" className="font-medium text-secondary dark:text-blue-500 hover:underline"><EditInvoice client={invoice} /></a>
                            </td>
                            </tr>
                        )) :  <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                        <td colSpan="12" className="text-center px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap border rounded bg-red-100">No Pending Invoices</td>
                    </tr>}
                       
                       
                      
                    </tbody>
                </table>}
                <div className=''>          
            <div>
                {(getInvoices && getInvoices.data && getInvoices.data.length > 0 && !isLoadingGetInvoices) &&
                    <div className="flex justify-end">
                        <Pagination
                            activePage={page}
                            itemsCountPerPage={itemsCountPerPage}
                            totalItemsCount={getInvoices.totalItemsCount}
                            pageRangeDisplayed={1}
                            onChange={handlePageChange}
                            itemclassName="page-item"
                            linkclassName="page-link"
                        />
                    </div>}
            </div>
        </div>
            </div>
        </div>
    )
}

export default Pending