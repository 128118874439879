import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    editProperty: {},
    isLoadingProperty: false,
    errorEditProperty: ''
}

const editPropertySlice = createSlice({
    name: 'editProperty',
    initialState,
    reducers:{
        editPropertyPending: (state) => {
            state.isLoadingProperty = true;
        },
        editPropertySuccess: (state, {payload}) => {
            state.isLoadingProperty = false;
            state.editProperty = payload;
            state.errorEditProperty = '';
        },
        editPropertyFail: (state, {payload}) => {
            state.isLoadingProperty = false;
            state.errorEditProperty = payload;
        }
    }
})

export const {editPropertyPending, editPropertySuccess, editPropertyFail} = editPropertySlice.actions

export default editPropertySlice.reducer