import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  GetDocuments: {},
  isLoadingGetDocuments: false,
  errorGetDocuments: "",
};

const GetDocumentsSlice = createSlice({
  name: "GetDocuments",
  initialState,
  reducers: {
    GetDocumentsPending: (state) => {
      state.isLoadingGetDocuments = true;
    },
    GetDocumentsSuccess: (state, { payload }) => {
      state.isLoadingGetDocuments = false;
      state.GetDocuments = payload;
      state.errorGetDocuments = "";
    },
    GetDocumentsFail: (state, { payload }) => {
      state.isLoadingGetDocuments = false;
      state.errorGetDocuments = payload;
    },
  },
});

export const { GetDocumentsPending, GetDocumentsSuccess, GetDocumentsFail } =
  GetDocumentsSlice.actions;

export default GetDocumentsSlice.reducer;
