import {getUserPending, getUserSuccess, getUserFail} from './userSlice'
import {fetchUser} from '../../api/userApi'

export const getUserProfile = () => async dispatch => {

    try {
        dispatch(getUserPending());
        const result = await fetchUser();

        if(result){
            return dispatch(getUserSuccess(result))
        }
    } catch (error) {
        dispatch(getUserFail(error))
    }
}