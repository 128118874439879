import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  EditMovingCert: {},
  isLoadingEditMovingCert: false,
  errorEditMovingCert: "",
};

const EditMovingCertSlice = createSlice({
  name: "EditMovingCert",
  initialState,
  reducers: {
    EditMovingCertPending: (state) => {
      state.isLoadingEditMovingCert = true;
    },
    EditMovingCertSuccess: (state, { payload }) => {
      state.isLoadingEditMovingCert = false;
      state.EditMovingCert = payload;
      state.errorEditMovingCert = "";
    },
    EditMovingCertFail: (state, { payload }) => {
      state.isLoadingEditMovingCert = false;
      state.errorEditMovingCert = payload;
    },
  },
});

export const { EditMovingCertPending, EditMovingCertSuccess, EditMovingCertFail } =
  EditMovingCertSlice.actions;

export default EditMovingCertSlice.reducer;
