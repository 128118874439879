import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    updateClientStatus: {},
    isLoadingUpdateStatus: false,
    errorUpdateStatus: ''
}

const updateStatusSlice = createSlice({
    name: 'updateClientStatus',
    initialState,
    reducers:{
        updateStatusPending: (state) => {
            state.isLoadingUpdateStatus = true;
        },
        updateStatusSuccess: (state, {payload}) => {
            state.isLoadingUpdateStatus = false;
            state.updateClientStatus = payload;
            state.errorUpdateStatus = '';
        },
        updateStatusFail: (state, {payload}) => {
            state.isLoadingUpdateStatus = false;
            state.errorUpdateStatus = payload;
        }
    }
})

export const {updateStatusPending, updateStatusSuccess, updateStatusFail} = updateStatusSlice.actions

export default updateStatusSlice.reducer