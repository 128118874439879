import React from "react";
import { Sidebar } from "../../layouts/auth/Sidebar";
import { TopNavigation } from "../../layouts/auth/TopNavigation";
import AddClientTier from "./AddClientTier";
import ClientTierList from "./ClientTierList";
import AddCareWorkerTier from "./AddCareWorkerTier";
import CareWorkerTierList from "./CareWorkerTierList";

const Configurations = () => {
  const [store, setStore] = React.useState(null);
  const [care, setCare] = React.useState(null);

  return (
    <div>
      <Sidebar />
      <TopNavigation title="Configurations" />
      <div className="ml-72 mr-8 mt-10 bg-white h-screen">
        <div className="grid grid-cols-2 gap-10">
          <div className="border p-4 rounded-lg">
            <AddClientTier store={store} setStore={setStore} />
            <ClientTierList setStore={setStore} />
          </div>
          <div className="border p-4 rounded-lg">
            <AddCareWorkerTier care={care} setCare={setCare} />
            <CareWorkerTierList setCare={setCare} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Configurations;
