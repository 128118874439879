import React from "react";
import { Link, useNavigate } from "react-router-dom";
import FormikController from "../../components/Formik/FormikController";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Auth from "../../layouts/auth/Auth";
import { loginPending, loginSucess, loginFail } from "./LoginSlice";
import { useDispatch, useSelector } from "react-redux";
import { userLogin } from "../../api/userApi";
import { getUserProfile } from "../../pages/dashboard/userAction";
import PageLoader from "../../components/PageLoader";

function Login() {
  
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { myAccount } = useSelector((state) => state.updateAccount);
  const { user } = useSelector((state) => state.user);

  const rol = user ? user.role : ""
console.log('role',rol);
  const { isLoading, error } = useSelector((state) => state.login);

  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const handleSubmit = async (data) => {
    dispatch(loginPending());

    try {
      const isAuth = await userLogin(data);

      if (isAuth) {
        dispatch(loginSucess());
        if(rol  === 'REGULATOR'){
          navigate("/assigned-clients")
        }
         else if (rol === 'ADMINSTRATOR' || rol === 'MANAGER' || rol === 'OFFICE STAFF' ) { 
          navigate("/dashboard");
         }   
        dispatch(getUserProfile());
      }
    } catch (error) {
      dispatch(loginFail(error.response.data.message));
    }


  };

  React.useEffect(() => {
    (sessionStorage.getItem("access_token") && localStorage.getItem("refreshToken")) && navigate("/dashboard");
  }, [navigate, myAccount,rol]);

  return (
    <Auth>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {() => (
          <Form className="mt-10">
            {error && (
              <div className="px-2">
                <div
                  className="bg-red-100 border mb-3 text-center font-medium border-red-200 text-red-700 px-4 py-3 rounded relative"
                  role="alert"
                >
                  <span className="block sm:inline">{error}</span>
                  <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                    {/* <svg className="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" /></svg> */}
                  </span>
                </div>
              </div>
            )}
            <div className="mb-6 pt-2 px-2">
              <FormikController
                control="input"
                type="email"
                placeholder="Email"
                name="email"
              />
            </div>
            <div className="mb-3 px-2">
              <FormikController
                control="input"
                type="password"
                placeholder="Password"
                name="password"
              />
            </div>
            <div className="flex flex-row-reverse px-2">
              <span></span>
              <Link
                to="reset-password"
                className="text-sm  text-button hover:underline font-medium mt-1"
              >
                Forgot Password?
              </Link>
            </div>
            <div className="mb-3 pt-1 px-2">
              {isLoading ? (
                <PageLoader />
              ) : (
                <div className="mb-3 pt-3">
                  <button
                    type="submit"
                    className="bg-button text-white font-medium border w-full rounded p-1.5 text-lg px-10"
                  >
                    Login
                  </button>
                </div>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </Auth>
  );
}

export default Login;
