import React from "react";
import { BsCircle } from "react-icons/bs";
import { BsFillCircleFill, BsDashLg, BsCalendar2XFill, BsThreeDots,BsCheckCircleFill,BsSticky, BsFillEyeFill} from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import addedPlans from "../ClientActions/GetAddedCarePlansAction";
import MARecord from "./MARecord";
import Select from 'react-select';
import { getAdministered } from "../../../api/medicationApi";
import ViewClientMarchart from "../AddClient/ViewMarchart";
import PageLoader from "../../../components/PageLoader";


const MARChart = (client) => {
  const date = new Date();
  let currentyear = date.getFullYear();
  const[year, setYear] = React.useState({
    "value": currentyear,
    "label": currentyear
  })
  const[month, setMonth] = React.useState({
    "value": (date.getMonth()+1),
    "label": (date.getMonth()+1).toLocaleString('default',{month: 'long'})
  })
  let daysofmonth =  new Date(year.value, (month.value), 0);
  let lastDayOfMonth = daysofmonth.getDate();
  const[lastday, setLastDay] = React.useState(lastDayOfMonth)
  const [days, setDays] = React.useState(lastday);


    const dispatch = useDispatch();
    const {addedCarePlans, isLoadingAddedPlans} = useSelector(state => state.addedclientcareplans)
     const {getAdministeredMedication,isLoadingGetAdministeredMedication} = useSelector(state=> state.administered)

const altDays = [
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10'
]

  var timeMar = [
    "Morning",
    "Lunchtime",
    "Evening",
    "Bed time",
    // "As needed",
    // "Alternating Hours"
  ];

  var MONTHS = [
    {
      value: 1,
      label: "January"
    },
    {
      value: 2,
      label: "February"
    },
    {
      value: 3,
      label: "March"
    },
    {
      value: 4,
      label: "April"
    },
    {
      value: 5,
      label: "May"
    },
    {
      value: 6,
      label: "June"
    },
    {
      value: 7,
      label: "July"
    },
    {
      value: 8,
      label: "August"
    },
    {
      value: 9,
      label: "September"
    },
    {
      value: 10,
      label: "October"
    },
    {
      value: 11,
      label: "November"
    },
    {
      value: 12,
      label: "December"
    }
   
  ];


  React.useEffect(() => {
    dispatch(addedPlans({
      clientId: client.client.id
    }))

  }, [])


let handleMonth = (selectedMonth) =>{
  setMonth(selectedMonth)
}

const handleYear = () =>{
var max = new Date().getFullYear()
var min = max - 9
var years = []
for(var i = max; i >= min; i--){
 years.push({
   "value" : i,
   "label" : i
})
}
return years
}

const years = handleYear();
const marcharts = !isLoadingAddedPlans && Array.isArray(addedCarePlans) && addedCarePlans.length > 0 ?  addedCarePlans.filter((e)=>e.careplan.code === 'CP06') : [];
// const handleMarchart = (machartId)=>{
//       dispatch(getAdministered(
//        {
//          id : machartId,
//          month: month.value,
//          year:year.value
//         }
//       ))
// }

const handleLastDayByMonth = (e)=>{ 
     setMonth(e)
  let daysofmonth =  new Date(year.value, (e.value), 0);
  return setLastDay(daysofmonth.getDate());
}

// console.log('addedCarePlans',addedCarePlans);

const handleLastDayByYear = (e)=>{ 
  setYear(e)
  let daysofmonth =  new Date(e.value, (month.value), 0);
  return setLastDay(daysofmonth.getDate());
}

const handleDay = (day, data, time) => {

  const dayData = data.find((d) => {
    const date = new Date(d.createdAt);
    const dayMatch = date.getDate() === day;
    const monthMatch = date.getMonth() + 1 === month.value;
    const yearMatch = date.getFullYear() === year.value;
    const timeMatch = (d.dayTime).toLocaleUpperCase() === time.toLocaleUpperCase();
    
    if (dayMatch && monthMatch && yearMatch && timeMatch) {
     
      return d;
    }
    
    return false;
  });

     
  return dayData &&  (typeof dayData === 'object') ? (dayData.numberTaken > 0 ? 1 : 2) : false;
}


const handleName = (marchart)=>{
 return (marchart.prn) ? 'As Needed' : (marchart.alternateHours ? 'Alternate Hours' : (marchart.alternateDays ? 'Alternate Days' : (marchart.weekly ? 'Weekly' : 'Daily')) )
}

const handleAdministered = (chart) => {
  const med = chart.medications;
  const filteredData = med.filter(function(obj) {
    const newDate = new Date(obj.createdAt);
    console.log('Selected Month:', newDate.getMonth() + 1, 'New Month:', month);
    return (newDate.getMonth() + 1) === month.value && newDate.getFullYear() === year.value;
  });

  const clientName = client.client?.firstname+' '+client.client?.lastname;
  const time = handleName(chart);

  return {
    administered: filteredData,
    medication: (chart.medication + ' - ' + time).toLocaleUpperCase(),
    clientName: clientName,
    dosage: chart.dosage
  };
};
  return (
    <div className="p-5">
          <div className="flex space-x-10 justify-end py-3 px-4 bg-primary rounded mb-5">
          <div className="flex flex-row">
  <div className="mr-4">
    <label htmlFor="month">Month</label>
    <Select
      id="month"
      defaultValue={{ value: month.value, label: month.label }}
      options={MONTHS}
      onChange={(e) => handleLastDayByMonth(e)}
      className="w-60 h-30 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
    />
  </div>
  <div>
    <label htmlFor="year">Year</label>
    <Select
      id="year"
      defaultValue={{ value: year.value, label: year.label }}
      options={years}
      onChange={(e) => handleLastDayByYear(e)}
      className="w-60 h-30 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
    />
  </div>
</div> 
      </div>
      <div className="flex space-x-10 justify-end py-3 px-2 bg-primary rounded mb-5">
    
    
        <div className="flex flex-row">
          <div>
            <BsSticky color="gray" className="mt-1 mr-2" />{" "}
          </div>
          <div>- Not Reported</div>
        </div>
        <div className="flex flex-row">
          <div>
            <BsCheckCircleFill color="#667EEA" className="mt-1 mr-2" />{" "}
          </div>
          <div>- Taken</div>
        </div>
        <div className="flex flex-row">
          <div>
            <BsCalendar2XFill color="#F97417" className="mt-1 mr-2" />{" "}
          </div>
          <div>- Declined</div>
        </div>
 
        {/* <div className="flex flex-row">
        <div
          className="text-white bg-orange-500 border hover:bg-white hover:bg-black hover:text-gray-700 hover:border hover:border-gray-300 hover:underline decoration-secondary focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
          <BsFillEyeFill color="#FEEAA7"/>{" "}
        </div>
          <div>- View More</div>
        </div> */}
       
      </div>
  
     {isLoadingAddedPlans ? <PageLoader/> : marcharts.length > 0 && marcharts.map((marchart)=>{
      //  {handleMarchart(marchart.id)}
      return (
        <>
        <div className="text-left pb-4">
        <div className="uppercase bg-button font-bold text-center p-3 rounded text-white">{handleName(marchart) }</div> <div className="text-center p-5">{marchart.medication.toUpperCase()} </div>
      </div>
      <div className="grid grid-cols-12">
        <div className="col-span-2 bghandleYear-gray-100 text-left rounded-l  border ">

          {timeMar && timeMar.map((cxt) => <div className="ml-2 mb-4 mt-6">{cxt}</div>)}

        </div>
        <div className="col-span-10 ">
          <div className="flex flex-row space-x-4">
         {[...Array(lastday).keys()].map((day) => { 
          
              return (
                <div className="text-center ml-1 hover:shadow hover:bg-primary hover:rounded hover:mr-1">
                <div className="underline font-bold mb-4">{day+1}</div>
               
                { <div key={day}  className="mr-1">
                {handleDay(day + 1,  marchart.medications, 'Morning') ? (
                     (handleDay(day + 1,  marchart.medications, 'Morning') === 1 ? <div><BsCheckCircleFill color="#667EEA" /></div> : <div><BsCalendar2XFill color="#F97417" /> </div>)
                  ) : (
                    <div><BsSticky color="gray" /></div>
                  )}
                </div> }
                { <div key={day}  className="mr-1 mt-6 mb-6">
                {handleDay(day + 1,  marchart.medications, 'Lunchtime') ? (
                     (handleDay(day + 1,  marchart.medications, 'Lunchtime') === 1 ? <div><BsCheckCircleFill color="#667EEA" /></div> : <div><BsCalendar2XFill color="#F97417" /> </div>)
                  ) : (
                    <div><BsSticky color="gray" /></div>
                  )}
                </div> }
                { <div key={day}  className="mr-1 mt-4 mb-8">
                {handleDay(day + 1,  marchart.medications, 'Evening') ? (
                    (handleDay(day + 1,  marchart.medications, 'Evening') === 1 ? <div><BsCheckCircleFill color="#667EEA" /></div> : <div><BsCalendar2XFill color="#F97417" /> </div>)
                  ) : (
                    <div><BsSticky color="gray" /></div>
                  )}
                </div> }
                { <div key={day}  className="mr-1 mb-8">
                {handleDay(day + 1,  marchart.medications, 'Bedtime') ? (
                    (handleDay(day + 1,  marchart.medications, 'Bedtime') === 1 ? <div><BsCheckCircleFill color="#667EEA" /></div> : <div><BsCalendar2XFill color="#F97417" /> </div>)
                  ) : (
                    <div><BsSticky color="gray" /></div>
                  )}
                </div> }

                  <div className="mt-1 text-left">
               </div>
            </div>
              )
            
               })}
               
          </div>
          <div className="mr-1 p-5"> <MARecord e={handleAdministered(marchart)}/></div>
        </div>
        
      </div>
     <hr className="mt-7 mb-5"/>

  
      </>
      )
  
     })
     }
    </div>
  );
};

export default MARChart;
