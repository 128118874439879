import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    editRiskAssessment: {},
    isLoadingeditRiskAssessment: false,
    erroreditRiskAssessment: ''
}

const editRiskAssessmentSlice = createSlice({
    name: 'editRiskAssessment',
    initialState,
    reducers:{
        editRiskAssessmentPending: (state) => {
            state.isLoadingeditRiskAssessment = true;
        },
        editRiskAssessmentSuccess: (state, {payload}) => {
            state.isLoadingeditRiskAssessment = false;
            state.editRiskAssessment = payload;
            state.erroreditRiskAssessment = '';
        },
        editRiskAssessmentFail: (state, {payload}) => {
            state.isLoadingeditRiskAssessment = false;
            state.erroreditRiskAssessment = payload;
        }
    }
})

export const {editRiskAssessmentPending, editRiskAssessmentSuccess, editRiskAssessmentFail} = editRiskAssessmentSlice.actions

export default editRiskAssessmentSlice.reducer