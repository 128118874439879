import {EmployeesProcessingPending, EmployeesProcessingSuccess, EmployeesProcessingFail} from '../RegisterCarerSlices/EmployeesProcessingSlice'
import {EmployeesProcessing} from '../../api/carerApi'

 const EmployeesProcessingAction = (data) => async dispatch => {

    try {
        dispatch(EmployeesProcessingPending());
        const result = await EmployeesProcessing(data);
        if(result){
            return dispatch(EmployeesProcessingSuccess(result))
        }
    } catch (error) {
        dispatch(EmployeesProcessingFail(error))
    }
} 

export default EmployeesProcessingAction