import React from "react";
import Logo from "../../components/Logo";

const Auth = (props) => {
  return (
    <div className="h-screen bg-primary p-14 2xl:p-24 ">
      <div className="grid grid-cols-3">
        <div></div>
        <div className=" bg-white shadow-lg mx-auto mt-10 rounded-lg">
          <div className="sm:p-6 lg:p-10 2xl:p-10">
            <Logo className="w-3/5 mx-auto" />
            <label className="text-left text-lg text-gray-700 font-bold ">
              {props.title}
            </label>
            {props.children}
          </div>
          {/* <div className='col-span-7 bg-auth bg-cover rounded-r-lg'>
                    </div> */}
        </div>
      </div>
    </div>
  );
};

export default Auth;
