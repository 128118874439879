import {educationTrainingPending, educationTrainingSuccess, educationTrainingFail} from '../RegisterCarerSlices/EducationTrainingSlice'
import {EducationTraining} from '../../api/carerApi'

 const EducationTrainingAction = (data) => async dispatch => {

    try {
        dispatch(educationTrainingPending());
        const result = await EducationTraining(data);
        if(result){
            return dispatch(educationTrainingSuccess(result))
        }
    } catch (error) {
        dispatch(educationTrainingFail(error))
    }
} 

export default EducationTrainingAction