import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    updateRegulatorClients: {},
    isLoadingupdateRegulatorClients: false,
    errorupdateRegulatorClients: ''
}

const updateRegulatorClientsSlice = createSlice({
    name: 'updateRegulatorClients',
    initialState,
    reducers:{
        updateRegulatorClientsPending: (state) => {
            state.isLoadingupdateRegulatorClients = true;
        },
        updateRegulatorClientsSuccess: (state, {payload}) => {
            state.isLoadingupdateRegulatorClients = false;
            state.updateRegulatorClients = payload;
            state.errorupdateRegulatorClients = '';
        },
        updateRegulatorClientsFail: (state, {payload}) => {
            state.isLoadingupdateRegulatorClients = false;
            state.errorupdateRegulatorClients = payload;
        }
    }
})

export const {updateRegulatorClientsPending, updateRegulatorClientsSuccess, updateRegulatorClientsFail} = updateRegulatorClientsSlice.actions

export default updateRegulatorClientsSlice.reducer