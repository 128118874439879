import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  CarerType: {},
  isLoadingCarerType: false,
  errorCarerType: "",
};

const CarerTypeSlice = createSlice({
  name: "CarerType",
  initialState,
  reducers: {
    CarerTypePending: (state) => {
      state.isLoadingCarerType = true;
    },
    CarerTypeSuccess: (state, { payload }) => {
      state.isLoadingCarerType = false;
      state.CarerType = payload;
      state.errorCarerType = "";
    },
    CarerTypeFail: (state, { payload }) => {
      state.isLoadingCarerType = false;
      state.errorCarerType = payload;
    },
  },
});

export const { CarerTypePending, CarerTypeSuccess, CarerTypeFail } =
  CarerTypeSlice.actions;

export default CarerTypeSlice.reducer;
