import { getInvoiceDetails } from "../../../api/invoicesApi";
import { getInvoiceDetailsFail, getInvoiceDetailsPending, getInvoiceDetailsSuccess } from "../Slices/GetInvoiceDetailsSlice";



const getInvoiceDetail = (data) => async dispatch => {

    try {
        dispatch(getInvoiceDetailsPending());
        const result = await getInvoiceDetails(data);
        if(result){
            return dispatch(getInvoiceDetailsSuccess(result))
        }
    } catch (error) {
        dispatch(getInvoiceDetailsFail(error))
    }
} 

export default getInvoiceDetail