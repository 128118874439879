import {addedCarePlansPending, addedCarePlansSuccess, addedCarePlansFail} from '../ClientSlices/GetAddedCarePlansSlice'
import {getAddedCarePlans} from '../../../api/clientApi'

 const addedPlans = (data) => async dispatch => {

    try {
        dispatch(addedCarePlansPending());
        const result = await getAddedCarePlans(data);
        if(result){            
            return dispatch(addedCarePlansSuccess(result))
        }
    } catch (error) {
        dispatch(addedCarePlansFail(error))
    }
} 

export default addedPlans