import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  EmployeesProcessing: {},
  isLoadingEmployeesProcessing: false,
  errorEmployeesProcessing: "",
};

const EmployeesProcessingSlice = createSlice({
  name: "EmployeesProcessing",
  initialState,
  reducers: {
    EmployeesProcessingPending: (state) => {
      state.isLoadingEmployeesProcessing = true;
    },
    EmployeesProcessingSuccess: (state, { payload }) => {
      state.isLoadingEmployeesProcessing = false;
      state.EmployeesProcessing = payload;
      state.errorEmployeesProcessing = "";
    },
    EmployeesProcessingFail: (state, { payload }) => {
      state.isLoadingEmployeesProcessing = false;
      state.errorEmployeesProcessing = payload;
    },
  },
});

export const { EmployeesProcessingPending, EmployeesProcessingSuccess, EmployeesProcessingFail } =
  EmployeesProcessingSlice.actions;

export default EmployeesProcessingSlice.reducer;
