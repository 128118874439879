import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    editReferralDoc: {},
    isLoadingReferral: false,
    errorEditReferral: ''
}

const editReferralSlice = createSlice({
    name: 'editReferralDoc',
    initialState,
    reducers:{
        editReferralPending: (state) => {
            state.isLoadingReferral = true;
        },
        editReferralSuccess: (state, {payload}) => {
            state.isLoadingReferral = false;
            state.editReferralDoc = payload;
            state.errorEditReferral = '';
        },
        editReferralFail: (state, {payload}) => {
            state.isLoadingReferral = false;
            state.errorEditReferral = payload;
        }
    }
})

export const {editReferralPending, editReferralSuccess, editReferralFail} = editReferralSlice.actions

export default editReferralSlice.reducer