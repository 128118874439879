import React from 'react'
import { Sidebar } from '../../layouts/auth/Sidebar'
import { TopNavigation } from '../../layouts/auth/TopNavigation'
import CareWorkers from './CareWorkers'
import Staff from './Staff'
import StaffDbs from './StaffDbs'
import { Link } from 'react-router-dom'
import { ImUserPlus } from "react-icons/im";
import SendInvite from '../carers/AddCarer/SendInvite'

function Recruitment() {
  const [active, setActive] = React.useState('carers');

  let content;

  switch (active) {
    case 'carers':
      content = <CareWorkers />
      break;
    case 'staff':
      content = <Staff />
      break;
    default:
      break;
  }
  return (
    <div><Sidebar /><TopNavigation title='Recruitment' />
      <div className='bg-white h-screen'>
        <div className='flex justify-between'>
          <div>
            <ul className="flex flex-wrap -mb-px ml-80 pt-10">
              <li className="mr-2">
                <button onClick={() => setActive('carers')} className={`inline-block py-2 px-4 focus:outline-none rounded-t-lg border-b-2 ${active === 'carers' ? "border-secondary text-white font-medium dark:text-button dark:border-button bg-orange-500" : "border-transparent hover:text-button hover:border-button dark:hover:text-gray-300"}`}>Carer Applications</button>
              </li>
              <li className="mr-2">
                <button onClick={() => setActive('staff')} className={`inline-block py-2 px-4 focus:outline-none rounded-t-lg border-b-2 ${active === 'staff' ? "border-secondary text-white font-medium dark:text-button dark:border-button bg-orange-500" : "border-transparent hover:text-button hover:border-button dark:hover:text-gray-300"}`}>DBS Applications</button>
              </li>
            </ul>
          </div>
         <div className='flex'>
         <div className='mt-10 mr-7'><Link to='/add-carer' className="bg-button hover:bg-transparent inline-flex text-white font-semibold hover:text-button py-2 px-4 border border-secondary hover:border-button rounded">
            Add Carer <ImUserPlus size={18} className='mt-1 ml-2'/>
          </Link></div>
          {/* <div className='mt-10 mr-7'><SendInvite/></div> */}
         </div>
        </div>
        <div className="">
          {content}
        </div>
      </div>
    </div>
  )
}

export default Recruitment