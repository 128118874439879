
import { useUpdateCompliance } from "../../../api/carerApi";
import { editComplianceStatusFail, editComplianceStatusPending, editComplianceStatusSuccess } from "../CarerSlices/UpdateComplianceSlice";

const editComplianceStatus = (data) => async dispatch => {

    try {
        dispatch(editComplianceStatusPending());
        const result = await useUpdateCompliance(data);
        if(result){
            return dispatch(editComplianceStatusSuccess(result))
        }
    } catch (error) {
        dispatch(editComplianceStatusFail(error))
    }
} 

export default editComplianceStatus