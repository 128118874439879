import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    getCarers: {},
    isLoadingGetCarers: false,
    errorGetCarers: ''
}

const getCarersSlice = createSlice({
    name: 'getCarers',
    initialState,
    reducers:{
        getCarersPending: (state) => {
            state.isLoadingGetCarers = true;
        },
        getCarersSuccess: (state, {payload}) => {
            state.isLoadingGetCarers = false;
            state.getCarers = payload;
            state.errorGetCarers = '';
        },
        getCarersFail: (state, {payload}) => {
            state.isLoadingGetCarers = false;
            state.errorGetCarers = payload;
        }
    }
})

export const {getCarersPending, getCarersSuccess, getCarersFail} = getCarersSlice.actions

export default getCarersSlice.reducer