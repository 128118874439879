import React from 'react'
import { Sidebar } from '../../layouts/auth/Sidebar'
import { TopNavigation } from '../../layouts/auth/TopNavigation'
import { Link } from 'react-router-dom'
import Active from './Active'
import Inactive from './Inactive'
import Archived from './Archived'
import Birthdays from './Birthdays'
import { ImUserPlus } from "react-icons/im";

const Clients = () => {
  const [active, setActive] = React.useState('active');

  let content;
  

  switch (active) {
    case 'active':
      content = <Active />
      break;
    case 'inactive':
      content = <Inactive />
      break;
    case 'archived':
      content = <Archived />
      break;
      case 'birthdays':
        content = <Birthdays />
      break;
    default:
      break;
  }

  return (
    <div><Sidebar /><TopNavigation title='Clients' />
      <div className='bg-white h-screen'>
        <div className='flex justify-between'>
          <div>
            <ul className="flex flex-wrap font-medium -mb-px ml-80 pt-5 mt-6">
              <li className="mr-2">
                <button onClick={() => setActive('active')} className={`inline-block py-2 px-4 focus:outline-none rounded-t-lg border-b-2 ${active === 'active' ? "border-secondary text-white font-medium dark:text-button dark:border-button bg-orange-500" : "border-transparent hover:text-button hover:border-secondary dark:hover:text-gray-300"}`}>Active</button>
              </li>
              <li className="mr-2">
                <button onClick={() => setActive('inactive')} className={`inline-block py-2 px-4 focus:outline-none rounded-t-lg border-b-2 ${active === 'inactive' ? "border-secondary text-white font-medium dark:text-button dark:border-button bg-orange-500" : "border-transparent hover:text-button hover:border-secondary dark:hover:text-gray-300"}`}>Inactive</button>
              </li>
              <li className="mr-2">
                <button onClick={() => setActive('archived')} className={`inline-block py-2 px-4 focus:outline-none rounded-t-lg border-b-2 ${active === 'archived' ? "border-secondary text-white font-medium dark:text-button dark:border-button bg-orange-500" : "border-transparent hover:text-button hover:border-secondary dark:hover:text-gray-300"}`}>Archived</button>
              </li>
              <li className="mr-2">
                <button onClick={() => setActive('birthdays')} className={`inline-block py-2 px-4 focus:outline-none rounded-t-lg border-b-2 ${active === 'birthdays' ? "border-secondary text-white font-medium dark:text-button dark:border-button bg-orange-500" : "border-transparent hover:text-button hover:border-secondary dark:hover:text-gray-300"}`}>Upcoming Birthdays</button>
              </li>
            </ul>
          </div>
          <div className='mt-10 mr-8'>  <Link to='/client' className="bg-button hover:bg-transparent inline-flex text-white font-semibold hover:text-button py-2 px-4 border border-secondary hover:border-button rounded">
            Add Client <ImUserPlus size={18} className='mt-1 ml-2'/>
          </Link>
          </div>
        </div>
        <div className="">
          {content}
        </div>
      </div>
    </div>
  )
}

export default Clients